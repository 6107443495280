import React from "react";
import { Form, Input, Button } from "antd";
import styles from "../Login.module.less";
import { bg2, head, back } from "../../../images/index";

const MobileSetEmail = Form.create({
  name: "CreateSetEmail_Form",
  onFieldsChange(props, changedFields) {
    props.changeVals("setEmail", changedFields);
  },
  mapPropsToFields(props) {
    return {
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  return (
    <div className={styles.mformBox}>
      <div className={styles.mBGBox} style={setBg(bg2, "contain", "top")}></div>
      <div className={styles.mBack}>
        <div
          className={styles.back}
          style={setBg(back)}
          onClick={() => {
            props.back("forgetPsd");
          }}
        />
      </div>
      <span style={insideStyles.mtitle1}>Please input your account</span>
      <Form className={styles.mformWrap} colon={false}>
        <Form.Item style={{ marginBottom: "5px" }}>
          {getFieldDecorator("email", {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              { max: 50, message: "fields cannot be longer than 50 characters" }
            ]
          })(
            <Input
              style={{ height: "42px" }}
              prefix={
                <div
                  className={styles.icon}
                  style={([insideStyles.icon1], setBg(head))}
                />
              }
              placeholder="Login Account"
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type="danger"
            htmlType="submit"
            onClick={() => {
              props.handleSubmit(props.form, "send_email");
            }}
            className={styles.btn}
          >
            Send Email
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});

const setBg = (url, size = "contain", x = "center") => ({
  background: `url(${url}) ${x} center no-repeat`,
  backgroundSize: size
});

const insideStyles = {
  title1: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#666666",
    letterSpacing: 0,
    marginBottom: "15px",
    marginTop: "13px"
  },
  icon1: {
    height: "12px",
    width: "17px"
  },
  mtitle1: {
    fontFamily: "ArialM",
    fontSize: "16px",
    color: "#666666",
    letterSpacing: 0,
    marginBottom: "25px"
  }
};
export default MobileSetEmail;
