const initialState = {
  usefulTemporaryData: { fieldsData: {} },
};

const commonRenducer = (state = initialState, action) => {
  return {
    ...state,
    ...action.payload
  };
};

export default commonRenducer;
