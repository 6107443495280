
import React, { useState, useEffect } from "react";
import { Tooltip, Form, Col, Select } from "antd";
import withConnector from '../../../connector';
import { get } from '../../../../../../../utils/request';
import styles from '../../index.module.less';

const { Option } = Select;

function SessionDaySelector(props) {
  const { getFieldDecorator } = props;
  const [sessionDayList, setsessionDayList] = useState([]);
  const fetchData = async (courseDetail) => {
    const { sessionId } = courseDetail;
    if (sessionId) {
      const res = await get("/attendanceTms/getSessiondayDatetimeList", { sessionId });
      const sessionDayList = res.data;
      setsessionDayList(sessionDayList);
      props.sessionDayChangesTrigger(sessionDayList.length && sessionDayList[0].sessiondayId);
    }
  }

  const selectChanges = value => {
    // console.log(value.key);
    props.sessionDayChangesTrigger(value.key);
  }

  useEffect(() => {
    // console.log(props);
    fetchData(props.courseDetail);
  }, [props.courseDetail]);

  return (
    <div className={styles.selectbox}>
      <Col xs={24}>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 19 }}
            label="Session Day"
          >
            {getFieldDecorator(
              "sessionDay",
              {
                initialValue: {
                  key: sessionDayList.length && sessionDayList[0].sessiondayId,
                  value: sessionDayList.length && sessionDayList[0].sessiondayId
                }
              }
            )(
              <Select
                labelInValue
                onChange={selectChanges}
              >
                {sessionDayList.length
                ? sessionDayList.map(item => (
                  <Option
                    value={item.sessiondayId}
                    key={item.sessiondayId}
                  >
                    <Tooltip
                      placement="top"
                      title={item.sessiondayDate}
                    >
                      {item.startDate} - {item.endDate} : {" "}
                      {item.instructorName} {item.classCode}
                    </Tooltip>
                  </Option>
                ))
                : null}
              </Select>
            )}
          </Form.Item>
      </Col>
    </div>
  );
}

export default withConnector(SessionDaySelector);
