import React from 'react'
import {
  Tooltip,
} from "antd";

export const commonColumns = [
  {
    title: 'No',
    dataIndex: "index",
    align: "center",
    width: 50,
    fixed: "left",
    render: (text, data, index) => {
      return (
        <Tooltip placement="top" title={index + 1}>
          {index + 1}
        </Tooltip>
      )
    }
  },
  {
    title: 'Agent ID',
    dataIndex: "agentId",
    align: "center",
    width: 150,
    fixed: "left",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Code',
    dataIndex: "agentCode",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Name',
    dataIndex: "agentName",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Region',
    dataIndex: "region",
    align: "center",
    width: 150,
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Unit Code',
    dataIndex: "unitCode",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Leader',
    dataIndex: "agentLeader",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
]

export const dropdownItem = [
  {
    label: 'Individual',
    key: '1',
  },
  {
    label: 'Import',
    key: '2',
  },
]
