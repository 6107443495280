import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  DatePicker,
  Tooltip,
  Table,
  Radio,
  Modal,
  notification
} from "antd";
import moment from "moment";
import { globalPromptTime10s } from "../../../../../utils/global";
import { changeVals } from "../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import styles from "./styles.module.less";
import {
  search,
  getLockTable,
  killLockTable,
  stop,
  setTime,
  init_data,
  save_data
} from "../../../../../redux/pambReducer/systemSettingsReducers/TimeJobRerunReducer";

const { RangePicker } = DatePicker;

const CreateForm = Form.create({
  name: "CreateTimeJobRerunForm",
  onFieldsChange(props, changedFields) {
    props.onChange("TimeJobRerun", changedFields);
  },
  mapPropsToFields(props) {
    return {
      time: Form.createFormField({
        ...props.time,
        value: props.time.value
      }),
      type: Form.createFormField({
        ...props.type,
        value: props.type.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { globalLoading, columns, list } = props;
  return (
    <Spin spinning={globalLoading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col span={24}>
            <Form.Item>
              {getFieldDecorator("type", {})(
                <Radio.Group onChange={props.changeBtn} buttonStyle="solid">
                  <Radio.Button value="H">Hourly</Radio.Button>
                  <Radio.Button value="D">Daily</Radio.Button>
                  <Radio.Button value="M">Monthly</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          onClick={() => {
            props.search(props.type.value)
          }}
        >Refresh
        </Button>
        <div
          style={{
            marginTop: "10px"
          }}
        >
          <Table
            bordered
            pagination={{
              pageSize: 50,
            }}
            className={styles.ant_dev_table}
            columns={columns}
            dataSource={list}
            size="small"
            rowKey="jobTaskId"
            scroll={{ x: 1400 }}
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pambReducer: { systemSettingsReducers: { TimeJobRerunReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: TimeJobRerunReducer,
    globalLoading: loading
  }),
  {
    changeVals,
    search,
    getLockTable,
    killLockTable,
    stop,
    setTime,
    init_data,
    save_data
  }
)
class TimeJobRerunList extends Component {
  constructor(props) {
    super(props);
    // this.handleTableChange = this.handleTableChange.bind(this);
    this.goDetail = this.goDetail.bind(this);
    this.state = {
      trainingStart: "",
      currentPageNumber: 1,
      fileList: [],
      pageSize: 10,
      pageIndex: 1,
      time: [],
      endDate: false,
      columns: [
        {
          title: "Sequence",
          dataIndex: "jobSequence",
          align: "center",
          width: 100,
          fixed: "left",
          render: (text, record, index) => (
            <Tooltip placement="top" title={index + 1}>
              {index + 1}
            </Tooltip>
          )
        },
        {
          title: "Name",
          dataIndex: "taskName",
          align: "center",
          width: 300,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Start Time",
          dataIndex: "startDateTime",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "End Time",
          dataIndex: "endDateTime",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Job Start Date",
          dataIndex: "startDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Job End Date",
          dataIndex: "endDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Actions",
          dataIndex: "Edit",
          align: "center",
          width: 150,
          fixed: "right",
          render: (text, record) => (
            <div className={styles.editBox}>
              <a
                onClick={() => {
                  // console.log("record.jobSequence:", record.jobSequence) // eslint-disable-line
                  this.goDetail(record.jobSequence, record.type, record.bu, record.taskName);
                }}
              >
                View
              </a>
              <a
                onClick={() => {
                  if (record.url.indexOf("endDate") > -1) {
                    this.setState(() => ({
                      endDate: true,
                      time: [moment().subtract(1, 'days'), moment()]
                    }));
                  } else {
                    this.setState(() => ({
                      endDate: false,
                      time: moment().subtract(1, 'days')
                    }))
                  }
                  console.log("record.url:", record.url) // eslint-disable-line
                  if (record.status == "PROCESSING") {
                    const service = record.bu == "PAMB" ? "pamb" : "attendancepamb";
                    this.props.getLockTable(service, record.jobTaskId, record.taskName, record.url);
                  } else {
                    this.props.save_data({
                      visible: true,
                      showName: record.taskName,
                      url: record.url
                    })
                  }
                }}
              >
                {
                  record.status == "PROCESSING" ? "Re-Run" : "Run"
                }
              </a>
            </div>
          )
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { search: searches } = this.props.location.state;
      if (searches) {
        this.props.search(searches);
      } else {
        this.props.init_data();
      }
    } else {
      this.props.init_data();
    }
    this.props.search('H');
  }

  handleOk = (type) => {
    const { killList, type: { value }, url, showName, bu, jobTaskId } = this.props.fields;
    const { time } = this.state;
    if (type === "visible2") {
      const killArr = [];
      killList.forEach((item) => {
        killArr.push(item.spid);
      });
      const service = bu == "PAMB" ? "pamb" : "attendancepamb"
      this.props.killLockTable(service, killArr, url, showName, jobTaskId);
    } else {
      console.log("time:", time) // eslint-disable-line

      if (Array.isArray(time)) {
        if (!time.length) {
          notification.error({
            message: 'Please fill in the Start Time Range',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        } else {
          this.props.setTime(url, time, value)
        }
      } else {
        if (!time) {
          notification.error({
            message: 'Please fill in the Start Time Range',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        } else {
          // console.log(url, time, value) // eslint-disable-line
          this.props.setTime(url, time, value)
        }
      }
    }
  };

  handleCancel = (type) => {
    this.props.save_data({ [type]: false });
    if (type === "visible2") {
      this.props.save_data({ loading2: false, visible2: false, killList: [] });
    } else {
      this.props.save_data({ loading: false, visible: false, showName: '', url: '' });
    }
  };

  // handleTableChange = pagination => {
  //   const { current } = pagination;
  //   this.setState(() => ({
  //     currentPageNumber: current
  //   }));
  // };

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      // const { fields } = this.props;
      if (!err) {
      }
    });
  };

  changeTime = (time) => {
    this.setState(() => ({
      time
    }))
  }

  changeBtn = (e) => {
    this.props.search(e.target.value);

    console.log(e.target.value) //eslint-disable-line
  }

  goDetail(sequence, type, bu, taskName) {
    this.props.history.push(
      "/home/system_settings/pamb_time_job_rerun/pamb_time_job_rerundetail",
      {
        sequence,
        type,
        bu,
        taskName
      }
    );
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      globalLoading: this.props.globalLoading,
      columns: this.state.columns,
      currentPageNumber: this.state.currentPageNumber,
      search: this.props.search,
      // handleTableChange: this.handleTableChange,
      changeBtn: this.changeBtn
    };
    const { visible, visible2, loading, loading2, showName } = this.props.fields;
    return (
      <div className={styles.container}>
        <div className={styles.title}>PAMB Timejob Dashboard</div>
        <CreateForm {...props} {...this.props.fields} />
        <Modal
          visible={visible2}
          title="Prompt"
          onCancel={() => {
            this.handleCancel('visible2');
          }}
          footer={[
            <Button
              key="back"
              onClick={() => {
                this.handleCancel('visible2');
              }}
            >
              Return
            </Button>,
            <Button style={{ background: "#ed1b2e", borderColor: "#ed1b2e" }} key="submit" type="primary" loading={loading2} onClick={() => { this.handleOk('visible2') }}>
              Submit
            </Button>,
          ]}
        >
          <p>Do you want to release the lock</p>
        </Modal>

        <Modal
          centered
          visible={visible}
          title={showName}
          onOk={() => {
            this.handleOk('visible');
          }}
          onCancel={() => {
            this.handleCancel('visible');
          }}
          wrapClassName="jobrerun"
          footer={[
            <Button
              key="back"
              onClick={() => {
                this.handleCancel('visible');
              }}
            >
              Return
            </Button>,
            <Button style={{ background: "#ed1b2e", borderColor: "#ed1b2e" }} key="submit" type="primary" loading={loading} onClick={this.handleOk}>
              Submit
            </Button>,
          ]}
        >
          <Form
            hideRequiredMark
            layout="horizontal"
            labelCol={{ span: 7 }}
            labelAlign="left"
            wrapperCol={{ span: 17 }}
            colon={false}
          >
            <Form.Item label="Start Time Range">
              {
                this.state.endDate ?
                  (
                    <RangePicker
                      value={this.state.time}
                      onChange={this.changeTime}
                      placeholder={["Start Time", "End Time"]}
                      style={{ width: "100%" }}
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                    />
                  )
                  : (
                    <DatePicker
                      value={this.state.time}
                      onChange={this.changeTime}
                      placeholder='Start Time'
                      style={{ width: "100%" }}
                      format='YYYY-MM-DD'
                    />
                  )
              }
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default TimeJobRerunList;
