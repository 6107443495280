import React, { useState } from "react";
import { Button, message, Spin } from "antd-v5";
import { useRequest } from "ahooks";
import { getRequirementSettingList } from './api'
import styles from "./CreateRequirement.module.less";

const initSetting = { fromMonth: "", toMonth: "", frequency: "MONTHLY", minSession: "", disabled: false }

const CreateRequirement = (props) => {
  const [isloading, setIsloading] = useState(true)
  useRequest(getRequirementSettingList, {
    manual: false,
    onSuccess: (result) => {
      const { data = [] } = result
      setIsloading(false)
      if (data && data.length > 0) {
        props.history.push('/home/scheme_management/pru_venture_requirement_setting/details', { params: [...data] })
      }
    },
    onError: (error) => {
      setIsloading(false)
      message.error(error.message);
    },
    onFanally: () => {
      setIsloading(false)
    }
  });

  const createRequirementSetting = () => {
    props.history.push('/home/scheme_management/pru_venture_requirement_setting/details', { params: [initSetting] })
  }

  return (
    <div className={styles.container}>
      <Spin spinning={isloading} wrapperClassName={styles.spin}>
        <div className={styles.createButton}>
          <Button className={styles.ant_btn} onClick={createRequirementSetting}>
            Set Program Requirement
          </Button>
        </div>
      </Spin>
    </div>
  )
}


export default CreateRequirement;
