import React from "react";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Form, Row, Col, Input, Table, Button, Modal, Select, Spin, Divider, Popconfirm } from 'antd'
import { getTabelData, getYearList, updateGrant, createGrant, getFcCodeList, getGrantDetail, deleteGrant, getFcCodeList2, resetData } from '../../../../redux/pclaReducer/CPDSettingsReducers/GrantHoursReducer'
import styles from './GrantHours.module.less'

const { Option } = Select
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  span: 8
};

const flexCol2 = {
  span: 24
};
const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pclaReducer: { CPDSettingsReducers: { GrantHoursReducer } } }) => ({ GrantHoursReducer }),
  { getTabelData, getYearList, updateGrant, createGrant, getFcCodeList, getGrantDetail, deleteGrant, getFcCodeList2, resetData }
)

class Formes extends React.Component {
  state = {
    visible: false,
    fetching: false,
    queryData: {
      currentPageNumber: 1,
      pageSize: 10,
      fcCode: '',
      effectYear: ''
    },
    yearName: '',
    fcCodeName: '',
    ethic: '',
    randR: '',
    cis: '',
    others: '',
    aandH: '',
    userId: '',
    year: '',
    agentCode: '',
    columns: [
      {
        title: "S/N",
        dataIndex: "sn",
        align: "center",
        ellipsis: true,
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "FC Code",
        dataIndex: "fcCode",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Effect Year",
        dataIndex: "effectYear",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Detail",
        dataIndex: "detail",
        align: "center",
        ellipsis: true,
        render: (text) => {
          const arr = text ? text.split(',') : []
          return (
            <div>
              {arr.map((elt) => <div key={elt}>{elt}</div>)}
            </div>)
        }
      },
      {
        title: "Create Date",
        dataIndex: "createTime",
        align: "center",
      },
      {
        title: "Last Update Date",
        dataIndex: "updateTime",
        align: "center",
      },
      {
        title: "Update By",
        dataIndex: "updateUser",
        align: "center",
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        ellipsis: true,
        render: (text, record) => {
          const { id } = record
          return (
            <span>
              <span className={styles.spanCss} onClick={() => this.handleShowModal({ record, type: 'edit' })}>
                Edit
              </span>
              <Divider type="vertical" />
              <Popconfirm
                title="Please double confirm?"
                onConfirm={() => this.handleDelete(id)}
                okText="Yes"
                cancelText="No"
              >
                <span className={styles.spanCss}>Delete</span>
              </Popconfirm>
            </span>
          )
        }
      }

    ]
  }

  fetchUser = debounce((val) => {
    this.setState({ fetching: true });
    this.props.getFcCodeList({ ownerName: val }, () => this.handleFcCodeChange(val))
  }, 800)

  fetchUserModal = debounce((val) => {
    this.setState({ fetching: true });
    this.props.getFcCodeList2({ ownerName: val }, () => this.handleFcCodeChange2(val))
  }, 800)

  componentDidMount() {
    const { queryData } = this.state
    this.props.getTabelData({ ...queryData })
    this.props.getYearList()
    this.props.getFcCodeList({ ownerName: '' })
  }

  handleShowModal = ({ record, type }) => {
    if (type === 'edit') {
      const { id, fcCode, effectYear } = record
      this.setState({ visible: true, type, fcCodeName: fcCode, yearName: effectYear })
      this.props.getGrantDetail(id)
    } else {
      this.props.getFcCodeList2({ ownerName: '' })
      this.setState({ visible: true, type })
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { userId, effectYear } = values
        const { pageSize } = this.state
        this.props.getTabelData({ ...values, currentPageNumber: 1, })
        this.setState({
          queryData: {
            userId, effectYear, currentPageNumber: 1, pageSize
          }
        })
      }
    });
  }


  handleOk = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { queryData, type } = this.state
        const { GrantHoursReducer: { grantDetailData } } = this.props
        const { id } = grantDetailData
        const submitData = {
          ...values,
        }
        const callback = () => {
          this.props.getTabelData({
            ...queryData
          })
          this.handleCancel(props)
        }
        if (type === 'edit') {
          this.props.updateGrant({ ...submitData, id }, callback)
        } else {
          this.props.createGrant(submitData, callback)
        }
        this.handleShowModal(props)
      }
    });
  };

  handleDelete = (id) => {
    const callback = () => {
      const { queryData } = this.state
      this.props.getTabelData({
        ...queryData
      })
    }

    this.props.deleteGrant(id, callback)
  };

  handleCancel = (props) => {
    props.form.resetFields()
    this.setState({
      visible: false,
    });
    this.props.resetData()
  };

  handleFcCodeChange = (value) => {
    this.setState({
      fetching: false
    });
    if (!value) {
      this.props.getFcCodeList({ ownerName: '' });
    }
  };

  handleFcCodeChange2 = (value) => {
    this.setState({
      fetching: false
    });
    if (!value) {
      this.props.getFcCodeList2({ ownerName: '' });
    }
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { queryData } = this.state
    this.setState({
      queryData: {
        ...queryData,
        currentPageNumber: current,
        pageSize
      }
    })

    this.props.getTabelData(
      {
        ...queryData,
        pageSize,
        currentPageNumber: current,
      }
    );
  };

  render() {
    const { form: { getFieldDecorator }, GrantHoursReducer: { grantUserData: { userList, total }, tableLoading, grantYearList, fcCodeList, grantDetailData, fcCodeList2 } } = this.props
    const { columns, visible, queryData: { currentPageNumber, pageSize }, fetching, type, fcCodeName, yearName } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.heading}>Grant Hours</div>
        <Form
          colon={false}
          {...formLayout}
          labelAlign="left"
          className={styles.ant_form}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="FC Code">
                {getFieldDecorator("userId", {})(
                  <Select
                    notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                    showSearch
                    allowClear
                    filterOption={false}
                    onChange={this.handleFcCodeChange}
                    onSearch={this.fetchUser}
                  >
                    {fcCodeList.map(elt => <Option key={elt.userId} value={elt.userId}>{elt.agentCode}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Year">
                {getFieldDecorator("effectYear", {})(
                  <Select
                    showSearch
                    allowClear
                  >
                    {
                      grantYearList.map(elt => <Option key={elt.yearId} value={elt.yearId}>{elt.year}</Option>)
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={styles.btnBox}>
          <Button className={styles.ant_btn} onClick={this.handleSearch}>
            Search
          </Button>
        </div>
        <div className={styles.addBtnBox}>
          <Button icon="plus" className={styles.addBtn} onClick={() => this.handleShowModal({ type: 'create' })}>New Grant</Button>
        </div>
        <Table
          columns={columns}
          dataSource={userList}
          pagination={{
            current: currentPageNumber,
            pageSize,
            total
          }}
          size="small"
          loading={
            tableLoading
          }
          onChange={this.handleTableChange}
          className={styles.ant_dev_table}
          rowKey='id'
        />

        <ModelForm
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          visible={visible}
          grantDetailData={grantDetailData}
          fcCodeName={fcCodeName}
          type={type}
          yearName={yearName}
          fetching={fetching}
          fcCodeList2={fcCodeList2}
          grantYearList={grantYearList}
          handleModalFcCodeChange={this.handleFcCodeChange2}
          fetchUserModal={this.fetchUserModal}
        />
      </div>
    )
  }
}

function Formes2(props) {
  const { form: { getFieldDecorator },
    handleModalFcCodeChange,
    fetchUserModal,
    visible,
    handleOk,
    handleCancel,
    type,
    fcCodeName,
    yearName,
    grantDetailData,
    fetching,
    fcCodeList2,
    grantYearList
  } = props
  const { ethic, randR, cis, others, aandH } = grantDetailData
  return (
    <Modal
      title={`Grant Hours ${type === 'edit' ? 'Edit' : 'Add'}`}
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText='Save'
      okType='danger'
    >

      <Form
        colon={false}
        {...formLayout}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          {type === 'edit' ? (
            <>
              <Col {...flexCol2}>
                <Form.Item label="FC Code">
                  {getFieldDecorator("fcCode", {
                    initialValue: fcCodeName
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol2}>
                <Form.Item label="Year">
                  {getFieldDecorator("year", {
                    initialValue: yearName
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
            </>) : (
              <><Col {...flexCol2}>
                <Form.Item label="FC Code">
                  {getFieldDecorator("userId", {})(
                    <Select
                      notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                      showSearch
                      allowClear
                      filterOption={false}
                      onChange={handleModalFcCodeChange}
                      onSearch={fetchUserModal}
                    >
                      {fcCodeList2.map(elt => <Option key={elt.userId} value={elt.userId}>{elt.agentCode}</Option>)}
                    </Select>
                  )}
                </Form.Item>
                </Col>
                <Col {...flexCol2}>
                  <Form.Item label="Year">
                    {getFieldDecorator("effectYearId", {})(
                      <Select
                        showSearch
                        allowClear
                      >
                        {
                          grantYearList.map(elt => <Option key={elt.yearId} value={elt.yearId}>{elt.year}</Option>)
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </>)
          }
          <Col {...flexCol2}>
            <Form.Item label="Core Ethics">
              {getFieldDecorator("ethic", {
                initialValue: ethic
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Core R&R">
              {getFieldDecorator("randR", {
                initialValue: randR
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Supplementary CIS">
              {getFieldDecorator("cis", {
                initialValue: cis
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Supplementary A&H">
              {getFieldDecorator("aandH", {
                initialValue: aandH
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Other">
              {getFieldDecorator("others", {
                initialValue: others
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModelForm = Form.create()(Formes2);

const GrantHours = Form.create()(Formes);

export default GrantHours;
