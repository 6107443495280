import { combineReducers } from "redux";
import AddAttendanceReducer from "./AddAttendanceReducer";
import CourseFinderReducer from "./CourseFinderReducer";
import EditAttendanceReducer from "./EditAttendanceReducer";
import SessionDetailReducer from "./SessionDetailReducer";

export default combineReducers({
  AddAttendanceReducer,
  CourseFinderReducer,
  EditAttendanceReducer,
  SessionDetailReducer
});
