import React from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Empty,
  Row,
  Col,
  Divider,
  Popconfirm
} from "antd";
import { connect } from "react-redux";
import styles from "./SurveySearch.module.less";
import {
  queryData,
  handleSearchPageDelete,
  reSetGroupForSurvey,
  saveTempData,
  handleResetQueryData
} from "../../../../../redux/pacsReducer/systemSettingsReducers/SurveyReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pacsReducer: { systemSettingsReducers: { SurveyReducer } } }) => ({
    SurveyReducer
  }),
  {
    queryData,
    handleSearchPageDelete,
    reSetGroupForSurvey,
    saveTempData,
    handleResetQueryData
  }
)
class SurveySearch extends React.Component {
  state = {
    isShowTable: false,
    currentPageNumber: 1,
    columns: [
      {
        title: "Survey Name",
        dataIndex: "surveyName",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Survey Code",
        align: "center",
        dataIndex: "surveyCode"
      },
      {
        title: "Actions",
        align: "center",
        width: "240px",
        render: (text, record) => {
          const { pambSurveyTemplateId, isInUse } = record;
          const toEdit = {
            pathname: "/home/system_settings/survey_management/survey_edit",
            state: {
              pambSurveyTemplateId
            }
          };

          const toDetail = {
            pathname:
              "/home/system_settings/survey_management/survey_detail",
            state: {
              pambSurveyTemplateId
            }
          };

          return (
            <div>
              <span className={styles.spanCss} onClick={() => this.gotoEditpage(toDetail)}>View</span>
              {
                isInUse === '0' ?
                  <>
                    <Divider type="vertical" />
                    <span
                      className={styles.spanCss}
                      onClick={() => this.gotoEditpage(toEdit)}
                    >
                      Edit
                    </span>
                    <Divider type="vertical" />
                    <Popconfirm
                      title="Please double confirm?"
                      onConfirm={() => this.handleDelete({ pambSurveyTemplateId })}
                      okText="Yes"
                      cancelText="No"
                    >
                      <span className={styles.spanCss}>Delete</span>
                    </Popconfirm>
                  </>
                  : null
              }
            </div>
          );
        }
      }
    ]
  };

  async componentDidMount() {
    await this.props.saveTempData({ dontFresh: false });
    const { temporaryQueryData } = this.props.SurveyReducer
    if (temporaryQueryData.isShowTable) {
      await this.props.queryData({ ...temporaryQueryData })
    }
  }

  componentWillUnmount() {
    const { temporaryQueryData } = this.props.SurveyReducer
    if (!temporaryQueryData.dontFresh) {
      this.props.handleResetQueryData()
    }
  }

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const queryData = {
          ...values,
          currentPageNumber: 1,
          isShowTable: true
        }
        this.props.saveTempData({ isShowTable: true })
        this.props.queryData(queryData);
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const {
      temporaryQueryData
    } = this.props.SurveyReducer;

    this.props.queryData({
      ...temporaryQueryData,
      pageSize,
      currentPageNumber: current
    });
  };

  handleDelete = id => {
    const {
      SurveyReducer: {
        surveyData: { data: dataList, totalPage },
        temporaryQueryData
      }
    } = this.props;
    const { currentPageNumber } = temporaryQueryData
    let current = currentPageNumber;
    if (totalPage > 1 && dataList.length === 1) {
      current = currentPageNumber - 1;
    }

    const callback = () => {
      this.props.queryData({
        ...temporaryQueryData,
        currentPageNumber: current
      });
    };

    this.props.handleSearchPageDelete(id, callback);
  };

  gotoCreatepage = async () => {
    await this.props.saveTempData({ dontFresh: true })
    await this.props.reSetGroupForSurvey();
    await this.props.history.push(
      "/home/system_settings/survey_management/survey_create"
    );
  };

  gotoEditpage = async (data) => {
    await this.props.saveTempData({ dontFresh: true })
    await this.props.reSetGroupForSurvey();
    await this.props.history.push(data);
  };

  render() {
    const {
      form: { getFieldDecorator },
      SurveyReducer: {
        surveyData: { data: dataList, total },
        queryLoading,
        temporaryQueryData,
      }
    } = this.props;

    const {
      currentPageNumber, surveyName, surveyCode
    } = temporaryQueryData
    const { columns } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.topBox}>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Survey Name">
                  {getFieldDecorator("surveyName", {
                    initialValue: surveyName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Survey Code">
                  {getFieldDecorator("surveyCode", {
                    initialValue: surveyCode
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <div className={styles.btnBox}>
                  <Button className={styles.ant_btn} onClick={this.handleSearch}>
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

        <Button
          className={styles.btn}
          style={{ marginBottom: "20px" }}
          icon="plus"
          onClick={this.gotoCreatepage}
        >
          Add Survey
        </Button>
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total,
            pageSize: 10,
            current: currentPageNumber
          }}
          size="small"
          loading={queryLoading}
          onChange={this.handleTableChange}
          className={styles.ant_dev_table}
          rowKey="pambSurveyTemplateId"
        />
      </div>
    );
  }
}

const SurveySearchForm = Form.create()(SurveySearch);

export default SurveySearchForm;
