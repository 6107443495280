import React from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Empty,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Alert
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./UserManagementHome.module.less";
import { getMenuList, getMenuListForToken } from "../../../../../redux/homeReducer";
import {
  getUserList,
  downloadUserList,
  resendEmail,
  handleActivate,
  saveFields,
  handleRefrash,
  saveTemporaryData,
  resetPassword,
  clickExtension,
  getResetDefaultPassword,
  impersonate
} from "../../../../../redux/pacsReducer/systemSettingsReducers/userManagementReducer";
import { handleSplitUrl } from "../../../../../utils";
import { pwdFormat, pwdTips, impersonatorUser} from "../../../../../utils/global";
import { RED_TRIANGLE } from "../../../../../images";
import { showActionByRole } from "../../../../../utils/tableUtils"

const { Option } = Select;
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol24 = {
  span: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ userReducer, homeReducer, pacsReducer: { systemSettingsReducers: { userManagementReducer } } }) => ({
    homeReducer,
    userReducer,
    userManagementReducer
  }),
  {
    getMenuList,
    getMenuListForToken,
    getUserList,
    downloadUserList,
    resendEmail,
    handleActivate,
    saveFields,
    handleRefrash,
    saveTemporaryData,
    resetPassword,
    clickExtension,
    getResetDefaultPassword,
    impersonate
  }
)
class HomeForm extends React.Component {
  state = {
    visible: false,
    extensionModal: false,
    resetData: {},
    columns: []
  };

  componentDidMount() {
    const {
      homeReducer: { buttonList },
    } = this.props;

    const { rolerList } = this.props.userManagementReducer;

    let columns = this.state.columns
    if (rolerList && rolerList[0]) {
      const roleId = this.props.form.getFieldValue('roleId')
      columns = this.getColumn(roleId || rolerList[0].roleid, rolerList)
      this.setState({
        columns
      })
    }

    if (!buttonList.length) {
      const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
      this.props.getMenuList(tabKey, sideKey);
    }

    const { noRefrash } = this.props.userManagementReducer;
    // const { columns } = this.state;
    if (noRefrash) {
      const {
        usefulTemporaryData: { buCode, buRole, searchedData }
      } = this.props.userManagementReducer;
      this.props.getUserList(
        {
          ...searchedData
        },
        buRole
      );
      this.props.handleRefrash(false);
      this.handleColumns({ columns, buCode, buRole });
    } else {
      this.handleInitSearchData();
    }
  }

  getColumn = (roleId, rolerList) => {
    const showAction = showActionByRole(roleId, rolerList);
    return [
      {
        title: "Name",
        dataIndex: "userName",
        ellipsis: true,
        align: "center",
        render: (text, record) => {
          const { userid, userId, userName, username, roleCode } = record;
          const {
            buRole,
            searchedData: { roleId },
            roleName,
            buCode
          } = this.props.userManagementReducer.usefulTemporaryData;
          const pathname = handleGotoDetail(buCode);
          return (
            <span onClick={() => this.props.handleRefrash(true)} title={username || userName}>
              <Link
                to={{
                  pathname,
                  state: {
                    userId: userid || userId,
                    bu: buRole,
                    roleId,
                    roleName: roleCode === 'PACS AGENT LEADER' ? 'PACS Agent Leader' : roleName
                  }
                }}
              >
                {username || userName}
              </Link>
            </span>
          );
        }
      },
      {
        title: "Role",
        align: "center",
        dataIndex: "roleCode",
        render: (text, record) => {
          const {
            roleName
          } = this.props.userManagementReducer.usefulTemporaryData;
          const { roleCode } = record
          if (roleCode === 'PACS AGENT LEADER') {
            return <span>PACS Agent Leader</span>;
          } else if (roleCode === 'PACS AGENT') {
            return <span>PACS Agent</span>;
          } else {
            return <span>{roleName}</span>;
          }
        }
      },
      {
        title: "Login Account",
        align: "center",
        dataIndex: "loginAccount",
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        align: "center",
        render: text => (
          <span>{text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : ""}</span>
        )
      },
      {
        title: "Creation Date",
        align: "center",
        dataIndex: "createTime",
        render: (text, record) => {
          const { createTime, createtime } = record;
          const time = createTime || createtime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}</span>
          );
        }
      },
      {
        title: "Last Update Date",
        align: "center",
        dataIndex: "updateTime",
        render: (text, record) => {
          const { updateTime, updatetime } = record;
          const time = updateTime || updatetime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD HH:mm:ss") : ""}</span>
          );
        }
      },
      {
        title: "Lock Status",
        align: "center",
        dataIndex: "lockStatus",
        render: (text) => {
          let isLock = ''
          if (text === '0') {
            isLock = 'No'
          } else if (text === '1') {
            isLock = "Yes"
          }
          return <span>{isLock}</span>
        }
      },
      {
        title: "Expired Date",
        align: "center",
        dataIndex: "expiredDate",
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
        render: text => {
          const statusArray = ["NoLogin", "Inactive", "Active"];
          return <span>{statusArray[text]}</span>;
        }
      },
      {
        title: "Actions",
        align: "center",
        // width: "240px",
        render: (text, record) => {
          const {
            status,
            loginaccount,
            loginAccount,
            userid,
            userId,
            username,
            userName,
          } = record;
          const {
            userManagementReducer: {
              usefulTemporaryData: {
                buRole,
                buCode
              }
            },
            userReducer: {
              roleid
            }
          } = this.props
          const { roleCode } = record;
          const isStatus0 = status === "0";
          const isInactive = status === "1";
          const isActivate = status === '1' ? 'Activate' : 'Deactivate'
          const isExtension = buCode === 'PACSGUEST'
          const isPacsLbuAdmin = roleid === '6'
          const isAbleImpersonate = ((roleCode === 'PACS AGENT LEADER') || (roleCode === 'PACS AGENT') || (roleCode === 'CANDIDATE')) && !isInactive;
          const isAnyButton = showAction || (isPacsLbuAdmin && isExtension)
          const menu = (
            <Menu>
              {isAbleImpersonate ?
                (
                  <Menu.Item onClick={() =>
                    this.handleImpersonate(
                      {
                        loginAccount: loginaccount || loginAccount,
                      },
                      roleid
                    )
                  }
                  >
                    Impersonate
                  </Menu.Item>
                )
                : null
              }
              {showAction && isPacsLbuAdmin ?
                (
                  <Menu.Item onClick={() =>
                    this.handleShowModal(
                      {
                        userName: username || userName,
                        loginAccount: loginaccount || loginAccount,
                      }
                    )
                  }
                  >
                    Reset Password
                  </Menu.Item>
                )
                : null
              }
              {
                (isPacsLbuAdmin && isExtension) ? (
                  <Menu.Item onClick={() =>
                    this.handleShowExtensionModal(
                      { userId: userid || userId }
                    )
                  }
                  >
                    Extension
                  </Menu.Item>
                ) : null
              }
              {
                showAction ? <Menu.Item onClick={() =>
                  this.handleClickActivate(
                    { userId: userid || userId },
                    buRole,
                    isActivate
                  )
                }
                >
                  {isActivate}
                             </Menu.Item> : null
              }
              {
                showAction && isStatus0 ?
                  (
                    <Menu.Item
                      onClick={() =>
                        this.props.resendEmail(
                          {
                            loginAccount: loginaccount || loginAccount,
                            subject: "init_password"
                          },
                          buRole
                        )
                      }
                    >
                      Resend Email
                    </Menu.Item>
                  )
                  : null
              }
            </Menu>
          )
          return (
            isAnyButton && <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomCenter"
              overlayClassName={styles.ant_dev_dropdown}
            >
              <span className={styles.spanCss}>
                <span className={styles.text}>More</span>
                <Icon type="caret-down" style={{ color: '#1890ff' }} />
              </span>
                           </Dropdown>
          )
        }
      }
    ]
  };

  handleDownload = e => {
    e.preventDefault();
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          tempBuRole,
          roleName
        } = this.props.userManagementReducer.usefulTemporaryData;
        const valuesData = {};
        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else {
            valuesData[item] = elt;
          }
        });
        const submitData = {
          ...valuesData,
        };
        this.props.downloadUserList({ ...submitData }, tempBuRole, roleName);
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          tempBuRole,
          tempRoleName,
          tempBuCode
        } = this.props.userManagementReducer.usefulTemporaryData;
        const {
          rolerList
        } = this.props.userManagementReducer
        // const { columns } = this.state;
        const columns = this.getColumn(values.roleId, rolerList)
        this.setState({
          columns
        })
        const valuesData = {};
        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else {
            valuesData[item] = elt;
          }
        });
        const { roleId } = values
        const submitData = {
          ...valuesData,
          currentPageNumber: 1,
        };

        this.props.getUserList({ ...submitData }, tempBuRole);
        this.props.saveTemporaryData({
          roleId,
          searchedData: { ...submitData },
          roleName: tempRoleName,
          buCode: tempBuCode,
          buRole: tempBuRole,
          isShowTable: true
        });
      }
    });
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  handleColumns = () => { };

  handleShowModal = (resetData) => {
    const { buRole } = this.props.userManagementReducer.usefulTemporaryData;
    this.setState({
      resetData,
      visible: true,
    });
    this.props.getResetDefaultPassword(buRole)
  };

  handleImpersonate = (resetData, roleid) => {
    // call API, Set SessionStorage value; Window.reload
    const callback = (data) => {
      console.log(data);
      const {token, refreshToken, country = 'PACS'} = data;
      console.log(resetData);
      impersonatorUser(country.toLowerCase(), token, refreshToken, country, roleid);
      const homeCallback = () => {
        this.props.history.replace("/");
        window.location.reload();
      }
      this.props.getMenuListForToken(homeCallback);
    };
    this.props.impersonate({}, resetData, callback);
  }

  handleShowExtensionModal = (resetData) => {
    this.setState({
      resetData,
      extensionModal: true,
    });
  };

  handleResetPassword = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { searchedData, buRole } = this.props.userManagementReducer.usefulTemporaryData;
        const { loginAccount } = this.state.resetData
        const { password } = values
        const submitData = {
          password,
          loginAccount,
        }
        const callback = () =>
          this.props.getUserList(
            { ...searchedData },
            buRole
          );
        this.props.resetPassword(submitData, buRole, callback);
        this.setState({
          resetData: {},
          visible: false
        });
        form.resetFields()
      }
    });
  };

  handleExtension = () => {
    const { searchedData, buRole, roleId } = this.props.userManagementReducer.usefulTemporaryData;
    const { userId } = this.state.resetData
    const callback = () =>
      this.props.getUserList(
        { ...searchedData },
        buRole
      );
    this.props.clickExtension({ roleId, userId }, callback);
    this.setState({
      resetData: {},
      extensionModal: false
    });
  };

  handleClickActivate = (payload, buRole, type) => {
    const {
      searchedData
    } = this.props.userManagementReducer.usefulTemporaryData;
    const callback = () =>
      this.props.getUserList(
        { ...searchedData },
        buRole
      );
    this.props.handleActivate(payload, buRole, type, callback);
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { buRole, searchedData } = this.props.userManagementReducer.usefulTemporaryData;

    this.props.saveTemporaryData({
      searchedData: {
        ...searchedData,
        currentPageNumber: current
      }
    });

    this.props.getUserList(
      {
        ...searchedData,
        pageSize,
        currentPageNumber: current
      },
      buRole
    );
  };

  handleCancelResetPwd = (props) => {
    props.form.resetFields()
    this.setState({ resetData: {}, visible: false });
  };

  handleCancelExtension = () => {
    this.setState({ resetData: {}, extensionModal: false });
  };

  handleRoleChange = (val, option) => {
    const {
      props: { bu, name, code }
    } = option;
    console.log("val:", val, code) // eslint-disable-line
    if (bu === "ADMIN") {
      this.props.saveTemporaryData({
        isShowTable: false,
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showLeaderName: false,
        showUnitCode: false,
        showFcCode: false,
        showChannel: false,
        showLoginAccount: true,
        showEmail: true,
        showDownload: false,
        showNNE: false,
        showPdCohortName: false,
        showNRIC: false
      });
    } else if (bu === "PACS") {
      this.props.saveTemporaryData({
        isShowTable: false,
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showFcCode: code === 'PACSAgent' || code === 'PACSAgentLeader',
        showUnitCode: false,
        showLeaderName: false,
        showNRIC: code === 'PACSAgent' || code === 'PACSGUEST' || code === 'PACSCandidate',
        showChannel: code === 'PACSAgent' || code === 'PACSCandidate',
        showLoginAccount: code === 'PACSCandidate' || code === 'PACSCandidate' || code === 'PACSTrainer' || code === 'PACSDailyCoachingTrainer' || code === 'PACSAgent' || code === 'PACSGUEST',
        showEmail: code === 'PACSTrainer' || code === 'PACSDailyCoachingTrainer' || code === 'PACSAgent' || code === 'PACSCandidate' || code === 'PACSGUEST',
        showDownload: code === 'PACSAgent' || code === 'PACSGUEST' || code === 'PACSCandidate',
        showNNE: code === 'PACSAgent',
        showPdCohortName: code === 'PACSCandidate'
      });
    }
    this.handleSaveField("roleId", val);
  };

  handleResetForm = () => {
    this.handleInitSearchData();
    this.props.form.resetFields();
  };

  handleInitSearchData = () => {
    const {
      userManagementReducer: { rolerList },
      userReducer: { roleid }
    } = this.props;
    const rolerListLength = rolerList[0] || {}
    const roleId = rolerListLength.roleid;
    const buRole = rolerListLength.bu;
    const roleName = rolerListLength.name;
    const buCode = rolerListLength.code;
    let showLeaderName = false;
    let showUnitCode = false;
    let showFcCode = false;
    let showChannel = false;
    let showLoginAccount = false;
    let showEmail = false;
    let showDownload = false;
    let showNRIC = false;
    let showNNE = false;
    let showPdCohortName = false;

    if (buRole === "PACS") {
      if (buCode === 'PACSAgent') {
        showFcCode = true
        showChannel = true
        showNRIC = true
        showNNE = true
        showEmail = true
        showDownload = true
        showLoginAccount = true
      }

      if (buCode === 'PACSAgentLeader') {
        showFcCode = true
      }

      if (buCode === 'PACSCandidate') {
        showChannel = true
        showLoginAccount = true
        showPdCohortName = true
        showEmail = true
        showDownload = true
        showNRIC = true
      }

      if (buCode === 'PACSGUEST') {
        showEmail = true
        showDownload = true
        showNRIC = true
        showLoginAccount = true
      }

      if (buCode === 'PACSTrainer' || buCode === 'PACSDailyCoachingTrainer') {
        showLoginAccount = true
        showEmail = true
      }

      showUnitCode = true
      showLeaderName = true;
    }

    if (buRole === "ADMIN") {
      showLoginAccount = true
      showEmail = true
    }

    const queryData = {
      roleId, currentPageNumber: 1, channel: this.handleChannelInit(roleid)
    }

    // this.props.getUserList(queryData, buRole);
    this.props.saveTemporaryData({
      roleId,
      buRole,
      tempBuRole: buRole,
      roleName,
      tempRoleName: roleName,
      buCode,
      tempBuCode: buCode,
      showLeaderName,
      showUnitCode,
      showFcCode,
      showChannel,
      showLoginAccount,
      showNNE,
      showNRIC,
      showEmail,
      showDownload,
      showPdCohortName,
      searchedData: queryData,
      fieldsData: {}
    });
  };

  handleChannelInit = (roleid, channel) => {
    if (roleid === '3') {
      return "AD"
    } else if (roleid === '17') {
      return 'PD'
    } else {
      return channel || ''
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      homeReducer: { buttonList },
      userReducer: { roleid },
      userManagementReducer: {
        rolerList,
        userData: { userList, total, pageSize },
        tableLoading,
        userStatusList = [],
        channelList = [],
        defaultPassword,
        usefulTemporaryData: {
          searchedData: { currentPageNumber },
          showLeaderName,
          showUnitCode,
          fieldsData,
          showFcCode,
          showChannel,
          showLoginAccount,
          showNNE,
          showNRIC,
          showEmail,
          showPdCohortName,
          showDownload,
          isShowTable,
          roleName
        },
      }
    } = this.props;
    const { columns, visible, resetData, extensionModal } = this.state;
    const { importList, createList } = handleButtonList(buttonList);
    const channelInit = this.handleChannelInit(roleid, fieldsData.channel)
    console.log("this.props user:", this.props) // eslint-disable-line
    return (
      <div className={styles.container}>
        <div className={styles.actionWrap}>
          <Row gutter={[20, 10]} type="flex">
            {importList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Import" }}
                  className={styles.buttonBox}
                  defaultActiveFirstOption={false}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {importList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}

            {createList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Create" }}
                  className={styles.buttonBox}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  defaultActiveFirstOption={false}
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {createList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}
          </Row>
        </div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("roleId", {
                  initialValue:
                    fieldsData.roleId || (rolerList[0] && rolerList[0].roleid)
                })(
                  <Select
                    placeholder="Select a option"
                    onChange={this.handleRoleChange}
                  >
                    {rolerList.map(elt => (
                      <Option
                        value={elt.roleid}
                        key={elt.roleid}
                        bu={elt.bu}
                        name={elt.name}
                        code={elt.code}
                      >
                        {elt.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Name">
                {getFieldDecorator("userName", {
                  initialValue: fieldsData.userName
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("userName", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            {
              showFcCode ? (
                <Col {...flexCol}>
                  <Form.Item label="FC Code">
                    {getFieldDecorator("agentCode", {
                      initialValue: fieldsData.agentCode
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("agentCode", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }
            {showUnitCode ? (
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: fieldsData.unitCode
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("unitCode", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showLeaderName ? (
              <Col {...flexCol}>
                <Form.Item label="Leader Name">
                  {getFieldDecorator("leaderName", {
                    initialValue: fieldsData.leaderName
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("leaderName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>

            ) : null}
            <Col {...flexCol}>
              <Form.Item label="User Status">
                {getFieldDecorator("status", {
                  initialValue: fieldsData.status || ''
                })(
                  <Select
                    onChange={e =>
                      this.handleSaveField("status", e)
                    }
                    allowClear
                  >
                    <Option value="">All</Option>
                    {
                      userStatusList.map((elt) => {
                        return (
                          <Option key={elt.value} value={elt.value}>{elt.code}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>

            {showChannel ? (
              <Col {...flexCol}>
                <Form.Item label="Channel">
                  {getFieldDecorator("channel", {
                    initialValue: channelInit
                  })(
                    <Select
                      onChange={e =>
                        this.handleSaveField("channel", e)
                      }
                      allowClear
                      disabled={roleid === '3' || roleid === '17'}
                    >
                      <Option value=''>All</Option>
                      {
                        channelList.map((elt) => {
                          return (
                            <Option key={elt.value} value={elt.value}>{elt.code}</Option>
                          )
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showLoginAccount ? (
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginAccount", {
                    initialValue: fieldsData.loginAccount
                  })(<Input onChange={e =>
                    this.handleSaveField("loginAccount", e.target.value)
                  }
                  />)}
                </Form.Item>
              </Col>
            ) : null}

            {showNRIC ? (
              <Col {...flexCol}>
                <Form.Item label="NRIC">
                  {getFieldDecorator("nric", {
                    initialValue: fieldsData.nric
                  })(<Input onChange={e =>
                    this.handleSaveField("nric", e.target.value)
                  }
                  />)}
                </Form.Item>
              </Col>
            ) : null}

            {showNNE ? (
              <Col {...flexCol}>
                <Form.Item label="NNE Indicator">
                  {getFieldDecorator("nne", {
                    initialValue: fieldsData.nne || ''
                  })(
                    <Select onChange={e =>
                      this.handleSaveField("nne", e)
                    }
                    >
                      <Option value="">All</Option>
                      <Option value="Y">Yes</Option>
                      <Option value="N">No</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showPdCohortName ? (
              <Col {...flexCol}>
                <Form.Item label="PD Cohort Name">
                  {getFieldDecorator("pdCohortName", {
                    initialValue: fieldsData.pdCohortName
                  })(<Input onChange={e =>
                    this.handleSaveField("pdCohortName", e.target.value)
                  }
                  />)}
                </Form.Item>
              </Col>
            ) : null}

            {showEmail ? (
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: fieldsData.email
                  })(<Input onChange={e =>
                    this.handleSaveField("email", e.target.value)
                  }
                  />)}
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
        <div className={styles.serachBox}>
          <Button onClick={this.handleSubmit} className={styles.ant_btn}>
            Search
          </Button>
          {/* <Button
            onClick={this.handleResetForm}
            className={styles.ant_btn_reset}
          >
            Reset
          </Button> */}
        </div>
        {
          isShowTable && showDownload ? (
            <Button
              className={styles.btn}
              onClick={this.handleDownload}
            >
              Download {roleName}
            </Button>
          ) : null
        }
        <div className={styles.table}>
          {
            isShowTable ?
              <Table
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
                columns={columns}
                dataSource={userList}
                pagination={{
                  total,
                  pageSize,
                  current: currentPageNumber
                }}
                size="small"
                loading={tableLoading}
                onChange={this.handleTableChange}
                className={styles.ant_dev_table}
                rowKey={(rowInfo) => rowInfo.userid || rowInfo.userId}
                scroll={userList.length >= 7 ? { y: 400 } : {}}
              />
              : null
          }
        </div>

        <ModelForm
          handleOk={this.handleResetPassword}
          handleCancelResetPwd={this.handleCancelResetPwd}
          visible={visible}
          resetData={resetData}
          defaultPassword={defaultPassword}
        />

        <Modal
          title=''
          visible={extensionModal}
          onOk={this.handleExtension}
          onCancel={this.handleCancelExtension}
          okText='Yes'
          cancelText='No'
          okType='danger'
        >
          Are you sure you would like to extend this User?
        </Modal>
      </div>
    );
  }
}

function handleButtonList(list) {
  const importList = [];
  const createList = [];
  list.map(item => {
    if (item.group === "import") {
      importList.push(item);
    } else if (item.group === "create") {
      createList.push(item);
    }
  });
  return { importList, createList };
}

const UserManagementHome = Form.create({ name: "homePage" })(HomeForm);

export default UserManagementHome;

function handleGotoDetail(buCode) {
  switch (buCode) {
    case "PACSCandidate":
      return "/home/system_settings/user_management/candidate_detail";
    case "PACSGUEST":
      return "/home/system_settings/user_management/guest_detail";
    case "PACSAdmin":
      return "/home/system_settings/user_management/lbu_admin_detail";
    case "PACSCourseAdmin":
      return "/home/system_settings/user_management/course_admin_detail";
    case "PACSADCandidateAdmin":
      return "/home/system_settings/user_management/ad_candidate_admin_detail";
    case "PACSPDCandidateAdmin":
      return "/home/system_settings/user_management/pd_candidate_admin_detail";
    default:
      return '/home/system_settings/user_management/pacs_detail';
  }
}

function Formes2(props) {
  const { form: { getFieldDecorator }, visible, resetData, handleOk, defaultPassword, handleCancelResetPwd } = props
  return (
    <Modal
      title="Reset Password"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancelResetPwd(props)}
      okText='Reset'
      okType='danger'
    >
      <Form
        colon={false}
        {...formLayout}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol24}>
            <Form.Item label="User Name">
              {getFieldDecorator("userName", {
                initialValue: resetData.userName
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: pwdFormat,
                    message: "You have entered an invalid password"
                  }
                ],
                initialValue: defaultPassword
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Alert message={pwdTips} type="info" />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModelForm = Form.create()(Formes2);
