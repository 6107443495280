import React from "react";
import { connect } from "react-redux";
import {
  getBuList,
  getRolerList,
  getAllRolerList,
  getAllRoleList,
  getUserStatusList,
} from "../../../redux/regionalReducer/systemSettingsReducers/userManagementReducer";

@connect(
  state => state,
  {
    getBuList,
    getRolerList,
    getAllRolerList,
    getAllRoleList,
    getUserStatusList
  }
)
class RegionalHome extends React.Component {
  componentDidMount() {
    this.props.getBuList();
    this.props.getRolerList();
    this.props.getAllRolerList();
    this.props.getUserStatusList();
    this.props.getAllRoleList();
  }

  render() {
    return null;
  }
}

export default RegionalHome;
