import React, { Component } from "react";
import { Spin } from "antd";
import withConnector from '../connector';
import CustomList from '../../../../components/CustomList';
import { getSearchFileds } from './searchFileds';
import { getColumns } from './columns';

@withConnector
class TrainingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerTitle: 'Training History',
      keyword: 'trainingHistroy',
      fetchUrl: '/trainingHistory/getTrainingHistory',
      searchFileds: [],
      columns: [],
      needTimestamp: false,
      loading: false,
      downloadUrl: '/trainingHistory/downloadTrainingHistory',
      downloadFileName: 'traning_history.xls',
      rowKey: 'name'
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await this.props.getUserRoleList();
    const { rolerList } = this.props.userManagementReducer;

    this.setState({
      searchFileds: getSearchFileds(rolerList),
      columns: getColumns(),
      loading: false
    });
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="training-list">
        <Spin spinning={loading}>
          <CustomList
            {...this.state}
          />
        </Spin>
      </div>
    )
  }
}

export default TrainingList;
