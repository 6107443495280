import { combineReducers } from "redux";
import systemSettingsReducers from "./systemSettingsReducers";
import CPDSettingsReducers from "./CPDSettingsReducers";
import myProfileReducers from "./myProfileReducers";
import attendanceReducers from "./attendanceReducers";
import attendanceReportReducers from "./attendanceReportReducers";
import CPDReportReducers from "./CPDReportReducers";
import registrationReducers from './registrationReducers';
import mySessionReducers from "./mySessionReducers";

export default combineReducers({
  systemSettingsReducers,
  CPDSettingsReducers,
  myProfileReducers,
  attendanceReducers,
  CPDReportReducers,
  registrationReducers,
  mySessionReducers,
  attendanceReportReducers,
});
