import React from "react";
import { globalUrlPre } from "../utils/config";

// import Login from "../views/user/Login";
import Home from "../views/home";
import ForgetPassword from "../views/user/ForgetPassword";

import Forbidden from "../views/exception/Forbidden";
import NotFound from "../views/exception/NotFound";
import ServerError from "../views/exception/ServerError";

// systemSettings
import SystemSettings from "../views/home/pcla/systemSettings";
import UserManagement from "../views/home/pcla/systemSettings/userManagement";
import UserManagementHome from "../views/home/pcla/systemSettings/userManagement/UserManagementHome";

import ImportCandidates from "../views/home/pcla/systemSettings/userManagement/ImportCandidates";
import ImportAgent from "../views/home/pcla/systemSettings/userManagement/ImportAgent";
import ImportGuests from "../views/home/pcla/systemSettings/userManagement/ImportGuests";

import CreateRegionAdmin from "../views/home/pcla/systemSettings/userManagement/CreateRegionAdmin";
import CreateLBUAdmin from "../views/home/pcla/systemSettings/userManagement/CreateLBUAdmin";
import CreateCourseAdmin from "../views/home/pcla/systemSettings/userManagement/CreateCourseAdmin";
import CreateCandidateAdminAD from "../views/home/pcla/systemSettings/userManagement/CreateCandidateAdminAD";
import CreateCandidateAdminPD from "../views/home/pcla/systemSettings/userManagement/CreateCandidateAdminPD";
import CreateCandidateAD from "../views/home/pcla/systemSettings/userManagement/CreateCandidateAD";
import CreateCandidatePD from "../views/home/pcla/systemSettings/userManagement/CreateCandidatePD";
import CreateGuest from "../views/home/pcla/systemSettings/userManagement/CreateGuest";
import ImportDcmsFile from "../views/home/pcla/systemSettings/userManagement/ImportDcmsFile";
import CandidateDetail from "../views/home/pcla/systemSettings/userManagement/CandidateDetail";

import PCLAAgentDetail from "../views/home/pcla/systemSettings/userManagement/PCLAAgentDetail";

import AdminDetail from "../views/home/pcla/systemSettings/userManagement/AdminDetail";
import GuestDetail from "../views/home/pcla/systemSettings/userManagement/GuestDetail";
import AdminDetailLBU from "../views/home/pcla/systemSettings/userManagement/AdminDetailLBU";
import AdminDetailCourse from "../views/home/pcla/systemSettings/userManagement/AdminDetailCourse";
import CandidateAdminADDetail from "../views/home/pcla/systemSettings/userManagement/CandidateAdminADDetail";
import CandidateAdminPDDetail from "../views/home/pcla/systemSettings/userManagement/CandidateAdminPDDetail";
import ResetPassword from "../views/home/pcla/systemSettings/userManagement/ResetPassword";

import SurveyManagement from "../views/home/pcla/systemSettings/SurveyManagement";
import SurveySearch from "../views/home/pcla/systemSettings/SurveyManagement/SurveySearch";
import SurveyCreate from "../views/home/pcla/systemSettings/SurveyManagement/SurveyCreate";
import SurveyDetail from "../views/home/pcla/systemSettings/SurveyManagement/SurveyDetail";

import QuestionGroup from "../views/home/pcla/systemSettings/QuestionGroup";
import QuestionGroupSearch from "../views/home/pcla/systemSettings/QuestionGroup/QuestionGroupSearch";
import QuestionrGroupDetail from "../views/home/pcla/systemSettings/QuestionGroup/QuestionrGroupDetail";
import QuestionrGroupEdit from "../views/home/pcla/systemSettings/QuestionGroup/QuestionrGroupEdit";
import QuestionrGroupCreate from "../views/home/pcla/systemSettings/QuestionGroup/QuestionrGroupCreate";
import AddQuestionGroup from "../views/home/pcla/systemSettings/QuestionGroup/AddQuestionGroup";

// myProfile
import MyProfileSetting from "../views/home/pcla/myProfile";
import ChangePassword from "../views/home/regional/myProfile/ChangePassword";
import MyProfile from "../views/home/pcla/myProfile/myProfile";
import AgentPACS from "../views/home/pcla/myProfile/Agent_PACS";
import CPDDashboard from "../views/home/pcla/myProfile/CPDDashboard";

// CPDSettings

import CPDSettings from "../views/home/pcla/CPDSettings";
import CpdRequirement from "../views/home/pcla/CPDSettings/CpdRequirement";
import RequirementSettingPacsEdit from "../views/home/pcla/CPDSettings/RequirementSettingPacsEdit";
import PacsRequireSettings from "../views/home/pcla/CPDSettings/PacsIndex";
import RequirementSettingPacs from "../views/home/pcla/CPDSettings/RequirementSettingPacs";
import ShiftInOut from "../views/home/pcla/CPDSettings/ShiftInOut";
import GrantHours from "../views/home/pcla/CPDSettings/GrantHours";
import RequirementSettingPambCreate from "../views/home/pcla/CPDSettings/RequirementSettingPambCreate";

// attendance
import QRCodePage from "../views/home/pcla/attendance/QRCode";
import AddAttendance from "../views/home/pcla/attendance/AddAttendance";
import CourseFinder from "../views/home/pcla/attendance/CourseFinder";
import EditAttendance from "../views/home/pcla/attendance/EditAttendance";
import SessionDetail from "../views/home/pcla/attendance/SessionDetail";

import Attendance from "../views/home/pcla/attendance";

//  CPDReports
import CPDReports from "../views/home/pcla/CPDReports";
import ManagementReport from "../views/home/pcla/CPDReports/ManagementReport";
import DeductionReport from "../views/home/pcla/CPDReports/DeductionReport";
import AttendedSummary from "../views/home/pcla/CPDReports/AttendedSummary";
import TrainingMaterialRating from "../views/home/pcla/CPDReports/TrainingMaterialRating";
import TrainerFeedbackRating from "../views/home/pcla/CPDReports/TrainerFeedbackRating";
import OverallFeedbackRating from "../views/home/pcla/CPDReports/OverallFeedbackRating";
import TeachingDaysReport from "../views/home/pcla/CPDReports/TeachingDaysReport";
import AttendanceSummary from "../views/home/pcla/CPDReports/AttendanceSummary";
import RegistrationSummary from "../views/home/pcla/CPDReports/RegistrationSummary";
import RegistrationAttendance from "../views/home/pcla/CPDReports/RegistrationAttendance";
import ManagementReportPACS from "../views/home/pcla/CPDReports/ManagementReportPACS";
import TrainingHistoryReport from "../views/home/pcla/CPDReports/TrainingHistoryReport";
import CourseRatingSummaryReportPACS from "../views/home/pcla/CPDReports/CourseRatingSummaryReportPACS";
import CourseRatingSummaryReportDetailPACS from "../views/home/pcla/CPDReports/CourseRatingSummaryReportDetailPACS";

// Reminder rule
import ReminderRule from "../views/home/pcla/systemSettings/reminderRule";
import ReminderList from "../views/home/pcla/systemSettings/reminderRule/ReminderList";
import ReminderDetail from "../views/home/pcla/systemSettings/reminderRule/ReminderDetail";

// Daily Coaching
import DailyCoaching from "../views/home/pcla/systemSettings/dailyCoaching";
import DailyCoachingSearch from "../views/home/pcla/systemSettings/dailyCoaching/DailyCoachingSearch";
import EditCoachingCourse from "../views/home/pcla/systemSettings/dailyCoaching/EditCoachingCourse";
import SelectCourse from "../views/home/pcla/systemSettings/dailyCoaching/SelectCourse";
import SelectTrainer from "../views/home/pcla/systemSettings/dailyCoaching/SelectTrainer";
import SelectAgentLeader from "../views/home/pcla/systemSettings/dailyCoaching/SelectAgentLeader";

// LA Report
import LAReport from "../views/home/pcla/systemSettings/LAReport";

import SessionDetailPCLA from "../views/home/pcla/attendance/SessionDetailPCLA";
import PCLAAgentTrainingCode from "../views/home/pcla/systemSettings/LAReport/PCLAAgentTrainingCode"


/**
 * hasSecondRoute: If the first level of menu has a second level of sidebar routing, this attribute should be added. The main function is to dynamically load the first sidebar menu
 * redirctPath: The back-end returned path, but it is not the final route, which needs to be redirected
 * customDevPath: The back-end does not returned path, but the front-end local needs to configure the redirected route
 * initialRoute: The back-end does not returned path，it is Locally initialized route
 */
const RouteConfig = [
  {
    title: "home",
    path: "/home",
    component: Home,
    initialRoute: true,
    children: [
      {
        id: "cpd_reports",
        title: "Las Back-stage management",
        path: "/home/cpd_reports",
        component: CPDReports,
        hasSecondRoute: true,
        children: [
          {
            id: "cpd_reports_managementreport",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_managementreport",
            exact: true,
            component: ManagementReport
          },
          {
            id: "cpd_reports_attendedsummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_attendedsummary",
            exact: true,
            component: AttendedSummary
          },
          {
            id: "cpd_reports_deductionreport",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_deductionreport",
            exact: true,
            component: DeductionReport
          },
          {
            id: "cpd_reports_attendancesummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_attendancesummary",
            exact: true,
            component: AttendanceSummary
          },
          {
            id: "cpd_reports_registrationSummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_registrationSummary",
            exact: true,
            component: RegistrationSummary
          },
          {
            id: "cpd_reports_registrationAttendance",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_registrationAttendance",
            exact: true,
            component: RegistrationAttendance
          },
          {
            id: "cpd_reports_managementReport_pacs",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_managementReport_pacs",
            exact: true,
            component: ManagementReportPACS
          },
          {
            id: "cpd_reports_courseratingsummaryReport_pacs",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_courseratingsummaryReport_pacs",
            exact: true,
            component: CourseRatingSummaryReportPACS
          },
          {
            id: "cpd_reports_courseratingsummaryReportDetail_pacs",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_courseratingsummaryReportDetail_pacs",
            customDevPath: true,
            component: CourseRatingSummaryReportDetailPACS
          },
          {
            id: "training_history_report",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/training_history_report",
            exact: true,
            component: TrainingHistoryReport
          },
          {
            id: "audit_log",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/audit_log",
            component: () => <div>audit_log</div>
          },
          {
            id: "system_log",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/system_log",
            component: () => <div>system_log</div>
          },
          {
            id: "training_material_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/training_material_rating",
            component: TrainingMaterialRating
          },
          {
            id: "trainer_feedback_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/trainer_feedback_rating",
            component: TrainerFeedbackRating
          },
          {
            id: "overall_feedback_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/overall_feedback_rating",
            component: OverallFeedbackRating
          },
          {
            id: "teaching_days_report",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/teaching_days_report",
            component: TeachingDaysReport
          }
        ]
      },
      {
        id: "attendance",
        title: "Las Back-stage management",
        path: "/home/attendance",
        hasSecondRoute: true,
        redirctPath: "/home/attendance/course_finder",
        component: Attendance, // Attendance,
        children: [
          {
            id: "course_finder",
            title: "Las Back-stage management",
            path: "/home/attendance/course_finder",
            component: () => <CourseFinder />
          },
          {
            id: "session_detail",
            title: "Las Back-stage management",
            path: "/home/attendance/session_detail",
            component: () => {
              if (sessionStorage.getItem("buCountry") === "PCLA" || sessionStorage.getItem("prefix") === "pcla") {
                return <SessionDetailPCLA />
              } else {
                return <SessionDetail />
              }
            }
          },
          {
            id: "add_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/add_attendance",
            customDevPath: true,
            component: () => <AddAttendance />
          },
          {
            id: "edit_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/edit_attendance",
            customDevPath: true,
            component: () => <EditAttendance />
          },
          {
            id: "qrcode_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/qrcode_attendance",
            customDevPath: true,
            component: QRCodePage
          }
        ]
      },
      {
        id: "my_profile_setting",
        title: "Las Back-stage management",
        path: "/home/my_profile_setting",
        hasSecondRoute: true,
        component: MyProfileSetting,
        children: [
          {
            id: "my_profile_admin",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/my_profile",
            component: () => <MyProfile />
          },
          {
            id: "pacs_agent",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/pacs_agent",
            component: () => <AgentPACS />
          },
          {
            id: "cpd_dashboard",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/cpd_dashboard",
            component: () => <CPDDashboard />
          },
          {
            id: "change_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/change_password",
            component: () => <ChangePassword />
          },
          {
            id: "forget_prubehere_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/forget_prubehere_password",
            component: () => <ForgetPassword />
          }
        ]
      },
      {
        id: "system_settings",
        title: "Las Back-stage management",
        path: "/home/system_settings",
        component: SystemSettings,
        hasSecondRoute: true,
        children: [
          {
            id: "user_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/user_management",
            redirctPath: "/home/system_settings/user_management/index",
            component: UserManagement,
            children: [
              {
                id: "user_management_index",
                customDevPath: true,
                path: "/home/system_settings/user_management/index",
                component: UserManagementHome,
                exact: true
              },
              {
                id: "import_candidates",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/import_candidates",
                component: ImportCandidates,
                exact: true
              },
              {
                id: "import_agents",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/import_agents",
                component: ImportAgent,
                exact: true
              },
              {
                id: "import_guests",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/import_guests",
                component: ImportGuests,
                exact: true
              },
              {
                id: "create_lbu_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_region_admin",
                component: CreateRegionAdmin
              },
              {
                id: "create_lbu_admin",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_lbu_admin",
                component: CreateLBUAdmin
              },
              {
                id: "create_course_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_course_admin",
                component: CreateCourseAdmin
              },
              {
                id: "create_ad_candidate_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_ad_candidate_admin",
                component: CreateCandidateAdminAD
              },
              {
                id: "create_pd_candidate_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_pd_candidate_admin",
                component: CreateCandidateAdminPD
              },
              {
                id: "create_ad_candidate",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_ad_candidate",
                component: CreateCandidateAD,
              },
              {
                id: "create_pd_candidate",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_pd_candidate",
                component: CreateCandidatePD,
              },
              {
                id: "create_guest",
                title: "Las Back-stage management",
                customDevPath: true,
                path: "/home/system_settings/user_management/create_guest",
                component: CreateGuest
              },
              {
                id: "import_dcms_files",
                title: "Las Back-stage management",
                customDevPath: true,
                path: "/home/system_settings/user_management/import_dcms_files",
                component: ImportDcmsFile
              },
              {
                id: "reset_password",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/reset_password",
                customDevPath: true,
                component: ResetPassword
              },
              {
                id: "admin_info",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/region_admin_detail",
                customDevPath: true,
                component: AdminDetail
              },
              {
                id: "lbu_admin_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/lbu_admin_detail",
                customDevPath: true,
                component: AdminDetailLBU
              },
              {
                id: "course_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/course_admin_detail",
                customDevPath: true,
                component: AdminDetailCourse
              },
              {
                id: "ad_candidate_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/ad_candidate_admin_detail",
                customDevPath: true,
                component: CandidateAdminADDetail
              },
              {
                id: "pd_candidate_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/pd_candidate_admin_detail",
                customDevPath: true,
                component: CandidateAdminPDDetail
              },
              {
                id: "guest_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/guest_detail",
                customDevPath: true,
                component: GuestDetail
              },
              // {
              //   id: "agentinfo_pacs",
              //   title: "Las Back-stage management",
              //   path: "/home/system_settings/user_management/pacs_detail",
              //   customDevPath: true,
              //   component: PACSAgentDetail
              // },
              {
                id: "agentinfo_pcla",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/pcla_detail",
                customDevPath: true,
                component: PCLAAgentDetail
              },
              // {
              //   id: "agentinfo_pamb",
              //   title: "Las Back-stage management",
              //   path: "/home/system_settings/user_management/pamb_detail",
              //   customDevPath: true,
              //   component: PAMBAgentDetail
              // },
              {
                id: "candidate_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/candidate_detail",
                customDevPath: true,
                component: CandidateDetail
              }
            ]
          },
          {
            id: "cpd_settings",
            title: "Las Back-stage management",
            path: "/home/system_settings/cpd_settings",
            redirctPath:
              "/home/system_settings/cpd_settings/requiement_setting",
            component: CPDSettings,
            children: [
              {
                id: "requirement_setting",
                title: "Las Back-stage management",
                path: "/home/system_settings/cpd_settings/requiement_setting",
                component: CpdRequirement,
                customDevPath: true,
                exact: true
              },
              {
                id: "requirement_setting_create",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/cpd_settings/requirement_setting_create",
                component: RequirementSettingPambCreate,
                exact: true,
                customDevPath: true
              }
            ]
          },
          {
            id: "survey_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/survey_management",
            redirctPath: "/home/system_settings/survey_management/index",
            component: SurveyManagement,
            customDevPath: true,
            children: [
              {
                id: "survey_search",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/index",
                component: SurveySearch,
                exact: true
              },
              {
                id: "survey_create",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/survey_create",
                component: SurveyCreate,
                exact: true
              },
              {
                id: "survey_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/survey_detail",
                component: SurveyDetail,
                exact: true
              }
            ]
          },
          {
            id: "question_group",
            title: "Las Back-stage management",
            path: "/home/system_settings/question_group",
            redirctPath: "/home/system_settings/question_group/index",
            component: QuestionGroup,
            customDevPath: true,
            children: [
              {
                id: "question_group_search",
                title: "Las Back-stage management",
                path: "/home/system_settings/question_group/index",
                component: QuestionGroupSearch,
                exact: true
              },
              {
                id: "question_group_edit",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_edit",
                component: QuestionrGroupEdit,
                exact: true
              },
              {
                id: "question_group_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_detail",
                component: QuestionrGroupDetail,
                exact: true
              },
              {
                id: "question_group_create",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_create",
                component: QuestionrGroupCreate,
                exact: true
              },
              {
                id: "add_question_group_to_tem",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/add_question_group_to_tem",
                component: AddQuestionGroup,
                exact: true
              }
            ]
          },
          {
            id: "life_asia_report",
            title: "Las Back-stage management",
            path: "/home/system_settings/life_asia_report",
            redirctPath: "/home/system_settings/life_asia_report/agent_training_code",
            component: LAReport,
            children: [
              {
                id: "agent_training_code",
                title: "Las Back-stage management",
                path: "/home/system_settings/life_asia_report/agent_training_code",
                component: PCLAAgentTrainingCode,
                customDevPath: true,
                exact: true
              }
            ]
          },
          {
            id: "reminder_rule",
            title: "Las Back-stage management",
            path: "/home/system_settings/reminder_rule",
            redirctPath: "/home/system_settings/reminder_rule/reminder_list",
            component: ReminderRule,
            children: [
              {
                id: "reminder_list",
                title: "Las Back-stage management",
                path: "/home/system_settings/reminder_rule/reminder_list",
                component: ReminderList,
                customDevPath: true,
                exact: true
              },
              {
                id: "reminder_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/reminder_rule/reminder_detail",
                component: ReminderDetail,
                exact: true,
                customDevPath: true
              }
            ]
          },
          {
            id: "daily_coaching",
            title: "Las Back-stage management",
            path: "/home/system_settings/daily_coaching",
            redirctPath:
              "/home/system_settings/daily_coaching/daily_coaching_search",
            component: DailyCoaching,
            children: [
              {
                id: "daily_coaching_search",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/daily_coaching_search",
                component: DailyCoachingSearch,
                customDevPath: true,
                exact: true
              },
              {
                id: "edit_coaching_course",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/edit_coaching_course",
                component: EditCoachingCourse,
                exact: true,
                customDevPath: true
              },

              {
                id: "select_course",
                title: "Las Back-stage management",
                path: "/home/system_settings/daily_coaching/select_course",
                component: SelectCourse,
                exact: true,
                customDevPath: true
              },
              {
                id: "select_trainer",
                title: "Las Back-stage management",
                path: "/home/system_settings/daily_coaching/select_trainer",
                component: SelectTrainer,
                exact: true,
                customDevPath: true
              },
              {
                id: "select_agent_leader",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/select_agent_leader",
                component: SelectAgentLeader,
                exact: true,
                customDevPath: true
              }
            ]
          }
        ]
      },
      {
        id: "pacs_cpd_settings",
        title: "Las Back-stage management",
        path: "/home/cpd_settings",
        component: CPDSettings,
        hasSecondRoute: true,
        children: [
          {
            id: "requirement_setting",
            title: "Las Back-stage management",
            path: "/home/cpd_settings/requiement_setting",
            component: PacsRequireSettings,
            redirctPath: "/home/cpd_settings/requiement_setting/index",
            children: [
              {
                id: "requirement_setting",
                title: "Las Back-stage management",
                path: "/home/cpd_settings/requiement_setting/index",
                component: RequirementSettingPacs,
                customDevPath: true,
                exact: true
              },
              {
                id: "requiement_setting_edit",
                title: "Las Back-stage management",
                path: "/home/cpd_settings/requiement_setting/edit",
                component: RequirementSettingPacsEdit,
                customDevPath: true,
                exact: true
              }
            ]
          },
          {
            id: "shift_in_out",
            title: "Las Back-stage management",
            path: "/home/cpd_settings/shift_in_out",
            component: ShiftInOut,
            exact: true
          },
          {
            id: "grant_hours",
            title: "Las Back-stage management",
            path: "/home/cpd_settings/grant_hours",
            component: GrantHours,
            exact: true
          }
        ]
      },
      {
        id: "404",
        path: "/home/404",
        component: NotFound,
        initialRoute: true
      },
      {
        id: "403",
        path: "/home/403",
        component: Forbidden,
        initialRoute: true
      },
    ]
  },
  {
    id: "qrcode_attendance",
    title: "QRCode Attendance",
    path: `/${globalUrlPre}/qrcode_attendance`,
    customDevPath: true,
    component: () => <QRCodePage />,
    initialRoute: true,
  },
  {
    id: "403",
    path: "/403",
    component: Forbidden,
    initialRoute: true
  },
  {
    id: "500",
    path: "/500",
    component: ServerError,
    initialRoute: true
  },
];

export default RouteConfig;
