import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Rate,
  Radio,
  Checkbox,
  Spin,
  notification
} from "antd";
import { connect } from "react-redux";

import styles from "./SurveyDetail.module.less";
import Header from "../../../../components/Header"
import {
  updateSurvey,
  changePosition,
  deleteItem,
  changeContent,
  saveFields,
  getSurveyDetail,
  handleFresh
} from "../../../../../redux/pacsReducer/systemSettingsReducers/SurveyReducer";
import { globalPromptTime10s } from '../../../../../utils/global'

const { Option } = Select;
const { TextArea } = Input;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const formLayout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol2 = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pacsReducer: { systemSettingsReducers: { SurveyReducer } } }) => ({
    SurveyReducer
  }),
  {
    updateSurvey,
    changePosition,
    deleteItem,
    changeContent,
    saveFields,
    getSurveyDetail,
    handleFresh
  }
)
class SurveyDetail extends React.Component {
  componentDidMount() {
    const { location, SurveyReducer } = this.props;
    const { state = {} } = location;
    const { pambSurveyTemplateId } = state;
    const { noReFresh } = SurveyReducer;

    if (!noReFresh) {
      this.props.getSurveyDetail({ pambSurveyTemplateId });
    }
  }

  componentWillUnmount() {
    this.props.handleFresh(true);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          SurveyReducer: { questionGroups }
        } = this.props;
        const array = [];
        questionGroups.map((item, index) => {
          item.sort = `${index}`;
          if (!item.mandatoryFlag) {
            item.mandatoryFlag = "1";
          }

          array.push({
            pambQuestionGroupId: item.pambQuestionGroupId,
            sort: item.sort,
            mandatoryFlag: item.mandatoryFlag
          });
        });


        const manditoryList = []
        array.map(elt => manditoryList.push(elt.mandatoryFlag))
        const isM = manditoryList.some(elt => elt === '1')
        if (!isM) {
          notification.error({
            message: "Please select at least one mandatory group",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return null
        }

        const {
          surveyName,
          surveyCode,
          mandatoryFlag,
          openTime,
          closeTime,
          surveyDescription
        } = values;
        const { location } = this.props;
        const { state = {} } = location;
        const { pambSurveyTemplateId } = state;
        this.props.getSurveyDetail({ pambSurveyTemplateId });
        const callback = () => {
          this.props.history.push(
            "/home/system_settings/survey_management/index"
          );
        };
        this.props.updateSurvey(
          {
            surveyName,
            surveyCode,
            mandatoryFlag,
            openTime,
            closeTime,
            pambSurveyTemplateId,
            surveyDescription,
            groupList: array
          },
          callback
        );
      }
    });
  };

  handleUpAndDown = (index, type) => {
    const {
      SurveyReducer: { questionGroups }
    } = this.props;
    const upGo = index !== 0 && type === "up";
    const downGo = index !== questionGroups.length - 1 && type === "down";
    if (upGo || downGo) {
      this.props.changePosition(index, type);
    }
  };

  handleDelete = index => {
    this.props.deleteItem(index);
  };

  handleSelectedChange = (val, index) => {
    this.props.changeContent(val, index);
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  render() {
    const {
      form: { getFieldDecorator },
      SurveyReducer: {
        mandatoryList = [],
        questionGroups,
        submitLoading,
        fieldsData = {}
      }
    } = this.props;

    return (
      <div className={styles.container}>
        <Spin spinning={submitLoading}>
          <Header title='Survey Info' />
          <Form labelAlign="left" colon={false} {...formLayout}>
            <div className={styles.ant_form}>
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Survey Name">
                    {getFieldDecorator("surveyName", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 50,
                          message: "fields cannot be longer than 50 characters"
                        }
                      ],
                      initialValue: fieldsData.surveyName
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("surveyName", e.target.value)
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Survey Code">
                    {getFieldDecorator("surveyCode", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 3,
                          message: "fields cannot be longer than 3 characters"
                        }
                      ],
                      initialValue: fieldsData.surveyCode
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("surveyCode", e.target.value)
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Is Mandatory">
                    {getFieldDecorator("mandatoryFlag", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 50,
                          message: "fields cannot be longer than 50 characters"
                        }
                      ],

                      initialValue: fieldsData.mandatoryFlag
                    })(
                      <Select
                        onChange={val =>
                          this.handleSaveField("mandatoryFlag", val)
                        }
                        disabled
                      >
                        {mandatoryList.map(elt => (
                          <Option value={elt.value} key={elt.value}>
                            {elt.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Open time(Hour)">
                    {getFieldDecorator("openTime", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          pattern: /^[0-9]+\.?[0-9]*$/,
                          message: "Only numbers are allowed"
                        }
                      ],
                      initialValue: fieldsData.openTime
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("openTime", e.target.value)
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Close Time(Hour)">
                    {getFieldDecorator("closeTime", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          pattern: /^[0-9]+\.?[0-9]*$/,
                          message: "Only numbers are allowed"
                        }
                      ],
                      initialValue: fieldsData.closeTime
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("closeTime", e.target.value)
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...flexCol2}>
                  <Form.Item label="Survey Description" {...formLayout2}>
                    {getFieldDecorator("surveyDescription", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 100,
                          message: "fields cannot be longer than 100 characters"
                        }
                      ],
                      initialValue: fieldsData.surveyDescription
                    })(
                      <TextArea
                        rows={4}
                        onChange={e =>
                          this.handleSaveField(
                            "surveyDescription",
                            e.target.value
                          )
                        }
                        disabled
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </div>

            {questionGroups.length ? (
              <div className={styles.groupBox}>
                {questionGroups.map((elt, ind) => {
                  return (
                    <div
                      className={styles.groupItem}
                      key={elt.pambQuestionGroupId + elt.groupIndex}
                    >
                      <div className={styles.groupTop}>
                        <div className={styles.groupMandatory}>
                          <Form.Item>
                            {getFieldDecorator(
                              `isM${elt.pambQuestionGroupId + elt.groupIndex}`,
                              {
                                initialValue: elt.mandatoryFlag || '1'
                              }
                            )(
                              <Select
                                className={styles.mandatoryBox}
                                onChange={val =>
                                  this.handleSelectedChange(val, ind)
                                }
                                disabled
                              >
                                {mandatoryList.map(item => (
                                  <Option value={item.value} key={item.value}>
                                    {item.code}
                                  </Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        </div>
                        <div className={styles.grouptitle}>{elt.groupName}</div>
                        <div className={styles.actionBox} />
                      </div>
                      <div className={styles.questionBox}>
                        {elt.questionList
                          ? elt.questionList.map((item, index) => {
                            return (
                              <div
                                className={styles.questionItem}
                                key={item.pambQuestionId}
                              >
                                <div className={styles.questionIndex}>
                                  {`${index + 1}.`}
                                </div>
                                <div className={styles.questionContent}>
                                  <div className={styles.questionTitle}>
                                    {item.questionName}
                                  </div>
                                  <div className={styles.questionTypeBox}>
                                    {(item.questionType === "0" || item.questionType === "4" || item.questionType === "5" || item.questionType === "6") ? (
                                      <Rate disabled />
                                    ) : null}
                                    {item.questionType === "1" ? (
                                      <TextArea
                                        rows={4}
                                        disabled
                                        style={{ width: 700 }}
                                      />
                                    ) : null}
                                    {item.questionType === "2" ? (
                                      <div className={styles.answerBox}>
                                        {item.answerList
                                          ? item.answerList.map(child => {
                                            return (
                                              <div
                                                className={
                                                  styles.answerItem
                                                }
                                                key={child.pambAnswerId}
                                              >
                                                <span
                                                  className={
                                                    styles.ant_com_box
                                                  }
                                                >
                                                  <Checkbox disabled />
                                                </span>
                                                <span
                                                  className={
                                                    styles.valueBox
                                                  }
                                                >
                                                  {`${child.answerValue}.`}
                                                </span>
                                                <span
                                                  className={styles.nameBox}
                                                >
                                                  {child.answerName}
                                                </span>
                                              </div>
                                            );
                                          })
                                          : null}
                                      </div>
                                    ) : null}

                                    {item.questionType === "3" ? (
                                      <div className={styles.answerBox}>
                                        {item.answerList
                                          ? item.answerList.map(child => {
                                            return (
                                              <div
                                                className={
                                                  styles.answerItem
                                                }
                                                key={child.pambAnswerId}
                                              >
                                                <span
                                                  className={
                                                    styles.ant_com_box
                                                  }
                                                >
                                                  <Radio disabled />
                                                </span>
                                                <span
                                                  className={
                                                    styles.valueBox
                                                  }
                                                >
                                                  {`${child.answerValue}.`}
                                                </span>
                                                <span
                                                  className={styles.nameBox}
                                                >
                                                  {child.answerName}
                                                </span>
                                              </div>
                                            );
                                          })
                                          : null}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </Form>
        </Spin>
      </div>
    );
  }
}

const SurveyDetailForm = Form.create()(SurveyDetail);

export default SurveyDetailForm;
