import { connect } from "react-redux";
import { getFcCodeList, getShiftCourseName } from "../../../../../redux/pacsReducer/CPDSettingsReducers/ShiftInOutReducer";
import {
  getCourseNameList,
  getCourseCodeList,
} from "../../../../../redux/pacsReducer/CPDReportReducers/AttendanceSummaryReducer";

const mapStateToProps = state => {
  return {
    homeReducer: state.homeReducer,
    userManagementReducer: state.phklReducer.systemSettingsReducers.userManagementReducer,

    ShiftInOutReducer: state.pacsReducer.CPDSettingsReducers.ShiftInOutReducer,
    AttendanceSummaryReducer: state.pacsReducer.CPDReportReducers.AttendanceSummaryReducer
  };
};

const mapDispatchToProps = {
  getFcCodeList,
  getCourseCodeList,
  getCourseNameList,
  getShiftCourseName
};

export default connect(mapStateToProps, mapDispatchToProps);
