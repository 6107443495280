import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INIT_DATA_LAREPORT = "PACS/INIT_DATA_LAREPORT";

const initState = {
  trainingCode: {
    value: ""
  },
  completuonDate: {
    value: ""
  },
  codeList: [],
  nameList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1
};

// reducer
export default function PACSAgentTrainingCodeReducer(state = initState, action) {
  switch (action.type) {
    case "PACSAgentTrainingCode":
      return { ...state, ...action.payload };
    case INIT_DATA_LAREPORT:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_LAREPORT
  };
}

export function save_data(payload) {
  return {
    type: "PACSAgentTrainingCode",
    payload
  };
}
// GET /agentTrainingCode/getCourseNameListByName
export function getCourseNameListByName(name, code) {
  return dispatch => {
    get("/agentTrainingCode/getCourseNameListByName", { name, code })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              nameList: res.data,
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /agentTrainingCode/getCourseNameListByCode
export function getCourseNameListByCode(code) {
  return dispatch => {
    dispatch(
      save_data({
        codeList: [],
      })
    );
    get("/agentTrainingCode/getCourseNameListByCode", { code })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              codeList: res.data,
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /agentTrainingCode/downLoadAgentTrainingCodeExcel
export function downLoadAgentTrainingCodeExcel(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/agentTrainingCode/downLoadAgentTrainingCodeExcel", payload, {}, "blob")
      .then(blob => {
        const fileName = `AgentsTrainingCode.xls`;
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /agentTrainingCode/downLoadAgentTrainingCodeTxt
export function downLoadAgentTrainingCodeTxt(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/agentTrainingCode/downLoadAgentTrainingCodeTxt", payload, {}, "blob")
      .then(blob => {
        const fileName = `AgentsTrainingCode.txt`;
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /TrainingCodeRelationShipMappingController/getAgentTrainingCodeNew
export function search(payload) {
  return dispatch => {
    dispatch(loading(true));
    post("/TrainingCodeRelationShipMappingController/getAgentTrainingCodeNew", payload)
      .then(res => {
        if (res.success) {
          // const finalData = [...res.data.data];
          // finalData.map((item) => {
          //   item.index = `${item.fcCode}&&${item.courseId}`;
          //   return item;
          // });
          dispatch(
            save_data({
              searchList: [...res.data.data],
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
