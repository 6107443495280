import React from "react";
import { connect } from "react-redux";
import { Form, Select, Input, Button, Row, Col, Spin, Icon } from "antd";
import styles from "./CreateLBUAdmin.module.less";
import { handleSubmit } from "../../../../../redux/pclaReducer/systemSettingsReducers/CreateLBUAdminReducer";

const { Option } = Select;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({
    pclaReducer: { systemSettingsReducers: { CreateLBUAdminReducer, userManagementReducer } }
  }) => ({ CreateLBUAdminReducer, userManagementReducer }),
  { handleSubmit }
)
class LBUAdminForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault();
    const { form, history } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { roleid } = values;
        const {
          userManagementReducer: { rolerList }
        } = this.props;
        const roleIdItem = rolerList.find(elt => elt.country === roleid);
        const callback = () =>
          history.push("/home/system_settings/user_management");
        this.props.handleSubmit(
          { ...values, roleid: roleIdItem.roleid },
          callback
        );
      }
    });
  };

  handleSelectChange = value => {
    this.props.form.setFieldsValue({
      roleid: value
    });
  };

  handleEmailChange = e => {
    this.props.form.setFieldsValue({
      loginaccount: e.target.value
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      userManagementReducer: { rolerList, buList },
      CreateLBUAdminReducer: { createLbuAdminLoading }
    } = this.props;
    const leftBulist = buList.filter(elt => elt.id !== "ADMIN");
    return (
      <div className={styles.container}>
        <Spin spinning={createLbuAdminLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            Create LBU Admin
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(
                    <Select onChange={this.handleSelectChange}>
                      {leftBulist.map(elt => (
                        <Option value={elt.id} key={elt.id}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("roleid", {
                    rules: []
                  })(
                    <Select disabled>
                      {rolerList.map(elt => (
                        <Option value={elt.country} key={elt.roleid}>
                          {elt.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginaccount", {})(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input onChange={this.handleEmailChange} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactnumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={styles.submitBtn}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const CreateLBUAdmin = Form.create({ name: "LBUAdminForm" })(LBUAdminForm);

export default CreateLBUAdmin;
