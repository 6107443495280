import { message } from 'antd'
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pacs/ShiftInOutReducer/SAVE";
const ALL = 'all'
const initState = {
  tableLoading: false,
  userCourseData: {
    total: 0,
    pageSize: 10,
    pageNumber: 1,
    userList: []
  },
  noUpdatedList: [],
  allUserList: [],
  fcCodeList: [],
  shiftToList: [],
  courseNameList: []
};

// reducer
export default function ShiftInOutReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// POST /pacsCPDCalculate/getAgentShiftIn0utListForSelectAl
export function getTabelDataAll(payload) {
  return dispatch => {
    post("/pacsCPDCalculate/getAgentShiftIn0utListForSelectAll", {}, payload)
      .then(res => {
        const { data = {} } = res;

        // console.log("getTabelDataAll:", data) // eslint-disable-line
        dispatch({
          type: SAVE,
          payload: {
            allUserList: data || []
          }
        });
      })
      .catch(() => {
      });
  };
}

export function getTabelData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/pacsCPDCalculate/getAgentShiftIn0utList", {}, payload)
      .then(res => {
        const { data = {} } = res;
        dispatch({
          type: SAVE,
          payload: {
            userCourseData: {
              total: data.total,
              pageSize: data.pageSize,
              userList: data.data || []
            },
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

// POST /pacsCPDCalculate/getAgentShiftIn0utBulkSave
export function getAgentShiftIn0utBulkSave(payload, cb) {
  return dispatch => {
    post("/pacsCPDCalculate/getAgentShiftIn0utBulkSave", payload, {})
      .then(res => {
        const { data = {} } = res;
        // console.log("getAgentShiftIn0utBulkSave:", data)// eslint-disable-line
        dispatch({
          type: SAVE,
          payload: {
            noUpdatedList: data || [],
            tableLoading: false
          }
        });
        if (!data.length) {
          message.info("Saved successfully", globalPromptTime);
        }
        cb(data || [])
      })
      .catch(() => {
      });
  };
}


export function getShiftToDate() {
  return dispatch => {
    get("/pacs/cpd/yearDesc")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            shiftToList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function handleSubmit(payload, cb) {
  return dispatch => {
    post('/pacsCPDCalculate/AgentShiftIn0ut', payload)
      .then(() => {
        message.info("Saved successfully", globalPromptTime, cb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function getFcCodeList(payload, cb) {
  const ownerName = payload?.ownerName?.toLowerCase() || '';

  payload.ownerName = ownerName;

  return dispatch => {
    get("/GrantHoursController/getPacsUserInfoSelect2", payload)
      .then(res => {
        const { data = [] } = res;

        data.map(elt => {
          elt.agentCodeValue = elt.agentCode
        })


        if (!ownerName || ALL.includes(ownerName)) {
          data.unshift({
            agentCode: '',
            agentCodeValue: 'All'
          })
        }

        dispatch({
          type: SAVE,
          payload: {
            fcCodeList: data || []
          }
        });
        if (cb) { cb() }
      })
      .catch(() => { });
  };
}
// GET /pacsCPDCalculate/AgentShiftIn0utForCourseCode
export function getShiftCourseName() {
  return dispatch => {
    get("/pacsCPDCalculate/AgentShiftIn0utForCourseCode")
      .then(res => {
        const { data } = res;
        data.unshift({
          courseName: "All",
          courseId: ""
        })
        // console.log("getShiftCourseName:", data) // eslint-disable-line
        dispatch({
          type: SAVE,
          payload: {
            courseNameList: data || []
          }
        });
      })
      .catch(() => { });
  };
}
