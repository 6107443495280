export const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

export const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export const flexRow = {
  gutter: [22, 0]
};

export const handleButtonList = (list) => {
  const importList = [];
  const createList = [];
  list.map(item => {
    if (item.group === "import") {
      importList.push(item);
    } else if (item.group === "create") {
      createList.push(item);
    }
  });
  return { importList, createList };
}

export const userListPath = '/home/system_settings/user_management/index';

export const getRoleById = (roleList, id) => roleList.filter(item => item.roleid === id);
