import React from "react";
import { connect } from "react-redux";
import RequirementSettingPamb from './RequirementSettingPamb'
import PacsIndex from './PacsIndex'

@connect(
  ({ userReducer }) => ({ userReducer }),
  {}
)

class CpdRequirement extends React.Component {
  componentDidMount() { }

  render() {
    const { userReducer: { bu } } = this.props
    if (bu === "PAMB") {
      return <RequirementSettingPamb history={this.props.history} />
    } else if (bu === "PACS") {
      return <PacsIndex history={this.props.history} />
    } else if (bu === "PLUK") {
      return <PacsIndex history={this.props.history} />
    }
    return null;
  }
}

export default CpdRequirement;
