import React, { useRef, useState } from "react";
import { Button, notification, Modal } from "antd";
import { withRouter } from "react-router-dom";
import { post } from '../../../../../../../utils/request';
import styles from '../../index.module.less';
import { handleCheckToken } from '../../../../../../../utils/refreshToken';
import { downloadFile } from "../../../../../../../utils";

function ActionButtons(props) {
  const { isRistrationTab } = props.CourseDetailReducer;
  const { currentSessiondayId } = props.CourseDetailReducer;
  const [showUploadConfirm, setShowUploadConfirm] = useState(false);
  const uploadFileRef = useRef();

  const addAttendance = () => {
    const { courseDetail, history } = props;
    history.push("/home/attendance/add_attendance", {
      courseDetail,
      currentSessiondayId
    });
  }

  const openFile = () => {
    if (uploadFileRef) {
      uploadFileRef.current.value = "";
      uploadFileRef.current.click();
    }
  }

  const selectFile = () => {
    const file = uploadFileRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    post("/attendanceTms/uploadAttendanceInfoCheck", formData, { sessiondayId: currentSessiondayId })
      .then(() => {
        uploadAttendance();
      })
      .catch((err) => {
        if (err.code === 1033) {
          setShowUploadConfirm(true);
        }
      });
  }

  const uploadAttendance = async () => {
    const file = uploadFileRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      await post("/attendanceTms/uploadAttendanceInfo", formData, { sessiondayId: currentSessiondayId });

      notification.success({
        message: "Uploaded successfully",
        duration: 10
      });

      props.getCourseAttendanceList(currentSessiondayId);
    } catch (error) {

    }
  }

  const downLoadRegistrationExcel = async () => {
    const { courseDetail } = props;
    const { sessionId } = courseDetail;

    await handleCheckToken();
    const blob = await post("/attendanceTms/downLoadRegistrationInfo",
      { sessionId },
      { sessionId },
      "blob");

    downloadFile(blob, 'Registration.xls');
  }

  const downLoadAttendanceToExcel = async () => {
    await handleCheckToken();
    const blob = await post("/attendanceTms/downLoadAttendanceInfo",
      { sessiondayId: currentSessiondayId },
      { sessiondayId: currentSessiondayId },
      "blob");

    downloadFile(blob, 'Attendance.xls');
  }

  return (
    <div className={styles.buttonArea}>
      {
        isRistrationTab ? (
          <Button
            type="danger"
            className={styles.btn}
            style={{ width: "165px", marginRight: "10px" }}
            icon="download"
            onClick={downLoadRegistrationExcel}
          >
            Download to Excel
          </Button>
        ) : (
          <>
              <Button
                type="danger"
                className={styles.btn}
                style={{ width: "165px", marginRight: "10px" }}
                icon="download"
                onClick={downLoadAttendanceToExcel}
              >
                Download to Excel
              </Button>
              <Button
                type="danger"
                className={styles.btn}
                onClick={openFile}
                style={{ width: "110px", marginRight: "10px" }}
                icon="upload"
              >
                Upload
              </Button>
              <input
                type="file"
                ref={uploadFileRef}
                style={{ display: "none" }}
                onChange={selectFile}
              />
              <Button
                className={styles.btn}
                type="danger"
                icon="plus"
                onClick={addAttendance}
              >
                Add
              </Button>
          </>
        )
      }


      <Modal
        title="Confirm Upload"
        visible={showUploadConfirm}
        onOk={() => {
          setShowUploadConfirm(false);
          uploadAttendance();
        }}
        onCancel={() => {
          setShowUploadConfirm(false);
        }}
      >
        <p>System will use the latest one to overwrite the existing one</p>
        <p>Continue or not ?</p>
      </Modal>

    </div>
  );
}

export default withRouter(ActionButtons);
