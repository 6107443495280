import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INIT_DATA_TRAININGCODEMAP = "PACS/INIT_DATA_TRAININGCODEMAP";

const initState = {
  system: {
    value: ""
  },
  relationship: {
    value: ""
  },
  codeCode: {
    value: ""
  },
  nowCode: {
    value: ""
  },
  trainingCode: {
    value: ""
  },
  editFields: {
    system: {
      value: ""
    },
    relationship: {
      value: ""
    },
    codeCode: {
      value: ""
    },
    trainingCode: {
      value: ""
    },
  },
  codeList: [],
  nameList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1
};

// reducer
export default function TrainingCodeMapReducer(state = initState, action) {
  switch (action.type) {
    case "PACSTrainingCodeMap":
      return { ...state, ...action.payload };
    case INIT_DATA_TRAININGCODEMAP:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_TRAININGCODEMAP
  };
}

export function save_data(payload) {
  return {
    type: "PACSTrainingCodeMap",
    payload
  };
}


//  GET /certification/getCourseNameListByName
export function getCourseNameListByName(name, code, callback) {
  return dispatch => {
    get("/certification/getCourseNameListByName", { name, code })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              nameList: res.data,
            })
          );
          callback(res.data)
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /certification/getCourseNameListByCode
export function getCourseNameListByCode(code) {
  return dispatch => {
    dispatch(
      save_data({
        codeList: [],
      })
    );
    get("/agentTrainingCode/getCourseNameListByCode", { code })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              codeList: res.data,
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /agentTrainingCode/downLoadAgentTrainingCodeExcel
export function downLoadAgentTrainingCodeExcel(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/agentTrainingCode/downLoadAgentTrainingCodeExcel", payload, {}, "blob")
      .then(blob => {
        const fileName = `AgentsTrainingCode.xls`;
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /agentTrainingCode/downLoadAgentTrainingCodeTxt
export function downLoadAgentTrainingCodeTxt(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/agentTrainingCode/downLoadAgentTrainingCodeTxt", payload, {}, "blob")
      .then(blob => {
        const fileName = `AgentsTrainingCode.txt`;
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}


// POST /TrainingCodeRelationShipMappingController/addTrainingCodeMapping
export function create(payload, callback) {
  return dispatch => {
    dispatch(loading(true));
    post("/TrainingCodeRelationShipMappingController/addTrainingCodeMapping", payload)
      .then(res => {
        if (res.success) {
          callback();
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST/TrainingCodeRelationShipMappingController/addTrainingCodeMappingCourseCode
export function update(payload, callback) {
  return dispatch => {
    dispatch(loading(true));
    post(`/TrainingCodeRelationShipMappingController/addTrainingCodeMappingCourseCode?Id=${payload.Id}&CourseCodes=${payload.CourseCodes}&CourseIds=${payload.CourseIds}`)
      .then(res => {
        if (res.success) {
          callback()
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}


// POST /certification/delete
export function deleteItem(payload, callback) {
  return dispatch => {
    dispatch(loading(true));
    post("/certification/delete", payload)
      .then(res => {
        if (res.success) {
          callback()
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}


// POST /TrainingCodeRelationShipMappingController/getTrainingCodeMappingList
export function search(payload) {
  return dispatch => {
    dispatch(loading(true));
    post("/TrainingCodeRelationShipMappingController/getTrainingCodeMappingList", payload)
      .then(res => {
        if (res.success) {
          // const finalData = [...res.data];
          // finalData.map((item) => {
          //   item.index = `${item.exam}&&${item.courseId}&&${item.courseCode}`;
          //   return item;
          // });
          dispatch(
            save_data({
              searchList: res.data.data,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /TrainingCodeRelationShipMappingController/deleteTrainingCodeMappingCourseCode

export function deleteTrainingCodeMappingCourseCode(id, callback) {
  return dispatch => {
    dispatch(loading(true));
    post(`/TrainingCodeRelationShipMappingController/deleteTrainingCodeMappingCourseCode?Id=${id}`)
      .then(res => {
        if (res.success) {
          callback()
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
