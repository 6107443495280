import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { Form, Row, Col, Input, Select, DatePicker } from "antd";
import { changeVals } from "../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { handleMyProfile } from "../../../../redux/homeReducer";
import './index.less';
import styles from "./styles.module.less";
import {
  getBuList,
  getRolerList,
  getAllRolerList,
  getAllRoleList,
} from "../../../../redux/regionalReducer/systemSettingsReducers/userManagementReducer";

export const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

export const formLayout2 = {
  labelCol: { span: 13 },
  wrapperCol: { span: 11 }
};

export const flexRow = {
  gutter: [22, 0]
};


const CreateForm = Form.create({
  name: "CreateAgent_PAMB_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("Agent_PAMB", changedFields);
  },
  mapPropsToFields(props) {
    return {
      role: Form.createFormField({
        ...props.role,
        value: props.role.value
      }),
      bu: Form.createFormField({
        ...props.bu,
        value: props.bu.value
      }),
      userName: Form.createFormField({
        ...props.userName,
        value: props.userName.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      contactNum: Form.createFormField({
        ...props.contactNum,
        value: props.contactNum.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      agentCode: Form.createFormField({
        ...props.agentCode,
        value: props.agentCode.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      terminationDate: Form.createFormField({
        ...props.terminationDate,
        value: props.terminationDate.value
      }),
      branchName: Form.createFormField({
        ...props.branchName,
        value: props.branchName.value
      }),
      division: Form.createFormField({
        ...props.division,
        value: props.division.value
      }),
      divisionManagerAgentCode: Form.createFormField({
        ...props.divisionManagerAgentCode,
        value: props.divisionManagerAgentCode.value
      }),
      designationCode: Form.createFormField({
        ...props.designationCode,
        value: props.designationCode.value
      }),
      agencyCode: Form.createFormField({
        ...props.agencyCode,
        value: props.agencyCode.value
      }),
      agencyName: Form.createFormField({
        ...props.agencyName,
        value: props.agencyName.value
      }),
      title: Form.createFormField({
        ...props.title,
        value: props.title.value
      }),
      branchCode: Form.createFormField({
        ...props.branchCode,
        value: props.branchCode.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      zone: Form.createFormField({
        ...props.zone,
        value: props.zone.value
      }),
      recruiterCode: Form.createFormField({
        ...props.recruiterCode,
        value: props.recruiterCode.value
      }),
      directUplineCode: Form.createFormField({
        ...props.directUplineCode,
        value: props.directUplineCode.value
      }),
      chineseFullName: Form.createFormField({
        ...props.chineseFullName,
        value: props.chineseFullName.value
      }),
      hkid: Form.createFormField({
        ...props.hkid,
        value: props.hkid.value
      }),
      mobile: Form.createFormField({
        ...props.mobile,
        value: props.mobile.value
      }),
      firstJoinCoDate: Form.createFormField({
        ...props.firstJoinCoDate,
        value: props.firstJoinCoDate.value
      }),
      firstLUDate: Form.createFormField({
        ...props.firstLUDate,
        value: props.firstLUDate.value
      }),
      directUplineName: Form.createFormField({
        ...props.directUplineName,
        value: props.directUplineName.value
      }),
      agentStatus: Form.createFormField({
        ...props.agentStatus,
        value: props.agentStatus.value
      }),
      ialicenseIndicator: Form.createFormField({
        ...props.ialicenseIndicator,
        value: props.ialicenseIndicator.value
      }),
      ialicenseDate: Form.createFormField({
        ...props.ialicenseDate,
        value: props.ialicenseDate.value
      }),
      mpflicenseIndicator: Form.createFormField({
        ...props.mpflicenseIndicator,
        value: props.mpflicenseIndicator.value
      }),
      mpfdate: Form.createFormField({
        ...props.mpfdate,
        value: props.mpfdate.value
      }),
      sfclicenseIndicator: Form.createFormField({
        ...props.sfclicenseIndicator,
        value: props.sfclicenseIndicator.value
      }),
      sfcdate: Form.createFormField({
        ...props.sfcdate,
        value: props.sfcdate.value
      }),
      activationDate: Form.createFormField({
        ...props.activationDate,
        value: props.activationDate.value
      }),
      candidateApplicationReceivedDate: Form.createFormField({
        ...props.candidateApplicationReceivedDate,
        value: props.candidateApplicationReceivedDate.value
      }),
      candidateReturnDate: Form.createFormField({
        ...props.candidateReturnDate,
        value: props.candidateReturnDate.value
      }),
      rejoinIndicator: Form.createFormField({
        ...props.rejoinIndicator,
        value: props.rejoinIndicator.value
      }),
      giindicator: Form.createFormField({
        ...props.giindicator,
        value: props.giindicator.value
      }),
      mpfindicator: Form.createFormField({
        ...props.mpfindicator,
        value: props.mpfindicator.value
      }),
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  // console.log(props);
  return (
    <Form
      layout="horizontal"
      className="ant_form"
      labelCol={{ span: 9 }}
      labelAlign="left"
      wrapperCol={{ span: 15 }}
      colon={false}
    >
      <Row gutter={[24, 0]}>
        <Col {...flexCol}>
          <Form.Item label="Role">
            {getFieldDecorator("role", {})(
              <Select disabled={props.disabled} placeholder="">
                {props.rolerList.map(item => (
                  <Option value={item.roleid}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agent Code">
            {getFieldDecorator("agentCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="BU">
            {getFieldDecorator("bu", {})(
              <Select disabled={props.disabled} placeholder="">
                {props.buList.map(item => (
                  <Option value={item.id} key={item.id}>{item.value}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="HKID">
            {getFieldDecorator("hkid", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Name">
            {getFieldDecorator("userName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Mobile">
            {getFieldDecorator("mobile", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Division">
            {getFieldDecorator("division", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Division Manager Agent Code" {...formLayout2}>
            {getFieldDecorator("divisionManagerAgentCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Designation Code">
            {getFieldDecorator("designationCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agency">
            {getFieldDecorator("agencyCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agency Name">
            {getFieldDecorator("agencyName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Title">
            {getFieldDecorator("title", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Branch Code">
            {getFieldDecorator("branchCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Branch Name">
            {getFieldDecorator("branchName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Recruiter Code">
            {getFieldDecorator("recruiterCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Direct Upline Code">
            {getFieldDecorator("directUplineCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="First Join Co Date">
            {getFieldDecorator("firstJoinCoDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Chinese Full Name">
            {getFieldDecorator("chineseFullName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="First LU Date">
            {getFieldDecorator("firstLUDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Direct Upline Name">
            {getFieldDecorator("directUplineName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agent Status">
            {getFieldDecorator("agentStatus", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Termination Date">
            {getFieldDecorator("terminationDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="IA License Indicator">
            {getFieldDecorator("ialicenseIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="IA License Date">
            {getFieldDecorator("ialicenseDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="MPF License Indicator">
            {getFieldDecorator("mpflicenseIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="MPF Date">
            {getFieldDecorator("mpfdate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="SFC License Indicator">
            {getFieldDecorator("sfclicenseIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="SFC Date">
            {getFieldDecorator("sfcdate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Activation Date">
            {getFieldDecorator("activationDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Candidate Application Received Date">
            {getFieldDecorator("candidateApplicationReceivedDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Candidate Return Date">
            {getFieldDecorator("candidateReturnDate", {
            })(<DatePicker disabled={props.disabled} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Rejoin Indicator">
            {getFieldDecorator("rejoinIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="GI Indicator">
            {getFieldDecorator("giindicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="MPF Indicator">
            {getFieldDecorator("mpfindicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Zone">
            {getFieldDecorator("zone", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status", {
            })(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => {
    const roleId = state.userReducer.roleid || state.userReducer.roleId;
    const roleList = state.regionalReducer.systemSettingsReducers.userManagementReducer.rolerList;
    return {
      fields: {
        // ...state.myProfileReducers.Agent_PAMB_Reducer,
        role: {
          value: (() => {
            const match = roleList.filter(item => {
              return item.roleid === roleId;
            });
            return match.length && match[0].name;
          })()
        },
        bu: {
          value: "PHKL"
        },
        account: {
          value: state.userReducer.loginaccount || state.userReducer.loginAccount
        },
        channel: {
          value: state.userReducer.channel
        },
        userName: {
          value: `${state.userReducer.firstName} ${state.userReducer.lastName}`
        },
        email: {
          value: state.userReducer.email
        },
        contactNum: {
          value: state.userReducer.contactnumber
        },
        unitCode: {
          value: state.userReducer.unitCode
        },
        agentCode: {
          value: state.userReducer.agentCode
        },
        status: {
          value: state.userReducer.accountStatus
        },
        contractedDate: {
          value: state.userReducer.contracteddate
            ? new Date(state.userReducer.contracteddate).toLocaleDateString()
            : "" // contracteddate
        },
        terminationDate: {
          value: state.userReducer.terminationDate ? moment(state.userReducer.terminationDate, "YYYY-MM-DD") : null
        },
        branchName: {
          value: state.userReducer.branchName
        },
        mobile: {
          value: state.userReducer.mobile
        },
        division: {
          value: state.userReducer.division
        },
        divisionManagerAgentCode: {
          value: state.userReducer.divisionManagerAgentCode
        },
        designationCode: {
          value: state.userReducer.designationCode
        },
        agencyCode: {
          value: state.userReducer.agencyCode
        },
        agencyName: {
          value: state.userReducer.agencyName
        },
        title: {
          value: state.userReducer.title
        },
        branchCode: {
          value: state.userReducer.branchCode
        },
        zone: {
          value: state.userReducer.zone
        },
        recruiterCode: {
          value: state.userReducer.recruiterCode
        },
        directUplineCode: {
          value: state.userReducer.directUplineCode
        },
        chineseFullName: {
          value: state.userReducer.chineseFullName
        },
        hkid: {
          value: state.userReducer.hkid
        },
        firstJoinCoDate: {
          value: state.userReducer.firstJoinCoDate ? moment(state.userReducer.firstJoinCoDate, "YYYY-MM-DD") : null
        },
        firstLUDate: {
          value: state.userReducer.firstLUDate ? moment(state.userReducer.firstLUDate, "YYYY-MM-DD") : null
        },
        directUplineName: {
          value: state.userReducer.directUplineName
        },
        agentStatus: {
          value: state.userReducer.agentStatus
        },
        ialicenseIndicator: {
          value: state.userReducer.ialicenseIndicator
        },
        ialicenseDate: {
          value: state.userReducer.ialicenseDate ? moment(state.userReducer.ialicenseDate, "YYYY-MM-DD") : null
        },
        mpflicenseIndicator: {
          value: state.userReducer.mpflicenseIndicator
        },
        mpfdate: {
          value: state.userReducer.mpfdate ? moment(state.userReducer.mpfdate, "YYYY-MM-DD") : null
        },
        sfclicenseIndicator: {
          value: state.userReducer.sfclicenseIndicator
        },
        sfcdate: {
          value: state.userReducer.sfcdate ? moment(state.userReducer.sfcdate, "YYYY-MM-DD") : null
        },
        activationDate: {
          value: state.userReducer.activationDate ? moment(state.userReducer.activationDate, "YYYY-MM-DD") : null
        },
        candidateApplicationReceivedDate: {
          value: state.userReducer.candidateApplicationReceivedDate ? moment(state.userReducer.candidateApplicationReceivedDate, "YYYY-MM-DD") : null
        },
        candidateReturnDate: {
          value: state.userReducer.candidateReturnDate ? moment(state.userReducer.candidateReturnDate, "YYYY-MM-DD") : null
        },
        rejoinIndicator: {
          value: state.userReducer.rejoinIndicator
        },
        giindicator: {
          value: state.userReducer.giindicator
        },
        mpfindicator: {
          value: state.userReducer.mpfindicator
        }
      },
      user: { ...state.userReducer },
      buList: state.pambReducer.systemSettingsReducers.userManagementReducer.buList.length
        ? [...state.pambReducer.systemSettingsReducers.userManagementReducer.buList]
        : [],
      rolerList: state.pambReducer.systemSettingsReducers.userManagementReducer.allRolerList
        .length
        ? [...state.pambReducer.systemSettingsReducers.userManagementReducer.allRolerList]
        : []
    }
  },
  { changeVals,
    handleMyProfile,
    getBuList,
    getRolerList,
    getAllRolerList,
    getAllRoleList,
  }
)
class AgentProfile extends Component {
  state = {
    disabled: true
  };

  componentDidMount() {
    // console.log(this.props)
    this.props.handleMyProfile();
    this.props.getBuList();
    this.props.getRolerList();
    this.props.getAllRolerList();
    this.props.getAllRoleList();
  }

  getRoleName(roleId, roleList) {
    // console.log(roleId);
    // console.log(roleList);
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  render() {
    const props = {
      rolerList: this.props.rolerList,
      buList: this.props.buList,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      disabled: this.state.disabled
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>My Profile</div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

// const insideStyles = {
//   goCenter: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   }
// };

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default AgentProfile;
