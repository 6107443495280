import { message } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const initState = {
  userName: {
    value: ""
  },
  account: {
    value: ""
  },
  password: {
    value: ""
  },
  passwordAgain: {
    value: ""
  }
};

// reducer
export default function ResetPasswordReducer(state = initState, action) {
  switch (action.type) {
    case "ResetPassword":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// Create change passd_word api
export function changePassword(payload) {
  return () => {
    post("/login/resetPassword", payload)
      .then(res => {
        if (res.success) {
          message.info("Reset password successfully", globalPromptTime);
        } else {
          message.info(res.message, globalPromptTime);
        }
      })
      .catch(() => {});
  };
}
