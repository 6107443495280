/* eslint-disable */
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import styles from './list.module.less';

export const getColumns = (params) => {
  return [
    {
      title: "FC Code",
      align: "center",
      dataIndex: "agentCode"
    },
    {
      title: "FC Name",
      align: "center",
      dataIndex: "userName"
    },
    {
      title: "Completed Date",
      align: "center",
      dataIndex: "dateCompleted"
    },
    {
      title: "Effect Year",
      align: "center",
      dataIndex: "effectiveYear"
    },
    {
      title: "Course Code",
      align: "center",
      dataIndex: "courseCode"
    },
    {
      title: "Course Name",
      align: "center",
      dataIndex: "courseName"
    },
    {
      title: "CPD Hours Assigned to Course",
      align: "center",
      dataIndex: "courseCpd",
      render: (data) => {
        const list = [
          `Ethics (${data['Ethics'] || 0})`,
          `R&R (${data['R&R'] || 0})`,
          `ERR (${data['ERR'] || 0})`,
          `CIS (${data['CIS'] || 0})`,
          `A&H (${data['A&H'] || 0})`,
          `Others (${data['Others'] || 0})`,
        ];

        const inner = list.map(item => {
          return <p style={{marginBottom: 5}}>{item}</p>
        })

        return (
          <div>
            {inner}
          </div>
        )
      }
    },
    {
      title: "Effective CPD Hours",
      align: "center",
      dataIndex: "effectiveCpd",
      render: (data, row) => {
        const { courseCpd } = row;

        const list = [
          {
            label: `Ethics (${data['Ethics'] || 0})`,
            isUpdated: (data['Ethics'] || 0) - (courseCpd['Ethics'] || 0) !== 0
          },
          {
            label: `R&R (${data['R&R'] || 0})`,
            isUpdated: (data['R&R'] || 0) - (courseCpd['R&R'] || 0) !== 0
          },
          {
            label: `ERR (${data['ERR'] || 0})`,
            isUpdated: (data['ERR'] || 0) - (courseCpd['ERR'] || 0) !== 0
          },
          {
            label: `CIS (${data['CIS'] || 0})`,
            isUpdated: (data['CIS'] || 0) - (courseCpd['CIS'] || 0) !== 0
          },
          {
            label: `A&H (${data['A&H'] || 0})`,
            isUpdated: (data['A&H'] || 0) - (courseCpd['A&H'] || 0) !== 0
          },
          {
            label: `Others (${data['Others'] || 0})`,
            isUpdated: (data['Others'] || 0) - (courseCpd['Others'] || 0) !== 0
          },
        ];

        const inner = list.map(item => {
          return <p style={{ marginBottom: 5 }} className={item.isUpdated && styles.redHighlight}>{item.label}</p>
        })

        return (
          <div>
            {inner}
          </div>
        )
      }
    },
    {
      title: "Last Update Time",
      dataIndex: "updateTime",
      align: "center",
    },
    {
      title: "Updated by",
      dataIndex: "updateUser",
      align: "center",
    },
    {
      title: "Reason of Update",
      dataIndex: "updateReason",
      align: "center",
    },
    {
      title: "Option",
      dataIndex: "option",
      ellipsis: true,
      // fixed: "right",
      align: "center",
      width: 150,
      render: (text, record) => {
        return (
          <div className="option-area">
            <span className={styles.editBtn} onClick={() => {
              console.log('record,', record)
              params.setModalData({
                ...params.modalData,
                ...record
              });
              params.setModalModalVisible(true);
            }}>
              Edit
            </span>

            <span className={`${styles.editBtn} ${!record.edited ? styles.disableLink: ''}`} onClick={() => {
              if (!record.edited) {
                return;
              }
              params.linkToHistoryPage(record.courseUserId)
            }}>
              View History
            </span>

          </div>
        );
      }
    }
  ]
}
