import { notification } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";

const INIT_CHANGE_PASSWORD = "INIT_CHANGE_PASSWORD";

const initState = {
  userName: {
    value: ""
  },
  account: {
    value: ""
  },
  oldPassword: {
    value: ""
  },
  password: {
    value: ""
  },
  passwordAgain: {
    value: ""
  }
};

// reducer
export default function ChangePasswordReducer(state = initState, action) {
  switch (action.type) {
    case "ChangePassword":
      return { ...state, ...action.payload };
    case INIT_CHANGE_PASSWORD:
      return {
        ...state,
        oldPassword: {
          value: ""
        },
        password: {
          value: ""
        },
        passwordAgain: {
          value: ""
        }
      };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_CHANGE_PASSWORD
  };
}


// Create change passd_word api
export function changePassword(payload) {
  return dispatch => {
    dispatch(loading(true));
    post("/login/changePassword", payload)
      .then(res => {
        if (res.success) {
          notification.success({
            message: "Password successfully changed",
            duration: globalPromptTime10s
          });
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
