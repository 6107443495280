import { combineReducers } from "redux";
import systemSettingsReducers from "./systemSettingsReducers";
import attendanceReducers from "./attendanceReducers";
import commonRenducers from "./commonRenducers/reducer";

export default combineReducers({
  systemSettingsReducers,
  attendanceReducers,
  commonRenducers
});
