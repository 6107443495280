import types from './types';

const initialState = {
  isRistrationTab: true,
  currentSessiondayId: '',
  courseAttendanceList: [],
  loadingCourseAttendanceList: false
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TABS_CHANGES:
      return {
        ...state,
        isRistrationTab: action.isRistrationTab,
      };

    case types.SESSION_DAY_CHANGES:
      return {
        ...state,
        currentSessiondayId: action.currentSessiondayId,
      };

    default:
    return {
      ...state,
      ...action.payload
    };
  }
};

export default userManagementReducer;
