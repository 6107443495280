import React from "react";
import { connect } from "react-redux";
import { Form, Select, Input, Button, Row, Col, Spin, Icon } from "antd";
import styles from "./AdminDetail.module.less";
import {
  handleSubmit,
  getuserDetial
} from "../../../../../redux/pacsReducer/systemSettingsReducers/otherInfoReducer";

const { Option } = Select;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({
    pacsReducer: { systemSettingsReducers: { CandidateAdminReducer, userManagementReducer, otherInfoReducer } },
  }) => ({ CandidateAdminReducer, userManagementReducer, otherInfoReducer }),
  { handleSubmit, getuserDetial }
)
class CandidatePD extends React.Component {
  state = {
    isEditable: false
  };

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { bu, userId } = state;
    this.props.getuserDetial({ userId }, bu);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, history } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const callback = () =>
          history.push("/home/system_settings/user_management");
        this.props.handleSubmit(values, callback);
      }
    });
  };

  handleEmailChange = e => {
    this.props.form.setFieldsValue({
      loginaccount: e.target.value
    });
  };

  handleEdit = () => {
    const { isEditable } = this.state;
    this.props.form.resetFields();
    this.setState(() => ({ isEditable: !isEditable }));
  };

  render() {
    const {
      form: { getFieldDecorator },
      userManagementReducer: { buList },
      otherInfoReducer: { userInfo = {}, updateAdminLoading }
    } = this.props;

    const { isEditable } = this.state

    const {
      roleName,
      bu,
      username,
      email,
      contactnumber,
      loginaccount
    } = userInfo;

    return (
      <div className={styles.container}>
        <Spin spinning={updateAdminLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            PD Candidate Admin Info
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: roleName
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: bu
                  })(
                    <Select disabled>
                      {buList.map(elt => (
                        <Option value={elt.id} key={elt.id}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginaccount", {
                    initialValue: loginaccount
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: username
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      }
                    ],
                    initialValue: email
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactnumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ],
                    initialValue: contactnumber
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={
                      isEditable ? styles.editbalBtn : styles.submitBtn
                    }
                    disabled={!isEditable}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleEdit}
                    className={styles.cancelBtn}
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </Button>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const CandidateAdminPDDetail = Form.create()(CandidatePD);

export default CandidateAdminPDDetail;
