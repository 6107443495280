import React from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Row,
  Col,
  Modal,
  Menu,
  Dropdown,
  Icon,
  Alert
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./UserManagementHome.module.less";
import { pwdFormat, pwdTips } from "../../../../../utils/global";
import { getMenuList } from "../../../../../redux/homeReducer";
import {
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  handleRefrash,
  saveTemporaryData,
  resetPassword,
  getResetDefaultPassword
} from "../../../../../redux/regionalReducer/systemSettingsReducers/userManagementReducer";
import { handleSplitUrl } from "../../../../../utils";
import { RED_TRIANGLE } from "../../../../../images";
import { showActionByRole } from "../../../../../utils/tableUtils"

const { Option } = Select;
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol24 = {
  span: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ homeReducer, regionalReducer: { systemSettingsReducers: { userManagementReducer } } }) => ({
    homeReducer,
    userManagementReducer
  }),
  {
    getMenuList,
    getUserList,
    resendEmail,
    handleActivate,
    saveFields,
    handleRefrash,
    saveTemporaryData,
    resetPassword,
    getResetDefaultPassword
  }
)

class HomeForm extends React.Component {
  state = {
    visible: false,
    resetData: {},
    columns: []
  };

  componentDidMount() {
    const {
      homeReducer: { buttonList },
    } = this.props;

    const { rolerList } = this.props.userManagementReducer;

    let columns = this.state.columns;
    if (rolerList && rolerList[0]) {
      columns = this.getColumn(rolerList[0].roleid, rolerList)
      this.setState({
        columns
      })
    }

    if (!buttonList.length) {
      const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
      this.props.getMenuList(tabKey, sideKey);
    }

    const { noRefrash } = this.props.userManagementReducer;
    if (noRefrash) {
      const {
        usefulTemporaryData: { buRole, searchedData }
      } = this.props.userManagementReducer;
      this.props.getUserList(
        {
          ...searchedData
        },
        buRole
      );
      this.props.handleRefrash(false);
    } else {
      this.handleInitSearchData();
    }
  }

  getColumn = (roleId, roleList) => {
    const showAction = showActionByRole(roleId, roleList);
    return [
      {
        title: "Name",
        dataIndex: "userName",
        ellipsis: true,
        align: "center",
        render: (text, record) => {
          const { userid, userId, userName, username } = record;
          const {
            buRole,
            roleId,
            roleName,
            buCode
          } = this.props.userManagementReducer.usefulTemporaryData;
          const url = buRole && buRole.toLowerCase();
          const pathname = handleGotoDetail({ url, buCode });

          return (
            <span onClick={() => this.props.handleRefrash(true)}>
              <Link
                to={{
                  pathname,
                  state: {
                    userId: userid || userId,
                    bu: buRole,
                    roleId,
                    roleName
                  }
                }}
              >
                {username || userName}
              </Link>
            </span>
          );
        }
      },
      {
        title: "Role",
        align: "center",
        dataIndex: "roleCode",
        render: () => {
          const {
            roleName
          } = this.props.userManagementReducer.usefulTemporaryData;
          return <span>{roleName}</span>;
        }
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        align: "center",
        render: text => (
          <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
        )
      },
      {
        title: "Creation Date",
        align: "center",
        dataIndex: "createTime",
        hasFilter: ['PACSAdmin'],
        render: (text, record) => {
          const { createTime, createtime } = record;
          const time = createTime || createtime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
          );
        }
      },
      {
        title: "Last Update Date",
        align: "center",
        dataIndex: "updateTime",
        hasFilter: ['PACSAdmin'],
        render: (text, record) => {
          const { updateTime, updatetime } = record;
          const time = updateTime || updatetime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
          );
        }
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
        render: text => {
          const statusArray = ["NoLogin", "Inactive", "Active"];
          return <span>{statusArray[text]}</span>;
        }
      },
      {
        title: "Actions",
        align: "center",
        render: (text, record) => {
          const {
            status,
            loginaccount,
            loginAccount,
            userid,
            userId,
            username,
            userName,
          } = record;
          const {
            userManagementReducer: {
              usefulTemporaryData: {
                buRole
              }
            }
          } = this.props
          const isStatus0 = status === "0";
          const isActivate = status === '1' ? 'Activate' : 'Deactivate'
          const isAnyButton = showAction;

          const menu = (
            <Menu>
              {
                showAction ? <Menu.Item onClick={() =>
                  this.handleShowModal(
                    {
                      userName: username || userName,
                      loginAccount: loginaccount || loginAccount,
                    }
                  )
                }
                >
                  Reset Password
                             </Menu.Item> : null
              }
              {
                showAction ? <Menu.Item onClick={() =>
                  this.handleClickActivate(
                    { userId: userid || userId },
                    buRole,
                    isActivate
                  )
                }
                >
                  {isActivate}
                             </Menu.Item> : null
              }
              {
                showAction && isStatus0 ?
                  (
                    <Menu.Item
                      onClick={() =>
                        this.props.resendEmail(
                          {
                            loginAccount: loginaccount || loginAccount,
                            subject: "init_password"
                          },
                          buRole
                        )
                      }
                    >
                      Resend Email
                    </Menu.Item>
                  )
                  : null
              }
            </Menu>
          )
          return (
            isAnyButton && <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomCenter"
              overlayClassName={styles.ant_dev_dropdown}
            >
              <span className={styles.spanCss}>
                <span className={styles.text}>More</span>
                <Icon type="caret-down" style={{ color: '#1890ff' }} />
              </span>
                           </Dropdown>
          )
        }
      }
    ]
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          tempBuRole,
          tempRoleName,
          tempBuCode
        } = this.props.userManagementReducer.usefulTemporaryData;
        const {
          rolerList
        } = this.props.userManagementReducer
        const columns = this.getColumn(values.roleId, rolerList)
        this.setState({
          columns
        })
        const valuesData = {};
        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else {
            valuesData[item] = elt;
          }
        });
        const {
          roleId,
        } = values;

        const submitData = {
          ...valuesData,
          currentPageNumber: 1,
        };

        this.props.getUserList({ ...submitData }, tempBuRole);
        this.props.saveTemporaryData({
          roleId,
          searchedData: { ...submitData },
          roleName: tempRoleName,
          buCode: tempBuCode,
          buRole: tempBuRole,
        });
      }
    });
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  handleCancelResetPwd = (props) => {
    props.form.resetFields()
    this.setState({ resetData: {}, visible: false });
  };

  handleResetPassword = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { searchedData, buRole } = this.props.userManagementReducer.usefulTemporaryData;
        const { loginAccount } = this.state.resetData
        const { password } = values
        const submitData = {
          password,
          loginAccount,
        }
        const callback = () =>
          this.props.getUserList(
            { ...searchedData },
            buRole
          );
        this.props.resetPassword(submitData, buRole, callback);
        this.setState({
          resetData: {},
          visible: false
        });
        form.resetFields()
      }
    });
  };

  handleShowModal = (resetData) => {
    const { buRole } = this.props.userManagementReducer.usefulTemporaryData;
    this.setState({
      resetData,
      visible: true,
    });
    this.props.getResetDefaultPassword(buRole)
  };

  handleClickActivate = (payload, buRole, type) => {
    const {
      searchedData
    } = this.props.userManagementReducer.usefulTemporaryData;
    const callback = () =>
      this.props.getUserList(
        { ...searchedData },
        buRole
      );
    this.props.handleActivate(payload, buRole, type, callback);
  };


  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { buRole, searchedData } = this.props.userManagementReducer.usefulTemporaryData;

    this.props.saveTemporaryData({
      searchedData: {
        ...searchedData,
        currentPageNumber: current
      }
    });

    this.props.getUserList(
      {
        ...searchedData,
        pageSize,
        currentPageNumber: current
      },
      buRole
    );
  };

  handleRoleChange = (val, option) => {
    const {
      props: { bu, name, code }
    } = option;
    if (bu === "ADMIN") {
      const reg = /^PACS/i
      const isPacs = reg.test(code)
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showUserStatus: isPacs
      });
    }
    this.handleSaveField("roleId", val);
  };

  handleResetForm = () => {
    this.handleInitSearchData();
    this.props.form.resetFields();
  };


  handleColumns = ({ columns = [], buCode }) => {
    const filterColumns = columns.filter((elt) => {
      const { hasFilter } = elt
      if (hasFilter) {
        return hasFilter.includes(buCode)
      }
      return !hasFilter
    })
    return filterColumns || []
  }

  handleInitSearchData = () => {
    const {
      userManagementReducer: { rolerList }
    } = this.props;
    const rolerListLength = rolerList[0] || {}
    const roleId = rolerListLength.roleid;
    const buRole = rolerListLength.bu;
    const roleName = rolerListLength.name;
    const buCode = rolerListLength.code;
    const showUserStatus = false;

    const queryData = {
      roleId, currentPageNumber: 1
    }
    this.props.getUserList({ roleId, currentPageNumber: 1 }, buRole);
    this.props.saveTemporaryData({
      roleId,
      buRole,
      tempBuRole: buRole,
      roleName,
      tempRoleName: roleName,
      buCode,
      tempBuCode: buCode,
      showUserStatus,
      searchedData: queryData,
      fieldsData: {}
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      homeReducer: { buttonList },
      userManagementReducer: {
        rolerList,
        userData: { userList, total, pageSize },
        tableLoading,
        userStatusList = [],
        defaultPassword,
        usefulTemporaryData: {
          searchedData: { currentPageNumber },
          fieldsData,
          buCode,
          showUserStatus,
        }
      }
    } = this.props;
    const { columns, visible, resetData } = this.state;
    const { importList, createList } = handleButtonList(buttonList);

    return (
      <div className={styles.container}>
        <div className={styles.actionWrap}>
          <Row gutter={[20, 10]} type="flex">
            {importList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Import" }}
                  className={styles.buttonBox}
                  defaultActiveFirstOption={false}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {importList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}

            {createList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Create" }}
                  className={styles.buttonBox}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  defaultActiveFirstOption={false}
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {createList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}
          </Row>
        </div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("roleId", {
                  initialValue:
                    fieldsData.roleId || (rolerList[0] && rolerList[0].roleid)
                })(
                  <Select
                    placeholder="Select a option"
                    onChange={this.handleRoleChange}
                  >
                    {rolerList.map(elt => (
                      <Option
                        value={elt.roleid}
                        key={elt.roleid}
                        bu={elt.bu}
                        name={elt.name}
                        code={elt.code}
                      >
                        {elt.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Name">
                {getFieldDecorator("userName", {
                  initialValue: fieldsData.userName
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("userName", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            {showUserStatus ? (
              <Col {...flexCol}>
                <Form.Item label="User Status">
                  {getFieldDecorator("status", {
                    initialValue: fieldsData.status || ''
                  })(
                    <Select
                      onChange={e =>
                        this.handleSaveField("status", e)
                      }
                      allowClear
                    >
                      <Option value=''>All</Option>
                      {
                        userStatusList.map((elt) => {
                          return (
                            <Option key={elt.value} value={elt.value}>{elt.code}</Option>
                          )
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
        <div className={styles.serachBox}>
          <Button onClick={this.handleSubmit} className={styles.ant_btn}>
            Search
          </Button>
          {/* <Button
            onClick={this.handleResetForm}
            className={styles.ant_btn_reset}
          >
            Reset
          </Button> */}
        </div>
        <div className={styles.table}>
          <Table
            columns={this.handleColumns({ columns, buCode })}
            dataSource={userList}
            pagination={{
              total,
              pageSize,
              current: currentPageNumber
            }}
            size="small"
            loading={tableLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(rowInfo) => rowInfo.userid || rowInfo.userId}
          />
        </div>

        <ModelForm
          handleOk={this.handleResetPassword}
          handleCancelResetPwd={this.handleCancelResetPwd}
          visible={visible}
          resetData={resetData}
          defaultPassword={defaultPassword}
        />
      </div>
    );
  }
}

function handleButtonList(list) {
  const importList = [];
  const createList = [];
  list.map(item => {
    if (item.group === "import") {
      importList.push(item);
    } else if (item.group === "create") {
      createList.push(item);
    }
  });
  return { importList, createList };
}

const UserManagementHome = Form.create({ name: "homePage" })(HomeForm);

export default UserManagementHome;

function handleGotoDetail({ buCode }) {
  // PACSCandidateAdmin
  switch (buCode) {
    case "PAMBAdmin":
    case "PACSAdmin":
    case "PLUKAdmin":
    case "PCLAAdmin":
    case "PHKLSystemAdmin":
      return "/home/system_settings/user_management/lbu_admin_detail";
    case "RegionalAdmin":
      return "/home/system_settings/user_management/region_admin_detail";
    default:
      return '';
  }
}

function Formes2(props) {
  const { form: { getFieldDecorator }, visible, resetData, handleOk, defaultPassword, handleCancelResetPwd } = props
  return (
    <Modal
      title="Reset Password"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancelResetPwd(props)}
      okText='Reset'
      okType='danger'
    >
      <Form
        colon={false}
        {...formLayout}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol24}>
            <Form.Item label="User Name">
              {getFieldDecorator("userName", {
                initialValue: resetData.userName
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: pwdFormat,
                    message: "You have entered an invalid password"
                  }
                ],
                initialValue: defaultPassword
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Alert message={pwdTips} type="info" />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModelForm = Form.create()(Formes2);
