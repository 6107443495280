import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button, Select } from "antd";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import { myProfile } from "../../../../redux/userReducer";
import { saveProfile } from "../../../../redux/pacsReducer/myProfileReducers/myProfileReducer";
// import AgentPacs from "./Agent_PACS";
// import AgentPamb from "./Agent_PAMB";
import styles from "./styles.module.less";


const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};


const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const flexRow = {
  gutter: [22, 0]
};

const CreateForm = Form.create({
  name: "CreateMyProfile_Form",
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { isEditable, role, bu, userName, email, contactNum, account } = props;
  return (
    <Form
      labelAlign="left"
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Role">
            {getFieldDecorator("role", {
              initialValue: role
            })(
              <Select disabled>
                {props.rolerList.map(item => (
                  <Option value={item.roleid} key={item.roleid}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="BU">
            {getFieldDecorator("bu", {
              initialValue: bu
            })(
              <Select disabled>
                {props.buList.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.value}
                  </Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {
              initialValue: account
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Name">
            {getFieldDecorator("userName", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                }
              ],
              initialValue: userName
            })(<Input placeholder="Name" disabled={isEditable} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                },
                {
                  type: "email",
                  message: "Please input the correct email format"
                }
              ],
              initialValue: email
            })(<Input placeholder="Email" disabled={isEditable} />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Contact Number">
            {getFieldDecorator("contactNum", {
              rules: [
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                },
                {
                  pattern: /^[0-9]{0,}$/,
                  message: "Only numbers are allowed"
                }
              ],
              initialValue: contactNum
            })(<Input disabled={isEditable} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={insideStyles.goCenter}
          className={styles.submitBox}
        >
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                props.handleSubmit(props.form, () =>
                  props.handleEdit(props, true));
              }}
              className={isEditable ? styles.submitBtn : styles.editbalBtn}
              disabled={isEditable}
            >
              Save
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => props.handleEdit(props, false)}
              className={styles.cancelBtn}
            >
              {isEditable ? "Edit" : "Cancel"}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => ({
    fields: {
      ...state.pacsReducer.myProfileReducers.myProfileReducer,
      role: state.userReducer.roleid || state.userReducer.roleId,
      bu: state.userReducer.bu,
      account: state.userReducer.loginaccount,
    },
    user: { ...state.userReducer },
    buList: state.pacsReducer.systemSettingsReducers.userManagementReducer.buList.length
      ? [...state.pacsReducer.systemSettingsReducers.userManagementReducer.buList]
      : [],
    rolerList: state.pacsReducer.systemSettingsReducers.userManagementReducer.allRoleList
      .length
      ? [...state.pacsReducer.systemSettingsReducers.userManagementReducer.allRoleList]
      : []
  }),
  {
    changeVals,
    myProfile,
    saveProfile
  }
)
class MyProfile extends Component {
  state = {
    isEditable: true
  };

  componentDidMount() {
    this.props.changeVals("myProfile", {
      userName: this.props.user.username,
      email: this.props.user.email,
      contactNum: this.props.user.contactnumber
    });
  }

  handleEdit = (props, isTrue) => {
    const { form } = props; // eslint-disable-line
    const { isEditable } = this.state;
    if (!isEditable && !isTrue) {
      // this.props.myProfile();
      form.resetFields();
    }
    this.setState(() => ({ isEditable: !isEditable }));
  };

  handleSubmit = (form, cb) => {
    form.validateFields(null, { first: true }, (err, values) => {
      if (!err) {
        const { contactNum, email, account, userName } = values;
        this.props.saveProfile(
          {
            contactnumber: contactNum,
            email,
            loginaccount: account,
            username: userName
          },
          cb
        );
      }
    });
  };

  render() {
    const props = {
      rolerList: this.props.rolerList,
      buList: this.props.buList,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      handleEdit: this.handleEdit,
      isEditable: this.state.isEditable
    };

    // const roleId = this.props.fields.role || ''
    // const pamblist = ['10', '5', '14', '15', '16']
    // const pacslist = ['11', '8', '12']
    // let Dom = null
    // if (pamblist.includes(roleId)) {
    //   Dom = AgentPamb
    // } else if (pacslist.includes(roleId)) {
    //   Dom = AgentPacs
    // }

    return (
      <div className={styles.container}>
        <div className={styles.title}>My Profile</div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};

export default MyProfile;
