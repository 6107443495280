import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  Table,
  Empty,
  notification
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  innit_data,
  getRegistrationSummaryReport,
  getCourseNameList,
  getCourseCodeList,
  getSessionNameList,
  downloadRegistrationSummaryReport
} from "../../../../redux/pacsReducer/CPDReportReducers/RegistrationSummaryReducer";
import { globalPromptTime10s, switchDate } from "../../../../utils/global";
import styles from "./styles.module.less";

function disabledDate(current) {
  // Can not select days before 2022
  return switchDate() && current < moment('20220101');
}

const CreateForm = Form.create({
  name: "CreateRegistrationSummary_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("RegistrationSummary", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      courseCode: Form.createFormField({
        ...props.courseCode,
        value: props.courseCode.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      reportDate: Form.createFormField({
        ...props.reportDate,
        value: props.reportDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow
                  defaultActiveFirstOption={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    console.log(option) // eslint-disable-line
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={(e) => {
                    props.getSessionNameList(e);
                  }}
                >
                  {props.courseNameList.length
                    ? props.courseNameList.map(item => (
                      <Option value={item.courseId} key={item.courseId}>
                        {item.courseName}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Course Code">
              {getFieldDecorator("courseCode", {})(
                <Select
                  showSearch
                  showArrow
                  allowClear
                  defaultActiveFirstOption={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    console.log(option) // eslint-disable-line
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                >
                  <Option value="">All</Option>
                  {props.courseCodeList.length
                    ? props.courseCodeList.map(item => (
                      <Option value={item.courseCode} key={item.courseCode}>
                        {item.courseCodeText}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>

          <Col {...flexCol}>
            <Form.Item label="Report Date Range">
              {getFieldDecorator("reportDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                  disabledDate={disabledDate}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {})(
                <Select
                  showSearch
                  showArrow
                  allowClear
                  defaultActiveFirstOption={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    console.log(option) // eslint-disable-line
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                >
                  <Option value="">All</Option>
                  {props.sessionNameList.length
                    ? props.sessionNameList.map(item => (
                      <Option value={item.sessionId} key={item.sessionId}>
                        {item.sessionName}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
        >
          {" "}
        </div>
        <Button
          style={{ display: props.searchList.length ? "inline-block" : "none" }}
          className={styles.btn}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_summary();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Table
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            bordered
            scroll={{ x: 1750 }}
            className={styles.ant_dev_table}
            columns={props.columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            dataSource={props.calTableData(props)}
            size="small"
            rowKey="index"
          />

        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { CPDReportReducers: { RegistrationSummaryReducer } },
    PublicReducer: { loading },
  }) => ({
    fields: RegistrationSummaryReducer,
    loading
  }),
  {
    changeVals,
    innit_data,
    getRegistrationSummaryReport,
    getCourseNameList,
    getCourseCodeList,
    getSessionNameList,
    downloadRegistrationSummaryReport
  }
)
class RegistrationSummary extends Component {
  constructor(props) {
    super(props);
    this.download_summary = this.download_summary.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "No",
          dataIndex: "No",
          align: "center",
          width: 50,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Module Name",
          dataIndex: "moduleName",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Training Date",
          dataIndex: "trainingDate",
          align: "center",
          width: 320,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Trainer Name",
          dataIndex: "trainerName",
          align: "center",
          width: 180,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC Code",
          dataIndex: "fcCode",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent Name",
          dataIndex: "agentName",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Rank",
          dataIndex: "rank",
          align: "center",
          width: 80,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent Status",
          dataIndex: "userStatusName",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status",
          dataIndex: "withdrawnStatus",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
      ]
    };
  }

  componentDidMount() {
    this.props.innit_data();
    this.props.getCourseCodeList();
    this.props.getCourseNameList();
    // this.props.getRegistrationSummaryReport({
    //   courseCode: "",
    //   courseId: "dhDtLijZ7p41",
    //   endDate: "",
    //   sessionId: "",
    //   startDate: "",
    //   pageSize: 10,
    //   currentPageNumber: 1,
    // });
  }

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { fields } = this.props;
    this.props.getRegistrationSummaryReport({
      courseCode: fields.courseCode.value,
      courseId: fields.courseName.value,
      endDate: fields.reportDate.value.length
        ? fields.reportDate.value[1].format("YYYY-MM-DD")
        : "",
      sessionId: fields.sessionName.value,
      startDate: fields.reportDate.value.length
        ? fields.reportDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize,
      currentPageNumber: current
    });
  };





  handleSubmit = form => {
    form.validateFields(null, { first: false }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.reportDate.value.length) {
          const end = fields.reportDate.value[1].valueOf();
          const start = fields.reportDate.value[0];
          const nowYear = moment(start).add(1, "year").valueOf();
          if (end <= nowYear) {
            this.props.getRegistrationSummaryReport({
              courseCode: fields.courseCode.value,
              courseId: fields.courseName.value,
              endDate: fields.reportDate.value.length
                ? fields.reportDate.value[1].format("YYYY-MM-DD")
                : "",
              sessionId: fields.sessionName.value,
              startDate: fields.reportDate.value.length
                ? fields.reportDate.value[0].format("YYYY-MM-DD")
                : "",
              pageSize: this.state.pageSize,
              currentPageNumber: this.state.pageIndex,
            })
          } else {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
          }
        }
      }
    });
  };

  download_summary() {
    const { fields } = this.props;
    this.props.downloadRegistrationSummaryReport({
      courseCode: fields.courseCode.value,
      courseId: fields.courseName.value,
      endDate: fields.reportDate.value.length
        ? fields.reportDate.value[1].format("YYYY-MM-DD")
        : "",
      sessionId: fields.sessionName.value,
      startDate: fields.reportDate.value.length
        ? fields.reportDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize: this.props.pageSize,
      currentPageNumber: this.props.currentPageNumber
    });
  }

  calTableData(props) {
    const {searchList = [], currentPageNumber = 1, pageSize = 10} = props
    if (searchList.length > 0) {
      const res = searchList.map((item, index) => {
        return {
          No: (currentPageNumber - 1) * pageSize + index + 1,
          ...item
          };
        });
        return res;
    }
    return [];
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      download_summary: this.download_summary,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      getSessionNameList: this.props.getSessionNameList,
      calTableData: this.calTableData
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>Registration Summary Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24
};

export default RegistrationSummary;
