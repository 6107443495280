import React from "react";
import { Form, Input, Row, Col, Icon, Popover, Popconfirm, Button, Spin } from "antd";
// import moment from "moment";
import { connect } from "react-redux";
import dayjs from 'dayjs'
import TitleAndTabel from "./TitleAndTabel";
import {
  getAgentDetail,
  updateStatus,
  handleDownloadxls
} from "../../../../../redux/pambReducer/CPDSettingsReducers/TRagentDetailReducer";
import { getTRsearchData } from "../../../../../redux/pambReducer/CPDSettingsReducers/trainingRequirementSearchReducer";
import styles from "./TRagentLIAMDetail.module.less";

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({
    pambReducer: { CPDSettingsReducers: {
      TRagentDetailReducer,
      trainingRequirementSearchReducer
    } }
  }) => ({
    TRagentDetailReducer,
    trainingRequirementSearchReducer
  }),
  { getAgentDetail, updateStatus, getTRsearchData, handleDownloadxls }
)
class HomeForm extends React.Component {
  state = {
    columns: [
      {
        title: "Course Name",
        dataIndex: "courseName",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Course Type",
        dataIndex: "courseType",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Department",
        dataIndex: "department",
        align: "center"
      },
      {
        title: "Training Date",
        dataIndex: "trainingDate",
        align: "center",
        render: (text, record) => {
          const { trainingDates = [] } = record;
          if (trainingDates && trainingDates.length > 1) {
            const content = (
              <div className={styles.contentBox}>
                {trainingDates.map(elt => {
                  return <p key={elt}>{elt}</p>;
                })}
              </div>
            );

            return (
              <div className={styles.datebox}>
                <span className={styles.firstdate}>{trainingDates[0]}</span>
                <Popover
                  placement="right"
                  title="Training Date"
                  content={content}
                  trigger="click"
                  overlayClassName="ant_popC_training_date"
                >
                  <span className={styles.datemore}>···</span>
                </Popover>
              </div>
            );
          }

          return <div>{(trainingDates && trainingDates.length) ? trainingDates[0] : ""}</div>;
        }
      },
      {
        title: "Training Session Status",
        dataIndex: "trainingSessionStatus",
        align: "center"
      },
      {
        title: "Training Hours",
        dataIndex: "cpdHours",
        align: "center",
      },
      {
        title: "Completed Date",
        dataIndex: "completeDate",
        align: "center",
      },
      {
        title: "Course Completed Status",
        dataIndex: "courseStatus",
        align: "center"
      },
      {
        title: "Operation",
        align: "center",
        render: (text, record) => {
          const { courseStatus, courseId, highLight } = record;
          const {
            location: { state = {} }
          } = this.props;
          const { userId, type } = state;
          if (courseStatus === "YES(overdue)" && highLight !== 0) {
            return (
              <Popconfirm
                title="Please double confirm?"
                onConfirm={() => this.handleUpdate({ userId, courseId, type })}
                okText="Yes"
                cancelText="No"
              >
                <span className={styles.spanCss}>Update</span>
              </Popconfirm>
            );
          }

          return <span />;
        }
      }
    ]
  };

  componentDidMount() {
    const { state = {} } = this.props.location;
    const { userId, type, searchPramas } = state;
    this.props.getAgentDetail({ userId, type });
    this.props.getTRsearchData(searchPramas, true);
  }

  handleUpdate = payload => {
    const { state = {} } = this.props.location;
    const { userId, type, searchPramas } = state;
    const callback = () => {
      this.props.getAgentDetail({ userId, type });
      this.props.getTRsearchData(searchPramas, true);
    };
    this.props.updateStatus(payload, callback);
  };

  handleDownload = props => {
    const { liam6monReq, liam12monReq, liam24monReq, state, rfpExamStatus } = props;
    const { userId, type } = state || {};
    this.props.handleDownloadxls({
      userId,
      type,
      requirementStatus1: liam6monReq,
      requirementStatus2: liam12monReq,
      requirementStatus3: liam24monReq,
      requirementStatus4: rfpExamStatus,
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      TRagentDetailReducer: { agentDetailData, detailLoading },
      trainingRequirementSearchReducer: { TRsearchData },
      location: { state }
    } = this.props;

    const {
      name,
      agentId,
      agentCode,
      nric,
      liamContractDate,
      first6MonthDeadline,
      first6MonthList = [],
      first12MonthDeadline,
      first12MonthList = [],
      first24MonthDeadline,
      first24MonthList = [],
      rfpExamList = [],
      rfpExamStatus,
      rfpExamDeadline,
      reappointedIndicator
    } = agentDetailData;
    const { columns } = this.state;
    // const { liam6monReq, liam12monReq, liam24monReq } = state || {};
    const { liam6monReq, liam12monReq, liam24monReq } = handleGetReqStatus(
      TRsearchData,
      state
    );
    const isContractBefore2024 = dayjs(liamContractDate).isBefore("2024-01-01", "day");
    return (
      <div className={styles.container}>
        <Spin spinning={detailLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/training_history/new_agent_training_history/search"
                )
              }
            />
            NEW AGENT REPORT
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    initialValue: name
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC Number">
                  {getFieldDecorator("NRICNumber", {
                    initialValue: nric
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent ID">
                  {getFieldDecorator("agentId", {
                    initialValue: agentId
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="LIAM Contract Date">
                  {getFieldDecorator("liamContractDate", {
                    initialValue: liamContractDate
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent Code">
                  {getFieldDecorator("agentCode", {
                    initialValue: agentCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Reappointed Indicator">
                  {getFieldDecorator("reappointedIndicator", {
                    initialValue: reappointedIndicator
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.downloadBox}>
            <Button
              className={styles.downloadBtn}
              icon="download"
              onClick={() =>
                this.handleDownload({
                  liam6monReq,
                  liam12monReq,
                  liam24monReq,
                  rfpExamStatus,
                  state
                })
              }
            >
              Download
            </Button>
          </div>
          <TitleAndTabel
            columns={columns}
            title={`LIAM 1st Year Agent Training Requirements (20 Training Hours) - Deadline: ${first6MonthDeadline ||
              ""}`}
            dataSource={first6MonthList}
            status={liam6monReq || ""}
          />
          {isContractBefore2024 &&
            <>
              <TitleAndTabel
                columns={columns}
                title={`LIAM 1st Year Agent Training Requirements (10 Training Hours) - Deadline: ${first12MonthDeadline ||
                  ""}`}
                dataSource={first12MonthList}
                status={liam12monReq || ""}
              />
              <TitleAndTabel
                columns={columns}
                title={`LIAM 2nd Year Agent Training Requirements (20 Training Hours) - Deadline: ${first24MonthDeadline ||
                  ""}`}
                dataSource={first24MonthList}
                status={liam24monReq || ""}
              />
            </>
          } <TitleAndTabel
            columns={columns}
            title={`LIAM – RFP M2 / CFP M2 / FLCP Exam - PITA - Deadline: ${rfpExamDeadline || ""}`}
            dataSource={rfpExamList}
            status={rfpExamStatus || ""}
          />
        </Spin>
      </div >
    );
  }
}
const TRagentLIAMDetail = Form.create()(HomeForm);

export default TRagentLIAMDetail;

function handleGetReqStatus(data = {}, state = {}) {
  const { items } = data;
  if (items) {
    const { userId } = state;
    const reqstatusData = items.find(elt => {
      return elt.userId === userId;
    });
    return reqstatusData || {};
  }
  return {};
}
