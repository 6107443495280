import { get, post } from "../../../utils/request";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pcla/TrainingHistoryReportReducer/SAVE";

const initState = {
  trainingHistoryData: {},
  leaderNameList: [],
  fcCodeList: [],
  unitCodeList: [],
  agentTypeList: [
    {
      code: 'candidate',
      value: 'Candidate'
    },
    {
      code: 'nne',
      value: 'NNE'
    },
    {
      code: 'agent',
      value: 'Agent'
    }
  ],
  agentStatusList: [{ code: '0', value: 'Active' }, { code: '2', value: 'In-Active' }],
  courseStatusList: [{ code: '1', value: 'Completed' }, { code: '0', value: 'Incompleted' }],
  sessionStatusList: [{ code: '1', value: 'Completed' }, { code: '0', value: 'Incompleted' }],
  channelList: [{ code: 'AD', value: 'AD' }, { code: 'PD', value: 'PD' }],
  tableLoading: false,
  downloadLoading: false
};

// reducer
export default function TrainingHistoryReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleQueryData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/pclaCPDCalculate/getAgentHistoryReportList", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false,
            trainingHistoryData: data || {}
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function getFcCodeList(payload, cb) {
  return dispatch => {
    get("/GrantHoursController/getPclaUserInfoSelect2", payload)
      .then(res => {
        const { data = {} } = res;
        dispatch({
          type: SAVE,
          payload: {
            fcCodeList: data || []
          }
        });
        if (cb) { cb() }
      })
      .catch(() => { });
  };
}

export function getLeaderNameList() {
  return dispatch => {
    get("/cpdReport/getAgentLeaderSelect")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            leaderNameList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getAgentStatusList() {
  return dispatch => {
    get("/cpdReport/getAgentStatusSelect")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            agentStatusList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getUnitCodeList() {
  return dispatch => {
    get("/cpdReport/getUnitCodeSelect")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            unitCodeList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export const downloadData = (payload) => {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/pclaCPDCalculate/downloadAgentHistoryReport", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Training History Report.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export function reSetSessionList() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        leaderNameList: []
      }
    });
  };
}
