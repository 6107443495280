import React from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Alert,
  DatePicker
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./UserManagementHome.module.less";
import { getMenuList } from "../../../../../redux/homeReducer";
import {
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  handleRefrash,
  saveTemporaryData,
  resetPassword,
  clickExtension,
  getResetDefaultPassword
} from "../../../../../redux/plukReducer/systemSettingsReducers/userManagementReducer";
import { handleSplitUrl } from "../../../../../utils";
import { pwdFormat, pwdTips } from "../../../../../utils/global";
import { RED_TRIANGLE } from "../../../../../images";
import { showActionByRole } from "../../../../../utils/tableUtils"

const { Option } = Select;
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};
const { RangePicker } = DatePicker;

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol24 = {
  span: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ userReducer, homeReducer, plukReducer: { systemSettingsReducers: { userManagementReducer } } }) => ({
    homeReducer,
    userReducer,
    userManagementReducer
  }),
  {
    getMenuList,
    getUserList,
    resendEmail,
    handleActivate,
    saveFields,
    handleRefrash,
    saveTemporaryData,
    resetPassword,
    clickExtension,
    getResetDefaultPassword
  }
)
class HomeForm extends React.Component {
  state = {
    visible: false,
    extensionModal: false,
    resetData: {},
    columns: []
  };

  componentDidMount() {
    const {
      homeReducer: { buttonList },
    } = this.props;

    const { rolerList } = this.props.userManagementReducer;

    let columns = this.state.columns
    if (rolerList && rolerList[0]) {
      columns = this.getColumn(rolerList[0].roleid, rolerList)
      this.setState({
        columns
      })
    }

    if (!buttonList.length) {
      const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
      this.props.getMenuList(tabKey, sideKey);
    }

    const { noRefrash } = this.props.userManagementReducer;
    // const { columns } = this.state;
    if (noRefrash) {
      const {
        usefulTemporaryData: { buCode, buRole, searchedData }
      } = this.props.userManagementReducer;
      this.props.getUserList(
        {
          ...searchedData
        },
        buRole
      );
      this.props.handleRefrash(false);
      this.handleColumns({ columns, buCode, buRole });
    } else {
      this.handleInitSearchData();
    }
  }

  getColumn = (roleId, rolerList) => {
    const showAction = showActionByRole(roleId, rolerList);
    return [
      {
        title: "Name",
        dataIndex: "userName",
        ellipsis: true,
        align: "center",
        render: (text, record) => {
          const { userid, userId, userName, username, roleCode } = record;
          const {
            buRole,
            searchedData: { roleId },
            roleName,
            buCode
          } = this.props.userManagementReducer.usefulTemporaryData;
          const pathname = handleGotoDetail(buCode);
          return (
            <span onClick={() => this.props.handleRefrash(true)} title={username || userName}>
              <Link
                to={{
                  pathname,
                  state: {
                    userId: userid || userId,
                    bu: buRole,
                    roleId,
                    roleName: roleCode === 'PLUK AGENT LEADER' ? 'PLUK Agent Leader' : roleName
                  }
                }}
              >
                {username || userName}
              </Link>
            </span>
          );
        }
      },
      {
        title: "Role",
        align: "center",
        dataIndex: "roleCode",
        render: (text, record) => {
          const {
            roleName
          } = this.props.userManagementReducer.usefulTemporaryData;
          const { roleCode } = record
          if (roleCode === 'PLUK AGENT LEADER') {
            return <span>PLUK Agent Leader</span>;
          } else if (roleCode === 'PLUK AGENT') {
            return <span>PLUK Agent</span>;
          } else {
            return <span>{roleName}</span>;
          }
        }
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        align: "center",
        render: text => (
          <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
        )
      },
      {
        title: "Creation Date",
        align: "center",
        dataIndex: "createTime",
        render: (text, record) => {
          const { createTime, createtime } = record;
          const time = createTime || createtime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
          );
        }
      },
      {
        title: "Last Update Date",
        align: "center",
        dataIndex: "updateTime",
        render: (text, record) => {
          const { updateTime, updatetime } = record;
          const time = updateTime || updatetime;
          return (
            <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
          );
        }
      },
      {
        title: "Lock Status",
        align: "center",
        dataIndex: "lockStatus",
        render: (text) => {
          let isLock = ''
          if (text === '0') {
            isLock = 'No'
          } else if (text === '1') {
            isLock = "Yes"
          }
          return <span>{isLock}</span>
        }
      },
      {
        title: "Expired Date",
        align: "center",
        dataIndex: "expiredDate",
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
        render: text => {
          const statusArray = ["NoLogin", "Inactive", "Active"];
          return <span>{statusArray[text]}</span>;
        }
      },
      {
        title: "Actions",
        align: "center",
        // width: "240px",
        render: (text, record) => {
          const {
            status,
            loginaccount,
            loginAccount,
            userid,
            userId,
            username,
            userName,
          } = record;
          const {
            userManagementReducer: {
              usefulTemporaryData: {
                buRole,
                buCode
              }
            },
            userReducer: {
              roleid
            }
          } = this.props
          const isStatus0 = status === "0";
          const isActivate = status === '1' ? 'Activate' : 'Deactivate'
          const isExtension = buCode === 'PLUKCandidate' || buCode === 'PLUKGUEST'
          const isPlukLbuAdmin = roleid === '300'
          const isAnyButton = showAction;

          const menu = (
            <Menu>
              {showAction && isPlukLbuAdmin ?
                (
                  <Menu.Item onClick={() =>
                    this.handleShowModal(
                      {
                        userName: username || userName,
                        loginAccount: loginaccount || loginAccount,
                      }
                    )
                  }
                  >
                    Reset Password
                  </Menu.Item>
                )
                : null
              }
              {
                (isPlukLbuAdmin && isExtension) ? (
                  <Menu.Item onClick={() =>
                    this.handleShowExtensionModal(
                      { userId: userid || userId }
                    )
                  }
                  >
                    Extension
                  </Menu.Item>
                ) : null
              }
              {
                showAction ? <Menu.Item onClick={() =>
                  this.handleClickActivate(
                    { userId: userid || userId },
                    buRole,
                    isActivate
                  )
                }
                >
                  {isActivate}
                             </Menu.Item> : null
              }
              {
                showAction && isStatus0 ?
                  (
                    <Menu.Item
                      onClick={() =>
                        this.props.resendEmail(
                          {
                            loginAccount: loginaccount || loginAccount,
                            subject: "init_password"
                          },
                          buRole
                        )
                      }
                    >
                      Resend Email
                    </Menu.Item>
                  )
                  : null
              }
            </Menu>
          )
          return (
            isAnyButton && <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomCenter"
              overlayClassName={styles.ant_dev_dropdown}
            >
              <span className={styles.spanCss}>
                <span className={styles.text}>More</span>
                <Icon type="caret-down" style={{ color: '#1890ff' }} />
              </span>
                           </Dropdown>
          )
        }
      }
    ]
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          tempBuRole,
          tempRoleName,
          tempBuCode
        } = this.props.userManagementReducer.usefulTemporaryData;
        const {
          rolerList
        } = this.props.userManagementReducer
        // const { columns } = this.state;
        const columns = this.getColumn(values.roleId, rolerList)
        this.setState({
          columns
        })
        const valuesData = {};
        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else if (elt && typeof elt === "object") {
            // valuesData[item] = elt;
            valuesData.codingDateStart = elt[0] ? moment(elt[0]).format("YYYY-MM-DD") : '';
            valuesData.codingDateEnd = elt[1] ? moment(elt[1]).format("YYYY-MM-DD") : '';
          }
        });
        const { roleId } = values
        const submitData = {
          ...valuesData,
          currentPageNumber: 1,
        };
        this.props.getUserList({ ...submitData }, tempBuRole);
        this.props.saveTemporaryData({
          roleId,
          searchedData: { ...submitData },
          roleName: tempRoleName,
          buCode: tempBuCode,
          buRole: tempBuRole,
        });
      }
    });
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  handleColumns = () => { };

  handleShowModal = (resetData) => {
    const { buRole } = this.props.userManagementReducer.usefulTemporaryData;
    this.setState({
      resetData,
      visible: true,
    });
    this.props.getResetDefaultPassword(buRole)
  };

  handleShowExtensionModal = (resetData) => {
    this.setState({
      resetData,
      extensionModal: true,
    });
  };

  handleResetPassword = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { searchedData, buRole } = this.props.userManagementReducer.usefulTemporaryData;
        const { loginAccount } = this.state.resetData
        const { password } = values
        const submitData = {
          password,
          loginAccount,
        }
        const callback = () =>
          this.props.getUserList(
            { ...searchedData },
            buRole
          );
        this.props.resetPassword(submitData, buRole, callback);
        this.setState({
          resetData: {},
          visible: false
        });
        form.resetFields()
      }
    });
  };

  handleExtension = () => {
    const { searchedData, buRole, roleId } = this.props.userManagementReducer.usefulTemporaryData;
    const { userId } = this.state.resetData
    const callback = () =>
      this.props.getUserList(
        { ...searchedData },
        buRole
      );
    this.props.clickExtension({ roleId, userId }, callback);
    this.setState({
      resetData: {},
      extensionModal: false
    });
  };

  handleClickActivate = (payload, buRole, type) => {
    const {
      searchedData
    } = this.props.userManagementReducer.usefulTemporaryData;
    const callback = () =>
      this.props.getUserList(
        { ...searchedData },
        buRole
      );
    this.props.handleActivate(payload, buRole, type, callback);
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { buRole, searchedData } = this.props.userManagementReducer.usefulTemporaryData;

    this.props.saveTemporaryData({
      searchedData: {
        ...searchedData,
        currentPageNumber: current
      }
    });

    this.props.getUserList(
      {
        ...searchedData,
        pageSize,
        currentPageNumber: current
      },
      buRole
    );
  };

  handleCancelResetPwd = (props) => {
    props.form.resetFields()
    this.setState({ resetData: {}, visible: false });
  };

  handleCancelExtension = () => {
    this.setState({ resetData: {}, extensionModal: false });
  };

  handleRoleChange = (val, option) => {
    const {
      props: { bu, name, code }
    } = option;
    if (bu === "ADMIN") {
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        // showLeaderName: false,
        // showUnitCode: false,
        // showFcCode: false,
        // showChannel: false,
        // showLoginAccount: false,
        showAgentCode: false,
        showFirstName: false,
        showMiddleName: false,
        showLastName: false,
        showMobile: false,
        showsignUpDate: false,
        showName: true
      });
    } else if (bu === "PLUK") { // add by zhy
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        // showFcCode: code === 'PLUKAgent' || code === 'PLUKAgentLeader',
        // showUnitCode: true,
        // showLeaderName: true,
        // showChannel: code === 'PLUKAgent' || code === 'PLUKCandidate',
        // showLoginAccount: code === 'PLUKCandidate',
        showAgentCode: code === 'PLUKAgent' || code === 'PLUKTrainer',
        showFirstName: true,
        showMiddleName: true,
        showLastName: true,
        showMobile: true,
        showsignUpDate: true,
        showName: false
      });
    }
    this.handleSaveField("roleId", val);
  };

  handleResetForm = () => {
    this.handleInitSearchData();
    this.props.form.resetFields();
  };

  handleInitSearchData = () => {
    const {
      userManagementReducer: { rolerList },
      userReducer: { roleid }
    } = this.props;
    const rolerListLength = rolerList[0] || {}
    const roleId = rolerListLength.roleid;
    const buRole = rolerListLength.bu;
    const roleName = rolerListLength.name;
    const buCode = rolerListLength.code;
    // const showLeaderName = false;
    // const showUnitCode = false;
    // const showFcCode = false;
    // const showChannel = false
    // const showLoginAccount = false

    const showAgentCode = true;
    const showFirstName = true;
    const showMiddleName = true;
    const showLastName = true;
    const showMobile = true;
    const showsignUpDate = true;
    const showName = true;

    const queryData = {
      roleId, currentPageNumber: 1, channel: this.handleChannelInit(roleid)
    }

    this.props.getUserList(queryData, buRole);
    this.props.saveTemporaryData({
      roleId,
      buRole,
      tempBuRole: buRole,
      roleName,
      tempRoleName: roleName,
      buCode,
      tempBuCode: buCode,
      // showLeaderName,
      // showUnitCode,
      // showFcCode,
      // showChannel,
      // showLoginAccount,
      searchedData: queryData,
      fieldsData: {},
      showAgentCode: !showAgentCode,
      showFirstName: !showFirstName,
      showMiddleName: !showMiddleName,
      showLastName: !showLastName,
      showMobile: !showMobile,
      showsignUpDate: !showsignUpDate,
      showName
    });
  };

  handleChannelInit = (roleid, channel) => {
    if (roleid === '3') {
      return "AD"
    } else if (roleid === '17') {
      return 'PD'
    } else {
      return channel
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      homeReducer: { buttonList },
      // userReducer: { roleid },
      userManagementReducer: {
        rolerList,
        userData: { userList, total, pageSize },
        tableLoading,
        userStatusList = [],
        // channelList = [],
        defaultPassword,
        usefulTemporaryData: {
          searchedData: { currentPageNumber },
          // showLeaderName,
          // showUnitCode,
          fieldsData,
          // showFcCode,
          // showChannel,
          // showLoginAccount,
          showAgentCode,
          showFirstName,
          showMiddleName,
          showLastName,
          showMobile,
          showsignUpDate,
          showName,
          tempBuCode
        }
      }
    } = this.props;
    const { columns, visible, resetData, extensionModal } = this.state;
    const { importList, createList } = handleButtonList(buttonList);
    // const channelInit = this.handleChannelInit(roleid, fieldsData.channel)
    return (
      <div className={styles.container}>
        <div className={styles.actionWrap}>
          <Row gutter={[20, 10]} type="flex">
            {importList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Import" }}
                  className={styles.buttonBox}
                  defaultActiveFirstOption={false}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {importList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}

            {createList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Create" }}
                  className={styles.buttonBox}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  defaultActiveFirstOption={false}
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {createList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}
          </Row>
        </div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("roleId", {
                  initialValue:
                    fieldsData.roleId || (rolerList[0] && rolerList[0].roleid)
                })(
                  <Select
                    placeholder="Select a option"
                    onChange={this.handleRoleChange}
                  >
                    {rolerList.map(elt => (
                      <Option
                        value={elt.roleid}
                        key={elt.roleid}
                        bu={elt.bu}
                        name={elt.name}
                        code={elt.code}
                      >
                        {elt.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            {
              showName ? (
                <Col {...flexCol}>
                  <Form.Item label="Name">
                    {getFieldDecorator("userName", {
                      initialValue: fieldsData.userName
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("userName", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }

            {
              showAgentCode ? (
                <Col {...flexCol}>
                  <Form.Item label="Agent Code">
                    {getFieldDecorator("agentCode", {
                      initialValue: fieldsData.agentCode
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("agentCode", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }

            {
              showFirstName ? (
                <Col {...flexCol}>
                  <Form.Item label="First Name">
                    {getFieldDecorator("firstName", {
                      initialValue: fieldsData.firstName
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("firstName", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }

            {showMiddleName ? (
              <Col {...flexCol}>
                <Form.Item label="Middle Name">
                  {getFieldDecorator("middleName", {
                    initialValue: fieldsData.middleName
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("middleName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showLastName ? (
              <Col {...flexCol}>
                <Form.Item label="Last Name">
                  {getFieldDecorator("lastName", {
                    initialValue: fieldsData.lastName
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("lastName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>

            ) : null}

            {showMobile ? (
              <Col {...flexCol}>
                <Form.Item label="Mobile">
                  {getFieldDecorator("contactNumber", {
                    initialValue: fieldsData.contactNumber
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("contactNumber", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>

            ) : null}

            {showsignUpDate ? (
              <Col {...flexCol}>
                <Form.Item label={tempBuCode === 'PLUKCandidate' ? "Sign Up Date" : "Coding Date"}>
                  {getFieldDecorator("signUpDate", {
                    initialValue: fieldsData.signUpDate
                  })(
                    <RangePicker
                      placeholder={["Start Date", "End Date"]}
                      style={{ width: "100%" }}
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                    />
                  )}
                </Form.Item>
              </Col>

            ) : null}

            <Col {...flexCol}>
              <Form.Item label="Status">
                {getFieldDecorator("status", {
                  initialValue: fieldsData.status
                })(
                  <Select
                    onChange={e =>
                      this.handleSaveField("status", e)
                    }
                    allowClear
                  >
                    {
                      userStatusList.map((elt) => {
                        return (
                          <Option key={elt.value} value={elt.value}>{elt.code}</Option>
                        )
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

        </Form>
        <div className={styles.serachBox}>
          <Button onClick={this.handleSubmit} className={styles.ant_btn}>
            Search
          </Button>
          {/* <Button
            onClick={this.handleResetForm}
            className={styles.ant_btn_reset}
          >
            Reset
          </Button> */}
        </div>
        <div className={styles.table}>
          <Table
            columns={columns}
            dataSource={userList}
            pagination={{
              total,
              pageSize,
              current: currentPageNumber
            }}
            size="small"
            loading={tableLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(rowInfo) => rowInfo.userid || rowInfo.userId}
          />
        </div>

        <ModelForm
          handleOk={this.handleResetPassword}
          handleCancelResetPwd={this.handleCancelResetPwd}
          visible={visible}
          resetData={resetData}
          defaultPassword={defaultPassword}
        />

        <Modal
          title=''
          visible={extensionModal}
          onOk={this.handleExtension}
          onCancel={this.handleCancelExtension}
          okText='Yes'
          cancelText='No'
          okType='danger'
        >
          Are you sure you would like to extend this User?
        </Modal>
      </div>
    );
  }
}

function handleButtonList(list) {
  const importList = [];
  const createList = [];
  list.map(item => {
    if (item.group === "import") {
      importList.push(item);
    } else if (item.group === "create") {
      createList.push(item);
    }
  });
  return { importList, createList };
}

const UserManagementHome = Form.create({ name: "homePage" })(HomeForm);

export default UserManagementHome;


function handleGotoDetail(buCode) {
  switch (buCode) {
    case "PLUKCandidate":// add by zhy
      return "/home/system_settings/user_management/candidate_detail";
    case "PLUKGUEST":
      return "/home/system_settings/user_management/guest_detail";
    case "PLUKAdmin":
      return "/home/system_settings/user_management/lbu_admin_detail";
    case "PLUKCourseAdmin":// add by zhy
      return "/home/system_settings/user_management/course_admin_detail";

    case "PLUKTrainer":// add by zhy
    case "PLUKAgent":
      return "/home/system_settings/user_management/pluk_detail";

    case "PLUKADCandidateAdmin":
      return "/home/system_settings/user_management/ad_candidate_admin_detail";
    case "PLUKPDCandidateAdmin":
      return "/home/system_settings/user_management/pd_candidate_admin_detail";
    default:
      return '/home/system_settings/user_management/pacs_detail';
  }
}

function Formes2(props) {
  const { form: { getFieldDecorator }, visible, resetData, handleOk, defaultPassword, handleCancelResetPwd } = props
  return (
    <Modal
      title="Reset Password"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancelResetPwd(props)}
      okText='Reset'
      okType='danger'
    >
      <Form
        colon={false}
        {...formLayout}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol24}>
            <Form.Item label="User Name">
              {getFieldDecorator("userName", {
                initialValue: resetData.userName
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: pwdFormat,
                    message: "You have entered an invalid password"
                  }
                ],
                initialValue: defaultPassword
              })(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol24}>
            <Alert message={pwdTips} type="info" />
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModelForm = Form.create()(Formes2);
