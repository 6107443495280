import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col, Spin, Icon } from "antd";
import styles from "./GuestDetail.module.less";
import {
  updateGuest,
  getDetail,
  getLeaderNameByUnitCode,
  resetLeaderName,
  handleReset
} from "../../../../../redux/pacsReducer/systemSettingsReducers/GuestReducer";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const formLayout2 = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ userReducer, pacsReducer: { systemSettingsReducers: { GuestReducer } } }) => ({
    GuestReducer,
    userReducer
  }),
  { updateGuest, getDetail, getLeaderNameByUnitCode, resetLeaderName, handleReset }
)
class Formes extends React.Component {
  state = {
    isEditable: false
  };

  componentDidMount() {
    const { location } = this.props;
    const { state = {} } = location;
    const { userId } = state;
    this.props.getDetail({ userId });
  }

  componentWillUnmount() {
    this.props.handleReset()
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, history } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const callback = () =>
          history.push("/home/system_settings/user_management");
        const { location } = this.props;
        const { state = {} } = location;
        const { userId } = state;
        this.props.updateGuest({ ...values, userId }, callback);
      }
    });
  };

  handleUnitcodeChange = e => {
    this.props.form.resetFields(["leaderName"]);
    this.props.getLeaderNameByUnitCode({ unitCode: e.target.value });
  };

  handleEdit = () => {
    const { isEditable } = this.state;
    this.props.form.resetFields();
    const { leaderName } = this.props.GuestReducer.userInfo;
    this.props.resetLeaderName(leaderName);
    this.setState(() => ({ isEditable: !isEditable }));
  };

  render() {
    const {
      form: { getFieldDecorator },
      GuestReducer: { createLoading, leaderName, userInfo },
      userReducer
    } = this.props;

    const {
      loginAccount,
      userName,
      email,
      bankEmployeeID,
      contactNumber,
      unitCode,
      nric
    } = userInfo;

    const { isEditable } = this.state;

    return (
      <div className={styles.container}>
        <Spin spinning={createLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            Guest Info
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: "Guest"
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: "PACS"
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginAccount", {
                    initialValue: loginAccount
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("userName", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: userName
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      }
                    ],
                    initialValue: email
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Banker Employee ID" {...formLayout2}>
                  {getFieldDecorator("bankEmployeeID", {
                    initialValue: bankEmployeeID
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC">
                  {getFieldDecorator("nric", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: nric
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: unitCode
                  })(
                    <Input
                      onChange={this.handleUnitcodeChange}
                      disabled={!isEditable}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Leader Name">
                  {getFieldDecorator("leaderName", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: leaderName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ],
                    initialValue: contactNumber
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
            </Row>
            {userReducer.roleid === "6" ? null : (
              <>
                <Row {...flexRow}>
                  <Col className={styles.submitBox}>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={this.handleSubmit}
                        className={
                          isEditable ? styles.editbalBtn : styles.submitBtn
                        }
                        disabled={!isEditable}
                      >
                        Save
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        onClick={this.handleEdit}
                        className={styles.cancelBtn}
                      >
                        {isEditable ? "Cancel" : "Edit"}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Spin>
      </div>
    );
  }
}

const GuestDetail = Form.create()(Formes);

export default GuestDetail;
