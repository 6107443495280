/* eslint-disable */
import { message } from "antd";
import types from './types';
import { get, post } from "../../../../utils/request";
import { globalPromptTime, globalPromptTime1s } from "../../../../utils/global";

export const getUserList = (payload, buRole) => async (dispatch) => {
  try {
    dispatch({
      type: types.BEFORE_GET_USER_LIST
    });

    const response = await post("/user/userList", payload, { buRole });
    const { data: { items, total, currentPageNumber, pageSize, data } } = response;
    const userData = {
      total,
      pageSize,
      pageNumber: currentPageNumber,
      userList: items || data
    };

    dispatch({
      type: types.GET_USER_LIST,
      payload: {
        userData
      }
    });

  } catch (error) {
    dispatch({
      type: types.GET_USER_LIST_ERROR
    });
  }
};

export const getUserRoleList = () => async (dispatch) => {
  try {
    const response = await get("/user/roleList");
    const { data } = response;

    dispatch({
      type: types.GET_ROLE_LIST,
      payload: {
        rolerList: data
      }
    });
  } catch (error) {

  }
};


export const getRankList = () => async (dispatch) => {
  try {
    const response = await get("/user/rankList");
    const { data } = response;

    dispatch({
      type: types.GET_RANK_LIST,
      payload: {
        rankList: data || []
      }
    });
  } catch (error) {

  }
};

export const getUserStatusList = () => async (dispatch) => {
  try {
    const response = await get("/user/getStatusList");
    const { data } = response;

    dispatch({
      type: types.GET_USER_STATUS_LIST,
      payload: {
        userStatusList: data || []
      }
    });
  } catch (error) {

  }
};

export const getAllRoleList = () => async (dispatch) => {
  try {
    const response = await get("/user/allRoleList");
    const { data } = response;

    dispatch({
      type: types.GET_USER_ROLE_LIST,
      payload: {
        allRoleList: data
      }
    });
  } catch (error) {

  }
};

export const getAllRolerList = () => async (dispatch) => {
  try {
    const response = await get("/systemSetting/getRoleList");
    const { data } = response;

    dispatch({
      type: types.GET_SYSTEM_ROLE_LIST,
      payload: {
        allRolerList: data
      }
    });
  } catch (error) {

  }
};

export const getBuList = () => async (dispatch) => {
  try {
    const response = await get("/systemSetting/getBuList");
    const { data } = response;

    dispatch({
      type: types.GET_BU_LIST,
      payload: {
        buList: data
      }
    });
  } catch (error) {

  }
};

export const resendEmail = (payload, buRole) => async (dispatch) => {
  try {
    dispatch({
      type: types.BEFORE_SEND_EMAIL,
      payload: {
        resendEmaiLoading: true
      }
    });

    await get("/login/generateLink", payload, { buRole });
    message.info("Operation Success", globalPromptTime);
    dispatch({
      type: types.RE_SEND_EMAIL,
      payload: {
        resendEmaiLoading: false
      }
    });
  } catch (error) {
    dispatch({
      type: types.SEND_EMAIL_ERROR,
      payload: {
        resendEmaiLoading: false
      }
    });
  }
};

export const handleActivate = (payload, buRole, type, callback) => async (dispatch) => {
    try {
      dispatch({
        type: types.BEFORE_ACTIVATE,
        payload: {
          activateLoading: true
        }
      });

      await post(`/user/${type}`, payload, { buRole });
      message.info("Operation Success！", globalPromptTime1s, callback);

      dispatch({
        type: types.HANDLE_ACTIVATE,
        payload: {
          activateLoading: false
        }
      });
    } catch (error) {
      dispatch({
        type: types.HANDLE_ACTIVATE_ERROR,
        payload: {
          activateLoading: false
        }
      });
    }
};

export const saveFields = (field, val) => (dispatch, getState) => {
  const {
    systemSettingsReducers: {
      userManagementReducer: { usefulTemporaryData }
    }
  } = getState().phklReducer;

  const { fieldsData } = usefulTemporaryData;
  fieldsData[field] = val;
  dispatch({
    type: types.SAVE_FIELDS,
    payload: {
      usefulTemporaryData
    }
  });
};
