import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import { globalPromptTime10s } from "../../../utils/global";

const INITDATA_MANAGEMENT_REPORT_PCLA = "PCLA/INITDATA_MANAGEMENT_REPORT_PCLA";
const SAVEDATA_MANAGEMENT_REPORT_PCLA = "PCLA/SAVEDATA_MANAGEMENT_REPORT_PCLA";

const initState = {
  agentLeader: {
    value: ""
  },
  unitCode: {
    value: ""
  },
  trainingDateRange: {
    value: ""
  },
  agentStatus: {
    value: "2"
  },
  contractDateRange: {
    value: ""
  },
  fcCode: {
    value: ""
  },
  terminationDateRange: {
    value: ""
  },
  fullFill: {
    value: ""
  },
  includeShiftedHours: {
    value: true
  },

  agentLeaderList: [],
  unitCodeList: [],
  agentStatusList: [],
  fcCodeList: [],
  fullFillList: [],

  searchList: [],
  newColumns: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1
};

export default function ManagementReportPCLAReducer(state = initState, action) {
  switch (action.type) {
    case "ManagementReportPCLA":
      return { ...state, ...action.payload };
    case INITDATA_MANAGEMENT_REPORT_PCLA:
      return { ...initState };
    case SAVEDATA_MANAGEMENT_REPORT_PCLA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_MANAGEMENT_REPORT_PCLA
  };
}

export function save_data(payload) {
  return {
    type: SAVEDATA_MANAGEMENT_REPORT_PCLA,
    payload
  };
}

// GET /cpdReport/getAgentLeaderSelect
export function getAgentLeaderSelect() {
  return dispatch => {
    get("/cpdReport/getAgentLeaderSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                agentLeaderList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getAgentStatusSelect
export function getAgentStatusSelect() {
  return dispatch => {
    get("/cpdReport/getAgentStatusSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                agentStatusList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getFcCodeSelect
export function getFcCodeSelect() {
  return dispatch => {
    get("/cpdReport/getFcCodeSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                fcCodeList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getFullFillSelect
export function getFullFillSelect() {
  return dispatch => {
    get("/cpdReport/getFullFillSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                fullFillList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getUnitCodeSelect
export function getUnitCodeSelect() {
  return dispatch => {
    get("/cpdReport/getUnitCodeSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                unitCodeList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /cpdReport/getReportList
export function getReportList(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/cpdReport/getReportList", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          const finalData = res.data ? [...res.data] : [];
          finalData.map((item) => {
            for (let q in item.attainedMap) {
              item[`attained_${q}`] = item.attainedMap[q];
            };
            for (let w in item.requirementMap) {
              item[`requirement_${w}`] = item.requirementMap[w];
            };
            for (let e in item.shortFallMap) {
              item[`shortFall_${e}`] = item.shortFallMap[e];
            };
            return item;
          });
          /* eslint-disable */
          console.log("finalData:", finalData);
          dispatch(
            save_data({
              searchList: finalData
              // totalPage: res.data.totalPage,
              // total: res.data.total,
              // pageSize: res.data.pageSize,
              // currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pacs/attendanceReport/downloadRegistrationSummaryReport
export function downloadRegistrationSummaryReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/attendanceReport/downloadRegistrationSummaryReport", data, {}, "blob")
      .then(blob => {
        const fileName = "RegistrationSummaryReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
