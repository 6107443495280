import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button } from "antd";
import { changeVals } from "../../../../redux/regionalReducer/systemSettingsReducers/index.action";
import { changePassword, init_data } from "../../../../redux/regionalReducer/myProfileReducers/ChangePasswordReducer";
import styles from "./styles.module.less";
import { isPacsAgent, getIsAADLogin } from '../../../../utils/global'

const CreateForm = Form.create({
  name: "CreateChangePassword_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("ChangePassword", changedFields);
  },
  mapPropsToFields(props) {
    return {
      userName: Form.createFormField({
        ...props.userName,
        value: props.userName.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      oldPassword: Form.createFormField({
        ...props.oldPassword,
        value: props.oldPassword.value
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password.value
      }),
      passwordAgain: Form.createFormField({
        ...props.passwordAgain,
        value: props.passwordAgain.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { errMsg, roleId } = props;
  const userisPacsAgent = isPacsAgent(roleId);

  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      labelAlign="left"
      wrapperCol={{ span: 17 }}
      colon={false}
    >
      <Row gutter={[24, 0]}>
        <Col {...flexCol}>
          <Form.Item label="Name">
            {getFieldDecorator("userName", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                }
              ]
            })(<Input disabled placeholder="Name" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                }
              ]
            })(<Input disabled placeholder="Login Account" />)}
          </Form.Item>
        </Col>
        {
          getIsAADLogin() ? null :
            <Col {...flexCol}>
              <Form.Item
                label="Password"
                validateStatus={errMsg.type === "1" ? "error" : null}
              >
                {getFieldDecorator("oldPassword", {})(
                  <Input.Password placeholder="Password" />
                )}
              </Form.Item>
            </Col>
        }
        <Col {...flexCol}>
          <Form.Item
            label="New Password"
            validateStatus={errMsg.type === "2" ? "error" : null}
          >
            {getFieldDecorator("password", {})(
              <Input.Password placeholder="New Password" />
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item
            label="Confirm Password"
            validateStatus={errMsg.type === "3" ? "error" : null}
          >
            {getFieldDecorator("passwordAgain", {})(
              <Input.Password placeholder="Confirm Password" />
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <div style={{ textAlign: "center" }}>Password must contain: 1 upper case, 1 lower case, 1 number, 1 special character, 8-15 characters</div>
          {
            !userisPacsAgent && <div style={{ textAlign: "center", fontWeight: 'bold' }}>This only changes your PruBeHere password and has no impact if you are using AD account to login to RTMS</div>
          }
          <div style={insideStyles.prompt}>
            {errMsg.title}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={insideStyles.goCenter}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={`${styles.btnColor} ${styles.btnText}`}
              onClick={() => {
                props.handleSubmit(props.form);
              }}
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => ({
    fields: {
      ...state.regionalReducer.myProfileReducers.ChangePasswordReducer,
      userName: {
        value: state.userReducer.username || state.userReducer.userName
      },
      account: {
        value: state.userReducer.loginaccount || state.userReducer.loginAccount
      },
    },
    userReducer: state.userReducer
  }),
  {
    changeVals,
    changePassword,
    init_data
  }
)
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
      errMsg: {
        title: "",
        type: ""
      }
    };
  }

  componentDidMount() {
    this.props.init_data();
  }

  handleSubmit = () => {
    const { fields } = this.props;
    this.setState({
      errMsg: {
        title: "",
        type: ""
      }
    });

    if (fields.oldPassword.value) {
      const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
      if (!reg.test(fields.oldPassword.value)) {
        this.setState({
          errMsg: {
            title: `You have entered an invalid password`,
            type: "1"
          }
        });
        return;
      }
    } else {
      if (!getIsAADLogin()) {
        this.setState({
          errMsg: {
            title: "You have entered an invalid password",
            type: "1"
          }
        });
        return;
      }
    }

    if (fields.password.value) {
      const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
      if (!reg.test(fields.password.value)) {
        this.setState({
          errMsg: {
            title: `You have entered an invalid password`,
            type: "2"
          }
        });
        return;
      }
    } else {
      this.setState({
        errMsg: {
          title: "You have entered an invalid password",
          type: "2"
        }
      });
      return;
    }

    if (fields.passwordAgain.value) {
      const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
      if (!reg.test(fields.passwordAgain.value)) {
        this.setState({
          errMsg: {
            title: `You have entered an invalid password`,
            type: "3"
          }
        });
        return;
      }
    } else {
      this.setState({
        errMsg: {
          title: "You have entered an invalid password",
          type: "3"
        }
      });
      return;
    }

    //

    if (fields.password.value === fields.passwordAgain.value) {
      this.props.changePassword({
        loginAccount: fields.account.value,
        newPassword: fields.password.value,
        newPasswordAgain: fields.passwordAgain.value,
        oldPassword: fields.oldPassword.value
      });
    } else {
      this.setState({
        errMsg: {
          title:
            "Confirm password is inconsistent with new password",
          type: ""
        }
      });
    }
  };

  render() {
    const { userReducer } = this.props;

    return (
      <div className={styles.container} style={{ paddingTop: "50px" }}>
        <CreateForm
          {...this.props.fields}
          errMsg={this.state.errMsg}
          handleSubmit={this.handleSubmit}
          onChange={this.props.changeVals}
          roleId={userReducer?.roleId || userReducer?.roleid || ''}
        />
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  prompt: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#ED1B2E",
    letterSpacing: 0,
    textAlign: "center",
    marginBottom: "35px",
    marginTop: "20px"
  }
};

const flexCol = {
  lg: 12,
  offset: 6
};

export default ChangePassword;
