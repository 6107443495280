import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import { globalPromptTime10s } from "../../../utils/global";

const INITDATA_MANAGEMENT_REPORT = "PAMB/INITDATA_MANAGEMENT_REPORT";
const SAVE_MANAGEMENT_REPORT_DATA = "PAMB/SAVE_MANAGEMENT_REPORT_DATA";

const initState = {
  status: {
    value: ""
  },
  trainingDate: {
    value: ""
  },
  contractDate: {
    value: ""
  },
  searchList: [],
  statusList: [],
  showList: false
};

export default function ManagementReportReducer(state = initState, action) {
  switch (action.type) {
    case "ManagementReport":
      return { ...state, ...action.payload };
    case INITDATA_MANAGEMENT_REPORT:
      return { ...initState };
    case SAVE_MANAGEMENT_REPORT_DATA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_MANAGEMENT_REPORT
  };
}

export function save_data(payload) {
  return {
    type: SAVE_MANAGEMENT_REPORT_DATA,
    payload
  };
}

// POST /pamb/report/cpdManagement
export function searchCPDReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/report/cpdManagement", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          const finalData = [];
          for (let i in res.data) {
            let index;
            switch (i) {
              case "zero":
                index = 0;
                break;
              case "oneToFour":
                index = 1;
                break;
              case "fiveToNine":
                index = 2;
                break;
              case "tenToFourTeen":
                index = 3;
                break;
              case "fifteenToNineteen":
                index = 4;
                break;
              case "twentyToTwentyFour":
                index = 5;
                break;
              case "twentyFiveToTwentyNine":
                index = 6;
                break;
              case "thirty":
                index = 7;
                break;
              case "totalNotCompleted":
                index = 8;
                break;
              case "total":
                index = 9;
                break;
              default:
                index = null;
                break;
            }
            const items = {};
            for (let j in res.data[i][0]) {
              const attr = res.data[i][0][j];
              if (attr && typeof attr === "object") {
                attr.forEach(item => {
                  const region = item.region.replace(/\s*/g, "");
                  items[region] = item.countNum;
                });
              } else {
                items[j] = attr;
              }
            }
            if (index !== null) {
              finalData[index] = {
                index,
                key: i,
                ...items
              };
            } else {
              finalData.push({
                index,
                key: i,
                ...items
              });
            }
          }
          dispatch(
            save_data({
              searchList: [...finalData],
              showList: true
            })
          );
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
// GET /pamb/report/status
export function getStatusList() {
  return dispatch => {
    get("/report/status")
      .then(res => {
        if (res.success) {
          if (res.data.length) {
            dispatch(
              save_data({
                statusList: [...res.data]
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /pamb/report/downloadCpdManagement
export function download_management(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/report/downloadCpdManagement", data, {}, "blob")
      .then(blob => {
        const fileName = "ManagementReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
