import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get, post } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const UPDATE_SEARCH_DATA = "PACS/ADDATTENDANCE/UPDATE_SEARCH_DATA";
const INIT_DATA_ADDATTENDANCE = "PACS/ADDATTENDANCE/INIT_DATA_ADDATTENDANCE";

const initState = {
  sessiondayId: "",
  sessionId: "",
  sessionName: "",
  litmosUserId: "",
  agentCode: "",
  agentName: "",
  sessionTime: {
    value: ""
  },
  agentId: {
    value: ""
  },
  name: {
    value: ""
  },
  contractDate: {
    value: ""
  },
  walkIn: {
    value: false
  },
  contractStartDate: "",
  contractEndDate: "",
  searchList: [],
  updatingSearchList: 0
};

// reducer
export default function AddAttendanceReducer(state = initState, action) {
  switch (action.type) {
    case "AddAttendance":
      let nowName = {};
      if (action.payload.walkIn) {
        nowName = {
          name: {
            value: ""
          },
          searchList: []
        };
      }
      return { ...state, ...action.payload, ...nowName };
    case UPDATE_SEARCH_DATA:
      return { ...state, searchList: action.payload };
    case INIT_DATA_ADDATTENDANCE:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_ADDATTENDANCE
  };
}

export function update_search(payload) {
  return {
    type: UPDATE_SEARCH_DATA,
    payload
  };
}

export function search_agent_name(data) {
  return dispatch => {
    get("/attendanceTms/getAgentNameList", data)
      .then(res => {
        if (res.success) {
          const arr = [];
          const filter = [];
          res.data.forEach(item => {
            if (arr.indexOf(item.agentId) < 0) {
              arr.push(item.agentId);
              filter.push(item);
            }
          });
          setTimeout(() => {
            dispatch(update_search([...filter]));
          }, 100);
        }
      })
      .catch(() => { });
  };
}

export function add_attendance(payload, history, location) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceTms/createAttendance", payload)
      .then(res => {
        if (res.success) {
          notification.success({
            message: "Successfully created",
            duration: globalPromptTime10s
          });
          history.push("/home/attendance/session_detail", {
            ...location.state
          });
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
