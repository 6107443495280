import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Popconfirm,
  DatePicker,
  Tooltip,
  Input,
  Spin,
  Icon,
  Table,
  Select,
  Modal
} from "antd";
import moment from "moment";
import QRCode from "qrcode.react";
import { changeVals } from "../../../../redux/pclaReducer/systemSettingsReducers/index.action";
import {
  downLoad_attendance_info,
  downLoad_registration_info,
  delete_attendance,
  deleteAttendanceBatch,
  auditAttendanceBatch,
  update_base_information,
  update_attendance_agents,
  update_registration_agents,
  getSessionDayList,
  getCourseSessiondayInfo,
  save_data,
  init_data,
  getAttendanceScanTimes,
  getCurrentTime,
  uploadAttendanceInfoCheck,
  upload_attendance_info,
  getSessionLockDate,
  saveUnlockSession
} from "../../../../redux/pclaReducer/attendanceReducers/SessionDetailReducer";
import styles from "./styles.module.less";
import { globalUrlPre } from "../../../../utils/config";

import { attendanceScanIn, attendanceScanOut } from "../../../../images";
import { download_template } from "../../../../redux/PublicReducer";

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableData: {
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "#666666"
  },
  tableTitle: {
    background: "#D8D8D8",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "#666666"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};

const CreateForm = Form.create({
  name: "CreateSessionDetailPCLA_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("SessionDetail", changedFields);
  },
  mapPropsToFields(props) {
    return {
      sessionDay: Form.createFormField({
        ...props.sessionDay,
        value: props.sessionDay.value
      }),
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      moduleName: Form.createFormField({
        ...props.moduleName,
        value: props.moduleName.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      startDate: Form.createFormField({
        ...props.startDate,
        value: props.startDate.value
      }),
      endDate: Form.createFormField({
        ...props.endDate,
        value: props.endDate.value
      }),
      submitDate: Form.createFormField({
        ...props.submitDate,
        value: props.submitDate.value
      }),
      instructorName: Form.createFormField({
        ...props.instructorName,
        value: props.instructorName.value
      }),
      submitBy: Form.createFormField({
        ...props.submitBy,
        value: props.submitBy.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      ethicCpdHours: Form.createFormField({
        ...props.ethicCpdHours,
        value: props.ethicCpdHours.value
      }),
      rrCpdHours: Form.createFormField({
        ...props.rrCpdHours,
        value: props.rrCpdHours.value
      }),
      ahCpdHours: Form.createFormField({
        ...props.ahCpdHours,
        value: props.ahCpdHours.value
      }),
      cisCpdHours: Form.createFormField({
        ...props.cisCpdHours,
        value: props.cisCpdHours.value
      }),
      othersCpdHours: Form.createFormField({
        ...props.othersCpdHours,
        value: props.othersCpdHours.value
      }),
      minSessionDays: Form.createFormField({
        ...props.minSessionDays,
        value: props.minSessionDays.value
      }),
      venueRegion: Form.createFormField({
        ...props.venueRegion,
        value: props.venueRegion.value
      }),
      venueName: Form.createFormField({
        ...props.venueName,
        value: props.venueName.value
      }),
      venueAddress: Form.createFormField({
        ...props.venueAddress,
        value: props.venueAddress.value
      }),
      classCode: Form.createFormField({
        ...props.classCode,
        value: props.classCode.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const {
    loading,
    attendanceColumns,
    attendanceAgents,
    registrationColumns,
    registrationAgents,
    attendanceBtn
  } = props;

  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 10 }}
        labelAlign="left"
        wrapperCol={{ span: 14 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              <Tooltip title={props.courseName.value} placement="top">
                {getFieldDecorator("courseName", {
                  rules: []
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Module Name">
              {getFieldDecorator("moduleName", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Start Date Time">
              {getFieldDecorator("startDate", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="End Date Time">
              {getFieldDecorator("endDate", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Submit Date Time">
              {getFieldDecorator("submitDate", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Trainer">
              <Tooltip title={props.instructorName.value} placement="top">
                {getFieldDecorator("instructorName", {
                  rules: []
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Submit By">
              {getFieldDecorator("submitBy", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Status">
              {getFieldDecorator("status", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Ethic CPD Hours">
              {getFieldDecorator("ethicCpdHours", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="R&R CPD Hours">
              <Tooltip title={props.LIAMRequirement.value} placement="top">
                {getFieldDecorator("rrCpdHours", {
                  rules: []
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="A&H CPD Hours">
              {getFieldDecorator("ahCpdHours", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="CIS CPD Hours">
              {getFieldDecorator("cisCpdHours", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Others CPD Hours">
              <Tooltip title={props.MTARequirement.value} placement="top">
                {getFieldDecorator("othersCpdHours", {
                  rules: []
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Min. Session Days">
              {getFieldDecorator("minSessionDays", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col xl={24}>
            <Form.Item wrapperCol={{ span: 21 }} label="Venue Region">
              {getFieldDecorator("venueRegion", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col xl={24}>
            <Form.Item wrapperCol={{ span: 21 }} label="Venue Name">
              {getFieldDecorator("venueName", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col xl={24}>
            <Form.Item wrapperCol={{ span: 21 }} label="Venue Address">
              {getFieldDecorator("venueAddress", {
                rules: []
              })(<Input disabled />)}
            </Form.Item>
          </Col>
        </Row>
        <div style={{ borderBottom: "1px solid #e6e6e6" }}> </div>
        <div style={{ width: "100%", marginTop: "30px" }}>
          <div
            style={{
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div style={{ width: "100%" }}>
              {attendanceBtn.Registration ? (
                <Button
                  type="danger"
                  className={`${styles.btn} ${
                    props.agentType === "Registration" ? styles.active : null
                  }`}
                  style={{ width: "165px", marginRight: "10px" }}
                  onClick={() => {
                    props.onChange("SessionDetail", {
                      agentType: "Registration"
                    });
                  }}
                >
                  Registration
                </Button>
              ) : null}
              {attendanceBtn.Attendance ? (
                <Button
                  type="danger"
                  className={`${styles.btn} ${
                    props.agentType === "Attendance" ? styles.active : null
                  }`}
                  style={{ width: "165px", marginRight: "10px" }}
                  onClick={() => {
                    props.onChange("SessionDetail", {
                      agentType: "Attendance"
                    });
                  }}
                >
                  Attendance
                </Button>
              ) : null}

              <div
                style={{
                  float: "right",
                  justifyContent: "flex-end",
                  width: "auto",
                  display: props.agentType === "Attendance" ? "flex" : "none"
                }}
              >
                <Button
                  type="danger"
                  disabled={!props.deleteAttendanceArr.length}
                  className={`${styles.btn} ${styles.active}`}
                  style={{ width: "105px", borderRadius: "12.5px" }}
                  onClick={props.deleteAttendanceBatch}
                >
                  Delete
                </Button>
                {sessionStorage.getItem("roleid") === "301" ? ( // update by zhy
                  <Button
                    type="danger"
                    disabled={!props.deleteAttendanceArr.length}
                    className={`${styles.btn} ${styles.active}`}
                    style={{
                      width: "105px",
                      borderRadius: "12.5px",
                      marginLeft: "10px"
                    }}
                    onClick={props.auditAttendanceBatch}
                  >
                    Audit
                  </Button>
                ) : null}
                {sessionStorage.getItem("roleid") === "300" ? ( // update by zhy
                  <Button
                    type="danger"
                    className={`${styles.btn} ${styles.active}`}
                    style={{
                      width: "105px",
                      borderRadius: "12.5px",
                      marginLeft: "10px"
                    }}
                    onClick={props.showLockDate}
                  >
                    Unlock
                  </Button>
                ) : null}
                <Button
                  type="danger"
                  className={`${styles.btn} ${styles.active}`}
                  style={{
                    width: "105px",
                    borderRadius: "12.5px",
                    marginLeft: "10px"
                  }}
                  onClick={props.downLoad_attendance_info}
                >
                  Download
                </Button>
                <Button
                  download
                  type="danger"
                  className={`${styles.btn} ${styles.active}`}
                  style={{
                    width: "155px",
                    borderRadius: "12.5px",
                    height: "25px",
                    lineHeight: "24px",
                    marginLeft: "10px"
                  }}
                  onClick={() => {
                    props.download_template(
                      "/model/import_attendance_template.xls",
                      "import_attendance_template.xls"
                    );
                  }}
                >
                  Download Template
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.sessionCol}>
            <div className={styles.session}>
              {attendanceBtn.Registration || attendanceBtn.Attendance ? (
                <div className={styles.selectbox}>
                  <Col xs={24}>
                    <Form.Item
                      labelCol={{ span: 5 }}
                      wrapperCol={{ span: 19 }}
                      label="Session Day"
                    >
                      {getFieldDecorator(
                        "sessionDay",
                        {}
                      )(
                        <Select
                          filterOption={false}
                          defaultActiveFirstOption={false}
                          onChange={e => {
                            props.onChange("SessionDetail", {
                              sessiondayId: e
                            });
                            props.update_attendance_agents(e);
                            props.getCourseSessiondayInfo(e);
                            props.getSessionLockDate(e);
                          }}
                        >
                          {props.sessionDayList.length
                            ? props.sessionDayList.map(item => (
                                <Option
                                  value={item.sessiondayId}
                                  key={item.sessiondayId}
                                >
                                  <Tooltip
                                    placement="top"
                                    title={item.sessiondayDate}
                                  >
                                    {item.startDate} - {item.endDate} :{" "}
                                    {item.instructorName} {item.classCode}
                                  </Tooltip>
                                </Option>
                              ))
                            : null}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </div>
              ) : null}

              <div
                className={styles.scanBox}
                style={{
                  display:
                    props.agentType === "Attendance" &&
                    props.sessiondayId &&
                    props.isValid === "1"
                      ? "flex"
                      : "none"
                }}
              >
                <Tooltip placement="top" title="Scan In">
                  <div
                    className={styles.console}
                    style={setBg(attendanceScanIn)}
                    onClick={() => {
                      if (props.sessionLockStatus === "Y") {
                        return;
                      }
                      const params = {
                        scanType: true,
                        qrDetail: props.qrDetail,
                        ScanTimes: props.ScanTimes,
                        ServerTime: props.ServerTime,
                        venueName: props.venueName.value
                      };
                      sessionStorage.setItem(
                        "QRCodeParams",
                        JSON.stringify(params)
                      );
                      const strWindowFeatures =
                        "width=700,height=700,menubar=yes,location=yes,resizable=yes,scrollbars=true,status=true";
                      window.open(
                        `/${globalUrlPre}/qrcode_attendance`,
                        "QRCode Attendance",
                        strWindowFeatures
                      );
                    }}
                  />
                </Tooltip>
                <Tooltip placement="top" title="Scan Out">
                  <div
                    className={styles.console}
                    style={setBg(attendanceScanOut)}
                    onClick={() => {
                      if (props.sessionLockStatus === "Y") {
                        return;
                      }
                      const params = {
                        scanType: false,
                        qrDetail: props.qrDetail,
                        ScanTimes: props.ScanTimes,
                        ServerTime: props.ServerTime,
                        venueName: props.venueName.value
                      };
                      sessionStorage.setItem(
                        "QRCodeParams",
                        JSON.stringify(params)
                      );
                      const strWindowFeatures =
                        "width=700,height=700,menubar=yes,location=yes,resizable=yes,scrollbars=true,status=true";
                      window.open(
                        `/${globalUrlPre}/qrcode_attendance`,
                        "QRCode Attendance",
                        strWindowFeatures
                      );
                    }}
                  />
                </Tooltip>
              </div>
            </div>

            <div
              style={{
                display: props.agentType === "Registration" ? "block" : "none"
              }}
            >
              <Button
                type="danger"
                className={styles.btn}
                style={{ width: "165px", marginRight: "10px" }}
                icon="download"
                onClick={() => {
                  props.downLoad_registration_info(props.sessiondayId);
                }}
              >
                Download to CSV
              </Button>
            </div>

            <div
              className={styles.sessionBtn}
              style={{
                display:
                  props.agentType === "Attendance" && props.sessiondayId
                    ? "flex"
                    : "none"
              }}
            >
              <Button
                disabled={props.sessionLockStatus === "Y"}
                type="danger"
                className={styles.btn}
                onClick={props.openFile}
                style={{ width: "110px", marginRight: "10px" }}
                icon="upload"
              >
                Upload
              </Button>
              <Button
                disabled={props.sessionLockStatus === "Y"}
                className={styles.btn}
                type="danger"
                icon="plus"
                onClick={props.addAttendance}
              >
                Add
              </Button>
            </div>
          </div>
          <div
            style={{
              display: props.agentType === "Attendance" ? "block" : "none"
            }}
          >
            <div style={insideStyles.title}>Attendance Information</div>
            <Table
              rowSelection={{
                onChange: (selectedRowKeys, selectedRows) => {
                  props.selectedAttendance(selectedRowKeys, selectedRows);
                  console.log("selectedRows: ", selectedRows); // eslint-disable-line
                },
                selectedRowKeys: props.selectedRowKeys
                // getCheckboxProps: record => ({
                //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
                //   name: record.name,
                // }),
              }}
              bordered
              scroll={{ x: 2400 }}
              className={styles.ant_dev_two_table}
              columns={attendanceColumns}
              dataSource={attendanceAgents}
              size="small"
              rowKey="attendanceId"
            />
          </div>
          <div
            style={{
              display: props.agentType === "Registration" ? "block" : "none"
            }}
          >
            <div style={insideStyles.title}>Registration Information</div>
            <Table
              bordered
              scroll={{ x: 2000 }}
              className={styles.ant_dev_two_table}
              columns={registrationColumns}
              dataSource={registrationAgents}
              size="small"
              rowKey="unitCode"
            />
          </div>
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  state => ({
    fields: {
      ...state.pclaReducer.attendanceReducers.SessionDetailReducer
    },
    loading: state.PublicReducer.loading
  }),
  {
    changeVals,
    update_base_information,
    update_attendance_agents,
    update_registration_agents,
    delete_attendance,
    downLoad_attendance_info,
    downLoad_registration_info,
    getSessionDayList,
    getCourseSessiondayInfo,
    save_data,
    init_data,
    getAttendanceScanTimes,
    getCurrentTime,
    uploadAttendanceInfoCheck,
    upload_attendance_info,
    deleteAttendanceBatch,
    auditAttendanceBatch,
    getSessionLockDate,
    saveUnlockSession,
    download_template
  }
)
class SessionDetailPCLA extends Component {
  constructor(props) {
    super(props);
    this.openFile = this.openFile.bind(this);
    this.addAttendance = this.addAttendance.bind(this);
    this.editAttendance = this.editAttendance.bind(this);
    this.showQRCode = this.showQRCode.bind(this);
    this.showLockDate = this.showLockDate.bind(this);
    this.selectedAttendance = this.selectedAttendance.bind(this);
    this.deleteAttendanceBatch = this.deleteAttendanceBatch.bind(this);
    this.auditAttendanceBatch = this.auditAttendanceBatch.bind(this);
    this.downLoad_attendance_info = this.downLoad_attendance_info.bind(this);
    this.state = {
      selectedRowKeys: [],
      deleteAttendanceArr: [],
      lockshow: false,
      lockdate: "",
      timer: null,
      time: 0,
      flash: false,
      timeText: 0,
      scanType: true,
      attendanceColumns: [
        {
          title: "No",
          width: 80,
          fixed: "left",
          dataIndex: "",
          ellipsis: true,
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        // {
        //   title: "Agent ID",
        //   width: 120,
        //   fixed: "left",
        //   dataIndex: "agentId",
        //   ellipsis: true,
        //   align: "center"
        // },
        {
          title: "Login Account",
          width: 160,
          fixed: "left",
          dataIndex: "agentCode",
          ellipsis: true,
          align: "center",
          render: (text, record) => {
            let final;
            if (text) {
              final = text;
            } else {
              final = record.agentId;
            }
            return final;
          }
        },
        {
          title: "Agent Name",
          dataIndex: "agentName",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Scan In",
          dataIndex: "scanInDate",
          ellipsis: true,
          align: "center",
          render: text => {
            const scanInDate =
              text && text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
            return scanInDate;
          }
        },
        {
          title: "Scan Out",
          dataIndex: "scanOutDate",
          ellipsis: true,
          align: "center",
          render: text => {
            const scanOutDate =
              text && text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
            return scanOutDate;
          }
        },
        {
          title: "Audited",
          dataIndex: "auditFlag",
          ellipsis: true,
          align: "center",
          render: text => {
            return text ? text : "N";
          }
        },
        {
          title: "Audited By",
          dataIndex: "auditBy",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Audited Time",
          dataIndex: "lastAuditTime",
          ellipsis: true,
          align: "center",
          render: text => {
            const scanOutDate =
              text && text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
            return scanOutDate;
          }
        },
        {
          title: "Walk In",
          width: 80,
          fixed: "right",
          dataIndex: "isWalkIn",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Complete",
          width: 120,
          fixed: "right",
          dataIndex: "isCompleted",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Remark",
          width: 120,
          fixed: "right",
          dataIndex: "remark",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Action",
          fixed: "right",
          dataIndex: "ss",
          ellipsis: true,
          width: 150,
          align: "center",
          render: (text, record) => {
            const {
              delete_attendance,
              fields: { sessiondayId, sessionId, sessionLockStatus }
            } = this.props;
            return (
              <div style={insideStyles.tableData}>
                {text}
                <Button
                  disabled={sessionLockStatus === "Y"}
                  type="link"
                  onClick={() => {
                    this.editAttendance(record);
                  }}
                >
                  Edit
                </Button>
                <Popconfirm
                  autoAdjustOverflow={true}
                  placement="left"
                  title="Confirm to delete?"
                  overlayClassName="deleteBtn"
                  onConfirm={() => {
                    delete_attendance(
                      record.attendanceId,
                      sessiondayId,
                      sessionId
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button type="link">Delete</Button>
                </Popconfirm>
              </div>
            );
          }
        }
      ],
      registrationColumns: [
        {
          title: "No",
          dataIndex: "no",
          fixed: "left",
          width: 80,
          ellipsis: true,
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Course Name",
          fixed: "left",
          width: 200,
          dataIndex: "courseName",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Course Code",
          fixed: "left",
          width: 200,
          dataIndex: "courseCode",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Module Name",
          fixed: "left",
          width: 200,
          dataIndex: "moduleName",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Session Name",
          fixed: "left",
          width: 200,
          dataIndex: "sessionName",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Training Date",
          dataIndex: "trainingDate",
          width: 350,
          ellipsis: true,
          align: "center"
          // render: (text) => {
          //   const arr = text.split("~");
          //   if (text) {
          //     return (
          //       <div>
          //         <span>
          //           {arr[0]}
          //         </span>
          //         <br />
          //         <span>
          //           {arr[1]}
          //         </span>
          //       </div>
          //     );
          //   }
          //   return text;
          // }
        },
        {
          title: "User Name",
          dataIndex: "userName",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Login Account",
          dataIndex: "loginAccount",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          ellipsis: true,
          align: "center"
        }
      ],
      statisticsColumns: [
        {
          title: "Registered",
          dataIndex: "registeredSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Attended",
          dataIndex: "attendedSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Walk in",
          dataIndex: "walkInSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Completed",
          dataIndex: "completedSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Absent",
          dataIndex: "absentSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Course Completed",
          dataIndex: "courseCompletedSum",
          ellipsis: true,
          align: "center"
        }
      ]
    };
  }

  componentWillUnmount() {
    this.props.init_data();
  }

  componentDidMount() {
    const {
      sessionId,
      attendanceBtn,
      showBtn,
      isValid,
      agentType,
      sessiondayId
    } = this.props.location.state;
    if (showBtn) {
      if (!agentType) {
        this.props.changeVals("SessionDetail", {
          agentType: attendanceBtn.Registration ? "Registration" : "Attendance"
        });
      } else {
        this.props.changeVals("SessionDetail", {
          agentType
        });
      }
    }
    this.props.getAttendanceScanTimes();
    this.props.getCurrentTime();
    this.getDefaultData(sessionId, isValid, sessiondayId);
  }

  async getDefaultData(sessionId, isValid, sessiondayId) {
    this.props.changeVals("SessionDetail", {
      sessionId,
      isValid
    });
    await this.props.update_base_information(sessionId);
    await this.props.getSessionDayList(sessionId, sessiondayId);
    await this.props.update_registration_agents(sessionId);
    await this.props.getSessionLockDate(sessionId);
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      if (!err) {
      }
    });
  };

  deleteAttendanceBatch() {
    const {
      fields: { sessiondayId, sessionId }
    } = this.props;
    // const attendanceId = [];
    // const deleteAttendanceArr = [...this.state.deleteAttendanceArr];
    // deleteAttendanceArr.forEach((item) => {
    //   attendanceId.push(item.attendanceId);
    // });
    const attendanceId = [...this.state.selectedRowKeys];
    this.props.deleteAttendanceBatch(attendanceId, sessiondayId, sessionId);
    this.setState(() => ({
      deleteAttendanceArr: [],
      selectedRowKeys: []
    }));
  }

  auditAttendanceBatch() {
    const {
      fields: { sessiondayId, sessionId }
    } = this.props;
    // const attendanceId = [];
    // const deleteAttendanceArr = [...this.state.deleteAttendanceArr];
    // deleteAttendanceArr.forEach((item) => {
    //   attendanceId.push(item.attendanceId);
    // });
    const attendanceId = [...this.state.selectedRowKeys];
    this.props.auditAttendanceBatch(attendanceId, sessiondayId, sessionId);
    this.setState(() => ({
      deleteAttendanceArr: [],
      selectedRowKeys: []
    }));
  }

  selectedAttendance(selectedRowKeys, selectedRows) {
    this.setState(
      () => ({
        deleteAttendanceArr: [...selectedRows],
        selectedRowKeys
      }),
      () => {
        console.log(this.state.selectedRowKeys); // eslint-disable-line
      }
    );
  }

  selectFile = () => {
    const file = this.refs.fileElem.files[0];
    this.props.uploadAttendanceInfoCheck(
      file,
      this.props.fields.sessiondayId,
      this.props.fields.sessionId
    );
  };

  uploadAttendance() {
    const file = this.refs.fileElem.files[0];
    this.props.upload_attendance_info(
      file,
      this.props.fields.sessiondayId,
      this.props.fields.sessionId
    );
  }

  addAttendance() {
    const { attendanceBtn, showBtn, isValid } = this.props.location.state;
    const {
      sessiondayId,
      sessionName,
      sessionId,
      startDate,
      endDate,
      agentType,
      qrDetail
    } = this.props.fields;
    this.props.history.push("/home/attendance/add_attendance", {
      sessiondayId,
      sessionName: sessionName.value,
      sessionId,
      attendanceBtn,
      showBtn,
      isValid,
      agentType,
      qrDetail,
      startEndDate: {
        startDate,
        endDate
      }
    });
  }

  editAttendance(item) {
    const { attendanceBtn, showBtn, isValid } = this.props.location.state;
    const {
      sessiondayId,
      sessionName,
      sessionId,
      startDate,
      endDate,
      agentType,
      qrDetail
    } = this.props.fields;

    const {
      agentCode,
      agentName,
      scanInDate,
      scanOutDate,
      isWalkIn,
      attendanceId
    } = item;
    this.props.history.push("/home/attendance/edit_attendance", {
      sessiondayId,
      sessionName: sessionName.value,
      sessionId,
      attendanceId,
      agentCode,
      agentName,
      scanInDate,
      scanOutDate,
      isWalkIn,
      attendanceBtn,
      showBtn,
      isValid,
      agentType,
      qrDetail,
      startEndDate: {
        startDate,
        endDate
      }
    });
  }

  downLoad_attendance_info() {
    const { sessiondayId } = this.props.fields;
    this.props.downLoad_attendance_info(sessiondayId);
  }

  openFile() {
    if (this.refs.fileElem) {
      this.refs.fileElem.value = "";
      this.refs.fileElem.click();
    }
  }

  showQRCode(e, qrDetail, type) {
    e.stopPropagation();
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
    this.setState(() => ({
      scanType: type
    }));
    this.props.save_data({
      showQR: true,
      qrDetail: {
        ...qrDetail,
        nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
        currentTime: date.getTime(),
        expirationTime: date.getTime() + this.props.fields.ScanTimes * 1000,
        scanType: type,
        venueName: this.props.fields.venueName.value
      }
    });
    this.setTimer();
  }

  freshQR() {
    const { qrDetail } = this.props.fields;
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
    this.props.save_data({
      qrDetail: {
        ...qrDetail,
        nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
        currentTime: date.getTime(),
        expirationTime: date.getTime() + this.props.fields.ScanTimes * 1000
      }
    });
    this.setTimer();
  }

  setTimer() {
    const ScanTimes = this.props.fields.ScanTimes;
    this.setState(() => ({
      time: ScanTimes,
      timeText: ScanTimes
    }));
    const timer = setInterval(() => {
      const time = this.state.time - 1;
      if (time < 0) {
        clearInterval(this.state.timer);
        this.freshQR();
      } else {
        this.setState(() => ({
          time,
          timeText: time > 9 ? time : `0${time}`
        }));
      }
    }, 1000);
    this.setState(() => ({
      timer
    }));
  }

  clearTimer() {
    this.props.save_data({
      showQR: false
    });
    clearInterval(this.state.timer);
  }

  showLockDate() {
    const { sessionLockDate } = this.props.fields;
    this.setState(() => ({
      lockshow: true,
      lockdate: sessionLockDate ? moment(sessionLockDate, "YYYY-MM-DD") : ""
    }));
  }

  render() {
    const props = {
      attendanceBtn: this.props.location.state.attendanceBtn,
      statisticsColumns: this.state.statisticsColumns,
      registrationColumns: this.state.registrationColumns,
      attendanceColumns: this.state.attendanceColumns,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      openFile: this.openFile,
      addAttendance: this.addAttendance,
      update_attendance_agents: this.props.update_attendance_agents,
      getCourseSessiondayInfo: this.props.getCourseSessiondayInfo,
      showQRCode: this.showQRCode,
      goBack: this.props.history.goBack,
      history: this.props.history,
      location: this.props.location.state,
      downLoad_attendance_info: this.downLoad_attendance_info,
      downLoad_registration_info: this.props.downLoad_registration_info,
      showLockDate: this.showLockDate,
      deleteAttendanceBatch: this.deleteAttendanceBatch,
      auditAttendanceBatch: this.auditAttendanceBatch,
      selectedAttendance: this.selectedAttendance,
      deleteAttendanceArr: this.state.deleteAttendanceArr,
      selectedRowKeys: this.state.selectedRowKeys,
      getSessionLockDate: this.props.getSessionLockDate
    };
    const {
      fields: { sessionId, sessionLockDate, showConfirm, qrDetail }
    } = this.props;
    return (
      <div
        className={styles.container}
        onClick={() => {
          this.clearTimer();
        }}
      >
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/home/attendance/course_finder");
            }}
          />
          Base Information
        </div>
        <div
          className={styles.unlock}
          style={{ display: this.state.lockshow ? "block" : "none" }}
        >
          <i></i>
          <div>
            <p>LockDate</p>
            <div className={styles.lockdate}>
              <span>Date</span>
              <DatePicker
                defaultValue={sessionLockDate}
                value={this.state.lockdate}
                onChange={e => {
                  this.setState(() => ({
                    lockdate: e
                  }));
                }}
                placeholder={""}
                style={{ width: "88%" }}
                format={"YYYY-MM-DD"}
              />
            </div>
            <div>
              <Button
                type="primary"
                style={{ marginRight: "40px" }}
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  this.setState(() => ({
                    lockshow: false
                  }));
                  const unLockDate = this.state.lockdate.format("YYYY-MM-DD");
                  this.props.saveUnlockSession(sessionId, unLockDate);
                }}
              >
                Save
              </Button>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  this.setState(() => ({
                    lockshow: false
                  }));
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
        <Modal
          title="Confirm Upload"
          visible={showConfirm}
          onOk={() => {
            this.props.changeVals("SessionDetail", {
              showConfirm: false
            });
            this.uploadAttendance();
          }}
          onCancel={() => {
            this.props.changeVals("SessionDetail", {
              showConfirm: false
            });
          }}
        >
          <p>System will use the latest one to overwrite the existing one</p>
          <p>Continue or not ?</p>
        </Modal>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
          style={{ display: this.props.fields.showQR ? "block" : "none" }}
          className={styles.qrContent}
        >
          <div> </div>
          <div className={styles.qrBox}>
            <div className={styles.qrLeft}>
              <div className={styles.qrTimer}>00’{this.state.timeText}”</div>
              <div className={styles.qr}>
                <QRCode
                  renderAs="svg"
                  value={JSON.stringify(qrDetail)}
                  size={400}
                  fgColor="#000000"
                />
              </div>
              <div className={styles.qrText}>{qrDetail.courseName}</div>
              <div className={styles.qrText}>
                {qrDetail.scanType ? "Scan In" : "Scan Out"}
              </div>
            </div>
          </div>
          <div style={{ ...insideStyles.goCenter, marginTop: "20px" }}>
            <Button
              type="primary"
              className={`${styles.btnColor} ${styles.btnText}`}
              onClick={() => {
                this.clearTimer();
              }}
            >
              Close
            </Button>
          </div>
        </div>

        <input
          type="file"
          ref="fileElem"
          style={{ display: "none" }}
          onChange={this.selectFile}
        />
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}
const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});
const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(SessionDetailPCLA);
