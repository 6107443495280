import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Checkbox,
  Spin,
  DatePicker,
  notification,
  Icon,
  Input
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  search_agent_name,
  update_search,
  add_attendance,
  init_data
} from "../../../../redux/pacsReducer/attendanceReducers/AddAttendanceReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateAddAttendance_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("AddAttendance", changedFields);
  },
  mapPropsToFields(props) {
    return {
      name: Form.createFormField({
        ...props.name,
        value: props.name.value
      }),
      // contractDate: Form.createFormField({
      //   ...props.contractDate,
      //   value: props.contractDate.value
      // }),
      agentId: Form.createFormField({
        ...props.agentId,
        value: props.agentId.value
      }),
      sessionTime: Form.createFormField({
        ...props.sessionTime,
        value: props.sessionTime.value
      }),
      walkIn: Form.createFormField({
        ...props.walkIn,
        value: props.walkIn.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, title } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row>
          <Col {...flexCol}>
            <Form.Item labelCol={{ span: 5 }} label="Walk-In">
              {getFieldDecorator("walkIn", {})(<Checkbox>Walk-In</Checkbox>)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Time">
              {getFieldDecorator("sessionTime", {})(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label={title} labelCol={{ span: 5 }}>
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    max: 50,
                    message: "fields cannot be longer than 50 characters"
                  }
                ]
              })(
                <Select
                  defaultActiveFirstOption={false}
                  showSearch
                  showArrow={false}
                  onSearch={props.search}
                  filterOption={false}
                  optionLabelProp="value"
                >
                  {props.searchList.length
                    ? props.searchList.map(item => (
                      <Option
                        onClick={() => {
                          props.selectAgenId(
                            item.agentcode,
                            item.agentName,
                            item.agentId,
                            item.litmosUserId
                          );
                        }}
                        key={item.agentId}
                        label={item.agentName}
                        value={item.agentId}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <div>{item.agentName}</div>
                          <div>{item.agentId}</div>
                        </div>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol} className={styles.dateWidth}>
            <Form.Item label="Scan In & Out" style={{ marginBottom: 0, width: "100%", }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Form.Item style={{ display: 'inline-block', width: '46%' }}>
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: moment("00:00:00", "HH:mm:ss"),
                    }}
                    value={props.contractStartDate}
                    onChange={e => {
                      props.onChange("AddAttendance", {
                        contractStartDate: e
                      });
                    }}
                    placeholder="Scan In"
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <span style={{ display: 'inline-block', flex: 1, textAlign: "center" }}> ~ </span>
                <Form.Item style={{ display: 'inline-block', width: '46%' }}>
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: moment("23:59:59", "HH:mm:ss")
                    }}
                    value={props.contractEndDate}
                    onChange={e => {
                      props.onChange("AddAttendance", {
                        contractEndDate: e
                      });
                    }}
                    placeholder="Scan Out"
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            {/* <Form.Item label="Scan In & Out" style={{ marginBottom: 0 }}>
              {getFieldDecorator("contractDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["Scan In", "Scan Out"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                />
              )}
            </Form.Item> */}
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
});

@connect(
  state => ({
    fields: {
      ...state.pacsReducer.attendanceReducers.AddAttendanceReducer
    },
    loading: state.PublicReducer.loading,
    user: { ...state.userReducer }
  }),
  {
    changeVals,
    search_agent_name,
    update_search,
    add_attendance,
    init_data
  }
)
class AddAttendance extends Component {
  constructor(props) {
    super(props);
    this.search = this.search.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeShow = this.changeShow.bind(this);
    this.selectAgenId = this.selectAgenId.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    this.state = {
      timeout: null,
      show: false,
      title: ""
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("buCountry") === "PACS" || sessionStorage.getItem("prefix") === "pacs") {
      this.setState(() => ({
        title: "Login Account"
      }))
    } else {
      this.setState(() => ({
        title: "Agent ID"
      }))
    }

    const { sessiondayId, sessionId, sessionName, startEndDate: {
      startDate,
      endDate
    } } = this.props.location.state;
    this.props.changeVals("AddAttendance", {
      sessiondayId,
      sessionId,
      sessionName,
      sessionTime: {
        value: `${startDate.value} - ${endDate.value}`
      },
      name: {
        value: ""
      },
      // contractDate: {
      //   value: startDate.value && endDate.value ? [moment(startDate.value), moment(endDate.value)] : ""
      // },
      contractStartDate: moment(startDate.value),
      contractEndDate: moment(endDate.value),
      walkIn: {
        value: false
      },
      agentId: {
        value: ""
      }
    });
  }

  disabledDate = current => {
    const { qrDetail } = this.props.location.state;
    const start = moment(qrDetail.startDate).valueOf();
    return current < start;
  };

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      const { startEndDate, qrDetail } = this.props.location.state;
      if (!err) {
        if (!fields.contractStartDate && !fields.contractEndDate) {
          notification.error({
            message: "Scan In & Out is empty",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return;
        }

        if (fields.contractStartDate && fields.contractEndDate) {
          const scanIn = fields.contractStartDate.valueOf();
          const scanOut = fields.contractEndDate.valueOf();
          if (scanOut <= scanIn) {
            notification.error({
              message: "The scan in in and scan out cannot be the same",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
          if (qrDetail.dailyCoachingFlag === "1") {
            const startLimitDate = moment(qrDetail.startDate).startOf("day").valueOf();
            const endLimitDate = moment(qrDetail.endDate).endOf("day").valueOf();
            if (scanIn >= startLimitDate && scanOut <= endLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const startLimitDate = moment(startEndDate.startDate.value).valueOf();
            const endLimitDate = moment(startEndDate.endDate.value).valueOf();
            if (scanIn < endLimitDate && scanOut > startLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }

        if (fields.contractStartDate) {
          const scanIn = fields.contractStartDate.valueOf();
          if (qrDetail.dailyCoachingFlag === "1") {
            const startLimitDate = moment(qrDetail.startDate).startOf("day").valueOf();
            if (scanIn >= startLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: "",
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const endLimitDate = moment(startEndDate.endDate.value).valueOf();
            if (scanIn < endLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: "",
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }

        if (fields.contractEndDate) {
          const scanOut = fields.contractEndDate.valueOf();
          if (qrDetail.dailyCoachingFlag === "1") {
            const endLimitDate = moment(qrDetail.endDate).endOf("day").valueOf();
            if (scanOut <= endLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: "",
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const startLimitDate = moment(startEndDate.startDate.value).valueOf();
            if (scanOut > startLimitDate) {
              this.props.add_attendance(
                {
                  agentName: fields.agentName,
                  agentCode: fields.agentCode,
                  litmosUserId: fields.litmosUserId,
                  agentId: fields.agentId.value,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: "",
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  sessionName: fields.name.value,
                  sessiondayId: fields.sessiondayId
                },
                this.props.history,
                this.props.location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }
      }
    });
  };

  selectAgenId(agentCode, agentName, agentId, litmosUserId) {
    this.props.changeVals("AddAttendance", {
      agentCode,
      agentName,
      litmosUserId,
      name: { value: agentName },
      agentId: { value: agentId }
    });
  }

  changeShow() {
    const show = !this.state.show;
    this.setState(() => ({
      show
    }));
  }

  search(name) {
    const { sessionId, walkIn } = this.props.fields;

    if (!name) {
      this.changeName();
      return;
    }
    if (this.state.timeout !== null) {
      clearTimeout(this.state.timeout);
      this.setState(() => ({
        timeout: null
      }));
    }
    const timeout = setTimeout(() => {
      this.props.search_agent_name({
        agentName: name,
        sessionId,
        isWalkIn: walkIn.value ? "Y" : "N"
      });
    }, 300);
    this.setState(() => ({
      timeout
    }));
  }

  changeName() {
    this.props.update_search([]);
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      search: this.search,
      loading: this.props.loading,
      changeName: this.changeName,
      show: this.state.show,
      selectAgenId: this.selectAgenId,
      goBack: this.props.history.goBack,
      disabledRangeTime: this.disabledRangeTime,
      disabledDate: this.disabledDate,
      title: this.state.title
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/home/attendance/session_detail", {
                ...this.props.location.state
              });
            }}
          />
          Add Attendance
        </div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    marginTop: "50px",
    justifyContent: "center",
    alignItems: "center"
  }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(AddAttendance);
