import { message } from 'antd'
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pluk/ShiftInOutReducer/SAVE";

const initState = {
  tableLoading: false,
  userCourseData: {
    total: 0,
    pageSize: 10,
    pageNumber: 1,
    userList: []
  },
  shiftToList: []
};

// reducer
export default function ShiftInOutReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getTabelData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/plukCPDCalculate/getAgentShiftIn0utList", {}, payload)
      .then(res => {
        const { data = {} } = res;
        dispatch({
          type: SAVE,
          payload: {
            userCourseData: {
              total: data.total,
              pageSize: data.pageSize,
              userList: data.data || []
            },
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function getShiftToDate() {
  return dispatch => {
    get("/pluk/cpd/yearDesc")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            shiftToList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function handleSubmit(payload, cb) {
  return dispatch => {
    post('/plukCPDCalculate/AgentShiftIn0ut', payload)
      .then(() => {
        message.info("Saved successfully", globalPromptTime, cb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}
