import React from "react";
import { Form, Row, Col, Tooltip, Input } from "antd";
import './index.less';

function CourseRegistration(props) {
  const { courseDetail, getFieldDecorator } = props;

  const flexCol = {
    xxl: 8,
    xl: 8,
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12
  };

  const formatDate = (date) => {
    if (!date) {
      return date;
    }

    return date.includes('.') ? date.split('.')[0] : date;
  }

  return (
    <div className="phklSessionDetail">
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              <Tooltip title={courseDetail.courseName} placement="top">
                {getFieldDecorator("courseName", {
                  initialValue: courseDetail.courseName
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Module Name">
              {getFieldDecorator("moduleName", {
                initialValue: courseDetail.moduleName
              })(<Input disabled />)}
            </Form.Item>
          </Col>
        <Col {...flexCol}>
          <Form.Item label="Course Code">
            {getFieldDecorator("courseCode", {
              initialValue: courseDetail.courseCode
            })(<Input disabled />)}
          </Form.Item>
        </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {
                initialValue: courseDetail.sessionName
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Start Date Time">
              {getFieldDecorator("startDate", {
                initialValue: formatDate(courseDetail.startDate)
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="End Date Time">
              {getFieldDecorator("endDate", {
                initialValue: formatDate(courseDetail.endDate)
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Submit Date Time">
              {getFieldDecorator("submitDate", {
                initialValue: formatDate(courseDetail.submitDate)
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Trainer">
              <Tooltip title={courseDetail.instructorName} placement="top">
                {getFieldDecorator("instructorName", {
                  initialValue: courseDetail.instructorName
                })(<Input disabled />)}
              </Tooltip>
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Submit By">
              {getFieldDecorator("submitBy", {
                initialValue: courseDetail.submitBy
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Status">
              {getFieldDecorator("status", {
                initialValue: courseDetail.submitStatus
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Training Hours">
              {getFieldDecorator("trainingHours", {
                initialValue: courseDetail.trainingHours
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="IA CPD">
            {getFieldDecorator("iaCpd", {
              initialValue: courseDetail.iaCpd
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="MPF Non-Core CPD">
              {getFieldDecorator("mpfNonCoreCpd", {
                initialValue: courseDetail.mpfNonCoreCpd
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="MPF Core CPD">
              {getFieldDecorator("mpfCoreCpd", {
                initialValue: courseDetail.mpfCoreCpd
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="SFC CPD">
              {getFieldDecorator("sfcCpd", {
                initialValue: courseDetail.sfcCpd
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Course Channel">
              {getFieldDecorator("courseChannel", {
                initialValue: courseDetail.courseChannel
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Accreditation Code">
              {getFieldDecorator("accreditationCode", {
                initialValue: courseDetail.accreditationCode
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Course Vendor">
              {getFieldDecorator("courseVendor", {
                  initialValue: courseDetail.courseVendor
                })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Ethics or Regulations">
              {getFieldDecorator("ethicsOrRegulations", {
                initialValue: courseDetail.ethicsOrRegulations
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          {/* <Col {...flexCol}>
            <Form.Item label="Venue Region">
              {getFieldDecorator("venueRegion", {
                initialValue: courseDetail.venueRegion
              })(<Input disabled />)}
            </Form.Item>
          </Col> */}
          <Col xl={24}>
            <Form.Item
              wrapperCol={{ span: 21 }}
              label="Venue Name"
            >
              {getFieldDecorator("venueName", {
                initialValue: courseDetail.venueName
              })(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col xl={24}>
            <Form.Item
              wrapperCol={{ span: 21 }}
              label="Venue Address"
            >
              {getFieldDecorator("venueAddress", {
                initialValue: courseDetail.venueAddress
              })(<Input disabled />)}
            </Form.Item>
          </Col>
        </Row>
    </div>
  );
}


export default CourseRegistration;
