
import React, { FC, useEffect, useState } from "react";
import { Form, Col, Select } from "antd";
import { flexCol } from './utils';

const { Option } = Select;

const SelectList = props => {
  const { handleSaveField, getFieldDecorator, name, label, placeholder = '', initialValue, options, canSearch = false, onFieldChange, onFieldSearch, ...restProps } = props;

  const [list, setList] = useState([]);


  useEffect(() => {
    const fetchAPI = async () => {
      if (typeof options === 'function') {
       const list = await options();
        setList(list);
      }
      setList(options);
    }

    fetchAPI();
    // eslint-disable-next-line
    return;
  }, [options])

  return (
    <Col {...flexCol}>
      <Form.Item label={label}>
        {getFieldDecorator(`${name}`, {
          initialValue: initialValue || '',
        })(
          <Select
            onChange={(val, option) => {
              handleSaveField(name, val);
              if (typeof onFieldChange === 'function') {
                onFieldChange(val || '');
              }
            }}
            onSearch={(val) => {
              if (typeof onFieldSearch === 'function') {
                onFieldSearch(val || '');
              }
            }}
            allowClear
            showSearch={canSearch}
            placeholder={placeholder}
            // filterOption={false}
            filterOption={(input, option) => (option?.props?.children?.toLowerCase() || '').indexOf(input.toLowerCase()) >= 0}
            {...restProps}
          >
            {
              list && list.length > 0 && list.map((item) => {
                return (
                  <Option key={item.value} value={item.value}>{item.label}</Option>
                )
              })
            }
          </Select>
        )}
      </Form.Item>
    </Col>
  );
};

export default SelectList;
