import React from "react";
import styles from '../../index.module.less';
import SessionDaySelector from './SessionDaySelector';
import ActionButtons from './ActionButtons';

function Selection(props) {
  return (
    <div className={styles.sessionCol}>
      <div className={styles.session}>
        <SessionDaySelector {...props} />
        {/* <ScanButtons /> */}
        <ActionButtons {...props} />
      </div>
    </div>
  );
}

export default Selection;
