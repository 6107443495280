import React from "react";
import { globalUrlPre } from "../utils/config";

// import Login from "../views/user/Login";
import ForgetPassword from "../views/user/ForgetPassword";
import Home from "../views/home";

import Forbidden from "../views/exception/Forbidden";
import NotFound from "../views/exception/NotFound";
import ServerError from "../views/exception/ServerError";

// systemSettings
import SystemSettings from "../views/home/pamb/systemSettings";
import UserManagement from "../views/home/pamb/systemSettings/userManagement";
import UserManagementHome from "../views/home/pamb/systemSettings/userManagement/UserManagementHome";

import ImportAgent from "../views/home/pamb/systemSettings/userManagement/ImportAgent";

import CreateRegionAdmin from "../views/home/pamb/systemSettings/userManagement/CreateRegionAdmin";
import CreateLBUAdmin from "../views/home/pamb/systemSettings/userManagement/CreateLBUAdmin";
import CreateCourseAdmin from "../views/home/pamb/systemSettings/userManagement/CreateCourseAdmin";
import CreateCandidateAdminAD from "../views/home/pamb/systemSettings/userManagement/CreateCandidateAdminAD";
import CreateCandidateAdminPD from "../views/home/pamb/systemSettings/userManagement/CreateCandidateAdminPD";
import CreateCandidateAD from "../views/home/pamb/systemSettings/userManagement/CreateCandidateAD";
import CreateCandidatePD from "../views/home/pamb/systemSettings/userManagement/CreateCandidatePD";
import CreateGuest from "../views/home/pamb/systemSettings/userManagement/CreateGuest";
import ImportDcmsFile from "../views/home/pamb/systemSettings/userManagement/ImportDcmsFile";
import CandidateDetail from "../views/home/pamb/systemSettings/userManagement/CandidateDetail";

import PAMBAgentDetail from "../views/home/pamb/systemSettings/userManagement/PAMBAgentDetail";
import PACSAgentDetail from "../views/home/pamb/systemSettings/userManagement/PACSAgentDetail";
import AdminDetail from "../views/home/pamb/systemSettings/userManagement/AdminDetail";
import GuestDetail from "../views/home/pamb/systemSettings/userManagement/GuestDetail";
import AdminDetailLBU from "../views/home/pamb/systemSettings/userManagement/AdminDetailLBU";
import AdminDetailCourse from "../views/home/pamb/systemSettings/userManagement/AdminDetailCourse";
import CandidateAdminADDetail from "../views/home/pamb/systemSettings/userManagement/CandidateAdminADDetail";
import CandidateAdminPDDetail from "../views/home/pamb/systemSettings/userManagement/CandidateAdminPDDetail";
import ResetPassword from "../views/home/pamb/systemSettings/userManagement/ResetPassword";

import SurveyManagement from "../views/home/pamb/systemSettings/SurveyManagement";
import SurveySearch from "../views/home/pamb/systemSettings/SurveyManagement/SurveySearch";
import SurveyCreate from "../views/home/pamb/systemSettings/SurveyManagement/SurveyCreate";
import SurveyDetail from "../views/home/pamb/systemSettings/SurveyManagement/SurveyDetail";

import QuestionGroup from "../views/home/pamb/systemSettings/QuestionGroup";
import QuestionGroupSearch from "../views/home/pamb/systemSettings/QuestionGroup/QuestionGroupSearch";
import QuestionrGroupDetail from "../views/home/pamb/systemSettings/QuestionGroup/QuestionrGroupDetail";
import QuestionrGroupEdit from "../views/home/pamb/systemSettings/QuestionGroup/QuestionrGroupEdit";
import QuestionrGroupCreate from "../views/home/pamb/systemSettings/QuestionGroup/QuestionrGroupCreate";
import AddQuestionrGroup from "../views/home/pamb/systemSettings/QuestionGroup/AddQuestionrGroup";

// certification
import Certification from "../views/home/pamb/systemSettings/Certification";
import CertificationCodeMap from "../views/home/pamb/systemSettings/Certification/CertificationCodeMap";

// time_job
import TimeJob from "../views/home/pamb/systemSettings/TimeJob";


// myProfile
import MyProfileSetting from "../views/home/pamb/myProfile";
import ChangePassword from "../views/home/regional/myProfile/ChangePassword";
import MyProfile from "../views/home/pamb/myProfile/myProfile";
import AgentPAMB from "../views/home/pamb/myProfile/Agent_PAMB";

// CPDSettings

import CPDSettings from "../views/home/pamb/CPDSettings";
import CpdRequirement from "../views/home/pamb/CPDSettings/CpdRequirement";
import RequirementSettingPambCreate from "../views/home/pamb/CPDSettings/RequirementSettingPambCreate";

// attendance
import QRCodePage from "../views/home/pamb/attendance/QRCode";
import AddAttendance from "../views/home/pamb/attendance/AddAttendance";
import CourseFinder from "../views/home/pamb/attendance/CourseFinder";
import EditAttendance from "../views/home/pamb/attendance/EditAttendance";
import SessionDetail from "../views/home/pamb/attendance/SessionDetail";
import SessionDetailPACS from "../views/home/pamb/attendance/SessionDetailPACS";
import Attendance from "../views/home/pamb/attendance";

//  CPDReports
import CPDReports from "../views/home/pamb/CPDReports";
import ManagementReport from "../views/home/pamb/CPDReports/ManagementReport";
import DeductionReport from "../views/home/pamb/CPDReports/DeductionReport";
import SurveyReports from "../views/home/pamb/CPDReports/SurveyReports";
import AttendedSummary from "../views/home/pamb/CPDReports/AttendedSummary";
import TrainingMaterialRating from "../views/home/pamb/CPDReports/TrainingMaterialRating";
import TrainerFeedbackRating from "../views/home/pamb/CPDReports/TrainerFeedbackRating";
import OverallFeedbackRating from "../views/home/pamb/CPDReports/OverallFeedbackRating";
import TeachingDaysReport from "../views/home/pamb/CPDReports/TeachingDaysReport";
import AttendanceSummary from "../views/home/pamb/CPDReports/AttendanceSummary";
import RegistrationSummary from "../views/home/pamb/CPDReports/RegistrationSummary";
import RegistrationAttendance from "../views/home/pamb/CPDReports/RegistrationAttendance";

// TrainingHistory
import TrainingHistory from "../views/home/pamb/trainingHistory";
import TrainingRequirement from "../views/home/pamb/trainingHistory/TrainingRequirement";
import TrainingRequirementSearch from "../views/home/pamb/trainingHistory/TrainingRequirement/TrainingRequirementSearch";
import TRagentLIAMDetail from "../views/home/pamb/trainingHistory/TrainingRequirement/TRagentLIAMDetail";
import TRagentMTADetails from "../views/home/pamb/trainingHistory/TrainingRequirement/TRagentMTADetail";
import CPDHours from "../views/home/pamb/trainingHistory/CPDHours";
import CPDHoursSearch from "../views/home/pamb/trainingHistory/CPDHours/CPDHoursSearch";
import CPDHoursLIAMDetail from "../views/home/pamb/trainingHistory/CPDHours/CPDHoursLIAMDetail";
import CPDHoursMTADetail from "../views/home/pamb/trainingHistory/CPDHours/CPDHoursMTADetail";

// Reminder rule
import ReminderRule from "../views/home/pamb/systemSettings/reminderRule";
import ReminderList from "../views/home/pamb/systemSettings/reminderRule/ReminderList";
import ReminderDetail from "../views/home/pamb/systemSettings/reminderRule/ReminderDetail";

// Daily Coaching
import DailyCoaching from "../views/home/pamb/systemSettings/dailyCoaching";
import DailyCoachingSearch from "../views/home/pamb/systemSettings/dailyCoaching/DailyCoachingSearch";
import EditCoachingCourse from "../views/home/pamb/systemSettings/dailyCoaching/EditCoachingCourse";
import SelectCourse from "../views/home/pamb/systemSettings/dailyCoaching/SelectCourse";
import SelectTrainer from "../views/home/pamb/systemSettings/dailyCoaching/SelectTrainer";
import SelectAgentLeader from "../views/home/pamb/systemSettings/dailyCoaching/SelectAgentLeader";

// LA Report
import LAReport from "../views/home/pamb/systemSettings/LAReport";
import PACSAgentTrainingCode from "../views/home/pamb/systemSettings/LAReport/PACSAgentTrainingCode"

// Scheme Management
import SchemeManagement from "../views/home/pamb/schemeManagement";
import AttendanceReport from "../views/home/pamb/schemeManagement/attendanceReport";
import MySession from "../views/home/pamb/schemeManagement/mySession";
import MySessionDetails from "../views/home/pamb/schemeManagement/mySession/MySessionDetails";
import MySessionQRCode from "../views/home/pamb/schemeManagement/mySession/MySessionQRCode";
import AttendanceDetail from "../views/home/pamb/schemeManagement/mySession/AttendanceDetail";
import Registration from "../views/home/pamb/schemeManagement/registration";
import RequirementSetting from "../views/home/pamb/schemeManagement/requirementSetting";
import CreateRequirement from "../views/home/pamb/schemeManagement/requirementSetting/CreateRequirement";
import RequirementSettingDetails from "../views/home/pamb/schemeManagement/requirementSetting/RequirementSettingDetails";
import RegistrationList from "../views/home/pamb/schemeManagement/registration/list";
import RegistrationBulkAdd from "../views/home/pamb/schemeManagement/registration/bulkAdd";
import RegistrationIndividualAdd from "../views/home/pamb/schemeManagement/registration/individualAdd";
import AttendanceReportList from "../views/home/pamb/schemeManagement/attendanceReport/agentView";
import AttendanceReportDetail from "../views/home/pamb/schemeManagement/attendanceReport/detial";

/**
 * hasSecondRoute: If the first level of menu has a second level of sidebar routing, this attribute should be added. The main function is to dynamically load the first sidebar menu
 * redirctPath: The back-end returned path, but it is not the final route, which needs to be redirected
 * customDevPath: The back-end does not returned path, but the front-end local needs to configure the redirected route
 * initialRoute: The back-end does not returned path，it is Locally initialized route
 */
const RouteConfig = [
  {
    title: "home",
    path: "/home",
    component: Home,
    initialRoute: true,
    children: [
      {
        id: "cpd_reports",
        title: "Las Back-stage management",
        path: "/home/cpd_reports",
        component: CPDReports,
        hasSecondRoute: true,
        children: [
          {
            id: "cpd_reports_managementreport",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_managementreport",
            exact: true,
            component: ManagementReport
          },
          {
            id: "cpd_reports_attendedsummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_attendedsummary",
            exact: true,
            component: AttendedSummary
          },
          {
            id: "cpd_reports_deductionreport",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_deductionreport",
            exact: true,
            component: DeductionReport
          },
          {
            id: "pamb_survey_reports",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/pamb_survey_reports",
            exact: true,
            component: SurveyReports
          },
          {
            id: "cpd_reports_attendancesummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_attendancesummary",
            exact: true,
            component: AttendanceSummary
          },
          {
            id: "cpd_reports_registrationSummary",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_registrationSummary",
            exact: true,
            component: RegistrationSummary
          },
          {
            id: "cpd_reports_registrationAttendance",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/cpd_reports_registrationAttendance",
            exact: true,
            component: RegistrationAttendance
          },
          {
            id: "audit_log",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/audit_log",
            component: () => <div>audit_log</div>
          },
          {
            id: "system_log",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/system_log",
            component: () => <div>system_log</div>
          },
          {
            id: "training_material_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/training_material_rating",
            component: TrainingMaterialRating
          },
          {
            id: "trainer_feedback_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/trainer_feedback_rating",
            component: TrainerFeedbackRating
          },
          {
            id: "overall_feedback_rating",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/overall_feedback_rating",
            component: OverallFeedbackRating
          },
          {
            id: "teaching_days_report",
            title: "Las Back-stage management",
            path: "/home/cpd_reports/teaching_days_report",
            component: TeachingDaysReport
          }
        ]
      },
      {
        id: "attendance",
        title: "Las Back-stage management",
        path: "/home/attendance",
        hasSecondRoute: true,
        redirctPath: "/home/attendance/course_finder",
        component: Attendance, // Attendance,
        children: [
          {
            id: "course_finder",
            title: "Las Back-stage management",
            path: "/home/attendance/course_finder",
            component: () => <CourseFinder />
          },
          {
            id: "session_detail",
            title: "Las Back-stage management",
            path: "/home/attendance/session_detail",
            component: () => {
              if (sessionStorage.getItem("buCountry") === "PACS" || sessionStorage.getItem("prefix") === "pacs") {
                return <SessionDetailPACS />
              } else {
                return <SessionDetail />
              }
            }
          },
          {
            id: "add_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/add_attendance",
            customDevPath: true,
            component: () => <AddAttendance />
          },
          {
            id: "edit_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/edit_attendance",
            customDevPath: true,
            component: () => <EditAttendance />
          },
          {
            id: "qrcode_attendance",
            title: "Las Back-stage management",
            path: "/qrcode_attendance",
            customDevPath: true,
            component: () => <QRCodePage />
          }
        ]
      },
      {
        id: "my_profile_setting",
        title: "Las Back-stage management",
        path: "/home/my_profile_setting",
        hasSecondRoute: true,
        component: MyProfileSetting,
        children: [
          {
            id: "my_profile_admin",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/my_profile",
            component: () => <MyProfile />
          },
          // {
          //   id: "pacs_agent",
          //   title: "Las Back-stage management",
          //   path: "/home/my_profile_setting/pacs_agent",
          //   component: () => <AgentPACS />
          // },
          {
            id: "pamb_agent",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/pamb_agent",
            component: () => <AgentPAMB />
          },
          {
            id: "change_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/change_password",
            component: () => <ChangePassword />
          },
          {
            id: "forget_prubehere_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/forget_prubehere_password",
            component: () => <ForgetPassword />
          }
        ]
      },
      {
        id: "system_settings",
        title: "Las Back-stage management",
        path: "/home/system_settings",
        component: SystemSettings,
        hasSecondRoute: true,
        children: [
          {
            id: "user_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/user_management",
            redirctPath: "/home/system_settings/user_management/index",
            component: UserManagement,
            children: [
              {
                id: "user_management_index",
                customDevPath: true,
                path: "/home/system_settings/user_management/index",
                component: UserManagementHome,
                exact: true
              },
              {
                id: "import_agents",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/import_agents",
                component: ImportAgent,
                exact: true
              },
              {
                id: "create_region_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_region_admin",
                component: CreateRegionAdmin
              },
              {
                id: "create_lbu_admin",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_lbu_admin",
                component: CreateLBUAdmin
              },
              {
                id: "create_course_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_course_admin",
                component: CreateCourseAdmin
              },
              {
                id: "create_ad_candidate_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_ad_candidate_admin",
                component: CreateCandidateAdminAD
              },
              {
                id: "create_pd_candidate_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_pd_candidate_admin",
                component: CreateCandidateAdminPD
              },
              {
                id: "create_ad_candidate",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_ad_candidate",
                component: CreateCandidateAD,
              },
              {
                id: "create_pd_candidate",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_pd_candidate",
                component: CreateCandidatePD,
              },
              {
                id: "create_guest",
                title: "Las Back-stage management",
                customDevPath: true,
                path: "/home/system_settings/user_management/create_guest",
                component: CreateGuest
              },
              {
                id: "import_dcms_files",
                title: "Las Back-stage management",
                customDevPath: true,
                path: "/home/system_settings/user_management/import_dcms_files",
                component: ImportDcmsFile
              },
              {
                id: "reset_password",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/reset_password",
                customDevPath: true,
                component: ResetPassword
              },
              {
                id: "admin_info",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/region_admin_detail",
                customDevPath: true,
                component: AdminDetail
              },
              {
                id: "lbu_admin_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/lbu_admin_detail",
                customDevPath: true,
                component: AdminDetailLBU
              },
              {
                id: "course_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/course_admin_detail",
                customDevPath: true,
                component: AdminDetailCourse
              },
              {
                id: "ad_candidate_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/ad_candidate_admin_detail",
                customDevPath: true,
                component: CandidateAdminADDetail
              },
              {
                id: "pd_candidate_admin_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/pd_candidate_admin_detail",
                customDevPath: true,
                component: CandidateAdminPDDetail
              },
              {
                id: "guest_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/guest_detail",
                customDevPath: true,
                component: GuestDetail
              },
              {
                id: "agentinfo_pacs",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/pacs_detail",
                customDevPath: true,
                component: PACSAgentDetail
              },
              {
                id: "agentinfo_pamb",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/pamb_detail",
                customDevPath: true,
                component: PAMBAgentDetail
              },
              {
                id: "candidate_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/candidate_detail",
                customDevPath: true,
                component: CandidateDetail
              }
            ]
          },
          {
            id: "cpd_settings",
            title: "Las Back-stage management",
            path: "/home/system_settings/cpd_settings",
            redirctPath:
              "/home/system_settings/cpd_settings/requiement_setting",
            component: CPDSettings,
            children: [
              {
                id: "requirement_setting",
                title: "Las Back-stage management",
                path: "/home/system_settings/cpd_settings/requiement_setting",
                component: CpdRequirement,
                customDevPath: true,
                exact: true
              },
              {
                id: "requirement_setting_create",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/cpd_settings/requirement_setting_create",
                component: RequirementSettingPambCreate,
                exact: true,
                customDevPath: true
              }
            ]
          },
          {
            id: "survey_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/survey_management",
            redirctPath: "/home/system_settings/survey_management/index",
            component: SurveyManagement,
            customDevPath: true,
            children: [
              {
                id: "survey_search",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/index",
                component: SurveySearch,
                exact: true
              },
              {
                id: "survey_create",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/survey_create",
                component: SurveyCreate,
                exact: true
              },
              {
                id: "survey_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/survey_management/survey_detail",
                component: SurveyDetail,
                exact: true
              }
            ]
          },
          {
            id: "question_group",
            title: "Las Back-stage management",
            path: "/home/system_settings/question_group",
            redirctPath: "/home/system_settings/question_group/index",
            component: QuestionGroup,
            customDevPath: true,
            children: [
              {
                id: "question_group_search",
                title: "Las Back-stage management",
                path: "/home/system_settings/question_group/index",
                component: QuestionGroupSearch,
                exact: true
              },
              {
                id: "question_group_edit",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_edit",
                component: QuestionrGroupEdit,
                exact: true
              },
              {
                id: "question_group_detail",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_detail",
                component: QuestionrGroupDetail,
                exact: true
              },
              {
                id: "question_group_create",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/question_group_create",
                component: QuestionrGroupCreate,
                exact: true
              },
              {
                id: "add_question_group_to_tem",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/question_group/add_question_group_to_tem",
                component: AddQuestionrGroup,
                exact: true
              }
            ]
          },
          {
            id: "life_asia_report",
            title: "Las Back-stage management",
            path: "/home/system_settings/life_asia_report",
            redirctPath: "/home/system_settings/life_asia_report/agent_training_code",
            component: LAReport,
            children: [
              {
                id: "agent_training_code",
                title: "Las Back-stage management",
                path: "/home/system_settings/life_asia_report/agent_training_code",
                component: PACSAgentTrainingCode,
                customDevPath: true,
                exact: true
              }
            ]
          },
          {
            id: "reminder_rule",
            title: "Las Back-stage management",
            path: "/home/system_settings/reminder_rule",
            redirctPath: "/home/system_settings/reminder_rule/reminder_list",
            component: ReminderRule,
            children: [
              {
                id: "reminder_list",
                title: "Las Back-stage management",
                path: "/home/system_settings/reminder_rule/reminder_list",
                component: ReminderList,
                customDevPath: true,
                exact: true
              },
              {
                id: "reminder_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/reminder_rule/reminder_detail",
                component: ReminderDetail,
                exact: true,
                customDevPath: true
              }
            ]
          },
          {
            id: "daily_coaching",
            title: "Las Back-stage management",
            path: "/home/system_settings/daily_coaching",
            redirctPath:
              "/home/system_settings/daily_coaching/daily_coaching_search",
            component: DailyCoaching,
            children: [
              {
                id: "daily_coaching_search",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/daily_coaching_search",
                component: DailyCoachingSearch,
                customDevPath: true,
                exact: true
              },
              {
                id: "edit_coaching_course",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/edit_coaching_course",
                component: EditCoachingCourse,
                exact: true,
                customDevPath: true
              },
              {
                id: "select_course",
                title: "Las Back-stage management",
                path: "/home/system_settings/daily_coaching/select_course",
                component: SelectCourse,
                exact: true,
                customDevPath: true
              },
              {
                id: "select_trainer",
                title: "Las Back-stage management",
                path: "/home/system_settings/daily_coaching/select_trainer",
                component: SelectTrainer,
                exact: true,
                customDevPath: true
              },
              {
                id: "select_agent_leader",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/daily_coaching/select_agent_leader",
                component: SelectAgentLeader,
                exact: true,
                customDevPath: true
              }
            ]
          },
          {
            id: 'certification',
            title: "Certification Code",
            path: '/home/system_settings/certification',
            component: Certification,
            customDevPath: true,
            redirctPath: "/home/system_settings/certification/certification_code_map",
            children: [
              {
                id: "certification_code_map",
                title: "Certification Code Map",
                path: "/home/system_settings/certification/certification_code_map",
                component: CertificationCodeMap,
                exact: true,
                customDevPath: true
              },
            ]
          },
          {
            id: 'time_job',
            title: "Las Back-stage management",
            path: '/home/system_settings/time_job',
            component: TimeJob,
            customDevPath: true
          }
        ]
      },
      {
        id: "training_history",
        title: "Las Back-stage management",
        path: "/home/training_history",
        hasSecondRoute: true,
        component: TrainingHistory,
        children: [
          {
            id: "training_requirement",
            title: "Las Back-stage management",
            path: "/home/training_history/new_agent_training_history",
            redirctPath:
              "/home/training_history/new_agent_training_history/search",
            component: TrainingRequirement,
            children: [
              {
                id: "training_requirement_search",
                title: "Las Back-stage management",
                path:
                  "/home/training_history/new_agent_training_history/search",
                component: TrainingRequirementSearch,
                customDevPath: true,
                exact: true
              },
              {
                id: "training_requirement_liam_agent_detail",
                title: "Las Back-stage management",
                path:
                  "/home/training_history/new_agent_training_history/liam_agent_detail",
                component: TRagentLIAMDetail,
                customDevPath: true,
                exact: true
              },
              {
                id: "training_requirement_mta_agent_detail",
                title: "Las Back-stage management",
                path:
                  "/home/training_history/new_agent_training_history/mta_agent_detail",
                component: TRagentMTADetails,
                customDevPath: true,
                exact: true
              }
            ]
          },
          {
            id: "cpd_history",
            title: "Las Back-stage management",
            path: "/home/training_history/cpd_history",
            redirctPath: "/home/training_history/cpd_history/search",
            component: CPDHours,
            children: [
              {
                id: "cpd_history_search",
                title: "Las Back-stage management",
                path: "/home/training_history/cpd_history/search",
                component: CPDHoursSearch,
                customDevPath: true,
                exact: true
              },
              {
                id: "cpd_history_liam_agent_detail",
                title: "Las Back-stage management",
                path: "/home/training_history/cpd_history/liam_agent_detail",
                customDevPath: true,
                component: CPDHoursLIAMDetail,
                exact: true
              },
              {
                id: "cpd_history_mta_agent_detail",
                title: "Las Back-stage management",
                path: "/home/training_history/cpd_history/mta_agent_detail",
                customDevPath: true,
                component: CPDHoursMTADetail,
                exact: true
              }
            ]
          }
        ]
      },
      {
        id: "scheme_management",
        title: "Las Back-stage management",
        path: "/home/scheme_management",
        redirctPath: "/home/scheme_management/pru_venture_registration",
        component: SchemeManagement,
        hasSecondRoute: true,
        children: [
          {
            id: 'pru_venture_registration',
            title: "Las Back-stage management",
            path: '/home/scheme_management/pru_venture_registration',
            redirctPath: "/home/scheme_management/pru_venture_registration/list",
            component: Registration,
            customDevPath: true,
            children: [
              {
                id: 'pru_venture_registration',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_registration/individual_add',
                component: RegistrationIndividualAdd,
                customDevPath: true,
              },
              {
                id: 'pru_venture_registration',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_registration/bulk_add',
                component: RegistrationBulkAdd,
                customDevPath: true,
              },
              {
                id: 'pru_venture_registration',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_registration/list',
                component: RegistrationList,
                customDevPath: true,
              },
            ]
          },
          {
            id: 'pru_venture_my_session',
            title: "Las Back-stage management",
            path: '/home/scheme_management/pru_venture_my_session',
            redirctPath: "/home/scheme_management/pru_venture_my_session/create",
            component: MySession,
            customDevPath: true,
            children: [
              {
                id: 'pru_venture_my_session_details',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_my_session/create',
                component: MySessionDetails,
                customDevPath: true,
              },
              {
                id: 'pru_venture_my_session_qr_code',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_my_session/qr_code',
                component: MySessionQRCode,
                customDevPath: true,
              },
              {
                id: 'pru_venture_my_session_attendance',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_my_session/attendance',
                component: AttendanceDetail,
                customDevPath: true,
              },
            ]
          },
          {
            id: 'pru_venture_attendance_report',
            title: "Las Back-stage management",
            path: '/home/scheme_management/pru_venture_attendance_report',
            component: AttendanceReport,
            redirctPath: '/home/scheme_management/pru_venture_attendance_report/list',
            customDevPath: true,
            children: [
              {
                id: 'pru_venture_attendance_report_list',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_attendance_report/list',
                component: AttendanceReportList,
                customDevPath: true,
                exact: true
              },
              {
                id: 'pru_venture_attendance_report_details_perios',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_attendance_report/period',
                component: (props) => <AttendanceReportDetail type='period' {...props}/>,
                customDevPath: true,
                exact: true
              },
              {
                id: 'pru_venture_attendance_report_details',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_attendance_report/overall',
                component: (props) => <AttendanceReportDetail type='overall' {...props}/>,
                customDevPath: true,
                exact: true
              },
            ]
          },
          {
            id: 'pru_venture_requirement_setting',
            title: "Las Back-stage management",
            path: '/home/scheme_management/pru_venture_requirement_setting',
            redirctPath: "/home/scheme_management/pru_venture_requirement_setting/create",
            component: RequirementSetting,
            customDevPath: true,
            children: [
              {
                id: 'pru_venture_requirement_setting_create',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_requirement_setting/create',
                component: CreateRequirement,
                customDevPath: true,
              },
              {
                id: 'pru_venture_requirement_setting_details',
                title: "Las Back-stage management",
                path: '/home/scheme_management/pru_venture_requirement_setting/details',
                component: RequirementSettingDetails,
                customDevPath: true,
              }
            ]
          },
        ]
      },
      {
        id: "404",
        path: "/home/404",
        component: NotFound,
        initialRoute: true
      },
      {
        id: "403",
        path: "/home/403",
        component: Forbidden,
        initialRoute: true
      },
    ]
  },
  {
    id: "qrcode_attendance",
    title: "QRCode Attendance",
    path: `/${globalUrlPre}/qrcode_attendance`,
    customDevPath: true,
    exact: true,
    component: () => <QRCodePage />,
    initialRoute: true
  },
  {
    id: "403",
    path: "/403",
    component: Forbidden,
    initialRoute: true
  },
  {
    id: "500",
    path: "/500",
    component: ServerError,
    initialRoute: true
  },
];

export default RouteConfig;
