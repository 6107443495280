import React from "react";
import { Button } from "antd";
import styles from '../index.module.less';
import { originalUrlPrefix } from '../../../../../../utils/config';

function Tabs(props) {
  const { isRistrationTab } = props.CourseDetailReducer;
  const { tabChangesTrigger } = props;
  const href = `${originalUrlPrefix.phkl}/model/import_attendance_template.xls`;

  return (
    <div>
      <Button
        type="danger"
        className={`${styles.btn} ${styles.actionBtn} ${isRistrationTab ? styles.active : null}`}
        onClick={() => {
          tabChangesTrigger(true);
        }}
      >
        Registration
      </Button>

      <Button
        type="danger"
        className={`${styles.btn} ${styles.actionBtn} ${!isRistrationTab ? styles.active : null}`}
        onClick={() => {
          tabChangesTrigger(false);
        }}
      >
        Attendance
      </Button>

      {
        !isRistrationTab && (
          <Button
            type="danger"
            className={`${styles.btn} ${styles.downloadBtn} ${styles.actionBtn} ${styles.active}`}
            download
            href={href}
            onClick={() => {
              tabChangesTrigger(false);
            }}
          >
            Download Template
          </Button>
        )
      }
    </div>
  );
}

export default Tabs;
