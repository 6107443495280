import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Spin,
  Tooltip,
  Table,
  Empty,
  Icon,
  Transfer
} from "antd";
import { changeVals } from "../../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  getTrainerList,
  trainerAlredayList
} from "../../../../../redux/pacsReducer/systemSettingsReducers/SelectTrainerReducer";

import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "SelectTrainer_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("SelectTrainer", changedFields);
  },
  mapPropsToFields(props) {
    return {
      trainerName: Form.createFormField({
        ...props.trainerName,
        value: props.trainerName.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const {
    loading,
    allList,
    trainerHaveList,
    trainerKeyList
  } = props;
  const wholeData = allList.concat(trainerHaveList);
  const a = [];
  const finalData = [];
  wholeData.forEach(item => {
    if (a.indexOf(item.userId) < 0) {
      finalData.push(item);
      a.push(item.userId);
    }
  });

  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Trainer Name">
              {getFieldDecorator("trainerName", {})(<Input />)}
            </Form.Item>
          </Col>
          <Col {...flexCol} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div>
          <TableTransfer
            dataSource={finalData}
            targetKeys={trainerKeyList}
            onChange={(targetKeys, direction, moveKeys) => {
              props.handleChange(targetKeys, direction, moveKeys, finalData);
            }}
            leftColumns={props.leftTableColumns}
            rightColumns={props.leftTableColumns}
            titles={["Select Trainer", "Selected Trainer"]}
          />
        </div>
        <Button
          type="primary"
          style={{ marginTop: "50px" }}
          className={`${styles.btnColor} ${styles.btnText}`}
          onClick={() => {
            props.done();
          }}
        >
          Done
        </Button>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { systemSettingsReducers: { SelectTrainerReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: SelectTrainerReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    getTrainerList,
    trainerAlredayList
  }
)
class SelectTrainer extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.done = this.done.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      tableData: {
        courseName: "",
        pageSize: 10,
        currentPageNumber: 1
      },
      mockData: [],
      targetKeys: [],
      trainerHaveList: [],
      trainerKeyList: [],
      leftTableColumns: [
        {
          dataIndex: "title",
          title: "Trainer Name"
        },
        {
          dataIndex: "email",
          title: "Email"
        }
      ],
      columns: [
        {
          title: "Course Name",
          dataIndex: "name",
          align: "center",
          width: 300,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          width: 250,
          align: "center"
        },
        {
          title: "Option",
          dataIndex: "option",
          ellipsis: true,
          align: "center",
          width: 150,
          render: (text, record) => {
            return (
              <div style={insideStyles.tableData}>
                <div
                  onClick={() => {
                    this.props.changeVals("EditCoachingCourse", {
                      courseName: {
                        value: record.name
                      },
                      courseCode: {
                        value: record.code
                      }
                    });

                    this.props.history.push(
                      "/home/system_settings/daily_coaching/edit_coaching_course",
                      {}
                    );
                  }}
                >
                  Ok
                </div>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    const {
      pacsDailyCoachingRuleId,
      trainerList,
      trainerHaveList,
      type
    } = this.props.location.state;
    this.props.changeVals("SelectTrainer", {
      trainerName: {
        value: ""
      }
    });
    this.props.getTrainerList(pacsDailyCoachingRuleId, "", type);

    const listData = [];
    trainerHaveList.map(item => {
      const obj = {
        key: item.userId,
        title: `${item.userName}`,
        description: "",
        chosen: true,
        ...item
      };
      listData.push(obj);
    });
    this.setState(() => ({
      trainerKeyList: trainerList,
      trainerHaveList: listData
    }));
  }

  handleChange = (targetKeys, direction, moveKeys, finalData) => {
    const allList = [...finalData];
    const { trainerKeyList, trainerHaveList } = this.state;
    const a = [...trainerKeyList];
    const b = [...trainerHaveList];
    allList.map(item => {
      for (let i = 0; i < moveKeys.length; i += 1) {
        if (moveKeys[i] === item.userId) {
          item.chosen = direction !== "left";
          if (direction === "left") {
            if (a.indexOf(item.userId) > -1) {
              const index = a.indexOf(item.userId);

              a.splice(index, 1);
              b.splice(index, 1);
            }
          } else if (direction === "right") {
            if (a.indexOf(item.userId) < 0) {
              a.push(item.userId);
              b.push(item);
            }
          }
          break;
        }
      }
      return item;
    });

    this.setState(() => ({
      trainerKeyList: a,
      trainerHaveList: b
    }));


    this.props.changeVals("SelectTrainer", {
      allList,
      trainerList: a
    });
  };

  handleSubmit = form => {
    const { pacsDailyCoachingRuleId, type } = this.props.location.state;
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;

      if (!err) {
        this.props.getTrainerList(
          pacsDailyCoachingRuleId,
          fields.trainerName.value,
          type
        );
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    this.setState(() => ({
      pageSize,
      pageIndex: current
    }));
  };

  done() {
    const allList = [...this.state.trainerHaveList];
    const trainerList = [];
    const trainerNameArr = [];
    allList.forEach(item => {
      trainerList.push(item.userId);
      trainerNameArr.push(item.title);
    });
    this.props.history.push(
      "/home/system_settings/daily_coaching/edit_coaching_course",
      {
        ...this.props.location.state,
        trainerList,
        trainerName: trainerNameArr.join(","),
        go: false
      }
    );
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange,
      pageSize: this.state.pageSize,
      pageIndex: this.state.pageIndex,
      handleChange: this.handleChange,
      trainerHaveList: this.state.trainerHaveList,
      trainerKeyList: this.state.trainerKeyList,
      leftTableColumns: this.state.leftTableColumns,
      renderItem: this.renderItem,
      done: this.done
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push(
                "/home/system_settings/daily_coaching/edit_coaching_course",
                {
                  ...this.props.location.state,
                  go: false
                }
              );
            }}
          />
          Select Trainer
        </div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(SelectTrainer);

const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} showSelectAll={false}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled
    }) => {
      const columns = direction === "left" ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const wholeArray = treeSelectedKeys.concat(listSelectedKeys);
          const uniqueArray = [...new Set(wholeArray)];
          const diffKeys = uniqueArray;
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys
      };

      return (
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{ pointerEvents: listDisabled ? "none" : null }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            }
          })}
        />
      );
    }}
  </Transfer>
);
