import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table
} from "antd";

import moment from "moment";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../utils/global";
import {
  getTrainerNameList,
  getCourseList,
  getCourseNameList,
  getSessionNameList,
  save_data,
  update_time,
  init_data,
  download_sessionday,
  getAttendanceScanTimes,
  getVenueNameList,
  getVenueRegionList,
  update_attendance_statistics
} from "../../../../redux/plukReducer/attendanceReducers/CourseFinderReducer";
import { update_base_information } from "../../../../redux/plukReducer/attendanceReducers/SessionDetailReducer";
import {
  attendanceDetails
} from "../../../../images";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateCourseFinder_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("CourseFinder", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      moduleName: Form.createFormField({
        ...props.moduleName,
        value: props.moduleName.value
      }),
      trainerName: Form.createFormField({
        ...props.trainerName,
        value: props.trainerName.value
      }),
      venueRegion: Form.createFormField({
        ...props.venueRegion,
        value: props.venueRegion.value
      }),
      venueName: Form.createFormField({
        ...props.venueName,
        value: props.venueName.value
      }),
      venueAddress: Form.createFormField({
        ...props.venueAddress,
        value: props.venueAddress.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, columns } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {})(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Module Name">
              {getFieldDecorator("moduleName", {
                rules: [
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {})(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Trainer Name">
              {getFieldDecorator(
                "trainerName",
                {}
              )(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.trainerNameList.length
                    ? props.trainerNameList.map(item => (
                      <Option
                        value={item.instructorId}
                        key={item.instructorId}
                      >
                        <Tooltip placement="top" title={item.instructorName}>
                          {item.instructorName}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Venue Region">
              {getFieldDecorator(
                "venueRegion",
                {}
              )(
                <Select defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.venueRegionList.length
                    ? props.venueRegionList.map(item => (
                      <Option
                        value={item.venueRegionId}
                        key={item.venueRegionId}
                      >
                        <Tooltip placement="top" title={item.venueRegionName}>
                          {item.venueRegionName}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Venue Name">
              {getFieldDecorator(
                "venueName",
                {}
              )(
                <Select defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.venueNameList.length
                    ? props.venueNameList.map(item => (
                      <Option value={item.venueId} key={item.venueId}>
                        <Tooltip placement="top" title={item.venueName}>
                          {item.venueName}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <Form.Item label="Venue Address">
              {getFieldDecorator("venueAddress", {
                rules: [
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item
              labelCol={{ span: 5 }}
              label="Training Date"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("trainingDate", {})(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    format: "HH:mm",
                    defaultValue: [
                      moment("00:00", "HH:mm"),
                      moment("23:59", "HH:mm")
                    ]
                  }}
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm"]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.btnCol}>
          <Button
            className={styles.btn}
            style={{ display: props.showList ? "block" : "none" }}
            type="danger"
            icon="download"
            onClick={() => {
              props.download_sessionday({
                venueAddress: props.venueAddress.value,
                venueName: props.venueName.value,
                sessionName: props.sessionName.value,
                courseName: props.courseName.value,
                moduleName: props.moduleName.value,
                endDate: props.trainingDate.value.length
                  ? props.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                instructorId: props.trainerName.value,
                startDate: props.trainingDate.value.length
                  ? props.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                venueRegion: props.venueRegion.value
              });
            }}
          >
            Download to Excel
          </Button>
        </div>
        <div
          style={{
            display: props.showList ? "block" : "none"
          }}
        >
          <Table
            bordered
            scroll={{ x: 5000, y: 380 }}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
            rowKey="sessionId"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { attendanceReducers: { CourseFinderReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: CourseFinderReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    getTrainerNameList,
    getCourseList,
    getCourseNameList,
    getSessionNameList,
    save_data,
    init_data,
    update_time,
    update_base_information,
    download_sessionday,
    getAttendanceScanTimes,
    getVenueNameList,
    getVenueRegionList,
    update_attendance_statistics
  }
)
class CourseFinder extends Component {
  constructor(props) {
    super(props);
    this.goDetail = this.goDetail.bind(this);
    this.showQRCode = this.showQRCode.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 50,
      pageIndex: 1,
      timer: null,
      time: 0,
      flash: false,
      timeText: 0,
      scanType: true,
      tableData: {
        courseId: "",
        endDate: "",
        instructorId: "",
        sessionId: "",
        startDate: "",
        venueRegion: "",
        pageSize: 50,
        currentPageNumber: 1
      },
      columns: [
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 300,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Code",
          dataIndex: "courseCode",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Module Name",
          dataIndex: "moduleName",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          align: "center"
        },
        {
          title: "Trainer",
          dataIndex: "instructorName",
          align: "center"
        },
        {
          title: "Training Date Start",
          dataIndex: "startDate",
          ellipsis: true,
          align: "center",
          render: text => {
            const startDate =
              text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
            return startDate;
          }
        },
        {
          title: "Training Date End",
          dataIndex: "endDate",
          ellipsis: true,
          align: "center",
          render: text => {
            const endDate =
              text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
            return endDate;
          }
        },

        {
          title: "Venue Region",
          dataIndex: "venueRegion",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },

        {
          title: "Venue",
          dataIndex: "venueName",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Registered",
          dataIndex: "registeredSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Attended",
          dataIndex: "attendedSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Walk In",
          dataIndex: "walkInSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Completed",
          dataIndex: "completedSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Absent",
          dataIndex: "absentSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Completed",
          dataIndex: "courseCompletedSum",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Submit Date Time",
          dataIndex: "submitDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },

        {
          title: "Submit By",
          dataIndex: "submitBy",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Status",
          dataIndex: "submitStatus",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Option",
          dataIndex: "option",
          ellipsis: true,
          fixed: "right",
          align: "center",
          width: 150,
          render: (text, record) => {
            return (
              <div style={insideStyles.tableData}>
                <Tooltip placement="top" title="Details">
                  <div
                    className={styles.details}
                    style={setBg(attendanceDetails)}
                    onClick={() => {
                      this.goDetail(record.sessionId, record.isValid);
                    }}
                  />
                </Tooltip>
              </div>
            );
          }
        }
      ],
      statisticsColumns: [
        {
          title: "Registered",
          dataIndex: "registeredSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Attended",
          dataIndex: "attendedSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Walk in",
          dataIndex: "walkInSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Completed",
          dataIndex: "completedSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Absent",
          dataIndex: "absentSum",
          ellipsis: true,
          align: "center"
        },
        {
          title: "Course Completed",
          dataIndex: "courseCompletedSum",
          ellipsis: true,
          align: "center"
        }
      ]
    };
  }

  componentDidMount() {
    this.getDefaultListData();
    this.props.getVenueRegionList();
    this.props.getVenueNameList();
  }

  async getDefaultListData() {
    await this.props.getTrainerNameList();
    await this.props.getCourseNameList();
  }

  setTimer() {
    const ScanTimes = this.props.fields.ScanTimes;
    this.setState(() => ({
      time: ScanTimes,
      timeText: ScanTimes
    }));
    const timer = setInterval(() => {
      const time = this.state.time - 1;
      if (time < 0) {
        clearInterval(this.state.timer);
        this.freshQR();
      } else {
        this.setState(() => ({
          time,
          timeText: time > 9 ? time : `0${time}`
        }));
      }
    }, 1000);
    this.setState(() => ({
      timer
    }));
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;

      if (!err) {
        if (fields.trainingDate.value.length) {
          const year = new Date().getFullYear();
          /* eslint-disable */
          let seconds = 0;
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            seconds = 31643326;
          } else {
            seconds = 31556926;
          }
          /* eslint-disable */
          const start = new Date(
            fields.trainingDate.value[0].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const end = new Date(
            fields.trainingDate.value[1].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const between = Math.round(end / 1000) - Math.round(start / 1000);
          if (seconds < between) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }

        }
        this.setState(() => ({
          tableData: {
            venueAddress: fields.venueAddress.value,
            venueName: fields.venueName.value,
            endDate: fields.trainingDate.value.length
              ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
              : "",
            instructorId: fields.trainerName.value,
            sessionName: fields.sessionName.value,
            courseName: fields.courseName.value,
            moduleName: fields.moduleName.value,
            startDate: fields.trainingDate.value.length
              ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
              : "",
            venueRegion: fields.venueRegion.value,
            pageSize: this.state.pageSize,
            currentPageNumber: this.state.pageIndex
          }
        }));
        this.props.getCourseList({
          venueAddress: fields.venueAddress.value,
          venueName: fields.venueName.value,
          endDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : "",
          instructorId: fields.trainerName.value,
          sessionName: fields.sessionName.value,
          courseName: fields.courseName.value,
          moduleName: fields.moduleName.value,
          startDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          venueRegion: fields.venueRegion.value,
          pageSize: this.state.pageSize,
          currentPageNumber: this.state.pageIndex
        });
      }
    });
  };

  disabledDate(current) {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    // Can not select days before today and today
    return current >= moment(new Date(`${year + 1}/${month}/${day}`));
  }

  freshQR() {
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;


    this.props.update_time({
      nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
      currentTime: date.getTime(),
      expirationTime: date.getTime() + this.props.fields.ScanTimes * 1000
    });
    this.setTimer();
  }

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { fields } = this.props;


    this.props.getCourseList({
      venueAddress: fields.venueAddress.value,
      venueName: fields.venueName.value,
      endDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : "",
      instructorId: fields.trainerName.value,
      sessionName: fields.sessionName.value,
      courseName: fields.courseName.value,
      moduleName: fields.moduleName.value,
      startDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      venueRegion: fields.venueRegion.value,
      pageSize,
      currentPageNumber: current
    });
  };

  showQRCode(e, item, type) {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
    const startDate =
      item.startDate.lastIndexOf(".") > -1
        ? item.startDate.split(".")[0]
        : item.startDate;
    const endDate =
      item.endDate.lastIndexOf(".") > -1
        ? item.endDate.split(".")[0]
        : item.endDate;
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
    //eslint-disable-line
    e.stopPropagation();
    this.setState(() => ({
      scanType: type
    }));

    this.props.save_data({
      showQR: true,
      qrDetail: {
        sessiondayId: item.sessiondayId,
        courseName: item.courseName,
        moduleName: item.moduleName,
        sessionName: item.sessionName,
        startTime: startDate,
        endTime: endDate,
        trainer: item.instructorName,
        venueRegion: item.venueRegion,
        venueName: item.venueName,
        venueAddress: item.venueAddress,
        nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
        currentTime: date.getTime(),
        expirationTime: date.getTime() + this.props.fields.ScanTimes * 1000,
        scanType: type
      }
    });
    this.setTimer();
  }

  clearTimer() {
    this.props.save_data({
      showQR: false
    });
    clearInterval(this.state.timer);
  }

  goDetail(sessionId, isValid) {
    const buttonList = this.props.buttonList;
    const attendanceBtn = {};
    let showBtn = false;
    for (let i = 0; i < buttonList.length; i++) {
      if (buttonList[i].path.indexOf("session_detail") > -1) {
        attendanceBtn[buttonList[i].name] = buttonList[i];
        showBtn = true;
      }
    }

    this.props.history.push("/home/attendance/session_detail", {
      isValid,
      sessionId,
      attendanceBtn,
      showBtn,
      agentType: "",
      sessiondayId: ""
    });
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      goDetail: this.goDetail,
      loading: this.props.loading,
      showQRCode: this.showQRCode,
      download_sessionday: this.props.download_sessionday,
      disabledDate: this.disabledDate,
      getSessionNameList: this.props.getSessionNameList,
      columns: this.state.columns,
      statisticsColumns: this.state.statisticsColumns,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Course Finder</div>

        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};
const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});
const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(CourseFinder);
