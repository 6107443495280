import React, { Component } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./redux";
import PreRoute from "./PreRoute";

// Google Browser decides whether Redux exists or not, which is convenient for debugging.
/* eslint-disable */
const reduxDevTool = window.__REDUX_DEVTOOLS_EXTENSION__
  ? window.__REDUX_DEVTOOLS_EXTENSION__()
  : f => f;
/* eslint-enable */
const store = createStore(
  reducers,
  compose(
    applyMiddleware(thunk),
    reduxDevTool
  )
);
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PreRoute />
      </Provider>
    );
  }
}

export default App;
