import React from 'react'
import {
  Tooltip,
} from "antd";
import moment from 'moment'

const format = "YYYY-MM-DD HH:mm"

export const columnsOverall = [
         {
           title: "Month",
           dataIndex: "month",
           align: "center",
           render: text => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "Requirement Fulfillment",
           dataIndex: "fulfillStatus",
           align: "left",
           render: text => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "Session Required",
           dataIndex: "sessionRequired",
           align: "left",
           render: (text, data, index) => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "Session Completed",
           dataIndex: "sessionCompleted",
           align: "left",
           render: (text, data, index) => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "AL",
           dataIndex: "alCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         },
         {
           title: "AG",
           dataIndex: "agCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         },
         {
           title: "Zoom Trainer",
           dataIndex: "zoomTrainerCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         }
       ];


export const columnsPeriod = [
         {
          title: 'No',
          dataIndex: "index",
          align: "center",
          width: 50,
          render: (text, data, index) => {
            return (
              <Tooltip placement="top" title={index + 1}>
                {index + 1}
              </Tooltip>
            )
          }
         },
         {
           title: "Session Type",
           dataIndex: "sessionType",
           align: "left",
           render: text => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "Session Start Time",
           dataIndex: "sessionStartTime",
          sorter: true,
           align: "left",
           render: (text, data, index) => {
            const t = moment(moment.utc(text).toDate()).format(format);
             return (
             <Tooltip placement="top" title={t}>
               {t}
             </Tooltip>
             )
           }
         },
         {
           title: "Session End Time",
           dataIndex: "sessionEndTime",
          sorter: true,
           align: "left",
           render: (text, data, index) => {
            const t = moment(moment.utc(text).toDate()).format(format);
             return (
             <Tooltip placement="top" title={t}>
               {t}
             </Tooltip>
             )
           }
         },
         {
           title: "Trainer",
           dataIndex: "trainerName",
           align: "left",
           render: (text, data, index) => (
             <Tooltip placement="top" title={text}>
               {text}
             </Tooltip>
           )
         },
         {
           title: "Scan In",
           dataIndex: "scanIn",
           align: "left",
           render: (text, data, index) => {
            const t = moment(moment.utc(text).toDate()).format(format);
             return (
             <Tooltip placement="top" title={t}>
               {t}
             </Tooltip>
             )
           }
         },
         {
           title: "Scan Out",
           dataIndex: "scanOut",
           align: "left",
           render: (text, data, index) => {
            const t = moment(moment.utc(text).toDate()).format(format);

             return (
             <Tooltip placement="top" title={t}>
               {t}
             </Tooltip>
             )
           }
         },
       ];
