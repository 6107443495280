import React from "react";
import { connect } from "react-redux";
import Router from "../../../../../router";

@connect(state => state)
class LAReport extends React.Component {
  componentDidMount() {}

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default LAReport;
