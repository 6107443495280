import React, { useState, useEffect } from "react";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { Form, Row, Col, Button, Select, Checkbox, Spin, DatePicker, notification, Input, Modal } from "antd";
import { get, post } from '../../../../../../utils/request';
import { globalPromptTime10s } from '../../../../../../utils/global';
import styles from './list.module.less';


const flexCol = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};


function EditModal(props) {
  const [loading, setLoading] = useState(false);
  const { form: { getFieldDecorator }, visible, handleOk, onHandleCancel, data, onFinshUpdate } = props;

  const CPDHourPattern = /^([0-9]\d*|([0-9]\d*\.(5)))$/; // match 0, 1.5, 1 etc
  const CPDNumberErrorMessage = 'Please enter the correct CPD hour format, i.e. 0, 0.5, 1.5, 2 ...';



  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState(false);


  useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  useEffect(() => {
    setModalData({...data})
    // eslint-disable-next-line
    props.form.setFieldsValue({
      ethics: data?.effectiveCpd?.Ethics,
      rr: data?.effectiveCpd && data?.effectiveCpd['R&R'],
      cis: data?.effectiveCpd && data?.effectiveCpd.CIS,
      ah: data?.effectiveCpd && data?.effectiveCpd['A&H'],
      others: data?.effectiveCpd && data?.effectiveCpd.Others,
      err: data?.effectiveCpd && data?.effectiveCpd.ERR,
      updateReason: data?.updateReason
    })
  }, [data])


  const handleSubmit = async () => {
    props.form.validateFields({ first: true }, async (err, values) => {
      if (err) {
        return;
      }

      setLoading(true);
      const payload = {
        ...modalData,
        ...values,
      };

      if (modalData.isMassUpdate) {
        delete payload.agentCode;
      } else {
        payload.courseUserIds = [modalData.courseUserId];
      }

      try {
        const res = await post("/cpdEditing/hours", payload);

        if (res.success) {
          setLoading(false);

          notification.success({
            message: "Successfully created",
            duration: globalPromptTime10s
          });

          onHandleCancel();
          onFinshUpdate();
        } else {
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    });
  }


  // console.log(props.form.getFieldsValue(['ethics']))

  return (
    <Modal
      title="CPD Hour Editing"
      visible={modalVisible}
      onOk={(e) => handleSubmit(e, props)}
      onCancel={onHandleCancel}
      okText='Save'
      okType='danger'
    >
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
        // initialValues={modalData}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="FC Code">
              {
                getFieldDecorator("agentCode", {
                  initialValue: modalData.agentCode
                })(<Input disabled />)
              }
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Course Code">
              {getFieldDecorator("courseCode", {
                initialValue: modalData.courseCode
              })(<Input disabled />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {
                initialValue: modalData.courseName
              })(<Input disabled />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Core Ethics">
              {getFieldDecorator("ethics", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Core R&R">
              {getFieldDecorator("rr", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Core ERR">
              {getFieldDecorator("err", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Supplementary CIS">
              {getFieldDecorator("cis", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Supplementary A&H">
              {getFieldDecorator("ah", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input/>)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Supplementary Others">
              {getFieldDecorator("others", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    pattern: CPDHourPattern,
                    message: CPDNumberErrorMessage
                  }
                ]
              })(<Input/>)}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Reason of Update">
              {getFieldDecorator("updateReason", {
                rules: [
                  {
                    required: true,
                    message: "Must not be blank",
                  },
                  {
                    max: 240,
                    message: "fields cannot be longer than 240 characters"
                  }
                ]
              })(<Input/>)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>

  );
}

const EditModalModal = Form.create({ name: "AttendanceTemp" })(EditModal);

export default withRouter(EditModalModal);
