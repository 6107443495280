// axiosSetting.js
import axios from "axios";
import { notification } from "antd";
import {get} from 'lodash'
import { getFilenameFromContentDispositionHeader } from './content-disposition'
import { originalUrlPrefix, BASE_URL_PAMB } from "./config";
import { globalPromptTime10s, buCuontryList } from "./global";
import { clearStorage, forbidden, serverError, redirectBaseUrl, readBlobToJson, replaceSpaces } from "./index";
import { handleCheckToken } from "./refreshToken";
// axios.defaults.withCredentials = true;
// axios.defaults.timeout = 30000;

const adminQueryList = [
  "/user/getMenusAndButtonsByRoleId",
  "/systemSetting/getRoleList",
  "/systemSetting/getBuList",
  '/systemSetting/createRegionAdmin',
  '/systemSetting/createLbuAdmin',
  "/systemSetting/createCourseAdmin",
  '/systemSetting/createPDCandidateAdmin',
  '/systemSetting/createADCandidateAdmin',
  "/user/roleList",
  "/user/allRoleList",
  "/login/saveMyProfile",
];

const commonQueryList = [
  "/user/userList",
  "/user/downloadUserList",
  "/user/userDeatail",
  "/user/Activate",
  "/user/Deactivate",
  "/login/generateLink",
  "/agent/checktAgent",
  "/agent/importAgent",
  "/user/getResetDefaultPassword",
  "/user/resetPasswordByAdmin",
  '/login/myProfile',
  '/login/changePassword'
];

const pacsQueryList = [
  "/job/getLockTable",
  "/job/kill",
  "/job/getLockTable",
];

const noneList = [
  "/attendancepacs/job/getlist",
  "/attendancepamb/job/getlist"
]

axios.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('onlyPopFirstErr')) {
      sessionStorage.removeItem('onlyPopFirstErr')
    }
    // config.headers['Content-Type'] = 'application/json; charset=UTF-8';
    const token = sessionStorage.getItem("user") || "";
    config.headers.Authorization = token; // add Unified request token
    // set time zone
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    config.headers['X-TIME-ZONE'] = timezone;
    const prefix = sessionStorage.getItem("prefix");
    const buCountry = sessionStorage.getItem("buCountry");
    if (buCountry) {
      if (buCountry === 'REGIONAL') {
        if (config.baseURL) {
          console.log("config.baseURL:", config.baseURL) // eslint-disable-line
        }
        config.baseURL = originalUrlPrefix[prefix]
      } else {
        config.baseURL = originalUrlPrefix[buCountry.toLocaleLowerCase()]
      }
    } else {
      config.baseURL = originalUrlPrefix[prefix]
    }
    // Dealing with malformed interfaces
    const nowUrl =
      config.url.indexOf("?") > -1 ? config.url.split("?")[0] : config.url;

    // admin Server
    const isAdmin = adminQueryList.includes(nowUrl);
    if (isAdmin) {
      config.baseURL = originalUrlPrefix.admin;
    }

    const isPacs = pacsQueryList.includes(nowUrl);
    if (isPacs) {
      config.baseURL = originalUrlPrefix.pacs;
    }

    if (nowUrl.indexOf("/job/") > -1) {
      config.baseURL = BASE_URL_PAMB;
    }

    const isNone = noneList.includes(nowUrl);
    if (isNone) {
      config.baseURL = BASE_URL_PAMB;
    }

    // Select service according to parameters
    const isPublic = commonQueryList.includes(nowUrl);
    if (isPublic) {
      redirectBaseUrl(config); // splices the fields of the secondary route for the interface to distinguish admin, pacs and pamb
    }

    if (buCountry === buCuontryList.PAMB) {
      const attendancepamb = '/attendancepamb/attendance/sendToTms'
      if (attendancepamb === nowUrl) {
        config.baseURL = BASE_URL_PAMB;
      }
    } else if (buCountry === buCuontryList.REGIONAL) {
      const getUserStatusUrl = '/user/getStatusList'
      if (nowUrl === getUserStatusUrl) {
        config.baseURL = originalUrlPrefix.pacs;
      }
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    if (sessionStorage.getItem('onlyPopFirstErr')) {
      return null
    }
    const { data, status, headers, config, request } = response;
    // handle refreshToken
    if (data && !data.success && data.code === 9905) {
      return handleCheckToken(config)
    }

    if (
      (headers["content-type"] &&
        headers["content-type"].indexOf("application/octet-stream") > -1) ||
      (headers["content-type"] &&
        headers["content-type"].indexOf("application/vnd.ms-excel") > -1) ||
      (headers["content-type"] &&
        headers["content-type"].indexOf("text/csv") > -1)
    ) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      return blob
    }
    if (headers["content-type"] &&
        headers["content-type"].indexOf(
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) > -1) {
      const blob = new Blob([data], { type: "application/octet-stream" });
      const disposition = headers["content-disposition"];
      let fileName = ''
      if (disposition) {
        fileName = getFilenameFromContentDispositionHeader(disposition)
      }
      return !fileName ? blob : {blob, fileName};
    }

    if (status === 200 && !data) {
      return;
    }

    if (status === 200 && data.success) {
      return data;
    }

    if (status === 202 && data.success) {
      return data;
    }

    if (sessionStorage.getItem("buCountry") === "PACS" || sessionStorage.getItem("prefix") === "pacs") {
      if (status === 200 && data.code === 1033) {
        return Promise.reject(data);
      }
      if (data.code === 1032 && data.message.indexOf("Scan In is not in valid range!") > -1) {
        return Promise.reject(data);
      }
    }
    // handle PAMB admin res code 30001 30002 30003
    if (sessionStorage.getItem("buCountry") === "PAMB" || sessionStorage.getItem("prefix") === "admin") {
      // handle post reject
      if (data && !data.success && (data.code === 30001 || data.code === 30002 || data.code === 30003 || data.code === 50001 || data.code === 50002 || data.code === 40001 || data.code === 40002)) {
        return Promise.reject(data)
      }
    }

    // handle sso logic, The reason why it can come here is that there is no success ahead
    if (
      config.url.indexOf("/user/getMenusAndButtonsByRoleId?type=checkToken") >
      -1
    ) {
      notification.error({
        message: "Sign in failed, please sign in again",
        duration: globalPromptTime10s,
        className: "ant-style-notification-error"
      });
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("refreshToken");
      return Promise.reject(data);
    }

    if (request.responseType === 'blob') {
      readBlobToJson(data, notification, globalPromptTime10s)
      return Promise.reject(data);
    }

    notification.error({
      message: replaceSpaces(data.message),
      duration: globalPromptTime10s,
      className: "ant-style-notification-error"
    });

    return Promise.reject(data);
  },
  error => {
    if (!error) {
      return Promise.reject(error);
    }
    const { response } = error;

    if (!response) {
      // serverError();
      return Promise.reject(error);
    }


    // use try catch to avoid error
    try {
      const { data: { status } = {} } = response || {};
      if (status === 401) {
        clearStorage();
      } else if (status === 403) {
        forbidden();
      } else if (status === 500) {
        serverError();
      } else if (status === 504) {
        serverError();
      }
    } catch (e) {
      console.log(e);
    }

    return Promise.reject(error);
  }
);
