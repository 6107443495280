import React, { useState, useEffect } from 'react'
import {
  Collapse,
  Form,
  InputNumber,
  Radio,
} from 'antd-v5'
import {
  Popconfirm,
  Icon,
  Col,
  Button,
  Row,
} from 'antd'
import styles from './RequirementSettingPanel.module.less'

const { Panel } = Collapse

const flexRow = {
  gutter: [22, 0]
};

const contentFlexCol = {
  xxl: 15,
  xl: 15,
  lg: 15,
  md: 15,
  sm: 12,
  xs: 12
};

const headerFlexCol = {
  xxl: 10,
  xl: 10,
  lg: 8,
  md: 8,
  sm: 8,
  xs: 8
}

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

const headerFormLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
};

const frequencyMap = {
  MONTHLY: '1',
  BI_WEEKLY: '2',
  WEEKLY: '3'

}

const RequirementSettingPanel = (props) => {
  const [resource, setResource] = useState(props.data)
  const [disabled, setDisabled] = useState(props.disabled)
  const [headerForm] = Form.useForm();
  const [panelForm] = Form.useForm();
  const [index, setIndex] = useState(props.index)
  const [activeKey, setActiveKey] = useState(props.data.requirementId || ['0'])
  const [validateInfo, setValidateInfo] = useState({
    status: 'success',
    help: ''
  })
  useEffect(() => {
    setIndex(index)
  }, [index])

  useEffect(() => {
    setResource(props.data)
    headerForm.setFieldsValue(props.data)
    panelForm.setFieldsValue(props.data)
  }, [props.data])

  const confirmDeletePanel = (event, id) => {
    props.handleDeletePanel(id, index)
    event.stopPropagation()
  }
  const getExtraIcon = (data, index) => {
    return (
      <div className={styles.iconGroup}>
        {resource.requirementId && <Icon className={[styles.functionIcon, disabled ? {} : styles.iconActive]} type="edit" onClick={(event) => handleEditClick(event)} />}
        <Popconfirm
          placement="right"
          title="Are you sure to delete requirement for this duration range? "
          onConfirm={(event) => confirmDeletePanel(event, resource.requirementId)}
          onCancel={(event) => { event.stopPropagation() }}
          okText="Yes"
          cancelText="No"
        >
          <Icon className={styles.functionIcon} type="close-circle" onClick={(event) => handleDeleteClick(event, index)} />
        </Popconfirm>
      </div>
    )
  }

  const handleDeleteClick = (event, key) => {
    event.stopPropagation()
  }

  const handleEditClick = (event) => {
    setDisabled(!disabled)
    const activeList = []
    if (resource.requirementId && !activeKey.includes(resource.requirementId)) {
      activeList.push(resource.requirementId)
      setActiveKey(activeList)
    }
  }

  const changeActiveKey = (e) => {
    const value = e.length ? e[e.length - 1] : ''
    const activeList = []
    if (!activeKey.includes(value)) {
      activeList.push(value)
    }
    setActiveKey(activeList)
  }

  const initSetting = { fromMonth: "", toMonth: "", frequency: "MONTHLY", minSessions: "", disabled: false }

  const panelHeader = () => {
    return (
      <Form
        labelAlign="left"
        colon={false}
        form={headerForm}
        requiredMark={false}
        initialValues={resource}
        {...headerFormLayout}
      >
        <div className={styles.formRow}>
          <Form.Item
            label={
              <div className={styles.panelInput}>
                <span className={styles.required}>*</span>
                <span className={styles.label}>Duration</span>
              </div>
            }
            validateStatus={validateInfo.status}
            help={
              <span className={styles.validateText}>
                {validateInfo.help}
              </span>
            }
          >
            <Row {...flexRow} className={styles.headerLayout}>
              <Col {...headerFlexCol} className={styles.headerFromMonth}>
                <Form.Item
                  name="fromMonth"
                  onClick={(event) => { event.stopPropagation() }}
                  rules={[{ required: true, message: 'All fields should be filled in' }]}
                  label="From"
                >
                  <InputNumber onChange={clearValidateInfo} id="fromMonth" disabled={disabled} min={1} precision={0} value={resource.fromMonth} />
                </Form.Item>
              </Col>
              <Col {...headerFlexCol} className={styles.headerToMonth}>
                <Form.Item
                  name="toMonth"
                  onClick={(event) => { event.stopPropagation() }}
                  rules={[{ required: true, message: 'All fields should be filled in' }]}
                  label="To"
                >
                  <InputNumber onChange={clearValidateInfo} id="toMonth" disabled={disabled} min={1} precision={0} value={resource.toMonth} />
                </Form.Item>
                <span className="ant-form-text-after">Month</span>
              </Col>
            </Row>
          </Form.Item>
        </div>
      </Form >
    )
  }




  const resetSetting = () => {
    headerForm.setFieldsValue(initSetting)
    panelForm.setFieldsValue(initSetting)
  }

  const confirmSetting = () => {
    headerForm.submit()
    panelForm.submit()
    onCheck()
  }

  const onCheck = () => {
    Promise.all([headerForm.validateFields(), panelForm.validateFields()]).then(res => {
      const obj = {}
      res.map(item => (Object.assign(obj, item)))
      obj.frequency = frequencyMap[obj.frequency]
      if (resource.requirementId) {
        obj.requirementId = resource.requirementId
      }
      const callback = () => {
        setDisabled(true)
      }
      // get
      const handleError = (e) => {
        setValidateInfo({
          status: 'error',
          help: e.message
        })
      }
      props.handleConfirmSetting([obj], callback, handleError)
    }).catch(e => {
      console.log(e)
    })
  }

  const clearValidateInfo = () => {
    setValidateInfo({
      status: 'success',
      help: ''
    })
  }

  return (
    <Collapse defaultActiveKey={resource.requirementId || ['0']} activeKey={activeKey} onChange={(e) => { changeActiveKey(e) }} collapsible="header">
      <Panel
        header={panelHeader()}
        extra={getExtraIcon(resource)}
        key={resource.requirementId}
      >
        <Form
          labelAlign="left"
          colon={false}
          {...formLayout}
          form={panelForm}
          initialValues={resource}
          requiredMark={false}
        >
          <div className={styles.formRow}>
            <div className={styles.contentArea}>
              <Row {...flexRow}>
                <Col {...contentFlexCol}>
                  <Form.Item
                    label={
                      <div className={styles.panelInput}>
                        <span className={styles.required}>*</span>
                        <span className={styles.label}>Frequency:</span>
                      </div>
                    }
                    name="frequency"
                    rules={[{ required: true, message: 'All fields should be filled in' },
                    ]}
                  >
                    <Radio.Group
                      disabled
                    >
                      <Radio value="MONTHLY">Monthly</Radio>
                      <Radio value="BI_WEEKLY">Bi-Weekly</Radio>
                      <Radio value="WEEKLY">Weekly</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...contentFlexCol}>
                  <Form.Item
                    label={
                      <div className={styles.panelInput}>
                        <span className={styles.required}>*</span>
                        <span className={styles.label}>Minimum Required:</span>
                      </div>
                    }
                    rules={[{ required: true, message: 'All fields should be filled in' }]}
                    name="minSessions"
                  >
                    <InputNumber
                      id="error"
                      disabled={disabled}
                      min={1}
                      precision={0}
                      value={resource.minSessions}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {!disabled ? (
              <Form.Item labelCol={0} wrapperCol={24}>
                <div className={styles.contentBtnContainer}>
                  <Button className={`${styles.btnoutColor} ${styles.btnoutText}`} onClick={resetSetting}>Reset</Button>
                  <Button type="primary" className={`${styles.btnColor} ${styles.btnText}`} onClick={confirmSetting} >Confirm</Button>
                </div>
              </Form.Item>
            ) : null}
          </div>
        </Form>
      </Panel>
    </Collapse >
  )
}

export default RequirementSettingPanel;
