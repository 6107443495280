import React from "react";
import moment from "moment";
import { Form, Input, Row, Col, DatePicker, Select } from "antd";
import hocUserDetail from '../index'
import { formLayout, formLayout2, flexCol, flexRow } from '../utils';
import { indicatorValues } from '../../constants';
import '../index.less';

const { Option } = Select;


class UserDetails extends React.Component {
  render() {
    const { form: { getFieldDecorator }, userInfo, isEditable } = this.props;

    const renderID = () => {
      switch (userInfo.roleCode) {
        // ADM Channel
        case 'PHKLADMTrainer':
        case 'PHKLADMAgentsTrainer':
        case 'PHKLADMCandidates':
        case 'PHKLADMAgents':
          return (
                <Col {...flexCol}>
                  <Form.Item label="Other ID">
                    {getFieldDecorator("otherId", {
                      initialValue: userInfo.otherId
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              )
          break;
        // PD Channel
        case 'PHKLPDTrainer':
        case 'PHKLPDAgents':
        case 'PHKLPDCandidates':
          return (
                <Col {...flexCol}>
                  <Form.Item label="Staff ID">
                    {getFieldDecorator("staffId", {
                      rules: [
                        {
                          pattern: /^(SCB\d+)$/,
                          message: "Staff ID format is invalid."
                        }
                      ],
                      initialValue: userInfo.staffId
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              )
          break;
        default:
            return (
              <Col {...flexCol}>
                <Form.Item label="HKID">
                  {getFieldDecorator("hkid", {
                    rules: [
                      {
                        pattern: /^([A-Z]\d{6}[A-Z0-9])$/,
                        message: "HKID format is invalid."
                      }
                    ],
                    initialValue: userInfo.hkid
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
            )
          break;
      }
    }


    return (
      <div className="container">
        <Form
          className="ant_form"
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("role", {
                  initialValue: userInfo.roleName
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agenct Code">
                {getFieldDecorator("agentCode", {
                  rules: [
                    {
                      pattern: /^(\d{8})$/,
                      message: "Agent code is not 8 digits."
                    }
                  ],
                  initialValue: userInfo.agentCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            {
              renderID()
            }
            <Col {...flexCol}>
              <Form.Item label="First Name">
                {getFieldDecorator("firstName", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.firstName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Last Name">
                {getFieldDecorator("lastName", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.lastName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Email">
                {getFieldDecorator("email", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      type: "email",
                      message: "Please input the correct email format"
                    }
                  ],
                  initialValue: userInfo.email
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Mobile">
                {getFieldDecorator("mobile", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^[0-9]{0,}$/,
                      message: "Only numbers are allowed"
                    }
                  ],
                  initialValue: userInfo.mobile
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Login Account">
                {getFieldDecorator("loginAccount", {
                  initialValue: userInfo.loginAccount
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Division">
                {getFieldDecorator("division", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^([D]\d{4})$/,
                      message: "Division is not 'D' + 4 digit number. "
                    }
                  ],
                  initialValue: userInfo.division
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Division Manager Agent Code" {...formLayout2}>
                {getFieldDecorator("divisionManagerAgentCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^(\d{8})$/,
                      message: "Division Manager Agent Code is not 8 digits. "
                    }
                  ],
                  initialValue: userInfo.divisionManagerAgentCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Designation Code">
                {getFieldDecorator("designationCode", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.designationCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agency">
                {getFieldDecorator("agencyCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters",
                    },
                    {
                      pattern: /^([A]\d{4})$/,
                      message: "Agency Code is not 'A' + 4 digit number."
                    }
                  ],
                  initialValue: userInfo.agencyCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agency Name">
                {getFieldDecorator("agencyName", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.agencyName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Title">
                {getFieldDecorator("title", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.title
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Branch Code">
                {getFieldDecorator("branchCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^([B]\d{4})$/,
                      message: "Branch Code is not 'B' + 4 digit number. "
                    }
                  ],
                  initialValue: userInfo.branchCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Unit Code">
                {getFieldDecorator("unitCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^([U]\d{4})$/,
                      message: "Unit Code is not 'U' + 4 digit number."
                    }
                  ],
                  initialValue: userInfo.unitCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Zone">
                {getFieldDecorator("zone", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.zone
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Branch Name">
                {getFieldDecorator("branchName", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.branchName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Recruiter Code">
                {getFieldDecorator("recruiterCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.recruiterCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Direct Upline Code">
                {getFieldDecorator("directUplineCode", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    },
                    {
                      pattern: /^(\d{8})$/,
                      message: "Direct Upline Code is not 8 digits. "
                    }
                  ],
                  initialValue: userInfo.directUplineCode
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="First Join Co Date">
                {getFieldDecorator("firstJoinCoDate", {
                  initialValue: userInfo.firstJoinCoDate
                    ? moment(userInfo.firstJoinCoDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Chinese Full Name">
                {getFieldDecorator("chineseFullName", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.chineseFullName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="First LU Date">
                {getFieldDecorator("firstLUDate", {
                  initialValue: userInfo.firstJoinCoDate
                    ? moment(userInfo.firstLUDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Direct Upline Name">
                {getFieldDecorator("directUplineName", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.directUplineName
                })(<Input disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Agent Status">
                {getFieldDecorator("agentStatus", {
                  initialValue: userInfo.agentStatus
                })(<Input disabled />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Termination Date">
                {getFieldDecorator("terminationDate", {
                  initialValue: userInfo.terminationDate
                    ? moment(userInfo.terminationDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="IA License Indicator">
                {getFieldDecorator("ialicenseIndicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.ialicenseIndicator
                })(
                  <Select disabled={!isEditable}>
                    {indicatorValues.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="IA License Date">
                {getFieldDecorator("ialicenseDate", {
                  initialValue: userInfo.ialicenseDate
                    ? moment(userInfo.ialicenseDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="MPF License Indicator">
                {getFieldDecorator("mpflicenseIndicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.mpflicenseIndicator
                })(
                  <Select disabled={!isEditable}>
                    {indicatorValues.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="MPF Date">
                {getFieldDecorator("mpfdate", {
                  initialValue: userInfo.mpfdate
                    ? moment(userInfo.mpfdate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="SFC License Indicator">
                {getFieldDecorator("sfclicenseIndicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.sfclicenseIndicator
                })(
                  <Select disabled={!isEditable}>
                    {indicatorValues.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="SFC Date">
                {getFieldDecorator("sfcdate", {
                  initialValue: userInfo.sfcdate
                    ? moment(userInfo.sfcdate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Activation Date">
                {getFieldDecorator("activationDate", {
                  initialValue: userInfo.activationDate
                    ? moment(userInfo.activationDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Candidate Application Received Date" {...formLayout2}>
                {getFieldDecorator("candidateApplicationReceivedDate", {
                  rules: [{
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }],
                  initialValue: userInfo.candidateApplicationReceivedDate
                    ? moment(userInfo.candidateApplicationReceivedDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Candidate Return Date">
                {getFieldDecorator("candidateReturnDate", {
                  initialValue: userInfo.candidateReturnDate
                    ? moment(userInfo.candidateReturnDate, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled={!isEditable} />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Rejoin Indicator">
                {getFieldDecorator("rejoinIndicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.rejoinIndicator
                })(<Input disabled />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Channel">
                {getFieldDecorator("channel", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.channel
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="GI Indicator">
                {getFieldDecorator("giindicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.giindicator
                })(
                  <Select disabled={!isEditable}>
                    {indicatorValues.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="MPF Indicator">
                {getFieldDecorator("mpfindicator", {
                  rules: [
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ],
                  initialValue: userInfo.mpfindicator
                })(
                  <Select disabled={!isEditable}>
                    {indicatorValues.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.value}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Creation Date">
                {getFieldDecorator("createTime", {
                  initialValue: userInfo.createTime
                    ? moment(userInfo.createTime, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Created By">
                {getFieldDecorator("createdByName", {
                  initialValue: userInfo.createdByName
                })(<Input disabled />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Last Update Date">
                {getFieldDecorator("createTime", {
                  initialValue: userInfo.updateTime
                    ? moment(userInfo.updateTime, "YYYY-MM-DD")
                    : null
                })(<DatePicker disabled />)}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Last Updated By">
                {getFieldDecorator("updateByName", {
                  initialValue: userInfo.updateByName
                })(<Input disabled />)}
              </Form.Item>
            </Col>

          </Row>
        </Form>
      </div>
    );
  }
}

const UserDetailsWithForm = Form.create()(UserDetails);
const UserDetailHOC = hocUserDetail(UserDetailsWithForm, {
  fetchUrl: '/user/phklUserDetail',
  saveUrl: '/candidate/updateCandidate',
});

export default UserDetailHOC;
