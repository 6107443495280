import React, { Component } from "react";
import { Form, Col, DatePicker } from "antd";
import moment from "moment";
import '../index.less';
import { switchDate } from "../../../../../utils/global";

export const flexCol = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

export default class DatePickers extends Component {
  render() {
    const { getFieldDecorator, label, startDate, endDate, handleSaveField, searchData, disabledDate } = this.props;
    const startDateValue = searchData[startDate.name];
    const endDateValue = searchData[endDate.name];

    const hasSaveFiled = Object.keys(searchData).length > 0;
    const startDataSaveValue = startDateValue && moment(startDateValue, "YYYY-MM-DD");
    const endDataSaveValue = endDateValue && moment(endDateValue, "YYYY-MM-DD");
    const defaultStartDate = (startDate.initialValue ? startDate.initialValue : null);
    const defaultEndDate = (endDate.initialValue ? endDate.initialValue : null);
    const disabledDateFn = (current) => {
      return switchDate() && current < moment(disabledDate || '');
    }
    return (
      <Col {...flexCol}>
        <div className="datebox">
          <Form.Item label={label} className="startDate">
            {getFieldDecorator(`${startDate.name}`, {
              initialValue: hasSaveFiled ? startDataSaveValue : defaultStartDate
            })(
              <DatePicker
                onChange={(date, dateString) => handleSaveField(startDate.name, dateString)}
                disabledDate={disabledDateFn}
              />
            )}
          </Form.Item>
          <span className="toSpan">to</span>
          <Form.Item labelCol={{ span: 10 }} wrapperCol={{ span: 14 }}>
            {getFieldDecorator(`${endDate.name}`, {
              initialValue: hasSaveFiled ? endDataSaveValue : defaultEndDate
            })(
              <DatePicker
                onChange={(date, dateString) => handleSaveField(endDate.name, dateString)}
                disabledDate={disabledDateFn}
              />
            )
            }
          </Form.Item>
        </div>
      </Col>
    );
  }
}
