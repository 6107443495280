import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Button,
  Spin,
  Tooltip,
  Table,
  Icon
} from "antd";
// import moment from "moment";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import {
  getSurveyDetailReport,
  downloadSurveyDetailReport
} from "../../../../redux/plukReducer/CPDReportReducers/CourseRatingSummaryReportPLUKReducer";
// import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({})(props => {
  const { loading } = props;
  return (
    <Spin spinning={loading}>
      <Button
        className={styles.btn}
        style={{ display: props.searchShortList.length ? "block" : "none", marginBottom: "10px" }}
        type="danger"
        icon="download"
        onClick={() => {
          props.downloadSurveyDetailReport();
        }}
      >
        Download to Excel
      </Button>
      <div>
        <Table
          bordered
          scroll={{ x: 2300 + ((props.sQIndex + 1) * 250) }}
          className={styles.ant_dev_table}
          columns={props.sDynamicColumns(props)}
          onChange={pagination => {
            props.sHandleTableChange(pagination);
          }}
          pagination={{
            total: props.sTotal,
            pageSize: props.sPageSize,
            current: props.sCurrentPageNumber
          }}
          dataSource={props.searchShortList}
          size="small"
          rowKey="sessionId"
        />
      </div>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { CPDReportReducers: { CourseRatingSummaryReportPLUKReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: CourseRatingSummaryReportPLUKReducer,
    loading
  }),
  {
    changeVals,
    getSurveyDetailReport,
    downloadSurveyDetailReport
  }
)
class CourseRatingSummaryReportDetailPLUK extends Component {
  constructor(props) {
    super(props);
    this.sHandleTableChange = this.sHandleTableChange.bind(this);
    this.sDynamicColumns = this.sDynamicColumns.bind(this);
    this.downloadSurveyDetailReport = this.downloadSurveyDetailReport.bind(this);
    this.state = {
      showColumns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          width: 100,
          fixed: "left",
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Venue",
          dataIndex: "venue",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Start Date",
          dataIndex: "startDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session End Date",
          dataIndex: "endDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Trainer",
          dataIndex: "trainer",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC code",
          dataIndex: "loginAccount",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent Name",
          dataIndex: "agentName",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Over All Rating",
          dataIndex: "overAllRating",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    const { sessionId } = this.props.location.state;
    this.props.changeVals("CourseRatingSummaryReportPLUK", {
      sessionId,
      searchShortList: [],
      sTotalPage: 0,
      sTotal: 0,
      sPageSize: 10,
      sCurrentPageNumber: 1,
      sQIndex: 0,
    });
    this.props.getSurveyDetailReport({
      sessionId,
      pageSize: 10,
      currentPageNumber: 1
    })
  }

  downloadSurveyDetailReport() {
    const { downloadSurveyDetailReport } = this.props;
    const { sessionId } = this.props.location.state;
    downloadSurveyDetailReport({
      sessionId,
    });
  }

  sHandleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { sessionId } = this.props.location.state;
    this.props.getSurveyDetailReport({
      sessionId,
      pageSize,
      currentPageNumber: current
    });
  };

  sDynamicColumns() {
    const { searchShortList } = this.props.fields;
    const { showColumns } = this.state;
    const finalColumns = [...showColumns];
    /* eslint-disable */
    if (searchShortList.length) {
      searchShortList.forEach((item) => {
        item.questionList.forEach((_item) => {
          finalColumns.push(
            {
              title: _item.showName,
              dataIndex: `${_item.questionName}_answer`,
              ellipsis: true,
              align: "center",
              width: 250,
              render: text => (
                <Tooltip placement="top" title={text}>
                  {text}
                </Tooltip>
              )
            }
          );
        });
      });
      console.log("sFinalColumns:", finalColumns);
    }
    /* eslint-disable */
    return finalColumns;
  }

  // getShowTable(sessionId = "") {
  //   this.setState(() => ({
  //     showTable: !this.state.showTable,
  //     sessionId
  //   }), () => {
  //     if (this.state.showTable) {
  //       this.props.getSurveyDetailReport({
  //         sessionId,
  //         pageSize: 10,
  //         currentPageNumber: 1
  //       });
  //     } else {
  //       this.props.changeVals("CourseRatingSummaryReportPACS", {
  //         searchShortList: [],
  //         sTotalPage: 0,
  //         sTotal: 0,
  //         sPageSize: 10,
  //         sCurrentPageNumber: 1,
  //         sQIndex: 0,
  //       })
  //     }
  //   });
  // }

  render() {
    const props = {
      loading: this.props.loading,
      currentPageNumber: this.state.currentPageNumber,
      sHandleTableChange: this.sHandleTableChange,
      sDynamicColumns: this.sDynamicColumns,
      downloadSurveyDetailReport: this.downloadSurveyDetailReport
    };
    // const { showTable, sPageSize } = this.state;
    // const { fields } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              // this.props.history.goBack();
              this.props.history.push("/home/cpd_reports/cpd_reports_courseratingsummaryReport_pacs", {
                detail: true
              });
            }}
          />
          Course Rating Detail Report
        </div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
        {/* <div className={styles.showTable} style={{ display: showTable && fields.searchShortList.length ? "block" : "none" }}>
          <div style={{ marginBottom: "20px", width: "100%", display: "flex", justifyContent: "space-between", color: "#ed1b2e", alignItems: "center" }}>
            <Button
              className={styles.btn}
              type="danger"
              icon="download"
              onClick={() => {
                this.downloadSurveyDetailReport();
              }}
            >
              Download to Excel
          </Button>
            <Icon
              type="close-circle"
              style={{ marginRight: 15, cursor: "pointer", fontSize: "25px" }}
              onClick={this.getShowTable}
            />
          </div>
          <div
            style={{ marginTop: "20px" }}
          >
            <Table
              bordered
              scroll={{ x: 3000 + ((fields.sQIndex + 1) * 250) }}
              className={styles.ant_dev_table}
              columns={this.sDynamicColumns()}
              onChange={pagination => {
                this.sHandleTableChange(pagination);
              }}
              pagination={{
                total: fields.sTotal,
                pageSize: sPageSize,
                current: fields.sCurrentPageNumber
              }}
              dataSource={fields.searchShortList}
              size="small"
              rowKey="userId"
            />
          </div>
        </div> */}
      </div>
    );
  }
}

export default CourseRatingSummaryReportDetailPLUK;
