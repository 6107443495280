import moment from 'moment';
import { SelectList, SearchInput, Datepickers } from '../../../../components/CustomList/SearchBar/components';

export const getSearchFileds = (rolerList) => {
  return [
    {
      key: 'Role',
      component: SelectList,
      props: {
        label: 'Role',
        name: 'roleId',
        options: () => {
          const items = [];
          rolerList.forEach(item => {
            items.push({
              value: item.roleid,
              label: item.name
            });
          });

          return items;
        }
      }
    },
    {
      key: 'agentCode',
      component: SearchInput,
      props: {
        label: 'Agent Code',
        name: 'agentCode'
      }
    },
    {
      key: 'loginAccount',
      component: SearchInput,
      props: {
        label: 'login Account',
        name: 'loginAccount'
      }
    },
    {
      key: 'courseName',
      component: SearchInput,
      props: {
        label: 'Course Name',
        name: 'courseName'
      }
    },
    {
      key: 'CourseStatus',
      component: SelectList,
      props: {
        label: 'Course Status',
        name: 'courseStatus',
        options: [{
          value: '',
          label: 'All'
        },
        {
          value: '0',
          label: 'InComplete'
        }, {
          value: '1',
          label: 'Complete'
        }
        ]
      }
    },
    {
      key: 'sequence',
      component: SearchInput,
      props: {
        label: 'Sequence',
        name: 'sequence'
      }
    },
    {
      key: 'courseCompleteDate',
      component: Datepickers,
      props: {
        label: 'Course Complete Date',
        name: 'courseCompleteDate',
        startDate: {
          name: 'startDate',
          initialValue: moment().startOf('year'),
          config: {
            placeholder: "Start Date",
          }
        },
        endDate: {
          name: 'endDate',
          initialValue: moment(),
          config: {
            placeholder: "End Date",
          }
        },
        labelCol: { span: 3 },
        wrapperCol: { span: 8 },

      }
    }
  ]
};
