import React from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Icon,
  Select,
  Popconfirm,
  Rate,
  Spin
} from "antd";
import { connect } from "react-redux";

import { upArrowRed, downArrowRed, deleteArrowRed } from "../../../../../images";
import styles from "./QuestionrGroupCreate.module.less";
import {
  changePosition,
  deleteQuestion,
  deleteAnswer,
  addQuestion,
  changeItemContent,
  addAnswer,
  createGroup,
  reSetGroup
} from "../../../../../redux/pacsReducer/systemSettingsReducers/QuestionGroupReducer";
import { numberToLetter } from "../../../../../utils/global";

const { Option } = Select;
const { TextArea } = Input;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const formLayout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const formLayout3 = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol2 = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pacsReducer: { systemSettingsReducers: { QuestionGroupReducer } } }) => ({
    QuestionGroupReducer
  }),
  {
    changePosition,
    deleteQuestion,
    deleteAnswer,
    addQuestion,
    changeItemContent,
    addAnswer,
    createGroup,
    reSetGroup
  }
)
class QuestionrGroupCreate extends React.Component {
  componentWillUnmount() {
    this.props.reSetGroup();
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          QuestionGroupReducer: { questionList }
        } = this.props;
        questionList.map((item, index) => {
          item.sort = `${index}`;
          if (item.answerList && item.answerList.length) {
            item.answerList.map((elt, i) => {
              elt.sort = `${i}`;
              if (item.questionType === "2" || item.questionType === "3") {
                elt.answerValue = numberToLetter[i];
              }
            });
          }
        });
        const callback = () => {
          this.props.history.push("/home/system_settings/question_group/index");
        };
        const { groupName, groupDescription, groupType } = values;
        this.props.createGroup(
          {
            groupName,
            groupType,
            groupDescription,
            questionList
          },
          callback
        );
      }
    });
  };

  handleUpAndDown = (index, type) => {
    const {
      QuestionGroupReducer: { questionList }
    } = this.props;
    const upGo = index !== 0 && type === "up";
    const downGo = index !== questionList.length - 1 && type === "down";
    if (upGo || downGo) {
      this.props.changePosition(index, type);
    }
  };

  handleDeleteQuestion = index => {
    this.props.deleteQuestion(index);
  };

  handleDeleteAnswer = (index, answerIndex) => {
    this.props.deleteAnswer(index, answerIndex);
  };

  handleChange = ({ type, index, title, answerIndex, answerName }) => {
    this.props.changeItemContent({
      type,
      index,
      title,
      answerIndex,
      answerName
    });
  };

  handleAddQuestion = () => {
    this.props.addQuestion();
  };

  handleAddAnswer = index => {
    this.props.addAnswer(index);
  };

  render() {
    const {
      form: { getFieldDecorator },
      QuestionGroupReducer: {
        questionList,
        typeList,
        submitLoading,
        trainerList
      }
    } = this.props;
    return (
      <div className={styles.container}>
        <Spin spinning={submitLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/question_group/index"
                )
              }
            />
            Create Group
          </div>
          <Form labelAlign="left" colon={false} {...formLayout}>
            <div className={styles.ant_form}>
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Group Name">
                    {getFieldDecorator("groupName", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 50,
                          message: "fields cannot be longer than 50 characters"
                        }
                      ]
                    })(<Input />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Is Trainer" {...formLayout3}>
                    {getFieldDecorator("groupType", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 50,
                          message: "fields cannot be longer than 50 characters"
                        }
                      ],
                      initialValue: (trainerList && trainerList.length) ? trainerList[0].value : ''
                    })(
                      <Select>
                        {trainerList.map(elt => (
                          <Option value={elt.value} key={elt.value}>
                            {elt.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...flexCol2}>
                  <Form.Item label="Group Description" {...formLayout2}>
                    {getFieldDecorator("groupDescription", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          max: 200,
                          message: "fields cannot be longer than 200 characters"
                        }
                      ]
                    })(<TextArea />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div className={styles.addBox}>
              <Button
                className={styles.ant_btn}
                icon="plus"
                onClick={this.handleAddQuestion}
              >
                Add Question
              </Button>
            </div>
            {questionList.length ? (
              <div className={styles.groupBox}>
                <div className={styles.contentBox}>
                  {questionList.map(
                    ({ questionType, answerList, questionIndex }, ind) => {
                      return (
                        <div className={styles.groupItem} key={questionIndex}>
                          <div className={styles.indexBox}>{`${ind + 1}.`}</div>
                          <div className={styles.content}>
                            <div className={styles.questionTop}>
                              <Form.Item>
                                {getFieldDecorator(`type${questionIndex}`, {
                                  initialValue: questionType
                                })(
                                  <Select
                                    className={styles.typeBox}
                                    onChange={val =>
                                      this.handleChange({
                                        type: val,
                                        index: ind
                                      })
                                    }
                                  >
                                    {typeList.map(elt => (
                                      <Option value={elt.value} key={elt.value}>
                                        {elt.code}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Form.Item>
                              <div className={styles.groupAction}>
                                {ind === 0 ||
                                  questionList.length <= 1 ? null : (
                                    <img
                                      src={upArrowRed}
                                      className={styles.actionBtn}
                                      onClick={() =>
                                        this.handleUpAndDown(ind, "up")
                                      }
                                      alt=""
                                    />
                                  )}
                                {ind === questionList.length - 1 ||
                                  questionList.length <= 1 ? null : (
                                    <img
                                      src={downArrowRed}
                                      className={styles.actionBtn}
                                      onClick={() =>
                                        this.handleUpAndDown(ind, "down")
                                      }
                                      alt=""
                                    />
                                  )}

                                <Popconfirm
                                  title="Please double confirm?"
                                  onConfirm={() =>
                                    this.handleDeleteQuestion(ind)
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <img
                                    src={deleteArrowRed}
                                    className={styles.actionBtn}
                                    alt=""
                                  />
                                </Popconfirm>
                              </div>
                            </div>
                            <div className={styles.bottomBox}>
                              <Form.Item>
                                {getFieldDecorator(`r${questionIndex}`, {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please fill in the mandatory fields"
                                    }
                                  ]
                                })(
                                  <TextArea
                                    rows={4}
                                    onChange={e =>
                                      this.handleChange({
                                        type: questionType,
                                        title: e.target.value,
                                        index: ind
                                      })
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                            {(questionType === "0" || questionType === '4' || questionType === '5' || questionType === '6') ? <Rate disabled /> : null}
                            {questionType === "1" ? (
                              <TextArea disabled />
                            ) : null}
                            {questionType === "2" ? (
                              <div className={styles.answerBox}>
                                {answerList
                                  ? answerList.map((item, i) => {
                                    return (
                                      <div
                                        className={styles.answerItem}
                                        key={item.answerIndex}
                                      >
                                        <span className={styles.sortBox}>
                                          {`${numberToLetter[i]}.`}
                                        </span>
                                        <Form.Item
                                          className={styles.answerInputBox}
                                        >
                                          {getFieldDecorator(
                                            item.answerIndex,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill in the mandatory fields"
                                                }
                                              ]
                                            }
                                          )(
                                            <Input
                                              className={styles.answerInput}
                                              onChange={e =>
                                                this.handleChange({
                                                  type: questionType,
                                                  index: ind,
                                                  answerIndex: i,
                                                  answerName: e.target.value
                                                })
                                              }
                                            />
                                          )}
                                        </Form.Item>
                                        {answerList.length > 1 ? (
                                          <Popconfirm
                                            title="Please double confirm?"
                                            onConfirm={() =>
                                              this.handleDeleteAnswer(ind, i)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              icon="close-circle"
                                              className={styles.addAnswer}
                                            />
                                          </Popconfirm>
                                        ) : null}
                                      </div>
                                    );
                                  })
                                  : null}
                                <Button
                                  icon="plus-circle"
                                  className={styles.addAnswer}
                                  onClick={() => this.handleAddAnswer(ind)}
                                >
                                  Add Answer
                                </Button>
                              </div>
                            ) : null}
                            {questionType === "3" ? (
                              <div className={styles.answerBox}>
                                {answerList
                                  ? answerList.map((item, i) => {
                                    return (
                                      <div
                                        className={styles.answerItem}
                                        key={item.answerIndex}
                                      >
                                        <span className={styles.sortBox}>
                                          {`${numberToLetter[i]}.`}
                                        </span>
                                        <Form.Item
                                          className={styles.answerInputBox}
                                        >
                                          {getFieldDecorator(
                                            item.answerIndex,
                                            {
                                              rules: [
                                                {
                                                  required: true,
                                                  message:
                                                    "Please fill in the mandatory fields"
                                                }
                                              ]
                                            }
                                          )(
                                            <Input
                                              className={styles.answerInput}
                                              onChange={e =>
                                                this.handleChange({
                                                  type: questionType,
                                                  index: ind,
                                                  answerIndex: i,
                                                  answerName: e.target.value
                                                })
                                              }
                                            />
                                          )}
                                        </Form.Item>
                                        {answerList.length > 1 ? (
                                          <Popconfirm
                                            title="Please double confirm?"
                                            onConfirm={() =>
                                              this.handleDeleteAnswer(ind, i)
                                            }
                                            okText="Yes"
                                            cancelText="No"
                                          >
                                            <Button
                                              icon="close-circle"
                                              className={styles.addAnswer}
                                            />
                                          </Popconfirm>
                                        ) : null}
                                      </div>
                                    );
                                  })
                                  : null}
                                <Button
                                  icon="plus-circle"
                                  className={styles.addAnswer}
                                  onClick={() => this.handleAddAnswer(ind)}
                                >
                                  Add Answer
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className={styles.btnBox}>
                  <Button
                    className={`${styles.ant_btn} ${styles.ant_btn_save}`}
                    onClick={this.handleSubmit}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : null}
          </Form>
        </Spin>
      </div>
    );
  }
}

const QuestionrGroupCreateForm = Form.create()(QuestionrGroupCreate);

export default QuestionrGroupCreateForm;
