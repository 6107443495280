import React from "react";
import { Form, Input, Button } from "antd";
import styles from "../Login.module.less";
import { bg2, psd } from "../../../images/index";

const MobileSetPsd = Form.create({
  name: "CreateSetPsd_Form",
  onFieldsChange(props, changedFields) {
    props.changeVals("setPsd", changedFields);
  },
  mapPropsToFields(props) {
    return {
      repassword: Form.createFormField({
        ...props.repassword,
        value: props.repassword.value
      }),
      oldPassword: Form.createFormField({
        ...props.oldPassword,
        value: props.oldPassword.value
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password.value
      })
    };
  },
  onValuesChange() { }
})(
  props => {
    const { getFieldDecorator } = props.form;
    const type = {
      init: {
        title: "initialize",
        btn: "Done"
      },
      reset: {
        title: "reset",
        btn: "Reset"
      }
    };
    const now = props.psdType;
    const { errMsg, changePsd, expired } = props;
    return (
      <div className={styles.mformBox}>
        <div className={styles.mBGBox} style={setBg(bg2, "contain", "top")}></div>
        <span style={insideStyles.mtitle1}>
          Welcome to Regional Training Management System
        </span>
        <span style={insideStyles.title2}>
          Please {type[now].title} your password
        </span>
        <div style={insideStyles.title4}>
          Password must contain: 1 upper case, 1 lower case, 1 number, 1 special
          character, 8-15 characters
        </div>
        <Form className={styles.mformWrap}>
          {changePsd ? (
            <Form.Item
              style={{ marginBottom: "26px" }}
              validateStatus={errMsg.type === "3" ? "error" : null}
            >
              {getFieldDecorator(
                "oldPassword",
                {}
              )(
                <Input.Password
                  style={{ height: "42px" }}
                  prefix={<div className={styles.icon} style={setBg(psd)} />}
                  placeholder="Old Password"
                />
              )}
            </Form.Item>
          ) : null}
          <Form.Item
            style={{ marginBottom: "26px" }}
            validateStatus={errMsg.type === "1" ? "error" : null}
          >
            {getFieldDecorator(
              "password",
              {}
            )(
              <Input.Password
                disabled={expired}
                style={{ height: "42px" }}
                prefix={<div className={styles.icon} style={setBg(psd)} />}
                placeholder="New Password"
              />
            )}
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "5px" }}
            validateStatus={errMsg.type === "2" ? "error" : null}
          >
            {getFieldDecorator(
              "repassword",
              {}
            )(
              <Input.Password
                disabled={expired}
                style={{ height: "42px" }}
                prefix={<div className={styles.icon} style={setBg(psd)} />}
                placeholder="Confirm Password"
              />
            )}
          </Form.Item>
          <Form.Item>
            <div style={insideStyles.errMsg}>{errMsg.title}</div>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={expired}
              style={{ marginTop: "20px" }}
              type="danger"
              htmlType="submit"
              className={styles.btn}
              onClick={() => {
                props.handleSubmit(props.form, "forget_password");
              }}
            >
              {type[now].btn}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
);

const setBg = (url, size = "contain", x = "center") => ({
  background: `url(${url}) ${x} center no-repeat`,
  backgroundSize: size
});

const insideStyles = {
  mtitle1: {
    textAlign: "center",
    width: "90%",
    fontFamily: "ArialM",
    fontSize: "16px",
    color: "#666666",
    letterSpacing: 0,
    marginBottom: "25px",
    marginTop: "20px",
  },
  title1: {
    fontFamily: "Arial-BoldMT",
    fontSize: "16px",
    color: "#666666",
    letterSpacing: 0,
    marginTop: "-10px",
    marginBottom: "20px"
  },
  title2: {
    width: "90%",
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#666666",
    letterSpacing: 0,
    textAlign: "center",
    marginBottom: "15px"
  },
  errMsg: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#ED1B2E",
    letterSpacing: 0,
    marginBottom: "15px",
    padding: "0",
    width: "100%"
  },
  title4: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#666666",
    letterSpacing: 0,
    marginBottom: "15px",
    padding: "0 20px",
    width: "100%",
    textAlign: "center"
  },
  title3: {
    width: "90%",
    fontFamily: "ArialMT",
    fontSize: "12px",
    color: "#FF0000",
    letterSpacing: 0,
    textAlign: "center",
    marginTop: "10px",
    lineHeight: "15px"
  }
};

export default MobileSetPsd;
