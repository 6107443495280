/* eslint-disable */
import React from "react";
import { Route, Redirect, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { urlIntoArray, handleSplitUrl, onlyClearLocalData } from "../utils";
import { globalUrlPre } from "../utils/config";
import { loginUrlAdmin, loginUrlPamb, loginUrlPacs, loginUrlPhkl, buServiceList, loginUrlPluk, loginUrlPcla } from "../utils/global";

@connect(({ homeReducer }) => ({ homeReducer }))
class RouteView extends React.Component {
  render() {
    const {
      routers,
      defaultConfig,
      location: { pathname },
      homeReducer: { menuButtonList, buttonList, homeMenuList, myProfileList }
    } = this.props;
    const routerDate = routers || defaultConfig;
    // Data secondary processing
    if (!routerDate) return null;
    // login or not
    const authed = sessionStorage.getItem("user");
    const hasDevPrefix = sessionStorage.getItem("prefix");

    const [x, y] = handleSplitUrl(this.props);
    const hasPrefix = buServiceList.includes(y);
    const currentLoginPage = `/${x}/${y}/login`;
    const isLoginFail = pathname.includes('401');

    if (!authed) {
      if (hasDevPrefix) {
        if (hasPrefix && x === globalUrlPre) {
          if (hasDevPrefix !== y) {
            sessionStorage.setItem("prefix", y);
            return <Redirect to={currentLoginPage} />;
          } else {
            if ((pathname === loginUrlAdmin) || (pathname === loginUrlPamb) || (pathname === loginUrlPacs) || (pathname === loginUrlPhkl) || (pathname === loginUrlPluk) || (pathname === loginUrlPcla)) {
              return <Redirect to={currentLoginPage} />;
            }
          }
        } else if (pathname === '/') {
          return <Redirect to={loginUrlAdmin} />;
        }
      } else {
        if (hasPrefix && x === globalUrlPre) {
          sessionStorage.setItem("prefix", y);
          return <Redirect to={currentLoginPage} />;
        } else if (pathname === '/') {
          return <Redirect to={loginUrlAdmin} />;
        }
      }
    } else if ((pathname === loginUrlAdmin) || (pathname === loginUrlPamb) || (pathname === loginUrlPacs) || (pathname === loginUrlPhkl) || (pathname === loginUrlPluk) || (pathname === loginUrlPcla)) { // update by zhy
      return <Redirect to='/home' />;
    }

    // Permission or not
    const hasDevpath = routerDate.find(elt => elt.path === pathname);
    if (hasDevpath) {
      const {
        redirctPath,
        initialRoute,
        customDevPath,
        hasSecondRoute
      } = hasDevpath;
      // the page of the first tab is not fixed due to permission, display the page of the first tab
      let { length } = homeMenuList;

      const hasMyProfileLink = myProfileList.length > 0;

      if (pathname === "/home" && (length || hasMyProfileLink)) {

        if (hasMyProfileLink && !length) {
          const myProfileUrl = myProfileList[0].path;
         return <Redirect to={myProfileUrl} />
        }

        if (length && !hasMyProfileLink) {
          const initpath = homeMenuList[0].path;
          return <Redirect to={initpath} />;
        }

        if (length) {
          const initpath = homeMenuList[0].path;
          return <Redirect to={initpath} />;
        }

      }

      const hasAuthority = handlePermission({
        pathname,
        menuButtonList,
        buttonList,
        myProfileList
      });

      if (!hasAuthority && !customDevPath && !redirctPath && !initialRoute) {
        return <Redirect to="/home/403" />;
      }
      // This judgment is the highest permission. When the back end does not have the permission to return to the page, the front end forces the jump. Only for front-end debugging, do not open it easily
      // if (hasAuthority || customDevPath) {
      if (hasAuthority) {
        if (hasSecondRoute) {
          const menuItem = homeMenuList.find(elt => elt.path === pathname);
          const redircts =
            menuItem.children && menuItem.children[0]
              ? menuItem.children[0].path
              : "/home/403";
          return <Redirect to={redircts} />;
        } else if (redirctPath) {
          return <Redirect to={redirctPath} />;
        }
      } else if (!initialRoute) {
        const pathArr = urlIntoArray(pathname);
        // In order to deal with the situation with 'redirctpath' and prevent direct access to 'redirctpath', we need to first determine whether it has the permission of the upper level route
        const parentRoute = pathArr[pathArr.length - 2];
        if (parentRoute === "/home") {
        } else {
          const hasParentAuthority = handlePermission({
            pathname: parentRoute,
            menuButtonList,
            buttonList,
            myProfileList
          });
          if (!hasParentAuthority) {
            return <Redirect to="/home/403" />;
          }
        }
      }
    } else if (pathname !== "/") {
      // route or not
      const pathArray = urlIntoArray(pathname);
      const haspath = routerDate.some(elt => pathArray.includes(elt.path));
      if (!haspath && pathname !== "/home") {
        return <Redirect to="/home/404" />;
      }
    }
    // Screen out those with redirect
    const routerDatepath = routerDate.filter(item => !item.redirect);

    // Filter redirect
    // const defualtRouter = routerDate.filter(item => item.redirect);

    // const defualtRedirect = defualtRouter.map(item => (
    //   <Redirect key={item.path} path={item.path} to={item.redirect} />
    // ));



    return (
      <Switch>
        {routerDatepath &&
          routerDatepath
            .map(item => {
              const Component = item.component;
              return authed ? <Route
                  path={item.path}
                  render={props => (
                    // document.title = item.title || "Background management system"
                    <Component routers={item.children} {...props} />
                  )}
                  key={item.id + item.path}
                /> :
                (
                  isLoginFail ? <Redirect to='/401' /> : <Redirect to={loginUrlAdmin} />
                )
            })
        }
      </Switch>
    );
  }
}

export default withRouter(RouteView);

function handlePermission({
  pathname,
  menuButtonList,
  buttonList,
  myProfileList
}) {
  const hasPermission = menuButtonList.some(elt => elt.path === pathname);

  const hasButtonPermission =
    !hasPermission && buttonList.some(elt => elt.path === pathname);

  const hasMyprofilePermission =
    !hasPermission && myProfileList.some(elt => elt.path === pathname);

  return hasPermission || hasButtonPermission || hasMyprofilePermission;
}
