import { message } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pamb/ImportAgentReducer/SAVE";

const initState = {
  uploadBackData: {},
  checkLoading: false,
  importLoading: false,
  isShowTable: false
};

// reducer
export default function ImportAgentReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleUploadAndCheck(payload, buRole, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        checkLoading: true
      }
    });
    post("/agent/checktAgent", payload, { buRole })
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false,
            uploadBackData: data || {}
          }
        });

        callback();
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false
          }
        });
      });
  };
}

export function handleImport(payload, buRole, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        importLoading: true
      }
    });
    get("/agent/importAgent", payload, { buRole })
      .then(() => {
        const resetData = () => {
          callback();
          dispatch({
            type: SAVE,
            payload: {
              importLoading: false,
              uploadBackData: {}
            }
          });
        };
        message.info("Import successfully", globalPromptTime, resetData);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            importLoading: false
          }
        });
      });
  };
}

export function uploadAndCheckForPambTemp(payload, callback) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        checkLoading: true
      }
    });
    post("/agent/convertToStanderd", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false
          }
        });
        const fileName = "import_agent_template.xls";
        downloadFile(res, fileName);
        callback();
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false
          }
        });
      });
  };
}

export const handleDoloadTemplate = () => {
  return async () => {
    await handleCheckToken()
    get('/model/import_agent_template.xls', null, null, "blob")
      .then(res => {
        const fileName = "import_agent_template.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {

      });
  };
}
