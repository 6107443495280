import { notification } from "antd";
import moment from "moment";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

const GET_REGISTRATIONS_COURSES = "GET_REGISTRATIONS_LIST";
const SAVE_DATA = 'REGISTRATIONS_SAVE'
const INIT_DATA = 'REGISTRATIONS_SAVE_INIT_DATA'
const ALL = 'All'

const initState = {
  agentId: {
    value: undefined,
  },
  agentCode: {
    value: ''
  },
  agentLeader: {
    value: ''
  },
  agentName: {
    value: ''
  },
  region: {
    value: ''
  },
  unitCode: {
    value: ''
  },
  registrationDate: {
    value: undefined
  },
  status: {
    value: ''
  },
  agents: [],
};

// reducer
export default function RegistrationListReducer(state = initState, action) {
  switch (action.type) {
    case 'onChange':
    case SAVE_DATA:
      return { ...state, ...action.payload };
    case INIT_DATA:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA,
  };
}

const baseApiUrl = '/pruventure/registrations'
const baseOptionsUrl = `${baseApiUrl}/options`
export function singleAddRegistration(payload, handleReject, handleAddSuccess) {
  return dispatch => {
    dispatch(loading(true))
    return post(`${baseApiUrl}`, payload)
      .then(res => {
        dispatch(loading(false))
        if (res.success) {
          const data = res.data
          handleAddSuccess()
          notification.success({
            message: 'Agent is added successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'update registration error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch((res) => {
        dispatch(loading(false));
        if (res.code === 0) {
          return
        }
        handleReject(res)
      });
  };
}

export function getAgents(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agents`, payload)
      .then(res => {
        dispatch(loading(false))
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {agents: data}
          })
        } else {
          notification.error({
            message: 'get agents error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
export function Confirm(payload, onSuccess) {
  return dispatch => {
    dispatch(loading(true))
    return post(`${baseApiUrl}`, payload)
      .then(res => {
        dispatch(loading(false))
        if (res.success) {
          const data = res.data
          onSuccess()
          notification.success({
            message: 'Agent is added successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'confirm error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  }
}
