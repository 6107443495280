import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Upload
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pclaReducer/systemSettingsReducers/index.action";
import {
  searchSummaryReport,
  getTrainingStatus,
  download_summary,
  getAgentExcel,
  innit_data
} from "../../../../redux/pclaReducer/CPDReportReducers/AttendedSummaryReducer";
import { getStatusList } from "../../../../redux/pclaReducer/CPDReportReducers/ManagementReportReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";
import { file as uploadfileIcon } from "../../../../images";
// import { originalUrlPrefix } from "../../../../utils/config";
import { download_template } from "../../../../redux/PublicReducer";

const CreateForm = Form.create({
  name: "CreateAttendedSummary_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("AttendedSummary", changedFields);
  },
  mapPropsToFields(props) {
    return {
      trainingStatus: Form.createFormField({
        ...props.trainingStatus,
        value: props.trainingStatus.value
      }),
      agentStatus: Form.createFormField({
        ...props.agentStatus,
        value: props.agentStatus.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      }),
      contractDate: Form.createFormField({
        ...props.contractDate,
        value: props.contractDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Session Training Status">
              {getFieldDecorator(
                "trainingStatus",
                {}
              )(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="All">All</Option>
                  {props.trainingStatusList.length
                    ? props.trainingStatusList.map(item => (
                        <Option value={item.value} key={item.value}>
                          {item.code}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Agent Status">
              {getFieldDecorator(
                "agentStatus",
                {}
              )(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.agentStatusList.length
                    ? props.agentStatusList.map(item => (
                        <Option value={item.value} key={item.value}>
                          {item.code}
                        </Option>
                      ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="LIAM Contract Date">
              {getFieldDecorator(
                "contractDate",
                {}
              )(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item
              label="Training Session End Date"
              validateStatus={props.errMsg.status ? "error" : null}
            >
              <div className={styles.colbox}>
                <Select
                  placeholder="Select Year"
                  defaultValue=""
                  value={props.trainingFromDate}
                  onChange={e => {
                    props.changeYear(e, 0);
                  }}
                  style={{ width: "44%" }}
                >
                  {props.years.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
                <Select
                  placeholder="Select Quarter"
                  defaultValue=""
                  value={props.trainingToDate}
                  onChange={props.changeMonth}
                  style={{ width: "54%" }}
                >
                  {props.months.map(item => (
                    <Option value={item.value} key={item.index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={insideStyles.errMsg}>{props.errMsg.title}</div>
            </Form.Item>
          </Col>
          <Col span={4} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{ display: props.showList ? "block" : "none" }}
          className={styles.line}
        >
          {" "}
        </div>
        <Button
          className={styles.btn}
          style={{ display: props.showList ? "block" : "none" }}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_summary();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
            display: props.showList ? "block" : "none"
          }}
        >
          <div style={insideStyles.title}>
            COURSE ATTENDED BY UNDERPERFORMANCE / QUARTER
          </div>
          <Table
            bordered
            scroll={{ x: 1800 }}
            className={styles.ant_dev_two_table}
            columns={props.setTableColumns(props)}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
            rowKey="courseId"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pclaReducer: {
      CPDReportReducers: { AttendedSummaryReducer }
    },
    PublicReducer: { loading },
    pclaReducer: {
      attendanceReducers: {
        SessionDetailReducer: { ServerTime }
      }
    }
  }) => ({
    fields: AttendedSummaryReducer,
    loading,
    ServerTime
  }),
  {
    changeVals,
    getStatusList,
    getTrainingStatus,
    searchSummaryReport,
    download_summary,
    getAgentExcel,
    innit_data,
    download_template
  }
)
class AttendedSummary extends Component {
  constructor(props) {
    super(props);
    this.handleEndDisabledDate = this.handleEndDisabledDate.bind(this);
    this.download_summary = this.download_summary.bind(this);
    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.setTableColumns = this.setTableColumns.bind(this);
    this.changeYear = this.changeYear.bind(this);
    this.changeMonth = this.changeMonth.bind(this);
    this.state = {
      years: [],
      months: [],
      trainingFromDate: "",
      trainingToDate: "",
      trainingStart: "",
      currentPageNumber: 1,
      fileList: [],
      pageSize: 10,
      pageIndex: 1,
      errMsg: {
        title: "",
        status: false
      },
      columns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          align: "center",
          width: 120,
          fixed: "left",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "COURSE NAME",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          width: 550,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.getStatusList();
    this.props.getTrainingStatus();
    const nowday = new Date(this.props.ServerTime);
    const nowYear = nowday.getFullYear();
    const year = nowYear + 2;
    const years = [];
    const len = year - 10;
    for (let i = year; i > len; i--) {
      years.push(i);
    }
    this.setState(
      () => ({
        years
      }),
      () => {
        if (!this.props.fields.trainingDate.value.length) {
          this.changeYear(nowYear, 0);
        } else {
          const tyear = new Date(
            this.props.fields.trainingDate.value[0]
          ).getFullYear();
          const ttmonth =
            new Date(this.props.fields.trainingDate.value[0]).getMonth() + 1;
          let tmonth = 0;
          switch (ttmonth) {
            case 4:
            case 5:
            case 6:
              tmonth = 1;
              break;
            case 7:
            case 8:
            case 9:
              tmonth = 2;
              break;
            case 10:
            case 11:
            case 12:
              tmonth = 3;
              break;
            default:
              tmonth = 0;
              break;
          }
          this.changeYear(tyear, tmonth);
        }
      }
    );
  }

  handleTableChange = pagination => {
    const { current } = pagination;
    this.setState(() => ({
      currentPageNumber: current
    }));
  };

  download_summary() {
    const { fields, download_summary } = this.props;
    download_summary({
      agentIdList: fields.agentIdList,
      sessionTrainingStatus: fields.trainingStatus.value,
      status: fields.agentStatus.value,
      liamFromdate: fields.contractDate.value.length
        ? fields.contractDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      liamTodate: fields.contractDate.value.length
        ? fields.contractDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : "",
      trainingFromDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      trainingToDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : ""
    });
  }

  setTableColumns(props) {
    const checkList = [...props.columns];
    if (props.checkList.length) {
      props.checkList.forEach(item => {
        checkList.push({
          title: () => (
            <div>
              <div>{item.title}</div>
              <div>({item.subTitle})</div>
            </div>
          ),
          dataIndex: item.index,
          ellipsis: true,
          width: props.checkList.length > 10 ? 150 : null,
          className: styles.checklist,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        });
      });
    }
    checkList.push({
      title: "TOTAL",
      dataIndex: "total",
      ellipsis: true,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    });
    return checkList;
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (!fields.trainingDate.value.length) {
          this.setState(() => ({
            errMsg: {
              title: "Please fill in the mandatory fields",
              status: true
            }
          }));
          return;
        } else {
          this.setState(() => ({
            errMsg: {
              title: "",
              status: false
            }
          }));
        }
        if (fields.trainingDate.value.length) {
          const year = new Date(this.props.ServerTime).getFullYear();
          /* eslint-disable */
          let seconds = 0;
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            seconds = 31643326;
          } else {
            seconds = 31556926;
          }
          /* eslint-disable */
          const start = new Date(
            fields.trainingDate.value[0].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const end = new Date(
            fields.trainingDate.value[1].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const between = Math.round(end / 1000) - Math.round(start / 1000);
          if (seconds < between) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        this.setState(() => ({
          currentPageNumber: 1
        }));
        this.props.searchSummaryReport({
          agentIdList: fields.agentIdList,
          sessionTrainingStatus: fields.trainingStatus.value,
          status: fields.agentStatus.value,
          liamFromdate: fields.contractDate.value.length
            ? fields.contractDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          liamTodate: fields.contractDate.value.length
            ? fields.contractDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : "",
          trainingFromDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          trainingToDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : ""
        });
      }
    });
  };
  handleEndDisabledDate = current => {
    if (this.state.trainingStart) {
      return (
        current > moment(this.state.trainingStart).add(1, "year") ||
        current < moment(this.state.trainingStart)
      );
    } else {
      return null;
    }
  };

  onCalendarChange(dates) {
    this.setState(() => {
      return {
        trainingStart: dates[0]
      };
    });
  }

  changeMonth(date) {
    this.setState(() => ({
      errMsg: {
        title: "",
        status: false
      },
      trainingToDate: date
    }));
    const trainingDate = date.split("~");
    this.props.changeVals("AttendedSummary", {
      trainingDate: {
        value: [
          moment(trainingDate[0], "YYYY-MM-DD HH:mm:ss"),
          moment(trainingDate[1], "YYYY-MM-DD HH:mm:ss")
        ]
      }
    });
  }

  changeYear(valYear, tmonth) {
    this.setState(() => ({
      errMsg: {
        title: "",
        status: false
      },
      trainingFromDate: valYear
    }));
    let months = [];
    // const nowday = new Date(this.props.ServerTime);
    // const year = nowday.getFullYear();
    // const month = nowday.getMonth() + 1;
    // const date = nowday.getDate();
    // if (year === valYear) {
    //   if (month <= 3) {
    //     months = [
    //       {
    //         index: "1-3",
    //         value: `${year}-01-01 00:00:00~${year}-${month}-${date} 23:59:59`,
    //         name: `01 ~ 03`
    //       }
    //     ]
    //   }
    //   else if (3 < month <= 6) {
    //     months = [
    //       {
    //         index: "1-3",
    //         value: `${year}-01-01 00:00:00~${year}-03-31 23:59:59`,
    //         name: `01 ~ 03`
    //       },
    //       {
    //         index: "4-6",
    //         value: `${year}-04-01 00:00:00~${year}-${month}-${date} 23:59:59`,
    //         name: `04 ~ 06`
    //       }
    //     ]
    //   }
    //   else if (6 < month <= 9) {
    //     months = [
    //       {
    //         index: "1-3",
    //         value: `${year}-01-01 00:00:00~${year}-03-31 23:59:59`,
    //         name: `01 ~ 03`
    //       },
    //       {
    //         index: "4-6",
    //         value: `${year}-04-01 00:00:00~${year}-06-30 23:59:59`,
    //         name: `04 ~ 06`
    //       },
    //       {
    //         index: "7-9",
    //         value: `${year}-07-01 00:00:00~${year}-${month}-${date} 23:59:59`,
    //         name: `07 ~ 09`
    //       },
    //     ]
    //   }
    //   else if (9 < month <= 12) {
    //     months = [
    //       {
    //         index: "1-3",
    //         value: `${year}-01-01 00:00:00~${year}-03-31 23:59:59`,
    //         name: `01 ~ 03`
    //       },
    //       {
    //         index: "4-6",
    //         value: `${year}-04-01 00:00:00~${year}-06-30 23:59:59`,
    //         name: `04 ~ 06`
    //       },
    //       {
    //         index: "7-9",
    //         value: `${year}-07-01 00:00:00~${year}-09-30 23:59:59`,
    //         name: `07 ~ 09`
    //       },
    //       {
    //         index: "10-12",
    //         value: `${year}-10-01 00:00:00~${year}-${month}-${date} 23:59:59`,
    //         name: `10 ~ 12`
    //       },
    //     ]
    //   }
    // }
    // else {
    // }
    months = [
      {
        index: "1-3",
        value: `${valYear}-01-01 00:00:00~${valYear}-03-31 23:59:59`,
        name: `01 ~ 03`
      },
      {
        index: "4-6",
        value: `${valYear}-04-01 00:00:00~${valYear}-06-30 23:59:59`,
        name: `04 ~ 06`
      },
      {
        index: "7-9",
        value: `${valYear}-07-01 00:00:00~${valYear}-09-30 23:59:59`,
        name: `07 ~ 09`
      },
      {
        index: "10-12",
        value: `${valYear}-10-01 00:00:00~${valYear}-12-31 23:59:59`,
        name: `10 ~ 12`
      }
    ];
    this.setState(
      () => ({
        months
      }),
      () => {
        this.changeMonth(months[tmonth].value);
      }
    );
  }

  render() {
    const { fileList } = this.state;
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      setTableColumns: this.setTableColumns,
      handleEndDisabledDate: this.handleEndDisabledDate,
      onCalendarChange: this.onCalendarChange,
      download_summary: this.download_summary,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      years: this.state.years,
      months: this.state.months,
      changeYear: this.changeYear,
      changeMonth: this.changeMonth,
      errMsg: this.state.errMsg,
      trainingFromDate: this.state.trainingFromDate,
      trainingToDate: this.state.trainingToDate
    };
    const uploadProps = {
      beforeUpload: file => {
        this.setState(() => ({
          fileList: [file]
        }));
        this.props.getAgentExcel(file);
        return false;
      },
      fileList,
      showUploadList: false
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Training Attended Summary</div>
        <div className={styles.uploadBox}>
          <div className={styles.uploadtitle}>Select File :</div>
          <div className={styles.showpath}>
            {fileList[0] && fileList[0].name}
          </div>
          <Upload {...uploadProps}>
            <div className={styles.uploadIconBox}>
              <img className={styles.uploadIcon} src={uploadfileIcon} alt="" />
            </div>
          </Upload>
          <div className={styles.downloadBox}>
            <Button
              className={styles.downloadBtn}
              download
              // href={`${originalUrlPrefix["pamb"]}/model/AgentID.xls`}
              icon="download"
              onClick={() => {
                this.props.download_template(
                  "/model/AgentID.xls",
                  "AgentID.xls"
                );
              }}
            >
              Download Template
            </Button>
          </div>
        </div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  errMsg: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#ED1B2E",
    letterSpacing: 0,
    marginBottom: "15px",
    padding: "0",
    width: "100%"
  }
};

const flexCol = {
  xxl: 10,
  xl: 10,
  lg: 10,
  md: 12,
  sm: 12,
  xs: 12
};

export default AttendedSummary;
