import React, {Component} from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Modal,
  Menu,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty,
  Dropdown,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { debounce, get } from 'lodash';
import styles from './style.module.less'
import {
  ONCAHENG_TYPE,
} from "../../../../../../redux/pambReducer/attendanceReportReducers/listReducer";
import { commonColumns, dropdownItem } from './columns'
import { globalPromptTime10s, isPambPruventureAdmin } from '../../../../../../utils/global';

export const ListForm = Form.create({
  name: 'attendance_report_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      agentId: Form.createFormField({
        ...props.agentId,
        value: props.agentId.value
      }),
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      registrationDate: Form.createFormField({
        ...props.registrationDate,
        value: props.registrationDate.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      reportPeriod: Form.createFormField({
        ...props.reportPeriod,
        value: props.reportPeriod.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { MonthPicker } = DatePicker
  const { statuses, agentIds, unitCodes, regions, agentLeaders, roleId } = props;
  return (
    <Form
      labelAlign="left"
      className={styles.ant_form}
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Report Period">
            {getFieldDecorator("reportPeriod", {
              initialValue: !props.reportPeriod.value ? undefined : moment(props.reportPeriod.value),
            })(
            <MonthPicker
              placeholder="Select Month"
            />
            )
            }
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent ID">
            {getFieldDecorator("agentId")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentIds, 500)}
                optionFilterProp="children"
              >
                {!!agentIds.length &&
                  agentIds.map(id => {
                    return (
                      <Option key={id.key} value={id.value}>
                        <Tooltip placement="top" title={id.key}>
                          {id.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region")(
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!regions.length &&
                  regions.map(region => {
                    return (
                      <Option key={region.key} value={region.value}>
                        <Tooltip placement="top" title={region.key}>
                          {region.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                onSearch={debounce(props.searchAgentUnitCodes, 500)}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!unitCodes.length &&
                  unitCodes.map(unit => {
                    return (
                      <Option key={unit.key} value={unit.value}>
                        <Tooltip placement="top" title={unit.key}>
                          {unit.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent Leader">
            {getFieldDecorator("agentLeader")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentLeaders, 500)}
                optionFilterProp="children"
              >
                {!!agentLeaders.length &&
                  agentLeaders.map(leader => {
                    return (
                      <Option key={leader.key} value={leader.value}>
                        <Tooltip placement="top" title={leader.key}>
                          {leader.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Registration Date">
            {getFieldDecorator("registrationDate", {
              initialValue: !props.registrationDate.value ? undefined : moment(props.registrationDate.value),
            })(
            <MonthPicker
              placeholder="Select Month"
            />
            )}

          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status")(
              <Select
                showArrow
                disabled={!isPambPruventureAdmin(roleId)}
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!statuses.length &&
                  statuses.map(status => {
                    return (
                      <Option key={status.key} value={status.value}>
                        <Tooltip placement="top" title={status.key}>
                          {status.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
})

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16
  },
};

const flexRow = {
  gutter: [22, 0]
};
