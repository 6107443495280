import { combineReducers } from "redux";
import CandidateAdminReducer from "./CandidateAdminReducer";
import CandidateReducer from "./CandidateReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import ImportCandidatesReducer from "./ImportCandidatesReducer";
import userManagementReducer from "./userManagementReducer";
import CreateCourseAdminReducer from "./CreateCourseAdminReducer";
import CreateLBUAdminReducer from "./CreateLBUAdminReducer";
import otherInfoReducer from "./otherInfoReducer";
import ImportAgentReducer from "./ImportAgentReducer";
import ImportGuestsReducer from "./ImportGuestsReducer";
import GuestReducer from "./GuestReducer";
import CreateRegionAdminReducer from "./CreateRegionAdminReducer";
import SurveyReducer from "./SurveyReducer";
import QuestionGroupReducer from "./QuestionGroupReducer";
import ReminderListTReducer from "./ReminderListTReducer";
import ReminderDetailReducer from "./ReminderDetailReducer";
import DailyCoachingSearchReducer from "./DailyCoachingSearchReducer";
import EditCoachingCourseReducer from "./EditCoachingCourseReducer";
import SelectCourseReducer from "./SelectCourseReducer";
import SelectTrainerReducer from "./SelectTrainerReducer";
import SelectAgentLeaderReducer from "./SelectAgentLeaderReducer";
import PACSAgentTrainingCodeReducer from "./PACSAgentTrainingCodeReducer";
import TimeJobReducer from "./TimeJobReducer";
import CertificationReducer from "./CertificationReducer";
import TimeJobRerunReducer from "./TimeJobRerunReducer";

export default combineReducers({
  CandidateReducer,
  CandidateAdminReducer,
  ResetPasswordReducer,
  ImportCandidatesReducer,
  userManagementReducer,
  CreateCourseAdminReducer,
  CreateLBUAdminReducer,
  otherInfoReducer,
  ImportAgentReducer,
  GuestReducer,
  ImportGuestsReducer,
  CreateRegionAdminReducer,
  SurveyReducer,
  QuestionGroupReducer,
  ReminderListTReducer,
  ReminderDetailReducer,
  DailyCoachingSearchReducer,
  EditCoachingCourseReducer,
  SelectCourseReducer,
  SelectTrainerReducer,
  SelectAgentLeaderReducer,
  PACSAgentTrainingCodeReducer,
  TimeJobReducer,
  CertificationReducer,
  TimeJobRerunReducer
});
