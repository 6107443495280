import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import { globalPromptTime10s } from "../../../utils/global";

const SAVE_MANAGEMENT_REPORT_DATA = "PLUK/SAVE_MANAGEMENT_REPORT_DATA";
const INITDATA_ATTENDED_SUMMARY = "PLUK/INITDATA_ATTENDED_SUMMARY";
const SAVEDATA_SUMMARY = "PLUK/SAVEDATA_ATTENDED_SUMMARY";
const initState = {
  trainingStatus: {
    value: "All"
  },
  agentStatus: {
    value: ""
  },
  contractDate: {
    value: ""
  },
  trainingDate: {
    value: ""
  },
  checkBox: {
    value: ""
  },
  agentStatusList: [],
  trainingStatusList: [],
  searchList: [],
  agentIdList: [],
  checkList: [
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    },
    {
      title: "",
      index: "",
      value: ""
    }
  ],
  showList: false
};

export default function AttendedSummaryReducer(state = initState, action) {
  switch (action.type) {
    case "AttendedSummary":
      return { ...state, ...action.payload };
    case INITDATA_ATTENDED_SUMMARY:
      return { ...initState };
    case SAVEDATA_SUMMARY:
      return { ...state, ...action.payload };
    case SAVE_MANAGEMENT_REPORT_DATA:
      return {
        ...state,
        agentStatusList: action.payload.statusList
          ? [...action.payload.statusList]
          : [...state.agentStatusList]
      };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_ATTENDED_SUMMARY
  };
}

export function save_data(payload) {
  return {
    type: SAVEDATA_SUMMARY,
    payload
  };
}
// POST /pamb/summary/summary
export function searchSummaryReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/summary/summary", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          const finalArr = [];
          res.data.forEach(item => {
            const quArr = [];
            const itemObj = {
              courseId: item.courseId,
              courseName: item.courseName,
              total: item.total
            };
            item.quarterList.forEach(x => {
              const Quarter = x.quarterName.replace(/\s*/g, "");
              for (let v in x) {
                switch (v) {
                  case "isBumi":
                    quArr.push({
                      title: "Bumi",
                      subTitle: x.quarterName,
                      index: `${v}_${Quarter}`,
                      value: x[v]
                    });
                    itemObj[`${v}_${Quarter}`] = x[v];
                    break;
                  case "notBumi":
                    quArr.push({
                      title: "Non Bumi",
                      subTitle: x.quarterName,
                      index: `${v}_${Quarter}`,
                      value: x[v]
                    });
                    itemObj[`${v}_${Quarter}`] = x[v];
                    break;
                  case "total":
                    quArr.push({
                      title: "Sub Total",
                      subTitle: x.quarterName,
                      index: `${v}_${Quarter}`,
                      value: x[v]
                    });
                    itemObj[`${v}_${Quarter}`] = x[v];
                    break;
                }
              }
            });
            itemObj["attrArr"] = quArr;
            finalArr.push(itemObj);
          });
          dispatch(
            save_data({
              searchList: [...finalArr],
              checkList: [...finalArr[0].attrArr],
              showList: true
            })
          );
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}

// GET /pamb/summary/SessionTrainingStatus
export function getTrainingStatus() {
  return dispatch => {
    get("/summary/SessionTrainingStatus")
      .then(res => {
        if (res.success) {
          if (res.data.length) {
            dispatch(
              save_data({
                trainingStatusList: [...res.data]
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(()=> {});
  };
}
// POST /pamb/summary/downloadTrainingAttendedSummaryReport
export function download_summary(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/summary/downloadTrainingAttendedSummaryReport", data, {}, "blob")
      .then(blob => {
        const fileName = "SummaryReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
// POST /pamb/summary/getAgentExcel
export function getAgentExcel(file) {
  return dispatch => {
    dispatch(loading(true));

    const formData = new FormData();
    formData.append("file", file);
    post("/summary/getAgentExcel", formData)
      .then(res => {
        setTimeout(() => {
          notification.success({
            message: "Uploaded successfully",
            duration: globalPromptTime10s
          });
        }, 500);
        dispatch(
          save_data({
            agentIdList: [...res.data]
          })
        );

        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
