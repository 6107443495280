import React, { Component } from "react";
import { Link } from "react-router-dom";
import { notFound } from "../../images"
import { globalUrlPre } from "../../utils/config"
import styles from './NotFound.module.less'

export default class NotFound extends Component {
  render() {
    const author = sessionStorage.getItem('user')
    const loginUrl = `/${globalUrlPre}/admin/login`
    // const text = author ? 'Sorry, you account is not authorized to view this page. Make sure the URL is correct and your account has access.You may request access by contacting the admin'
    return (
      <div className={styles.container}>
        <img src={notFound} className={styles.img} alt="" />
        <div className={styles.right}>
          <div className={styles.num}>404</div>
          <div className={styles.text}>Sorry, the page you visited does not exist.</div>
          <div className={styles.btn}><Link to={author ? '/' : loginUrl}>Back Home</Link></div>
        </div>

      </div>
    );
  }
}
