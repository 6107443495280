import { message } from "antd";
import { get } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pamb/TimeJobReducer/SAVE";

const initState = {
  submitLoading: false,
  timeJobList: [
    {
      id: 'TMS_PAMB_Daily_Dawn_Job',
      name: 'TMS PAMB Daily Dawn Job',
      children: [
        {
          title: 'Sync Agent 1 /user/toLitmosUser',
          url: '/user/toLitmosUserDM',
          hasStartDate: true
        },
        {
          title: 'Sync Agent 2 /sync/RemoveTeamUser',
          url: '/sync/RemoveTeamUser'
        },
        {
          title: 'Sync Agent 3 /user/updateLitmosUser',
          url: '/user/updateLitmosUser',
          hasStartDate: true
        },
        {
          title: 'Sync Agent 4 /user/emailToUser',
          url: '/user/emailToUser',
          hasStartDate: true
        },
        {
          title: 'Sync Course 1 /sync/course',
          url: '/sync/course',
          hasRangeDate: true
        },
        {
          title: 'Sync Course 2 /pamb/sync/instructors',
          url: '/sync/instructors'
        },
        {
          title: 'Sync Course 3 /sync/getFutureSession',
          url: '/sync/getFutureSession'
        },
        {
          title: 'Sync Course 4 /sync/getInstructorsSessions',
          url: '/sync/getInstructorsSessions',
          hasRangeDate: true
        },
        {
          title: 'Sync Course 5 /sync/courseDateUser',
          url: '/sync/courseDateUser',
          hasRangeDate: true
        },
        {
          title: 'Sync CPD Calculation 1',
          url: '/calculate',
          hasRangeDate: true
        },
        {
          title: 'Generate PIP CPD detail txt 1',
          url: '/pip/downCpdDetailTxt'
        },
        {
          title: 'Generate PIP master file 1',
          url: '/pip/downMasterFileTxt'
        },
        {
          title: 'Sync CPD Calculation History 2',
          url: '/calculate/history',
          hasRangeDate: true
        },
        {
          title: 'Generate Survey 1',
          url: '/survey/generateSurvey',
          hasRangeDate: true
        },
        {
          title: 'Generate Daily Coaching 1',
          url: '/dailycoaching/createSessionDay',
          hasStartDate: true
        }
      ],
    },
    {
      id: 'TMS_PAMB_Daily_Minute_Job',
      name: 'TMS PAMB Daily Minute Job',
      children: [
        {
          title: 'Reminder 1 ScheduledTaskLiamCpd',
          url: '/cpdTimeJob/ScheduledTaskLiamCpd',
        },
        {
          title: 'Reminder 1 ScheduledTaskLiamRfp',
          url: '/cpdTimeJob/ScheduledTaskLiamRfp',
        },
        {
          title: 'Reminder 1 ScheduledTaskLiamTwelve',
          url: '/cpdTimeJob/ScheduledTaskLiamTwelve',
        },
        {
          title: 'Reminder 1 ScheduledTaskLiamTwentyFour',
          url: '/cpdTimeJob/ScheduledTaskLiamTwentyFour',
        },
        {
          title: 'Reminder 1 ScheduledTaskMtaCpd',
          url: '/cpdTimeJob/ScheduledTaskMtaCpd',
        },
        {
          title: 'Reminder 1 ScheduledTaskMtaFirst',
          url: '/cpdTimeJob/ScheduledTaskMtaFirst',
        },
        {
          title: 'Reminder 1 ScheduledTaskMtaSecond',
          url: '/cpdTimeJob/ScheduledTaskMtaSecond',
        },
        {
          title: 'Reminder 1 scheduledTaskLiamSix',
          url: '/cpdTimeJob/scheduledTaskLiamSix',
        }
      ]
    },
    {
      id: 'TMS_PAMB_Daily_Night_Job',
      name: 'TMS PAMB Daily Night Job',
      children: [
        {
          title: 'Sync Attendance 1 /sync/AttendanceToTMS',
          url: '/attendancepamb/attendance/sendToTms',
          hasRangeDate: true
        },
        {
          title: 'Sync Attendance 2 /sync/AttendanceToLitmos',
          url: '/sync/AttendanceToLitmos',
        },
        {
          title: 'Sync Attendance 3 /sync/DailyCoachingAttendanceToLitmos',
          url: '/dailycoaching/dailyCoachingToLitmos',
          hasStartDate: true
        }
      ]
    },

    {
      id: 'TMS_PAMB_Monthly_Job',
      name: 'TMS PAMB Monthly Job',
      children: [
        {
          title: 'Generate Report 1 /oneEyeSee/report',
          url: '/oneEyeSee/report'
        }
      ]
    }
  ],
};

// reducer
export default function TimeJobReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export const handleSubmit = (payload, url) => async (dispatch) => {
  const combineCb = () => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: false
      }
    });
  }
  try {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    await get(url, payload);
    message.info("Operation Success！", globalPromptTime, combineCb);
  } catch (error) {
    message.error("Operation Failed！", globalPromptTime, combineCb);
  }
};
