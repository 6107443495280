import { post } from "../../../utils/request";

const SAVE = "pamb/trainingRequirementSearchReducer/SAVE";

const initState = {
  TRsearchData: {},
  searchLoading: false,
  isShowTableT: false,
  temporaryData: {
    currentNum: 1,
    searchPramas: {}
  }
};

// reducer
export default function trainingRequirementSearchReducer(
  state = initState,
  action
) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getTRsearchData(payload, fromDetail) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        searchLoading: true
      }
    });
    post("/training/function", payload)
      .then(res => {
        const { data } = res;
        if (fromDetail) {
          dispatch({
            type: SAVE,
            payload: {
              TRsearchData: data || {},
              searchLoading: false,
            }
          });
        } else {
          dispatch({
            type: SAVE,
            payload: {
              TRsearchData: data || {},
              searchLoading: false,
              isShowTableT: true
            }
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            searchLoading: false
          }
        });
      });
  };
}

export function saveFields(payload) {
  return (dispatch, getState) => {
    const {
      pambReducer: { CPDSettingsReducers: {
        trainingRequirementSearchReducer: { temporaryData }
      } }
    } = getState();
    dispatch({
      type: SAVE,
      payload: {
        temporaryData: { ...temporaryData, ...payload }
      }
    });
  };
}

export function resetForm() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        temporaryData: {
          currentNum: 1,
          searchPramas: {}
        },
        isShowTableT: false
      }
    });
  };
}
