import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Button } from "antd";
import { changeVals } from "../../../../../redux/plukReducer/systemSettingsReducers/index.action";
import { change_password } from "../../../../../redux/loginReducer";
import { changePassword } from "../../../../../redux/plukReducer/systemSettingsReducers/ResetPasswordReducer";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateResetPassword_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("ResetPassword", changedFields);
  },
  mapPropsToFields(props) {
    return {
      userName: Form.createFormField({
        ...props.userName,
        value: props.userName.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password.value
      }),
      passwordAgain: Form.createFormField({
        ...props.passwordAgain,
        value: props.passwordAgain.value
      })
    };
  },
  onValuesChange() {}
})(props => {
  const { getFieldDecorator } = props.form;
  const { errMsg } = props;
  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 7 }}
      labelAlign="left"
      wrapperCol={{ span: 17 }}
      colon={false}
    >
      <Row gutter={[24, 0]}>
        <Col {...flexCol}>
          <Form.Item label="Name">
            {getFieldDecorator("userName", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                }
              ]
            })(<Input disabled placeholder="Name" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  max: 50,
                  message: "fields cannot be longer than 50 characters"
                }
              ]
            })(<Input disabled placeholder="Login Account" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item
            label="Password"
            validateStatus={errMsg.type === "1" ? "error" : null}
          >
            {getFieldDecorator("password", {})(<Input.Password placeholder="Password" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item
            label="Password Again"
            validateStatus={errMsg.type === "2" ? "error" : null}
          >
            {getFieldDecorator("passwordAgain", {})(<Input.Password placeholder="Password Again" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <div style={insideStyles.prompt}>
            {errMsg.title}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={insideStyles.goCenter}>
          <Form.Item>
            <Button
              type="primary"
              className={`${styles.btnColor} ${styles.btnText}`}
              onClick={() => {
                props.handleSubmit(props.form);
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => ({
    fields: {
      ...state.systemSettingsReducers.ResetPasswordReducer
    }
  }),
  {
    changeVals,
    change_password,
    changePassword
  }
)
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
      errMsg: {
        title: "",
        type: ""
      }
    };
  }

  componentDidMount() {
    this.props.changeVals("ResetPassword", {
      userName: {
        value: this.props.location.state.useName
          ? this.props.location.state.useName
          : ""
      },
      account: {
        value: this.props.location.state.account
          ? this.props.location.state.account
          : ""
      }
    });
  }

  handleSubmit = () => {
    const { fields } = this.props;
    this.setState({
      errMsg: {
        title: "",
        type: ""
      }
    });
    if (fields.password.value) {
      const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
      if (!reg.test(fields.password.value)) {
        this.setState({
          errMsg: {
            title: `You have entered an invalid password`,
            type: "1"
          }
        });
        return;
      }
    } else {
      this.setState({
        errMsg: {
          title: "You have entered an invalid password",
          type: "1"
        }
      });
      return;
    }

    if (fields.passwordAgain.value) {
      const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
      if (!reg.test(fields.passwordAgain.value)) {
        this.setState({
          errMsg: {
            title: `You have entered an invalid password`,
            type: "2"
          }
        });
        return;
      }
    } else {
      this.setState({
        errMsg: {
          title: "You have entered an invalid password",
          type: "2"
        }
      });
      return;
    }

    if (fields.password.value === fields.passwordAgain.value) {
      this.props.changePassword({
        loginAccount: fields.account.value,
        password: fields.password.value,
        passwordAgain: fields.passwordAgain.value
      });
    } else {
      this.setState({
        errMsg: {
          title: "The password you entered does not match the password Again",
          type: ""
        }
      });
    }
  };

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>Reset Password</div>
        <CreateForm
          {...this.props.fields}
          errMsg={this.state.errMsg}
          handleSubmit={this.handleSubmit}
          onChange={this.props.changeVals}
        />
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  prompt: {
    fontFamily: "PingFangSC-Regular",
    fontSize: "14px",
    color: "#ED1B2E",
    letterSpacing: 0,
    textAlign: "center",
    marginBottom: "35px"
  }
};

const flexCol = {
  lg: 12,
  offset: 6
};

export default ResetPassword;
