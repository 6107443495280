import React from "react";
import { Form, Input, Row, Col } from "antd";
import hocUserDetail from '../index'
import { formLayout, flexCol, flexRow } from '../utils';
import '../index.less';

class Details extends React.Component {
  render() {
    const { form: { getFieldDecorator }, userInfo, isEditable } = this.props;

    return (
      <div className="container">
          <Form
            className="ant_form"
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: userInfo.roleName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: userInfo.bu
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginaccount", {
                    initialValue: userInfo.loginaccount
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: userInfo.username
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      }
                    ],
                    initialValue: userInfo.email
                  })(
                    <Input
                      // onChange={this.handleEmailChange}
                      disabled={!isEditable}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactnumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ],
                    initialValue: userInfo.contactnumber
                  })(<Input disabled={!isEditable} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
      </div>
    );
  }
}

const DetailsWithForm = Form.create()(Details);
const UserDetailHOC = hocUserDetail(DetailsWithForm, {
  fetchUrl: '/user/userDeatail',
  saveUrl: '/login/saveMyProfile',
});

export default UserDetailHOC;
