import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Tooltip, Menu, Dropdown, Icon } from 'antd';
import styles from "./index.module.less";
import { getRoleById } from './utils';
import { showActionByRole } from "../../../../../../utils/tableUtils"

const getRole = (record, self) => {
  const roleId = record.roleId;
  const roleList = self.props.userManagementReducer.rolerList;
  const role = getRoleById(roleList, roleId);

  return role;
}

const columns = {
  NamePHKL: function () { // eslint-disable-line
    return {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: "120px",
      render: (text, record) => {
        const { firstName, lastName, userId, userid } = record;
        const role = getRole(record, this);
        const bu = role[0] && role[0].bu;
        const roleCode = role[0] && role[0].code

        const Id = userId || userid;
        const name = `${firstName} ${lastName}`;
        const pathname = `/home/system_settings/user_management/user_details`;

        return (
          <Tooltip placement="topLeft" title={name}>
            <Link
              to={{
                pathname,
                state: {
                  userId: Id,
                  bu,
                  roleCode
                }
              }}
            >
              <span className={`${styles.col} ${styles.name}`} style={{ WebkitLineClamp: 2 }}>
                {name}
              </span>
            </Link>
          </Tooltip>
        );
      }
    }
  },
  NameAdmin: function () { // eslint-disable-line
    return {
      title: "Name",
      dataIndex: "username",
      align: "center",
      width: "120px",
      render: (text, record) => {
        const { userid, username, bu } = record;
        const pathname = `/home/system_settings/user_management/admin_details`;

        return (
          <Tooltip placement="topLeft" title={username}>
            <Link
              to={{
                pathname,
                state: {
                  userId: userid,
                  bu
                }
              }}
            >
              <span className={`${styles.col} ${styles.name}`} style={{ WebkitLineClamp: 2 }}>
                {username}
              </span>
            </Link>
          </Tooltip>
        );
      }
    }
  },
  loginAccount: {
    title: "Login Account",
    align: "center",
    dataIndex: "loginAccount"
  },
  agentCode: {
    title: "Agent Code",
    align: "center",
    dataIndex: "agentCode"
  },
  hkid: {
    title: "HKID",
    align: "center",
    dataIndex: "hkid"
  },
  otherId: {
    title: "Other ID",
    align: "center",
    dataIndex: "otherId"
  },
  staffId: {
    title: "Staff ID",
    align: "center",
    dataIndex: "staffId"
  },
  agentStatus: {
    title: "Agent Status",
    dataIndex: "agentStatus",
    align: "center"
  },
  createTime: {
    title: "Creation Date",
    align: "center",
    dataIndex: "createTime",
    render: text => (
      <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
    )
  },
  lastLogin: {
    title: "Last Login Date",
    align: "center",
    dataIndex: "lastLogin",
    render: text => (
      <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
    )
  },
  accountStatus: {
    title: "Account Status",
    align: "center",
    dataIndex: "accountStatus"
  },
  accountStatusAdmin: {
    title: "Account Status",
    align: "center",
    dataIndex: "status",
    render: text => {
      const statusArray = ["NoLogin", "Inactive", "Active"];
      return <span>{statusArray[text]}</span>;
    }
  },
  action: function (roleid, roleList) {  // eslint-disable-line
    return {
      title: "Action",
      dataIndex: "action",
      align: "center",
      width: "140px",
      render: (text, record) => {
        const showAction = showActionByRole(roleid, roleList);
        const { roleCode, roleId, accountStatus, status } = record;
        const needSendEmailAdmin = ['PHKLADCourseAdmin', 'PHKLBDCourseAdmin'].includes(roleCode);
        const needSendEmailTrainer = ['204', '205', '206'].includes(roleId);
        const noLogin = accountStatus === 'NoLogin' || status === '0';
        const isActive = accountStatus === 'Active' || status === '2';
        const isInActive = accountStatus === 'Inactive' || status === '1';

        const showResendEmailToAdmin = needSendEmailAdmin && noLogin;
        const showResendEmailToTrainer = needSendEmailTrainer && noLogin;
        const showActive = needSendEmailAdmin && isInActive;
        const showDeactivate = needSendEmailAdmin && (isActive || noLogin);
        const NeedToShowAction = showAction && (showResendEmailToAdmin || showResendEmailToTrainer || showActive || showDeactivate);

        const menu = (
          <Menu>
            {showAction && showResendEmailToAdmin ?
              (
                <Menu.Item onClick={() => {
                  const payload = {
                    loginAccount: record.loginAccount || record.loginaccount,
                    subject: "init_password"
                  }
                  this.resendEmail(payload);
                }}
                >
                  Resend Email
                </Menu.Item>
              )
              : null
            }

            {showAction && showResendEmailToTrainer ?
              (
                <Menu.Item onClick={() => {
                  const payload = {
                    userId: record.userId,
                    subject: "init_password"
                  }
                  this.resendEmailToTrainer(payload);
                }}
                >
                  Resend Email
                </Menu.Item>
              )
              : null
            }

            {showAction && showActive ?
              (
                <Menu.Item onClick={() => {
                  const { userid, userId } = record;
                  const payload = {
                    userId: userid || userId,
                  };

                  this.activate(payload);
                }}
                >
                  Activate
                </Menu.Item>
              )
              : null
            }

            {showAction && showDeactivate ?
              (
                <Menu.Item onClick={() => {
                  const { userid, userId } = record;
                  const payload = {
                    userId: userid || userId,
                  };

                  this.deActivate(payload);
                }}
                >
                  Deactivate
                </Menu.Item>
              )
              : null
            }
          </Menu>
        )

        return (
          NeedToShowAction && (
            <Dropdown
              overlay={menu}
              trigger={['click']}
              placement="bottomCenter"
              overlayClassName={styles.ant_dev_dropdown}
            >
              <span className={styles.spanCss}>
                <span className={styles.text}>More</span>
                <Icon type="caret-down" style={{ color: '#1890ff' }} />
              </span>
            </Dropdown>
          )
        )
      }
    }
  },
  Role: function () { // eslint-disable-line
    const roleList = this.props.userManagementReducer.rolerList;
    return {
      title: "Role",
      align: "center",
      dataIndex: "roleCode",
      render: text => {
        const role = roleList.filter(item => item.code === text);
        return (
          <span>{(role[0] && role[0].name) || ''}</span>
        );
      }
    }
  },
  updateTime: {
    title: "Last Update Date",
    align: "center",
    dataIndex: "updateTime",
    render: text => (
      <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
    )
  }
};

export const getColumnsByRole = (roleCode, self, roleid, roleList = []) => {
  switch (roleCode) {
    case 'PHKLADCourseAdmin':
    case 'PHKLBDCourseAdmin':
    case 'PHKLADMCourseAdmin':
    case 'PHKLPDCourseAdmin':
      return [columns.NameAdmin.apply(self), columns.Role.apply(self), columns.lastLogin, columns.createTime, columns.updateTime, columns.accountStatusAdmin, columns.action.apply(self, [roleid, roleList])];
      break;
    // ADM Channel
    case 'PHKLADMTrainer':
    case 'PHKLADMAgentsTrainer':
    case 'PHKLADMCandidates':
    case 'PHKLADMAgents':
      return [columns.NamePHKL.apply(self), columns.loginAccount, columns.agentCode, columns.otherId, columns.agentStatus, columns.lastLogin, columns.createTime, columns.updateTime, columns.accountStatus, columns.action.apply(self, [roleid, roleList])]
      break;
    // PD Channel
    case 'PHKLPDTrainer':
    case 'PHKLPDAgents':
    case 'PHKLPDCandidates':
      return [columns.NamePHKL.apply(self), columns.loginAccount, columns.agentCode, columns.staffId, columns.agentStatus, columns.lastLogin, columns.createTime, columns.updateTime, columns.accountStatus, columns.action.apply(self, [roleid, roleList])]
      break;
    default:
      return [columns.NamePHKL.apply(self), columns.loginAccount, columns.agentCode, columns.hkid, columns.agentStatus, columns.lastLogin, columns.createTime, columns.updateTime, columns.accountStatus, columns.action.apply(self, [roleid, roleList])]
      break;
  }
}
