import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { post } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_DAILYCOACHINGSEARCH = "Pcla/INIT_DATA_DAILYCOACHINGSEARCH";

const initState = {
  courseName: {
    value: ""
  },
  courseCode: {
    value: ""
  },
  trainer: {
    value: ""
  },
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1
};

// reducer
export default function DailyCoachingSearchReducer(state = initState, action) {
  switch (action.type) {
    case "DailyCoachingSearch":
      return { ...state, ...action.payload };
    case INIT_DATA_DAILYCOACHINGSEARCH:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_DAILYCOACHINGSEARCH
  };
}

export function save_data(payload) {
  return {
    type: "DailyCoachingSearch",
    payload
  };
}

export function search(payload) {
  return dispatch => {
    dispatch(loading(true));
    post("/dailycoaching/dailycoachingList", payload)
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              searchList: res.data.data,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
