import types from './types';
import { get } from '../../../../utils/request';

export const tabChangesTrigger = isRistrationTab => async (dispatch) => {
  try {
    dispatch({
      type: types.TABS_CHANGES,
      isRistrationTab
    });
  } catch (error) {

  }
};


export const sessionDayChangesTrigger = currentSessiondayId => async (dispatch) => {
  try {
    dispatch({
      type: types.SESSION_DAY_CHANGES,
      currentSessiondayId
    });
  } catch (error) {

  }
};

export const getCourseAttendanceList = sessiondayId => async (dispatch) => {
  try {
    dispatch({
      type: types.BEFORE_FETCH_COURSE_ATTENDANCE_LIST,
      payload: {
        loadingCourseAttendanceList: true
      }
    });

    const res = await get("/attendanceTms/getAttendanceAgents", { sessiondayId });

    dispatch({
      type: types.FETCH_COURSE_ATTENDANCE_LIST,
      payload: {
        courseAttendanceList: res.data,
        loadingCourseAttendanceList: false
      }
    });
  } catch (error) {
    dispatch({
      type: types.AFTER_FETCH_COURSE_ATTENDANCE_LIST,
      payload: {
        loadingCourseAttendanceList: false
      }
    });
  }
};
