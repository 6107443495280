import React from "react";
import { connect } from "react-redux";
import Router from "../../../../../router";
import { getRankList } from "../../../../../redux/pacsReducer/systemSettingsReducers/userManagementReducer";

@connect(
  state => state,
  { getRankList }
)
class UserManagement extends React.Component {
  componentDidMount() {
    // this.props.getRankList();
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default UserManagement;
