import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_SELECTTRAINER = "PACS/INIT_DATA_SELECTTRAINER";
const SEARCHDATA_SELECTTRAINER = "PACS/SEARCHDATA_SELECTTRAINER";

const initState = {
  trainerName: {
    value: ""
  },
  List: [],
  allList: [],
  trainerList: []
};

// reducer
export default function SelectTrainerReducer(state = initState, action) {
  switch (action.type) {
    case "SelectTrainer":
      return { ...state, ...action.payload };
    case SEARCHDATA_SELECTTRAINER:
      return { ...state, ...action.payload };
    case INIT_DATA_SELECTTRAINER:
      return { ...initState };
    default:
      return state;
  }
}
export function search_data(payload) {
  return {
    type: SEARCHDATA_SELECTTRAINER,
    payload: {
      List: [...payload]
    }
  };
}

export function init_data() {
  return {
    type: INIT_DATA_SELECTTRAINER
  };
}

export function save_data(payload) {
  return {
    type: "SelectTrainer",
    payload
  };
}

export function getTrainerList(pacsDailyCoachingRuleId, trainerName, type) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/trainerList", {
      pacsDailyCoachingRuleId: "",
      trainerName
    })
      .then(res => {
        if (res.success) {
          const data = [...res.data];
          if (type === "edit") {
            dispatch(
              trainerAlredayList(pacsDailyCoachingRuleId, trainerName, data)
            );
          } else {
            const finalData = [];
            data.forEach(item => {
              finalData.push({
                key: item.userId,
                title: `${item.userName}`,
                description: "",
                chosen: false,
                ...item
              });
            });
            dispatch(
              save_data({
                allList: finalData,
                trainerList: []
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function trainerAlredayList(
  pacsDailyCoachingRuleId,
  trainerName,
  allData
) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/trainerAlredayList", {
      pacsDailyCoachingRuleId,
      trainerName
    })
      .then(res => {
        if (res.success) {
          const finalData = [];
          const keys = [];
          allData.forEach(item => {
            const obj = {
              key: item.userId,
              title: `${item.userName}`,
              description: "",
              chosen: false,
              ...item
            };
            for (let i = 0; i < res.data.length; i += 1) {
              if (res.data[i].userId === item.userId) {
                obj.chosen = true;
                break;
              }
            }
            if (obj.chosen) {
              keys.push(item.userId);
            }
            finalData.push(obj);
          });

          dispatch(
            save_data({
              allList: finalData,
              trainerList: keys
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
