import React, { Component, Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  Table,
  Input,
  Checkbox,
  notification,
  Empty
} from "antd";
import { useMount } from "ahooks";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  innit_data,
  getAgentStatusSelect,
  getAgentLeaderSelect,
  getFcCodeSelect,
  getFullFillSelect,
  getUnitCodeSelect,
  getReportList,
  downloadCPDManagementReport,
  getAgentYearList
} from "../../../../redux/pacsReducer/CPDReportReducers/ManagementReportPACSReducer";
// import { getYeaRequirementList } from "../../../../redux/pacsReducer/myProfileReducers/CPDDashboardReducer";
import { globalPromptTime10s, isPacsAdmin, isPacsPureAgent, isPacsAgentLeader, switchDate } from "../../../../utils/global";
import styles from "./styles.module.less";
import MyCPDDashboard from '../CPDDashboard/MyCPDDashboard';





const CreateForm = Form.create({
  name: "CreateManagementReportPACS_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("ManagementReportPACS", changedFields);
  },
  mapPropsToFields(props) {
    const { roleId, agentCode, unitCode } = props.userReducer;
    // console.log("userReducer:", roleId, agentCode, unitCode) // eslint-disable-line
    const isAgent = isPacsPureAgent(roleId);
    return {
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: isAgent ? unitCode : props.unitCode.value
      }),
      cpdYear: Form.createFormField({
        ...props.cpdYear,
        value: props.cpdYear.value
      }),
      trainingDateRange: Form.createFormField({
        ...props.trainingDateRange,
        value: props.trainingDateRange.value
      }),
      agentStatus: Form.createFormField({
        ...props.agentStatus,
        value: props.agentStatus.value
      }),
      contractDateRange: Form.createFormField({
        ...props.contractDateRange,
        value: props.contractDateRange.value
      }),
      fcCode: Form.createFormField({
        ...props.fcCode,
        value: isAgent ? agentCode : props.fcCode.value
      }),
      terminationDateRange: Form.createFormField({
        ...props.terminationDateRange,
        value: props.terminationDateRange.value
      }),
      fullFill: Form.createFormField({
        ...props.fullFill,
        value: props.fullFill.value
      }),
      includeShiftedHours: Form.createFormField({
        ...props.includeShiftedHours,
        value: props.includeShiftedHours.value
      }),
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, userReducer, disabledDate } = props;
  const { RangePicker } = DatePicker;
  const { roleId, roldid } = userReducer;
  const isAgent = isPacsPureAgent(roleId);
  const isAgentLeader = isPacsAgentLeader(roleId);
  const isAdmin = isPacsAdmin(roleId || roldid);

  useMount(() => {
    if (isAgentLeader || isAgent) {
      props.handleSubmit(props.form);
    }
  })

  return (
    <Spin spinning={loading}>
      {
        (isAgent || isAgentLeader) ?
          (
            <div>
              <MyCPDDashboard />
              <div className={styles.title}>{isAgent ? 'My CPD Summary' : 'Agency CPD Summary'}</div>
            </div>
          )
          : <div className={styles.title}>CPD Management Report</div>
      }

      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >

        <div className={styles.resizeCol}>
          {
            (isAgent || isAgentLeader) ? null : (

              <Form.Item label="Agent Leader" className={styles.shortItemLeft}>
                {getFieldDecorator("agentLeader", {})(
                  <Select
                    showSearch
                    showArrow
                    defaultActiveFirstOption={false}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // console.log(option) // eslint-disable-line
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                  >
                    <Option value="">All</Option>
                    {props.agentLeaderList.length
                      ? props.agentLeaderList.map(item => (
                        <Option value={item.leaderCode} key={item.leaderCode}>
                          {item.leaderName}
                        </Option>
                      ))
                      : null}
                  </Select>
                )}
              </Form.Item>

            )
          }

          {

            isAdmin || isAgentLeader ?
              (
                <Form.Item label="Unit Code" className={styles.shortItemLeft}>
                  {getFieldDecorator("unitCode", {})(
                    <Select
                      disabled={isAgent}
                      showSearch
                      showArrow
                      allowClear
                      defaultActiveFirstOption={false}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        // console.log(option) // eslint-disable-line
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      <Option value="">All</Option>
                      {props.unitCodeList.length
                        ? props.unitCodeList.map(item => (
                          <Option value={item.unitCode} key={item.unitCode}>
                            {item.unitCode}
                          </Option>
                        ))
                        : null}
                    </Select>
                  )}
                </Form.Item>
              ) : null
          }


          {
            isAdmin || isAgentLeader ?
              (
                <Form.Item label="FC Code" className={styles.shortItemLeft}>
                  {getFieldDecorator("fcCode", {})(
                    <Select
                      disabled={isAgent}
                      showSearch
                      showArrow
                      allowClear
                      defaultActiveFirstOption={false}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      <Option value="">All</Option>
                      {props.fcCodeList.length
                        ? props.fcCodeList.map(item => (
                          <Option value={item.agentCode} key={item.agentCode}>
                            {item.agentCode}
                          </Option>
                        ))
                        : null}
                    </Select>
                  )}
                </Form.Item>
              ) : null
          }


          {
            (isAgent || isAgentLeader) ?
              <Form.Item label="Year" className={styles.shortItemLeft}>
                {getFieldDecorator('cpdYear', {})(
                  <Select
                    // showSearch
                    showArrow
                  // defaultActiveFirstOption={false}
                  >
                    {
                      props.agentYearList.length ?
                      props.agentYearList.map(item => (
                          <Option value={item.year} key={item.yearId}>
                            {item.year}
                          </Option>
                        ))
                        : null
                    }
                  </Select>
                )}
              </Form.Item>
              : null
          }

          {
            isAdmin ?
              <Form.Item label="Agent Status" className={styles.shortItemLeft}>
                {getFieldDecorator("agentStatus", {})(
                  <Select
                    disabled={isAgent}
                    // showSearch
                    showArrow
                    // allowClear
                    defaultActiveFirstOption={false}
                  // optionFilterProp="children"
                  // filterOption={(input, option) => {
                  //   console.log(option) // eslint-disable-line
                  //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  // }
                  // }
                  >
                    <Option value="">All</Option>
                    {props.agentStatusList.length
                      ? props.agentStatusList.map(item => (
                        <Option value={item.value} key={item.value}>
                          {item.code}
                        </Option>
                      ))
                      : null}
                  </Select>
                )
                }
              </Form.Item>
              : null
          }
          {
            isAdmin ?
              <Form.Item label="Fulfillment Status" className={styles.shortItemLeft}>
                {getFieldDecorator("fullFill", {
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Please fill in the mandatory fields"
                  //   }
                  // ]
                })(
                  <Select
                    showArrow
                    defaultActiveFirstOption={false}
                  >
                    <Option value="">All</Option>
                    {props.fullFillList.length
                      ? props.fullFillList.map(item => (
                        <Option value={item.value} key={item.value}>
                          {item.code}
                        </Option>
                      ))
                      : null}
                  </Select>
                )}
              </Form.Item>
              : null
          }
          {
            isAdmin ?
              <Form.Item>
                {getFieldDecorator("includeShiftedHours", {
                  // rules: [
                  //   {
                  //     required: true,
                  //     message: "Please fill in the mandatory fields"
                  //   }
                  // ]
                  valuePropName: 'checked',
                  initialValue: true,
                })(<Checkbox>Include Shifted Hours</Checkbox>)}
              </Form.Item>
              : null
          }
        </div>
        {
          isAdmin ?
            <div className={`${styles.resizeCol} ${styles.halfCol}`}>
              <Form.Item label="Training Date Range" className={styles.longItemLeft}>
                {getFieldDecorator("trainingDateRange", {
                })(
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    style={{ width: "100%" }}
                    format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                    disabledDate={disabledDate}
                  />
                )}
              </Form.Item>

              <Form.Item label="Contract Date Range" className={styles.longItemLeft}>
                {getFieldDecorator("contractDateRange", {})(
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    style={{ width: "100%" }}
                    format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                  />
                )}
              </Form.Item>

              <Form.Item label="Termination Date Range" className={styles.longItemLeft}>
                {getFieldDecorator("terminationDateRange", {})(
                  <RangePicker
                    placeholder={["Start Date", "End Date"]}
                    style={{ width: "100%" }}
                    format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                  />
                )}
              </Form.Item>
            </div>
            : null
        }
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Button
          style={{ display: props.searchList.length ? "inline-block" : "none" }}
          className={styles.btn}
          type="danger"
          icon="download"
          onClick={() => {
            props.downloadCPDManagementReport();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
            minWidth: 1200
            // display: props.searchList.length ? "block" : "none"
          }}
        >
          <Table
            bordered
            // scroll={{ y: 380 }}
            scroll={{ y: 200 }}
            className={styles.ant_dev_table}
            // columns={props.columns}
            columns={props.dynamicColumns(props)}
            // onChange={pagination => {
            //   props.handleTableChange(pagination);
            // }}
            pagination={{
              pageSize: 20,
            }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            dataSource={props.calTableData(props.searchList)}
            size="small"
            rowKey="agentCode"
          />

          {
            !(isAgent || isAgentLeader) && props.searchList && props.searchList.length > 0 ? (
              <div style={{ marginTop: 20, paddingBottom: 20 }}>
                <div className={styles.markbox}>
                  <div className={styles.colorBox}>
                    <span className={`${styles.ant_row_bg_orange} ${styles.ant_mark}`} />
                    Not New Entrant (NNE) has to fulfil pro-rated mandatory CPD training hours requirement in first year of appointment
                  </div>
                </div>

                <div className={styles.colorBox}>
                  <span className={`${styles.ant_mark}`}> Fulfill = B </span>
                  Agent can bring over and fulfil current CPD requirement next year
                </div>

                <div className={styles.colorBox}>
                  <span className={`${styles.ant_mark}`}> Fulfill = E </span>
                  Agent is exempted from having to fulfil current CPD requirement this year
                </div>
              </div>
            ) : null
          }

        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { CPDReportReducers: { ManagementReportPACSReducer } },
    // PublicReducer: { loading },
    userReducer
  }) => ({
    fields: {
      ...ManagementReportPACSReducer,
    },
    // loading,
    userReducer,
    pageLoading: ManagementReportPACSReducer.pageLoading
  }),
  {
    changeVals,
    innit_data,
    getAgentStatusSelect,
    getAgentLeaderSelect,
    getFcCodeSelect,
    getFullFillSelect,
    getUnitCodeSelect,
    getReportList,
    downloadCPDManagementReport,
    getAgentYearList
  }
)
class ManagementReportPACS extends Component {
  constructor(props) {
    super(props);
    this.downloadCPDManagementReport = this.downloadCPDManagementReport.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.dynamicColumns = this.dynamicColumns.bind(this);
    const { roleId, roldid } = this.props.userReducer;
    const isAdmin = isPacsAdmin(roleId || roldid);

    const that = this;
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "No",
          dataIndex: "no",
          align: "center",
          width: 30,
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          align: "center",
          width: 60,
          // fixed: 'left',
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC Code",
          dataIndex: "agentCode",
          align: "center",
          width: 60,
          // fixed: 'left',
          render: (text, record) => {
            const { fields } = this.props;

            return (
              <Tooltip placement="top" title={text}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: record.nne === "Y" ? "orange" : "", position: "absolute", width: "100%", height: "100%" }}>
                  <Link
                    to={{
                      pathname: "/home/cpd_reports/training_history_report/search",
                      state: {
                        leaderCode: fields.agentLeader.value,
                        unitCode: fields.unitCode.value,
                        status: fields.agentStatus.value,
                        agentCode: record.agentCode,
                        fulfillmentStatus: fields.fullFill.value,
                        contractedDateStart: fields.contractDateRange.value.length ? fields.contractDateRange.value[0].format("YYYY-MM-DD") : "",
                        contractedDateEnd: fields.contractDateRange.value.length ? fields.contractDateRange.value[1].format("YYYY-MM-DD") : "",
                        terminationDateStart: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[0].format("YYYY-MM-DD") : "",
                        terminationDateEnd: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[1].format("YYYY-MM-DD") : "",
                        trainingDateStart: this.handleStartDate(fields.trainingDateRange, true),
                        trainingDateEnd: this.handleStartDate(fields.trainingDateRange, false),
                        includeShiftedHours: fields.includeShiftedHours.value ? 1 : 0,
                        cpdYear: isAdmin ? '' : fields.cpdYear.value,
                      }
                    }}
                  >
                    {text}
                  </Link>
                </div>
              </Tooltip>
            );
          }
        },
        {
          title: "Name",
          dataIndex: "userName",
          align: "center",
          width: 60,
          // fixed: 'left',
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Date of Appt.",
          dataIndex: "contractedDate",
          align: "center",
          width: 60,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agency Leader",
          dataIndex: "leaderName",
          width: 70,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        // {
        //   title: "BDM",
        //   dataIndex: "bdm",
        //   align: "center",
        //   width: 70,
        //   render: text => (
        //     <Tooltip placement="top" title={text}>
        //       {text}
        //     </Tooltip>
        //   )
        // },
      ]
    };
  }

  componentDidMount() {
    this.props.innit_data();
    this.props.getAgentLeaderSelect();
    this.props.getAgentStatusSelect();
    this.props.getFcCodeSelect();
    this.props.getFullFillSelect();
    this.props.getUnitCodeSelect();
    this.props.getAgentYearList();
    // this.props.getReportList({
    //   // bdm: 1
    //   "leadercode": "",
    //   "unitCode": "",
    //   "status": "",
    //   "agentCode": "",
    //   "fulfillmentStatus": "",
    //   "contractedDateStart": "",
    //   "contractedDateEnd": "",
    //   "terminationDateStart": "",
    //   "terminationDateEnd": "",
    //   "trainingDateStart": "",
    //   "trainingDateEnd": ""
    // });
  }


  handleStartDate(dataRange, isStartDate) {
    const { roleId, roldid } = this.props.userReducer;
    const { fields } = this.props;

    const isAgent = isPacsPureAgent(roleId || roldid)
    const isAgentLeader = isPacsAgentLeader(roleId || roldid)

    if (isAgent || isAgentLeader) {
      const year = fields.cpdYear.value;
      const start = moment(`${year}-01-01`).format("YYYY-MM-DD");
      const end = moment(`${year}-12-31`).format("YYYY-MM-DD");

      return isStartDate ? start : end;
    }

    return dataRange.value.length ? dataRange.value[0].format("YYYY-MM-DD") : "";
  }




  handleTableChange = pagination => {
    // const { current, pageSize } = pagination;
    // const { fields } = this.props;
    // this.props.getRegistrationSummaryReport({
    //   courseCode: fields.courseCode.value,
    //   courseId: fields.courseName.value,
    //   endDate: fields.reportDate.value.length
    //     ? fields.reportDate.value[1].format("YYYY-MM-DD")
    //     : "",
    //   sessionId: fields.sessionName.value,
    //   startDate: fields.reportDate.value.length
    //     ? fields.reportDate.value[0].format("YYYY-MM-DD")
    //     : "",
    //   pageSize,
    //   currentPageNumber: current
    // });
  };


  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.trainingDateRange.value.length) {
          // const end = fields.trainingDateRange.value[1].valueOf();
          // const start = fields.trainingDateRange.value[0];
          // const nowYear = moment(start).add(1, "year").valueOf();
          const start = new Date(fields.trainingDateRange.value[0]).getFullYear();
          const end = new Date(fields.trainingDateRange.value[1]).getFullYear();
          // console.log("fields:", fields) // eslint-disable-line
          if (end !== start) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }


        this.props.getReportList({
          leaderCode: fields.agentLeader.value,
          unitCode: fields.unitCode.value,
          status: fields.agentStatus.value,
          agentCode: fields.fcCode.value,
          fulfillmentStatus: fields.fullFill.value,
          contractedDateStart: fields.contractDateRange.value.length ? fields.contractDateRange.value[0].format("YYYY-MM-DD") : "",
          contractedDateEnd: fields.contractDateRange.value.length ? fields.contractDateRange.value[1].format("YYYY-MM-DD") : "",
          terminationDateStart: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[0].format("YYYY-MM-DD") : "",
          terminationDateEnd: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[1].format("YYYY-MM-DD") : "",
          trainingDateStart: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[0].format("YYYY-MM-DD") : "",
          trainingDateEnd: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[1].format("YYYY-MM-DD") : "",
          includeShiftedHours: fields.includeShiftedHours.value ? 1 : 0,
          cpdYear: fields.cpdYear.value,
        })
      }
    });
  };

  downloadCPDManagementReport() {
    const { fields } = this.props;
    this.props.downloadCPDManagementReport({
      leaderCode: fields.agentLeader.value,
      unitCode: fields.unitCode.value,
      status: fields.agentStatus.value,
      agentCode: fields.fcCode.value,
      fulfillmentStatus: fields.fullFill.value,
      contractedDateStart: fields.contractDateRange.value.length ? fields.contractDateRange.value[0].format("YYYY-MM-DD") : "",
      contractedDateEnd: fields.contractDateRange.value.length ? fields.contractDateRange.value[1].format("YYYY-MM-DD") : "",
      terminationDateStart: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[0].format("YYYY-MM-DD") : "",
      terminationDateEnd: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[1].format("YYYY-MM-DD") : "",
      trainingDateStart: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[0].format("YYYY-MM-DD") : "",
      trainingDateEnd: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[1].format("YYYY-MM-DD") : "",
      includeShiftedHours: fields.includeShiftedHours.value ? 1 : 0
    });
  }

  dynamicColumns(props) {
    const { searchList, columns } = props;
    const finalColumns = [...columns];
    // console.log("finalColumns:", finalColumns) // eslint-disable-line
    /* eslint-disable */
    if (searchList.length) {
      const attainedColumns = {
        title: "CPD Hours Attained",
        className: 'grey_bg',
        children: [
          {
            title: "Core",
            className: 'grey_bg',
            children: []
          }, {
            title: "Supplementary",
            className: 'grey_bg',
            children: []
          }
        ]
      }
      const requirementColumns = {
        title: "CPD Hours Requirement",
        className: 'more_grey_bg',
        children: [{
          title: "Core",
          className: 'more_grey_bg',
          children: []
        }, {
          title: "Supplementary",
          className: 'more_grey_bg',
          children: []
        }]
      }
      const shortFallColumns = {
        title: "CPD Hours Shortfall",
        children: [{
          title: "Core",
          children: []
        }, {
          title: "Supplementary",
          children: []
        }]
      }
      for (let q in searchList[0].attainedMap) {
        if (q.indexOf("Ethics") > -1) {
          attainedColumns.children[0].children[0] = {
            title: "Ethics",
            dataIndex: `attained_${q}`,
            align: "center",
            className: 'grey_bg',
            // width: 60,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (q.indexOf("R&R") > -1) {
          attainedColumns.children[0].children[1] = {
            title: "R&R",
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 50,
            className: 'grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (q.indexOf("ERR") > -1) {
          attainedColumns.children[0].children[2] = {
            title: "ERR",
            className: 'grey_bg',
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 50,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (q.indexOf("CIS") > -1) {
          attainedColumns.children[1].children[0] = {
            title: "CIS",
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 50,
            className: 'grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (q.indexOf("A&H") > -1) {
          attainedColumns.children[1].children[1] = {
            title: "A&H",
            className: 'grey_bg',
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 50,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }

        else if (q.indexOf("Others") > -1) {
          attainedColumns.children[1].children[2] = {
            title: "Others",
            className: 'grey_bg',
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 70,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (q.indexOf("Total") > -1) {
          attainedColumns.children[1].children[3] = {
            title: "S(TOT)",
            className: 'grey_bg',
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 70,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else {
          attainedColumns.children[attainedColumns.children.length] = {
            title: q,
            dataIndex: `attained_${q}`,
            align: "center",
            // width: 50,
            className: 'grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        // attainedColumns.children.push(
        //   {
        //     title: q,
        //     dataIndex: `attained_${q}`,
        //     align: "center",
        //     width: q === "Others" ? 70 : 50,
        //     render: text => (
        //       <Tooltip placement="top" title={text}>
        //         {text}
        //       </Tooltip>
        //     )
        //   }
        // )
      };

      for (let w in searchList[0].requirementMap) {
        if (w.indexOf("Ethics") > -1) {
          requirementColumns.children[0].children[0] = {
            title: "Ethics",
            dataIndex: `requirement_${w}`,
            align: "center",
            width: 60,
            className: 'more_grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("R&R") > -1) {
          requirementColumns.children[0].children[1] = {
            title: "R&R",
            dataIndex: `requirement_${w}`,
            align: "center",
            width: 50,
            className: 'more_grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("ERR") > -1) {
          requirementColumns.children[0].children[2] = {
            title: "ERR",
            className: 'more_grey_bg',
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 50,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("CIS") > -1) {
          requirementColumns.children[1].children[0] = {
            title: "CIS",
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 50,
            className: 'more_grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("A&H") > -1) {
          requirementColumns.children[1].children[1] = {
            title: "A&H",
            className: 'more_grey_bg',
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 50,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("Others") > -1) {
          requirementColumns.children[1].children[2] = {
            title: "Others",
            className: 'more_grey_bg',
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 70,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else if (w.indexOf("Total") > -1) {
          requirementColumns.children[1].children[3] = {
            title: "S(TOT)",
            className: 'more_grey_bg',
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 70,
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
        else {
          requirementColumns.children[requirementColumns.children.length] = {
            title: w,
            dataIndex: `requirement_${w}`,
            align: "center",
            // width: 50,
            className: 'more_grey_bg',
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        }
      };
      for (let e in searchList[0].shortFallMap) {
        if (e.indexOf("Ethics") > -1) {
          shortFallColumns.children[0].children[0] = {
            title: "Ethics",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 60,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
        else if (e.indexOf("R&R") > -1) {
          shortFallColumns.children[0].children[1] = {
            title: "R&R",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }

        else if (e.indexOf("ERR") > -1) {
          shortFallColumns.children[0].children[2] = {
            title: "ERR",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
        else if (e.indexOf("CIS") > -1) {
          shortFallColumns.children[1].children[0] = {
            title: "CIS",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
        else if (e.indexOf("A&H") > -1) {
          shortFallColumns.children[1].children[1] = {
            title: "A&H",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }

        else if (e.indexOf("Others") > -1) {
          shortFallColumns.children[1].children[2] = {
            title: "Others",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 70,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
        else if (e.indexOf("Total") > -1) {
          shortFallColumns.children[1].children[3] = {
            title: "S(TOT)",
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 70,
            // textWrap: 'word-break',
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
        else {
          shortFallColumns.children[shortFallColumns.children.length] = {
            title: e,
            dataIndex: `shortFall_${e}`,
            align: "center",
            width: 50,
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0" && record[`shortFall_${e}`] != "0.00") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        }
      };
      finalColumns.push(attainedColumns, requirementColumns, shortFallColumns,
        {
          title: "Fulfill",
          dataIndex: "fulfillmentStatus",
          align: "center",
          // width: 50,
          // fixed: 'right',
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        });
    }
    return finalColumns;
  }


  calTableData(searchList) {
    return searchList.map((item, index) => {
      return {
        no: index + 1,
        ...item
      }
    });
  }

  disabledDate(current) {
    // Can not select days for 2022 and before
    return switchDate() && current < moment("20220101")
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.pageLoading,
      userReducer: this.props.userReducer,
      columns: this.state.columns,
      downloadCPDManagementReport: this.downloadCPDManagementReport,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      dynamicColumns: this.dynamicColumns,
      calTableData: this.calTableData,
      disabledDate: this.disabledDate,
    };

    return (
      <div className={styles.container}>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24
};

export default ManagementReportPACS;
