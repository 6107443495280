import React from "react";
import moment from "moment";
import {
  Form,
  Button,
  Table,
  Empty,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Input
} from "antd";
import { connect } from "react-redux";
import styles from "./TrainingHistoryReportSearch.module.less";
import {
  getLeaderNameList,
  downloadData,
  handleQueryData,
  getFcCodeList,
  getUnitCodeList,
  getAgentStatusList,
  saveTempData,
  handleResetQueryData
} from "../../../../../redux/pacsReducer/CPDReportReducers/TrainingHistoryReportReducer";
import { isPacsPureAgent, isPacsAgentLeader, switchDate } from "../../../../../utils/global";

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

function disabledDate(current) {
  // Can not select days for 2021 and before
  return switchDate() && current < moment("20220101");
}


const { Option } = Select;
const { RangePicker } = DatePicker;
@connect(
  ({ userReducer, pacsReducer: { CPDReportReducers: { TrainingHistoryReportReducer } } }) => ({
    TrainingHistoryReportReducer, userReducer
  }),
  {
    handleQueryData,
    getLeaderNameList,
    downloadData,
    getFcCodeList,
    getUnitCodeList,
    getAgentStatusList,
    saveTempData,
    handleResetQueryData
  }
)
class TrainingHistoryReportSearch extends React.Component {
  state = {
    columns: [
      {
        title: "Unit Code",
        dataIndex: "unitCode",
        align: "center",
      },
      {
        title: "FC Code",
        align: "center",
        dataIndex: "fcCode",
      },
      {
        title: "Agent Name",
        dataIndex: "agentName",
        align: "center",
      },
      {
        title: "Contracted Date",
        dataIndex: "contractedDateV",
        align: "center",
      },
      {
        title: "Leader FC Code",
        align: "center",
        dataIndex: "leaderFcCode",
      },
      {
        title: "Channel",
        dataIndex: "channel",
        align: "center",
      },
      {
        title: "Actions",
        dataIndex: "actions",
        render: (text, record) => {
          const { userId, hasDownloadBtn } = record
          if (hasDownloadBtn === 1) {
            return (
              <div>
                {/* <span className={styles.downloadBtn} onClick={() => this.handleGotoDetail(userId)}>View</span> */}
                <span />
              </div>
            )
          }

          return (
            <div>
              <span
                className={styles.downloadBtn}
                onClick={() => this.handleGotoDetail(userId)}
              >View
              </span>
              <span className={styles.downloadBtn} onClick={() => this.handleDownload(userId)}>Download</span>
            </div>
          )
        }
      }
    ]
  };

  componentDidMount() {
    const { temporaryQueryData } = this.props.TrainingHistoryReportReducer
    const callback = (users) => {
      if (!temporaryQueryData.noRefresh) {
        const { agentCode } = this.props.location.state || {};
        const user = users && users.find(user => agentCode === user.agentCode);
        return user && this.props.saveTempData({ fcName: user.userName });
      }
    }
    this.props.getLeaderNameList();
    // this.props.getAgentStatusList();
    this.props.getUnitCodeList();
    this.props.getFcCodeList({}, callback);
    const { status, agentCode, contractedDateStart, contractedDateEnd, trainingDateStart, trainingDateEnd }
      = this.props.location.state || {};
    if (agentCode) {
      const trainingStart = trainingDateStart && moment(trainingDateStart, "YYYY-MM-DD");
      const trainingEnd = trainingDateEnd && moment(trainingDateEnd, "YYYY-MM-DD");
      const contractedStart = contractedDateStart && moment(contractedDateStart, "YYYY-MM-DD");
      const contractedEnd = contractedDateEnd && moment(contractedDateEnd, "YYYY-MM-DD");
      const changeFields = {
        agentStatus: status || "2",
        trainingDate: trainingStart ? [trainingStart, trainingEnd] : [],
        appointedRange: contractedStart ? [contractedStart, contractedEnd] : [],
        fcCode: agentCode,
      };
      const queryData = {
        ...changeFields,
        currentPageNumber: 1,
        isShowTable: true,
        trainingDateFrom: trainingDateStart,
        trainingDateTo: trainingDateEnd,
        appointedDateFrom: contractedDateStart,
        appointedDateTo: contractedDateEnd,
      };
      const { form } = this.props
      const fields = form.getFieldsValue();
      this.props.handleQueryData({ ...fields, ...queryData });
    }
    this.props.saveTempData({ noRefresh: false });
  }

  componentWillUnmount() {
    const { temporaryQueryData } = this.props.TrainingHistoryReportReducer
    if (!temporaryQueryData.noRefresh) {
      this.props.handleResetQueryData()
    }
  }

  handleFcCodeChange = (value, option) => {
    const { props } = option || {};
    const { username } = props || {};
    this.props.saveTempData({ fcName: username })
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          trainingDate,
          appointedRange
        } = values;
        const [start, end] = trainingDate || [];
        const trainingDateFrom = start && moment(start).format("YYYY-MM-DD");
        const trainingDateTo = end && moment(end).format("YYYY-MM-DD");

        const [from, to] = appointedRange || [];
        const appointedDateFrom = from && moment(from).format("YYYY-MM-DD");
        const appointedDateTo = to && moment(to).format("YYYY-MM-DD");
        const queryData = {
          ...values,
          trainingDateFrom,
          trainingDateTo,
          appointedDateFrom,
          appointedDateTo,
          currentPageNumber: 1,
          isShowTable: true
        }

        this.props.saveTempData({ isShowTable: true })
        this.props.handleQueryData(queryData);
      }
    });
  };

  handleTableChange = pagination => {
    const { current } = pagination;

    const {
      temporaryQueryData
    } = this.props.TrainingHistoryReportReducer;
    this.props.handleQueryData({
      ...temporaryQueryData,
      currentPageNumber: current
    });
  };

  handleDownload = (userId) => {
    const { temporaryQueryData } = this.props.TrainingHistoryReportReducer
    this.props.downloadData({
      ...temporaryQueryData,
      userId
    });
  };

  handleGotoDetail = async (userId) => {
    const { temporaryQueryData } = this.props.TrainingHistoryReportReducer
    const data = {
      ...temporaryQueryData, userId, appointedRange: undefined, trainingDate: undefined
    }
    await this.props.saveTempData({ noRefresh: true })
    await this.props.history.push({
      pathname: '/home/cpd_reports/training_history_report/detail',
      state: data
    })
  };

  handleTempData = (fields, condition) => {
    const { temporaryQueryData } = this.props.TrainingHistoryReportReducer
    return temporaryQueryData[fields] || condition
  }

  render() {
    const {
      form: { getFieldDecorator },
      userReducer,
      TrainingHistoryReportReducer: {
        trainingHistoryData: { data: dataList, total },
        leaderNameList,
        agentStatusList,
        courseStatusList,
        sessionStatusList,
        agentTypeList,
        channelList,
        unitCodeList,
        tableLoading,
        downloadLoading,
        fcCodeList,
        temporaryQueryData
      }
    } = this.props;
    const { userName, roleId, agentCode, unitCode } = userReducer
    const { columns } = this.state;
    const isAgent = isPacsPureAgent(roleId);
    const isAgentLeader = isPacsAgentLeader(roleId);
    const {
      currentPageNumber,
      fcName,
    } = temporaryQueryData

    return (
      <div className={styles.container}>
        <Spin spinning={downloadLoading}>
          <div className={styles.heading}>Training History Report</div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              {
                (isAgent || isAgentLeader) ? null :
                  (
                    <Col {...flexCol}>
                      <Form.Item label="Leader Name">
                        {getFieldDecorator("learnerName", {
                          initialValue: this.handleTempData("learnerName", '')
                        })(
                          <Select optionFilterProp="children" showSearch allowClear>
                            <Option value="">All</Option>
                            {leaderNameList.map(elt => (
                              <Option value={elt.agentCode} key={elt.agentCode}>
                                {elt.leaderName}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )
              }

              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: this.handleTempData("unitCode", isAgent ? unitCode : '')
                  })(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      disabled={isAgent}
                      defaultActiveFirstOption
                    >
                      <Option value=''>All</Option>
                      {unitCodeList.filter(elt => elt.unitCode).map(elt => (
                        <Option value={elt.unitCode} key={elt.unitCode}>
                          {elt.unitCode}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Agent Status">
                  {getFieldDecorator("agentStatus", {
                    initialValue: this.handleTempData("agentStatus", agentStatusList[0].code)
                  })(
                    <Select disabled={isAgent}>
                      {agentStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Training Date">
                  {getFieldDecorator("trainingDate", {
                    rules: [
                      {
                        validator: async (rule, value = []) => {
                          const [start, end] = value
                          if (start && end) {
                            const isSameYear = moment(start).isSame(end, 'year')
                            if (!isSameYear) {
                              throw new Error('Maximal range is 1 year');
                            }
                          }
                        },
                      }
                    ],
                    initialValue: this.handleTempData("trainingDate", [])
                  })(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                      disabledDate={disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="FC Code">
                  {getFieldDecorator("fcCode", {
                    initialValue: this.handleTempData("fcCode", isAgent ? agentCode : '')
                  })(
                    isAgent ?
                      (<Input disabled />)
                      :
                      (
                        <Select
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          onChange={this.handleFcCodeChange}
                        >
                          <Option value=''>All</Option>
                          {fcCodeList.map(elt => (
                            <Option value={elt.agentCode} key={elt.agentCode} username={elt.userName}>
                              {elt.agentCodeValue}
                            </Option>
                          ))}
                        </Select>
                      )
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="FC Name">
                  {getFieldDecorator("fcName", {
                    initialValue: this.handleTempData("fcName", isAgent ? userName : fcName)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Appointed Range">
                  {getFieldDecorator("appointedRange", {
                    initialValue: this.handleTempData("appointedRange", [])
                  })(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                      disabledDate={disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Agent Type">
                  {getFieldDecorator("agentType", {
                    initialValue: this.handleTempData("agentType", agentTypeList[0].code)
                  })(
                    <Select allowClear>
                      {agentTypeList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Course Status">
                  {getFieldDecorator("courseStatus", {
                    initialValue: this.handleTempData("courseStatus", courseStatusList[0].code)
                  })(
                    <Select>
                      {courseStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Session Status">
                  {getFieldDecorator("sessionStatus", {
                    initialValue: this.handleTempData("sessionStatus", sessionStatusList[0].code)
                  })(
                    <Select>
                      {sessionStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {
                (isAgentLeader || isAgent) ? null :

                  (
                    <Col {...flexCol}>
                      <Form.Item label="Channel">
                        {getFieldDecorator("channel", {
                          initialValue: this.handleTempData("channel", channelList[0].code)
                        })(
                          <Select
                            optionFilterProp="children"
                            showSearch
                            allowClear
                            disabled
                          >
                            {channelList.map(elt => (
                              <Option value={elt.code} key={elt.code}>
                                {elt.value}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  )
              }
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={dataList || []}
            pagination={{
              total,
              pageSize: 10,
              current: currentPageNumber
            }}
            size="small"
            loading={tableLoading}
            onChange={this.handleTableChange}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            className={styles.ant_dev_table}
            rowKey={(elt, ind) => ind}
          />


        </Spin>
      </div>
    );
  }
}

const TrainingHistoryReportForm = Form.create()(TrainingHistoryReportSearch);

export default TrainingHistoryReportForm;
