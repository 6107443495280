import { get } from "../utils/request";

const GET_MUNULIST = "GET_MUNULIST";
const CHANGE_MUNULIST = "CHANGE_MUNULIST";
const SAVE = "SAVE";
const HANDLEMYPROFILE = "HANDLEMYPROFILE";
const initState = {
  user: "",
  initMenuKey: "",
  initSideMenuKey: "",
  menuButtonList: [],
  homeMenuList: [],
  sideMenuList: [],
  buttonList: [],
  myProfileList: [],
  menuLoading: false
};

// reducer
export default function homeReducer(state = initState, action) {
  switch (action.type) {
    case GET_MUNULIST:
      return { ...state, ...action.payload };
    case CHANGE_MUNULIST:
      return { ...state, ...action.payload };
    case SAVE:
      return { ...state, ...action.payload };
    case HANDLEMYPROFILE:
      return { ...state, sideMenuList: state.myProfileList };
    default:
      return state;
  }
}

export function getMenuList(tabKey, sideKey) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        menuLoading: true
      }
    })
    get("/user/getMenusAndButtonsByRoleId")
      .then(res => {
        const { menuButtonList, buttonList, isMyProfileList } = res.data;
        const homeMenuList = menuButtonList.filter(elt => !elt.parentId);
        homeMenuList.map(elt => {
          elt.children = [];
          menuButtonList.map(item => {
            if (item.parentId === elt.menuId) {
              elt.children.push(item);
            }
          });
        });

        let sideMenuObj = {};
        if (tabKey) {
          sideMenuObj = homeMenuList.find(item => {
            const { keyPath } = item;
            return tabKey === (keyPath ? keyPath.toLowerCase() : "");
          })
          if (!sideMenuObj) {
            const path = isMyProfileList.length ? isMyProfileList[0].path : ''
            const [x, y] = path.split("/").filter(i => i); // eslint-disable-line
            if (y === tabKey) {
              sideMenuObj = {
                children: isMyProfileList
              }
            } else {
              sideMenuObj = { children: [] }
            }
          }
        }

        dispatch({
          type: GET_MUNULIST,
          payload: {
            menuButtonList,
            homeMenuList,
            sideMenuList: sideMenuObj.children || [],
            buttonList: buttonList || [],
            initMenuKey: tabKey,
            initSideMenuKey: sideKey,
            myProfileList: isMyProfileList || [],
            menuLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            menuLoading: false
          }
        })
      });
  };
}

export function getMenuListForToken(cb) {
  return () => {
    get("/user/getMenusAndButtonsByRoleId", { type: "checkToken" })
      .then(() => {
        cb();
      })
      .catch(() => {

      });
  };
}

export function changeMenuList(tabKey, homeMenuList) {
  return dispatch => {
    let sideMenuObj = {};
    if (tabKey) {
      sideMenuObj =
        homeMenuList.find(item => {
          const { keyPath } = item;
          return tabKey === (keyPath ? keyPath.toLowerCase() : "");
        }) || {};
    }

    dispatch({
      type: CHANGE_MUNULIST,
      payload: {
        sideMenuList: sideMenuObj.children || []
      }
    });
  };
}

export function handleHighLight(tabKey, sideKey) {
  return dispatch =>
    dispatch({
      type: SAVE,
      payload: {
        initMenuKey: tabKey,
        initSideMenuKey: sideKey
      }
    });
}

export function handleMyProfile() {
  return dispatch =>
    dispatch({
      type: HANDLEMYPROFILE,
      payload: {}
    });
}

export function handleReset() {
  return dispatch =>
    dispatch({
      type: SAVE,
      payload: {
        menuButtonList: [],
        homeMenuList: [],
        sideMenuList: [],
        buttonList: [],
        myProfileList: []
      }
    });
}
