import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Row, Col, Spin, Icon } from "antd";
import styles from "./CreateCandidate.module.less";
import {
  handleSubmit,
  getLeaderNameByUnitCode,
  handleReset
} from "../../../../../redux/pacsReducer/systemSettingsReducers/CandidateReducer";

// const { Option } = Select
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const formLayout2 = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pacsReducer: { systemSettingsReducers: { CandidateReducer, userManagementReducer: { rankList } } } }) => ({
    CandidateReducer,
    rankList
  }),
  { handleSubmit, getLeaderNameByUnitCode, handleReset }
)
class CandidateForm extends React.Component {
  componentWillUnmount() {
    this.props.handleReset();
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form, history } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const callback = () =>
          history.push("/home/system_settings/user_management/index");
        this.props.handleSubmit({ ...values, channel: "AD" }, callback);
      }
    });
  };

  handleEmailChange = e => {
    this.props.form.setFieldsValue({
      loginAccount: e.target.value
    });
  };

  handleUnitcodeChange = e => {
    this.props.form.resetFields(["leaderName"]);
    this.props.getLeaderNameByUnitCode({ unitCode: e.target.value });
  };

  render() {
    const {
      form: { getFieldDecorator },
      CandidateReducer: { createLoading, leaderName },
    } = this.props;

    return (
      <div className={styles.container}>
        <Spin spinning={createLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            Create Candidate
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: "Candidate"
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: "PACS"
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginAccount", {})(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("userName", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      }
                    ]
                  })(
                    <Input onChange={this.handleEmailChange} />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Banker Employee ID" {...formLayout2}>
                  {getFieldDecorator("bankEmployeeID", {
                    initialValue: "N/A"
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC">
                  {getFieldDecorator("nric", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input onChange={this.handleUnitcodeChange} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Leader Name">
                  {getFieldDecorator("leaderName", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ],
                    initialValue: leaderName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactNumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={styles.submitBtn}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const CreateCandidate = Form.create()(CandidateForm);

export default CreateCandidate;
