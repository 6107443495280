import { notification } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { myProfile } from "../../userReducer";
import { loading } from "../../PublicReducer";

const initState = {
  role: {
    value: ""
  },
  bu: {
    value: ""
  },
  userName: {
    value: ""
  },
  email: {
    value: ""
  },
  contactNum: {
    value: ""
  },
  account: {
    value: ""
  }
};

// reducer
export default function CPDDashboardReducer(state = initState, action) {
  switch (action.type) {
    case "CPDDashboard":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function saveProfile(payload, cb) {
  return dispatch => {
    dispatch(loading(true));
    post("/login/saveMyProfile", payload)
      .then(res => {
        if (res.success) {
          dispatch(myProfile(payload));
          cb();
          setTimeout(() => {
            notification.success({
              message: "Saved successfully",
              duration: globalPromptTime10s
            });
          }, 500);
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          setTimeout(() => {
            dispatch(loading(false));
          }, 500);
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
