import React, { Component } from "react";
import { Form, notification, Spin } from "antd";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import qstring from "query-string";
import TCpage from "./TCpage";
import {
  login,
  forgetPsd,
  send_email,
  back,
  submit_login,
  changeVals,
  reset_password,
  init_password,
  initPsd,
  setPsd,
  check_code,
  changePassword
} from "../../redux/loginReducer";
import { init_data } from "../../redux/pambReducer/attendanceReducers/CourseFinderReducer";
import styles from "./Login.module.less";
import { boxright, boxleft, bg, bg2 } from "../../images/index";
import LoginBox from "./components/LoginBox.component";
import SetEmail from "./components/SetEmail.component";
import SetPsd from "./components/SetPsd.component";
import MobileSetPsd from "./components/mobileSetPsd.component";
import MobileLogin from "./components/mobileLogin.component";
import MobileSetEmail from "./components/mobileSetEmail.component";
import { globalPromptTime10s } from "../../utils/global";
import { getUrlParam } from "../../utils";
import { getMenuListForToken } from "../../redux/homeReducer";
import { originalUrlPrefix } from "../../utils/config";

const LOGIN_CONFIG = {
  admin: "ad",
  pacs: "both",
  pamb: "ad",
  phkl: "ad",
  pluk: "ad",
  pcla: "ad",
};

const AD_URLS = {
  admin: `${originalUrlPrefix.admin}/login/oauth2/admin`,
  pacs: `${originalUrlPrefix.admin}/login/oauth2/pacs`,
  pamb: `${originalUrlPrefix.admin}/login/oauth2/pamb`,
  phkl: `${originalUrlPrefix.admin}/login/oauth2/phkl`,
  pluk: `${originalUrlPrefix.admin}/login/oauth2/pluk`,
  pcla: `${originalUrlPrefix.admin}/login/oauth2/pcla`,
};
@connect(
  state => ({
    loginType: state.loginReducer.loginType,
    psdType: state.loginReducer.psdType,
    expired: state.loginReducer.expired,
    changePsd: state.loginReducer.changePsd,
    account: state.loginReducer.account,
    password: state.loginReducer.password,
    sendEmail: state.loginReducer.sendEmail,
    loginLoading: state.loginReducer.loginLoading,
    aticode: state.loginReducer.aticode,
    checkEmail: state.loginReducer.checkEmail,
    login_fields: { ...state.loginReducer.login_fields },
    setPsd_fields: { ...state.loginReducer.setPsd_fields },
    setEmail_fields: { ...state.loginReducer.setEmail_fields }
  }),
  {
    login,
    forgetPsd,
    send_email,
    back,
    submit_login,
    changeVals,
    reset_password,
    initPsd,
    init_password,
    setPsd,
    check_code,
    getMenuListForToken,
    changePassword,
    attendanceInit: init_data,
    goChangePsd: (loginAccount) => {
      return {
        type: "CHANGEPSD",
        payload: loginAccount
      }
    }
  }
)
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinning: false,
      errMsg: {
        title: "",
        type: ""
      },
      innerWidth: "",
      loginType: "ad",
      bu: ""
    };
  }

  componentDidMount() {
    this.getBu();
    window.addEventListener("resize", this.handleResize.bind(this));
    this.setState(() => ({
      innerWidth: window.innerWidth
    }));

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("changePsd") > -1
    ) {
      const loginAccount = this.props.location.search.split("=")[1];
      this.props.goChangePsd(loginAccount);
    }

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("forgetPsd") > -1
    ) {
      this.props.forgetPsd();
    }

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("initPsd") > -1
    ) {
      this.props.initPsd(this.props);
    }

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("setPsd") > -1
    ) {
      this.props.setPsd(this.props);
    }

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("token") > -1
    ) {
      const { search } = this.props.location;
      const { token, refreshToken, country } = getUrlParam(search);
      const notfound = "TMS_USER_NOT_FOUND";
      const failed = "AUTHENTICATION_FAILED";
      const accountDisabled = "ACCOUNT_DISABLED";
      if (token === notfound || token === failed || token === accountDisabled) {
        notification.error({
          message: "Single sign on failed, maybe your account was disabled or you're not allowed to access RTMS.",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
      } else {
        sessionStorage.setItem("user", token);
        sessionStorage.setItem("refreshToken", refreshToken);
        sessionStorage.setItem("buCountry", country);
        const callback = () => this.props.history.push("/home");
        this.props.getMenuListForToken(callback);
      }
    }

    if (
      this.props.location.search &&
      this.props.location.search.indexOf("loginChannel") > -1
    ) {
      const { search } = this.props.location;
      const { loginChannel, redirectUrl } = getUrlParam(search);
      sessionStorage.setItem("loginChannel", loginChannel);
      sessionStorage.setItem("PSERedirectUrl", redirectUrl);
    } else {
      sessionStorage.removeItem("loginChannel");
      sessionStorage.removeItem("PSERedirectUrl");
    }
  }

  getBu = () => {
    // eslint-disable-next-line no-console
    console.log(this.props.location.pathname)
    const bu = (/\/(\w+)\/login/.exec(this.props.location.pathname))[1]
    // eslint-disable-next-line no-console
    console.log({ bu })
    if (bu) {
      this.setState(() => ({
        bu
      }));
    }
  }

  handleResize = e => {
    this.setState(() => ({
      innerWidth: e.target.innerWidth
    }));
  };

  handleSubmit = (form, type) => {
    this.props.attendanceInit();
    this.setState({
      errMsg: {
        title: "",
        type: ""
      }
    });
    switch (type) {
      case "submit":
        const { login_fields } = this.props;
        this.props.submit_login({
          loginAccount: login_fields.username.value,
          password: login_fields.password.value
        });
        break;
      case "forget_password":
        const { setPsd_fields } = this.props;
        if (this.props.changePsd) {
          if (!setPsd_fields.oldPassword.value) {
            this.setState({
              errMsg: {
                title: "You have entered an invalid password",
                type: "3"
              }
            });
            return;
            // const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
            // const reg8 = /^8{4,15}$/;
            // if (!reg.test(setPsd_fields.oldPassword.value) && !reg8.test(setPsd_fields.oldPassword.value)) {
            //   this.setState({
            //     errMsg: {
            //       title: `You have entered an invalid password`,
            //       type: "3"
            //     }
            //   });
            //   return;
            // }
          }
          // else {
          //   this.setState({
          //     errMsg: {
          //       title: "You have entered an invalid password",
          //       type: "3"
          //     }
          //   });
          //   return;
          // }
        }
        if (setPsd_fields.password.value) {
          const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
          if (!reg.test(setPsd_fields.password.value)) {
            this.setState({
              errMsg: {
                title: `You have entered an invalid password`,
                type: "1"
              }
            });
            return;
          }
        } else {
          this.setState({
            errMsg: {
              title: "You have entered an invalid password",
              type: "1"
            }
          });
          return;
        }
        if (setPsd_fields.repassword.value) {
          const reg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;
          if (!reg.test(setPsd_fields.repassword.value)) {
            this.setState({
              errMsg: {
                title: `You have entered an invalid password`,
                type: "2"
              }
            });
            return;
          }
        } else {
          this.setState({
            errMsg: {
              title: "You have entered an invalid password",
              type: "2"
            }
          });
          return;
        }
        if (setPsd_fields.password.value === setPsd_fields.repassword.value) {
          if (this.props.psdType === "init") {
            this.props.init_password({
              loginAccount: this.props.account,
              password: setPsd_fields.password.value,
              passwordAgain: setPsd_fields.repassword.value,
              atiCode: this.props.aticode
            });
          } else if (this.props.psdType === "reset") {
            if (this.props.changePsd) {
              this.props.changePassword({
                loginAccount: this.props.account,
                newPassword: setPsd_fields.password.value,
                newPasswordAgain: setPsd_fields.repassword.value,
                oldPassword: setPsd_fields.oldPassword.value
              }, this.state.innerWidth);
            } else {
              this.props.reset_password({
                loginAccount: this.props.account,
                password: setPsd_fields.password.value,
                passwordAgain: setPsd_fields.repassword.value,
                atiCode: this.props.aticode
              });
            }
          }
        } else {
          this.setState({
            errMsg: {
              title: "Please keep the same password",
              type: ""
            }
          });
          return;
        }
        break;
      case "send_email":
        const { setEmail_fields } = this.props;
        this.props.send_email({
          loginAccount: setEmail_fields.email.value,
          subject: this.props.loginType
        });
        break;
      default:
        break;
    }

    form.validateFields(null, { first: true }, err => {
      if (!err) {
      }
    });
  };

  getFrom = location => {
    location = location || this.props.location;
    const query = qstring.parse(location.search);
    return query.from || "/home";
  };

  showComponent(type) {
    const all = {
      changeVals: this.props.changeVals,
      forgetPsd: this.props.forgetPsd,
      back: this.props.back,
      psdType: this.props.psdType,
      handleSubmit: this.handleSubmit,
      sendEmail: this.props.sendEmail,
      checkEmail: this.props.checkEmail,
      spinning: this.state.spinning,
      loginLoading: this.props.loginLoading,
      errMsg: this.state.errMsg,
      changePsd: this.props.changePsd,
      expired: this.props.expired
    };
    switch (type) {
      case "login":
        if (this.state.innerWidth <= 414) {
          return <MobileLogin {...all} {...this.props.login_fields} bu={this.state.bu} type={LOGIN_CONFIG[this.state.bu]} adUrl={AD_URLS[this.state.bu]} />;
        }
        return <LoginBox {...all} {...this.props.login_fields} type={LOGIN_CONFIG[this.state.bu]} adUrl={AD_URLS[this.state.bu]} />;

      case "forget_password":
        if (this.state.innerWidth <= 414) {
          return <MobileSetEmail {...all} {...this.props.setEmail_fields} />;
        }

        return <SetEmail {...all} {...this.props.setEmail_fields} />;

      case "set_password":
        if (this.state.innerWidth <= 414) {
          return <MobileSetPsd {...all} {...this.props.setPsd_fields} />;
        }

        return <SetPsd {...all} {...this.props.setPsd_fields} />;

      case "change_password":
        if (this.state.innerWidth <= 414) {
          return <MobileSetPsd {...all} {...this.props.setPsd_fields} />;
        }
        return <SetPsd {...all} {...this.props.setPsd_fields} />;
      default:
        return null;
    }
  }

  render() {
    const from = this.getFrom();
    const { loginType } = this.props;
    const user = sessionStorage.getItem("user");
    if (user) {
      return <Redirect to={from} />;
    }
    return (
      <div className={`${styles.wrapper} ${
        this.state.innerWidth <= 414 ? styles.noPadding : ""
        }`}
      >
        <div
          className={`${styles.loginContainer} ${
            this.state.innerWidth <= 414 ? styles.mSpin : ""
            } ${this.state.innerWidth <= 768 && this.state.innerWidth > 414 ? styles.padSpin : ""}
            ${
            this.state.innerWidth <= 414 ? styles.noPadding : ""
            }
          `}
        >
          {this.state.innerWidth > 768 ? (
            <div className={styles.left}>
              <div
                className={styles.rightRedBox}
                style={{ background: `url(${boxleft})` }}
              />
              <div
                className={styles.bgBox}
                style={{
                  background: `url(${bg}) no-repeat`,
                  backgroundSize: "cover"
                }}
              />
              <div className={styles.title}>
                Regional Training Management System
              </div>
            </div>
          ) : null}

          {
            this.state.innerWidth <= 768 && this.state.innerWidth > 414 ?
              <div className={styles.mBGBox} style={setBg(bg2, "cover")}></div>
              : null
          }

          {
            this.state.innerWidth <= 768 && this.state.innerWidth > 414 ?
              <div className={styles.padBg}></div>
              : null
          }

          <Spin
            tip="Loading..."
            size="large"
            spinning={this.props.loginLoading}
            delay={100}
          >
            {" "}
            {this.showComponent(loginType)}
          </Spin>

          {this.state.innerWidth > 768 ? (
            <div
              className={styles.rightRedBox}
              style={{ background: `url(${boxright})` }}
            />
          ) : null}
        </div>
        <div className={styles.tcBox}>
          <TCpage />
        </div>

      </div>
    );
  }
}
const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});
const LoginForm = Form.create({ name: "normal_login" })(Login);

export default LoginForm;
