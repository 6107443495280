import React, { Component } from "react";
import { Form, Select, Button, Row, Col } from "antd";
import styles from "../index.module.less";
import { formLayout, flexCol, flexRow } from '../utils';
import withConnector from '../../connector';
import { searchFileds } from './searchFileds';

const { Option } = Select;

@withConnector
class SearchBar extends Component {
  constructor(props) {
    super(props);

    const { rolerList } = this.props.userManagementReducer;
    const role = (rolerList && rolerList[0]) || {};
    const { bu: buRole, roleid: roleId, code } = role;

    this.state = {
      bu: buRole,
      roleId,
      roleCode: code,
      displaySearchCriteria: null,
      search: {}
    };
  }

  componentDidMount() {
    this.updateSearchFields();
  }

  updateSearchFields = () => {
    this.clearSeachFields(() => {
      this.setState({
        displaySearchCriteria: this.displaySearchFieldByRole()
      });
    });
  }

  clearSeachFields = (cb) => {
    this.setState({
      displaySearchCriteria: null,
      search: {}
    }, cb);
  }

  handleRoleChange = (val, option) => {
    this.props.saveFields('roleCode', option.props.code);
    this.props.saveFields('bu', option.props.bu);
    this.props.saveFields('roleId', val);
    this.setState({
      bu: option.props.bu,
      roleId: val,
      roleCode: option.props.code
    }, () => {
      this.updateSearchFields();
    });
  };

  handleSaveField = (name, value) => {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        ...{
          [name]: value
        }
      }
    }));
  }

  displaySearchFieldByRole() {
    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;
    const { rolerList } = this.props.userManagementReducer;
    const role = (rolerList && rolerList[0]) || {};
    const { bu: buRoleDefault, code: codeDefault, roleid: roleIdDefault } = role;

    const { roleCode, bu, roleId } = fieldsData;

    this.props.saveFields('roleCode', roleCode || codeDefault);
    this.props.saveFields('bu', bu || buRoleDefault);
    this.props.saveFields('roleId', roleId || roleIdDefault);

    const { Name, NameAdmin, accountStatusAdmin, agentCode, agentStatus, hkid, otherId, staffId, loginAccount, email } = searchFileds;
    switch (roleCode || codeDefault) {
      case 'PHKLADCourseAdmin':
      case 'PHKLBDCourseAdmin':
      case 'PHKLADMCourseAdmin':
      case 'PHKLPDCourseAdmin':
        return [NameAdmin, accountStatusAdmin];
        break;
      case 'PHKLADCandidates':
        return [Name, agentCode, agentStatus, hkid, loginAccount];
        break;
      // ADM Channel
      case 'PHKLADMTrainer':
      case 'PHKLADMAgentsTrainer':
      case 'PHKLADMCandidates':
      case 'PHKLADMAgents':
        return [Name, agentCode, agentStatus, otherId, loginAccount, email];
        break;
      // PD Channel
      case 'PHKLPDTrainer':
      case 'PHKLPDAgents':
      case 'PHKLPDCandidates':
        return [Name, agentCode, agentStatus, staffId, loginAccount, email];
        break;
      default:
        return [Name, agentCode, agentStatus, hkid, loginAccount, email];
        break;
    }
  }

  render() {
    const {
      form: { getFieldDecorator },
      userManagementReducer: {
        rolerList,
        usefulTemporaryData: {
          fieldsData,
        }
      },
      search
    } = this.props;
    const { displaySearchCriteria } = this.state;
    const self = this;
    const { searchData } = fieldsData;

    return (
      <div className="search-bar">
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("roleId", {
                  initialValue:
                    fieldsData.roleId
                })(
                  <Select
                    placeholder="Select a option"
                    onChange={(val, option) => {
                      self.handleRoleChange(val, option)
                    }}
                  >
                    {rolerList.map(elt => (
                      <Option
                        value={elt.roleid}
                        key={elt.roleid}
                        bu={elt.bu}
                        name={elt.name}
                        code={elt.code}
                      >
                        {elt.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {
              displaySearchCriteria && displaySearchCriteria.map(item => {
                const Component = item.component;

                return (
                  <Component
                    {...item.props}
                    getFieldDecorator={getFieldDecorator}
                    handleSaveField={(value) => { this.handleSaveField(item.props.name, value) }}
                    key={item.key}
                    initialValue={(searchData && searchData[item.props.name]) || ''}
                  />
                )
              })
            }

          </Row>
        </Form>

        <div className={styles.serachBox}>
          <Button onClick={search} className={styles.ant_btn}>
            Search
          </Button>
        </div>
      </div>
    )
  }
}

export default SearchBar;
