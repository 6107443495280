import React from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Form, Table, Divider, Popconfirm, Button, Icon } from "antd";
import {
  getDataByYear,
  handleSubmit,
  addARow,
  getTypeBycateGory,
  removeData
} from "../../../../redux/plukReducer/CPDSettingsReducers/requirementSettingReducer";
import EditableCell from './EditableCell'
import { randnum } from '../../../../utils'

import styles from './RequirementSettingPacsEdit.module.less'

const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

const flexCol = {
  span: 8
};

@connect(
  ({ plukReducer: { CPDSettingsReducers: { requirementSettingReducer, commonReducer } } }) => ({ requirementSettingReducer, commonReducer }),
  { getDataByYear, handleSubmit, addARow, getTypeBycateGory, removeData }
)

class Formes extends React.Component {
  state = {
    editingKey: '',
    canAddRow: true,
    tempYearId: '',
    tempYearName: '',
    isEditAction: false,
    columns: [
      {
        title: "CPD Category",
        dataIndex: "cpdcategory",
        align: "center",
        ellipsis: true,
        editable: true,
      },
      {
        title: "CPD Type",
        dataIndex: "cpdtype",
        align: "center",
        ellipsis: true,
        editable: true,
        render: (text, record) => {
          const { cpdTypeMap = {}, cpdtype } = record
          return <span>{cpdTypeMap[cpdtype]}</span>
        }
      },
      {
        title: "Minimum Hours",
        dataIndex: "minimumHours",
        align: "center",
        ellipsis: true,
        editable: true,
      },
      {
        title: "Maximum Hours",
        dataIndex: "maximumHours",
        align: "center",
        ellipsis: true,
        editable: true,
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (text, record) => {
          const { editingKey } = this.state;
          const { form } = this.props
          const editable = this.isEditing(record);
          return editable ?
            (
              <div>
                <span
                  onClick={() => this.saveAndUpdate(form, record)}
                  style={{ marginRight: 8 }}
                  className={styles.spanCss}
                >
                  Save
                </span>
                <Popconfirm
                  title={record.custormType === 'new' ? 'Sure to delete?' : "Sure to cancel?"}
                  onConfirm={() => this.handleCancel(record, form)}
                  overlayClassName={styles.ant_dev_popcss}
                >
                  <span className={styles.spanCss}>Cancel</span>
                </Popconfirm>
              </div>
            ) : (
              <div>
                <a
                  disabled={editingKey !== ''}
                  onClick={() => this.handleEdit(record.yearReqId)}
                >
                  Edit
                </a>
                <Divider type="vertical" />
                <Popconfirm
                  title="Sure to delete？"
                  onConfirm={() => this.handleDelete(record.yearReqId)}
                  okText="Yes"
                  cancelText="No"
                  overlayClassName={styles.ant_dev_popcss}
                >
                  <a disabled={editingKey !== ''}>Delete</a>
                </Popconfirm>
              </div>
            )
        }
      },

    ]
  }

  componentDidMount() {
    const { commonReducer: { plukCdpYearList } } = this.props;
    const yearId = plukCdpYearList.length ? plukCdpYearList[0].yearId : ""
    const yearName = plukCdpYearList.length ? plukCdpYearList[0].year : ""
    const { state = {} } = this.props.location
    const { yearId: id } = state
    const theOne = plukCdpYearList.find(elt => elt.yearId === id)
    const name = theOne ? theOne.year : ''
    this.props.getDataByYear(id || yearId)
    this.setState({ tempYearId: id || yearId, tempYearName: name || yearName })
  }

  isEditing = record => {
    return record.yearReqId === this.state.editingKey;
  }

  getCpdType = (val) => {
    const { form } = this.props
    form.resetFields('cpdtype')
    this.props.getTypeBycateGory(val)
  }

  handleCancel = (record) => {
    const { yearReqId, custormType } = record || {}
    if (custormType === 'new') {
      const { requirementSettingReducer: { dataSourceList } } = this.props
      const index = dataSourceList.findIndex(item => yearReqId === item.yearReqId);
      if (index > -1) {
        dataSourceList.splice(index, 1)
        this.props.addARow(dataSourceList)
      }
    }

    const { canAddRow } = this.state
    this.setState({ editingKey: '', canAddRow: !canAddRow, isEditAction: false });
  };


  handleEdit = (key) => {
    const { canAddRow } = this.state
    this.setState({ editingKey: key, canAddRow: !canAddRow, isEditAction: true });
  }

  handleDelete = (id) => {
    const { tempYearId } = this.state
    const callback = () => {
      this.props.getDataByYear(tempYearId)
    }
    this.props.removeData({ yearReqId: id, yearId: tempYearId }, callback)
  }

  handleAddRow = () => {
    const { requirementSettingReducer: { dataSourceList } } = this.props;
    const { canAddRow } = this.state
    const newData = {
      cpdcategory: "",
      cpdtype: "",
      minimumHours: "",
      maximumHours: "",
      yearReqId: randnum(),
      custormType: 'new'
    };
    dataSourceList.push(newData)
    this.props.addARow(dataSourceList)
    this.setState({ editingKey: newData.yearReqId, canAddRow: !canAddRow })
  };

  saveAndUpdate = (form, record) => {
    form.validateFields((error, row) => {
      if (error) {
        return;
      }
      const { tempYearId, canAddRow } = this.state
      const callback = () => {
        this.setState({ editingKey: '', canAddRow: !canAddRow })
        this.props.getDataByYear(tempYearId)
      }
      const {
        cpdcategory,
        cpdtype,
        minimumHours,
        maximumHours
      } = row

      const subData = {
        cpdcategory,
        cpdtype,
        minimumHours,
        maximumHours,
        yearReqId: record.custormType ? undefined : record.yearReqId,
        yearId: tempYearId
      }
      this.props.handleSubmit(subData, callback)
    });
    this.setState({ isEditAction: false })
  }

  render() {
    const {
      commonReducer: { plukCdpYearList, cpdCategoryList },
      form: { getFieldDecorator },
      requirementSettingReducer: { dataSourceList, tableLoading, cpdTypeList }
    } = this.props
    const { canAddRow, tempYearName, isEditAction, tempYearId } = this.state
    const components = {
      body: {
        cell: EditableCell
      },
    };
    const columns = this.state.columns.map(col => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          getFieldDecorator,
          cpdCategoryList,
          cpdTypeList,
          form: this.props.form,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: this.isEditing(record),
          getcpdtype: this.getCpdType,
          isEditAction,
        }),
      };
    });

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() =>
              this.props.history.push(
                "/home/cpd_settings/requiement_setting/index"
              )
            }
          />
            Edit Requirement
        </div>
        <div className={styles.header}>

          <Form
            colon={false}
            {...formLayout}
            labelAlign="left"
            className={styles.ant_form}
          >
            <Row>
              <Col {...flexCol}>
                <Form.Item label="CPD Year">
                  {getFieldDecorator("cpdyear", {
                    initialValue: tempYearId
                  })(
                    <Select disabled>
                      {
                        plukCdpYearList.map((elt => {
                          return (<Option key={elt.yearId} value={elt.yearId}>{elt.year}</Option>)
                        }))
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div
          className={styles.tableBox}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.text}>{`${tempYearName} CPD Requirement Detail`}</div>
            <Button className={canAddRow ? styles.editBtn : styles.disableEditBtn} icon="plus" onClick={this.handleAddRow} disabled={!canAddRow}>Add</Button>
          </div>
          <Table
            components={components}
            columns={columns}
            dataSource={dataSourceList || []}
            size="small"
            loading={tableLoading}
            className={styles.ant_dev_table}
            rowKey="yearReqId"
            pagination={false}
          />
        </div>
      </div>
    )
  }
}
const RequirementSettingPacsEdit = Form.create()(Formes)
export default RequirementSettingPacsEdit;
