import React from "react";
import { connect } from "react-redux";
import {
  getReportTypeList,
  getCpdCategory,
} from "../../../../redux/pambReducer/CPDSettingsReducers/commonReducer";

import Router from "../../../../router";

@connect(
  state => state,
  { getReportTypeList, getCpdCategory }
)
class CPDSettings extends React.Component {
  componentDidMount() {
    this.props.getCpdCategory()
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default CPDSettings;
