import React, {Children, Component} from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Icon,
  Modal,
  Menu,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty,
  Dropdown,
  Checkbox,
  Popconfirm,
  Tag,
  Popover,
} from "antd";
import { get, isArray, isObject, range, uniq, } from 'lodash';
import styles from './style.module.less'

const {MonthPicker} = DatePicker

class AttendanceReportDownload extends Component {
  state = {
    overAll: true,
    monthly: false,
    downloadStart: undefined,
    downloadEnd: undefined,
  };

  onConfirm = () => {
    this.props.onConfirm({
      withOverallReport: this.state.overAll,
      startMonth: this.state.downloadStart ?? '',
      endMonth: this.state.downloadEnd ?? '',
    })
  }

  onChange = (e) => {
    if (e.target.value === 'monthly') {
      let timeRange
      if (e.target.checked) {
        timeRange = {
          downloadStart: moment().format('YYYY-MM'),
          downloadEnd: moment().format('YYYY-MM'),
        }
      } else {
        timeRange = {
          downloadStart: undefined,
          downloadEnd: undefined,
        }
      }
      this.setState({monthly: e.target.checked, ...timeRange})
    } else if (e.target.value === 'overall') {
      this.setState({overAll: e.target.checked})
    }
  }

  onFromChange = (e) => {
    let res
    if (e !== null) {
      res = moment(e).format('YYYY-MM')
    }
    this.setState({downloadStart: res})
  }

  onToChange = (e) => {
    let res
    if (e !== null) {
      res = moment(e).format('YYYY-MM')
    }
    this.setState({downloadEnd: res})
  }

  disabelFrom = (current) => {
    const cur = moment(current)
    return cur.isAfter(this.state.downloadEnd)
  }

  disabelTo = (current) => {
    const cur = moment(current)
    return cur.isBefore(this.state.downloadStart)
  }

  disableConfirm = () => {
    if (!this.state.monthly && !this.state.overAll) {
      return true
    }
    if (this.state.monthly) {
      return !this.state.downloadEnd || !this.state.downloadStart
    }
  }

  render() {
    return (
      <div className={styles.modal}>
        <div className={styles.firstBlock}>
          <div className={styles.titleBox}>
            <span className={styles.title}>Report Period</span>
            <span className={styles.required}>*</span>
          </div>
          <div>
            <Checkbox
              className={styles.modalCheck}
              defaultChecked={this.state.overAll}
              value='overall'
              onChange={this.onChange}
            >Overall
            </Checkbox>
            <Checkbox
              className={styles.modalCheck}
              defaultChecked={this.state.monthly}
              value='monthly'
              onChange={this.onChange}
            >Monthly
            </Checkbox>
          </div>
        </div>
        { this.state.monthly &&
          <div>
            <div className={styles.titleBox}>
              <span className={styles.title}>Monthly Report Range</span>
              <span className={styles.required}>*</span>
            </div>
            <div className={styles.secondBlock}>
              <span className={styles.from}>From</span>
              <MonthPicker
                placeholder='Select Month'
                disabledDate={this.disabelFrom}
                defaultValue={moment(this.state.downloadStart)}
                onChange={this.onFromChange}
              />
              <span className={styles.to}>To</span>
              <MonthPicker
                placeholder='Select Month'
                disabledDate={this.disabelTo}
                defaultValue={moment(this.state.downloadEnd)}
                onChange={this.onToChange}
              />
            </div>
          </div>
        }
        <div className={styles.modalActionBox}>
          <Button className={styles.cancelBtn} onClick={this.props.onCancel}>Cancel</Button>
          <Button
            type="primary"
            className={styles.confirmBtn}
            disabled={this.disableConfirm()}
            onClick={this.onConfirm}
          >
            Download
          </Button>
        </div>
      </div>
    );
  }
}


export {AttendanceReportDownload};

