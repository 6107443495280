import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_REMINDERLIST = "PCLA/INIT_DATA_REMINDERLIST";

const initState = {
  search: {
    value: ""
  },
  list: []
};

// reducer
export default function ReminderListTReducer(state = initState, action) {
  switch (action.type) {
    case "ReminderList":
      return { ...state, ...action.payload };
    case INIT_DATA_REMINDERLIST:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_REMINDERLIST
  };
}

export function save_data(payload) {
  return {
    type: "ReminderList",
    payload
  };
}

export function search(name) {
  return dispatch => {
    dispatch(loading(true));
    get("/reminder/search", { name })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              list: [...res.data]
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
