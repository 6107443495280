import React from "react";
import styles from "./WrapGroup.module.less";

function WrapGroup(props) {
  const { heading, children } = props;
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>{heading}</span>
      </div>
      {children}
    </div>
  );
}

export default WrapGroup;
