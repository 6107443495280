export const agentStatus = [
  {
    value: '',
    label: 'All',
  },
  {
    value: 'P',
    label: 'P',
  },
  {
    value: 'A',
    label: 'A',
  },
  {
    value: 'S',
    label: 'S',
  },
  {
    value: 'T',
    label: 'T',
  },
];

export const accountStatus = [
  {
    value: 'NoLogin',
    label: 'NoLogin'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'Inactive',
    label: 'Inactive'
  }
];

export const accountStatusAdmin = [
  {
    value: '0',
    label: 'NoLogin'
  },
  {
    value: '2',
    label: 'Active'
  },
  {
    value: '1',
    label: 'Inactive'
  }
];


export const indicatorValues = [
  {
    value: 'Y',
    label: 'Y',
  },
  {
    value: 'N',
    label: 'N',
  }
];
