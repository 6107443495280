import React, { Component } from "react";
import { Form, Col, Select } from "antd";
import { flexCol } from '../../utils';

const { Option } = Select;

export default class SelectList extends Component {
  render() {
    const { handleSaveField, getFieldDecorator, name, label, options, initialValue } = this.props;
    return (
      <Col {...flexCol}>
        <Form.Item label={label}>
          {getFieldDecorator(`${name}`, {
            initialValue: initialValue || ''
          })(
            <Select
              onChange={val => handleSaveField(val)}
              allowClear
            >
              {
                options.map((item) => {
                  return (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  )
                })
              }
            </Select>
          )}
        </Form.Item>
      </Col>
    );
  }
}
