import { get, post } from '../../../../../../utils/request';


export const getFcCodeList = async (ownerName) => {
  return get("/GrantHoursController/getPacsUserInfoSelect2", {
    ownerName
  })
}

export const validateEditing = async (data) => {
  return post("/cpdEditing/hours/validation", data)
}
