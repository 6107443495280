import React, {Component} from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Spin,
  Tooltip,
  Modal,
} from "antd";
import { debounce } from 'lodash'
// import Router from "../../../../../../router";
import styles from './index.module.less'
import {
  ONCAHENG_TYPE,
} from "../../../../../../redux/pambReducer/registrationReducers/listReducer";

const flexCol = {
  lg: 24,
  // offset: 6
};

const formLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18
  },
};

const flexRow = {
  gutter: [8, 0]
};

export const CreateForm = Form.create({
  name: 'linkToACourse_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      courseCode: Form.createFormField({
        ...props.courseCode,
        value: props.courseCode.value
      }),
      courseId: Form.createFormField({
        ...props.courseId,
        value: props.courseId.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading } = props;
  const enabled = !props.registrationConfig
  return (
    <Spin spinning={loading}>
      <Form
        layout="horizontal"
        labelAlign="left"
        hideRequiredMark
        colon={false}
        {...formLayout}
      >
        <Row {...flexRow}>
          <Col {...flexCol}>
            <Form.Item
              label={
                <div className={styles.label}>
                  <span>Course Code</span>
                  <span className={styles.required}>*</span>
                </div>
              }>
              {getFieldDecorator("courseCode", {
                rules: [
                  {
                    required: true,
                    message: "Please input all the mandatory fields"
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  disabled={!enabled}
                  filterOption={false}
                  onSearch={debounce(props.searchCourses, 500)}
                  onChange={e => {
                    if (props.courses.length > 0) {
                      const target = props.courses.find(
                        c => c.courseCode === e
                      );
                      if (target) {
                        props.onChange(ONCAHENG_TYPE, {
                          courseName: {
                            value: target.courseName
                          },
                          courseCode: {
                            value: target.courseCode
                          },
                          courseId: {
                            value: target.courseId
                          }
                        });
                      }
                    }
                  }}
                >
                  {!!props.courses.length &&
                    props.courses.map(course => {
                      return (
                        <Option key={course.courseId} value={course.courseCode}>
                          <Tooltip placement="top" title={course.courseName}>
                            {course.courseCode}
                          </Tooltip>
                        </Option>
                      );
                    })}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row {...flexRow}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {})(<Input disabled />)}
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.modalActionBox}>
          <Button
            onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            type="primary"
            className={styles.confirmBtn}
            onClick={(e) => {
              e.preventDefault()
              props.form.validateFields((error, values) => {
                if (error) {
                  return
                }
                props.onConfirm()
              })
            }}>
            Confirm
          </Button>
        </div>
      </Form>
    </Spin>
  );
})
