/* eslint-disable */
import React, { Component } from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Router from "./router";
import RouteConfig from "./router/RouteConfig";
import RouteConfig_PACS from "./router/RouteConfig_PACS";
import RouteConfig_PAMB from "./router/RouteConfig_PAMB";
import RouteConfig_REGIONAL from "./router/RouteConfig_REGIONAL";
import RouteConfig_PHKL from "./router/RouteConfig_PHKL";
import RouteConfig_PLUK from "./router/RouteConfig_PLUK";
import RouteConfig_PCLA from "./router/RouteConfig_PCLA";

import {
  buCuontryList,
  loginUrlAdmin,
  loginUrlPamb,
  loginUrlPacs,
  loginUrlPhkl,
  loginUrlPluk,
  loginUrlPcla
} from "./utils/global";
import Login from "./views/user/Login";
import NotFound from "./views/exception/NotFound";
import NoAuth from './views/exception/NoAuth';

@connect(({ userReducer }) => ({ userReducer }), {})
class PreRoute extends Component {
  handleLoadRoute = bu => {
    switch (bu) {
      case buCuontryList.PACS:
        return <Router defaultConfig={RouteConfig_PACS} />;
      case buCuontryList.PAMB:
        return <Router defaultConfig={RouteConfig_PAMB} />;
      case buCuontryList.REGIONAL:
        return <Router defaultConfig={RouteConfig_REGIONAL} />;
      case buCuontryList.PHKL:
        return <Router defaultConfig={RouteConfig_PHKL} />;
      case buCuontryList.PLUK: // add by zhy
        return <Router defaultConfig={RouteConfig_PLUK} />;
      case buCuontryList.PCLA: // add by zhy
        return <Router defaultConfig={RouteConfig_PCLA} />;
      default:
        return <Router defaultConfig={RouteConfig} />;
    }
  };

  render() {
    const {
      userReducer: { buCountry }
    } = this.props;
    return (
      <BrowserRouter>
        <Route path="/" exact render={() => <Redirect to="/home" />} />
        <Route path={loginUrlAdmin} component={Login} />
        <Route path={loginUrlPamb} component={Login} />
        <Route path={loginUrlPacs} component={Login} />
        <Route path={loginUrlPhkl} component={Login} />
        <Route path={loginUrlPluk} component={Login} />
        <Route path={loginUrlPcla} component={Login} />
        <Route path="/401" component={NoAuth} />
        <Route path="/404" component={NotFound} />

        {this.handleLoadRoute(buCountry)}
      </BrowserRouter>
    );
  }
}

export default PreRoute;
