import { get, post } from "../../../utils/request";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pacs/TrainingHistoryReportReducer/SAVE";
const initState = {
  trainingHistoryData: {},
  leaderNameList: [],
  fcCodeList: [],
  unitCodeList: [],
  detailData: {},
  agentTypeList: [
    {
      code: '',
      value: 'All'
    },
    {
      code: 'nne',
      value: 'NNE'
    },
    {
      code: 'agent',
      value: 'Agent'
    }
  ],
  agentStatusList: [{ code: '2', value: 'Active' }, { code: '1', value: 'Inactive' }],
  courseStatusList: [{ code: '1', value: 'Completed' }, { code: '0', value: 'Incompleted' }],
  sessionStatusList: [{ code: '1', value: 'Completed' }, { code: '0', value: 'Incompleted' }],
  channelList: [{ code: 'AD', value: 'AD' }],
  tableLoading: false,
  downloadLoading: false,
  pageLoading: false,
  temporaryQueryData: { currentPageNumber: 1 },
};

// reducer
export default function TrainingHistoryReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleQueryData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/pacsCPDCalculate/getAgentHistoryReportList", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false,
            trainingHistoryData: data || {},
            temporaryQueryData: payload
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function getFcCodeList(payload, cb) {
  return dispatch => {
    get("/cpdReport/getFcCodeSelect", payload)
      .then(res => {
        const { data = [] } = res;
        data.map(elt => {
          elt.agentCodeValue = elt.agentCode
        })

        dispatch({
          type: SAVE,
          payload: {
            fcCodeList: data || []
          }
        });
        if (cb) { cb(data) }
      })
      .catch(() => { });
  };
}

export function getLeaderNameList() {
  return dispatch => {
    get("/cpdReport/getAgentLeaderSelect")
      .then(res => {
        const { data = [] } = res;
        dispatch({
          type: SAVE,
          payload: {
            leaderNameList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getAgentStatusList() {
  return dispatch => {
    get("/cpdReport/getAgentStatusSelect")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            agentStatusList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getUnitCodeList() {
  return dispatch => {
    get("/cpdReport/getUnitCodeSelect")
      .then(res => {
        const { data = [] } = res;
        dispatch({
          type: SAVE,
          payload: {
            unitCodeList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export const downloadData = (payload) => {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/pacsCPDCalculate/downloadAgentHistoryReport", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Training History Report.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export const getDetailByUserId = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: SAVE,
      payload: {
        pageLoading: true
      }
    });

    const response = await post("/pacsCPDCalculate/getAgentHistoryReport", payload);
    const { data } = response;
    const {
      totalEthic,
      totalRandR,
      totalERR,
      totalCis,
      totalAandH,
      totalOthers,
      totalSTOT,

      requirementEthicV,
      requirementRandRV,
      requirementERRV,
      requirementCisV,
      requirementAandHV,
      requirementOthersV,
      requirementSTOTV,

      shortFallEthic,
      shortFallRandR,
      shortFallERR,
      shortFallCis,
      shortFallAandH,
      shortFallOthers,
      shortFallSTOT,
      agentHistoryReportCourseInfoList = [],
    } = data;

    let summaryArray = [];

    if (agentHistoryReportCourseInfoList.length) {
      summaryArray = [
        [
          shortFallEthic,
          shortFallRandR,
          shortFallERR,
          shortFallCis,
          shortFallAandH,
          shortFallOthers,
          shortFallSTOT
        ],
        [
          requirementEthicV,
          requirementRandRV,
          requirementERRV,
          requirementCisV,
          requirementAandHV,
          requirementOthersV,
          requirementSTOTV
        ],
        [
          totalEthic,
          totalRandR,
          totalERR,
          totalCis,
          totalAandH,
          totalOthers,
          totalSTOT,
        ],

      ]
    }


    dispatch({
      type: SAVE,
      payload: {
        detailData: {
          ...data,
          ...{ summaryArray }
        } || {},
        pageLoading: false
      }
    });
  } catch (error) {
    dispatch({
      type: SAVE,
      payload: {
        pageLoading: false
      }
    });
  }
};

export const resetDetailData = () => async (dispatch) => {
  dispatch({
    type: SAVE,
    payload: {
      detailData: {}
    }
  })
}

export function saveTempData(payload) {
  return (dispatch, getState) => {
    const {
      pacsReducer: {
        CPDReportReducers: {
          TrainingHistoryReportReducer: {
            temporaryQueryData
          }
        }
      }
    } = getState()

    dispatch({
      type: SAVE,
      payload: {
        temporaryQueryData: { ...temporaryQueryData, ...payload }
      }
    });
  };
}

export function handleResetQueryData() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        trainingHistoryData: {},
        temporaryQueryData: { currentPageNumber: 1 }
      }
    });
  };
}
