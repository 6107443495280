import React from 'react'
import { Input, Form, Select } from 'antd'
import styles from './EditableCell.module.less'

const { Option } = Select
class EditableCell extends React.Component {
  getInput = () => {
    const { dataIndex, getcpdtype, cpdCategoryList, cpdTypeList, record, isEditAction } = this.props
    if (dataIndex === 'cpdcategory') {
      return (
        <Select
          className={styles.defaultWidth}
          onChange={getcpdtype}
          disabled={isEditAction}
        >
          {
            cpdCategoryList.map((elt) => {
              return (
                <Option key={elt} value={elt}>{elt}</Option>
              )
            })
          }
        </Select>
      )
    } else if (dataIndex === 'cpdtype') {
      const { cpdTypeMap = {} } = record
      return (
        <Select className={styles.defaultWidth} disabled={isEditAction}>
          {
            isEditAction ?
              Object.keys(cpdTypeMap).map((elt) => <Option key={elt} value={elt}>{cpdTypeMap[elt]}</Option>)
              :
              cpdTypeList.map((elt) => <Option key={elt.id} value={elt.id}>{elt.value}</Option>)
          }
        </Select>
      )
    }

    return <Input />;
  };

  handleInitialVal = (record, key) => {
    return record[key]
  }

  render() {
    const {
      editing,
      dataIndex,
      title,
      inputType,
      record,
      children,
      getFieldDecorator,
      getcpdtype,
      cpdCategoryList,
      cpdTypeList,
      isEditAction,
      ...restProps
    } = this.props;

    return (
      <td {...restProps} className={styles.container}>
        {editing ? (
          <Form.Item style={{ margin: 0 }}>
            {getFieldDecorator(dataIndex, {
              rules: [
                {
                  required: true,
                  message: `Please Input!`,
                },
              ],
              initialValue: this.handleInitialVal(record, dataIndex),
            })(this.getInput())}
          </Form.Item>
        ) : (
            children
          )}
      </td>
    );
  }
}

export default EditableCell
