import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { withRouter } from "react-router-dom";
import styles from '../index.module.less';
import { get } from '../../../../../../utils/request'
import { getColumns } from './columns';

function CourseRegistration(props) {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [columns, setColumns] = useState([]);

  const fetchData = async () => {
    const { sessionId } = props.location.state;
    setLoading(true);
    const res = await get("/attendanceTms/getRegistrationAgents", { sessionId });
    setList(res.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
    setColumns(getColumns());
  }, []);

  return (
    <div>
      <div className={styles.tableTitle}>Registration Information</div>
      <Spin spinning={loading}>
        <Table
          bordered
          scroll={{ x: 1800 }}
          className={styles.ant_dev_two_table}
          columns={columns}
          dataSource={list}
          size="small"
        />
      </Spin>
    </div>
  );
}

export default withRouter(CourseRegistration);
