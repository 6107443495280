import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_SELECTCOURSE = "PACS/INIT_DATA_SELECTCOURSE";
const SEARCHDATA_SELECTCOURSE = "PACS/SEARCHDATA_SELECTCOURSE";

const initState = {
  courseName: {
    value: ""
  },
  List: []
};

// reducer
export default function SelectCourseReducer(state = initState, action) {
  switch (action.type) {
    case "SelectCourse":
      return { ...state, ...action.payload };
    case SEARCHDATA_SELECTCOURSE:
      return { ...state, ...action.payload };
    case INIT_DATA_SELECTCOURSE:
      return { ...initState };
    default:
      return state;
  }
}
export function search_data(payload) {
  return {
    type: SEARCHDATA_SELECTCOURSE,
    payload: {
      List: [...payload]
    }
  };
}

export function init_data() {
  return {
    type: INIT_DATA_SELECTCOURSE
  };
}

export function save_data(payload) {
  return {
    type: "SelectCourse",
    payload
  };
}

export function searchCourse(payload) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/courseList", { name: payload })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              List: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
