import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import debounce from "lodash/debounce";
import { Form, Row, Col, Input, DatePicker, Table, Empty, Button, Modal, Select, Spin, notification } from 'antd'
import { getTabelData, getTabelDataAll, getShiftToDate, handleSubmit, getAgentShiftIn0utBulkSave, getFcCodeList, getShiftCourseName } from '../../../../redux/pacsReducer/CPDSettingsReducers/ShiftInOutReducer'
import styles from './ShiftInOut.module.less'
import { switchDate } from "../../../../utils/global";

const { Option } = Select
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const formLayout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  span: 8
};

const flexCol2 = {
  span: 24
};
const flexRow = {
  gutter: [22, 0]
};

function disabledDate(current) {
  return switchDate() && current < moment('20220101');
}

const { RangePicker } = DatePicker
@connect(
  ({ pacsReducer: { CPDSettingsReducers: { ShiftInOutReducer } } }) => ({ ShiftInOutReducer }),
  { getTabelData, getTabelDataAll, getAgentShiftIn0utBulkSave, getShiftToDate, handleSubmit, getFcCodeList, getShiftCourseName }
)

class Formes extends React.Component {
  state = {
    selectedRowKeys: [],
    selectedRows: [],
    visible: false,
    visible1: false,
    visible2: false,
    fetching: false,
    selectShiftFromDate: '',
    currentPageNumber: 1,
    pageSize: 10,
    fcCode: '',
    courseId: '',
    shiftFromDate: '',
    userId: '',
    effectYear: '',
    fromCompletionDate: '',
    toCompletionDate: '',
    agentCode: '',
    isShowTable: false,
    columns: [
      {
        title: "FC Code",
        dataIndex: "agentCode",
        align: "center",
        width: 120
      },
      {
        title: "Completed Date",
        dataIndex: "dateCompletedV",
        align: "center",
      },
      {
        title: "Effect Year",
        dataIndex: "effectDateCompletedV",
        align: "center",
        width: 100
      },
      {
        title: "Course Code",
        dataIndex: "code",
        align: "center",
      },
      {
        title: "Course Name",
        dataIndex: "name",
        align: "center",
      },
      {
        title: "CPD Category and Type",
        dataIndex: "cpdcategoryAndType",
        align: "center",
        render: (text) => {
          const arr = text ? text.split(',') : []
          return (
            <div>
              {arr.map((elt) => <div key={elt}>{elt}</div>)}
            </div>)
        }
      },
      {
        title: "Last Update Date",
        dataIndex: "lastUpdateDateShiftV",
        align: "center",
      },
      {
        title: "Updated By",
        dataIndex: "updatedByShift",
        align: "center",
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        render: (text, record) => {
          return (
            <span className={styles.spanCss} onClick={() => this.handleEdit(record)}>
              Edit
            </span>
          )
        }
      }

    ]
  }

  fetchUser = debounce((val) => {
    this.setState({ fetching: true });
    this.props.getFcCodeList({ ownerName: val }, () => this.handleFcCodeChange(val))
  }, 800)


  componentDidMount() {
    this.props.getShiftCourseName()
    this.props.getFcCodeList({ ownerName: '' })
    this.props.getShiftToDate()
  }

  handleEdit = (record) => {
    const { agentCode, courseId, effectDateCompletedV, userId } = record
    this.setState({ fcCode: agentCode, courseId, shiftFromDate: effectDateCompletedV, userId, visible: true })
  }

  handleSearch = (e) => {
    e.preventDefault();
    /* eslint-disable */
    // console.log("handleSearch:",this.state.selectedRowKeys)

    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { agentCode, completeDate, courseName, effectYear } = values
        const [start, end] = completeDate || [];
        // console.log("courseId:", courseName)  // eslint-disable-line
        const fromCompletionDate = start ? moment(start).format("YYYY-MM-DD") : ''
        const toCompletionDate = end ? moment(end).format("YYYY-MM-DD") : ''
        this.props.getTabelData({ effectYear, agentCode: agentCode || '', courseId: courseName || '', fromCompletionDate, toCompletionDate, currentPageNumber: 1, pageSize: this.state.pageSize })
        this.props.getTabelDataAll(
          { effectYear, agentCode: agentCode || '', courseId: courseName || '', fromCompletionDate, toCompletionDate }
        )
        this.setState({ currentPageNumber: 1, agentCode, effectYear, courseName, fromCompletionDate, toCompletionDate, isShowTable: true })
      }
    });
  }

  handleOk = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { courseId, shiftFromDate, userId, agentCode, effectYear, courseName, fromCompletionDate, toCompletionDate } = this.state
        const { shiftToDate } = values
        const submitData = {
          courseId, shiftFromDate, shiftToDate, userId
        }
        const callback = () => {
          this.props.getTabelData({
            agentCode,
            fromCompletionDate,
            toCompletionDate,
            effectYear,
            courseId: courseName,
          })
          this.props.getTabelDataAll({
            agentCode,
            fromCompletionDate,
            toCompletionDate,
            effectYear,
            courseId: courseName,
          })
        }
        this.props.handleSubmit(submitData, callback)
        this.setState({
          fcCode: '',
          courseId: '',
          shiftFromDate: '',
          userId: '',
          visible: false,
        });
        form.resetFields()
      }
    });
  };

  handleCancel = (props) => {
    props.form.resetFields()
    this.setState({
      fcCode: '',
      courseId: '',
      shiftFromDate: '',
      userId: '',
      visible: false,
    });
  };

  handleFcCodeChange = (value) => {
    this.setState({
      fetching: false
    });
    if (!value) {
      this.props.getFcCodeList({ ownerName: '' });
    }
  };

  handleTableChange = pagination => {
    /* eslint-disable */
    // console.log("handleSearch:",this.state.selectedRowKeys)
    const { current, pageSize } = pagination;
    const { agentCode, fromCompletionDate, courseName, effectYear, toCompletionDate } = this.state
    this.setState({ currentPageNumber: current })
    this.props.getTabelData(
      {
        pageSize,
        agentCode,
        fromCompletionDate,
        toCompletionDate,
        currentPageNumber: current,
        effectYear,
        courseId: courseName
      }
    );
  };

  changeSelect = (selectedRowKeys, selectedRows) => {
    /* eslint-disable */

    // console.log("selectedRowKeys", selectedRowKeys)
    // console.log("selectedRows", selectedRows)

    this.setState(() => ({
      selectedRowKeys,
      selectedRows
    }))

  }

  selectTableAll = () => {
    const { selectedRowKeys } = this.state;
    const { ShiftInOutReducer: { userCourseData: { userList }, allUserList } } = this.props;
    // console.log("allUserList:", allUserList)
    const _selectedRowKeys = [];

    if (selectedRowKeys.length != allUserList.length) {
      allUserList.forEach((item) => {
        _selectedRowKeys.push(item.courseUserId)
      })
    }

    this.setState(() => ({
      selectedRowKeys: _selectedRowKeys,
      selectedRows: _selectedRowKeys.length ? allUserList : []
    }))
  }

  editSelection = () => {
    const { selectedRows } = this.state;

    if (selectedRows.length) {
      const stateYear = selectedRows[0].effectDateCompletedV;
      let status = true;

      for (let x = 0; x < selectedRows.length; x++) {
        if (stateYear != selectedRows[x].effectDateCompletedV) {
          status = false;
          break;
        }
      }
      if (status) {
        this.setState(() => ({
          selectShiftFromDate: stateYear,
          visible1: true
        }))
      }
      else {
        notification.warn({
          message: "Please select only one effect year"
        })
      }
    }


  }

  handleOk1 = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { selectedRowKeys, agentCode, selectShiftFromDate, effectYear, fromCompletionDate, toCompletionDate, courseId } = this.state;
        const { shiftToDate } = values
        const submitData = {
          courseId, shiftToDate,
          courseUserIds: selectedRowKeys,
          agentCode,
          fromCompletionDate,
          toCompletionDate,
          effectYear,
          shiftFromDate: selectShiftFromDate,
        }
        const callback = (data) => {

          if (data.length) {
            this.setState(() => ({
              visible1: false,
              visible2: true,
            }))
          }
          else{
            this.setState(() => ({
              visible1: false,
            }))
          }

          // console.log("callback data:", data)
          this.props.getTabelData({
            agentCode,
            fromCompletionDate,
            toCompletionDate,
            effectYear,
            courseId: courseName,
          })
          this.props.getTabelDataAll({
            agentCode,
            fromCompletionDate,
            toCompletionDate,
            effectYear,
            courseId: courseName,
          })
        }
        this.props.getAgentShiftIn0utBulkSave(submitData, callback)
        this.setState({
          fcCode: '',
          courseId: '',
          shiftFromDate: '',
          userId: '',
          visible: false,
        });
        form.resetFields()
      }
    });

  }

  handleCancel1 = () => {
    this.setState(() => ({
      visible1: false
    }))

  }


  render() {
    const { form: { getFieldDecorator }, ShiftInOutReducer: { userCourseData: { userList, total }, noUpdatedList, tableLoading, shiftToList, fcCodeList, courseNameList } } = this.props
    const { columns, visible, visible1, visible2, selectShiftFromDate, currentPageNumber, fcCode, courseId, shiftFromDate, pageSize, fetching, selectedRowKeys } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.heading}>Shift In / Shift Out</div>
        <Form
          colon={false}
          {...formLayout}
          labelAlign="left"
          className={styles.ant_form}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="FC Code">
                {getFieldDecorator("agentCode", {
                  initialValue: ''
                })(
                  <Select
                    notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                    showSearch
                    allowClear
                    filterOption={false}
                    onChange={this.handleFcCodeChange}
                    onSearch={this.fetchUser}
                  >
                    {fcCodeList.map(elt => <Option key={elt.agentCode} value={elt.agentCode}>{elt.agentCodeValue}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Completed Date">
                {getFieldDecorator("completeDate", {})(
                  <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={["Start Time", "End Time"]}
                    disabledDate={disabledDate}
                  />
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Course Name">
                {getFieldDecorator("courseName", {
                  initialValue: ''
                })(
                  <Select
                    notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      // console.log(input, option) // eslint-disable-line
                      return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    }
                  >
                    {courseNameList.map(elt => <Option key={elt.courseId} value={elt.courseId}>{elt.courseName}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <Form.Item label="Effect Year">
                {getFieldDecorator("effectYear", {
                  initialValue: '',
                  rules: [{
                    pattern: /^[0-9]{4}$/,
                    whitespace: true,
                    message: "Please enter the correct number of years, for example:2020"
                  }]
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>

            <Col {...flexCol}>
              <div className={styles.btnBox}>
                <Button className={styles.ant_btn} onClick={this.handleSearch}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <div className={styles.btnContent}>
          <Button
            style={{ display: userList.length ? "inline-block" : "none", marginRight: '10px' }}
            className={styles.btn}
            type="danger"
            onClick={this.selectTableAll}
          >
            Select All
        </Button>
          <Button
            style={{ display: userList.length ? "inline-block" : "none" }}
            className={styles.btn}
            type="danger"
            onClick={this.editSelection}
          >
            Edit Selection
        </Button>
        </div>
        <Table
          rowSelection={
            {
              onChange: this.changeSelect,
              selectedRowKeys
            }
          }
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
          columns={columns}
          dataSource={userList || []}
          pagination={{
            current: currentPageNumber,
            pageSize,
            total
          }}
          size="small"
          loading={
            tableLoading
          }
          onChange={this.handleTableChange}
          className={styles.ant_dev_table}
          rowKey="courseUserId"
          // rowKey={(rowInfo) => `${rowInfo.courseId + rowInfo.userId}`}
          scroll={userList.length > 5 ? { y: 450 } : {}}
        />
        <ModelForm
          shiftToList={shiftToList}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          visible={visible}
          fcCode={fcCode}
          courseId={courseId}
          shiftFromDate={shiftFromDate}
        />
        <ModelFormSelect
          shiftToList={shiftToList}
          handleOk={this.handleOk1}
          handleCancel={this.handleCancel1}
          visible={visible1}
          shiftFromDate={selectShiftFromDate}
        />

        <Modal
          title={<div style={{ fontSize: '14px' }}>
            <p>Effect year will be updated for applicable agents.</p>
            <p>Effect year is not applicable to some agents and will not be updated.</p>
          </div>}
          visible={visible2}
          onOk={() => {
            this.setState({
              visible2: false
            })
          }}
          onCancel={() => {
            this.setState({
              visible2: false
            })
          }}
          okText='Ok'
          okType='danger'
        >
          <Table
            bordered
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            scroll={{ y: 800 }}
            columns={[
              {
                title: "Agent Code",
                dataIndex: "fcCode",
                align: "center"
              },
              {
                title: "Agent Name",
                dataIndex: "fcName",
                align: "center"
              },
            ]}
            dataSource={noUpdatedList}
            size="small"
            rowKey="fcCode"
          />
        </Modal>

      </div>
    )
  }
}


const ShiftInOut = Form.create()(Formes);

function Formes2(props) {
  const { form: { getFieldDecorator }, shiftToList, visible, handleOk, handleCancel, fcCode, courseId, shiftFromDate } = props
  return (
    <Modal
      title="Shift In / Shift Out"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText='Save'
      okType='danger'
    >

      <Form
        colon={false}
        {...formLayout2}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <Form.Item label="FC Code">
              {getFieldDecorator("fcCode", {
                initialValue: fcCode
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Course Id">
              {getFieldDecorator("courseId", {
                initialValue: courseId
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Shift From">
              {getFieldDecorator("shiftFromDate", {
                initialValue: shiftFromDate
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Shift To">
              {getFieldDecorator("shiftToDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    validator: async (rule, value) => {
                      if (value && value === shiftFromDate) {
                        throw new Error('Shift To cannot select the same year of Shift From');
                      }
                    },
                  }
                ]
              })(
                <Select>
                  {
                    shiftToList.map(elt => <Option key={elt.year} value={elt.year}>{elt.year}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

function Formes3(props) {
  const { form: { getFieldDecorator }, shiftToList, visible, handleOk, handleCancel, shiftFromDate } = props
  return (
    <Modal
      title="Shift In / Shift Out"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText='Save'
      okType='danger'
    >

      <Form
        colon={false}
        {...formLayout2}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <Form.Item label="Shift From">
              {getFieldDecorator("shiftFromDate", {
                initialValue: shiftFromDate
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Shift To">
              {getFieldDecorator("shiftToDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    validator: async (rule, value) => {
                      if (value && value === shiftFromDate) {
                        throw new Error('Shift To cannot select the same year of Shift From');
                      }
                    },
                  }
                ]
              })(
                <Select>
                  {
                    shiftToList.map(elt => <Option key={elt.year} value={elt.year}>{elt.year}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}


const ModelForm = Form.create()(Formes2);

const ModelFormSelect = Form.create()(Formes3);

export default ShiftInOut;
