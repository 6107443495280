import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INITDATA_COURSERATINGSUMMARY_REPORT = "PLUK/INITDATA_COURSERATINGSUMMARY_REPORT";
const SAVE_COURSERATINGSUMMARY_REPORT_DATA = "PLUK/SAVE_COURSERATINGSUMMARY_REPORT_DATA";

const initState = {
  courseName: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  trainingDate: {
    value: ""
  },
  sessionId: "",
  qIndex: 0,
  courseList: [],
  sessionList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1,

  searchShortList: [],
  sTotalPage: 0,
  sTotal: 0,
  sPageSize: 10,
  sCurrentPageNumber: 1,
  sQIndex: 0,
};

export default function CourseRatingSummaryReportPLUKReducer(state = initState, action) {
  switch (action.type) {
    case "CourseRatingSummaryReportPLUK":
      return { ...state, ...action.payload };
    case INITDATA_COURSERATINGSUMMARY_REPORT:
      return { ...initState };
    case SAVE_COURSERATINGSUMMARY_REPORT_DATA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_COURSERATINGSUMMARY_REPORT
  };
}

export function save_data(payload) {
  return {
    type: SAVE_COURSERATINGSUMMARY_REPORT_DATA,
    payload
  };
}
// GET /surveyReport/getCourseNameList
export function getCourseNameList() {
  return dispatch => {
    get("/surveyReport/getCourseNameList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              courseList: res.data.length ? [...res.data] : [],
              // courseName: {
              //   value: res.data.length ? res.data[0].courseId : ""
              // }
            })
          );
          // if (res.data.length) {
          //   dispatch(getSessionNameList(res.data[0].courseId));
          // }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /surveyReport/getSessionNameList
export function getSessionNameList(courseId) {
  return dispatch => {
    get("/surveyReport/getSessionNameList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionName: {
                value: ""
              },
              sessionList: [...res.data],
              // sessionName: {
              //   value:
              //     res.data.length && res.data[0] !== null
              //       ? res.data[0].sessionId
              //       : ""
              // }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /surveyReport/getSurveySummaryReport
export function getSurveySummaryReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/surveyReport/getSurveySummaryReport", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          let qIndex = 0;
          const searchList = [...res.data.data];
          searchList.map((item) => {
            item.questionList.forEach((_item) => {
              qIndex++;
              for (let x in _item) {
                if (x !== "questionName" && x !== "showName") {
                  item[`${x}_${_item.questionName}`] = _item[x];
                }
              };
            });
            return item;
          });
          console.log("searchList:", searchList);
          dispatch(
            save_data({
              searchList,
              qIndex,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /surveyReport/getSurveyDetailReport
export function getSurveyDetailReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/surveyReport/getSurveyDetailReport", data)
      .then(res => {
        if (res.success) {
          let sQIndex = 0;
          const searchShortList = [...res.data.data];
          searchShortList.map((item) => {
            item.questionList.forEach((_item) => {
              sQIndex++;
              item[`${_item.questionName}_answer`] = _item.answer;
            });
            return item;
          });
          console.log("searchShortList:", searchShortList);
          dispatch(
            save_data({
              searchShortList,
              sQIndex,
              sTotalPage: res.data.totalPage,
              sTotal: res.data.total,
              sPageSize: res.data.pageSize,
              sCurrentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /surveyReport/downloadSurveySummaryReport
export function downloadSurveySummaryReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/surveyReport/downloadSurveySummaryReport", data, {}, "blob")
      .then(blob => {
        const fileName = "SurveySummaryReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}

// POST /surveyReport/downloadSurveyDetailReport
export function downloadSurveyDetailReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/surveyReport/downloadSurveyDetailReport", data, {}, "blob")
      .then(blob => {
        const fileName = "SurveyDetailReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
