import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Row, Col, Input } from "antd";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import { handleMyProfile } from "../../../../redux/homeReducer";
import { handleStatus, handleGender } from "../../../../utils";
import styles from "./pacsAgent.module.less";

// const { RangePicker } = DatePicker;

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

// const formLayout2 = {
//   labelCol: { span: 11 },
//   wrapperCol: { span: 13 }
// };

const flexRow = {
  gutter: [22, 0]
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};
const flexCol12 = {
  span: 12
};

const CreateForm = Form.create({
  name: "CreateAgent_PLUK_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("Agent_PLUK", changedFields);
  },
  mapPropsToFields(props) {
    return {
      // role: Form.createFormField({
      //   ...props.role,
      //   value: props.role.value
      // }),
      roleName: Form.createFormField({
        ...props.roleName,
        value: props.roleName.value
      }),
      bu: Form.createFormField({
        ...props.bu,
        value: props.bu.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      agentCode: Form.createFormField({
        ...props.agentCode,
        value: props.agentCode.value
      }),
      firstName: Form.createFormField({
        ...props.firstName,
        value: props.firstName.value
      }),
      middleName: Form.createFormField({
        ...props.middleName,
        value: props.middleName.value
      }),
      lastName: Form.createFormField({
        ...props.lastName,
        value: props.lastName.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      gender: Form.createFormField({
        ...props.gender,
        value: props.gender.value
      }),
      contactNumber: Form.createFormField({
        ...props.contactNumber,
        value: props.contactNumber.value
      }),
      rank: Form.createFormField({
        ...props.rank,
        value: props.rank.value
      }),
      codingDate: Form.createFormField({
        ...props.codingDate,
        value: props.codingDate.value
      }),
      unitNo: Form.createFormField({
        ...props.unitNo,
        value: props.unitNo.value
      }),
      // unitCode: Form.createFormField({
      //   ...props.unitCode,
      //   value: props.unitCode.value
      // }),
      group: Form.createFormField({
        ...props.group,
        value: props.group.value
      }),
      channel: Form.createFormField({
        ...props.channel,
        value: props.channel.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      unitManagerFirstName: Form.createFormField({
        ...props.unitManagerFirstName,
        value: props.unitManagerFirstName.value
      }),
      unitManagerLastName: Form.createFormField({
        ...props.unitManagerLastName,
        value: props.unitManagerLastName.value
      }),
      unitManagerAgentCode: Form.createFormField({
        ...props.unitManagerAgentCode,
        value: props.unitManagerAgentCode.value
      }),
      accreditedTrainer: Form.createFormField({
        ...props.accreditedTrainer,
        value: props.accreditedTrainer.value
      }),
      promotionDate: Form.createFormField({
        ...props.promotionDate,
        value: props.promotionDate.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      terminationDate: Form.createFormField({
        ...props.terminationDate,
        value: props.terminationDate.value
      }),
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  return (
    <Form layout="horizontal" labelAlign="left" {...formLayout} colon={false}>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Role">
            {getFieldDecorator("roleName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="BU">
            {getFieldDecorator("bu", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent Code">
            {getFieldDecorator("agentCode", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="First Name">
            {getFieldDecorator("firstName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Middle Name">
            {getFieldDecorator("middleName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Last Name">
            {getFieldDecorator("lastName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Gender">
            {getFieldDecorator("gender", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Mobile Number">
            {getFieldDecorator("contactNumber", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Classification">
            {getFieldDecorator("rank", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Coding Date">
            {getFieldDecorator("codingDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Sales Unit Code">
            {getFieldDecorator("unitNo", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Branch Name">
            {getFieldDecorator("group", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Channel">
            {getFieldDecorator("channel", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol12}>
          <Form.Item label="Unit Manager First Name">
            {getFieldDecorator("unitManagerFirstName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol12}>
          <Form.Item label="Unit Manager Last Name">
            {getFieldDecorator("unitManagerLastName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol12}>
          <Form.Item label="Unit Manager Agent Code">
            {getFieldDecorator("unitManagerAgentCode", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol12}>
          <Form.Item label="Accredited Trainer">
            {getFieldDecorator("accreditedTrainer", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Promotion Date">
            {getFieldDecorator("promotionDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Termination Date">
            {getFieldDecorator("terminationDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => {
    return {
      fields: {
        // ...state.myProfileReducers.Agent_PAMB_Reducer,
        // role: {
        //   value: state.userReducer.roldId
        // },
        roleName: {
          value: state.userReducer.roleCode
        },
        bu: {
          value: state.userReducer.bu ? state.userReducer.bu.toUpperCase() : ""
        },
        account: {
          value: state.userReducer.loginAccount
        },
        agentCode: {
          value: state.userReducer.agentCode
        },
        firstName: {
          value: state.userReducer.firstName
        },
        middleName: {
          value: state.userReducer.middleName
        },
        lastName: {
          value: state.userReducer.lastName
        },
        email: {
          value: state.userReducer.email
        },
        gender: {
          value: state.userReducer.gender
            ? handleGender(state.userReducer.gender)
            : ""
        },
        contactNumber: {
          value: state.userReducer.contactNumber
        },
        rank: {
          value: state.userReducer.rank
        },
        codingDate: {
          value: state.userReducer.codingDate
        },
        unitNo: {
          value: state.userReducer.unitNo
        },
        // unitCode: {
        //   value: state.userReducer.unitCode
        // },
        group: {
          value: state.userReducer.pgroup
        },
        channel: {
          value: state.userReducer.channel
        },
        region: {
          value: state.userReducer.region
        },
        unitManagerFirstName: {
          value: state.userReducer.uniMrgFirstName
        },
        unitManagerLastName: {
          value: state.userReducer.uniMrgLastName
        },
        unitManagerAgentCode: {
          value: state.userReducer.uniMrgAgentCode
        },
        accreditedTrainer: {
          value: state.userReducer.accreditedTrainer
        },
        promotionDate: {
          value: state.userReducer.promotionDate ? moment(state.userReducer.promotionDate).format("YYYY-MM-DD")
          : ""
        },
        status: {
          value: state.userReducer.status
            ? handleStatus(state.userReducer.status)
            : ""
        },
        terminationDate: {
          value: state.userReducer.terminationDate ? moment(state.userReducer.terminationDate).format("YYYY-MM-DD")
          : ""
        },
      },
      user: { ...state.userReducer },
      buList: state.plukReducer.systemSettingsReducers.userManagementReducer
        .buList.length
        ? [
            ...state.plukReducer.systemSettingsReducers.userManagementReducer
              .buList
          ]
        : [],
      rolerList: state.plukReducer.systemSettingsReducers.userManagementReducer
        .allRolerList.length
        ? [
            ...state.plukReducer.systemSettingsReducers.userManagementReducer
              .allRolerList
          ]
        : []
    }
  },
  { changeVals, handleMyProfile }
)
class Agent_PLUK extends Component {
  state = {
    disabled: true
  };

  componentDidMount() {
    this.props.handleMyProfile();
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  render() {
    const props = {
      rolerList: this.props.rolerList,
      buList: this.props.buList,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      disabled: this.state.disabled
    };
    return (
      <div className={styles.container}>
        <div className={styles.heading}>My Profile</div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

export default Agent_PLUK;
