import React from "react";
import { Form, Select, Input, Button, Table, Row, Col, message } from "antd";
// import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getTRsearchData,
  saveFields,
  resetForm
} from "../../../../../redux/pambReducer/CPDSettingsReducers/trainingRequirementSearchReducer";
import { globalPromptTime10s } from "../../../../../utils/global";
import styles from "./TrainingRequirementSearch.module.less";

const { Option } = Select;
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({
    pambReducer: { CPDSettingsReducers: { trainingRequirementSearchReducer, commonReducer } }
  }) => ({ trainingRequirementSearchReducer, commonReducer }),
  { getTRsearchData, saveFields, resetForm }
)
class SearchForm extends React.Component {
  state = {
    columnsLIAM: [
      {
        title: "No",
        dataIndex: "No",
        ellipsis: true,
        align: "center",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "Name",
        dataIndex: "username",
        align: "center",
        render: (text, record) => {
          const {
            type,
            searchPramas
          } = this.props.trainingRequirementSearchReducer.temporaryData;
          const { userId } = record;
          const pathname =
            "/home/training_history/new_agent_training_history/liam_agent_detail";
          return (
            <Link
              to={{
                pathname,
                state: {
                  userId,
                  type,
                  searchPramas
                }
              }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title: "NRIC Number",
        dataIndex: "nric",
        align: "center"
      },
      {
        title: "Agent ID",
        dataIndex: "agentId",
        align: "center"
      },
      {
        title: "Agent Code",
        dataIndex: "agentCode",
        align: "center"
      },
      {
        title: () => (
          <div>
            <div>20h</div>
            <div>(First Year)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam6monReqStatus"
      },
      {
        title: () => (
          <div>
            <div>10h</div>
            <div>(First Year)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam12monReqStatus"
      },
      {
        title: () => (
          <div>
            <div>20h</div>
            <div>(Second Year)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam24monReqStatus"
      }
    ],
    columnsMTA: [
      {
        title: "No",
        dataIndex: "No",
        ellipsis: true,
        align: "center",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "Name",
        dataIndex: "username",
        align: "center",
        render: (text, record) => {
          const {
            type,
            searchPramas
          } = this.props.trainingRequirementSearchReducer.temporaryData;
          const { userId } = record;
          const pathname =
            "/home/training_history/new_agent_training_history/mta_agent_detail";
          return (
            <Link
              to={{
                pathname,
                state: {
                  userId,
                  type,
                  searchPramas
                }
              }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title: "NRIC Number",
        dataIndex: "nric",
        align: "center"
      },
      {
        title: "Agent ID",
        dataIndex: "agentId",
        align: "center"
      },
      {
        title: "Agent Code",
        dataIndex: "agentCode",
        align: "center"
      },
      {
        title: () => (
          <div>
            <div>20 Training Hours</div>
            <div>(First Year)</div>
          </div>
        ),
        align: "center",
        dataIndex: "mtaFirstReqStatus"
      },
    ]
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { type, status, nric, unitCode, agentId, agentCode } = values;

        const atleastont = unitCode || agentId || agentCode || nric;
        if (!atleastont) {
          message.info(
            "Please fill in at least one from NRIC Number, Unit code, Agent id and Agent code",
            globalPromptTime10s
          );
          return null;
        }

        const submitData = {
          ...values,
          page: 1,
          pageSize: 10
        };

        this.props.getTRsearchData({ ...submitData });
        this.props.saveFields({
          searchPramas: submitData,
          type,
          tempType: type,
          status
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const {
      type,
      status,
      nric,
      unitCode,
      agentId,
      agentCode
    } = this.props.trainingRequirementSearchReducer.temporaryData;
    const submitDa = {
      type,
      status,
      nric,
      unitCode,
      agentId,
      agentCode,
      pageSize,
      page: current
    };
    this.props.saveFields({ currentNum: current, searchPramas: submitDa });
    this.props.getTRsearchData(submitDa);
  };

  handleSaveField = (field, val) => {
    this.props.saveFields({ [field]: val });
  };

  handleResetForm = () => {
    this.props.resetForm();
    this.props.form.resetFields();
  };

  render() {
    const {
      form: { getFieldDecorator },
      trainingRequirementSearchReducer: {
        TRsearchData,
        searchLoading,
        isShowTableT,
        temporaryData
      },
      commonReducer: { reportTypeList, statusList }
    } = this.props;
    const { items = [], total } = TRsearchData;
    const { columnsLIAM, columnsMTA } = this.state;
    const columns = temporaryData.type === "LIAM" ? columnsLIAM : columnsMTA;
    return (
      <div className={styles.container}>
        <div className={styles.heading}>New Agent Training History</div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Report Type">
                {getFieldDecorator("type", {
                  initialValue:
                    temporaryData.tempType ||
                    (reportTypeList[0] && reportTypeList[0].value)
                })(
                  <Select
                    onChange={val => this.handleSaveField("tempType", val)}
                  >
                    {reportTypeList.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Status">
                {getFieldDecorator("status", {
                  initialValue:
                    temporaryData.status ||
                    (statusList[0] && statusList[0].value)
                })(
                  <Select onChange={val => this.handleSaveField("status", val)}>
                    {statusList.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="NRIC Number">
                {getFieldDecorator("nric", {
                  initialValue: temporaryData.nric
                })(
                  <Input
                    onChange={e => this.handleSaveField("nric", e.target.value)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Unit Code">
                {getFieldDecorator("unitCode", {
                  initialValue: temporaryData.unitCode
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("unitCode", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agent ID">
                {getFieldDecorator("agentId", {
                  initialValue: temporaryData.agentId
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("agentId", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agent Code">
                {getFieldDecorator("agentCode", {
                  initialValue: temporaryData.agentCode
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("agentCode", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <div className={styles.searchBtnBox}>
            <Button onClick={this.handleSubmit} className={styles.ant_btn}>
              Search
            </Button>
            {/* <Button
              onClick={this.handleResetForm}
              className={styles.ant_btn_reset}
            >
              Reset
            </Button> */}
          </div>
        </Form>
        {isShowTableT ? (
          <Table
            columns={columns}
            dataSource={items}
            pagination={{
              total,
              pageSize: 10,
              current: temporaryData.currentNum
            }}
            size="small"
            loading={searchLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="userId"
          />
        ) : null}
      </div>
    );
  }
}

const TrainingRequirementSearch = Form.create()(SearchForm);

export default TrainingRequirementSearch;
