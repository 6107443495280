import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Row, Col, Input, DatePicker, Table, Button, Modal, Select } from 'antd'
import { getTabelData, getShiftToDate, handleSubmit } from '../../../../redux/pclaReducer/CPDSettingsReducers/ShiftInOutReducer'
import styles from './ShiftInOut.module.less'

const { Option } = Select
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const formLayout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  span: 8
};

const flexCol2 = {
  span: 24
};
const flexRow = {
  gutter: [22, 0]
};
const { RangePicker } = DatePicker
@connect(
  ({ pclaReducer: { CPDSettingsReducers: { ShiftInOutReducer } } }) => ({ ShiftInOutReducer }),
  { getTabelData, getShiftToDate, handleSubmit }
)

class Formes extends React.Component {
  state = {
    visible: false,
    currentPageNumber: 1,
    pageSize: 10,
    fcCode: '',
    courseId: '',
    shiftFromDate: '',
    userId: '',
    fromCompletionDate: '',
    toCompletionDate: '',
    agentCode: '',
    columns: [
      {
        title: "FC Code",
        dataIndex: "agentCode",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Completed Date",
        dataIndex: "dateCompletedV",
        align: "center",
      },
      {
        title: "Effect Year",
        dataIndex: "effectDateCompletedV",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Course Code",
        dataIndex: "courseCode",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Course Name",
        dataIndex: "name",
        align: "center",
        ellipsis: true,
      },
      {
        title: "CPD Category and Type",
        dataIndex: "cpdcategoryAndType",
        align: "center",
        render: (text) => {
          const arr = text ? text.split(',') : []
          return (
            <div>
              {arr.map((elt) => <div key={elt}>{elt}</div>)}
            </div>)
        }
      },
      {
        title: "Last Update Date",
        dataIndex: "lastUpdateDate",
        align: "center",
      },
      {
        title: "Updated By",
        dataIndex: "updatedBy",
        align: "center",
        ellipsis: true,
      },
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        ellipsis: true,
        render: (text, record) => {
          return (
            <span className={styles.spanCss} onClick={() => this.handleEdit(record)}>
              Edit
            </span>
          )
        }
      }

    ]
  }

  componentDidMount() {
    this.props.getTabelData({
      agentCode: '',
      fromCompletionDate: '',
      toCompletionDate: '',
      currentPageNumber: 1,
      pageSize: this.state.pageSize
    })
    this.props.getShiftToDate()
  }

  handleEdit = (record) => {
    const { agentCode, courseId, effectDateCompletedV, userId } = record
    this.setState({ fcCode: agentCode, courseId, shiftFromDate: effectDateCompletedV, userId, visible: true })
  }

  handleSearch = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { agentCode, completeDate } = values
        const [start, end] = completeDate || [];
        const fromCompletionDate = start ? moment(start).format("YYYY-MM-DD") : ''
        const toCompletionDate = end ? moment(end).format("YYYY-MM-DD") : ''
        this.props.getTabelData({ agentCode: agentCode || '', fromCompletionDate, toCompletionDate, currentPageNumber: 1, pageSize: this.state.pageSize })
        this.setState({ currentPageNumber: 1, agentCode, fromCompletionDate, toCompletionDate })
      }
    });
  }

  handleOk = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { courseId, shiftFromDate, userId, agentCode, fromCompletionDate, toCompletionDate } = this.state
        const { shiftToDate } = values
        const submitData = {
          courseId, shiftFromDate, shiftToDate, userId
        }
        const callback = () => {
          this.props.getTabelData({
            agentCode,
            fromCompletionDate,
            toCompletionDate,
          })
        }
        this.props.handleSubmit(submitData, callback)
        this.setState({
          fcCode: '',
          courseId: '',
          shiftFromDate: '',
          userId: '',
          visible: false,
        });
        form.resetFields()
      }
    });
  };

  handleCancel = (props) => {
    props.form.resetFields()
    this.setState({
      fcCode: '',
      courseId: '',
      shiftFromDate: '',
      userId: '',
      visible: false,
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { agentCode, fromCompletionDate, toCompletionDate } = this.state
    this.setState({ currentPageNumber: current })
    this.props.getTabelData(
      {
        pageSize,
        agentCode,
        fromCompletionDate,
        toCompletionDate,
        currentPageNumber: current,
      }
    );
  };

  render() {
    const { form: { getFieldDecorator }, ShiftInOutReducer: { userCourseData: { userList, total }, tableLoading, shiftToList } } = this.props
    const { columns, visible, currentPageNumber, fcCode, courseId, shiftFromDate, pageSize } = this.state
    return (
      <div className={styles.container}>
        <div className={styles.heading}>Shift In / Shift Out</div>
        <Form
          colon={false}
          {...formLayout}
          labelAlign="left"
          className={styles.ant_form}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="FC Code">
                {getFieldDecorator("agentCode", {
                })(
                  <Input />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Completed Date">
                {getFieldDecorator("completeDate", {})(
                  <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={["Start Time", "End Time"]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className={styles.btnBox}>
          <Button className={styles.ant_btn} onClick={this.handleSearch}>
            Search
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={userList || []}
          pagination={{
            current: currentPageNumber,
            pageSize,
            total
          }}
          size="small"
          loading={
            tableLoading
          }
          onChange={this.handleTableChange}
          className={styles.ant_dev_table}
          rowKey={(rowInfo) => `${rowInfo.courseId + rowInfo.userId}`}
        />

        <ModelForm
          shiftToList={shiftToList}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          visible={visible}
          fcCode={fcCode}
          courseId={courseId}
          shiftFromDate={shiftFromDate}
        />
      </div>
    )
  }
}


const ShiftInOut = Form.create()(Formes);

function Formes2(props) {
  const { form: { getFieldDecorator }, shiftToList, visible, handleOk, handleCancel, fcCode, courseId, shiftFromDate } = props
  return (
    <Modal
      title="Shift In / Shift Out"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText='Save'
      okType='danger'
    >

      <Form
        colon={false}
        {...formLayout2}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <Form.Item label="FC Code">
              {getFieldDecorator("fcCode", {
                initialValue: fcCode
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Course Id">
              {getFieldDecorator("courseId", {
                initialValue: courseId
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Shift From">
              {getFieldDecorator("shiftFromDate", {
                initialValue: shiftFromDate
              })(
                <Input disabled />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item label="Shift To">
              {getFieldDecorator("shiftToDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    validator: async (rule, value) => {
                      if (value && value === shiftFromDate) {
                        throw new Error('Shift To cannot select the same year of Shift From');
                      }
                    },
                  }
                ]
              })(
                <Select>
                  {
                    shiftToList.map(elt => <Option key={elt.year} value={elt.year}>{elt.year}</Option>)
                  }
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModelForm = Form.create()(Formes2);

export default ShiftInOut;
