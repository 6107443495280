import React, { Component } from "react";
import { Spin } from "antd";
import { get } from '../../../../../utils/request';
import withConnector from '../connector';
import CustomList from '../../../../components/CustomList';
import { getSearchFileds } from './searchFileds';
import { getColumns } from './columns';
import './index.less';

@withConnector
class CourseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchUrl: '/attendanceTms/getCourseList',
      headerTitle: 'Course Finder',
      keyword: 'CourseFinderList',
      rowKey: 'sessionId',
      searchFileds: [],
      columns: [],
      loading: false,
      needTimestamp: false,
      downloadUrl: '/attendanceTms/downloadSessionInfo',
      downloadFileName: 'CourseList.xls'
    }
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    const venueRegionList = await this.getVenueRegionList();
    const venueNameList = await this.getVenueNameList();
    const trainerNameList = await this.getTrainerNameList();

    this.setState({
      searchFileds: getSearchFileds(venueRegionList, venueNameList, trainerNameList),
      columns: getColumns(),
      loading: false
    });
  }

  async getVenueRegionList() {
    const list = await get("/attendanceTms/getVenueRegionList");
    return list.data;
  }

  async getVenueNameList() {
    const list = await get("/attendanceTms/getVenueNameList");
    return list.data;
  }

  async getTrainerNameList() {
    const list = await get("/attendanceTms/getTrainerNameList");
    return list.data;
  }

  render() {
    const { loading } = this.state;
    return (
      <div className="course-finder-list">
        <Spin spinning={loading}>
          <CustomList
            {...this.state}
          />
        </Spin>
      </div>
    )
  }
}

export default CourseList;
