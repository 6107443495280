import { notification } from "antd";
import { isArray, remove } from "lodash";
import moment from "moment";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

export const SAVE_DATA = 'ATTENDANCE_REPORT_DETAIL_SAVE'
export const ONCAHENG_TYPE = "ATTENDANCE_REPORT_DETAIL_GetOptions"

export const INIT_DATA = "ATTENDANCE_REPORT_DETAIL_INIT_DATA"


export const initState = {
  orderBy: {
    value: ""
  },
  orderField: {
    value: ""
  },
  attendanceReports: [],
  totalPage: 0,
  total: 0,
  currentPageNumber: 1,
  currentOverAllPageNumber: 1,
  pageSize: 10,
};


// reducer
export default function AttendanceReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE_DATA:
    case ONCAHENG_TYPE:
      return { ...state, ...action.payload };
    case INIT_DATA:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA,
  };
}

const baseApiUrl = '/pruventure'
const reportUrl = `${baseApiUrl}/attendances/report`


/**
 * @param {*} payload
 * @returns
 */
export function getAttendanceDetailsPeriod(id, payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${reportUrl}/${id}/periodically`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
              attendanceReports: data.items,
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              attendanceReports: [],
            }
          })
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
          dispatch({
            type: SAVE_DATA,
            payload: {
              attendanceReports: [],
            }
          })
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 * @returns
 */
export function getAttendanceDetailsOverAll(id, payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${reportUrl}/${id}/overall`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          const overallPage = data.currentPageNumber
          delete data.currentPageNumber
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
              currentOverAllPageNumber: overallPage,
              attendanceReports: data.items,
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              attendanceReports: [],
            }
          })
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
          dispatch({
            type: SAVE_DATA,
            payload: {
              attendanceReports: [],
            }
          })
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }, 500);
      });
  };
}
