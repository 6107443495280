// login module
export const boxright = require("./login/boxright.png");
export const boxleft = require("./login/boxleft.png");
export const bg = require("./login/bg.png");
export const bg2 = require("./login/bg2.png");
export const logo = require("./login/logo.png");
export const psd = require("./login/psd.png");
export const head = require("./login/head.png");
export const email = require("./login/email.png");
export const back = require("./login/back.png");

// home module
export const HOME_LOGO = require("./home/logo.png");
export const HOME_ARROW = require("./home/arrow.png");
export const HOME_AVATAR = require("./home/avatar.png");
export const HOME_SYSTEMSETTINGS_DOWNLOAD = require("./home/systemSettings/download.png");
export const HOME_SYSTEMSETTINGS_IMPORT = require("./home/systemSettings/import.png");
export const HOME_SYSTEMSETTINGS_USER = require("./home/systemSettings/user.png");
export const HOME_SYSTEMSETTINGS_INTERWORKING = require("./home/systemSettings/interworking.png");
export const file = require("./home/file.png");
export const RED_TRIANGLE = require("./home/redTriangle.png");
export const BLACK_TRIANGLE = require("./home/blackTriangle.png");

export const deleteIcon = require("./CPDSettings/delete.png");
export const addIcon = require("./CPDSettings/add.png");

export const attendanceConsole = require("./attendance/console.png");
export const attendanceDetails = require("./attendance/details.png");
export const attendanceScanIn = require("./attendance/scanin.png");
export const attendanceScanOut = require("./attendance/scanout.png");

// home/system_setting/survey
export const upArrow = require("./survey/up.png");
export const downArrow = require("./survey/down.png");
export const deleteArrow = require("./survey/delete.png");

// home/system_setting/questionGroup
export const upArrowRed = require("./questionGroup/up.png");
export const downArrowRed = require("./questionGroup/down.png");
export const deleteArrowRed = require("./questionGroup/delete.png");

// reminder
export const editIcon = require("./reminder/edit.png");

// notFound
export const notFound = require("./notFound/notFound.png");

// achievement
export const finish = require("./achievement/in.png");
export const notfinish = require("./achievement/out.png");
