import { SelectList, SearchInput } from './components';
import { agentStatus, accountStatus, accountStatusAdmin } from '../constants';

export const searchFileds = {
  Name: {
    key: 'name',
    component: SearchInput,
    props: {
      label: 'Name',
      name: 'name'
    }
  },
  NameAdmin: {
    key: 'name_admin',
    component: SearchInput,
    props: {
      label: 'Name',
      name: 'userName'
    }
  },
  agentStatus: {
    key: 'AgentStatus',
    component: SelectList,
    props: {
      label: 'Agent Status',
      name: 'agentStatus',
      options: agentStatus
    }
  },
  agentCode: {
    key: 'agentCode',
    component: SearchInput,
    props: {
      label: 'Agent Code',
      name: 'agentCode'
    }
  },
  accountStatus: {
    key: 'AccountStatus',
    component: SelectList,
    props: {
      label: 'Account Status',
      name: 'AccountStatus',
      options: accountStatus
    }
  },
  accountStatusAdmin: {
    key: 'AccountStatus',
    component: SelectList,
    props: {
      label: 'Account Status',
      name: 'status',
      options: accountStatusAdmin
    }
  },
  hkid: {
    key: 'hkid',
    component: SearchInput,
    props: {
      label: 'HKID',
      name: 'hkid'
    }
  },
  otherId: {
    key: 'otherId',
    component: SearchInput,
    props: {
      label: 'Other ID',
      name: 'otherId'
    }
  },
  staffId: {
    key: 'staffId',
    component: SearchInput,
    props: {
      label: 'Staff ID',
      name: 'staffId'
    }
  },
  loginAccount: {
    key: 'loginAccount',
    component: SearchInput,
    props: {
      label: 'Login Account',
      name: 'loginAccount'
    }
  },
  email: {
    key: 'email',
    component: SearchInput,
    props: {
      label: 'Email',
      name: 'email'
    }
  },
}
