import { notification } from "antd";
import { isArray } from "lodash";
import moment from "moment";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

export const SAVE_DATA = 'ATTENDANCE_REPORT_LIST_SAVE'
export const ONCAHENG_TYPE = "ATTENDANCE_REPORT_GetOptions"

export const INIT_DATA = "ATTENDANCE_REPORT_INIT_DATA"

export const fields = {
  agentId: {
    key: "",
    value: ""
  },
  reportPeriod: {
    key: "",
    value: undefined,
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: undefined,
  },
  status: {
    key: "",
    value: ""
  },
}

export const initState = {
  agentId: {
    key: "",
    value: ""
  },
  reportPeriod: {
    key: "",
    value: undefined
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: undefined
  },
  status: {
    key: "",
    value: "1"
  },
  orderBy: {
    // ASCENDING
    value: ""
  },
  requirementStatuses: {
    value: []
  },
  courses: [],
  registrationConfig: null,
  agentIds: [],
  regions: [],
  unitCodes: [],
  agentLeaders: [],
  statuses: [],
  attendanceReports: [],
  totalPage: 0,
  currentPageNumber: 1,
  pageSize: 10,
  downLoading: false
};


// reducer
export default function AttendanceReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE_DATA:
    case ONCAHENG_TYPE:
      return { ...state, ...action.payload };
    case INIT_DATA:
      return {...initState}
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA
  };
}

const baseApiUrl = '/pruventure'
const baseOptionsUrl = `${baseApiUrl}/registrations/options`
const reportUrl = `${baseApiUrl}/attendances/report`

/**
 * @param {*} payload {search: string}
 */
export function getUnitCodes(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/unitCodes`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [],
            }
          })
          notification.error({
            message: 'get unitCodes error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getStatus(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/status`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          if (data.length > 0) {
            dispatch({
              type: SAVE_DATA,
              payload: {
                statuses: data,
              }
            });
          }
        } else {
          notification.error({
            message: 'get status error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getRegions(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/regions`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              regions: data,
            }
          });
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentLeaders(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentLeaders`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentLeaders: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentIds(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentIds`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentIds: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function downLoadExcel(fileName, payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${reportUrl}/registrations/excel`, payload, {}, 'blob')
      .then(blob => {
        downloadFile(blob.blob, blob.fileName);
        dispatch(loading(false));
      })
      .catch((res) => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * {
    "pageSize": 0,
    "currentPageNumber": 0,
    "orderBy": "string",
    "agentId": "string",
    "agentLeader": "string",
    "region": "string",
    "unitCode": "string",
    "registrationDate": "2023-01-12",
    "reportPeriod": "2023-01-12",
    "status": "Active"
  }
 * @param {*} payload
 * @returns
 */
export function getAttendanceReports(payload) {
  return dispatch => {
    dispatch(loading(true))
    get(`${reportUrl}/registrations`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
              attendanceReports: data.items,
            }
          })
        } else {
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch({
            type: SAVE_DATA,
            payload: {
              attendanceReports: [],
            }
          })
          dispatch(loading(false));
          notification.error({
            message: 'get attendance report error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getRequirements(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}/requirements`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              requirements: data ?? [],
            }
          })
        } else {
          notification.error({
            message: 'get requirements error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
        notification.error({
          message: 'get requirements error',
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        })
      });
  };
}
