import axios from 'axios'
import { originalUrlPrefix } from "./config";
import { clearStorage } from "./index";

export const handleCheckToken = async (config) => {
  const refreshToken = sessionStorage.getItem('refreshToken')
  const buCountry = sessionStorage.getItem('buCountry')
  const str = buCountry ? buCountry.toLocaleLowerCase() : ''
  let baseUrl = originalUrlPrefix[str]
  if (config) {
    baseUrl = config.baseURL
  }

  try {
    const res = await axios({
      method: 'POST',
      url: `${baseUrl}/login/refresh-token`,
      params: { refreshToken },
    })

    const { data: { token, refreshToken: refresh } } = res || {}
    if (token) {
      sessionStorage.setItem('user', token)
      sessionStorage.setItem('refreshToken', refresh)
      sessionStorage.removeItem('onlyPopFirstErr')
      if (config) {
        return axios.request(config);
      }
      return true
    } else {
      return setTimeout(clearStorage, 3000)
    }
  } catch (error) {
    const { code } = error
    if (code === 9906) {
      sessionStorage.setItem('onlyPopFirstErr', true)
      return setTimeout(clearStorage, 3000)
    }
  }
}
