import { combineReducers } from "redux";
import ManagementReportReducer from "./ManagementReportReducer";
import AttendedSummaryReducer from "./AttendedSummaryReducer";
import DeductionReportReducer from "./DeductionReportReducer";
import TrainingReportReducer from "./TrainingReportReducer";
import AttendanceSummaryReducer from "./AttendanceSummaryReducer";
import RegistrationSummaryReducer from "./RegistrationSummaryReducer";
import RegistrationAttendanceReducer from "./RegistrationAttendanceReducer";
import SurveyReportsReducer from "./SurveyReportsReducer";

export default combineReducers({
  ManagementReportReducer,
  AttendedSummaryReducer,
  DeductionReportReducer,
  TrainingReportReducer,
  AttendanceSummaryReducer,
  RegistrationSummaryReducer,
  RegistrationAttendanceReducer,
  SurveyReportsReducer
});
