
export const getColumns = () => {
  return [
    {
      title: "No",
      dataIndex: "no",
      ellipsis: true,
      align: "center",
      render: (text, item, index) => {
        /* eslint-disable */
        const a = text;
        const b = item;
        /* eslint-disable */
        const c = index + 1;
        return c;
      }
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Module Name",
      dataIndex: "moduleName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Session Name",
      dataIndex: "sessionName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Training Date",
      dataIndex: "trainingDate",
      ellipsis: true,
      align: "center"
    },
    {
      title: "userLoginAccount",
      dataIndex: "loginAccount",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Name",
      dataIndex: "userName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Role",
      dataIndex: "roleName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Unit Code",
      dataIndex: "unitCode",
      ellipsis: true,
      align: "center"
    }
  ];
}
