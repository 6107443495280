import React, { useState, useEffect, useRef } from "react";
import debounce from 'lodash/debounce';
import { useMount } from "ahooks";
import { Form, Col, Select } from "antd";
import { flexCol } from './utils';

const { Option } = Select;

function DebounceSelect({ handleSaveField, getFieldDecorator, name, label, placeholder = '', initialValue, onFieldChange, fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const fetchRef = React.useRef(0);
  const [value, setValue] = React.useState('');

  useMount(async () => {
    // only excute once when component mounted
      await fetchDataByKeyword('');
    })

  const fetchDataByKeyword = async (keyword = '') => {
    const res = await fetchOptions(keyword);

    const options = (res.data || []).map(item => {
      return {
        value: item.agentCode,
        label: item.userName
      }
    })
    setOptions(options);

    return options;
  }

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchDataByKeyword(value).then(async (newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        await handleValueChange(value)
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  const handleValueChange = async (newValue) => {
    setValue(newValue);
    const option = newValue ? options : await fetchDataByKeyword(newValue)
    onFieldChange && onFieldChange(newValue, option)
  }

  return (
    <Col {...flexCol}>
      <Form.Item label={label}>
        {getFieldDecorator(`${name}`, {
          initialValue: initialValue || [],
        })(
          <Select
            value={value}
            filterOption={false}
            placeholder={placeholder}
            fetchOptions={fetchOptions}
            onChange={handleValueChange}
            allowClear
            showSearch
            onSearch={debounceFetcher}
            {...props}
          >
            {
              options && options.length > 0 && options.map((item, index) => {
                return (
                  <Option key={`${item.value}-${index}`} value={item.value}>{item.value}</Option>
                )
              })
            }
          </Select>
        )}
      </Form.Item>
    </Col>
  );
}

export default DebounceSelect;
