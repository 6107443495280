import React, { Component } from "react";
import { Form, Col, Input } from "antd";
import { flexCol } from '../../utils';

export default class Name extends Component {
  render() {
    const { handleSaveField, getFieldDecorator, name, label, initialValue } = this.props;
    return (
      <Col {...flexCol}>
        <Form.Item label={label}>
          {getFieldDecorator(`${name}`, {
            initialValue: initialValue || ''
          })(
            <Input
              onChange={e => handleSaveField(e.target.value)}
            />
          )}
        </Form.Item>
      </Col>
    );
  }
}
