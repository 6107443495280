import { notification } from "antd";
import { isArray } from "lodash";
import moment from "moment";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

export const SAVE_DATA = 'ATTENDANCE_LIST_REGISTRATIONS_SAVE'
export const ONCAHENG_TYPE = "ATTENDANCE_LIST_REGISTRATIONS_GetOptions"

export const INIT_DATA = "ATTENDANCE_LIST_REGISTRATIONS_INIT_DATA"

export const fields = {
  agentId: {
    key: "",
    value: ""
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: "",
  },
  status: {
    key: "",
    value: ""
  },
}

export const initState = {
  agentId: {
    key: "",
    value: ""
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: "",
  },
  status: {
    key: "",
    value: ""
  },
  orderBy: {
    // ASCENDING
    value: ""
  },
  courseCode: {
    value: ""
  },
  courseName: {
    value: ""
  },
  courseId: {
    value: ""
  },
  courses: [],
  registrationConfig: null,
  agentIds: [],
  regions: [],
  unitCodes: [],
  agentLeaders: [],
  statuses: [],
  registrations: [],
  totalPage: 0,
  currentPageNumber: 1,
  pageSize: 10,
  downLoading: false
};

// reducer
export default function RegistrationListReducer(state = initState, action) {
  switch (action.type) {
    case ONCAHENG_TYPE:
    case SAVE_DATA:
      return { ...state, ...action.payload };
    case INIT_DATA:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA
  };
}

export function changeVals(type, val) {
  return dispatch => {
    return dispatch({
      type,
      payload: val
    });
  };
}

const baseApiUrl = '/pruventure/registrations'
const baseOptionsUrl = `${baseApiUrl}/options`

/**
 * @param {*} payload {search: string}
 */
export function getUnitCodes(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/unitCodes`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [],
            }
          })
          notification.error({
            message: 'get unitCodes error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getStatus(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/status`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          if (data.length > 0) {
            dispatch({
              type: SAVE_DATA,
              payload: {
                statuses: data,
                status: data[0],
              }
            });
          }
        } else {
          notification.error({
            message: 'get status error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getRegions(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/regions`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              regions: data,
              region: data[0]
            }
          });
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentLeaders(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentLeaders`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentLeaders: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentIds(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentIds`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentIds: [{key: 'All', value: ''}, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * {
    "pageSize": 0,
    "currentPageNumber": 0,
    "orderBy": "string",
    "agentId": "string",
    "agentLeader": "string",
    "region": "string",
    "unitCode": "string",
    "registrationDate": "2023-01-12",
    "status": "Active"
  }
 * @param {*} payload
 * @returns
 */
export function getRegistrations(payload) {
  return dispatch => {
    dispatch(loading(true))
    get(`${baseApiUrl}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
              registrations: data.items,
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function downLoadExcel(fileName, payload) {
  return dispatch => {
    dispatch(loading(true))
    get(`${baseApiUrl}/excel`, payload, {}, 'blob')
      .then(blob => {
        downloadFile(blob.blob, blob.fileName);
        dispatch(loading(false));
      })
      .catch((res) => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// id: registrationId
export function deleteRegistrations(id, payload) {
  return dispatch => {
    dispatch(loading(true))
    return deleteReq(`${baseApiUrl}/${id}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          notification.success({
            message: 'delete successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'delete registration error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function updateRegistrations(id, payload, isStatus) {
  return dispatch => {
    dispatch(loading(true))
    return put(`${baseApiUrl}/${id}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          notification.success({
            message: isStatus ? 'update agent status successfully' :
            'update registration date successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'update registration error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * get courses from litmos
 * @param {*} payload undefined
 * @returns
 */
export function getCourses(payload) {
  return dispatch => {
    dispatch(loading(true));
    get(`${baseApiUrl}/courses`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const courses = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              courses: courses ?? [],
            }
          })
        } else {
          notification.error({
            message: 'You do not have relevant litmos courses',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * create registration
 * @param {*} payload {courseCode: string, courseId: string}
 * @returns
 */
export function CreateRegistration(payload, callBack) {
  return dispatch => {
    dispatch(loading(true));
    return post(`${baseApiUrl}/config`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          dispatch({
            type: SAVE_DATA,
            payload: {
              registrationConfig: res.data
            }
          })
          if (callBack) {
            callBack(res.data)
          }
          notification.success({
            message: 'Registration list is created successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'Failed to create registration list',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch((r) => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 * @returns
 */
export function CheckRegistration(callBack, payload) {
  return dispatch => {
    dispatch(loading(true));
    return get(`${baseApiUrl}/config`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          dispatch({
            type: SAVE_DATA,
            payload: {
              registrationConfig: res.data,
              courseName: {value: res.data.courseName},
              courseCode: {value: res.data.courseCode},
              courseId: {value: res.data.courseId}
            }
          })
          if (callBack) {
            callBack(res.data)
          }
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 1000);
      });
  };
}

/**
 * @param {*} payload
 * @returns
 */
export function DeleteRegistration(callBack, payload) {
  return dispatch => {
    dispatch(loading(true));
    return deleteReq(`${baseApiUrl}/config`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          dispatch({
            type: SAVE_DATA,
            payload: {
              registrationConfig: null
            }
          })
          if (callBack) {
            callBack(res.data)
          }
          notification.success({
            message: 'Linkage with Litmos course is removed successfully',
            duration: globalPromptTime10s,
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 1000);
      });
  };
}
