import React, { Component } from "react";
import { Form, Row, Col, Input, Button, Spin, notification } from "antd";
import { get } from '../../utils/request';
import styles from "./Forget.module.less";

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
  }

  handleSubmit = () => {
    this.setState({
      loading: true
    })
    get("/login/generateLink", {
      loginAccount: sessionStorage.getItem("email"),
      subject: "forget_password"
    })
      .then(res => {
        if (res.success) {
          notification.success({
            message: "Email sent successfully",
            duration: 10
          });
        } else {
          notification.error({
            message: res.message,
            duration: 10,
            className: "ant-style-notification-error"
          });
        }
        this.setState({
          loading: false
        })
      })
      .catch(() => {
        this.setState({
          loading: false
        })
        notification.error({
          message: "Please retry later",
          duration: 10,
          className: "ant-style-notification-error"
        });
      });
  };

  render() {
    return (
      <div className={styles.container} style={{ paddingTop: "50px" }}>
        <Spin
          tip="Loading..."
          size="large"
          spinning={this.state.loading}
          delay={100}
        ><Form
          layout="horizontal"
          labelCol={{ span: 7 }}
          labelAlign="left"
          wrapperCol={{ span: 17 }}
          colon={false}
        >
            <Row gutter={[24, 0]}>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  <Input disabled placeholder="Name" value={sessionStorage.getItem("username")} />
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  <Input disabled placeholder="Login Account" value={sessionStorage.getItem("email")} />
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <div style={{ textAlign: "center", fontWeight: 'bold' }}>An email will be sent to your account for you to reset the password.</div>
              </Col>
            </Row>
            <Row className={styles.mt10}>
              <Col span={24} style={insideStyles.goCenter}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={this.state.loading}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={this.handleSubmit}
                  >
                    Send Email
                  </Button>
                </Form.Item>
              </Col>
            </Row>
         </Form>
        </Spin>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
};

const flexCol = {
  lg: 12,
  offset: 6
};

export default ForgetPassword;
