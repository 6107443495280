import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INITDATA_SURVEY_REPORT = "PAMB/INITDATA_SURVEY_REPORT";
const SAVE_SURVEY_REPORT_DATA = "PAMB/SAVE_SURVEY_REPORT_DATA";

const initState = {
  courseName: {
    value: []
  },
  faculty: {
    value: []
  },
  trainingDate: {
    value: ""
  },
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1,
  courseList: [],
  facultyList: [],
  searchList: [],
  searchShortList: [],
  showList: false
};

export default function SurveyReportsReducer(state = initState, action) {
  switch (action.type) {
    case "SurveyReports":
      return { ...state, ...action.payload };
    case INITDATA_SURVEY_REPORT:
      return { ...initState };
    case SAVE_SURVEY_REPORT_DATA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_SURVEY_REPORT
  };
}

export function save_data(payload) {
  return {
    type: SAVE_SURVEY_REPORT_DATA,
    payload
  };
}
// GET /commentsFeedbackReport/getCourseNameList
export function getCourseList() {
  return dispatch => {
    get("/commentsFeedbackReport/getCourseNameList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              courseList: res.data.length ? [...res.data] : [],
              courseName: {
                value: "All"
              }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}


// GET /commentsFeedbackReport/getFacultyList
export function getFacultyList() {
  return dispatch => {
    get("/commentsFeedbackReport/getFacultyList")
      .then(res => {
        if (res.success) {
          const arr = res.data.filter((item) => {
            return item != null
          })
          dispatch(
            save_data({
              facultyList: arr,
              faculty: {
                value: "All"
              }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /commentsFeedbackReport/getCommentsFeedbackReportList
export function getCommentsFeedbackReportList(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/commentsFeedbackReport/getCommentsFeedbackReportList", data)
      .then(res => {
        if (res.success) {
          const arr = res.data.data.map((item) => {
            item.index = `${item.sessionId}_${item.pambQuestionId}_${item.agentId}`;
            return item;
          })
          dispatch(
            save_data({
              searchList: arr,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber,
              showList: true
            })
          );
          // const { list, grossTotal, sstTotal, installment } = res.data;
          // dispatch(
          //   save_data({
          //     grossTotal,
          //     sstTotal,
          //     installment,
          //     searchList: [...list],
          //     showList: true
          //   })
          // );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
// POST /commentsFeedbackReport/downloadCommentsFeedbackReport
export function downloadCommentsFeedbackReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/commentsFeedbackReport/downloadCommentsFeedbackReport", data, {}, "blob")
      .then(blob => {
        const fileName = "CommentsFeedbackReport.xls";
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
