import React from "react";

// import Login from "../views/user/Login";
import ForgetPassword from "../views/user/ForgetPassword";
import Home from "../views/home";

import Forbidden from "../views/exception/Forbidden";
import NotFound from "../views/exception/NotFound";
import ServerError from "../views/exception/ServerError";

// systemSettings
import SystemSettings from "../views/home/regional/systemSettings";
import UserManagement from "../views/home/regional/systemSettings/userManagement";
import UserManagementHome from "../views/home/regional/systemSettings/userManagement/UserManagementHome";

import CreateRegionAdmin from "../views/home/regional/systemSettings/userManagement/CreateRegionAdmin";
import CreateLBUAdmin from "../views/home/regional/systemSettings/userManagement/CreateLBUAdmin";

import AdminDetail from "../views/home/regional/systemSettings/userManagement/AdminDetail";
import AdminDetailLBU from "../views/home/regional/systemSettings/userManagement/AdminDetailLBU";
import ResetPassword from "../views/home/regional/systemSettings/userManagement/ResetPassword";

// myProfile
import MyProfileSetting from "../views/home/regional/myProfile";
import ChangePassword from "../views/home/regional/myProfile/ChangePassword";
import MyProfile from "../views/home/regional/myProfile/myProfile";

// TimeJobRerun
import TimeJobRerun from "../views/home/pacs/systemSettings/TimeJobRerun";
import TimeJobRerunList from "../views/home/pacs/systemSettings/TimeJobRerun/List";
import TimeJobRerunDetail from "../views/home/pacs/systemSettings/TimeJobRerun/Detail";

// PAMBTimeJobRerun
import PAMBTimeJobRerun from "../views/home/pamb/systemSettings/TimeJobRerun";
import PAMBTimeJobRerunList from "../views/home/pamb/systemSettings/TimeJobRerun/List";
import PAMBTimeJobRerunDetail from "../views/home/pamb/systemSettings/TimeJobRerun/Detail";

/**
 * hasSecondRoute: If the first level of menu has a second level of sidebar routing, this attribute should be added. The main function is to dynamically load the first sidebar menu
 * redirctPath: The back-end returned path, but it is not the final route, which needs to be redirected
 * customDevPath: The back-end does not returned path, but the front-end local needs to configure the redirected route
 * initialRoute: The back-end does not returned path，it is Locally initialized route
 */
const RouteConfig = [
  {
    title: "home",
    path: "/home",
    component: Home,
    initialRoute: true,
    children: [
      {
        id: "my_profile_setting",
        title: "Las Back-stage management",
        path: "/home/my_profile_setting",
        hasSecondRoute: true,
        component: MyProfileSetting,
        children: [
          {
            id: "my_profile_admin",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/my_profile",
            component: () => <MyProfile />
          },
          {
            id: "change_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/change_password",
            component: () => <ChangePassword />
          },
          {
            id: "forget_prubehere_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/forget_prubehere_password",
            component: () => <ForgetPassword />
          }
        ]
      },
      {
        id: "system_settings",
        title: "Las Back-stage management",
        path: "/home/system_settings",
        component: SystemSettings,
        hasSecondRoute: true,
        children: [
          {
            id: "user_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/user_management",
            redirctPath: "/home/system_settings/user_management/index",
            component: UserManagement,
            children: [
              {
                id: "user_management_index",
                customDevPath: true,
                path: "/home/system_settings/user_management/index",
                component: UserManagementHome,
                exact: true
              },
              {
                id: "create_region_admin",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/create_region_admin",
                component: CreateRegionAdmin
              },
              {
                id: "create_lbu_admin",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/create_lbu_admin",
                component: CreateLBUAdmin
              },
              {
                id: "reset_password",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/reset_password",
                customDevPath: true,
                component: ResetPassword
              },
              {
                id: "admin_info",
                title: "Las Back-stage management",
                path:
                  "/home/system_settings/user_management/region_admin_detail",
                customDevPath: true,
                component: AdminDetail
              },
              {
                id: "lbu_admin_detail",
                title: "Las Back-stage management",
                path: "/home/system_settings/user_management/lbu_admin_detail",
                customDevPath: true,
                component: AdminDetailLBU
              }
            ]
          },
          {
            id: 'time_job_rerun',
            title: "Las Back-stage management",
            path: '/home/system_settings/time_job_rerun',
            redirctPath: "/home/system_settings/time_job_rerun/time_job_rerunlist",
            component: TimeJobRerun,
            children: [
              {
                id: "time_job_rerunlist",
                title: "Las Back-stage management",
                path: "/home/system_settings/time_job_rerun/time_job_rerunlist",
                component: TimeJobRerunList,
                exact: true,
                customDevPath: true
              },
              {
                id: "time_job_rerundetail",
                title: "Las Back-stage management",
                path: "/home/system_settings/time_job_rerun/time_job_rerundetail",
                component: TimeJobRerunDetail,
                exact: true,
                customDevPath: true
              },
            ]
          },
          {
            id: 'pamb_time_job_rerun',
            title: "Las Back-stage management",
            path: '/home/system_settings/pamb_time_job_rerun',
            redirctPath: "/home/system_settings/pamb_time_job_rerun/pamb_time_job_rerunlist",
            component: PAMBTimeJobRerun,
            children: [
              {
                id: "pamb_time_job_rerunlist",
                title: "Las Back-stage management",
                path: "/home/system_settings/pamb_time_job_rerun/pamb_time_job_rerunlist",
                component: PAMBTimeJobRerunList,
                exact: true,
                customDevPath: true
              },
              {
                id: "pamb_time_job_rerundetail",
                title: "Las Back-stage management",
                path: "/home/system_settings/pamb_time_job_rerun/pamb_time_job_rerundetail",
                component: PAMBTimeJobRerunDetail,
                exact: true,
                customDevPath: true
              },
            ]
          }
        ],
      },
      {
        id: "404",
        path: "/home/404",
        component: NotFound,
        initialRoute: true
      },
      {
        id: "403",
        path: "/home/403",
        component: Forbidden,
        initialRoute: true
      },
    ]
  },
  {
    id: "403",
    path: "/403",
    component: Forbidden,
    initialRoute: true
  },
  {
    id: "500",
    path: "/500",
    component: ServerError,
    initialRoute: true
  },
];

export default RouteConfig;
