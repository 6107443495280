import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Table,
  Row,
  Col,
  Popconfirm,
  Divider,
  Select
} from "antd";
import { connect } from "react-redux";
import styles from "./QuestionGroupSearch.module.less";
import {
  queryData,
  handleSearchPageDelete
} from "../../../../../redux/pclaReducer/systemSettingsReducers/QuestionGroupReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

const { Option } = Select;

@connect(
  ({ pclaReducer: { systemSettingsReducers: { QuestionGroupReducer } } }) => ({
    QuestionGroupReducer
  }),
  {
    queryData,
    handleSearchPageDelete
  }
)
class QuestionGroupSearch extends React.Component {
  state = {
    isShowTable: false,
    currentNum: 1,
    columns: [
      {
        title: "Group Name",
        dataIndex: "groupName",
        align: "center",
        width: 200,
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        dataIndex: "groupDescription",
        ellipsis: true
      },
      {
        title: "Actions",
        align: "center",
        width: 240,
        render: (text, record) => {
          const { pambQuestionGroupId, isInUse } = record;
          const toEdit = {
            pathname:
              "/home/system_settings/question_group/question_group_edit",
            state: {
              pambQuestionGroupId
            }
          };
          const toDetail = {
            pathname:
              "/home/system_settings/question_group/question_group_detail",
            state: {
              pambQuestionGroupId
            }
          };
          return (
            <div className={styles.actions}>
              <Link to={toDetail}>
                <span className={styles.spanCss}>View</span>
              </Link>
              {isInUse === "0" ? (
                <div>
                  <Divider type="vertical" />
                  <Link to={toEdit}>
                    <span className={styles.spanCss}>Edit</span>
                  </Link>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Please double confirm?"
                    onConfirm={() => this.handleDelete({ pambQuestionGroupId })}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span className={styles.spanCss}>Delete</span>
                  </Popconfirm>
                </div>
              ) : null}
            </div>
          );
        }
      }
    ]
  };

  handleDelete = id => {
    const { groupName, currentNum, groupType } = this.state;
    const {
      QuestionGroupReducer: {
        questionGroupData: { data: dataList, totalPage }
      }
    } = this.props;

    let current = currentNum;
    if (totalPage > 1 && dataList.length === 1) {
      current = currentNum - 1;
    }
    const callback = () => {
      this.props.queryData({
        groupName,
        groupType,
        currentPageNumber: current
      });
      this.setState({ currentNum: current });
    };

    this.props.handleSearchPageDelete(id, callback);
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { groupName, groupType } = values;
        const callback = () => {
          this.setState({
            isShowTable: true,
            currentNum: 1,
            groupName,
            groupType
          });
        };
        this.props.queryData(
          {
            ...values,
            currentPageNumber: 1
          },
          callback
        );
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    this.setState({ currentNum: current });
    const { groupName, groupType } = this.state;
    this.props.queryData({
      groupName,
      groupType,
      pageSize,
      currentPageNumber: current
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      QuestionGroupReducer: {
        questionGroupData: { data: dataList, total },
        queryLoading,
        trainerList
      }
    } = this.props;

    const { columns, isShowTable, currentNum } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.topBox}>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Group Name">
                  {getFieldDecorator("groupName", {})(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Is Trainer">
                  {getFieldDecorator("groupType", {
                    initialValue: (trainerList && trainerList.length) ? trainerList[0].value : ''
                  })(
                    <Select>
                      {trainerList.map(elt => (
                        <Option value={elt.value} key={elt.value}>
                          {elt.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>

            <Link to="/home/system_settings/question_group/question_group_create">
              <Button className={styles.ant_btn} icon="plus">
                Add
              </Button>
            </Link>
          </div>
        </div>
        {isShowTable ? (
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              total,
              pageSize: 10,
              current: currentNum
            }}
            size="small"
            loading={queryLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="pambQuestionGroupId"
          />
        ) : null}
      </div>
    );
  }
}

const QuestionGroupSearchForm = Form.create()(QuestionGroupSearch);

export default QuestionGroupSearchForm;
