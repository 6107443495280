import { notification } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const UPDATE_BASE_INFORMATION = "PCLA/SESSIONDETAIL/UPDATE_BASE_INFORMATION";
const UPDATE_TABLE_DATA = "PCLA/SESSIONDETAIL/UPDATE_TABLE_DATA";
const INIT_DATA_SESSIONDETAIL = "PCLA/INIT_DATA_SESSIONDETAIL";

const initState = {
  showConfirm: false,
  sessiondayId: "",
  sessionId: "",
  isValid: "",
  sessionLockDate: "",
  sessionLockStatus: "",
  courseName: {
    value: ""
  },
  moduleName: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  startDate: {
    value: ""
  },
  endDate: {
    value: ""
  },
  submitDate: {
    value: ""
  },
  instructorName: {
    value: ""
  },
  submitBy: {
    value: ""
  },
  status: {
    value: ""
  },
  LIAMCourseType: {
    value: ""
  },
  LIAMRequirement: {
    value: ""
  },
  LIAMCPDHours: {
    value: ""
  },
  MTACourseType: {
    value: ""
  },
  MTARequirement: {
    value: ""
  },
  MTACPDHours: {
    value: ""
  },
  ethicCpdHours: {
    value: ""
  },
  rrCpdHours: {
    value: ""
  },
  ahCpdHours: {
    value: ""
  },
  cisCpdHours: {
    value: ""
  },
  othersCpdHours: {
    value: ""
  },
  minSessionDays: {
    value: ""
  },
  venueRegion: {
    value: ""
  },
  venueName: {
    value: ""
  },
  venueAddress: {
    value: ""
  },
  attendanceAgents: [],
  registrationAgents: [],
  attendanceStatistics: {
    absentSum: "",
    attendedSum: "",
    completedSum: "",
    courseCompletedSum: "",
    registeredSum: "",
    walkInSum: ""
  },
  agentType: "", // Registration Attendance
  sessionDay: {
    value: ""
  },
  classCode: {
    value: ""
  },
  sessionDayList: [],
  showQR: false,
  ScanTimes: 0,
  ServerTime: "",
  qrDetail: {}
};

// reducer
export default function SessionDetailReducer(state = initState, action) {
  switch (action.type) {
    case "SessionDetail":
    case UPDATE_TABLE_DATA:
    case UPDATE_BASE_INFORMATION:
      return { ...state, ...action.payload };
    case INIT_DATA_SESSIONDETAIL:
      return initState;
    default:
      return state;
  }
}

export function save_data(payload) {
  return {
    type: UPDATE_BASE_INFORMATION,
    payload
  };
}

export function init_data() {
  return {
    type: INIT_DATA_SESSIONDETAIL
  };
}

export function base_informatio(data) {
  const startDate =
    data.startDate.lastIndexOf(".") > -1
      ? data.startDate.split(".")[0]
      : data.startDate;
  const endDate =
    data.endDate.lastIndexOf(".") > -1
      ? data.endDate.split(".")[0]
      : data.endDate;
  const submitDate =
    data.submitDate && data.submitDate.lastIndexOf(".") > -1
      ? data.submitDate.split(".")[0]
      : data.submitDate;
  const LIAMtrainingCpdHours = data.liamTrainingCpdHours;
  const MTAtrainingCpdHours = data.mtaTrainingCpdHours;

  return {
    type: UPDATE_BASE_INFORMATION,
    payload: {
      sessionId: data.sessionId,
      courseName: {
        value: data.courseName
      },
      moduleName: {
        value: data.moduleName
      },
      sessionName: {
        value: data.sessionName
      },
      startDate: {
        value: startDate
      },
      endDate: {
        value: endDate
      },
      submitDate: {
        value: submitDate
      },
      instructorName: {
        value: data.instructorName
      },
      submitBy: {
        value: data.submitBy
      },
      status: {
        value: data.submitStatus
      },
      LIAMCourseType: {
        value: data.liamCourseType
      },
      LIAMRequirement: {
        value: data.liamRequirement
      },
      LIAMCPDHours: {
        value: LIAMtrainingCpdHours
      },
      MTACourseType: {
        value: data.mtaCourseType
      },
      MTARequirement: {
        value: data.mtaRequirement
      },
      MTACPDHours: {
        value: MTAtrainingCpdHours
      },
      ethicCpdHours: {
        value: data.ethicCpdHours
      },
      rrCpdHours: {
        value: data.rrCpdHours
      },
      ahCpdHours: {
        value: data.ahCpdHours
      },
      cisCpdHours: {
        value: data.cisCpdHours
      },
      othersCpdHours: {
        value: data.othersCpdHours
      },
      minSessionDays: {
        value: data.minSessionDays
      },
      venueName: {
        value: data.venueName
      },
      venueAddress: {
        value: data.venueAddress
      },
      venueRegion: {
        value: data.venueRegion
      },
      sessionLockStatus: data.lockFlag
    }
  };
}

export function save_table_data(payload) {
  return {
    type: UPDATE_TABLE_DATA,
    payload
  };
}

// GET /pamb/attendanceTms/getAttendanceScanTimes
export function getAttendanceScanTimes() {
  return dispatch => {
    get("/attendanceTms/getAttendanceScanTimes")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              ScanTimes: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

export function update_base_information(id) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getCourseDetail", { sessionId: id })
      .then(res => {
        if (res.success) {
          if (res.data) {
            dispatch(base_informatio(res.data));
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function getSessionDayList(sessionId, dayId) {
  return dispatch => {
    get("/attendanceTms/getSessiondayDatetimeList", { sessionId })
      .then(res => {
        if (res.success) {
          if (dayId) {
            dispatch(update_attendance_agents(dayId));
            dispatch(getCourseSessiondayInfo(dayId));
            dispatch(
              save_data({
                sessionDayList: res.data,
                sessiondayId: dayId,
                sessionDay: {
                  value: dayId
                }
              })
            );
          } else {
            const sessiondayId = res.data.length
              ? res.data[0].sessiondayId
              : "";
            if (sessiondayId) {
              dispatch(update_attendance_agents(sessiondayId));
              dispatch(getCourseSessiondayInfo(sessiondayId));
              dispatch(
                save_data({
                  sessionDayList: res.data,
                  sessiondayId: res.data.length ? res.data[0].sessiondayId : "",
                  sessionDay: {
                    value: res.data.length ? res.data[0].sessiondayId : ""
                  }
                })
              );
            }
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

export function getCourseSessiondayInfo(sessiondayId) {
  return dispatch => {
    get("/attendanceTms/getCourseSessiondayInfo", { sessiondayId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              classCode: {
                value: res.data[0].classCode
              },
              qrDetail: { ...res.data[0] }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

export function update_attendance_agents(sessiondayId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getAttendanceAgents", { sessiondayId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_table_data({
              attendanceAgents: [...res.data]
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// GET /attendanceTms/getSessionLockDate 返回yyyy-MM-dd日期
export function getSessionLockDate(sessionId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getSessionLockDate", { sessionId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionLockDate: res.data
            })
          );
          dispatch(getSessionLockStatus(sessionId));
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// GET /attendanceTms/getSessionLockStatus 返回状态Y/N ,Y是锁
export function getSessionLockStatus(sessionId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getSessionLockStatus", { sessionId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionLockStatus: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// GET /attendanceTms/saveUnlockSession 保存下一个锁的日期
export function saveUnlockSession(sessionId, unLockDate) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/saveUnlockSession", { sessionId, unLockDate })
      .then(res => {
        if (res.success) {
          setTimeout(() => {
            notification.success({
              message: "Save successfully",
              duration: globalPromptTime10s
            });
          }, 500);
          dispatch(
            getSessionLockDate(sessionId)
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function update_registration_agents(sessionId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getRegistrationAgents", { sessionId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_table_data({
              registrationAgents: [...res.data]
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /attendanceTms/auditAttendanceBatch
export function auditAttendanceBatch(attendanceIdList, sessiondayId, sessionId) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceTms/auditAttendanceBatch", { attendanceIdList })
      .then(res => {
        if (res.success) {
          setTimeout(() => {
            notification.success({
              message: "Audit successfully",
              duration: globalPromptTime10s
            });
          }, 500);
          dispatch(update_attendance_agents(sessiondayId));
          dispatch(update_registration_agents(sessionId));
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST pacs/attendanceTms/deleteAttendanceBatch
export function deleteAttendanceBatch(attendanceIdList, sessiondayId, sessionId) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceTms/deleteAttendanceBatch", { attendanceIdList })
      .then(res => {
        if (res.success) {
          setTimeout(() => {
            notification.success({
              message: "Deleted successfully",
              duration: globalPromptTime10s
            });
          }, 500);
          dispatch(update_attendance_agents(sessiondayId));
          dispatch(update_registration_agents(sessionId));
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// GET pamb/attendanceTms/deleteAttendance
export function delete_attendance(attendanceId, sessiondayId, sessionId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/deleteAttendance", { attendanceId })
      .then(res => {
        if (res.success) {
          setTimeout(() => {
            notification.success({
              message: "Deleted successfully",
              duration: globalPromptTime10s
            });
          }, 500);
          dispatch(update_attendance_agents(sessiondayId));
          dispatch(update_registration_agents(sessionId));
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pamb/attendanceTms/downLoadRegistrationInfo
export function downLoad_registration_info(sessiondayId) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post(
      "/attendanceTms/downLoadRegistrationInfo",
      {},
      { sessiondayId },
      "blob"
    )
      .then(blob => {
        const fileName = "Registration.csv"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pamb/attendanceTms/downLoadAttendanceInfo
export function downLoad_attendance_info(id) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post(
      "/attendanceTms/downLoadAttendanceInfo",
      {},
      { sessiondayId: id },
      "blob"
    )
      .then(blob => {
        const fileName = "Attendance.txt"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pasc/attendanceTms/uploadAttendanceInfoCheck
export function uploadAttendanceInfoCheck(file, sessiondayId, sessionId) {
  return dispatch => {
    dispatch(loading(true));

    const formData = new FormData();
    formData.append("file", file);
    post("/attendanceTms/uploadAttendanceInfoCheck", formData, { sessiondayId })
      .then(() => {
        dispatch(upload_attendance_info(file, sessiondayId, sessionId));
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch((err) => {
        if (err.code === 1033) {
          dispatch(
            save_data({
              showConfirm: true
            })
          );
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pamb/attendanceTms/uploadAttendanceInfo
export function upload_attendance_info(file, sessiondayId, sessionId) {
  return dispatch => {
    dispatch(loading(true));

    const formData = new FormData();
    formData.append("file", file);
    post("/attendanceTms/uploadAttendanceInfo", formData, { sessiondayId })
      .then(() => {
        setTimeout(() => {
          notification.success({
            message: "Uploaded successfully",
            duration: globalPromptTime10s
          });
        }, 500);
        dispatch(update_attendance_agents(sessiondayId));
        dispatch(update_registration_agents(sessionId));
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function getCurrentTime() {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getCurrentTime")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              ServerTime: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
