import { get, post } from "../../../utils/request";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pacs/TrainingReportReducer/SAVE";

const initState = {
  trainingMaterialData: {},
  trainerFeedbackData: {},
  overallFeedbackData: {},
  teachingDaysData: {},
  trainerNameList: [],
  courseList: [],
  sessionList: [],
  courseStatusList: [],
  venueRegionList: [],
  venueNameList: [],
  trainerStatusList: [],
  downloadLoading: false,
  queryLoading: false
};

// reducer
export default function TrainingReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleQueryData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/report/trainingMaterialRating", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            trainingMaterialData: data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function handleQueryOverAllData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/report/overallFeedbackRating", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            overallFeedbackData: data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function handleQueryFeedbackData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/report/trainerFeedbackRating", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            trainerFeedbackData: data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function handleQueryDataForTeachingDays(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/report/teachingDaysReport", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            teachingDaysData: data
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function getTrainerNameList() {
  return dispatch => {
    get("/survey/report/getTrainerNameList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            trainerNameList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getCourseList() {
  return dispatch => {
    get("/survey/report/getCourseList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            courseList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getCourseStatus() {
  return dispatch => {
    get("/survey/report/getCourseStatus")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            courseStatusList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getTrainerStatus() {
  return dispatch => {
    get("/survey/report/getTrainerStatus")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            trainerStatusList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getSessionNameList(payload, cb) {
  return dispatch => {
    get("/survey/report/getSessionNameList", payload)
      .then(res => {
        const { data } = res;
        cb();
        dispatch({
          type: SAVE,
          payload: {
            sessionList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getVenueRegionList() {
  return dispatch => {
    get("/survey/report/getVenueRegionList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            venueRegionList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function getVenueNameList() {
  return dispatch => {
    get("/survey/getVenueNameList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            venueNameList: data || []
          }
        });
      })
      .catch(() => {});
  };
}

export function downloadDataForTMRating(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/survey/report/downloadTrainingMaterialRating", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Training Material Rating.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export function downloadDataForFeedbackRating(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/survey/report/downloadTrainerFeedbackRating", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Trainer Feedback Rating.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export function downloadDataForOverallRating(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/survey/report/downloadOverallFeedbackRating", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Overall Feedback Rating.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export function downloadTeachingDaysReport(payload) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/survey/report/downloadTeachingDaysReport", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "Teaching Days Report.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}

export function reSetSessionList() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        sessionList: []
      }
    });
  };
}
