/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Tooltip, Input, Spin, Button, notification } from "antd";
import { withRouter } from "react-router-dom";
import withConnector from './../connector';
import CustomList from '../../../../../components/CustomList';
import { getSearchFileds } from './searchFileds';
import { getColumns } from './columns';
import styles from './list.module.less'
import { get, post } from '../../../../../../utils/request';
import EditModalModal from './editModal';
import { getFcCodeList, validateEditing } from './api';


function CPDhourEditingList(props) {
  const initialFilters = {
    headerTitle: 'CPD Hour Editing',
    keyword: 'CPDHourEditing',
    fetchUrl: '/cpdEditing/hours',
    method: 'get',
    searchFileds: [],
    columns: [],
    loading: false,
    needTimestamp: false,
    rowKey: 'courseUserId'
  }
  const tableRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const initialValues = {};
  const [filters, setFilters] = useState(initialFilters);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalModalVisible, setModalModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [selectAllCourse, setSelectAllCourse] = useState(true);
  const [allCourseIds, setAllCourseIds] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const { fcCodeList, courseNameList } = props.ShiftInOutReducer;
  const { courseCodeList } = props.AttendanceSummaryReducer;

  useEffect(() => {
    const fetchAPI = async () => {
      await props.getFcCodeList({
        ownerName: ''
      });
      await props.getCourseCodeList()
      await props.getShiftCourseName();
    }

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, [])

  const getAllIds = async (payload) => {
    if (payload?.courseId?.length > 0 || payload?.courseCode?.length > 0 ) {
      setSelectAllCourse(false)
      const res = await get("/cpdEditing/hours/ids", payload);
      return res.data;
    } else {
      setSelectAllCourse(true)
      return [];
    }
  }


  const updateFilters = async (data) => {
    const listRef = tableRef?.current?.refs?.wrappedComponent;

    const searchBarRef = listRef?.searchBarRef?.current;

    searchBarRef.setFieldsValue({
      userName: data.userName || ''
    })
  }

  const searchFiledsParams = { updateFilters, initialValues, courseCodeList, courseNameList, fetchOptions: getFcCodeList }

  const linkToHistoryPage = (courseUserId) => {
    props.history.push("/home/cpd_settings/cpd_hour_editing/history", {
      courseUserId
    });
  }

  const fetchData = async () => {
    setLoading(true);
    setFilters(
      {
        ...filters,
        ...{
          columns: getColumns({ setModalData, modalData, setModalModalVisible, linkToHistoryPage }),
          searchFileds: getSearchFileds(searchFiledsParams),
        }
      }
    )

    setLoading(false);
  }


  useEffect(() => {
    fetchData();
  }, [fcCodeList, courseCodeList, courseNameList]);


  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({}),
  };


  const onTableResearch = () => {
    setSelectedRowKeys([]);
  }

  const onTableLoaded = async (data) => {
    const { dataSource, searchData } = data;
    setDataSource(dataSource);

    const ids = await getAllIds(searchData);
    setAllCourseIds(ids);
  }

  const onSelectTableAll = () => {
    if (!isSelectAll) {
      setSelectedRowKeys(allCourseIds);
    } else {
      setSelectedRowKeys([]);
    }

    setIsSelectAll(!isSelectAll)
  }

  const onValidateBeforeEdit = async (pickSource) => {
    const data = { ...pickSource };
    try {
      if (pickSource.courseUserIds.length > 1) {
        pickSource.isMassUpdate = true;
        delete data.agentCode;
      }

      const res = await validateEditing(data);
      return res.success;
    } catch (error) {
      return false;
    }

  }

  const onEditSelection = async () => {

    if (selectedRowKeys.length === 0) {
      return;
    }

    const firstSelectedId = selectedRowKeys[0];

    const firstSelectedItem = firstSelectedId ? dataSource.find(item => item.courseUserId === firstSelectedId) : {};

    const pickSource = firstSelectedItem || dataSource[0];


    pickSource.courseUserIds = selectedRowKeys;

    const data = { ...pickSource};

    if (selectedRowKeys.length > 1) {
      data.isMassUpdate = true;
      delete data.agentCode;
    }

    const isSucess = await onValidateBeforeEdit(data);

    if(!isSucess) {
      return;
    }

    setModalData({ ...data });
    setModalModalVisible(true)

  }

  const reload = () => {
    tableRef?.current?.refs?.wrappedComponent?.reloadTable()
  }

  return (
    <div className="editing-hour">
      <Spin spinning={loading}>
        <CustomList
          {...filters}
          scroll={{ y: 350 }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys,
            ...rowSelection,
          }}
          ref={tableRef}
          onTableResearch={onTableResearch}
          onTableLoaded={onTableLoaded}
          headerBtnDef={[
            {
              color: "danger",
              title: 'Select All',
              onClick: onSelectTableAll,
              disabled: selectAllCourse,
              condition: () => dataSource && dataSource.length > 0
            },
            {
              color: "danger",
              title: 'Edit Selection',
              onClick: onEditSelection,
              condition: () => dataSource && dataSource.length > 0
            }
          ]}
        />
      </Spin>
      <EditModalModal
        visible={modalModalVisible}
        onHandleCancel={() => { setModalModalVisible(false); setModalData(null); }}
        data={modalData}
        setModalData={setModalData}
        onFinshUpdate={reload}
      />
    </div>

  );
}

export default withConnector(withRouter(CPDhourEditingList));
