import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Input,
  Row,
  Col,
  Icon,
  // DatePicker,
  Spin
  // Select
} from "antd";
import styles from "./PACSAgentDetail.module.less";
import {
  getuserDetial,
  updateAgent
} from "../../../../../redux/plukReducer/systemSettingsReducers/otherInfoReducer";
import { handleStatus, handleGender } from "../../../../../utils";

// const { Option } = Select;
// const { RangePicker } = DatePicker;
const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol12 = {
  span: 12
};
// const flexCol24 = {
//   span: 24
// };

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

// const formLayout2 = {
//   labelCol: { span: 11 },
//   wrapperCol: { span: 13 }
// };

// const formLayout3 = {
//   labelCol: { span: 3 },
//   wrapperCol: { span: 21 }
// };

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({
    plukReducer: {
      systemSettingsReducers: {
        CreateLBUAdminReducer,
        userManagementReducer,
        otherInfoReducer
      }
    }
  }) => ({ CreateLBUAdminReducer, userManagementReducer, otherInfoReducer }),
  { getuserDetial, updateAgent }
)
class AdminForm extends React.Component {
  state = {
    isEditable: false,
    nneList: [
      {
        id: "Y",
        value: "Y"
      },
      {
        id: "N",
        value: "N"
      }
    ]
  };

  componentDidMount() {
    const {
      location: { state = {} }
    } = this.props;
    const { bu, userId, roleId } = state;
    this.props.getuserDetial({ userId, roleId }, bu);
  }

  handleEdit = () => {
    const { isEditable } = this.state;
    this.props.form.resetFields();
    this.setState(() => ({ isEditable: !isEditable }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form,
      location: { state = {} }
    } = this.props;
    const { userId } = state;
    form.validateFields(null, { first: true }, (err, values) => {
      if (!err) {
        const { nne, priorContractDate } = values;
        const [start, end] = priorContractDate || [];
        const priorContractStartDate =
          start && moment(start).format("YYYY-MM-DD");
        const priorContractEndDate = end && moment(end).format("YYYY-MM-DD");
        const submitData = {
          userId,
          nne,
          priorContractStartDate,
          priorContractEndDate
        };
        const callback = () => {
          this.props.history.push("/home/system_settings/user_management");
        };
        this.props.updateAgent(submitData, callback);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      otherInfoReducer: { userInfo = {}, updateAdminLoading },
      location: { state = {} }
    } = this.props;
    const { roleName, bu } = state;
    const {
      email,
      // unitCode,
      unitNo,
      contactNumber,
      channel,
      gender,
      loginAccount,
      pgroup,
      status,
      terminationDate,
      rank,
      // priorContractStartDate,
      // priorContractEndDate,
      firstName,
      middleName,
      lastName,
      codingDate,
      region,
      uniMrgFirstName,
      uniMrgLastName,
      uniMrgAgentCode,
      accreditedTrainer,
      promotionDate,
      agentCode
    } = userInfo;
    // const priorContractDate = [
    //   priorContractStartDate
    //     ? moment(priorContractStartDate, "YYYY-MM-DD")
    //     : "",
    //   priorContractEndDate ? moment(priorContractEndDate, "YYYY-MM-DD") : ""
    // ];
    // const { isEditable, nneList } = this.state;
    return (
      <div className={styles.container}>
        <Spin spinning={updateAdminLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            {`${roleName} Info`}
          </div>
          <Form
            layout="horizontal"
            labelAlign="left"
            {...formLayout}
            colon={false}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: roleName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: bu // add by zhy
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginAccount", {
                    initialValue: loginAccount
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent Code">
                  {getFieldDecorator("agentCode", {
                    initialValue: agentCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="First Name">
                  {getFieldDecorator("firstName", {
                    initialValue: firstName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Middle Name">
                  {getFieldDecorator("middleName", {
                    initialValue: middleName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Last Name">
                  {getFieldDecorator("lastName", {
                    initialValue: lastName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: email
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Gender">
                  {getFieldDecorator("gender", {
                    initialValue: handleGender(gender)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Mobile Number">
                  {getFieldDecorator("mobileNumber", {
                    initialValue: contactNumber
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Classification">
                  {getFieldDecorator("classification", {
                    initialValue: rank
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Coding Date">
                  {getFieldDecorator("signUpDate", {
                    initialValue: codingDate
                      ? moment(codingDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Sales Unit Code">
                  {getFieldDecorator("unitNo", {
                    initialValue: unitNo
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Branch Name">
                  {getFieldDecorator("branchName", {
                    initialValue: pgroup
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Channel">
                  {getFieldDecorator("channel", {
                    initialValue: channel
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Region">
                  {getFieldDecorator("region", {
                    initialValue: region
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol12}>
                <Form.Item label="Unit Manager First Name">
                  {getFieldDecorator("unitManagerFirstName", {
                    initialValue: uniMrgFirstName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol12}>
                <Form.Item label="Unit Manager Last Name">
                  {getFieldDecorator("unitManagerLastName", {
                    initialValue: uniMrgLastName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol12}>
                <Form.Item label="Unit Manager Agent Code">
                  {getFieldDecorator("unitManagerAgentCode", {
                    initialValue: uniMrgAgentCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol12}>
                <Form.Item label="Accredited Trainer">
                  {getFieldDecorator("accreditedTrainer", {
                    initialValue: accreditedTrainer
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Promotion Date">
                  {getFieldDecorator("promotionDate", {
                    initialValue: promotionDate
                      ? moment(promotionDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Status">
                  {getFieldDecorator("status", {
                    initialValue: handleStatus(status)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Termination Date">
                  {getFieldDecorator("terminationDate", {
                    initialValue: terminationDate
                      ? moment(terminationDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            {/* <Row {...flexRow}>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={
                      isEditable ? styles.editbalBtn : styles.submitBtn
                    }
                    disabled={!isEditable}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleEdit}
                    className={styles.cancelBtn}
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </Spin>
      </div>
    );
  }
}

const PLUKAgentDetail = Form.create()(AdminForm);

export default PLUKAgentDetail;
