import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Select,
  Spin,
  Tooltip,
  Table
} from "antd";
import { changeVals } from "../../../../../redux/plukReducer/systemSettingsReducers/index.action";
import {
  search,
  init_data
} from "../../../../../redux/plukReducer/systemSettingsReducers/DailyCoachingSearchReducer";

import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "DailyCoachingSearch_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("DailyCoachingSearch", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      courseCode: Form.createFormField({
        ...props.courseCode,
        value: props.courseCode.value
      }),
      trainer: Form.createFormField({
        ...props.trainer,
        value: props.trainer.value
      })
    };
  },
  onValuesChange() {}
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, columns } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {})(
                <Input />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Trainer">
              {getFieldDecorator(
                "trainer",
                {}
              )(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="0">Agent Leaders</Option>
                  <Option value="1">Real Trainer Name</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.btnCol}>
          <Button
            className={styles.btn}
            type="danger"
            icon="plus"
            onClick={() => {
              props.goDetail("add", "", "");
            }}
          >
            Add Course
          </Button>
        </div>
        <div>
          <Table
            bordered
            dataSource={props.searchList}
            scroll={{ x: 1600 }}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            size="small"
            rowKey="pambDailyCoachingRuleId"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { systemSettingsReducers: { DailyCoachingSearchReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: DailyCoachingSearchReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    search,
    init_data
  }
)
class DailyCoachingSearch extends Component {
  constructor(props) {
    super(props);
    this.goDetail = this.goDetail.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      tableData: {
        trainer: "",
        courseName: "",
        courseCode: "",
        pageSize: 10,
        currentPageNumber: 1
      },
      columns: [
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 300,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Module Name",
          dataIndex: "moduleName",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          align: "center"
        },
        {
          title: "Trainer",
          dataIndex: "trainer",
          align: "center",
          render: text => {
            const trainer =
              text === "0" ? "Agent Leaders" : "Real Trainer Name";
            return (
              <Tooltip placement="top" title={text}>
                {trainer}
              </Tooltip>
            );
          }
        },

        {
          title: "Option",
          dataIndex: "option",
          ellipsis: true,
          align: "center",
          width: 150,
          fixed: "right",
          render: (text, record) => {
            return (
              <div
                style={insideStyles.tableData}
                onClick={() => {
                  this.goDetail(
                    "edit",
                    record.courseId,
                    record.sessionId,
                    record.pambDailyCoachingRuleId
                  );
                }}
              >
                View
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    this.props.init_data();
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      const { pageSize, pageIndex } = this.state
      if (!err) {
        this.setState(() => ({
          tableData: {
            courseName: fields.courseName.value,
            courseCode: fields.courseCode.value,
            trainer: fields.trainer.value,
            pageSize,
            currentPageNumber: pageIndex
          }
        }));
        this.props.search({
          courseName: fields.courseName.value,
          courseCode: fields.courseCode.value,
          trainer: fields.trainer.value,
          pageSize,
          currentPageNumber: pageIndex
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { tableData: fields } = this.state;
    this.props.search({
      courseName: fields.courseName,
      courseCode: fields.courseCode,
      trainer: fields.trainer,
      pageSize,
      currentPageNumber: current
    });
  };

  goDetail(type, courseId, sessionId, pambDailyCoachingRuleId) {
    this.props.history.push(
      "/home/system_settings/daily_coaching/edit_coaching_course",
      {
        type,
        courseId,
        sessionId,
        pambDailyCoachingRuleId,
        go: true
      }
    );
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      goDetail: this.goDetail,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Daily Coaching</div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "rgb(38, 98, 208)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};
const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(DailyCoachingSearch);
