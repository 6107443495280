import { PUBLIC_LOADING } from "./pambReducer/actionTypes";
import { get } from "../utils/request";
import { downloadFile } from "../utils";
import { handleCheckToken } from "../utils/refreshToken";

const initState = {
  loading: false
};

export default function PublicReducer(state = initState, action) {
  switch (action.type) {
    case PUBLIC_LOADING:
      return { loading: action.payload };
    default:
      return state;
  }
}

export function loading(now) {
  return {
    type: PUBLIC_LOADING,
    payload: now
  };
}

export function download_template(url, fileName) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    get(url, null, null, "blob")
      .then(blob => {
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
