import React from "react";
import { Form } from "antd";
import withConnector from '../connector';
import AddUserByRole from '../../../../../components/AddUserByRole';
import { userListPath } from '../UserList/utils';

@withConnector
class ADMCourseAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Create ADM Course Admin',
      url: "/systemSetting/createCourseAdmin",
      lastPage: userListPath
    }
  }

  afterSaveCb = () => {
    const { history } = this.props;
    history.push("/home/system_settings/user_management");
  };

  render() {
    const {
      userManagementReducer: { rolerList, buList },
    } = this.props;

    const { url, lastPage, title } = this.state;
    return (
        <AddUserByRole
          afterSaveCb={this.afterSaveCb}
          form={this.props.form}
          url={url}
          lastPage={lastPage}
          title={title}
          rolerList={rolerList}
          buList={buList}
          roleCode="PHKLADMCourseAdmin"
        />
    );
  }
}

const ADMCourseAdminFrom = Form.create({ name: "ADMCourseAdmin" })(ADMCourseAdmin);

export default ADMCourseAdminFrom;
