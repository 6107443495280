import React, {Component} from 'react'
import {
  Button,
  Icon,
  Spin,
  Upload,
  Empty,
  Table,
} from "antd";
import { connect } from "react-redux";
import styles from './index.module.less'
import {
  validateFile,
  uploadFile,
  init_data,
  SAVE_DATA,
} from "../../../../../../redux/pambReducer/registrationReducers/importAddReducer";
import { changeVals } from "../../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { download_template } from '../../../../../../redux/PublicReducer';
import {columns} from './column'

@connect(
  state => ({
    reducer: state.pambReducer.registrationReducers.importReducer,
    loading: state.PublicReducer.loading,
  }),
  {
    changeVals,
    validateFile,
    uploadFile,
    init_data,
    download_template
  }
)
class RegistrationBulkAdd extends Component {
  state = {
    fileList: [],
    fileName: '',
  }

  componentDidMount() {
    this.props.init_data()
  }

  componentWillUnmount() {
    this.props.init_data()
  }

  downLoadTemplate = () => {
    this.props.download_template(
      "/pruventure/templates/PruVenture Registration.xlsx",
      'PruVentureRegistrationTemplate.xlsx',
    );
  }

  goBack = () => {
    this.props.history.push(
      "/home/scheme_management/pru_venture_registration/list"
    );
  };

  checkAndUpload = () => {
    if (this.state.fileList.length === 0) {
      return
    }
    const formData = new FormData()
    formData.append('file', this.state.fileList[0])
    this.props.validateFile(formData)
  }

  importData = () => {
    if (!this.props.reducer.fileId) {
      return
    }
    const onSuccess = () => {
      this.goBack()
    }
    this.props.uploadFile(this.props.reducer.fileId, onSuccess)
  }

  onChangeFile = (file) => {
    this.setState({ fileList: [file], fileName: file.name });
    this.props.changeVals(SAVE_DATA, {
      registrations: [],
      fileId: "",
      containError: false,
    });
    return false;
  }

  render() {
    return (
      <div className={styles.container}>
        <Spin spinning={this.props.loading}>
          <div className={styles.header}>
            <Button
              className={styles.goback}
              onClick={this.goBack}
              type="link"
              icon="left"
            >
              Add Registration
            </Button>
          </div>
          <div className={styles.content}>
            <span className={styles.importText}>Import</span>
            <Upload
              multiple={false}
              showUploadList={false}
              onRemove={file => this.setState({ fileList: [] })}
              accept=".xlsx"
              beforeUpload={this.onChangeFile}
            >
              <div className={styles.uploadBox}>
                <span className={styles.fileName}>{this.state.fileName}</span>
                <Icon type="folder-open" className={styles.folder}></Icon>
              </div>
            </Upload>
            <Button
              className={styles.downloadBtn}
              icon="download"
              onClick={this.downLoadTemplate}
            >
              Download Template
            </Button>
          </div>
          {!this.props.reducer.fileId && (
            <div className={styles.checkContainer}>
              <Button
                disabled={this.state.fileList.length === 0}
                className={styles.uploadCheckBtn}
                type='primary'
                onClick={this.checkAndUpload}
              >
                Upload & Check
              </Button>
            </div>
          )}
          {!!this.props.reducer.fileId && (
            <div className={styles.container}>
              <div className={styles.tableBox}>
                <Table
                  className={styles.ant_dev_table}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No Data Found"
                      />
                    )
                  }}
                  size="small"
                  columns={columns}
                  dataSource={this.props.reducer.registrations}
                  pagination={false}
                  scroll={this.props.reducer.registrations.length > 6 ? { y: 240 } : {}}
                  rowKey="agentId"
                />
              </div>
              <div className={styles.importBox}>
                <Button
                  disabled={this.props.reducer.containError}
                  onClick={this.importData}
                  className={styles.importBtn}
                >
                  Import
                </Button>
              </div>
            </div>
          )}
        </Spin>
      </div>
    );
  }
}

export default RegistrationBulkAdd;

