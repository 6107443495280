import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
  Spin,
  Icon,
  TimePicker,
  notification
} from "antd";

import moment from "moment";

import { changeVals } from "../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../../utils/global";

import {
  edit,
  getDetail,
  create,
  getSession,
  init_data
} from "../../../../../redux/pambReducer/systemSettingsReducers/EditCoachingCourseReducer";

import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "EditCoachingCourse_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("EditCoachingCourse", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      trainerName: Form.createFormField({
        ...props.trainerName,
        value: props.trainerName.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      sessionNameEdit: Form.createFormField({
        ...props.sessionNameEdit,
        value: props.sessionNameEdit.value
      }),
      trainer: Form.createFormField({
        ...props.trainer,
        value: props.trainer.value
      }),
      sessionDate: Form.createFormField({
        ...props.sessionDate,
        value: props.sessionDate.value
      }),
      frequency: Form.createFormField({
        ...props.frequency,
        value: props.frequency.value
      }),
      attendHours: Form.createFormField({
        ...props.attendHours,
        value: props.attendHours.value
      }),
      trainingDays: Form.createFormField({
        ...props.trainingDays,
        value: props.trainingDays.value
      }),
      attendRate: Form.createFormField({
        ...props.attendRate,
        value: props.attendRate.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const {
    loading,
    disable,
    pambDailyCoachingRuleId,
    trainerList,
    trainerHaveList,
  } = props;

  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 10 }}
        labelAlign="left"
        wrapperCol={{ span: 14 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input
                disabled={props.state.type === "edit"}
                onClick={() => {
                  props.history.push(
                    "/home/system_settings/daily_coaching/select_course",
                    {
                      ...props.state,
                      courseName: props.courseName.value,
                      trainerList,
                      trainer: props.trainer.value,
                      trainerName: props.trainerName.value
                    }
                  );
                }}
              />)}
            </Form.Item>
          </Col>
          {props.state.type === "edit" ? (
            <Col {...flexCol}>
              <Form.Item label="Session Name">
                {getFieldDecorator("sessionNameEdit", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message:
                        "The field cannot be input more than 50 characters"
                    }
                  ]
                })(<Input disabled={props.state.type === "edit"} />)}
              </Form.Item>
            </Col>
          ) : (
              <Col {...flexCol}>
                <Form.Item label="Session Name">
                  {getFieldDecorator("sessionName", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message:
                          "The field cannot be input more than 50 characters"
                      }
                    ]
                  })(
                    <Select
                      disabled={props.state.type === "edit" && disable}
                      filterOption={false}
                      defaultActiveFirstOption={false}
                    >
                      {props.sessionList.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )}
          <Col {...flexCol}>
            <Form.Item label="Trainer">
              {getFieldDecorator("trainer", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(
                <Select
                  disabled={props.state.type === "edit" && disable}
                  filterOption={false}
                  defaultActiveFirstOption={false}
                  onChange={() => {
                    props.onChange("EditCoachingCourse", {
                      trainerName: {
                        value: ""
                      }
                    });
                  }}
                >
                  <Option value="0">Agent Leaders</Option>
                  <Option value="1">Real Trainer Name</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Trainer Name">
              {getFieldDecorator("trainerName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <Input
                  disabled={props.state.type === "edit" && disable}
                  onClick={() => {
                    if (props.trainer.value === "0") {
                      props.history.push(
                        "/home/system_settings/daily_coaching/select_agent_leader",
                        {
                          ...props.state,
                          pambDailyCoachingRuleId,
                          courseName: props.courseName.value,
                          trainerList,
                          trainerHaveList,
                          trainer: props.trainer.value,
                          trainerName: props.trainerName.value
                        }
                      );
                    } else if (props.trainer.value === "1") {
                      props.history.push(
                        "/home/system_settings/daily_coaching/select_trainer",
                        {
                          ...props.state,
                          pambDailyCoachingRuleId,
                          courseName: props.courseName.value,
                          trainerList,
                          trainerHaveList,
                          trainer: props.trainer.value,
                          trainerName: props.trainerName.value
                        }
                      );
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Generation Frequency(Day)">
              {getFieldDecorator("frequency", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: /^([1-9][0-9]*){1,}$/,
                    message: "Please fill in the valid Generation Frequency(Day)"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input disabled={props.state.type === "edit" && disable} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xl={12}>
            <Form.Item labelCol={{ span: 5 }} label="Session Time">
              <TimePicker
                value={props.sessionStart}
                disabled={props.state.type === "edit" && disable}
                format="HH:mm:ss"
                placeholder="Start Time"
                onChange={e => {
                  props.onChange("EditCoachingCourse", {
                    sessionStart: e
                  });
                }}
              />{" "}
              -{" "}
              <TimePicker
                value={props.sessionEnd}
                disabled={props.state.type === "edit" && disable}
                format="HH:mm:ss"
                placeholder="End Time"
                onChange={e => {
                  props.onChange("EditCoachingCourse", {
                    sessionEnd: e
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col xl={12}>
            <Form.Item labelCol={{ span: 5 }} label="Program Date">
              <DatePicker
                value={props.programStart}
                onChange={e => {
                  props.onChange("EditCoachingCourse", {
                    programStart: e
                  });
                }}
                disabledDate={props.disabledDate}
                disabled={props.state.type === "edit"}
                placeholder="Start Date"
                format="YYYY-MM-DD"
                style={{ width: "45%" }}
              />
              {" "}
              -{" "}
              <DatePicker
                value={props.programEnd}
                onChange={e => {
                  props.onChange("EditCoachingCourse", {
                    programEnd: e
                  });
                }}
                disabledDate={props.disabledDate}
                disabled={props.state.type === "edit" && disable}
                placeholder="End Date"
                format="YYYY-MM-DD"
                style={{ width: "45%" }}
              />
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Daily Minimum Attend Hours">
              {getFieldDecorator("attendHours", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Daily Minimum Attend Hours is not a number"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input disabled={props.state.type === "edit" && disable} />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Total Training Days">
              {getFieldDecorator("trainingDays", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },

                  {
                    pattern: /^([1-9][0-9]*){1,}$/,
                    message: "Please fill in the valid Total Training Days"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input disabled={props.state.type === "edit" && disable} />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Required Attend Rate(%)">
              {getFieldDecorator("attendRate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  {
                    pattern: /^\d+(\.\d+)?$/,
                    message: "Required Attend Rate(%) is not a number"
                  },
                  {
                    max: 50,
                    message: "The field cannot be input more than 50 characters"
                  }
                ]
              })(<Input disabled={props.state.type === "edit" && disable} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                disabled={props.state.type === "edit" && disable}
                type="primary"
                className={
                  props.state.type === "edit" && disable
                    ? styles.submitBtn
                    : styles.editbalBtn
                }
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Save
              </Button>
            </Form.Item>
            {props.state.type === "edit" && props.disable ? (
              <Form.Item style={{ marginLeft: "20px" }}>
                <Button
                  type="primary"
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={() => {
                    props.changeDisable(false);
                  }}
                >
                  Edit
                </Button>
              </Form.Item>
            ) : null}

            {props.state.type === "edit" && !props.disable ? (
              <Form.Item style={{ marginLeft: "20px" }}>
                <Button
                  type="primary"
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={() => {
                    props.changeDisable(true);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pambReducer: { systemSettingsReducers: { EditCoachingCourseReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: EditCoachingCourseReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    edit,
    getDetail,
    create,
    getSession,
    init_data
  }
)
class EditCoachingCourse extends Component {
  constructor(props) {
    super(props);
    this.changeDisable = this.changeDisable.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    this.state = {
      disable: true,
    };
  }

  componentDidMount() {
    const {
      courseName,
      trainerList,
      type,
      courseId,
      sessionId,
      go,
      trainer,
      trainerName,
      pambDailyCoachingRuleId
    } = this.props.location.state;
    if (courseId) {
      this.props.getSession(courseId);
    }
    if (type === "edit") {
      this.setState(() => ({
        disable: go
      }));
      if (!go) {
        const obj = {
          trainerList,
          trainer: {
            value: trainer
          },
          trainerName: {
            value: trainerName
          },
          type
        };

        this.props.getDetail(courseId, sessionId, obj, pambDailyCoachingRuleId);
      } else {
        this.props.getDetail(courseId, sessionId, {}, pambDailyCoachingRuleId);
      }
    } else {
      if (!go) {
        this.props.changeVals("EditCoachingCourse", {
          courseName: {
            value: courseName
          },
          trainerName: {
            value: trainerName
          },
          trainerList,
          trainer: {
            value: trainer
          },
          courseId,
          type
        });
      } else {
        this.props.init_data();
      }
    }
  }

  handleSubmit = form => {
    const { type } = this.props.location.state;
    form.validateFields(null, { first: false }, err => {
      const { fields } = this.props;
      if (!err) {
        if (!fields.programStart || !fields.sessionStart || !fields.sessionEnd) {
          notification.error({
            message: "Please fill in the mandatory fields",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return;
        }

        if (fields.sessionStart.valueOf() >= fields.sessionEnd.valueOf()) {
          notification.error({
            message: "Please fill in the valid Session Time",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return;
        }

        if (fields.programEnd) {
          const end1 = fields.programEnd.endOf("day").valueOf();
          const start1 = fields.programStart.endOf("day").valueOf();
          let days = (end1 - start1) / 1000 / 60 / 60 / 24;
          if (start1 > end1) {
            notification.error({
              message: "Please fill in the valid Program Date",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
          if (start1 === end1) {
            if (fields.trainingDays.value != 1) {
              notification.error({
                message: "Please fill in the valid Total Training Days",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
              return;
            }
          } else {
            days += 1;
            const tdays = Math.floor(days / fields.frequency.value);

            if (tdays < fields.trainingDays.value) {
              notification.error({
                message: "Please fill in the valid Total Training Days",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
              return;
            }
          }
        } else {
          if (fields.trainingDays.value != 1) {
            notification.error({
              message: "Please fill in the valid Total Training Days",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }

        const startSession = moment(fields.sessionStart).valueOf();
        const endSession = moment(fields.sessionEnd).valueOf();
        const result = (endSession - startSession) / 1000 / 60 / 60;

        if (result < fields.attendHours.value) {
          notification.error({
            message: "Please fill in the valid Daily Minimum Attend Hours",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return;
        }

        if (type === "add") {
          this.props.create(
            {
              dailyMinAttendHours: fields.attendHours.value,
              generationFrequency: fields.frequency.value,
              pambDailyCoachingRuleId: "",
              programDateEnd: fields.programEnd ? `${fields.programEnd.format("YYYY-MM-DD")} 00:00:00` : "",
              programDateStart: `${fields.programStart.format("YYYY-MM-DD")} 00:00:00`,
              requiredAttendRate: fields.attendRate.value,
              sessionEndTime: fields.sessionEnd.format("HH:mm:ss"),
              sessionId: fields.sessionName.value,
              sessionStartTime: fields.sessionStart.format("HH:mm:ss"),
              totalTrainingDays: fields.trainingDays.value,
              trainerFlag: fields.trainer.value,
              trainerList: fields.trainerList
            },
            this.props.history
          );
        } else if (type === "edit") {
          this.props.edit(
            {
              courseName: fields.courseName.value,
              dailyMinAttendHours: fields.attendHours.value,
              generationFrequency: fields.frequency.value,
              pambDailyCoachingRuleId: fields.pambDailyCoachingRuleId,
              programDateEnd: fields.programEnd ? `${fields.programEnd.format("YYYY-MM-DD")} 00:00:00` : "",
              programDateStart: `${fields.programStart.format("YYYY-MM-DD")} 00:00:00`,
              requiredAttendRate: fields.attendRate.value,
              sessionEndTime: fields.sessionEnd.format("HH:mm:ss"),
              sessionId: fields.sessionName.value,
              sessionStartTime: fields.sessionStart.format("HH:mm:ss"),
              totalTrainingDays: fields.trainingDays.value,
              trainerFlag: fields.trainer.value,
              trainerList: fields.trainerList
            },
            this.props.history
          );
        }
      }
    });
  };

  disabledDate = current => {
    return current < moment().startOf("day");
  };

  changeDisable(disable) {
    this.setState(() => ({
      disable
    }));
    if (disable) {
      const {
        courseId,
        sessionId,
        pambDailyCoachingRuleId
      } = this.props.location.state;
      this.props.getDetail(courseId, sessionId, {}, pambDailyCoachingRuleId);
    }
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      history: this.props.history,
      state: this.props.location.state,
      disable: this.state.disable,
      changeDisable: this.changeDisable,
      disabledDate: this.disabledDate,
    };
    const { type } = this.props.location.state;
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push(
                "/home/system_settings/daily_coaching/daily_coaching_search"
              );
            }}
          />
          {type === "add" ? "Add" : "Edit"} Coaching Course
        </div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(EditCoachingCourse);
