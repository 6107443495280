import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import { globalPromptTime10s, switchDate } from "../../../utils/global";

const INITDATA_MANAGEMENT_REPORT_PACS = "PACS/INITDATA_MANAGEMENT_REPORT_PACS";
const SAVEDATA_MANAGEMENT_REPORT_PACS = "PACS/SAVEDATA_MANAGEMENT_REPORT_PACS";

const handleCpdYear = () => {
  const year = new Date().getFullYear();
  // if curren year is 2022 then return 2023,else return current year
  const cpdYear = year === 2022 ? 2023 : year;
  return switchDate() ? cpdYear : year;
}

const initState = {
  agentLeader: {
    value: ""
  },
  unitCode: {
    value: ""
  },
  trainingDateRange: {
    value: ""
  },
  agentStatus: {
    value: "2"
  },
  contractDateRange: {
    value: ""
  },
  fcCode: {
    value: ""
  },
  cpdYear: {
    value: handleCpdYear()
  },
  terminationDateRange: {
    value: ""
  },
  fullFill: {
    value: ""
  },
  includeShiftedHours: {
    value: true
  },

  agentLeaderList: [],
  unitCodeList: [],
  agentStatusList: [],
  fcCodeList: [],
  fullFillList: [],
  agentYearList: [],
  searchList: [],
  newColumns: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1,
  qIndex: 0,
  pageLoading: false,
};

export default function ManagementReportPACSReducer(state = initState, action) {
  switch (action.type) {
    case "ManagementReportPACS":
      return { ...state, ...action.payload };
    case INITDATA_MANAGEMENT_REPORT_PACS:
      return { ...initState };
    case SAVEDATA_MANAGEMENT_REPORT_PACS:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_MANAGEMENT_REPORT_PACS
  };
}

export function save_data(payload) {
  return {
    type: SAVEDATA_MANAGEMENT_REPORT_PACS,
    payload
  };
}

// GET /cpdReport/getAgentLeaderSelect
export function getAgentLeaderSelect() {
  return dispatch => {
    get("/cpdReport/getAgentLeaderSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                agentLeaderList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getAgentStatusSelect
export function getAgentStatusSelect() {
  return dispatch => {
    get("/cpdReport/getAgentStatusSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                agentStatusList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getFcCodeSelect
export function getFcCodeSelect() {
  return dispatch => {
    get("/cpdReport/getFcCodeSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                fcCodeList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getFullFillSelect
export function getFullFillSelect() {
  return dispatch => {
    get("/cpdReport/getFullFillSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                fullFillList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /cpdReport/getUnitCodeSelect
export function getUnitCodeSelect() {
  return dispatch => {
    get("/cpdReport/getUnitCodeSelect")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                unitCodeList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /cpdReport/getReportList
export function getReportList(data) {
  return dispatch => {
    // dispatch(loading(true));
    dispatch(
      save_data({
        pageLoading: true
      })
    );

    post("/cpdReport/getReportList", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          const finalData = res.data ? [...res.data] : [];
          finalData.map((item) => {
            for (let q in item.attainedMap) {
              item[`attained_${q}`] = item.attainedMap[q];
            };
            for (let w in item.requirementMap) {
              item[`requirement_${w}`] = item.requirementMap[w];
            };
            for (let e in item.shortFallMap) {
              item[`shortFall_${e}`] = item.shortFallMap[e];
            };
            return item;
          });
          let qIndex = 0;
          for (let xs in finalData[0].attainedMap) {
            qIndex++;
          }
          for (let xs in finalData[0].requirementMap) {
            qIndex++;
          }
          for (let xs in finalData[0].shortFallMap) {
            qIndex++;
          }

          /* eslint-disable */
          // console.log("qIndex:",qIndex)
          dispatch(
            save_data({
              qIndex,
              searchList: finalData,
              pageLoading: false
              // totalPage: res.data.totalPage,
              // total: res.data.total,
              // pageSize: res.data.pageSize,
              // currentPageNumber: res.data.currentPageNumber
            })
          );
        }
        setTimeout(() => {
          // dispatch(loading(false));
          dispatch(
            save_data({
              pageLoading: false
            })
          );
        }, 500);
      })
      .catch(() => {
        dispatch(
          save_data({
            qIndex: 0,
            searchList: []
          })
        );
        setTimeout(() => {
          dispatch(
            save_data({
              qIndex: 0,
              searchList: [],
              pageLoading: false
            })
          );
        }, 500);
      });
  };
}

// POST /pacs/cpdReport/downloadCPDManagementReport
export function downloadCPDManagementReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/cpdReport/downloadCPDManagementReport", data, {}, "blob")
      .then(blob => {
        const fileName = "CPDManagementReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

//GET /pacs/tmsRequirement/getAgentYearList
export function getAgentYearList() {
  return dispatch => {
    get("/tmsRequirement/getAgentYearList")
      .then(res => {
        if(res.success) {
          dispatch(
            save_data(
              {
                agentYearList: [...res.data]
              }
            )
          )
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => { });
  };
}
