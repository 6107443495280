import React from "react";
import { connect } from "react-redux";
import Router from "../../../../../router";


@connect(
  state => state,
  {}
)
class TrainingHistoryReport extends React.Component {
  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default TrainingHistoryReport;
