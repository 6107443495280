import { combineReducers } from "redux";
import ManagementReportReducer from "./ManagementReportReducer";
import AttendedSummaryReducer from "./AttendedSummaryReducer";
import DeductionReportReducer from "./DeductionReportReducer";
import TrainingReportReducer from "./TrainingReportReducer";
import AttendanceSummaryReducer from "./AttendanceSummaryReducer";
import RegistrationSummaryReducer from "./RegistrationSummaryReducer";
import RegistrationAttendanceReducer from "./RegistrationAttendanceReducer";
import ManagementReportPCLAReducer from "./ManagementReportPCLAReducer";
import TrainingHistoryReportReducer from "./TrainingHistoryReportReducer";
import CourseRatingSummaryReportPCLAReducer from "./CourseRatingSummaryReportPCLAReducer";

export default combineReducers({
  ManagementReportReducer,
  AttendedSummaryReducer,
  DeductionReportReducer,
  TrainingReportReducer,
  AttendanceSummaryReducer,
  RegistrationSummaryReducer,
  RegistrationAttendanceReducer,
  ManagementReportPCLAReducer,
  TrainingHistoryReportReducer,
  CourseRatingSummaryReportPCLAReducer
});
