import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  Table,
  Empty,
  notification,
  Input
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  innit_data,
  getRegistrationAttendanceReport,
  getCourseNameList,
  getCourseCodeList,
  getSessionNameList,
  downloadRegistrationAttendanceReport
} from "../../../../redux/pacsReducer/CPDReportReducers/RegistrationAttendanceReducer";
import { globalPromptTime10s, switchDate } from "../../../../utils/global";
import styles from "./styles.module.less";

function disabledDate(current) {
  // Can not select days before today and today
  return switchDate() && current < moment('20220101');
}

const CreateForm = Form.create({
  name: "CreateRegistrationAttendance_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("RegistrationAttendance", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      courseCode: Form.createFormField({
        ...props.courseCode,
        value: props.courseCode.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      loginAccount: Form.createFormField({
        ...props.loginAccount,
        value: props.loginAccount.value
      }),
      reportDate: Form.createFormField({
        ...props.reportDate,
        value: props.reportDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <div className={styles.resizeCol}>
          <Form.Item label="Course Name" className={styles.tooShortItemLeft}>
            {getFieldDecorator("courseName", {
              // rules: [
              //   {
              //     required: true,
              //     message: "Please fill in the mandatory fields"
              //   }
              // ]
            })(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log(option) // eslint-disable-line
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
                onChange={(e) => {
                  props.getSessionNameList(e);
                }}
              >
                <Option value="">All</Option>
                {props.courseNameList.length
                  ? props.courseNameList.map(item => (
                    <Option value={item.courseId} key={item.courseId}>
                      {item.courseName}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Course Code" className={styles.tooShortItemLeft}>
            {getFieldDecorator("courseCode", {})(
              <Select
                showSearch
                showArrow
                allowClear
                defaultActiveFirstOption={false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log(option) // eslint-disable-line
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
              >
                <Option value="">All</Option>
                {props.courseCodeList.length
                  ? props.courseCodeList.map(item => (
                    <Option value={item.courseCode} key={item.courseCode}>
                      {item.courseCodeText}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Report Date Range" className={styles.middleItemLeft}>
            {getFieldDecorator("reportDate", {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                }
              ]
            })(
              <RangePicker
                placeholder={["Start Date", "End Date"]}
                style={{ width: "100%" }}
                format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                disabledDate={disabledDate}
              />
            )}
          </Form.Item>

          <Form.Item label="Session Name" className={styles.tooShortItemLeft}>
            {getFieldDecorator("sessionName", {})(
              <Select
                showSearch
                showArrow
                allowClear
                defaultActiveFirstOption={false}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  console.log(option) // eslint-disable-line
                  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                }
              >
                <Option value="">All</Option>
                {props.sessionNameList.length
                  ? props.sessionNameList.map(item => (
                    <Option value={item.sessionId} key={item.sessionId}>
                      {item.sessionName}
                    </Option>
                  ))
                  : null}
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Login Account" className={styles.tooShortItemLeft}>
            {getFieldDecorator("loginAccount", {})(
              <Input />
            )}
          </Form.Item>
        </div>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
        >
          {" "}
        </div>
        <Button
          style={{ display: props.searchList.length ? "inline-block" : "none" }}
          className={styles.btn}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_summary();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <Table
            bordered
            scroll={{ x: 2400, y: 380 }}
            className={styles.ant_dev_table}
            columns={props.columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            dataSource={props.calTableData(props)}
            size="small"
            rowKey="index"
          />

        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { CPDReportReducers: { RegistrationAttendanceReducer } },
    PublicReducer: { loading },
  }) => ({
    fields: RegistrationAttendanceReducer,
    loading
  }),
  {
    changeVals,
    innit_data,
    getRegistrationAttendanceReport,
    getCourseNameList,
    getCourseCodeList,
    getSessionNameList,
    downloadRegistrationAttendanceReport
  }
)
class RegistrationAttendance extends Component {
  constructor(props) {
    super(props);
    this.download_summary = this.download_summary.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "No",
          dataIndex: "No",
          align: "center",
          width: 50,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Module Name",
          dataIndex: "moduleName",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          align: "center",
          width: 130,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Start Date",
          dataIndex: "startDate",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "End Date",
          dataIndex: "endDate",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        // {
        //   title: "Agreement Date",
        //   dataIndex: "agreementDate",
        //   align: "center",
        //   width: 150,
        //   render: text => (
        //     <Tooltip placement="top" title={text}>
        //       {text}
        //     </Tooltip>
        //   )
        // },

        {
          title: "Unit Code",
          dataIndex: "unitCode",
          align: "center",
          width: 110,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC Code",
          dataIndex: "loginAccount",
          align: "center",
          width: 150,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Name",
          dataIndex: "agentName",
          align: "center",
          width: 120,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Rank",
          dataIndex: "rank",
          align: "center",
          width: 110,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },


        {
          title: "Registration Date",
          dataIndex: "registrationDate",
          align: "center",
          width: 150,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },

        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          width: 100,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status Updated",
          dataIndex: "statusUpdated",
          align: "center",
          width: 180,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Attendance",
          dataIndex: "attendance",
          align: "center",
          width: 100,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "In",
          dataIndex: "in",
          align: "center",
          width: 60,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Out",
          dataIndex: "out",
          align: "center",
          width: 60,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Fee",
          dataIndex: "courseFee",
          align: "center",
          width: 100,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "No-Show Fee",
          dataIndex: "noShowFee",
          align: "center",
          width: 110,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Total Fees",
          dataIndex: "totleFees",
          align: "center",
          width: 110,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.innit_data();
    this.props.getCourseCodeList();
    this.props.getCourseNameList();
    this.props.getSessionNameList('');
  }

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { fields } = this.props;
    this.props.getRegistrationAttendanceReport({
      courseCode: fields.courseCode.value,
      courseId: fields.courseName.value,
      endDate: fields.reportDate.value.length
        ? fields.reportDate.value[1].format("YYYY-MM-DD")
        : "",
      sessionId: fields.sessionName.value,
      loginAccount: fields.loginAccount.value,
      startDate: fields.reportDate.value.length
        ? fields.reportDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize,
      currentPageNumber: current
    });
  };





  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.reportDate.value.length) {
          const end = fields.reportDate.value[1].valueOf();
          const start = fields.reportDate.value[0];
          const nowYear = moment(start).add(1, "year").valueOf();
          if (end <= nowYear) {
            this.props.getRegistrationAttendanceReport({
              courseCode: fields.courseCode.value,
              courseId: fields.courseName.value,
              endDate: fields.reportDate.value.length
                ? fields.reportDate.value[1].format("YYYY-MM-DD")
                : "",
              sessionId: fields.sessionName.value,
              loginAccount: fields.loginAccount.value,
              startDate: fields.reportDate.value.length
                ? fields.reportDate.value[0].format("YYYY-MM-DD")
                : "",
              pageSize: this.state.pageSize,
              currentPageNumber: this.state.pageIndex,
            })
          } else {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
          }
        }
      }
    });
  };

  download_summary() {
    const { fields } = this.props;
    this.props.downloadRegistrationAttendanceReport({
      courseCode: fields.courseCode.value,
      courseId: fields.courseName.value,
      endDate: fields.reportDate.value.length
        ? fields.reportDate.value[1].format("YYYY-MM-DD")
        : "",
      sessionId: fields.sessionName.value,
      loginAccount: fields.loginAccount.value,
      startDate: fields.reportDate.value.length
        ? fields.reportDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize: this.props.pageSize,
      currentPageNumber: this.props.currentPageNumber
    });
  }


  calTableData(props) {
    const {searchList = [], currentPageNumber = 1, pageSize = 10} = props
    if (searchList.length > 0) {
      const res = searchList.map((item, index) => {
        return {
          No: (currentPageNumber - 1) * pageSize + index + 1,
          ...item
          };
        });
        return res;
    }
    return [];
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      download_summary: this.download_summary,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      getSessionNameList: this.props.getSessionNameList,
      calTableData: this.calTableData,
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>No Show Fee Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24
};

export default RegistrationAttendance;
