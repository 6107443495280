import React from "react";

// import Login from "../views/user/Login";
import ForgetPassword from "../views/user/ForgetPassword";
import Home from "../views/home";

import Forbidden from "../views/exception/Forbidden";
import NotFound from "../views/exception/NotFound";
import ServerError from "../views/exception/ServerError";

// systemSettings
import SystemSettings from "../views/home/phkl/systemSettings";
import UserManagement from "../views/home/phkl/systemSettings/userManagement/index";
import UserList from "../views/home/phkl/systemSettings/userManagement/UserList/index";
import CreateADCourseAdmin from '../views/home/phkl/systemSettings/userManagement/CreateADCourseAdmin/index';
import CreateBDCourseAdmin from '../views/home/phkl/systemSettings/userManagement/CreateBDCourseAdmin/index';
import CreateADCandidates from '../views/home/phkl/systemSettings/userManagement/CreateADCandidates/index';
import CreateADMCandidates from '../views/home/phkl/systemSettings/userManagement/CreateADMCandidates/index';
import CreateADMCourseAdmin from '../views/home/phkl/systemSettings/userManagement/CreateADMCourseAdmin/index';
import CreatePDCourseAdmin from '../views/home/phkl/systemSettings/userManagement/CreatePDCourseAdmin/index';
import AdminDetail from '../views/home/phkl/systemSettings/userManagement/UserList/Details/AdminDetails/index';
import UserDetail from '../views/home/phkl/systemSettings/userManagement/UserList/Details/UserDetails/index';

// Training History
import TrainingHistory from "../views/home/phkl/TrainingHisotry";
import TrainingHistoryList from "../views/home/phkl/TrainingHisotry/List";

// Rigistration_Attendance
import RigistrationAttendance from "../views/home/phkl/Rigistration_Attendance";
import RigistrationAttendanceList from "../views/home/phkl/Rigistration_Attendance/List";
import RigistrationAttendanceDetail from "../views/home/phkl/Rigistration_Attendance/Detail";
import EditAttendance from '../views/home/phkl/Rigistration_Attendance/Detail/CourseAttendance/EditAttendance';
import AddAttendance from '../views/home/phkl/Rigistration_Attendance/Detail/CourseAttendance/AddAttendance';


// myProfile
import MyProfileSetting from "../views/home/regional/myProfile";
import ChangePassword from "../views/home/regional/myProfile/ChangePassword";
import MyProfile from "../views/home/regional/myProfile/myProfile";
import AgentPHKLProfile from "../views/home/regional/myProfile/AgentPHKLProfile";
import HouseKeeping from "../views/home/phkl/systemSettings/houseKeeping";

/**
 * hasSecondRoute: If the first level of menu has a second level of sidebar routing, this attribute should be added. The main function is to dynamically load the first sidebar menu
 * redirctPath: The back-end returned path, but it is not the final route, which needs to be redirected
 * customDevPath: The back-end does not returned path, but the front-end local needs to configure the redirected route
 * initialRoute: The back-end does not returned path，it is Locally initialized route
 */
const RouteConfig = [
  {
    title: "home",
    path: "/home",
    component: Home,
    initialRoute: true,
    children: [
      {
        id: "my_profile_setting",
        title: "Las Back-stage management",
        path: "/home/my_profile_setting",
        hasSecondRoute: true,
        component: MyProfileSetting,
        children: [
          {
            id: "my_profile_admin",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/my_profile",
            component: () => <MyProfile />
          },
          {
            id: "change_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/change_password",
            component: () => <ChangePassword />
          },
          {
            id: "forget_prubehere_password",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/forget_prubehere_password",
            component: () => <ForgetPassword />
          },
          {
            id: "pamb_agent",
            title: "Las Back-stage management",
            path: "/home/my_profile_setting/phkl_agent",
            component: () => <AgentPHKLProfile />
          },
        ]
      },
      {
        id: "system_settings",
        title: "Las Back-stage management",
        path: "/home/system_settings",
        component: SystemSettings,
        hasSecondRoute: true,
        children: [
          {
            id: "user_management",
            title: "Las Back-stage management",
            path: "/home/system_settings/user_management",
            redirctPath: "/home/system_settings/user_management/index",
            component: UserManagement,
            children: [
              {
                id: "user_management_index",
                customDevPath: true,
                path: "/home/system_settings/user_management/index",
                component: UserList,
                exact: true
              },
              {
                id: "create_phkl_ad_course_admin",
                path: "/home/system_settings/user_management/create_phkl_ad_course_admin",
                component: CreateADCourseAdmin,
                exact: true
              },
              {
                id: "create_phkl_bd_course_admin",
                path: "/home/system_settings/user_management/create_phkl_bd_course_admin",
                component: CreateBDCourseAdmin,
                exact: true
              },
              {
                id: "create_phkl_adm_course_admin",
                path: "/home/system_settings/user_management/create_phkl_adm_course_admin",
                component: CreateADMCourseAdmin,
                exact: true
              },
              {
                id: "create_phkl_pd_course_admin",
                path: "/home/system_settings/user_management/create_phkl_pd_course_admin",
                component: CreatePDCourseAdmin,
                exact: true
              },
              {
                id: "create_phkl_ad_candidate",
                path: "/home/system_settings/user_management/create_phkl_ad_candidate",
                component: CreateADCandidates,
                exact: true
              },
              {
                id: "create_phkl_adm_candidate",
                path: "/home/system_settings/user_management/create_phkl_adm_candidate",
                component: CreateADMCandidates,
                exact: true
              },
              {
                id: "admin_details",
                path: "/home/system_settings/user_management/admin_details",
                component: AdminDetail,
                customDevPath: true,
                exact: true
              },
              {
                id: "user_details",
                path: "/home/system_settings/user_management/user_details",
                component: UserDetail,
                customDevPath: true,
                exact: true
              },
            ]
          },
          {
            id: "house_keeping",
            title: "Las Back-stage management",
            path: "/home/system_settings/housekeeping",
            component: HouseKeeping,
          },
        ]
      },
      {
        id: "training_history",
        title: "Las Back-stage management",
        path: "/home/training_history",
        redirctPath:
          "/home/training_history/training_history/search",
        hasSecondRoute: true,
        component: TrainingHistory,
        children: [
          {
            id: "training_requirement",
            title: "Las Back-stage management",
            path: "/home/training_history/training_history/search",
            component: TrainingHistoryList,
          },
        ]
      },
      {
        id: "attendance",
        title: "Las Back-stage management",
        path: "/home/attendance",
        hasSecondRoute: true,
        component: RigistrationAttendance, // Attendance,
        children: [
          {
            id: "course_finder",
            title: "Las Back-stage management",
            path: "/home/attendance/course_finder",
            component: () => <RigistrationAttendanceList />
          },
          {
            id: "session_detail",
            title: "Las Back-stage management",
            path: "/home/attendance/session_detail",
            exact: true,
            customDevPath: true,
            component: () => <RigistrationAttendanceDetail />
          },
          {
            id: "add_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/add_attendance",
            customDevPath: true,
            component: () => <AddAttendance />
          },
          {
            id: "edit_attendance",
            title: "Las Back-stage management",
            path: "/home/attendance/edit_attendance",
            customDevPath: true,
            component: () => <EditAttendance />
          },
        ]
      },
      {
        id: "404",
        path: "/home/404",
        component: NotFound,
        initialRoute: true
      },
      {
        id: "403",
        path: "/home/403",
        component: Forbidden,
        initialRoute: true
      },
    ]
  },
  {
    id: "403",
    path: "/403",
    component: Forbidden,
    initialRoute: true
  },
  {
    id: "500",
    path: "/500",
    component: ServerError,
    initialRoute: true
  },
];

export default RouteConfig;
