import React, { Component } from "react";
import moment from 'moment';
import { Form, Table } from "antd";
import { post, get } from "../../../utils/request";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import withConnector from './connector';
import styles from "./index.module.less";
import Header from '../Header';
import SearchBar from './SearchBar';

@withConnector
class CustomList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 10,
      searchData: {
        currentPageNumber: 1,
      },
      dataSource: [],
      loading: false
    };

    this.searchBarRef = React.createRef();
  }

  componentDidMount() {
    this.handleInitData();
  }

  handleInitData = () => {
    const { fieldsData } = this.props.commonRenducers.usefulTemporaryData;
    const { keyword, noSearch = false } = this.props;

    if (noSearch) {
      this.fetchData();
    }

    if (fieldsData[keyword]) {
      const { searchData } = fieldsData[keyword];

      this.setState({
        searchData
      }, () => {
        this.fetchData();
      });
    }
  }

  reloadTable = () => {
    this.handleInitData();
  }

  search = (searchForm, needTimestamp = true) => {
    if (this.props.onTableResearch) {
      this.props.onTableResearch();
    }

    searchForm.validateFields({ first: true }, (err, values) => {
      if (err) {
        return;
      }

      const valuesData = {};
      if (values.scanDateRange) {
        values.startDate = values.scanDateRange[0];
        values.endDate = values.scanDateRange[1];
      }

      Object.keys(values).map(item => {
        const elt = values[item];
        if (!elt) {
          return;
        }

        if (elt && typeof elt === "string") {
          valuesData[item] = elt.trim();
        } else if (elt._isAMomentObject) { // eslint-disable-line
          valuesData[item] = needTimestamp ? moment(elt).format('x') : moment(elt).format('YYYY-MM-DD')
        } else {
          valuesData[item] = elt;
        }
      });

      this.setState({
        searchData: {
          ...valuesData,
          currentPageNumber: 1
        }
      }, () => {
          this.props.saveFields(this.props.keyword, 'searchData', {
          ...this.state.searchData,
        });

        this.fetchData();
      });
    });
  }

  handleTableChange = pagination => {
    const { current } = pagination;
    const searchData = {
      ...this.state.searchData,
      ...{ currentPageNumber: current }
    };

    this.props.saveFields(this.props.keyword, 'searchData', searchData);

    this.setState({
      searchData
    }, () => {
      this.fetchData();
    });
  };

  download = async () => {
    const { downloadUrl, downloadFileName } = this.props;
    await handleCheckToken()
    const bold = await post(downloadUrl, this.state.searchData, "", "blob")
    downloadFile(bold, downloadFileName);
  }

  async fetchData() {
    const { fetchUrl, method } = this.props;
    this.setState({
      loading: true,
      dataSource: []
    });

    try {
      let res;
      if (method === 'get') {
        res = await get(fetchUrl, this.state.searchData);
      } else {
        res = await post(fetchUrl, this.state.searchData);
      }

      const {
        data: { data, items, total, currentPageNumber, pageSize }
      } = res;

      this.setState({
        loading: false,
        total,
        pageSize,
        dataSource: data || items,
        searchData: {
          ...this.state.searchData,
          ...{ currentPageNumber }
        }
      });

      if (typeof this.props.onTableLoaded === 'function') {
        this.props.onTableLoaded({
          searchData: this.state.searchData,
          dataSource: this.state.dataSource
        });
      }
    } catch (error) {
      this.setState({
        loading: false
      });
    }
  }

  render() {
    const { searchFileds, columns, headerTitle, needTimestamp, keyword, canScroll = false, maxHeight = 450, rowSelection, rowKey = '', onTableChange, noSearch = false, downloadUrl, headerBtnDef, isTopNav = true, scroll = { x: 2000 } } = this.props;
    const { dataSource, loading, total, pageSize } = this.state;

    return (
      <div className={styles.container}>
        <Header title={headerTitle} isTopNav={isTopNav} />
        {
          !noSearch && <SearchBar
            searchFileds={searchFileds}
            ref={this.searchBarRef}
            search={(form) => { this.search(form, needTimestamp) }}
            download={this.download}
            needDownload={!!downloadUrl && total > 0}
            keyword={keyword}
            headerBtnDef={headerBtnDef}
          />
        }
        <div className={styles.table}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              total,
              pageSize,
              current: this.state.searchData.currentPageNumber
            }}
            size="small"
            loading={loading}
            onChange={pagination => {
              this.handleTableChange(pagination);
              onTableChange && onTableChange(pagination);
            }}
            className={styles.ant_dev_table}
            rowKey={(data, index) => {
              return data[`${rowKey}`] || index;
            }}
            scroll={scroll}
            rowSelection={rowSelection}
          />
        </div>
      </div>
    )
  }
}

const CustomListWithForm = Form.create({ name: "CustomList", withRef: true })(CustomList);

export default CustomListWithForm;
