import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Progress } from "antd";
// import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
// import { handleMyProfile } from "../../../../redux/homeReducer";
// import { myProfile } from "../../../../redux/userReducer";
// import { saveProfile } from "../../../../redux/pacsReducer/myProfileReducers/myProfileReducer";
import styles from "./styles.module.less";

@connect(
  state => ({
    fields: {
      ...state.pclaReducer.myProfileReducers.myProfileReducer,
      role: state.userReducer.roleid || state.userReducer.roleId,
      bu: state.userReducer.bu,
      account: state.userReducer.loginaccount,
    },
    user: { ...state.userReducer },
    buList: state.pclaReducer.systemSettingsReducers.userManagementReducer.buList.length
      ? [...state.pclaReducer.systemSettingsReducers.userManagementReducer.buList]
      : [],
    rolerList: state.pclaReducer.systemSettingsReducers.userManagementReducer.allRoleList
      .length
      ? [...state.pclaReducer.systemSettingsReducers.userManagementReducer.allRoleList]
      : []
  }),
  {
    // changeVals,
    // handleMyProfile,
    // myProfile,
    // saveProfile
  }
)
class CPDDashboard extends Component {
  state = {
    isEditable: true
  };

  componentDidMount() {}


  render() {
    const { Option } = Select;
    return (
      <div className={styles.container}>
        <div className={styles.title}>My CPD Achievement</div>
        <div className={styles.selectContent}>
          <Select style={{ width: "200px", marginRight: "10px", marginBottom: "30px" }}>
            <Option value="jack">Jack</Option>
          </Select>
          <span>Achievement</span>
        </div>
        <div className={styles.dashboardContent}>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div>CORE(TOTAL)</div>
              <div><span>5</span> to fulfill</div>
              <div>1 Attained / 5 Required</div>
            </div>
            <Progress percent={50} strokeColor="#F14A4A" showInfo={false} />
          </div>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div>SUPPLEMENTARY(TOTAL)</div>
              <div><span>5</span> to fulfill</div>
              <div>1 Attained / 5 Required</div>
            </div>
            <Progress percent={50} strokeColor="#F14A4A" showInfo={false} />
          </div>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div>SUPPLEMENTARY(A&H)</div>
              <div><span>5</span> to fulfill</div>
              <div>1 Attained / 5 Required</div>
            </div>
            <Progress percent={50} strokeColor="#F14A4A" showInfo={false} />
          </div>
        </div>
      </div>
    );
  }
}

export default CPDDashboard;
