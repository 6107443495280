import { combineReducers } from "redux";
import systemSettingsReducers from "./systemSettingsReducers";
import CPDSettingsReducers from "./CPDSettingsReducers";
import myProfileReducers from "./myProfileReducers";
import attendanceReducers from "./attendanceReducers";
import CPDReportReducers from "./CPDReportReducers";

export default combineReducers({
  systemSettingsReducers,
  CPDSettingsReducers,
  myProfileReducers,
  attendanceReducers,
  CPDReportReducers
});
