import { combineReducers } from "redux";
import myProfileReducer from "./myProfileReducer";
import ChangePasswordReducer from "./ChangePasswordReducer";
import Agent_PAMB_Reducer from "./Agent_PAMB_Reducer";
import Agent_PACS_Reducer from "./Agent_PACS_Reducer";
import CPDDashboardReducer from "./CPDDashboardReducer";
import Agent_PLUK_Reducer from "./Agent_PLUK_Reducer";

export default combineReducers({
  myProfileReducer,
  ChangePasswordReducer,
  Agent_PAMB_Reducer,
  Agent_PACS_Reducer,
  Agent_PLUK_Reducer,
  CPDDashboardReducer,
});
