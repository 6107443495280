import { notification } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";

const SAVE_PACS_CPDDASHBOARD = "SAVE_PACS_CPDDASHBOARD";
const INIT_PACS_CPDDASHBOARD = "INIT_PACS_CPDDASHBOARD";

const initState = {
  yearList: [],
  coreAttained: 0,
  coreRequired: 0,
  coreTotal: 0,
  supplementtaryAttained: 0,
  supplementtaryRequired: 0,
  supplementtaryTotal: 0,
  aandHAttained: 0,
  aandHRequired: 0,
  aandH: 0
};

// reducer
export default function CPDDashboardReducer(state = initState, action) {
  switch (action.type) {
    case "CPDDashboard":
      return { ...state, ...action.payload };
    case SAVE_PACS_CPDDASHBOARD:
      return { ...state, ...action.payload };
    case INIT_PACS_CPDDASHBOARD:
      return { ...initState };
    default:
      return state;
  }
}

export function save_data(payload) {
  return {
    type: SAVE_PACS_CPDDASHBOARD,
    payload
  };
}

export function init_data() {
  return {
    type: INIT_PACS_CPDDASHBOARD
  };
}

// POST /pacsCPDCalculate/getYeaRequirementList

export function getYeaRequirementList() {
  return dispatch => {
    dispatch(loading(true));
    post("/pacsCPDCalculate/getYeaRequirementList")
      .then(res => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 100);
        if (res.success) {
          if (res.data !== null) {
            dispatch(save_data({
              yearList: [...res.data],
            }));
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 100);
      });
  };
}


export function getAgentOneselfCPDHours(receiveYear) {
  return dispatch => {
    dispatch(loading(true));
    get("/pacsCPDCalculate/getAgentOneselfCPDHours", { receiveYear })
      .then(res => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 100);
        if (res.success) {
          if (res.data !== null) {
            dispatch(save_data(res.data));
          }
          // if (receiveYear == "2020") {
          //   dispatch(save_data({
          //     coreAttained: 1,
          //     coreRequired: 5,
          //     coreTotal: 4,
          //     supplementtaryAttained: 9,
          //     supplementtaryRequired: 20,
          //     supplementtaryTotal: 11,
          //     aandHAttained: 2,
          //     aandHRequired: 15,
          //     aandH: 13
          //   }))
          // } else {
          // }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 100);
      });
  };
}
