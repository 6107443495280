import React, { useEffect, useState } from "react";
import { Radio, Tree, Transfer, Space } from 'antd-v5'
import { Form, Row, Col, Button, Modal, Icon, Upload } from 'antd'
import styles from './MySessionDetails.module.less'
import { ModalAlert } from "./AddAttendanceAlert";


const formLayout3 = {
  labelCol: { span: 1 },
  wrapperCol: { span: 23 }
};


const flexCol2 = {
  span: 24
};

const flexRow = {
  gutter: [16, 0]
};

function AddAttendanceModal(props) {
  const { form: { getFieldDecorator }, onChangeImportType, attendancesList, onRemoveFile, fileName, downLoadTemplate, onChangeFile, handleUpdateImport, validateInfo, onChange, visible, handleOk, handleCancel, targetKeys = [] } = props

  const [treeNodesInfo, setTreeNodesInfo] = useState({
    isAttendantKeys: [],
    isRootKeys: [],
  })

  const [originTreeData, setOriginTreeData] = useState([])
  const [originSource, setOriginSource] = useState([])
  const [successValidateInfo, setSuccessValidateInfo] = useState(validateInfo)
  // format orgin data
  const formatData = (data = []) => {
    const result = []
    const isRootKeys = []
    const isAttendantKeys = []
    data.forEach(item => {
      const { regionName, agents } = item
      isRootKeys.push(regionName)
      result.push({
        key: regionName,
        title: regionName,
        children: agents.map(elt => {
          if (elt.isAttendant) {
            isAttendantKeys.push(elt.agentId)
          }
          return {
            key: elt.agentId,
            title: `${elt.agentName} - ${elt.agentId}`,
            description: elt.agentId + elt.agentName,
            isAttendant: elt.isAttendant,
            isLeaf: true,
          }
        })
      })
    })
    const treeNodesInfo = {
      isAttendantKeys,
      isRootKeys,
    }
    setTreeNodesInfo(treeNodesInfo)
    return result
  }
  const [expandedKeys, setExpandedKeys] = useState([])
  const [transferDataSource, setTransferDataSource] = useState([])
  const [treeData, setTreeData] = useState(originTreeData)
  const generateTree = (treeNodes = [], checkedKeys = []) => {
    return treeNodes.map(({ children, ...props }) => ({
      ...props,
      disabled: props.isAttendant,
      // disableCheckbox: !props.isLeaf,
      children: generateTree(children, checkedKeys),
    }));
  }
  const filterOption = (inputValue, option) => {
    try {
      return option.description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    } catch (e) {
      console.log(e)
    }
  }




  useEffect(() => {
    const originTreeData = formatData(attendancesList)
    setOriginTreeData(originTreeData)
    const treeData = originTreeData
    const dataSource = []
    function flatten(list = []) {
      list.forEach(item => {
        if (item.children) {
          flatten(item.children)
        } else {
          dataSource.push(item)
        }
      })
    }
    flatten(originTreeData)
    setTransferDataSource(dataSource)
    setOriginSource(dataSource)
    setTreeData(treeData)
  }, [attendancesList])

  useEffect(() => {
    setSuccessValidateInfo(validateInfo)
  }, [validateInfo])



  const isChecked = (selectedKeys, eventKey) => {
    if (treeNodesInfo.isRootKeys.indexOf(eventKey) !== -1) {
      return true
    }
    return selectedKeys.indexOf(eventKey) !== -1
  }

  const handleSearchTree = (direction, value, props) => {
    if (direction === 'left') {
      // filter tree data and expand parent node
      const filterTreeData = originTreeData.map(item => {
        const { children, ...props } = item
        return {
          ...props,
          children: children.filter(elt => elt.description.toLowerCase().indexOf(value.toLowerCase()) > -1)
        }
      })
      setTreeData(filterTreeData)
      const expandedKeys = filterTreeData.map(item => {
        if (item.children.length > 0) {
          return item.key
        }
      })
      setExpandedKeys(expandedKeys)
    } else {
      // filter transfer data
      const filterTransferData = originSource.filter(item => item?.description?.toLowerCase().indexOf(value.toLowerCase()) > -1)
      setTransferDataSource(filterTransferData)
    }
  }

  const dealCheckboxSelected = ({ node, onItemSelect, onItemSelectAll, checkedKeys }) => {
    const { checked, halfCheckedKeys, node: { key, children } } = node
    if (children?.length > 0 || treeNodesInfo.isRootKeys.indexOf(key) !== -1) {
      const keys = []
      children.forEach(item => {
        if (!item.isAttendant) {
          keys.push(item.key)
        }
      })
      onItemSelectAll([...keys], checked)
    } else {
      let parentKey = ''
      treeData.forEach(item => {
        const { children, ...props } = item
        children.forEach(elt => {
          if (elt.key === key) {
            parentKey = props.key
          }
        })
      })
      if (!checked) {
        onItemSelectAll([key, parentKey], checked)
      } else {
        onItemSelect(key, checked)
      }
    }
  }

  const bannerTypeMap = {
    WARNING_YELLOW: 'warning',
    WRONG_RED: 'error',
    SUCCESS_GREEN: 'success',
  }


  let errorDetail = {}
  if (validateInfo.isShowErrorBanner && validateInfo.importErrors.length > 0) {
    errorDetail = validateInfo.importErrors[0]
  }

  const bannerType = bannerTypeMap[errorDetail?.colorCode]

  const handleExpand = (expandedKeysValue, { expanded, node }) => {
    setExpandedKeys([...expandedKeysValue])
    setTransferDataSource([...transferDataSource])
  }





  return (
    <Modal
      title="Add Session Attendance"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText="Confirm"
      width="1000px"
      className={styles.ant_dev_modal}
      destroyOnClose
    >
      {
        validateInfo.isShowErrorBanner && (
          <ModalAlert
            message={errorDetail?.errorDesc}
            type={bannerType}
            handleUpdateImport={handleUpdateImport}
            errorDetail={errorDetail}
          />
        )
      }
      {
        (successValidateInfo?.isShowSuccessBanner) && (
          <div className={styles.ant_dev_modal_alert}>
            <ModalAlert
              message={successValidateInfo?.successDesc}
              type='success'
              duration={5}
              onClose={() => setSuccessValidateInfo({})}
            />
          </div>
        )
      }
      <Form
        {...formLayout3}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <Form.Item>
              {getFieldDecorator("attendance", {
                initialValue: "transfer",
                rules: [{
                  required: true,
                  message: "Please fill in the mandatory fields"
                }]
              })(
                <Radio.Group onChange={onChangeImportType}>
                  <Space direction="vertical">
                    <Radio
                      className={styles.ant_dev_radio_transfer}
                      value="transfer">
                      <Transfer
                        dataSource={transferDataSource}
                        showSearch
                        filterOption={filterOption}
                        targetKeys={targetKeys}
                        onChange={onChange}
                        onSearch={(direction, value, props) => handleSearchTree(direction, value, props)}
                        render={item => item.title}
                        className={styles.ant_dev_transfer}
                        oneWay
                        titles={['Agent', 'Attendee']}
                        locale={{
                          itemUnit: 'Agent',
                          itemsUnit: 'Agents',
                          searchPlaceholder: 'Search for agent name or agent ID',
                          notFoundContent: 'No agent found',
                        }}
                        selectAllLabels={
                          [
                            ({ selectedCount, totalCount }) => `${selectedCount ? `${selectedCount} / ${totalCount} ` : `${totalCount} `}`,
                            ({ selectedCount, totalCount }) => `${selectedCount ? `${selectedCount} / ${totalCount} ` : `${totalCount} `}`,
                          ]
                        }
                      >
                        {({ direction, onItemSelect, onItemSelectAll, selectedKeys }) => {
                          if (direction === 'left') {
                            const isAttendantKeys = treeNodesInfo.isAttendantKeys
                            const checkedKeys = [...targetKeys, ...selectedKeys, ...isAttendantKeys]
                            return (
                              <div className={styles.ant_dev_transfer_tree}>
                                <Tree
                                  blockNode
                                  checkable
                                  checkedKeys={checkedKeys}
                                  treeData={generateTree(treeData, checkedKeys)}
                                  onCheck={(_, node) => {
                                    dealCheckboxSelected({ node, onItemSelect, onItemSelectAll, checkedKeys })
                                  }}
                                  onSelect={(_, { node: { key } }) => {
                                    onItemSelect(key, !isChecked(checkedKeys, key))
                                  }}
                                  onExpand={handleExpand}
                                  expandedKeys={[...expandedKeys]}
                                />
                              </div>
                            )
                          }
                        }}
                      </Transfer>
                      <span className={styles.transferTips}>Only active agents in the program are listed on the left. For agent leader sessions, only his or her downline agents are listed here.</span>
                    </Radio>
                    <Radio value="import">
                      {/* upload component */}
                      <Space>
                        <span className={styles.importText}>Import</span>
                        <Upload
                          multiple={false}
                          showUploadList={false}
                          onRemove={onRemoveFile}
                          accept=".xlsx"
                          beforeUpload={onChangeFile}
                        >
                          <div className={styles.uploadBox}>
                            <span className={styles.fileName}>{fileName}</span>
                            <Icon type="folder-open" className={styles.folder}></Icon>
                          </div>
                        </Upload>
                        <Button
                          className={styles.downloadBtn}
                          icon="download"
                          onClick={downLoadTemplate}
                        >
                          Download Template
                        </Button>
                      </Space>
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

const AddAttendanceModalForm = Form.create()(AddAttendanceModal)

export default AddAttendanceModalForm


