import { notification } from "antd";
import {
  SET_PSD_SUCCESS,
  SET_PSD,
  INIT_PSD,
  LOGIN,
  FORGET_PSD,
  SEND_EMAIL_SUCCESS,
  BACK,
  CHANGE_FORM,
  LOGIN_SUCCESS,
  SAVE,
  CHECK_CODE_SUCCESS,
  LOGOUT
} from "./pambReducer/actionTypes";
import { get, post } from "../utils/request";
import { globalPromptTime10s } from "../utils/global";

const CHANGEPSD = "CHANGEPSD";

const initState = {
  sendEmail: false,
  checkEmail: false,
  loginLoading: false,
  loginType: "login",
  psdType: "init",
  changePsd: false,
  account: "",
  aticode: "",
  password: "*******",
  inputShowTip: true,
  expired: false,
  login_fields: {
    username: {
      value: ""
    },
    password: {
      value: ""
    }
  },
  setPsd_fields: {
    repassword: {
      value: ""
    },
    password: {
      value: ""
    },
    oldPassword: {
      value: ""
    }
  },
  setEmail_fields: {
    email: {
      value: ""
    }
  }
};

export default function loginReducer(state = initState, action) {
  switch (action.type) {
    case CHANGE_FORM:
      const { type } = action.payload;
      const fields_type = `${type}_fields`;
      return {
        ...state,
        inputShowTip: false,
        [fields_type]: { ...state[fields_type], ...action.payload.fields }
      };
    case SAVE:
      return { ...state, ...action.payload };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false
      };
    case LOGIN:
      return { ...state, loginType: "login", changePsd: false };
    case FORGET_PSD:
      return { ...state, loginType: "forget_password" };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loginLoading: false,
        sendEmail: true
      };
    case CHECK_CODE_SUCCESS:
      return {
        ...state,
        checkEmail: true,
        loginLoading: false,
        ...action.payload
      };
    case SET_PSD:
      return {
        ...state,
        account: action.payload,
        loginType: "set_password",
        psdType: "reset",
        expired: false,
        sendEmail: true,
        changePsd: false
      };
    case CHANGEPSD:
      return {
        ...state,
        account: action.payload,
        loginType: "set_password",
        psdType: "reset",
        expired: false,
        sendEmail: true,
        changePsd: true
      };
    case SET_PSD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loginLoading: false,
        login_fields: {
          username: {
            value: state.account
          },
          password: {
            value: ""
          }
        }
      };
    case INIT_PSD:
      return {
        ...state,
        account: action.payload,
        loginType: "set_password",
        psdType: "init",
        expired: false,
        sendEmail: true,
        changePsd: false
      };
    case BACK:
      const logintype =
        action.payload === "forgetPsd" ? "login" : "forget_password";
      return { ...state, loginType: logintype, psdType: "init" };
    default:
      return state;
  }
}

// go to login page
export function login() {
  return {
    type: LOGIN,
    payload: ""
  };
}

// go to forget passd_word page
export function forgetPsd() {
  return {
    type: FORGET_PSD,
    payload: ""
  };
}

// go to reset passd_word page
export function setPsd(props) {
  const { search } = props.history.location;
  if (search.indexOf("&") > -1) {
    const arr = search.split("&");
    const loginAccount = arr[1].split("=");
    const aticode = arr[2].split("=");
    let finalCode = aticode[1];
    for (let i = 2; i < aticode.length; i += 1) {
      const now = `=${aticode[i]}`;
      finalCode += now;
    }
    const params = {};
    params[loginAccount[0]] = loginAccount[1];
    params[aticode[0]] = finalCode;
    return dispatch => {
      dispatch({
        type: SET_PSD,
        payload: loginAccount[1]
      });
      dispatch(check_code(params));
    };
  }
}

// go to init passd_word page
export function initPsd(props) {
  const { search } = props.history.location;
  if (search.indexOf("&") > -1) {
    const arr = search.split("&");
    const loginAccount = arr[1].split("=");
    const aticode = arr[2].split("=");
    let finalCode = aticode[1];
    for (let i = 2; i < aticode.length; i += 1) {
      const now = `=${aticode[i]}`;
      finalCode += now;
    }
    const params = {};
    params[loginAccount[0]] = loginAccount[1];
    params[aticode[0]] = finalCode;
    return dispatch => {
      dispatch({
        type: INIT_PSD,
        payload: loginAccount[1]
      });
      dispatch(check_code(params));
    };
  }
}

// back
export function back(now) {
  return {
    type: BACK,
    payload: now
  };
}

// save anything
export function save(now) {
  return {
    type: SAVE,
    payload: now
  };
}

// login api
export function submit_login(payload) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    post("/login", payload)
      .then(res => {
        if (res.success) {
          if (res.data && res.data.token) {
            sessionStorage.setItem("user", res.data.token);
            sessionStorage.setItem("refreshToken", res.data.refreshToken);
            sessionStorage.setItem("buCountry", res.data.country);
          }
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              // user: payload.username,
              user: payload.loginAccount, // update by zhy
              data: res.data
            }
          });
        } else {
          dispatch(
            save({
              loginLoading: false
            })
          );
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(error => {
        dispatch(
          save({
            loginLoading: false
          })
        );
        if (error.code === 1111) {
          dispatch({
            type: CHANGEPSD,
            payload: payload.loginAccount
          });
        }
      });
  };
}

// send email api
export function send_email(payload) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    get("/login/generateLink", payload)
      .then(res => {
        if (res.success) {
          dispatch({
            type: SEND_EMAIL_SUCCESS,
            payload: {
              account: payload.loginAccount
            }
          });
          notification.success({
            message: "Email sent successfully",
            duration: globalPromptTime10s
          });
        } else {
          dispatch(
            save({
              loginLoading: false
            })
          );
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
        dispatch(
          save({
            loginLoading: false
          })
        );
      });
  };
}

// init passd_word api
export function init_password(payload) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    post("/login/initPassword", payload)
      .then(res => {
        if (res.success) {
          dispatch({
            type: SET_PSD_SUCCESS,
            payload: {
              password: payload.password
            }
          });
          dispatch(login());
          notification.success({
            message: "Password set successfully",
            duration: globalPromptTime10s
          });
        } else {
          dispatch(
            save({
              loginLoading: false
            })
          );
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
        dispatch(
          save({
            loginLoading: false
          })
        );
      });
  };
}
// reset passd_word api
export function reset_password(payload) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    post("/login/resetPassword", payload)
      .then(res => {
        if (res.success) {
          dispatch({
            type: SET_PSD_SUCCESS,
            payload: {
              password: payload.password
            }
          });
          dispatch(login());
          notification.success({
            message: "Password set successfully",
            duration: globalPromptTime10s
          });
        } else {
          dispatch(
            save({
              loginLoading: false
            })
          );
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
        dispatch(
          save({
            loginLoading: false
          })
        );
      });
  };
}

// change passd_word api
export function change_password(payload) {
  return () => {
    post("/login/changePassword", payload)
      .then(res => {
        if (res.success) {
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// email check code api
export function check_code(payload) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    get("/login/checAticode", payload)
      .then(res => {
        if (res.success) {
          dispatch({
            type: CHECK_CODE_SUCCESS,
            payload: {
              aticode: payload.aticode
            }
          });
        } else {
          dispatch(
            save({
              loginLoading: false
            })
          );
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch((err) => {
        let expired = false;
        if (err.message.indexOf("expired") > -1) {
          console.log("expired") // eslint-disable-line
          expired = true;
        }
        dispatch(
          save({
            loginLoading: false,
            expired
          })
        );
      });
  };
}

// Create change passd_word api
export function changePassword(payload, innerWidth) {
  return dispatch => {
    dispatch(
      save({
        loginLoading: true
      })
    );
    post("/login/changePassword", payload)
      .then(res => {
        if (res.success) {
          if (innerWidth <= 414) {
            alert("Password modified successfully, please login PRUBeHere again");
          } else {
            dispatch(login());
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(
            save({
              loginLoading: false
            })
          );
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(
            save({
              loginLoading: false
            })
          );
        }, 500);
      });
  };
}

// change login module all form value
export function changeVals(type, val) {
  return {
    type: CHANGE_FORM,
    payload: {
      type,
      fields: val
    }
  };
}

// logout
export function logout(history) {
  return {
    type: LOGOUT,
    payload: { history }
  };
}
