import { notification } from "antd";
import { isArray } from "lodash";
import moment from "moment";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

export const SAVE_DATA = 'LIST_REGISTRATIONS_SAVE'
export const ONCAHENG_TYPE = "GetOptions"

export const fields = {
  agentId: {
    key: "",
    value: ""
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: "",
  },
  status: {
    key: "",
    value: ""
  },
}

export const initState = {
  agentId: {
    key: "",
    value: ""
  },
  agentLeader: {
    key: "",
    value: ""
  },
  region: {
    key: "",
    value: ""
  },
  unitCode: {
    key: "",
    value: ""
  },
  registrationDate: {
    key: "",
    value: "",
  },
  status: {
    key: "",
    value: ""
  },
  orderBy: {
    // ASCENDING
    value: ""
  },
  voidRecord: {
    value: "SCAN_IN"
  },
  clockRecord: {
    value: "SCAN_IN"
  },
  updateStatus: {
    value: "",
    key: ""
  },
  courses: [],
  registrationConfig: null,
  agentIds: [],
  regions: [],
  unitCodes: [],
  agentLeaders: [],
  statuses: [],
  attendances: [],
  totalPage: 0,
  currentPageNumber: 1,
  pageSize: 10,
  downLoading: false
};

// reducer
export default function AttendanceDetailReducer(state = initState, action) {
  switch (action.type) {
    case 'GetOptions':
    case SAVE_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: SAVE_DATA
  };
}

// clear reducer data
export function clearData() {
  return {
    type: SAVE_DATA,
    payload: initState
  };
}

// clear
export function clearUpdateStatus() {
  return {
    type: SAVE_DATA,
    payload: {
      updateStatus: {
        value: "",
        key: ""
      },
      voidRecord: {
        value: "SCAN_IN"
      },
      clockRecord: {
        value: "SCAN_IN"
      },
    }
  };
}


export function changeVals(type, val) {
  return dispatch => {
    return dispatch({
      type,
      payload: val
    });
  };
}

const baseApiUrl = '/pruventure/attendances'
const attendanceOption = `${baseApiUrl}/options`
const baseOptionsUrl = `/pruventure/registrations/options`

export function resetDetailData() {
  return dispatch => {
    dispatch({
      type: SAVE_DATA,
      payload: {
        detailData: {}
      }
    })
  }
}

export function updateAttendance(sessionId, payload) {
  return dispatch => {
    dispatch(loading(true))
    return put(`${baseApiUrl}/${sessionId}`, payload)
      .then(res => {
        dispatch(loading(false))
        if (res.success) {
          notification.success({
            message: 'update attendance success',
            duration: globalPromptTime10s,
            className: "ant-style-notification-success"
          })
        } else {
          notification.error({
            message: 'update attendance error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  }
}

/**
 * @param {*} payload {search: string}
 */
export function getUnitCodes(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/unitCodes`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [{ key: 'All', value: '' }, ...data],
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              unitCodes: [],
            }
          })
          notification.error({
            message: 'get unitCodes error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getStatus(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${attendanceOption}/status`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          if (data.length > 0) {
            dispatch({
              type: SAVE_DATA,
              payload: {
                statuses: data,
                status: data[0],
              }
            });
          }
        } else {
          notification.error({
            message: 'get status error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload
 */
export function getRegions(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/regions`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              regions: data,
              region: data[0]
            }
          });
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentLeaders(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentLeaders`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentLeaders: [{ key: 'All', value: '' }, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @returns
 */
export function getAgentIds(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseOptionsUrl}/agentIds`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              agentIds: [{ key: 'All', value: '' }, ...data],
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * {
    "pageSize": 0,
    "currentPageNumber": 0,
    "orderBy": "string",
    "agentId": "string",
    "agentLeader": "string",
    "region": "string",
    "unitCode": "string",
    "registrationDate": "2023-01-12",
    "status": "Active"
  }
 * @param {*} payload
 * @returns
 */
export function getAttendance(payload, sessionId) {
  return dispatch => {
    dispatch(loading(true))
    get(`${baseApiUrl}/session/${sessionId}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
              attendances: data.items
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function downLoadExcel(fileName, payload, sessionId) {
  return dispatch => {
    dispatch(loading(true))
    get(`${baseApiUrl}/session/${sessionId}/excel`, payload, {}, 'blob')
      .then(blob => {
        downloadFile(blob.blob, blob.fileName);
        dispatch(loading(false));
      })
      .catch((res) => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// id: registrationId
export function deleteRegistrations(id, payload) {
  return dispatch => {
    dispatch(loading(true))
    return deleteReq(`${baseApiUrl}/${id}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          notification.success({
            message: 'delete successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'delete registration error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function updateRegistrations(id, payload) {
  return dispatch => {
    dispatch(loading(true))
    return put(`${baseApiUrl}/${id}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          notification.success({
            message: 'update registration date successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'update registration error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}



