import React from "react";
import { connect } from "react-redux";
import Router from "../../../../../router";
import { getMandatory } from "../../../../../redux/plukReducer/systemSettingsReducers/SurveyReducer";

@connect(
  state => state,
  { getMandatory }
)
class SurveyManagement extends React.Component {
  componentDidMount() {
    this.props.getMandatory();
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default SurveyManagement;
