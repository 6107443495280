import { message } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pluk/CandidateReducer/SAVE";

const initState = {
  createLoading: false,
  userInfo: {},
  leaderName: ""
};

// reducer
export default function CandidateReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleSubmit(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        createLoading: true
      }
    });
    post("/candidate/createCandidate", payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              createLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLoading: false
          }
        });
      });
  };
}

export function updateCandidate(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        createLoading: true
      }
    });
    post("/candidate/updateCandidate", payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              createLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLoading: false
          }
        });
      });
  };
}

export function getLeaderNameByUnitCode(payload) {
  return dispatch => {
    get("/candidate/getLeaderNameByUnitCode", payload)
      .then((res) => {
        const { data } = res
        dispatch({
          type: SAVE,
          payload: {
            leaderName: data
          }
        });
      })
      .catch(() => {
      });
  };
}

export function resetLeaderName(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        leaderName: payload
      }
    })
  };
}

export function getCandidateDetail(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        createLoading: true
      }
    });
    get("/candidate/getDetail", payload)
      .then(res => {
        const { data } = res;
        const { leaderName } = data || {}
        dispatch({
          type: SAVE,
          payload: {
            leaderName,
            userInfo: data || {},
            createLoading: false
          }
        })
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLoading: false
          }
        });
      });
  };
}

export function handleReset() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        userInfo: {},
        leaderName: ''
      }
    })
  }
}
