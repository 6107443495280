import React, { useState } from "react";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { Form, Row, Col, Button, Checkbox, Spin, DatePicker, notification, Input } from "antd";
import { post } from '../../../../../../../utils/request';
import styles from '../../index.module.less';
import { validate } from '../utils';
import Header from '../../../../../../components/Header';

const { RangePicker } = DatePicker;

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
};

function EditAttendance(props) {
  const [loading, setLoading] = useState(false);
  const { form: { getFieldDecorator } } = props;
  const { courseDetail, record } = props.location.state;

  const handleSubmit = async () => {
    props.form.validateFields({ first: true }, async (err, values) => {
      if (validate(values, courseDetail)) {
        setLoading(true);
        const payload = {
          ...record,
          scanInDate: values.scanDateRange[0].format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          scanOutDate: values.scanDateRange[1].format(
            "YYYY-MM-DD HH:mm:ss"
          ),
         };

        await post("/attendanceTms/updateAttendance", payload);
        setLoading(false);

        notification.success({
          message: "Successfully updated",
          duration: 10
        });

        props.history.push("/home/attendance/session_detail", {
          sessionId: courseDetail.sessionId,
          courseId: courseDetail.courseId
        });
      }
    });
  }

  return (
    <div>
      <Header title="Edit Attendance" />
      <Spin spinning={loading}>
        <Form
          hideRequiredMark
          layout="horizontal"
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          colon={false}
        >
          <Row gutter={[24, 0]}>
            <Col {...flexCol}>
              <Form.Item labelCol={{ span: 5 }} label="Walk-In">
                {getFieldDecorator("isWalkIn", {
                  valuePropName: "checked",
                  initialValue: record.isWalkIn
                })(<Checkbox disabled>Walk-In</Checkbox>)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agent Code" labelCol={{ span: 5 }}>
                {getFieldDecorator("agentCode", {
                  initialValue: record.agentCode
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Name" labelCol={{ span: 5 }}>
                {getFieldDecorator("agentName", {
                  initialValue: record.agentName
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Scan In & Out" style={{ marginBottom: 0 }}>
                {getFieldDecorator("scanDateRange", {
                  initialValue: [
                    record.scanInDate ? moment(record.scanInDate) : null,
                    record.scanOutDate ? moment(record.scanOutDate) : null,
                  ],
                  rules: [
                    {
                      required: true,
                      message: "Scan in is empty"
                    }
                  ]
                })(
                  <RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment("00:00:00", "HH:mm:ss"),
                        moment("23:59:59", "HH:mm:ss")
                      ]
                    }}
                    placeholder={["Scan In", "Scan Out"]}
                    style={{ width: "100%" }}
                    format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={insideStyles.goCenter}>
              <Form.Item>
                <Button
                  type="primary"
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>

  );
}

const AttendanceTempWithForm = Form.create({ name: "EditAttendance" })(EditAttendance);

export default withRouter(AttendanceTempWithForm);
