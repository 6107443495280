import types from './types';

export const saveFields = (key, field, val) => (dispatch, getState) => {
  const {
    commonRenducers: { usefulTemporaryData }
  } = getState().phklReducer;

  const { fieldsData } = usefulTemporaryData;

  if (!Object.prototype.hasOwnProperty.call(fieldsData, key)) {
    fieldsData[key] = {};
  }

  fieldsData[key][field] = val;

  dispatch({
    type: types.SAVE_FIELDS,
    payload: {
      usefulTemporaryData
    }
  });
};
