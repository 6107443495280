import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Table, Row, Col, Icon, Divider } from "antd";
import { connect } from "react-redux";
import styles from "./AddQuestionGroup.module.less";
import { queryDataToTem } from "../../../../../redux/plukReducer/systemSettingsReducers/QuestionGroupReducer";
import {
  addQuestionBySurveyId,
  handleSaveOriginData
} from "../../../../../redux/plukReducer/systemSettingsReducers/SurveyReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ plukReducer: { systemSettingsReducers: { QuestionGroupReducer } } }) => ({
    QuestionGroupReducer
  }),
  {
    queryDataToTem,
    addQuestionBySurveyId,
    handleSaveOriginData
  }
)
class AddQuestionGroup extends React.Component {
  state = {
    isShowTable: false,
    currentNum: 1,
    columns: [
      {
        title: "Group Name",
        dataIndex: "groupName",
        align: "center",
        width: 200,
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        dataIndex: "groupDescription",
        ellipsis: true
      },
      {
        title: "Actions",
        align: "center",
        width: "240px",
        render: (text, record) => {
          const { pambQuestionGroupId } = record;
          const { state } = this.props.location;
          return (
            <div>
              <Link
                to={{
                  pathname:
                    "/home/system_settings/question_group/question_group_detail",
                  state: {
                    ...state,
                    pambQuestionGroupId,
                    from:
                      "/home/system_settings/question_group/add_question_group_to_tem"
                  }
                }}
              >
                View
              </Link>
              <Divider type="vertical" />
              <span
                onClick={() => this.handleOkbtn({ pambQuestionGroupId })}
                className={styles.spancss}
              >
                OK
              </span>
            </div>
          );
        }
      }
    ]
  };

  componentDidMount() {
    const { state } = this.props.location;
    const { noReFresh, edit, fieldsData, questionGroups } = state || {};
    if (edit) {
      this.props.handleSaveOriginData({
        fieldsData,
        questionGroups,
        noReFresh
      });
    }
  }

  handleOkbtn = payload => {
    const { state } = this.props.location;
    const { from } = state || {};
    const callback = () => {
      this.props.history.push({
        pathname:
          from || "/home/system_settings/survey_management/survey_create",
        state
      });
    };
    this.props.addQuestionBySurveyId(payload, callback);
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { groupName } = values;
        const callback = () => {
          this.setState({
            isShowTable: true,
            currentNum: 1,
            groupName
          });
        };
        this.props.queryDataToTem(
          {
            ...values,
            currentPageNumber: 1
          },
          callback
        );
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    this.setState({ currentNum: current });
    const { groupName } = this.state;
    this.props.queryDataToTem({
      groupName,
      pageSize,
      currentPageNumber: current
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      QuestionGroupReducer: {
        questionGroupToTemData: { data: dataList, total },
        queryLoading
      }
    } = this.props;

    const { columns, isShowTable, currentNum } = this.state;
    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              const { state } = this.props.location;
              const { from } = state || {};
              this.props.history.push({
                pathname:
                  from ||
                  "/home/system_settings/survey_management/survey_create",
                state
              });
            }}
          />
          Add Group to Survey Tem
        </div>
        <div className={styles.topBox}>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Group Name">
                  {getFieldDecorator("groupName", {})(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
          </div>
        </div>
        {isShowTable ? (
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              total,
              pageSize: 10,
              current: currentNum
            }}
            size="small"
            loading={queryLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="pambQuestionGroupId"
          />
        ) : null}
      </div>
    );
  }
}

const AddQuestionGroupForm = Form.create()(AddQuestionGroup);

export default AddQuestionGroupForm;
