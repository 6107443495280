import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_TIMEJOBRERUN = "PACS/INIT_DATA_TIMEJOBRERUN";

const initState = {
  time: {
    value: ""
  },
  type: {
    value: "H"
  },
  list: [],
  detailList: [],
  loading: false,
  visible: false,
  showName: "",
  url: "",
  bu: "",
  jobTaskId: "",
  loading2: false,
  visible2: false,
  killList: []
};

// reducer
export default function TimeJobRerunReducer(state = initState, action) {
  switch (action.type) {
    case "TimeJobRerun":
      return { ...state, ...action.payload };
    case INIT_DATA_TIMEJOBRERUN:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_TIMEJOBRERUN
  };
}

export function save_data(payload) {
  return {
    type: "TimeJobRerun",
    payload
  };
}

// /attendancepacs/job/getlist

export function search(type) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendancepacs/job/getlist", { type })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              list: [...res.data]
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function detail(sequence, type, bu, taskName) {
  return dispatch => {
    dispatch(loading(true));
    get(`/${bu}/job/getDetai/${type}/${sequence}`)
      .then(res => {
        if (res.success) {
          console.log("detailList:", res); // eslint-disable-line
          const finalData = res.data.map((item) => {
            item.taskName = taskName;
            return item
          })
          dispatch(
            save_data({
              detailList: finalData
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        dispatch(loading(false));
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function stop(bu, taskId, showName, url) {
  return dispatch => {
    dispatch(loading(true));
    get(`/${bu}/job/stop?taskId=${taskId}`)
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              visible: true,
              showName,
              url,
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function getLockTable(bu, jobTaskId, showName, url) {
  return dispatch => {
    dispatch(loading(true));
    get(`/${bu}/job/getLockTable`)
      .then(res => {
        if (res.success) {
          if (!res.data.length) {
            dispatch(stop(bu, jobTaskId, showName, url))
          } else {
            dispatch(
              save_data({
                bu,
                jobTaskId,
                visible2: true,
                killList: [...res.data],
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function killLockTable(bu, spid, url, showName, jobTaskId) {
  return dispatch => {
    dispatch(
      save_data({
        loading2: true,
        loading: true
      })
    );
    get(`/${bu}/job/kill`, { ids: [...spid] })
      .then(res => {
        if (res.success) {
          dispatch(stop(bu, jobTaskId))
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        dispatch(
          save_data({
            loading2: false,
            loading: false,
            visible2: false,
          })
        );
      })
      .catch(() => {
        // console.log("50001:", error); // eslint-disable-line
        // dispatch(
        //   save_data({
        //     loading2: false,
        //     loading: false,
        //     visible2: false
        //   })
        // );
        // if (error.code === 50001) {
        //   if (url.indexOf("startDate") > -1) {
        //     setTimeout(() => {
        //       dispatch(
        //         save_data({
        //           visible: true,
        //           showName,
        //           url
        //         })
        //       );
        //     }, 500);
        //   }
        // }
      });
  };
}

export function setTime(url, time, type) {
  /* eslint-disable */
  // const url = 'https://rtms-sit.prudential.com.sg/pamb/async/doSpecialRule?receiveStartDate=0&startDate&receiveEndDate=0'

  if (url && url.indexOf("?") > -1) {
    if (url.indexOf("receiveStartDate") > -1) {
      const urls = url.split("startDate");
      console.log(urls);
      return dispatch => {
        dispatch(
          save_data({
            loading: true
          })
        );
        get(urls[0] + "startDate=" + time.format('YYYY-MM-DD') + urls[1])
          .then(res => {
            if (res.success) {
              dispatch(search(type));
            } else {
              notification.error({
                message: res.message,
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
            setTimeout(() => {
              dispatch(
                save_data({
                  loading: false,
                  visible: false
                })
              );
            }, 500);
          })
          .catch(() => {
            setTimeout(() => {
              dispatch(
                save_data({
                  loading: false,
                  visible: false
                })
              );
            }, 500);
          });
      }
    } else {
      const urls = url.split("?");
      const times = {}
      if (time.length > 1) {
        const params = urls[1].split("&");
        const attr1 = params[0].split("=");
        const attr2 = params[1].split("=")
        times[attr1[0]] = time[0].format('YYYY-MM-DD');
        times[attr2[0]] = time[1].format('YYYY-MM-DD');
      } else {
        const change = urls[1].split("=");
        times[change[0]] = time.format('YYYY-MM-DD');
      }
      return dispatch => {
        dispatch(
          save_data({
            loading: true
          })
        );
        get(urls[0], { ...times })
          .then(res => {
            if (res.success) {
              dispatch(search(type));
            } else {
              notification.error({
                message: res.message,
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
            setTimeout(() => {
              dispatch(
                save_data({
                  loading: false,
                  visible: false
                })
              );
            }, 500);
          })
          .catch(() => {
            setTimeout(() => {
              dispatch(
                save_data({
                  loading: false,
                  visible: false
                })
              );
            }, 500);
          });
      }
    }
  } else {
    return dispatch => {
      console.log("no ?:", url, time, type)
      get(url)
        .then(res => {
          if (res.success) {
            dispatch(search(type));
          } else {
            notification.error({
              message: res.message,
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
          }
          dispatch(
            save_data({
              loading: false,
              visible: false
            })
          );
        })
        .catch(() => {
          dispatch(
            save_data({
              loading: false,
              visible: false
            })
          );
        });
    }
  }
  /* eslint-disable */
}
