import React, {Component} from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Modal,
  Menu,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty,
  Dropdown,
  Popconfirm,
} from "antd";
import { connect } from "react-redux";
import { debounce, get, isObject } from 'lodash';
import {CreateForm} from './create'
import styles from './index.module.less'
import {
  getAgentIds,
  getAgentLeaders,
  getRegions,
  getRegistrations,
  getStatus,
  getUnitCodes,
  downLoadExcel,
  init_data,
  deleteRegistrations,
  updateRegistrations,
  fields,
  changeVals,
  getCourses,
  CreateRegistration,
  CheckRegistration,
  DeleteRegistration,
  ONCAHENG_TYPE,
  SAVE_DATA,
} from "../../../../../../redux/pambReducer/registrationReducers/listReducer";
import { commonColumns, dropdownItem } from './columns'
import { globalPromptTime10s, isPambPruventureAdmin } from '../../../../../../utils/global';

const ListForm = Form.create({
  name: 'registrationList_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      agentId: Form.createFormField({
        ...props.agentId,
        value: props.agentId.value
      }),
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      registrationDate: Form.createFormField({
        ...props.registrationDate,
        value: props.registrationDate.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { MonthPicker } = DatePicker
  const { statuses, agentIds, unitCodes, regions, agentLeaders, roleId } = props;
  return (
    <Form
      labelAlign="left"
      className={styles.ant_form}
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent ID">
            {getFieldDecorator("agentId")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentIds, 500)}
                optionFilterProp="children"
              >
                {!!agentIds.length &&
                  agentIds.map(id => {
                    return (
                      <Option key={id.key} value={id.value}>
                        <Tooltip placement="top" title={id.key}>
                          {id.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region")(
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!regions.length &&
                  regions.map(region => {
                    return (
                      <Option key={region.key} value={region.value}>
                        <Tooltip placement="top" title={region.key}>
                          {region.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                onSearch={debounce(props.searchAgentUnitCodes, 500)}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!unitCodes.length &&
                  unitCodes.map(unit => {
                    return (
                      <Option key={unit.key} value={unit.value}>
                        <Tooltip placement="top" title={unit.key}>
                          {unit.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent Leader">
            {getFieldDecorator("agentLeader")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentLeaders, 500)}
                optionFilterProp="children"
              >
                {!!agentLeaders.length &&
                  agentLeaders.map(leader => {
                    return (
                      <Option key={leader.key} value={leader.value}>
                        <Tooltip placement="top" title={leader.key}>
                          {leader.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Registration Date">
            <MonthPicker
              placeholder="Select Month"
              onChange={e => {
                props.onChange(ONCAHENG_TYPE, {
                  registrationDate: {
                    value: e?.format("YYYY-MM") ?? ""
                  }
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status")(
              <Select
                showArrow
                disabled={!isPambPruventureAdmin(roleId)}
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!statuses.length &&
                  statuses.map(status => {
                    return (
                      <Option key={status.key} value={status.value}>
                        <Tooltip placement="top" title={status.key}>
                          {status.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
})

@connect(state => ({
  listReducer: state.pambReducer.registrationReducers.listReducer,
  fields: state.pambReducer.registrationReducers.listReducer,
  loading: state.PublicReducer.loading,
  roleId: state.userReducer.roleid,
}), {
  getAgentIds,
  getAgentLeaders,
  getRegions,
  getRegistrations,
  getStatus,
  getUnitCodes,
  init_data,
  changeVals,
  downLoadExcel,
  updateRegistrations,
  deleteRegistrations,
  CheckRegistration,
  getCourses,
  CreateRegistration,
  DeleteRegistration,
})
class RegistrationList extends Component {
  state = {
    modalVisible: false,
    editingKey: '',
    editingDate: '',
    ...fields
  }

  searchCourses = debounce((value) => {
    return this.props.getCourses({ courseCode: value });
  }, 500)

  componentDidMount() {
    this.props.changeVals(ONCAHENG_TYPE, {
      agentId: {value: ''},
      agentLeader: {value: ''},
      unitCode: {value: ''},
      region: {value: ''},
      registrationDate: {value: ''},
      status: {value: ''},
    })
    Promise.allSettled([
      this.props.getAgentIds(),
      this.props.getAgentLeaders(),
      this.props.getRegions(),
      this.props.getStatus(),
      this.props.getUnitCodes(),
      this.props.CheckRegistration(),
    ]).then(() => {
      this.setState({region: this.props.fields.region, status: this.props.fields.status})
      this.props.getRegistrations({
        pageSize: this.props.listReducer.pageSize,
        currentPageNumber: this.props.listReducer.currentPageNumber,

        agentId: this.props.fields.agentId.value,
        agentLeader: this.props.fields.agentLeader.value,
        unitCode: this.props.fields.unitCode.value,
        region: this.props.fields.region.value,
        registrationDate: this.props.fields.registrationDate.value,
        status: this.props.fields.status.value,

        orderBy: this.props.fields.orderBy.value,
      });
    })
  }

  componentWillUnmount() {
    this.props.init_data()
  }


  getColumns = () => {
    const dataIndex = () => {
      if (this.props.fields.status.value === '1') {
        return 'duration'
      } else if (this.props.fields.status.value === '2') {
        return 'withdrawDate'
      } else if (this.props.fields.status.value === '3') {
        return 'graduationDate'
      } else if (this.props.fields.status.value === '4') {
        return 'terminationDate'
      } else {
        return ''
      }
    }
    const actionWidth = () => {
      if (this.props.fields.status.value === '1') {
        return 284
      } else if (this.props.fields.status.value === '2') {
        return 150
      } else {
        return 0
      }
    }
    const columns = [
      ...commonColumns,
      {
        title: 'Registration Date',
        sorter: true,
        dataIndex: "registrationDate",
        align: "center",
        width: 200,
        render: (text, data) => {
          const editing = this.state.editingKey === data.registrationId
          if (!editing) {
            return (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          } else {
            return (
              <DatePicker
                onChange={(value) => {
                  const m = moment(value).format('YYYY-MM-DD')
                  this.setState({ editingDate: m})
                }}
                style={{width: 130}}
                defaultValue={moment(data.registrationDate)}
                defaultPickerValue={moment(data.registrationDate)}
                />
            );
          }
        }
      },
      {
        title: 'Status',
        dataIndex: "status",
        align: "center",
        render: text => (
          <Tooltip placement="top" title={text}>
            {text}
          </Tooltip>
        )
      },
      {
        title: (tableData) => {
          if (this.props.fields.status.value === '1') {
            return 'Duration'
          } else if (this.props.fields.status.value === '2') {
            return 'Withdrawn Date'
          } else if (this.props.fields.status.value === '3') {
            return 'Graduated Date'
          } else if (this.props.fields.status.value === '4') {
            return 'Terminated Date'
          } else {
            return 'Date'
          }
        },
        dataIndex: dataIndex(),
        align: "center",
        render: text => {
          const t = isObject(text) ? '' : text
          return (
          <Tooltip placement="top" title={t}>
            {t}
          </Tooltip>
        )
        }
      },
    ]
    if (!isPambPruventureAdmin(this.props.roleId) ||
      this.props.fields.status.value === '3' ||
      this.props.fields.status.value === '4'
    ) {
      return columns
    } else {
      columns.push({
        title: 'Action',
        dataIndex: "Action",
        align: "center",
        fixed: "right",
        width: actionWidth(),
        render: (text, data, index) => {
          const editing = this.state.editingKey === data.registrationId
          if (this.props.fields.status.value === '1') {
            return (
              <div className={styles.action}>
                <Button type='link' onClick={() => this.clickWithDraw(data)}>Withdraw</Button>
                {
                  editing ?
                    <>
                      <Button type='link' onClick={() => this.clickSave(data)}>Save</Button>
                      <Button type='link' onClick={() => this.clickCancel(data)}>Cancel</Button>
                    </>
                  :
                    <>
                      <Button type='link' disabled={!data.isLocal} onClick={() => this.clickEdit(data)}>Edit</Button>
                    </>
                }
                <div style={{marginLeft: 'auto'}} >
                  <Popconfirm
                    autoAdjustOverflow
                    placement="top"
                    title="Are you sure to delete this account? All attendance records will be removed."
                    overlayClassName="registration-deleteBtn"
                    onConfirm={() => {
                      this.clickDelete(data)
                    }}
                    okText="Yes"
                    cancelText="No"
                    disabled={!data.isLocal}
                  >
                    <Button type='link' disabled={!data.isLocal} icon='close-circle'></Button>
                  </Popconfirm>
                </div>
              </div>
            )
          } else if (this.props.fields.status.value === '2') {
            return (
              <div className={styles.action}>
                <Button type='link' onClick={() => this.clickReactivate(data)}>Reactivate</Button>
              </div>
            )
          } else {
            return text
          }
        }
      })
    }
    return columns
  }

  clickEdit = (data) => {
    this.setState({ editingKey: data.registrationId, editingDate: data.registrationDate});
  }

  clickWithDraw = async (data) => {
    await this.props.updateRegistrations(data.registrationId, {
      status: '2',
    }, true)
    this.setState({ editingKey: ''});
    this.getData()
  }

  clickCancel = (data) => {
    this.setState({ editingKey: ''});
  }

  clickSave = async (data) => {
    if (!moment(this.state.editingDate).isValid()) {
      notification.error({
        message: 'invalid registration date',
        duration: globalPromptTime10s,
        className: "ant-style-notification-error"
      })
      return
    }
    await this.props.updateRegistrations(this.state.editingKey, {
      registrationDate: this.state.editingDate,
    }, false)
    this.getData()
    this.setState({ editingKey: ''});
  }

  clickDelete = async (data) => {
    await this.props.deleteRegistrations(data.registrationId);
    this.getData();
  }

  clickReactivate = async (data) => {
    await this.props.updateRegistrations(data.registrationId, {
      status: '1',
    }, true)
    this.getData()
  }

  handleTableChange = (pagination, filters, sorter) => {
    const order = get(sorter, "order", "");
    const orders = order === 'ascend' ? 1 : -1
    this.props.changeVals(ONCAHENG_TYPE, {orderBy: {value: orders}})
    this.props.getRegistrations({
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: pagination.current,

      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,

      orderBy: orders,
    });
  }

  searchAgentIds = (value) => {
    this.props.getAgentIds({search: value})
  }

  searchAgentLeaders = (value) => {
    this.props.getAgentLeaders({search: value})
  }

  searchUnitCodes = (value) => {
    this.props.getUnitCodes({search: value})
  }

  getData = (payload) => {
    const param = payload ?? {
      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,
    }
    this.props.getRegistrations({
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: this.props.listReducer.currentPageNumber,
      orderBy: this.props.listReducer.orderBy.value,
      ...param,
    });
  }

  handleMenuClick = (value) => {
    if (value.key === '1') {
      this.props.history.push('/home/scheme_management/pru_venture_registration/individual_add', {
        ...this.props.location.state,
      })
    } else if (value.key === '2') {
      this.props.history.push('/home/scheme_management/pru_venture_registration/bulk_add', {
        ...this.props.location.state,
      })
    }
  }

  downLoadExcel = () => {
    this.props.downLoadExcel('PruVenture Registration.xlsx', {
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: this.props.listReducer.currentPageNumber,

      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,

      orderBy: this.props.listReducer.orderBy.value,
    })
  }

  changeFields = (data, fields) => {
    this.setState(fields)
  }

  linkToCourse = () => {
    this.openModal()
  }

  unDoCourse = () => {
    this.openModal()
  }

  openModal = () => {
    if (this.props.listReducer.courses.length === 0) {
      this.props.getCourses()
    }
    this.setState({modalVisible: true})
  }

  modalConfirmopen = async () => {
    if (!this.props.listReducer.registrationConfig) {
      await this.props.CreateRegistration({
        courseCode: this.props.listReducer.courseCode.value,
        courseId: this.props.listReducer.courseId.value,
      })
    } else {
      await this.props.DeleteRegistration()
    }
    this.props.getRegistrations({
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: this.props.listReducer.currentPageNumber,

      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,

      orderBy: this.props.fields.orderBy.value,
    });
    this.setState({modalVisible: false})
  }

  closeModal = () => {
    this.setState({modalVisible: false})
  }


  confirm = (data) => {
    this.props.changeVals(ONCAHENG_TYPE, {
      agentId: this.state.agentId,
      agentLeader: this.state.agentLeader,
      unitCode: this.state.unitCode,
      region: this.state.region,
      registrationDate: this.state.registrationDate,
      status: this.state.status,
    })
    this.getData({
      currentPageNumber: 1,
      agentId: this.state.agentId.value,
      agentLeader: this.state.agentLeader.value,
      unitCode: this.state.unitCode.value,
      region: this.state.region.value,
      registrationDate: this.state.registrationDate.value,
      status: this.state.status.value,
    })
  }

  render() {
    const modalProps = {
      loading: this.props.loading,
      courses: this.props.listReducer.courses,
      onChange: this.props.changeVals,
      searchCourses: this.searchCourses,
      onCancel: this.closeModal,
      onConfirm: this.modalConfirmopen,
    }
    const formProps = {
      loading: this.props.loading,
      onChange: this.changeFields,
      statuses: this.props.listReducer.statuses,
      agentIds: this.props.listReducer.agentIds,
      unitCodes: this.props.listReducer.unitCodes,
      regions: this.props.listReducer.regions,
      agentLeaders: this.props.listReducer.agentLeaders,
      registrationDate: this.props.listReducer.registrationDate,
      roleId: this.props.roleId,
      searchAgentLeaders: this.searchAgentLeaders,
      searchAgentIds: this.searchAgentIds,
      searchAgentUnitCodes: this.searchUnitCodes,
    }
    const modalTitle = !this.props.listReducer.registrationConfig ? 'Link to a Pre-requisite Course' :
      'Are you sure to undo the linkage with Litmos course?'
    const modalMessage = !this.props.listReducer.registrationConfig ? 'The registration date will be the pre-requisite completion date from Litmos' :
      'All attendance records of the affected agents will be deleted if removing the linkage with the Litmos course'
    const menu = (
      <Menu onClick={this.handleMenuClick} className={styles.registrationAddMenu}>
        {
          dropdownItem.map(i => {
            return (
              <Menu.Item
                style={{
                  justifyContent: "center",
                  display: "flex",
                  color: "#DA3738"
                }}
                key={i.key}
                className={styles.menuItem}
              >
                {i.label}
              </Menu.Item>
            );
          })
        }
      </Menu>
    )
    return (
      <div className={styles.container}>
        {
          <Spin spinning={this.props.loading}>
            <div className={styles.actionWrap}>
              <ListForm {...this.state} {...formProps}></ListForm>
            </div>
            <div className={styles.actionBox}>
              <div className={styles.apply}>
                <Button
                  type="primary"
                  className={styles.ant_btn}
                  onClick={this.confirm}
                >
                  Search
                </Button>
              </div>
              <div className={styles.add_import}>
                {!this.props.listReducer.registrationConfig
                  ? isPambPruventureAdmin(this.props.roleId) && (
                      <Button
                        onClick={this.linkToCourse}
                        className={styles.ant_btn_link}
                      >
                        Link to Litmos Course
                      </Button>
                    )
                  : isPambPruventureAdmin(this.props.roleId) && (
                      <Button
                        onClick={this.unDoCourse}
                        className={styles.ant_btn_link}
                        icon="undo"
                      >
                        Undo Linkage
                      </Button>
                    )}
                {isPambPruventureAdmin(this.props.roleId) && (
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button className={styles.ant_btn_add} icon="down">
                      Add
                    </Button>
                  </Dropdown>
                )}
                <Button
                  onClick={this.downLoadExcel}
                  className={styles.ant_btn_download}
                  icon="download"
                >
                  Download to Excel
                </Button>
              </div>
            </div>
            <div className={styles.table}>
              <Table
                className={styles.ant_dev_table}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Registration List is Created Yet"
                    />
                  )
                }}
                scroll={{ x: 1500 }}
                // className={styles.ant_dev_table}
                columns={this.getColumns()}
                onChange={this.handleTableChange}
                pagination={{
                  total: this.props.listReducer.total,
                  pageSize: this.props.listReducer.pageSize,
                  current: this.props.listReducer.currentPageNumber
                }}
                dataSource={this.props.listReducer.registrations}
                size="small"
                rowKey={(record, index) => {
                  return String(index);
                }}
              />
            </div>
            <Modal
              title={modalTitle}
              visible={this.state.modalVisible}
              onCancel={this.closeModal}
              okButtonProps={{ disabled: this.props.loading }}
              footer={null}
            >
              <div className={styles.modalContainer}>
                <div style={{ marginBottom: 24 }}>{modalMessage}</div>
                <CreateForm {...modalProps} {...this.props.fields}></CreateForm>
              </div>
            </Modal>
          </Spin>
        }
      </div>
    );
  }
}

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16
  },
};

const flexRow = {
  gutter: [22, 0]
};


export default RegistrationList;

