import React, { Component } from "react";
import { Form, Button, Row } from "antd";
import styles from "../index.module.less";
import { formLayout, flexRow } from './components/utils';
import withConnector from '../connector';

@withConnector
class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: {}
    };
  }

  handleSaveField = (name, value) => {
    this.setState(prevState => ({
      search: {
        ...prevState.search,
        ...{
          [name]: value
        }
      }
    }));
  }

  searchHandler = () => {
    this.props.search(this.props.form)
  }

  renderHeaderButton = () => {
    return (
      this.props.headerBtnDef && this.props.headerBtnDef.map(btn => {
        let disabled = false;

        if (typeof btn.disabled === 'function') {
          disabled = btn.disabled();
        } else {
          disabled = btn.disabled;
        }

        return ((!btn.condition || (btn.condition !== undefined && btn.condition())) && (
          <Button
            key={`header-button-${btn.title}`}
            className={`${styles.btn}`}
            style={{ marginLeft: 15 }}
            variant="contained"
            type={btn.color}
            disabled={disabled}
            onClick={() => {
              if (disabled) {
                return;
              }

              btn.onClick();
            }}
          >
            {btn.title}
          </Button>)
        )
      })
    )
  }

  render() {
    const {
      form: { getFieldDecorator },
      searchFileds,
      download,
      needDownload
    } = this.props;

    const { fieldsData } = this.props.commonRenducers.usefulTemporaryData;
    const { keyword } = this.props;
    const { searchData } = (fieldsData && fieldsData[keyword]) || {};

    return (
      <div className="search-bar">
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            {
              searchFileds && searchFileds.map(item => {
                const Component = item.component;

                return (
                  <Component
                    {...item.props}
                    getFieldDecorator={getFieldDecorator}
                    handleSaveField={(name, value) => { this.handleSaveField(name, value) }}
                    keyword={item.key}
                    initialValue={(searchData && searchData[item.props.name]) || item.props.initialValue || ''}
                    searchData={(searchData || {})}
                  />
                )
              })
            }

          </Row>
        </Form>

        <div className={styles.serachBox}>
          <Button onClick={this.searchHandler} className={styles.ant_btn}>
            Search
          </Button>
        </div>
        {
          needDownload ? (
            <div className={styles.btnCol}>
              <Button
                className={styles.downloadBtn}
                icon="download"
                onClick={download}
              >
                Download
              </Button>
            </div>
          ) : null
        }
        <div className={styles.headerBtnContainer}>
          {
            this.renderHeaderButton()
          }
        </div>
      </div>
    )
  }
}

const SearchBarWithForm = Form.create({ name: "CustomSearchBar", withRef: true })(SearchBar);

export default SearchBarWithForm;
