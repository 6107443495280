import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Layout, Menu, Spin } from "antd";
import Router from "../../router";
import { HOME_LOGO, HOME_ARROW, HOME_AVATAR } from "../../images";
import PacsHome from "./pacs";
import PambHome from "./pamb";
import RegionalHome from "./regional";
import PhklHome from "./phkl";
import PlukHome from "./pluk";
import {
  getMenuList,
  changeMenuList,
  handleHighLight,
  handleReset,
  handleMyProfile
} from "../../redux/homeReducer";
import { myProfile, logout, setBuCountry, checkDatePickerDisabled } from "../../redux/userReducer";

import { handleSplitUrl } from "../../utils";
import { buCuontryList, getIsPSELogin, getIsImpersonate, backToImpersonator, isPacsAgent } from "../../utils/global";

import styles from "./index.module.less";
import PclaHome from "./pcla";

const { Header, Content, Sider } = Layout;

@connect(({ homeReducer, userReducer }) => ({ homeReducer, userReducer }), {
  getMenuList,
  logout,
  changeMenuList,
  myProfile,
  handleHighLight,
  handleReset,
  setBuCountry,
  handleMyProfile,
  checkDatePickerDisabled
})
class Home extends Component {
  state = {
    height: "",
    isPSELogin: false,
    isImpersonate: false
  };

  UNSAFE_componentWillMount() {
    this.setState({
      height: `${document.body.clientHeight - 64}px`
    });
  }

  componentDidMount() {
    const isPSELogin = getIsPSELogin();
    const isImpersonate = getIsImpersonate();

    window.addEventListener("resize", () => {
      this.setState(() => ({
        height: `${document.body.clientHeight - 64}px`,
      }));
    });

    this.setState({
      isPSELogin,
      isImpersonate
    });

    this.props.myProfile();
    this.props.checkDatePickerDisabled();
    const country = sessionStorage.getItem("buCountry");
    this.props.setBuCountry(country);

    const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
    this.props.getMenuList(tabKey, sideKey);
  }

  componentDidUpdate(prevProps) {
    const {
      homeReducer: { initMenuKey, initSideMenuKey },
      // eslint-disable-next-line no-unused-vars
      location: { pathname }
    } = prevProps;
    const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
    if (initMenuKey !== tabKey || initSideMenuKey !== sideKey) {
      this.props.handleHighLight(tabKey, sideKey);
    }
    // if (pathname !== this.props.location.pathname) {
    //   const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
    //   this.props.getMenuList(tabKey, sideKey);
    // }
  }

  componentWillUnmount() {
    this.props.handleReset();
    this.setState = () => {
      return null;
    };
  }

  gotoMyProfile = () => {
    const {
      homeReducer: { myProfileList }
    } = this.props;
    const defaultMyProfile = myProfileList.length ? myProfileList[0] : "";
    const path = defaultMyProfile ? defaultMyProfile.path : "";
    this.props.history.push(path);
    this.props.handleMyProfile();
  };

  handleLogout = () => {
    const urlPrefix = sessionStorage.getItem("prefix");
    const { history } = this.props;
    this.props.logout(urlPrefix, history);
  };

  clickMenuItem = (key, path) => {
    const {
      homeReducer: { homeMenuList, initMenuKey }
    } = this.props;
    if (key !== initMenuKey) {
      this.props.history.push((path && path.toLowerCase()) || "");
      this.props.changeMenuList(key, homeMenuList);
    }
  };

  backToOriginUser = () => {
    backToImpersonator();
    this.props.history.replace("/home");
    window.location.replace("/home/system_settings/user_management");
  }

  gotoChangePwd = () => {
    const {
      homeReducer: { myProfileList }
    } = this.props;
    const defaultMyProfile = myProfileList.length ? myProfileList[0] : "";
    const path = defaultMyProfile ? defaultMyProfile.path : "";
    this.props.history.push(path);
    setTimeout(() => {
      this.props.history.push("/home/my_profile_setting/change_password");
    });
  };

  loadBuHomePage = bu => {
    switch (bu) {
      case buCuontryList.PACS:
        return <PacsHome />;
      case buCuontryList.PAMB:
        return <PambHome />;
      case buCuontryList.PHKL:
        return <PhklHome />;
      case buCuontryList.REGIONAL:
        return <RegionalHome />;
      case buCuontryList.PLUK: // add by zhy
        return <PlukHome />;
      case buCuontryList.PCLA: // add by zhy
        return <PclaHome />;
      default:
        return null;
    }
  };

  render() {
    const {
      routers,
      homeReducer: {
        homeMenuList,
        sideMenuList = [],
        initMenuKey,
        initSideMenuKey,
        menuLoading,
        myProfileList
      },
      userReducer: user
    } = this.props;
    const defaultMenuKey = initMenuKey;
    const defaultSideMenuKey = initSideMenuKey;
    const country = sessionStorage.getItem("buCountry");
    const { isPSELogin, isImpersonate } = this.state;

    const { roleId } = user;
    const userisPacsAgent = isPacsAgent(roleId || '');

    return (
      <div className={styles.container}>
        <Spin
          spinning={menuLoading}
          style={{ paddingTop: menuLoading ? 400 : 0 }}
        >
          {(homeMenuList.length || myProfileList.length) ? (
            <Layout>
              <Header className={styles.header}>
                <div className={styles.logo}>
                  <Link to="/">
                    <img src={HOME_LOGO} alt="" />
                  </Link>
                </div>
                <div className={styles.menu_and_user}>
                  <Menu mode="horizontal" selectedKeys={[defaultMenuKey]}>
                    {homeMenuList.map(({ name, keyPath, path }) => (
                      <Menu.Item
                        key={keyPath ? keyPath.toLowerCase() : ""}
                        onClick={({ key }) => this.clickMenuItem(key, path)}
                      >
                        <span>{name}</span>
                      </Menu.Item>
                    ))}
                  </Menu>
                  <div className={styles.user_wrap}>
                  {
                      isImpersonate ?
                      <div
                        className={styles.changepwd}
                        onClick={this.backToOriginUser}
                      >
                        Back to Impersonator
                      </div> : null
                    }
                    <div className={styles.user_name}>
                      {user.username || user.userName || user.loginAccount}
                    </div>
                    {
                      (!isPSELogin && !isImpersonate) ?
                      <div
                        className={styles.changepwd}
                        onClick={this.gotoChangePwd}
                      >
                          {userisPacsAgent ? 'Change Password' : 'Change PruBehere Password'}
                      </div> : null
                    }

                    {
                      (!isPSELogin && !isImpersonate) ?
                      <div className={styles.logout} onClick={this.handleLogout}>
                        Logout
                      </div> : null
                    }
                    <img
                      onClick={this.gotoMyProfile}
                      src={HOME_AVATAR}
                      alt=""
                      className={styles.avatar}
                    />
                  </div>
                </div>
              </Header>
              <Layout className={styles.sider_container}>
                <Sider width={210} style={{ background: "#fff" }}>
                  <Menu
                    mode="inline"
                    selectedKeys={[defaultSideMenuKey]}
                    style={{
                      width: 208,
                      height: this.state.height,
                      overflow: "hidden auto",
                      paddingTop: 3
                    }}
                  >
                    {sideMenuList.map(item => {
                      const actived = item.keyPath === defaultSideMenuKey;
                      return (
                        <Menu.Item
                          key={item.keyPath || item.menuId}
                          // onClick={this.clickSideMenuKey}
                        >
                          <Link
                            to={
                              item.url ||
                              item.path ||
                              "/home/cpd_reports/import"
                            }
                            className={styles.side_menu_item}
                            style={
                              actived ? null : { justifyContent: "flex-start" }
                            }
                          >
                            <span className={styles.name}>{item.name}</span>
                            {actived ? <img src={HOME_ARROW} alt="" /> : null}
                          </Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>
                <Layout>
                  <Content
                    style={{
                      background: "#fff",
                      padding: "0 24px",
                      margin: 0,
                      height: this.state.height,
                      overflowX: "hidden",
                      overflowY: "auto"
                    }}
                  >
                    <Router routers={routers} />
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          ) : (
            <div />
          )}
        </Spin>
        {this.loadBuHomePage(country)}
      </div>
    );
  }
}

export default withRouter(Home);
