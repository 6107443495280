import { connect } from "react-redux";
import {
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  getUserRoleList
} from "../../../../../redux/phklReducer/systemSettingsReducers/userManagement/action";
import { getMenuList } from "../../../../../redux/homeReducer";

const mapStateToProps = state => {
  return {
    homeReducer: state.homeReducer,
    userManagementReducer: state.phklReducer.systemSettingsReducers.userManagementReducer
  };
};

const mapDispatchToProps = {
  getMenuList,
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  getUserRoleList
};

export default connect(mapStateToProps, mapDispatchToProps);
