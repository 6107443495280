import React, { Component } from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Modal,
  Menu,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty,
  Popover,
  Popconfirm,
  Input,
  Icon,
} from "antd";
import { connect } from "react-redux";
import { debounce, get } from 'lodash';
import dayjs from 'dayjs';
import { CreateForm } from './AttendanceDetailEditForm';
import styles from './AttendanceDetail.module.less'
import {
  getAgentIds,
  getAgentLeaders,
  getRegions,
  getAttendance,
  getStatus,
  getUnitCodes,
  downLoadExcel,
  init_data,
  updateRegistrations,
  fields,
  changeVals,
  ONCAHENG_TYPE,
  clearData,
  updateAttendance,
  clearUpdateStatus,
  SAVE_DATA,
} from "../../../../../redux/pambReducer/mySessionReducers/AttendanceDetailReducer";
import { getAttendance as getAttendanceList, validateAttendances, handleUpdateImportAttendances, handleValidateUserIds, handleImportAttendances } from "../../../../../redux/pambReducer/mySessionReducers/AddAttendanceReducer";
import { commonColumns } from './AttendanceDetailColumns'
import { globalPromptTime10s, isPambPruventureAdmin } from '../../../../../utils/global';
import AddAttendanceModalForm from './AddAttendanceModal';
import { download_template } from "../../../../../redux/PublicReducer";

const ListForm = Form.create({
  name: 'registrationList_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      sessionType: Form.createFormField({
        ...props.sessionType,
        value: props.sessionType
      }),
      sessionDateTime: Form.createFormField({
        ...props.sessionDateTime,
        value: props.sessionDateTime
      }),
      trainer: Form.createFormField({
        ...props.trainer,
        value: props.trainer
      }),
      agentId: Form.createFormField({
        ...props.agentId,
        value: props.agentId.value
      }),
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      registrationDate: Form.createFormField({
        ...props.registrationDate,
        value: props.registrationDate.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { MonthPicker } = DatePicker
  const { statuses, agentIds, unitCodes, regions, agentLeaders, roleId } = props;
  return (
    <Form
      labelAlign="left"
      className={styles.ant_form}
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Session Type">
            {getFieldDecorator("sessionType")(
              <Input disabled />
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Session Date Time">
            {getFieldDecorator("sessionDateTime")(
              <Input disabled />
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Trainer">
            {getFieldDecorator("trainer")(
              <Input disabled />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent ID">
            {getFieldDecorator("agentId")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentIds, 500)}
                optionFilterProp="children"
              >
                {!!agentIds.length &&
                  agentIds.map(id => {
                    return (
                      <Option key={id.key} value={id.value}>
                        <Tooltip placement="top" title={id.key}>
                          {id.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region")(
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!regions.length &&
                  regions.map(region => {
                    return (
                      <Option key={region.key} value={region.value}>
                        <Tooltip placement="top" title={region.key}>
                          {region.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                onSearch={debounce(props.searchAgentUnitCodes, 500)}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!unitCodes.length &&
                  unitCodes.map(unit => {
                    return (
                      <Option key={unit.key} value={unit.value}>
                        <Tooltip placement="top" title={unit.key}>
                          {unit.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>

        <Col {...flexCol}>
          <Form.Item label="Agent Leader">
            {getFieldDecorator("agentLeader")(
              <Select
                showSearch
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                onSearch={debounce(props.searchAgentLeaders, 500)}
                optionFilterProp="children"
              >
                {!!agentLeaders.length &&
                  agentLeaders.map(leader => {
                    return (
                      <Option key={leader.key} value={leader.value}>
                        <Tooltip placement="top" title={leader.key}>
                          {leader.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Registration Date">
            <MonthPicker
              placeholder="Select Month"
              onChange={(e) => {
                props.onChange(ONCAHENG_TYPE, {
                  registrationDate: {
                    value: e?.format('YYYY-MM') ?? '',
                  }
                })
              }}
            />
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Attendance Status">
            {getFieldDecorator("status")(
              <Select
                showArrow
                defaultActiveFirstOption={false}
                filterOption={false}
                optionFilterProp="children"
              >
                {!!statuses.length &&
                  statuses.map(status => {
                    return (
                      <Option key={status.key} value={status.value}>
                        <Tooltip placement="top" title={status.key}>
                          {status.key}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
})

@connect(state => ({
  AttendanceDetailReducer: state.pambReducer.mySessionReducers.AttendanceDetailReducer,
  fields: state.pambReducer.mySessionReducers.AttendanceDetailReducer,
  loading: state.PublicReducer.loading,
  roleId: state.userReducer.roleid,
  attendancesList: state.pambReducer.mySessionReducers.AddAttendanceReducer.attendancesList,
  AddAttendanceReducer: state.pambReducer.mySessionReducers.AddAttendanceReducer,
}), {
  getAgentIds,
  getAgentLeaders,
  getRegions,
  getAttendance,
  getStatus,
  getUnitCodes,
  init_data,
  changeVals,
  downLoadExcel,
  updateRegistrations,
  clearData,
  updateAttendance,
  clearUpdateStatus,
  getAttendanceList,
  handleUpdateImportAttendances,
  handleValidateUserIds,
  handleImportAttendances,
  validateAttendances,
  download_template
})
class AttendanceDetail extends Component {
  state = {
    modalVisible: false,
    attendanceVisible: false,
    fileName: '',
    fileList: [],
    targetKeys: [],
    editingKey: '',
    editingDate: '',
    addAttendanceValidateInfo: {
      isShowErrorBanner: false,
      importErrors: [],
      activeAgentIds: [],
      importId: '',
      isChecked: false,
      type: '',
      isShowSuccessBanner: false,
    },
    isShowActions: false,
    selectedRowKeys: [],
    editAttendanceArr: [],
    ...fields
  }

  initialValidationInfo = {
    isShowErrorBanner: false,
    importErrors: [],
    activeAgentIds: [],
    importId: '',
    isChecked: false,
    isShowSuccessBanner: false,
    type: ''
  }

  componentDidMount() {
    const {
      sessionType,
      sessionDateTime,
      trainer,
      sessionId,
      trainerId,
      sessionStartTime,
      sessionEndTime,
      createdUser,
      createdBy,
    } = this.props.location.state;
    const isShowActions = isPambPruventureAdmin(this.props.roleId) || sessionStorage.getItem("userId") === trainerId;
    Promise.allSettled([
      this.props.getAgentIds(),
      this.props.getAgentLeaders(),
      this.props.getRegions(),
      this.props.getStatus(),
      this.props.getUnitCodes(),
    ]).then(() => {
      this.setState({ region: this.props.fields.region, isShowActions, status: this.props.fields.status, sessionType, trainer, sessionDateTime, sessionId, sessionStartTime, sessionEndTime, createdUser, createdBy })
      this.props.getAttendance({
        pageSize: this.props.AttendanceDetailReducer.pageSize,
        currentPageNumber: this.props.AttendanceDetailReducer.currentPageNumber,
        agentId: this.props.fields.agentId.value,
        agentLeader: this.props.fields.agentLeader.value,
        unitCode: this.props.fields.unitCode.value,
        region: this.props.fields.region.value,
        registrationDate: this.props.fields.registrationDate.value,
        status: this.props.fields.status.value,
        orderBy: this.props.fields.orderBy.value,
      }, this.state.sessionId);
    })
  }

  componentWillUnmount() {
    this.props.clearData()
  }

  onChangeFile = (file) => {
    this.setState({ fileList: [file], fileName: file.name });
    return false;
  };

  onRemoveFile = () => {
    this.setState({ fileList: [], fileName: '' });
  };

  onChangeImportType = (e) => {
    // set validateInfo to initial state
    this.setState({ addAttendanceValidateInfo: this.initialValidationInfo, targetKeys: [], fileList: [], fileName: '' })
  }

  handleAddAttendanceCancel = (props) => {
    props.form.resetFields()
    this.setState({
      attendanceVisible: false,
      targetKeys: [],
      fileList: [],
      fileName: '',
      addAttendanceValidateInfo: this.initialValidationInfo
    });
  }

  downLoadTemplate = () => {
    this.props.download_template(
      "/pruventure/templates/Import Session Attendance Template.xlsx",
      'ImportSessionAttendanceTemplate.xlsx',
    );
  }

  handleAddAttendanceOk = (e, props) => {
    const { form } = props;
    const addType = form.getFieldValue('attendance')
    const { addAttendanceValidateInfo } = this.state
    const callback = () => {
      this.handleAddAttendanceCancel.bind(this, props)()
      // refresh table
      this.getData()
    }
    if (addAttendanceValidateInfo.isChecked && (!addAttendanceValidateInfo.isShowErrorBanner || !addAttendanceValidateInfo.isContainError)) {
      this.props.handleImportAttendances({ sessionId: this.state.attendanceSessionId, importId: this.state.addAttendanceValidateInfo.importId }, callback)
    } else {
      // validation
      this.checkAddAttendance({ type: addType }, callback)
    }
  }

  checkAddAttendance = async (payload, cb = () => { }) => {
    try {
      const { type } = payload
      const { targetKeys, attendanceSessionId, fileList } = this.state
      switch (type) {
        case 'transfer':
          await this.props.handleValidateUserIds({ sessionId: attendanceSessionId, userIds: targetKeys })
          break
        case 'import':
          if (fileList.length === 0) {
            return
          }
          const formData = new FormData()
          formData.append('file', fileList[0])
          await this.props.validateAttendances({ sessionId: attendanceSessionId, file: formData })
          break
        default:
          break
      }
      const validateInfo = this.props.AddAttendanceReducer.validateInfo
      const newValidateInfo = {
        isShowErrorBanner: false,
        importErrors: validateInfo.importErrors,
        activeAgentIds: validateInfo.activeAgentIds,
        importId: validateInfo.importId,
        isChecked: true,
        type
      }

      if (validateInfo && (validateInfo.isContainError || validateInfo.importErrors.length > 0)) {
        newValidateInfo.isShowErrorBanner = true
        newValidateInfo.isContainError = validateInfo.isContainError
        this.setState({ addAttendanceValidateInfo: newValidateInfo })
      } else {
        this.props.handleImportAttendances({ sessionId: this.state.attendanceSessionId, importId: newValidateInfo.importId }, cb)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // accept/remove all errors
  handleUpdateImport = async (actionType) => {
    const { attendanceSessionId, addAttendanceValidateInfo, targetKeys } = this.state
    const { importId, importErrors, type } = addAttendanceValidateInfo

    const errorInfo = importErrors.shift()
    const isShowErrorBanner = importErrors.length > 0
    const submitValidate = {
      ...this.state.addAttendanceValidateInfo,
      importErrors,
      isShowErrorBanner
    }
    let newTargetKeys = targetKeys
    if (actionType === 'REMOVE_ALL') {
      newTargetKeys = targetKeys.filter((item) => {
        return !errorInfo.agentIds.includes(item)
      })
    }

    const callback = (res) => {
      try {
        const { toUploadAgents } = res.data
        if (toUploadAgents.length > 0) {
          submitValidate.isShowSuccessBanner = true
          submitValidate.successDesc = `${toUploadAgents?.length} attendance will be uploaded`
        }
      } catch (e) {
        console.log(e)
      }
      this.setState({ addAttendanceValidateInfo: submitValidate, targetKeys: newTargetKeys })
    }

    const updateData = {
      importId,
      action: actionType,
      errorAgentIds: errorInfo.agentIds,
      sessionId: attendanceSessionId
    }

    await this.props.handleUpdateImportAttendances(updateData, callback)
  }

  // handle Transfer on change
  handleTransferChange = targetKeys => {
    const { addAttendanceValidateInfo } = this.state
    const newValidateInfo = addAttendanceValidateInfo
    newValidateInfo.isChecked = false
    this.setState({ targetKeys, newValidateInfo });
  };



  getColumns = () => {
    const columns = [
      ...commonColumns,
    ]
    if (this.state.isShowActions) {
      columns.push({
        dataIndex: "duplications",
        align: "center",
        render: text => {
          if (!text) return null
          return (
            <Popover
              overlayStyle={{ width: '362px' }}
              placement='topRight'
              content={
                <div>
                  {text.map((item, index) => {
                    return (
                      <div key={index}>
                        {item}
                      </div>
                    )
                  })}
                </div>
              }>
              <Icon type="exclamation-circle" style={{ color: 'red', fontSize: '21px' }} />
            </Popover>
          )
        }
      })
    }
    return columns
  }




  clickCancel = (data) => {
    this.setState({ editingKey: '' });
  }

  clickSave = async (data) => {
    if (!moment(this.state.editingDate).isValid()) {
      notification.error({
        message: 'invalid registration date',
        duration: globalPromptTime10s,
        className: "ant-style-notification-error"
      })
      return
    }
    await this.props.updateRegistrations(this.state.editingKey, {
      registrationDate: this.state.editingDate,
    })
    this.getData()
    this.setState({ editingKey: '' });
  }

  clickDelete = async (data) => {
    await this.props.deleteRegistrations(data.registrationId);
    this.getData();
  }

  clickReactivate = async (data) => {
    await this.props.updateRegistrations(data.registrationId, {
      status: '1',
    })
    this.getData()
  }

  handleTableChange = (pagination, filters, sorter) => {
    const order = get(sorter, "order", "");
    const orders = order === 'ascend' ? 1 : -1
    this.props.changeVals(ONCAHENG_TYPE, { orderBy: { value: orders } })
    this.props.getAttendance({
      pageSize: this.props.AttendanceDetailReducer.pageSize,
      currentPageNumber: pagination.current,
      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,
      orderBy: orders,
    }, this.state.sessionId);
  }

  searchAgentIds = (value) => {
    this.props.getAgentIds({ search: value })
  }

  searchAgentLeaders = (value) => {
    this.props.getAgentLeaders({ search: value })
  }

  searchUnitCodes = (value) => {
    this.props.getUnitCodes({ search: value })
  }

  getData = (payload) => {
    const param = payload ?? {
      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      status: this.props.fields.status.value
    }
    this.props.getAttendance({
      pageSize: this.props.AttendanceDetailReducer.pageSize,
      currentPageNumber: this.props.AttendanceDetailReducer.currentPageNumber,
      orderBy: this.props.AttendanceDetailReducer.orderBy.value,
      ...param,
    }, this.state.sessionId);
  }

  handleMenuClick = (value) => {
    if (value.key === '1') {
      this.props.history.push('/home/scheme_management/pru_venture_registration/individual_add', {
        ...this.props.location.state,
      })
    } else if (value.key === '2') {
      this.props.history.push('/home/scheme_management/pru_venture_registration/bulk_add', {
        ...this.props.location.state,
      })
    }
  }

  downLoadExcel = () => {
    const fileName = `Session Attendance - ${this.state.sessionType} ${dayjs(this.state.sessionStartTime).format('YYYYMMDD')}.xlsx`
    this.props.downLoadExcel(fileName, {
      pageSize: this.props.AttendanceDetailReducer.pageSize,
      currentPageNumber: this.props.AttendanceDetailReducer.currentPageNumber,

      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,
      orderBy: this.props.AttendanceDetailReducer.orderBy.value,
    }, this.state.sessionId)
  }

  changeFields = (data, fields) => {
    this.setState(fields)
  }

  linkToCourse = () => {
    this.openModal()
  }

  unDoCourse = () => {
    this.openModal()
  }

  editAttendance = () => {
    const { selectedRowKeys, editAttendanceArr, sessionStartTime } = this.state
    if (((dayjs.utc(sessionStartTime).local()).diff(dayjs(), 'hour') > 1)) {
      notification.info({
        message: `Your can't edit attendance now`,
        description: `Editting attendance is only allowed starting from ${dayjs.utc(sessionStartTime).local().subtract(1, 'hour').format('YYYY-MM-DD HH:mm')}`,
        duration: globalPromptTime10s,
        className: styles.ant_dev_session_error
      })
      return
    }
    if (selectedRowKeys.length === 0) {
      notification.error({
        message: 'Please select a record to start editting',
        duration: globalPromptTime10s,
        className: "ant-style-notification-error"
      })
      return
    } else {
      // check if the editAttendanceArr is same status
      const status = editAttendanceArr[0].status
      const isSameStatus = editAttendanceArr.every(item => item.status === status)
      if (!isSameStatus) {
        notification.error({
          message: 'Editing different attendance status is not allowed',
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        })
        return
      }
      this.setState({
        editStatus: status
      })
    }
    this.openModal()
  }

  addAttendance = async () => {
    const { sessionId } = this.state
    const callback = () => {
      this.setState({
        attendanceVisible: true,
        attendanceSessionId: sessionId,
      })
    }
    await this.props.getAttendanceList({ sessionId }, callback)
  }


  openModal = () => {
    this.setState({ modalVisible: true })
  }

  modalConfirmopen = async () => {
    const { updateStatus, clockRecord, voidRecord } = this.props.AttendanceDetailReducer
    const { editStatus } = this.state
    const updateStatusValue = updateStatus.value
    const submitData = { status: updateStatusValue, registrationIds: this.state.selectedRowKeys }

    if (updateStatusValue === '2') {
      if (editStatus === 'Not Attended') {
        submitData.clockRecord = clockRecord.value
      } else if (editStatus === 'Completed') {
        submitData.voidRecord = voidRecord.value
      }
    }

    await this.props.updateAttendance(this.state.sessionId, submitData)
    this.props.clearUpdateStatus()

    this.props.getAttendance({
      pageSize: this.props.AttendanceDetailReducer.pageSize,
      currentPageNumber: this.props.AttendanceDetailReducer.currentPageNumber,
      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      registrationDate: this.props.fields.registrationDate.value,
      status: this.props.fields.status.value,
      orderBy: this.props.fields.orderBy.value,
    }, this.state.sessionId);
    this.setState({ modalVisible: false, selectedRowKeys: [] })
  }

  closeModal = () => {
    this.setState({ modalVisible: false, selectedRowKeys: [] })
    this.props.clearUpdateStatus()
  }

  goBack = () => {
    this.props.history.push(
      "/home/scheme_management/pru_venture_my_session/create"
    );
  };


  selectedAttendance = (selectedRowKeys, selectedRows) => {
    this.setState(
      () => ({
        editAttendanceArr: [...selectedRows],
        selectedRowKeys
      })
    );
  }

  confirm = (data) => {
    this.props.changeVals(ONCAHENG_TYPE, {
      agentId: this.state.agentId,
      agentLeader: this.state.agentLeader,
      unitCode: this.state.unitCode,
      region: this.state.region,
      registrationDate: this.state.registrationDate,
      status: this.state.status,
      sessionType: this.state.sessionType.value,
    })
    this.getData({
      currentPageNumber: 1,
      agentId: this.state.agentId.value,
      agentLeader: this.state.agentLeader.value,
      unitCode: this.state.unitCode.value,
      region: this.state.region.value,
      registrationDate: this.state.registrationDate.value,
      status: this.state.status.value,
      sessionType: this.state.sessionType.value,
    })
  }

  render() {
    const modalProps = {
      originStatus: this.state.editStatus,
      statuses: this.props.AttendanceDetailReducer.statuses,
      onChange: this.props.changeVals,
      onCancel: this.closeModal,
      onConfirm: this.modalConfirmopen,
    }
    const formProps = {
      loading: this.props.loading,
      onChange: this.changeFields,
      statuses: this.props.AttendanceDetailReducer.statuses,
      agentIds: this.props.AttendanceDetailReducer.agentIds,
      unitCodes: this.props.AttendanceDetailReducer.unitCodes,
      regions: this.props.AttendanceDetailReducer.regions,
      agentLeaders: this.props.AttendanceDetailReducer.agentLeaders,
      roleId: this.props.roleId,
      searchAgentLeaders: this.searchAgentLeaders,
      searchAgentIds: this.searchAgentIds,
      searchAgentUnitCodes: this.searchUnitCodes,
    }
    const modalTitle = 'Edit Attendance'
    const modalMessage = `Selected Attendance Status: ${this.state.editStatus}`
    return (
      <div className={styles.container}>
        <Spin spinning={this.props.loading}>
          <div className={styles.header}>
            <Button className={styles.goback} onClick={this.goBack} type='link' icon="left">View Session Details</Button>
          </div>
          <div className={styles.actionWrap}>
            <ListForm {...this.state} {...formProps} ></ListForm>
          </div>
          <div className={styles.actionBox}>
            <div className={styles.apply}>
              <Button
                type="primary"
                className={styles.ant_btn}
                onClick={this.confirm}
              >
                Search
              </Button>
            </div>
            <div className={styles.add_import}>
              {
                this.state.isShowActions && (
                  <>
                    <Button
                      onClick={this.addAttendance}
                      className={styles.ant_btn_link}
                      icon='user-add'
                    >
                      Add
                    </Button>

                    <Button
                      className={styles.ant_btn_add}
                      onClick={this.editAttendance}
                    >
                      Edit
                    </Button>

                    <Button
                      onClick={this.downLoadExcel}
                      className={styles.ant_btn_download}
                      icon='download'
                    >
                      Download to Excel
                    </Button>
                  </>
                )
              }
            </div>
          </div>
          <div className={styles.table}>
            <Table
              className={styles.ant_dev_table}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />
                )
              }}
              scroll={{ x: 1500 }}
              rowClassName={(record, index) => {
                return (this.state.isShowActions && record.duplications?.length) ? styles.duplication : ''
              }}
              columns={this.getColumns()}
              onChange={this.handleTableChange}
              pagination={{
                total: this.props.AttendanceDetailReducer.total,
                pageSize: this.props.AttendanceDetailReducer.pageSize,
                current: this.props.AttendanceDetailReducer.currentPageNumber
              }}
              dataSource={this.props.AttendanceDetailReducer.attendances}
              size="small"
              rowKey={(record, index) => {
                return record.registrationId
              }}
              rowSelection={
                {
                  onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedAttendance(selectedRowKeys, selectedRows);
                  },
                  selectedRowKeys: this.state.selectedRowKeys
                }
              }
            />
          </div>
          <Modal
            title={modalTitle}
            visible={this.state.modalVisible}
            onCancel={this.closeModal}
            okButtonProps={{ disabled: this.props.loading }}
            footer={null}
          >
            <div className={styles.modalContainer}>
              <div style={{ marginTop: '20px', marginBottom: '48px' }}>{modalMessage}</div>
              <CreateForm {...modalProps} {...this.props.fields}></CreateForm>
            </div>
          </Modal>

          <AddAttendanceModalForm
            visible={this.state.attendanceVisible}
            fileName={this.state.fileName}
            handleCancel={this.handleAddAttendanceCancel}
            onChange={this.handleTransferChange}
            handleOk={this.handleAddAttendanceOk}
            targetKeys={this.state.targetKeys}
            attendancesList={this.props.attendancesList}
            handleAttendancesChange={this.handleAttendancesChange}
            validateInfo={this.state.addAttendanceValidateInfo}
            handleUpdateImport={this.handleUpdateImport}
            downLoadTemplate={this.downLoadTemplate}
            onChangeFile={this.onChangeFile}
            onRemoveFile={this.onRemoveFile}
            onChangeImportType={this.onChangeImportType}
          />
        </Spin>
      </div>
    );
  }
}

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16
  },
};

const flexRow = {
  gutter: [22, 0]
};


export default AttendanceDetail;

