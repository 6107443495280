import { message } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pcla/otherInfoReducer/SAVE";

const initState = {
  userInfo: {},
  updateAdminLoading: false,
  updateGuestLoading: false
};

// reducer
export default function otherInfoReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleSubmit(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateAdminLoading: true
      }
    });
    post("/login/saveMyProfile", payload, true)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              updateAdminLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateAdminLoading: false
          }
        });
      });
  };
}

export function getuserDetial(payload, buRole) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateAdminLoading: true
      }
    });
    post("/user/userDeatail", payload, { buRole })
      .then(res => {
        const { data } = res;
        dispatch({ type: SAVE, payload: { userInfo: data || {}, updateAdminLoading: false } });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateAdminLoading: false
          }
        });
      });
  };
}

export function updateAgent(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateAdminLoading: true
      }
    });
    post("/agent/updateAgentFields", payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              updateAdminLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateAdminLoading: false
          }
        });
      });
  };
}
