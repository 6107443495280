import React, { useEffect } from "react";
import { Alert, Space, Button } from "antd-v5";
import styles from './MySessionDetails.module.less'

export const ModalAlert = ({ message, description, errorDetail, duration = 0, type, handleUpdateImport = () => { }, onClose }) => {
  useEffect(() => {
    let timeoutId = null;
    if (duration) {
      timeoutId = setTimeout(() => {
        onClose();
      }, duration * 1000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [duration, onClose]);

  return (
    <Alert
      message={message}
      type={type}
      action={
        !duration &&
        <Space>
          <Space>
            <Button
              size="small"
              type={type === 'error' ? "danger" : 'default'}
              className={type === 'warning' ? styles.ant_btn_warning : ''}
              onClick={() => handleUpdateImport('REMOVE_ALL')}>Remove All
            </Button>
            {
              errorDetail?.colorCode === 'WARNING_YELLOW' &&
              <Button
                size="small"
                className={styles.ant_btn_warning}
                onClick={() => handleUpdateImport('ACCEPT')}>
                Aceept All
              </Button>
            }
          </Space>
        </Space>
      }
      showIcon
    />
  );
};
