import React, { Component } from "react";
import { Button, Upload, Spin, message } from "antd";
import styles from './index.module.less';
import { post, get } from '../../../../../../utils/request';
import { file as uploadfileIcon } from "../../../../../../images";
import { originalUrlPrefix } from '../../../../../../utils/config';
import Header from '../../../../../components/Header';
import ErrorList from './ErrorList';
import { buCuontryList, globalPromptTime } from '../../../../../../utils/global';
import { userListPath } from '../UserList/utils';

const buRole = buCuontryList.PHKL.toLowerCase();

class CreateADCandidates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      hrefs: `${originalUrlPrefix.phkl}/model/import_ad_candidate_template.xlsx`,
      checkLoading: false,
      importLoading: false,
      isShowTable: false,
      columns: this.getColumns(),
      dataSource: [],
      fileId: '',
      total: 0,
      correct: 0,
      incorrect: 0,
      uploadedFileName: ''
    };
  }

  getColumns = () => {
    return [
      {
        title: "S/N",
        dataIndex: "line",
        align: "center"
      },
      {
        title: "Name",
        dataIndex: "name",
        align: "center",
        ellipsis: true
      },
      {
        title: "Agent Code",
        dataIndex: "agentCode",
        align: "center",
        ellipsis: true
      },
      {
        title: "HKID",
        dataIndex: "identity",
        align: "center",
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        width: "400px",
        render: (text, record) => {
          const { description } = record;
          return description.map((item, index) => {
            return (
              <div key={`${index}_${record.agentCode}`} className={styles.error}>
                {item}
              </div>
            );
          });
        }
      }
    ];
  }

  uploadAndCheck = () => {
    const { fileList } = this.state;
    const FormDatas = new FormData();
    FormDatas.append("file", fileList[0]);
    this.handleUploadAndCheck(FormDatas);
  };

  handleUploadAndCheck = async (FormDatas) => {
    this.setState({
      checkLoading: true,
    });

    try {
      const res = await post("/candidate/checktCandidates", FormDatas, { channel: 'AGY' })
      const { data } = res;
      const { fileId, candidateErrorMsgVo, total, correct, incorrect, success } = data;
      const { fileList } = this.state;
      const uploadedFileName = fileList[0] && fileList[0].name;
      this.setState({
        fileId,
        dataSource: candidateErrorMsgVo,
        total,
        correct,
        incorrect,
        isShowTable: true,
        checkLoading: false,
        containError: !success,
        fileList: [],
        uploadedFileName
      });
    } catch (error) {
      this.setState({
        checkLoading: false
      });
    }
  }

  handleImportData = async () => {
    const data = {
      fileId: this.state.fileId,
      channel: 'AGY'
    }

    this.setState({
      importLoading: true
    });

    await get("/candidate/importCandidates", data, { buRole })

    this.setState({
      isShowTable: false,
      fileList: [],
      importLoading: false
    });

    message.info("Import successfully", globalPromptTime);
  }

  render() {
    const { fileList, hrefs, checkLoading, isShowTable, columns, dataSource, containError, total, correct, incorrect, importLoading, uploadedFileName } = this.state;

    const props = {
      beforeUpload: file => {
        this.setState(() => ({
          fileList: [file],
          isShowTable: false
        }));
        return false;
      },
      fileList,
      showUploadList: false,
      containError: false
    };

    return (
      <div className={styles.container}>
        <Spin spinning={importLoading}>
          <Header
            title="Import AD Candidates"
            goBackUrl={userListPath}
          />
          <div className={styles.contentBox}>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>
                {fileList[0] && fileList[0].name}
              </div>
              <Upload {...props}>
                <div className={styles.uploadIconBox}>
                  <img
                    className={styles.uploadIcon}
                    src={uploadfileIcon}
                    alt=""
                  />
                </div>
              </Upload>
              <div className={styles.downloadBox}>
                <Button
                  className={styles.downloadBtn}
                  download
                  href={hrefs}
                  icon="download"
                >
                  Download Template
                </Button>
              </div>
            </div>
            <div className={styles.uploadAndCheckBtnBox}>
              <Button
                onClick={() => this.uploadAndCheck(buRole)}
                disabled={!fileList.length}
                loading={checkLoading}
                className={
                  fileList.length ? styles.uploadBtnActive : styles.uploadBtn
                }
              >
                {checkLoading ? "Uploading" : "Upload & Check"}
              </Button>
            </div>
            {isShowTable ?
             (
               <>
                  <div className={styles.displayFileName}>{uploadedFileName}</div>
                  <ErrorList
                    columns={columns}
                    dataSource={dataSource}
                    scroll={dataSource.length > 6 ? { y: 400 } : {}}
                    loading={checkLoading}
                    containError={containError}
                    total={total}
                    correct={correct}
                    incorrect={incorrect}
                    handleImportData={this.handleImportData}
                  />
               </>
             ) : null}
          </div>
        </Spin>
      </div>
    );
  }
}

export default CreateADCandidates;
