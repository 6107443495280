/* eslint-disable */
import moment from 'moment';
import { SelectList, DebounceSelect, SearchInput, Datepickers } from '../../../../../components/CustomList/SearchBar/components';

export const getSearchFileds = (params) => {
  return [
    {
      key: 'agentCode',
      component: DebounceSelect,
      props: {
        label: 'FC Code',
        name: 'agentCode',
        placeholder: "All",
        fetchOptions: params.fetchOptions,
        onFieldChange: async (value = '', fcCodeList = []) => {
          const targetFc = fcCodeList.find(item => item.value === value);
          params?.updateFilters({
            userName: targetFc?.label || '',
          })
        },
      },
    },
    {
      key: 'userName',
      component: SearchInput,
      props: {
        label: 'FC Name',
        name: 'userName',
        disabled: true,
      }
    },
    {
      key: 'courseCode',
      component: SelectList,
      props: {
        label: 'Course Code',
        name: 'courseCode',
        placeholder: "All",
        options: () => {
          const items = [];
          const courseCodeList = params?.courseCodeList;
          if (courseCodeList && courseCodeList.length > 0) {
            courseCodeList.forEach(item => {
              items.push({
                value: item.courseCode,
                label: item.courseCodeText
              });
            });
          }

          return items || [];
        },
        canSearch: true,
      },
    },
    {
      key: 'courseId',
      component: SelectList,
      props: {
        label: 'Course Name',
        name: 'courseId',
        placeholder: "All",
        options: () => {
          const items = [];
          const courseNameList = params?.courseNameList;
          if (courseNameList && courseNameList.length > 0) {
            courseNameList.forEach(item => {
              items.push({
                value: item.courseId,
                label: item.courseName
              });
            });
          }

          return items || [];
        },
        canSearch: true,
      },
    },
    {
      key: 'effectYear',
      component: SearchInput,
      props: {
        label: 'Effect Year',
        name: 'effectYear',
        rules: [{
          pattern: /^[0-9]{4}$/,
          whitespace: true,
          message: "Please enter the correct number of years, for example:2020"
        }]
      }
    },
    {
      key: 'courseCompleteDate',
      component: Datepickers,
      props: {
        label: 'Complete Date',
        name: 'courseCompleteDate',
        startDate: {
          name: 'fromCompletionDate',
          // initialValue: moment().startOf('year'),
          config: {
            placeholder: "Start Date",
          }
        },
        endDate: {
          name: 'toCompletionDate',
          // initialValue: moment(),
          config: {
            placeholder: "End Date",
          }
        },
        labelCol: { span: 3 },
        wrapperCol: { span: 8 },
        disabledDate: "20220101"
      }
    }
  ]
};
