import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Input, Select } from "antd";
import { changeVals } from "../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { handleMyProfile } from "../../../../redux/homeReducer";
import { handleStatus, handleGender } from "../../../../utils";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateAgent_PAMB_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("Agent_PAMB", changedFields);
  },
  mapPropsToFields(props) {
    return {
      role: Form.createFormField({
        ...props.role,
        value: props.role.value
      }),
      bu: Form.createFormField({
        ...props.bu,
        value: props.bu.value
      }),
      nirc: Form.createFormField({
        ...props.nirc,
        value: props.nirc.value
      }),
      userName: Form.createFormField({
        ...props.userName,
        value: props.userName.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      contactNum: Form.createFormField({
        ...props.contactNum,
        value: props.contactNum.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      agentCode: Form.createFormField({
        ...props.agentCode,
        value: props.agentCode.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      contractedDate: Form.createFormField({
        ...props.contractedDate,
        value: props.contractedDate.value
      }),
      terminationDate: Form.createFormField({
        ...props.terminationDate,
        value: props.terminationDate.value
      }),
      gender: Form.createFormField({
        ...props.gender,
        value: props.gender.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      branchlocation: Form.createFormField({
        ...props.branchlocation,
        value: props.branchlocation.value
      }),
      edpIndicator: Form.createFormField({
        ...props.edpIndicator,
        value: props.edpIndicator.value
      }),
      PBTBAppointed: Form.createFormField({
        ...props.PBTBAppointed,
        value: props.PBTBAppointed.value
      }),
      eastspringCode: Form.createFormField({
        ...props.eastspringCode,
        value: props.eastspringCode.value
      }),
      bumiIndicator: Form.createFormField({
        ...props.bumiIndicator,
        value: props.bumiIndicator.value
      }),
      pruventureIndicator: Form.createFormField({
        ...props.pruventureIndicator,
        value: props.pruventureIndicator.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  return (
    <Form
      layout="horizontal"
      labelCol={{ span: 9 }}
      labelAlign="left"
      wrapperCol={{ span: 15 }}
      colon={false}
    >
      <Row gutter={[24, 0]}>
        <Col {...flexCol}>
          <Form.Item label="Role">
            {getFieldDecorator("role", {})(
              <Select disabled={props.disabled} placeholder="">
                {props.rolerList.map(item => (
                  <Option value={item.roleid}>{item.name}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="BU">
            {getFieldDecorator("bu", {})(
              <Select disabled={props.disabled} placeholder="">
                {props.buList.map(item => (
                  <Option value={item.id} key={item.id}>{item.value}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Name">
            {getFieldDecorator("userName", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="NRIC">
            {getFieldDecorator("nirc", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Contact Number">
            {getFieldDecorator("contactNum", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Agent Code">
            {getFieldDecorator("agentCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status", {
            })(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Contracted Date">
            {getFieldDecorator("contractedDate", {})(
              <Input disabled={props.disabled} placeholder="" />
            )}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Termination Date">
            {getFieldDecorator("terminationDate", {})(
              <Input disabled={props.disabled} placeholder="" />
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Gender">
            {getFieldDecorator("gender", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="EDP Indicator">
            {getFieldDecorator("edpIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="PBTB Appointed">
            {getFieldDecorator("PBTBAppointed", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Eastspring Code">
            {getFieldDecorator("eastspringCode", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Bumi Indicator">
            {getFieldDecorator("bumiIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Pruventure Indicator">
            {getFieldDecorator("pruventureIndicator", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Branch Location">
            {getFieldDecorator("branchlocation", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region", {})(<Input disabled={props.disabled} placeholder="" />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
});

@connect(
  state => ({
    fields: {
      // ...state.myProfileReducers.Agent_PAMB_Reducer,
      role: {
        value: state.userReducer.roldid
      },
      bu: {
        value: state.userReducer.bu ? state.userReducer.bu.toUpperCase() : ""
      },
      account: {
        value: state.userReducer.loginaccount
      },
      channel: {
        value: state.userReducer.channel
      },
      nirc: {
        value: state.userReducer.nirc
      },
      userName: {
        value: state.userReducer.username
      },
      email: {
        value: state.userReducer.email
      },
      contactNum: {
        value: state.userReducer.contactnumber
      },
      unitCode: {
        value: state.userReducer.unitcode
      },
      agentCode: {
        value: state.userReducer.agentcode
      },
      status: {
        value: state.userReducer.status ? handleStatus(state.userReducer.status) : ''
      },
      contractedDate: {
        value: state.userReducer.contracteddate
          ? new Date(state.userReducer.contracteddate).toLocaleDateString()
          : "" // contracteddate
      },
      terminationDate: {
        value: state.userReducer.terminationdate
          ? new Date(state.userReducer.terminationdate).toLocaleDateString()
          : "" // state.userReducer.terminationdate
      },
      gender: {
        value: state.userReducer.gender ? handleGender(state.userReducer.gender) : ''
      },
      region: {
        value: state.userReducer.region
      },
      branchlocation: {
        value: state.userReducer.branchlocation
      },
      edpIndicator: {
        value: state.userReducer.edpIndicator
      },
      PBTBAppointed: {
        value: state.userReducer.pbtbappointed
          ? new Date(state.userReducer.pbtbappointed).toLocaleDateString()
          : "" // state.userReducer.pbtbappointed
      },
      eastspringCode: {
        value: state.userReducer.eastspringCode
      },
      bumiIndicator: {
        value: state.userReducer.bumiIndicator
      },
      pruventureIndicator: {
        value: state.userReducer.pruventureIndicator
      }
    },
    user: { ...state.userReducer },
    buList: state.pambReducer.systemSettingsReducers.userManagementReducer.buList.length
      ? [...state.pambReducer.systemSettingsReducers.userManagementReducer.buList]
      : [],
    rolerList: state.pambReducer.systemSettingsReducers.userManagementReducer.allRolerList
      .length
      ? [...state.pambReducer.systemSettingsReducers.userManagementReducer.allRolerList]
      : []
  }),
  { changeVals, handleMyProfile }
)
class Agent_PAMB extends Component {
  state = {
    disabled: true
  };

  componentDidMount() {
    this.props.handleMyProfile();
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  render() {
    const props = {
      rolerList: this.props.rolerList,
      buList: this.props.buList,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      disabled: this.state.disabled
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>My Profile</div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

// const insideStyles = {
//   goCenter: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center'
//   }
// };

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default Agent_PAMB;
