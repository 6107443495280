import React, { Component } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Form, Row, Col, Input, DatePicker } from "antd";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import { handleMyProfile } from "../../../../redux/homeReducer";
import { handleStatus, handleGender } from "../../../../utils";
import styles from "./pacsAgent.module.less";

const { RangePicker } = DatePicker;

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const formLayout2 = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 }
};

const flexRow = {
  gutter: [22, 0]
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const CreateForm = Form.create({
  name: "CreateAgent_PACS_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("Agent_PACS", changedFields);
  },
  mapPropsToFields(props) {
    return {
      role: Form.createFormField({
        ...props.role,
        value: props.role.value
      }),
      bu: Form.createFormField({
        ...props.bu,
        value: props.bu.value
      }),
      channel: Form.createFormField({
        ...props.channel,
        value: props.channel.value
      }),
      nirc: Form.createFormField({
        ...props.nirc,
        value: props.nirc.value
      }),
      userName: Form.createFormField({
        ...props.userName,
        value: props.userName.value
      }),
      email: Form.createFormField({
        ...props.email,
        value: props.email.value
      }),
      contactNumber: Form.createFormField({
        ...props.contactNumber,
        value: props.contactNumber.value
      }),
      gender: Form.createFormField({
        ...props.gender,
        value: props.gender.value
      }),
      uinitCode: Form.createFormField({
        ...props.uinitCode,
        value: props.uinitCode.value
      }),
      account: Form.createFormField({
        ...props.account,
        value: props.account.value
      }),
      BDM: Form.createFormField({
        ...props.BDM,
        value: props.BDM.value
      }),
      GAM: Form.createFormField({
        ...props.GAM,
        value: props.GAM.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      nationality: Form.createFormField({
        ...props.nationality,
        value: props.nationality.value
      }),
      agentCode: Form.createFormField({
        ...props.agentCode,
        value: props.agentCode.value
      }),
      group: Form.createFormField({
        ...props.group,
        value: props.group.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      contractedDate: Form.createFormField({
        ...props.contractedDate,
        value: props.contractedDate.value
      }),
      terminationDate: Form.createFormField({
        ...props.terminationDate,
        value: props.terminationDate.value
      }),
      rank: Form.createFormField({
        ...props.rank,
        value: props.rank.value
      }),
      leaderName: Form.createFormField({
        ...props.leaderName,
        value: props.leaderName.value
      }),
      leaderCode: Form.createFormField({
        ...props.leaderCode,
        value: props.leaderCode.value
      }),
      nne: Form.createFormField({
        ...props.nne,
        value: props.nne.value
      }),
      dob: Form.createFormField({
        ...props.dob,
        value: props.dob.value
      }),
      nric: Form.createFormField({
        ...props.nric,
        value: props.nric.value
      }),
      officeAddress: Form.createFormField({
        ...props.officeAddress,
        value: props.officeAddress.value
      }),
      officeBuildin: Form.createFormField({
        ...props.officeBuildin,
        value: props.officeBuildin.value
      }),
      bankPartner: Form.createFormField({
        ...props.bankPartner,
        value: props.bankPartner.value
      }),
      unitNo: Form.createFormField({
        ...props.unitNo,
        value: props.unitNo.value
      }),
      RNFNo: Form.createFormField({
        ...props.RNFNo,
        value: props.RNFNo.value
      }),
      CISLicenseDate: Form.createFormField({
        ...props.CISLicenseDate,
        value: props.CISLicenseDate.value
      }),
      HIdate: Form.createFormField({
        ...props.HIdate,
        value: props.HIdate.value
      }),
      priorContractDate: Form.createFormField({
        ...props.priorContractDate,
        value: props.priorContractDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;

  return (
    <Form layout="horizontal" labelAlign="left" {...formLayout} colon={false}>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Agent Name">
            {getFieldDecorator("userName", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Login Account">
            {getFieldDecorator("account", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Nationality">
            {getFieldDecorator("nationality", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="DOB">
            {getFieldDecorator("dob", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Gender">
            {getFieldDecorator("gender", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="BDM">
            {getFieldDecorator("BDM", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="GAM">
            {getFieldDecorator("GAM", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Group">
            {getFieldDecorator("group", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="FC Code">
            {getFieldDecorator("agentCode", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Status">
            {getFieldDecorator("status", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Contracted Date">
            {getFieldDecorator("contractedDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Business Partner Code" {...formLayout2}>
            {getFieldDecorator("bankPartner", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Termination Date">
            {getFieldDecorator("terminationDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Segment">
            {getFieldDecorator("rank", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Leader FC Code">
            {getFieldDecorator("leaderCode", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="NRIC/FIN">
            {getFieldDecorator("nric", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Contact Number">
            {getFieldDecorator("contactNumber", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col span={24}>
          <Form.Item
            label="Office Address"
            labelCol={{ span: 3 }}
            labelAlign="left"
            wrapperCol={{ span: 21 }}
          >
            {getFieldDecorator("officeAddress", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Office Building"
            labelCol={{ span: 3 }}
            labelAlign="left"
            wrapperCol={{ span: 21 }}
          >
            {getFieldDecorator("officeBuildin", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Unit No. ">
            {getFieldDecorator("unitNo", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="RNF No. ">
            {getFieldDecorator("RNFNo", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="CIS License Date">
            {getFieldDecorator("CISLicenseDate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="NNE">
            {getFieldDecorator("nne", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="HI date">
            {getFieldDecorator("HIdate", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>

        <Col {...flexCol}>
          <Form.Item label="Channel">
            {getFieldDecorator("channel", {
            })(<Input disabled />)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col span={12}>
          <Form.Item
            label="Prior Contract Date"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 11 }}
          >
            {getFieldDecorator("priorContractDate", {
            })(<RangePicker
              format="YYYY-MM-DD"
              placeholder={["Start Time", "End Time"]}
              disabled
            />)}
          </Form.Item>
        </Col>
      </Row>
    </Form>

  );
});

@connect(
  state => ({
    fields: {
      // ...state.myProfileReducers.Agent_PAMB_Reducer,
      role: {
        value: state.userReducer.roldId
      },
      bu: {
        value: state.userReducer.bu ? state.userReducer.bu.toUpperCase() : ""
      },
      channel: {
        value: state.userReducer.channel
      },
      nirc: {
        value: state.userReducer.nirc
      },
      userName: {
        value: state.userReducer.userName
      },
      nationality: {
        value: state.userReducer.nationality
      },
      gender: {
        value: state.userReducer.gender ? handleGender(state.userReducer.gender) : ''
      },
      email: {
        value: state.userReducer.email
      },
      group: {
        value: state.userReducer.pgroup
      },
      contactNumber: {
        value: state.userReducer.contactNumber
      },
      account: {
        value: state.userReducer.loginAccount
      },
      BDM: {
        value: state.userReducer.bdm
      },
      GAM: {
        value: state.userReducer.gam
      },
      unitCode: {
        value: state.userReducer.unitCode
      },
      agentCode: {
        value: state.userReducer.agentCode
      },
      leaderCode: {
        value: state.userReducer.leaderCode
      },
      status: {
        value: state.userReducer.status ? handleStatus(state.userReducer.status) : ''
      },
      contractedDate: {
        value: state.userReducer.contractedDate
          ? moment(state.userReducer.contractedDate).format('YYYY-MM-DD')
          : ""
      },
      terminationDate: {
        value: state.userReducer.terminationDate
          ? moment(state.userReducer.terminationDate).format('YYYY-MM-DD') : ""
      },
      rank: {
        value: state.userReducer.rank
      },
      leaderName: {
        value: state.userReducer.leaderName
      },
      bankPartner: {
        value: state.userReducer.bankPartner
      },
      nne: {
        value: state.userReducer.nne
      },
      uinitCode: {
        value: state.userReducer.uinitCode
      },
      officeAddress: {
        value: state.userReducer.officeAddress
      },
      officeBuildin: {
        value: state.userReducer.officeBuilding
      },
      unitNo: {
        value: state.userReducer.unitNo
      },
      RNFNo: {
        value: state.userReducer.rnfno
      },
      CISLicenseDate: {
        value: state.userReducer.cislicenseDate ? moment(state.userReducer.cislicenseDate).format('YYYY-MM-DD') : ''
      },
      nric: {
        value: state.userReducer.nric
      },
      HIdate: {
        value: state.userReducer.hidate ? moment(state.userReducer.hidate).format('YYYY-MM-DD') : ''
      },
      dob: {
        value: state.userReducer.dob ? moment(state.userReducer.dob).format('YYYY-MM-DD') : ''
      },
      priorContractDate: {
        value: [
          state.userReducer.priorContractStartDate ? moment(state.userReducer.priorContractStartDate, "YYYY-MM-DD") : '',
          state.userReducer.priorContractEndDate ? moment(state.userReducer.priorContractEndDate, "YYYY-MM-DD") : '']
      }
    },
    user: { ...state.userReducer },
    buList: state.pacsReducer.systemSettingsReducers.userManagementReducer.buList.length
      ? [...state.pacsReducer.systemSettingsReducers.userManagementReducer.buList]
      : [],
    rolerList: state.pacsReducer.systemSettingsReducers.userManagementReducer.allRolerList
      .length
      ? [...state.pacsReducer.systemSettingsReducers.userManagementReducer.allRolerList]
      : []
  }),
  { changeVals, handleMyProfile }
)
class Agent_PACS extends Component {
  state = {
    disabled: true
  };

  componentDidMount() {
    this.props.handleMyProfile();
  }

  handleFormChange = changedFields => {
    this.setState(({ fields }) => ({
      fields: { ...fields, ...changedFields }
    }));
  };

  render() {
    const props = {
      rolerList: this.props.rolerList,
      buList: this.props.buList,
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      disabled: this.state.disabled
    };
    return (
      <div className={styles.container}>
        <div className={styles.heading}>My Profile</div>
        <div className={styles.col_dev_left}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

export default Agent_PACS;
