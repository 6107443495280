/* eslint-disable */
import React, { useState, useEffect, useReducer } from "react";
import { Form, Row, Col, Tooltip, Input, Spin } from "antd";
import { withRouter } from "react-router-dom";
import { get } from '../../../../../utils/request';
import Header from '../../../../components/Header';
import SessionDetail from './SessionDetail';
import ActionBar from './ActionBar';
import CourseRegistration from './CourseRegistration';
import CourseAttendance from './CourseAttendance';
import withConnector from './../connector';


function CourseDetail(props) {
  const [loading, setLoading] = useState(false);
  const [courseDetail, setCourseDetail] = useState({});
  const { getFieldDecorator } = props.form;
  const { isRistrationTab } = props.CourseDetailReducer;

  const fetchData = async () => {
    const { sessionId, courseId } = props.location.state;
    setLoading(true);
    const res = await get("/attendanceTms/getCourseDetail", { sessionId, courseId });
    setCourseDetail(res.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header title="Base Information" />
      <Spin spinning={loading}>
        <Form
          hideRequiredMark
          layout="horizontal"
          labelCol={{ span: 10 }}
          labelAlign="left"
          wrapperCol={{ span: 14 }}
          colon={false}
        >
          <SessionDetail
            courseDetail={courseDetail}
            getFieldDecorator={getFieldDecorator}
          />

          <ActionBar
            getFieldDecorator={getFieldDecorator}
            courseDetail={courseDetail}
          />
          {
            isRistrationTab ? <CourseRegistration courseDetail={courseDetail}/> : <CourseAttendance courseDetail={courseDetail}/>
          }

        </Form>
      </Spin>
    </div>

  );
}

const CourseDetailWithForm = Form.create()(CourseDetail);

export default withConnector(withRouter(CourseDetailWithForm));
