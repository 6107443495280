import React from "react";
import { connect } from "react-redux";
import Router from "../../../../router";
import {
  getTrainerNameList,
  getCourseList,
  getCourseStatus,
  getVenueRegionList,
  getVenueNameList,
  getTrainerStatus
} from "../../../../redux/pambReducer/CPDReportReducers/TrainingReportReducer";

@connect(
  state => state,
  {
    getTrainerNameList,
    getCourseList,
    getCourseStatus,
    getVenueRegionList,
    getVenueNameList,
    getTrainerStatus
  }
)
class CPDReports extends React.Component {
  componentDidMount() {
    this.props.getTrainerNameList();
    this.props.getCourseList();
    this.props.getCourseStatus();
    this.props.getVenueRegionList();
    this.props.getVenueNameList();
    this.props.getTrainerStatus();
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default CPDReports;
