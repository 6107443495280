import React from "react";
import { connect } from "react-redux";
import {
  getBuList,
  getRolerList,
  getAllRolerList,
  getAllRoleList,
  getUserStatusList,
  getChannelList
} from "../../../redux/pclaReducer/systemSettingsReducers/userManagementReducer";
import {
  getCurrentTime
} from "../../../redux/pclaReducer/attendanceReducers/SessionDetailReducer";
import { getDateList } from "../../../redux/pclaReducer/CPDSettingsReducers/requirementSettingReducer";
import { getYearList } from "../../../redux/pclaReducer/CPDSettingsReducers/commonReducer";

@connect(
  state => state,
  {
    getBuList,
    getRolerList,
    getAllRolerList,
    getAllRoleList,
    getUserStatusList,
    getChannelList,
    getCurrentTime,
    getDateList,
    getYearList
  }
)
class PclaHome extends React.Component {
  componentDidMount() {
    this.props.getBuList();
    this.props.getRolerList();
    this.props.getAllRolerList();
    this.props.getAllRoleList();
    this.props.getUserStatusList();
    this.props.getChannelList();
    this.props.getCurrentTime()
    this.props.getDateList()
    this.props.getYearList()
  }

  render() {
    return null;
  }
}

export default PclaHome;
