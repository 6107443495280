// columns
import { Menu, Dropdown, Icon, Button } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export const columns = [
  {
    title: "No.",
    dataIndex: "No",
    align: "center",
    width: 50,
    render: (text, record, index) => {
      return index + 1;
    }
  },
  {
    title: "Session Type",
    dataIndex: "sessionType",
    align: "center",
    width: 150,
    render: (text, record) => {
      return record.sessionType
    },
  },
  {
    title: "Session Start Time",
    dataIndex: "sessionStartTime",
    sorter: true,
    align: "center",
    render: text => {
      return dayjs.utc(text).local().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  {
    title: "Session End Time",
    dataIndex: "sessionEndTime",
    sorter: true,
    align: "center",
    render: text => {
      return dayjs.utc(text).local().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  {
    title: "Trainer",
    dataIndex: "trainer",
    align: "center",
    render: (text, record) => {
      return record.trainerName
    }
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    align: "center",
    render: (text, record) => {
      return record.createdBy
    }
  },
  {
    title: "#Completed",
    dataIndex: "completed",
    align: "center",
  },
]
