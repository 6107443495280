import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Table,
  Row,
  Col,
  Divider,
  Popconfirm
} from "antd";
import { connect } from "react-redux";
import styles from "./SurveySearch.module.less";
import {
  queryData,
  handleSearchPageDelete,
  reSetGroupForSurvey
} from "../../../../../redux/plukReducer/systemSettingsReducers/SurveyReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ plukReducer: { systemSettingsReducers: { SurveyReducer } } }) => ({
    SurveyReducer
  }),
  {
    queryData,
    handleSearchPageDelete,
    reSetGroupForSurvey
  }
)
class SurveySearch extends React.Component {
  state = {
    isShowTable: false,
    currentNum: 1,
    columns: [
      {
        title: "Survey Name",
        dataIndex: "surveyName",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Survey Code",
        align: "center",
        dataIndex: "surveyCode"
      },
      {
        title: "Actions",
        align: "center",
        width: "240px",
        render: (text, record) => {
          const { pambSurveyTemplateId } = record;
          const to = {
            pathname: "/home/system_settings/survey_management/survey_detail",
            state: {
              pambSurveyTemplateId
            }
          };
          return (
            <div>
              <Link to={to}>
                <span
                  className={styles.spanCss}
                  onClick={() => this.props.reSetGroupForSurvey()}
                >
                  Edit
                </span>
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Please double confirm?"
                onConfirm={() => this.handleDelete({ pambSurveyTemplateId })}
                okText="Yes"
                cancelText="No"
              >
                <span className={styles.spanCss}>Delete</span>
              </Popconfirm>
            </div>
          );
        }
      }
    ]
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const { surveyName, surveyCode } = values;
        const callback = () => {
          this.setState({
            isShowTable: true,
            currentNum: 1,
            surveyName,
            surveyCode
          });
        };
        this.props.queryData(
          {
            ...values,
            currentPageNumber: 1
          },
          callback
        );
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    this.setState({ currentNum: current });
    const { surveyName, surveyCode } = this.state;
    this.props.queryData({
      surveyName,
      surveyCode,
      pageSize,
      currentPageNumber: current
    });
  };

  handleDelete = id => {
    const { surveyName, surveyCode, currentNum } = this.state;

    const {
      SurveyReducer: {
        surveyData: { data: dataList, totalPage }
      }
    } = this.props;

    let current = currentNum;
    if (totalPage > 1 && dataList.length === 1) {
      current = currentNum - 1;
    }

    const callback = () => {
      this.props.queryData({
        surveyName,
        surveyCode,
        currentPageNumber: current
      });

      this.setState({ currentNum: current });
    };

    this.props.handleSearchPageDelete(id, callback);
  };

  gotoCreatepage = () => {
    this.props.history.push(
      "/home/system_settings/survey_management/survey_create"
    );

    this.props.reSetGroupForSurvey();
  };

  render() {
    const {
      form: { getFieldDecorator },
      SurveyReducer: {
        surveyData: { data: dataList, total },
        queryLoading
      }
    } = this.props;

    const { columns, isShowTable, currentNum } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.topBox}>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Survey Name">
                  {getFieldDecorator("surveyName", {})(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Survey Code">
                  {getFieldDecorator("surveyCode", {})(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
            <Button className={styles.ant_btn} onClick={this.gotoCreatepage}>
              Add Survey
            </Button>
          </div>
        </div>
        {isShowTable ? (
          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              total,
              pageSize: 10,
              current: currentNum
            }}
            size="small"
            loading={queryLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="pambSurveyTemplateId"
          />
        ) : null}
      </div>
    );
  }
}

const SurveySearchForm = Form.create()(SurveySearch);

export default SurveySearchForm;
