import { message } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime, globalPromptTime1s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pacs/userManagementReducer/SAVE";

const initState = {
  userData: {
    total: 0,
    pageSize: 10,
    pageNumber: 1,
    userList: [],
  },
  userStatusList: [],
  channelList: [],
  rolerList: [],
  buList: [],
  rankList: [],
  allRolerList: [],
  allRoleList: [],
  tableLoading: false,
  noRefrash: false,
  defaultPassword: '',
  usefulTemporaryData: { fieldsData: {}, searchedData: {} }
};

// reducer
export default function userManagementReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}


export function getUserList(payload, buRole) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/user/userList", payload, { buRole })
      .then(res => {
        const {
          data: { data, total, currentPageNumber, pageSize }
        } = res;
        const final = data.map((item) => {
          item.loginAccount = item.loginAccount ? item.loginAccount : item.loginaccount
          return item;
        })
        const userData = {
          total,
          pageSize,
          pageNumber: currentPageNumber,
          userList: final
        };
        dispatch({
          type: SAVE,
          payload: {
            userData,
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            userData: {
              total: 0,
              pageSize: 10,
              pageNumber: 1,
              userList: [],
            },
            tableLoading: false
          }
        });
      });
  };
}


export function downloadUserList(payload, buRole, roleName) {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post(
      "/user/downloadUserList",
      payload,
      { buRole },
      "blob"
    )
      .then(blob => {
        const nowFile = roleName.replace(/\s+/g, "");
        const fileName = `${nowFile}UserList.xls`; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch({
            type: SAVE,
            payload: {
              tableLoading: false
            }
          });
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch({
            type: SAVE,
            payload: {
              tableLoading: false
            }
          });
        }, 500);
      });
  };
}

export function getRolerList() {
  return dispatch => {
    get("/user/roleList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            rolerList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getRankList() {
  return dispatch => {
    get("/user/rankList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            rankList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getUserStatusList() {
  return dispatch => {
    get("/user/getStatusList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            userStatusList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getChannelList() {
  return dispatch => {
    get("/user/getChannelList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            channelList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getAllRoleList() {
  return dispatch => {
    get("/user/allRoleList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            allRoleList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getAllRolerList() {
  return dispatch => {
    get("/systemSetting/getRoleList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            allRolerList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getBuList() {
  return dispatch => {
    get("/systemSetting/getBuList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            buList: data
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getResetDefaultPassword(payload) {
  return dispatch => {
    get("/user/getResetDefaultPassword", null, { buRole: payload })
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            defaultPassword: (data && data[0]) ? data[0].value : ''
          }
        });
      })
      .catch(() => {

      });
  };
}

export function resendEmail(payload, buRole) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });

    get("/login/generateLink", payload, { buRole })
      .then(() => {
        message.info("Operation Success", globalPromptTime);
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function impersonate(payload, loginAccount, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });

    post('/impersonate', loginAccount, {})
    .then(res => {
      const { data } = res;
      console.log(data);
      callback(data);
      dispatch({
        type: SAVE,
        payload: {
          tableLoading: false
        }
      });
    })
    .catch(() => {
      dispatch({
        type: SAVE,
        payload: {
          tableLoading: false
        }
      });
    });
  };
}

export function handleActivate(payload, buRole, type, callback) {
  const url = `/user/${type}`;
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });

    post(url, payload, { buRole })
      .then(() => {
        message.info("Operation Success！", globalPromptTime1s, callback);
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function resetPassword(payload, buRole, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });

    post('/user/resetPasswordByAdmin', payload, { buRole })
      .then(() => {
        message.info("Operation Success！", globalPromptTime1s, callback);
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function clickExtension(payload, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });

    post('/user/extendUser', payload)
      .then(() => {
        message.info("Operation Success！", globalPromptTime1s, callback);
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function saveFields(field, val) {
  return (dispatch, getState) => {
    const {
      pacsReducer: { systemSettingsReducers: {
        userManagementReducer: { usefulTemporaryData }
      } }
    } = getState();
    const { fieldsData } = usefulTemporaryData;
    fieldsData[field] = val;
    dispatch({
      type: SAVE,
      payload: {
        usefulTemporaryData
      }
    });
  };
}

export function saveTemporaryData(payload) {
  return (dispatch, getState) => {
    const {
      pacsReducer: { systemSettingsReducers: {
        userManagementReducer: { usefulTemporaryData }
      } }
    } = getState();
    dispatch({
      type: SAVE,
      payload: {
        usefulTemporaryData: { ...usefulTemporaryData, ...payload }
      }
    });
  };
}

export function handleRefrash(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        noRefrash: payload
      }
    });
  };
}
