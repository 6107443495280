import { combineReducers } from "redux";
import ResetPasswordReducer from "./ResetPasswordReducer";
import userManagementReducer from "./userManagementReducer";
import CreateLBUAdminReducer from "./CreateLBUAdminReducer";
import otherInfoReducer from "./otherInfoReducer";
import CreateRegionAdminReducer from "./CreateRegionAdminReducer";

export default combineReducers({
  ResetPasswordReducer,
  userManagementReducer,
  CreateLBUAdminReducer,
  otherInfoReducer,
  CreateRegionAdminReducer,
});
