
import { combineReducers } from "redux";
import AddSessionReducer from './AddSessionReducer'
import AddAttendanceReducer from './AddAttendanceReducer'
import AttendanceDetailReducer from './AttendanceDetailReducer'

export default combineReducers({
  AddSessionReducer,
  AddAttendanceReducer,
  AttendanceDetailReducer
});
