import { post, get, deleteReq } from "../../../../../utils/request";

const path = `/pruventure/requirements`
export const getRequirementSettingList = () => {
  return get(path)
}

export const deleteRequirementSetting = (id) => {
  return deleteReq(`${path}/${id}`)
}

export const saveRequirementSetting = (data) => {
  return post(path, data)
}
