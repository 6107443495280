import moment from 'moment';
import { SelectList, SearchInput, DateRangePickers } from '../../../../components/CustomList/SearchBar/components';

export const getSearchFileds = (venueRegionList, venueNameList, TrainerNameList) => {
  return [
    {
      key: 'trainingDate',
      component: DateRangePickers,
      props: {
        label: 'Training Date',
        name: 'trainingDate',
        startDate: {
          name: 'startDate',
          initialValue: moment().startOf('year'),
          placeholder: "Start Date",
        },
        endDate: {
          name: 'endDate',
          initialValue: moment(),
          placeholder: "End Date",
        },
        labelCol: { span: 3 },
        wrapperCol: { span: 8 },
        rules: [
          {
            validator: async (rule, value) => {
              const [start, end] = value
              if (start && end) {
                const range = end.diff(start, 'years', true)
                if (range > 1) {
                  throw new Error('No longer than one year');
                  return false;
                }
              }

              return true
            },
          }
        ],
      }
    },
    {
      key: 'venueName',
      component: SelectList,
      props: {
        label: 'Venue Name',
        name: 'venueName',
        options: () => {
          const items = [
            {
              value: '',
              label: 'All'
            }
          ];
          venueNameList.forEach(item => {
            items.push({
              value: item.venueId,
              label: item.venueName
            });
          });

          return items;
        }
      }
    },
    {
      key: 'trainerName',
      component: SelectList,
      props: {
        label: 'Trainer Name',
        name: 'instructorId',
        options: () => {
          const items = [
            {
              value: '',
              label: 'All'
            }
          ];
          TrainerNameList.forEach(item => {
            items.push({
              value: item.instructorId,
              label: item.instructorName
            });
          });

          return items;
        }
      }
    },
    {
      key: 'courseName',
      component: SearchInput,
      props: {
        label: 'Course Name',
        name: 'courseName'
      }
    },
    {
      key: 'moduleName',
      component: SearchInput,
      props: {
        label: 'Module Name',
        name: 'moduleName'
      }
    },
    {
      key: 'sessionName',
      component: SearchInput,
      props: {
        label: 'Session Name',
        name: 'sessionName'
      }
    },

  ]
};
