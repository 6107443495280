import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_SELECTAGENTLEADER = "PACS/INIT_DATA_SELECTAGENTLEADER";
const SEARCHDATA_SELECTAGENTLEADER = "PACS/SEARCHDATA_SELECTAGENTLEADER";

const initState = {
  agentCode: {
    value: ""
  },
  agentId: {
    value: ""
  },
  EDPIndicator: {
    value: ""
  },
  Pruventrue: {
    value: ""
  },
  List: [],
  allList: [],
  trainerList: [],
  EDPList: [],
  PruventrueList: []
};

// reducer
export default function SelectAgentLeaderReducer(state = initState, action) {
  switch (action.type) {
    case "SelectAgentLeader":
      return { ...state, ...action.payload };
    case SEARCHDATA_SELECTAGENTLEADER:
      return { ...state, ...action.payload };
    case INIT_DATA_SELECTAGENTLEADER:
      return { ...initState };
    default:
      return state;
  }
}
export function search_data(payload) {
  return {
    type: SEARCHDATA_SELECTAGENTLEADER,
    payload: {
      List: [...payload]
    }
  };
}

export function init_data() {
  return {
    type: INIT_DATA_SELECTAGENTLEADER
  };
}

export function save_data(payload) {
  return {
    type: "SelectAgentLeader",
    payload
  };
}

export function agentLeaderList(
  pacsDailyCoachingRuleId,
  // agentId,
  agentCode,
  // edpIndicator1,
  // pruventrueIndicator1,
  type
) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/pacsAgentLeaderTrainer", {
      pacsDailyCoachingRuleId: "",
      // agentId,
      agentCode,
      // edpIndicator: edpIndicator1,
      // pruventrueIndicator: pruventrueIndicator1
    })
      .then(res => {
        if (res.success) {
          const data = [...res.data];
          if (type === "edit") {
            dispatch(
              agentLeaderAlredayList(
                pacsDailyCoachingRuleId,
                // agentId,
                agentCode,
                // edpIndicator,
                // pruventrueIndicator,
                data
              )
            );
          } else {
            const finalData = [];
            data.forEach(item => {
              finalData.push({
                key: item.userId,
                title: `${item.userName}`,
                description: "",
                chosen: false,
                ...item
              });
            });
            dispatch(
              save_data({
                allList: finalData,
                trainerList: []
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function agentLeaderAlredayList(
  pacsDailyCoachingRuleId,
  // agentId,
  agentCode,
  // edpIndicator1,
  // pruventrueIndicator1,
  allData
) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/pacsAgentLeaderTrainerAlredayList", {
      pacsDailyCoachingRuleId,
      // agentId,
      agentCode,
      // edpIndicator: edpIndicator1,
      // pruventrueIndicator: pruventrueIndicator1
    })
      .then(res => {
        if (res.success) {
          const finalData = [];
          const keys = [];
          allData.forEach(item => {
            const obj = {
              key: item.userId,
              title: `${item.userName}`,
              description: "",
              chosen: false,
              ...item
            };
            for (let i = 0; i < res.data.length; i += 1) {
              if (res.data[i].userId === item.userId) {
                obj.chosen = true;
                break;
              }
            }
            if (obj.chosen) {
              keys.push(item.userId);
            }
            finalData.push(obj);
          });

          dispatch(
            save_data({
              allList: finalData,
              trainerList: keys
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function pruventrueIndicator() {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/pruventrueIndicator")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              PruventrueList: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function edpIndicator() {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/edpIndicator")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              EDPList: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
