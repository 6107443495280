/* eslint-disable */
import React, { Component } from "react";
import { Link } from 'react-router-dom';

export const getColumns = (params) => {
  return [
    {
      title: "FC Code",
      align: "center",
      dataIndex: "agentCode"
    },
    {
      title: "FC Name",
      align: "center",
      dataIndex: "userName"
    },
    {
      title: "Completed Date",
      align: "center",
      dataIndex: "dateCompleted"
    },
    {
      title: "Effect Year",
      align: "center",
      dataIndex: "effectiveYear"
    },
    {
      title: "Course Code",
      align: "center",
      dataIndex: "courseCode"
    },
    {
      title: "Course Name",
      align: "center",
      dataIndex: "courseName"
    },
    {
      title: "CPD Hours Assigned to Course",
      align: "center",
      dataIndex: "courseCpd",
      render: (data) => {
        const list = [
          `Ethics (${data['Ethics'] || 0})`,
          `R&R (${data['R&R'] || 0})`,
          `ERR (${data['ERR'] || 0})`,
          `CIS (${data['CIS'] || 0})`,
          `A&H (${data['A&H'] || 0})`,
          `Others (${data['Others'] || 0})`,
        ];

        const inner = list.map(item => {
          return <p style={{marginBottom: 5}}>{item}</p>
        })

        return (
          <div>
            {inner}
          </div>
        )
      }
    },
    {
      title: "Effective CPD Hours",
      align: "center",
      dataIndex: "effectiveCpd",
      render: (data) => {
        const list = [
          `Ethics (${data['Ethics'] || 0})`,
          `R&R (${data['R&R'] || 0})`,
          `ERR (${data['ERR'] || 0})`,
          `CIS (${data['CIS'] || 0})`,
          `A&H (${data['A&H'] || 0})`,
          `Others (${data['Others'] || 0})`,
        ];

        const inner = list.map(item => {
          return <p style={{ marginBottom: 5 }}>{item}</p>
        })

        return (
          <div>
            {inner}
          </div>
        )
      }
    },
    {
      title: "Last Update Time",
      dataIndex: "updateTime",
      align: "center",
    },
    {
      title: "Updated by",
      dataIndex: "updateUser",
      align: "center",
    },
    {
      title: "Reason of Update",
      dataIndex: "updateReason",
      align: "center",
    },
  ]
}
