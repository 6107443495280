import React from "react";
import moment from "moment";
import debounce from "lodash/debounce";
import {
  Form,
  Button,
  Table,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
  Input
} from "antd";
import { connect } from "react-redux";
import styles from "./TrainingHistoryReport.module.less";
import {
  getLeaderNameList,
  downloadData,
  reSetSessionList,
  handleQueryData,
  getFcCodeList,
  getUnitCodeList,
  getAgentStatusList,
} from "../../../../redux/plukReducer/CPDReportReducers/TrainingHistoryReportReducer";

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

const { Option } = Select;
const { RangePicker } = DatePicker;
@connect(
  ({ userReducer, plukReducer: { CPDReportReducers: { TrainingHistoryReportReducer } } }) => ({
    TrainingHistoryReportReducer, userReducer
  }),
  {
    handleQueryData,
    getLeaderNameList,
    downloadData,
    reSetSessionList,
    getFcCodeList,
    getUnitCodeList,
    getAgentStatusList,
  }
)
class TrainingHistoryReport extends React.Component {
  state = {
    fetching: false,
    queryData: { currentPageNumber: 1 },
    isShowTable: false,
    columns: [
      {
        title: "Agent Name",
        dataIndex: "agentName",
        align: "center",
        ellipsis: true
      },
      {
        title: "FC Code",
        align: "center",
        dataIndex: "fcCode",
        ellipsis: true
      },
      {
        title: "Unit Code",
        dataIndex: "unitCode",
        align: "center",
        ellipsis: true
      },
      {
        title: "Contracted Date",
        dataIndex: "contractedDateV",
        align: "center",
        ellipsis: true
      },
      {
        title: "Leader FC Code",
        align: "center",
        dataIndex: "leaderFcCode",
        ellipsis: true
      },
      {
        title: "Channel",
        dataIndex: "channel",
        align: "center",
        ellipsis: true
      },
      {
        title: "Actions",
        dataIndex: "actions",
        align: "center",
        render: (text, record) => {
          const { userId, agentType } = record
          const type = agentType ? agentType.toLowerCase() : ''
          if (type === 'candidate') {
            return <span />
          }
          return <span className={styles.downloadBtn} onClick={() => this.handleDownload(userId)}>Download</span>
        }
      }
    ]
  };

  fetchUser = debounce((val) => {
    this.setState({ fetching: true });
    this.props.getFcCodeList({ ownerName: val }, () => this.handleFcCodeChange(val))
  }, 800)

  componentDidMount() {
    this.props.getLeaderNameList();
    // this.props.getAgentStatusList();
    this.props.getUnitCodeList();
    this.props.getFcCodeList({ ownerName: '' });
    const { queryData } = this.state
    const { form } = this.props
    const fields = form.getFieldsValue()
    this.props.handleQueryData({ ...fields, ...queryData });
    this.setState({ queryData: fields })
  }

  componentWillUnmount() {
    this.props.reSetSessionList();
  }

  handleFcCodeChange = (value, option) => {
    const { queryData } = this.state;
    const { props } = option || {};
    const { username } = props || {};
    this.setState({
      fetching: false,
      queryData: { ...queryData, fcName: username }
    });
    if (!value) {
      this.props.getFcCodeList({ ownerName: '' });
    }
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          trainingDate,
          appointedRange
        } = values;
        const [start, end] = trainingDate || [];
        const trainingDateFrom = start && moment(start).format("YYYY-MM-DD");
        const trainingDateTo = end && moment(end).format("YYYY-MM-DD");

        const [from, to] = appointedRange || [];
        const appointedDateFrom = from && moment(from).format("YYYY-MM-DD");
        const appointedDateTo = to && moment(to).format("YYYY-MM-DD");
        const queryData = {
          ...values,
          trainingDateFrom,
          trainingDateTo,
          appointedDateFrom,
          appointedDateTo,
          currentPageNumber: 1
        }

        this.props.handleQueryData({
          ...queryData
        });

        this.setState({
          queryData
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current } = pagination;

    const {
      queryData
    } = this.state;
    this.props.handleQueryData({
      ...queryData,
      currentPageNumber: current
    });

    this.setState({ queryData: { ...queryData, currentPageNumber: current } });
  };

  handleDownload = (userId) => {
    const {
      queryData
    } = this.state;
    this.props.downloadData({
      ...queryData,
      userId
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      userReducer,
      TrainingHistoryReportReducer: {
        trainingHistoryData: { data: dataList, total },
        leaderNameList,
        agentStatusList,
        courseStatusList,
        sessionStatusList,
        agentTypeList,
        channelList,
        unitCodeList,
        queryLoading,
        downloadLoading,
        fcCodeList,
      }
    } = this.props;
    const { userName, roleId, agentCode, unitCode } = userReducer
    const { columns, queryData: { currentPageNumber, fcName }, fetching } = this.state;
    const isAgent = roleId === '8'
    const isAgentLeader = roleId === '12'
    return (
      <div className={styles.container}>
        <Spin spinning={downloadLoading}>
          <div className={styles.heading}>Training History Report</div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              {
                (isAgent || isAgentLeader) ? null :
                  (<Col {...flexCol}>
                    <Form.Item label="Leader Name">
                      {getFieldDecorator("leaderName")(
                        <Select optionFilterProp="children" showSearch allowClear>
                          {leaderNameList.map(elt => (
                            <Option value={elt.leaderName} key={elt.leaderName}>
                              {elt.leaderName}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                   </Col>)
              }

              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: isAgent ? unitCode : undefined
                  })(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      disabled={isAgent}
                    >
                      {unitCodeList.filter(elt => elt.unitCode).map(elt => (
                        <Option value={elt.unitCode} key={elt.unitCode}>
                          {elt.unitCode}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Agent Status">
                  {getFieldDecorator("agentStatus", {
                    initialValue: agentStatusList[0].code
                  })(
                    <Select disabled={isAgent}>
                      {agentStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Training Date">
                  {getFieldDecorator("trainingDate", {
                    rules: [
                      {
                        validator: async (rule, value = []) => {
                          const [start, end] = value
                          if (start && end) {
                            const isSameYear = moment(start).isSame(end, 'year')
                            if (!isSameYear) {
                              throw new Error('Maximal range is 1 year');
                            }
                          }
                        },
                      }
                    ],
                  })(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="FC Code">
                  {getFieldDecorator("fcCode", {
                    initialValue: isAgent ? agentCode : undefined
                  })(
                    isAgent ?
                      (<Input disabled />)
                      :
                      (<Select
                        notFoundContent={fetching ? <Spin size="small" /> : 'Not Found'}
                        showSearch
                        allowClear
                        filterOption={false}
                        onChange={this.handleFcCodeChange}
                        onSearch={this.fetchUser}
                      >
                        {fcCodeList.map(elt => (
                          <Option value={elt.agentCode} key={elt.agentCode} username={elt.userName}>
                            {elt.agentCode}
                          </Option>
                        ))}
                       </Select>)
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="FC Name">
                  {getFieldDecorator("fcName", {
                    initialValue: fcName || userName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Appointed Range">
                  {getFieldDecorator("appointedRange", {})(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Agent Type">
                  {getFieldDecorator("agentType", {
                    initialValue: (isAgent || isAgentLeader) ? agentTypeList[2].code : undefined
                  })(
                    <Select allowClear disabled={isAgent || isAgentLeader}>
                      {agentTypeList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Course Status">
                  {getFieldDecorator("courseStatus", {
                    initialValue: courseStatusList[0].code
                  })(
                    <Select>
                      {courseStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Session Status">
                  {getFieldDecorator("sessionStatus", {
                    initialValue: sessionStatusList[0].code
                  })(
                    <Select>
                      {sessionStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              {
                (isAgentLeader || isAgent) ? null :

                  (<Col {...flexCol}>
                    <Form.Item label="Channel">
                      {getFieldDecorator("channel", {
                      })(
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                        >
                          {channelList.map(elt => (
                            <Option value={elt.code} key={elt.code}>
                              {elt.value}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                   </Col>)
              }
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
          </div>

          <Table
            columns={columns}
            dataSource={dataList || []}
            pagination={{
              total,
              pageSize: 10,
              current: currentPageNumber
            }}
            size="small"
            loading={queryLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="userId"
          />

        </Spin>
      </div>
    );
  }
}

const TrainingHistoryReportForm = Form.create()(TrainingHistoryReport);

export default TrainingHistoryReportForm;
