import React, {Component} from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
} from "antd";
import { connect } from "react-redux";
import { debounce, get } from 'lodash';
import styles from './index.module.less'
import {
  getAgentIds,
  getAgentLeaders,
  getRegions,
  getStatus,
  getUnitCodes,
  init_data,
} from "../../../../../../redux/pambReducer/registrationReducers/listReducer";
import {
  singleAddRegistration,
  getAgents,
  Confirm,
} from "../../../../../../redux/pambReducer/registrationReducers/individualReducer";
import { changeVals } from "../../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s, isPambPruventureTrainer } from '../../../../../../utils/global';

const ONCAHENG_TYPE = "onChange"

const IndividualAddForm = Form.create({
  name: 'registrationList_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      agentId: Form.createFormField({
        ...props.agentId,
        value: props.agentId.value
      }),
      agentName: Form.createFormField({
        ...props.agentName,
        value: props.agentName.value
      }),
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: props.unitCode.value
      }),
      registrationDate: Form.createFormField({
        ...props.registrationDate,
        value: props.registrationDate.value
      }),
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator, getFieldsError } = props.form;
  const { Option } = Select;
  const { agents } = props;
  return (
    <Form
      labelAlign="left"
      hideRequiredMark
      className={styles.ant_form}
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item
            label={
              <div className={styles.label}>
                <span>Agent ID</span>
                <span className={styles.required}>*</span>
              </div>
            }
          >
            {getFieldDecorator("agentId", {
              rules: [
                {
                  required: true,
                  message: "Please input all the mandatory fields"
                }
              ]
            })(
              <Select
                // style={{display: 'flex'}}
                showSearch
                showArrow
                onSearch={debounce(props.searchAgents, 500)}
                placeholder="Select an agent ID"
                disabled={props.disable}
                filterOption={false}
                onChange={value => {
                  const target = agents.find(a => a.agentId === value);
                  if (!target) {
                    return;
                  }
                  props.onChange(ONCAHENG_TYPE, {
                    agentName: { value: target.agentName },
                    agentCode: { value: target.agentCode },
                    region: { value: target.region },
                    agentLeader: { value: target.agentLeader },
                    unitCode: { value: target.unitCode }
                  });
                }}
              >
                {!!agents.length &&
                  agents.map(id => {
                    return (
                      <Option key={id.agentId} value={id.agentId}>
                        <Tooltip placement="top" title={id.agentId}>
                          {id.agentId}
                        </Tooltip>
                      </Option>
                    );
                  })}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agent Code">
            {getFieldDecorator("agentCode")(<Input disabled></Input>)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agent Name">
            {getFieldDecorator("agentName")(<Input disabled></Input>)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item label="Region">
            {getFieldDecorator("region")(<Input disabled></Input>)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Unit Code">
            {getFieldDecorator("unitCode")(<Input disabled></Input>)}
          </Form.Item>
        </Col>
        <Col {...flexCol}>
          <Form.Item label="Agent Leader">
            {getFieldDecorator("agentLeader")(<Input disabled></Input>)}
          </Form.Item>
        </Col>
      </Row>
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item
            label={
              <div className={styles.label}>
                <span>Registration Date</span>
                <span className={styles.required}>*</span>
              </div>
            }
          >
            {getFieldDecorator("registrationDate", {
              rules: [
                {
                  required: true,
                  message: "Please input all the mandatory fields"
                }
              ]
            })(
              <DatePicker
                disabled={props.disable}
                defaultPickerValue={moment()}
                placeholder="Select date"
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <div className={styles.actionBox}>
        <Button
          type="primary"
          className={styles.confirmBtn}
          disabled={props.disable}
          onClick={() => {
            props.form.validateFields((error, values) => {
              if (error) {
                return;
              }
              props.onConfirm();
            });
          }}
        >
          Confirm
        </Button>
      </div>
    </Form>
  );
})

@connect(
  state => ({
    individualReducer: state.pambReducer.registrationReducers.individualReducer,
    fields: state.pambReducer.registrationReducers.individualReducer,
    loading: state.PublicReducer.loading,
  }),
  {
    getAgentIds,
    getAgentLeaders,
    getRegions,
    getStatus,
    getUnitCodes,
    Confirm,
    init_data,
    changeVals,
    singleAddRegistration,
    getAgents,
  }
)
class RegistrationIndividualAdd extends Component {
  state = {
    duplicateAgentId: undefined,
  }

  componentDidMount() {
    this.props.getAgents()
  }

  componentWillUnmount() {
    this.props.init_data()
  }

  searchAgents = value => {
    this.props.getAgents({ search: value });
  };

  goBack = () => {
    this.props.history.push(
      "/home/scheme_management/pru_venture_registration/list"
    );
  };

  individualAdd = () => {
    this.props.singleAddRegistration({
      agentId: this.props.fields.agentId.value,
      registrationDate: moment(this.props.fields.registrationDate.value).format('YYYY-MM-DD').toString(),
    }, this.handleAddFailed, this.handleAddSuccess)
  }

  handleAddFailed = (res) => {
    this.setState({
      duplicateAgentId: this.props.fields.agentId.value,
    })
    notification.open({
      type: "warning",
      key: 'add failed',
      message:
        <div className={styles.noticeWarning}>
          <span>Duplicate Agent Found</span>
          <Button type='primary' className={styles.confirm} onClick={() => this.confirm(res)}>Confirm
          </Button>
        </div>,
      style: {width: 400},
      className: styles.notification,
      description: res.message,
      duration: globalPromptTime10s,
      onClose: () => this.setState({duplicateAgentId: undefined})
    });
  }

  handleAddSuccess = () => {
    this.props.changeVals(ONCAHENG_TYPE, {
      agentId: {value: undefined},
      registrationDate: {value: undefined},
      agentName: {value: ''},
      agentCode: {value: ''},
      region: {value: ''},
      agentLeader: {value: ''},
      unitCode: {value: ''},
    })
    this.setState({
      duplicateAgentId: undefined,
    })
    this.goBack()
  }

  confirm = (res) => {
    notification.close('add failed')
    this.props.Confirm({
      agentId: this.props.fields.agentId.value,
      registrationDate: moment(this.props.fields.registrationDate.value).format('YYYY-MM-DD').toString(),
      force: true,
    }, this.handleAddSuccess)
  }

  render() {
    const formProps = {
      loading: this.props.loading,
      onChange: this.props.changeVals,
      agents: this.props.individualReducer.agents,
      searchAgents: this.searchAgents,
      onConfirm: this.individualAdd,
      disable: !!this.state.duplicateAgentId,
    };
    return (
      <div className={styles.container}>
        <Spin spinning={this.props.loading}>
          <div className={styles.header}>
            <Button className={styles.goback} onClick={this.goBack} type='link' icon="left">Add Registration</Button>
          </div>
          <IndividualAddForm
            {...formProps}
            {...this.props.fields}
          />
        </Spin>
      </div>
    );
  }
}

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const formLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16
  },
};

const flexRow = {
  gutter: [22, 0]
};

export default RegistrationIndividualAdd;

