import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Checkbox,
  notification,
  Icon,
  Spin
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../utils/global";
import { update_attendance } from "../../../../redux/plukReducer/attendanceReducers/EditAttendanceReducer";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateEditAttendance_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("EditAttendance", changedFields);
  },
  mapPropsToFields(props) {
    return {
      code: Form.createFormField({
        ...props.code,
        value: props.code.value
      }),
      name: Form.createFormField({
        ...props.name,
        value: props.name.value
      }),
      contractDate: Form.createFormField({
        ...props.contractDate,
        value: props.contractDate.value
      }),
      walkIn: Form.createFormField({
        ...props.walkIn,
        value: props.walkIn.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item labelCol={{ span: 5 }} label="Walk-In">
              {getFieldDecorator("walkIn", {
                valuePropName: "checked",
                initialValue: false
              })(<Checkbox disabled>Walk-In</Checkbox>)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Code" labelCol={{ span: 5 }}>
              {getFieldDecorator("code", {})(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Name" labelCol={{ span: 5 }}>
              {getFieldDecorator("name", {})(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Scan In & Out" style={{ marginBottom: 0 }}>
              {getFieldDecorator("contractDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["Scan In", "Scan Out"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
});

@connect(
  state => ({
    fields: {
      ...state.plukReducer.attendanceReducers.EditAttendanceReducer
    },
    loading: state.PublicReducer.loading
  }),
  {
    changeVals,
    update_attendance
  }
)
class EditAttendance extends Component {
  componentDidMount() {
    const {
      attendanceId,
      agentCode,
      agentName,
      scanInDate,
      scanOutDate,
      isWalkIn
    } = this.props.location.state;
    this.props.changeVals("EditAttendance", {
      attendanceId,
      code: {
        value: agentCode
      },
      name: {
        value: agentName
      },
      contractDate: {
        value: [
          scanInDate ? moment(scanInDate, "YYYY-MM-DD HH:mm:ss") : "",
          scanOutDate ? moment(scanOutDate, "YYYY-MM-DD HH:mm:ss") : ""
        ]
      },
      walkIn: {
        value: isWalkIn === "Y"
      }
    });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields, history, location } = this.props;
      const { startEndDate, qrDetail } = this.props.location.state;
      if (!err) {
        const scanIn = fields.contractDate.value[0].valueOf();
        const scanOut = fields.contractDate.value[1].valueOf();
        if (qrDetail.dailyCoachingFlag === "1") {
          const startLimitDate = moment(qrDetail.startDate).startOf("day").valueOf();
          const endLimitDate = moment(qrDetail.endDate).endOf("day").valueOf();
          if (scanIn >= startLimitDate && scanOut <= endLimitDate) {
            if (
              fields.contractDate.value[0].valueOf() ===
              fields.contractDate.value[1].valueOf()
            ) {
              notification.error({
                message: "The contract in and contract out cannot be the same",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
              return;
            }
            this.props.update_attendance(
              {
                attendanceId: fields.attendanceId,
                isWalkIn: fields.walkIn.value ? "Y" : "N",
                scanInDate: fields.contractDate.value[0].format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                scanOutDate: fields.contractDate.value[1].format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              },
              history,
              location
            );
          } else {
            notification.error({
              message: "Time out of session valid time",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
          }
        } else {
          const startLimitDate = moment(startEndDate.startDate.value).valueOf();
          const endLimitDate = moment(startEndDate.endDate.value).valueOf();
          if (scanIn < endLimitDate && scanOut > startLimitDate) {
            if (
              fields.contractDate.value[0].valueOf() ===
              fields.contractDate.value[1].valueOf()
            ) {
              notification.error({
                message: "The contract in and contract out cannot be the same",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
              return;
            }
            this.props.update_attendance(
              {
                attendanceId: fields.attendanceId,
                isWalkIn: fields.walkIn.value ? "Y" : "N",
                scanInDate: fields.contractDate.value[0].format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                scanOutDate: fields.contractDate.value[1].format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              },
              history,
              location
            );
          } else {
            notification.error({
              message: "Time out of session valid time",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
          }
        }
      }
    });
  };

  render() {
    const props = {
      onChange: this.props.changeVals,
      loading: this.props.loading,
      handleSubmit: this.handleSubmit,
      goBack: this.props.history.goBack
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/home/attendance/session_detail", {
                ...this.props.location.state
              });
            }}
          />
          Edit Attendance
        </div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(EditAttendance);
