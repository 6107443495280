import React from "react";
import moment from "moment";

import {
  Form,
  Button,
  Table,
  Empty,
  Row,
  Col,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { connect } from "react-redux";

import styles from "./SFAReport.module.less";
import {
  downloadData,
  handleQueryData,
} from "../../../../redux/pacsReducer/CPDReportReducers/SFAReportReducer";
import {switchDate} from "../../../../utils/global";

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

function disabledDate(current) {
  // Can not select days before 2022
  return switchDate() && current < moment('20220101');
}

const { Option } = Select;
const { RangePicker } = DatePicker;
@connect(
  ({ pacsReducer: { CPDReportReducers: { SFAReportReducer } } }) => ({
    SFAReportReducer
  }),
  {
    handleQueryData,
    downloadData,
  }
)
class SFAReport extends React.Component {
  state = {
    fetching: false,
    queryData: { currentPageNumber: 1, pageSize: 10 },
    isShowTable: false,
    columns: [
      {
        title: "fc_code",
        align: "center",
        dataIndex: "agentCode",
        fixed: 'left',
        width: 100
      },
      {
        title: "cc_course",
        dataIndex: "courseCode",
        align: "center",
        fixed: 'left',
        width: 150
      },
      {
        title: "cc_course_desc",
        dataIndex: "courseDesc",
        align: "center",
        width: 150
      },
      {
        title: "cc_from_date",
        align: "center",
        dataIndex: "fromDate",
      },
      {
        title: "cc_to_date",
        dataIndex: "toDate",
        align: "center",
      },
      {
        title: "Category",
        dataIndex: "category",
        align: "center",
        width: 80
      },
      {
        title: "min_sessions",
        dataIndex: "minSessions",
        align: "center",
        width: 110
      },
      {
        title: "sessions",
        dataIndex: "sessions",
        align: "center",
        width: 80
      },
      {
        title: "cc_esum",
        dataIndex: "esum",
        align: "center",
        width: 80
      },
      {
        title: "cc_rsum",
        dataIndex: "rsum",
        align: "center",
        width: 80
      },
      {
        title: "cc_osum",
        dataIndex: "osum",
        align: "center",
        width: 80
      },
      {
        title: "cc_asum",
        dataIndex: "asum",
        align: "center",
        width: 80
      },
      {
        title: "cc_csum",
        dataIndex: "csum",
        align: "center",
        width: 80
      },
      {
        title: "cc_period",
        dataIndex: "period",
        align: "center",
        width: 85
      }
    ]
  };

  componentDidMount() {
    // const { queryData } = this.state
    // const { form } = this.props
    // const fields = form.getFieldsValue()
    // this.props.handleQueryData({ ...fields, ...queryData });
    // this.setState({ queryData: fields })
  }

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          trainingDate,
          agentsContractDate
        } = values;
        const [start, end] = trainingDate || [];
        const startDate = start && moment(start).format("YYYY-MM-DD");
        const endDate = end && moment(end).format("YYYY-MM-DD");

        const [from, to] = agentsContractDate || [];
        const contractStartDate = from && moment(from).format("YYYY-MM-DD");
        const contractEndDate = to && moment(to).format("YYYY-MM-DD");
        const queryData = {
          ...values,
          startDate,
          endDate,
          contractStartDate,
          contractEndDate,
          currentPageNumber: 1,
          pageSize: 10
        }

        this.props.handleQueryData({
          ...queryData
        });

        this.setState({
          queryData,
          isShowTable: true
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current } = pagination;
    const {
      queryData
    } = this.state;
    this.props.handleQueryData({
      ...queryData,
      currentPageNumber: current
    });

    this.setState({ queryData: { ...queryData, currentPageNumber: current } });
  };

  handleDownload = () => {
    const {
      queryData
    } = this.state;
    this.props.downloadData({
      ...queryData,
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      SFAReportReducer: {
        sfaBackData: { data: dataList = [], total },
        agentStatusList,
        courseStatusList,
        courseTypeList,
        distributionList,
        tableLoading,
        downloadLoading,
      }
    } = this.props;
    const { columns, queryData: { currentPageNumber }, isShowTable } = this.state;
    return (
      <div className={styles.container}>
        <Spin spinning={downloadLoading}>
          <div className={styles.heading}>SFA Extraction Report</div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Course Type">
                  {getFieldDecorator("courseType", {
                    initialValue: courseTypeList[0].code
                  })(
                    <Select allowClear>
                      {courseTypeList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Course Status">
                  {getFieldDecorator("courseStatus", {
                    initialValue: courseStatusList[0].code
                  })(
                    <Select allowClear>
                      {courseStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Agent Status">
                  {getFieldDecorator("agentStatus", {
                    initialValue: agentStatusList[1].code
                  })(
                    <Select allowClear>
                      {agentStatusList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Training Date">
                  {getFieldDecorator("trainingDate", {
                    rules: [
                      {
                        validator: async (rule, value = []) => {
                          const [start, end] = value
                          if (start && end) {
                            const isSameYear = moment(start).isSame(end, 'year')
                            if (!isSameYear) {
                              throw new Error('Maximal range is 1 year');
                            }
                          }
                        },
                      }
                    ],
                  })(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                      disabledDate={disabledDate}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contract Date">
                  {getFieldDecorator("agentsContractDate", {})(
                    <RangePicker
                      format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                      placeholder={["Start Time", "End Time"]}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Distribution">
                  {getFieldDecorator("distribution", {
                    initialValue: distributionList[0].code
                  })(
                    <Select allowClear>
                      {distributionList.map(elt => (
                        <Option value={elt.code} key={elt.code}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
          </div>

          {
            isShowTable ?
              <div>
                {
                  dataList.length ?
                    <Button icon="download" className={styles.downloadBtn} onClick={this.handleDownload}>Download</Button> : null
                }
              </div> : null
          }

          <Table
            columns={columns}
            dataSource={dataList}
            pagination={{
              total,
              pageSize: 10,
              current: currentPageNumber
            }}
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            size="small"
            loading={tableLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(elt, ind) => ind}
            scroll={{ x: 1400, y: 300 }}
          />

        </Spin>
      </div>
    );
  }
}

const SFAReportForm = Form.create()(SFAReport);

export default SFAReportForm;
