import React from 'react'
import {
  Tooltip,
} from "antd";
import { isPambPruventureAdmin } from '../../../../../../utils/global';

export const commonColumns = [
  {
    title: 'No',
    dataIndex: "index",
    align: "center",
    width: 50,
    fixed: "left",
    render: (text, data, index) => {
      return (
        <Tooltip placement="top" title={index + 1}>
          {index + 1}
        </Tooltip>
      )
    }
  },
  {
    title: 'Agent ID',
    dataIndex: "agentId",
    align: "center",
    width: 150,
    fixed: "left",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Code',
    dataIndex: "agentCode",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Name',
    dataIndex: "agentName",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Region',
    dataIndex: "region",
    align: "center",
    width: 150,
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Unit Code',
    dataIndex: "unitCode",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Agent Leader',
    dataIndex: "agentLeader",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Registration Date',
    dataIndex: "registrationDate",
    align: "center",
    sorter: true,
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Status',
    dataIndex: "status",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
]
export const getSessionColumns = (roleId, status) => {
  if (isPambPruventureAdmin(roleId)) {
    return statusSessionColumns(status)
  } else {
    return [
      ...statusSessionColumns(status),
      {
        title: "My Session",
        dataIndex: "mySessionCompleted",
        align: "center",
        render: (text, data, index) => {
          return (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          );
        }
      }
    ];
  }
}

const statusSessionColumns = (status) => {
  const columns = []
  if (status === '1') {
    columns.push(
      {
        title: "Session Required",
        dataIndex: "sessionRequired",
        align: "center",
        render: (text, data, index) => {
          return (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          );
        }
      }
    )
  }
  columns.push(...sessionColumns)
  return columns
}

export const sessionColumns = [
         {
           title: "Session Completed",
           dataIndex: "sessionCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         },
         {
           title: "AL",
           dataIndex: "alCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         },
         {
           title: "AG",
           dataIndex: "agCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         },
         {
           title: "Zoom Trainer",
           dataIndex: "zoomTrainerCompleted",
           align: "center",
           render: (text, data, index) => {
             return (
               <Tooltip placement="top" title={text}>
                 {text}
               </Tooltip>
             );
           }
         }
       ];

export const dropdownItem = [
  {
    label: 'Individual',
    key: '1',
  },
  {
    label: 'Import',
    key: '2',
  },
]
