import types from './types';

const initialState = {
  userData: {
    total: 0,
    pageSize: 10,
    pageNumber: 1,
    userList: [],
    queryUserListLoading: false
  },
  userStatusList: [],
  rolerList: [],
  buList: [],
  rankList: [],
  allRolerList: [],
  allRoleList: [],
  resendEmaiLoading: false,
  activateLoading: false,
  noRefrash: false,
  usefulTemporaryData: { currentNum: 1, fieldsData: {} },
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.BEFORE_GET_USER_LIST:
      return {
        ...state,
        queryUserListLoading: true,
      };
    case types.GET_USER_LIST:
      return {
        ...state,
        ...{
          userData: action.payload.userData,
          queryUserListLoading: false
        }
      };
    case types.GET_USER_LIST_ERROR:
      return {
        ...state,
        ...{
          queryUserListLoading: false
        }
      };

    default:
      return {
        ...state,
        ...action.payload
      };
  }
};

export default userManagementReducer;
