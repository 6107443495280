import React from "react";
import { Form, Input, Button } from "antd";
import styles from "../Login.module.less";
import { logo, psd, head } from "../../../images/index";
// import { originalUrlPrefix } from "../../../utils/config";

const LoginBox = Form.create({
  name: "CreateLoginBox_Form",
  onFieldsChange(props, changedFields) {
    props.changeVals("login", changedFields);
  },
  mapPropsToFields(props) {
    return {
      username: Form.createFormField({
        ...props.username,
        value: props.username.value
      }),
      password: Form.createFormField({
        ...props.password,
        value: props.password.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { type } = props
  // const prefix = sessionStorage.getItem("prefix");
  // const domin = originalUrlPrefix[prefix];
  // const ssoUrl = `${domin}/secure/aad`;
  return (
    <div className={styles.formBox}>
      <div className={styles.logo} style={setBg(logo)} />
      <span style={insideStyles.title1}>
        Welcome to Regional Training Management System
      </span>
      <div className={styles.formWrap}>
      {
        type === "both" ?
          <Form colon={false}>
            <Form.Item style={{ marginBottom: "36px" }}>
              {getFieldDecorator("username", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  },
                  { max: 50, message: "fields cannot be longer than 50 characters" }
                ]
              })(
                <Input
                  name="username"
                  autoComplete="username"
                  style={{ height: "42px" }}
                  prefix={<div className={styles.icon} style={setBg(head)} />}
                  placeholder="Login Account"
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: "5px" }}>
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "You have entered an invalid password"
                  },
                  { max: 50, message: "fields cannot be longer than 50 characters" }
                ]
              })(
                <Input.Password
                  style={{ height: "42px" }}
                  prefix={<div className={styles.icon} style={setBg(psd)} />}
                  type="password"
                  placeholder="Password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <div className={styles.fp}>
                <a className="login-form-forgot" href="#" onClick={props.forgetPsd}>
                  Forgot Password
                </a>

                <Button
                  type="danger"
                  htmlType="submit"
                  onClick={() => {
                    props.handleSubmit(props.form, "submit");
                  }}
                  className={styles.btn}
                >
                  Sign in
                </Button>
                {/* <a href={ssoUrl} style={{ display: "block", width: "100%" }}>
                  <Button type="danger" className={styles.ssobtn}>
                    Login with Prudential ID
                  </Button>
                </a> */}
              </div>
            </Form.Item>
          </Form> : null
      }
      {
        type === "both" || type === 'ad' ?
        <Button
          type="danger"
          htmlType="submit"
          onClick={() => {
            if (props.adUrl) {
              // window.open(props.adUrl);
              window.location.href = props.adUrl;
            }
          }}
          className={[styles.btn, styles.mt15, styles.adbtn]}
        >
          Staff Login
        </Button> : null
      }
      </div>
    </div>
  );
});

export default LoginBox;

const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});

const insideStyles = {
  title1: {
    fontFamily: "ArialM",
    fontSize: "16px",
    color: "#666666",
    letterSpacing: 0,
    marginBottom: "35px"
  }
};
