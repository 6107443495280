import { message } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";
import { randnum, randArray } from "../../../utils";

const SAVE = "pluk/QuestionGroupReducer/SAVE";

const initState = {
  questionGroupData: {},
  queryLoading: false,
  questionList: [],
  typeList: [],
  submitLoading: false,
  questionDetailData: {},
  questionGroupToTemData: {},
  trainerList: []
};

// reducer
export default function QuestionGroupReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function queryData(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/group/list", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            questionGroupData: data || {}
          }
        });
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function queryDataToTem(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/group/list", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            questionGroupToTemData: data || {}
          }
        });
        if (cb) {
          cb();
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}
export function createGroup(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    post("/survey/group/add", payload)
      .then(() => {
        const cbcombine = () => {
          dispatch({
            type: SAVE,
            payload: {
              submitLoading: false
            }
          });
          cb();
        };
        message.info("Saved successfully", globalPromptTime, cbcombine);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}

export function updateGroup(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    post("/survey/group/update", payload)
      .then(() => {
        const cbcombine = () => {
          dispatch({
            type: SAVE,
            payload: {
              submitLoading: false
            }
          });
          cb();
        };
        message.info("Saved successfully", globalPromptTime, cbcombine);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}

export function getQuestionDetail(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    get("/survey/group/detail", payload)
      .then(res => {
        const { data } = res;
        const { questionList = [] } = data;
        questionList.map((item, index) => {
          item.questionIndex = `quesion${randArray(index)}`;
          if (item.answerList && item.answerList.length) {
            item.answerList.map((elt, ind) => {
              elt.answerIndex = `answer${randArray(ind)}`;
            });
          }
        });
        dispatch({
          type: SAVE,
          payload: {
            questionDetailData: data || {},
            questionList,
            submitLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}
export function handleSearchPageDelete(payload, cb) {
  return () => {
    get("/survey/group/delete", payload)
      .then(() => {
        message.info("Operation Success", globalPromptTime, cb);
      })
      .catch(() => {

      });
  };
}

export function getQuestionType() {
  return dispatch => {
    get("/survey/getQuestionType")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            typeList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getTrainerList() {
  return dispatch => {
    get("/survey/getGroupType")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            trainerList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function deleteQuestion(index) {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList }
      } }
    } = getState();
    questionList.splice(index, 1);
    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function deleteAnswer(index, answerIndex) {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList }
      } }
    } = getState();
    const item = questionList[index];
    const { answerList } = item;
    answerList.splice(answerIndex, 1);
    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function addQuestion() {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList, typeList }
      } }
    } = getState();
    const qtype = typeList.length ? typeList[0].value : ''
    questionList.push({
      questionType: qtype,
      questionName: "",
      questionIndex: `question${randnum()}`,
    });

    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function addAnswer(index) {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList }
      } }
    } = getState();
    const item = questionList[index];
    const { answerList } = item;
    if (answerList.length < 26) {
      answerList.push({
        answerName: "",
        answerValue: "",
        answerIndex: `answer${randnum()}`
      });
    }
    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function changeItemContent({
  type,
  index,
  title,
  answerIndex,
  answerName
}) {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList }
      } }
    } = getState();

    const item = questionList[index];

    if (type) {
      if (type === item.questionType) {
        if (type === "0" || type === '4' || type === '5' || type === '6') {
          if (title) {
            item.questionName = title;
          }
        } else if (type === "1") {
          if (title) {
            item.questionName = title;
          }
        } else if (type === "2") {
          if (title) {
            item.questionName = title;
          }
          if (answerName) {
            const elt = item.answerList[answerIndex];
            elt.answerName = answerName;
          }
        } else if (type === "3") {
          if (title) {
            item.questionName = title;
          }
          if (answerName) {
            const elt = item.answerList[answerIndex];
            elt.answerName = answerName;
          }
        }
      } else {
        item.type = type;
        if (type === "0" || type === '4' || type === '5' || type === '6') {
          questionList.splice(index, 1, {
            questionType: type,
            questionName: "",
            questionIndex: `question${randnum()}`,
            answerList: [
              {
                answerName: "1",
                answerValue: "1"
              },
              {
                answerName: "2",
                answerValue: "2"
              },
              {
                answerName: "3",
                answerValue: "3"
              },
              {
                answerName: "4",
                answerValue: "4"
              },
              {
                answerName: "5",
                answerValue: "5"
              }
            ]
          });
        } else if (type === "1") {
          questionList.splice(index, 1, {
            questionType: type,
            questionName: title,
            questionIndex: `question${randnum()}`
          });
        } else if (type === "2") {
          questionList.splice(index, 1, {
            answerList: [
              {
                answerName: "",
                answerValue: "",
                answerIndex: `answer${randnum()}`
              }
            ],
            questionType: type,
            questionName: title,
            questionIndex: `question${randnum()}`
          });
        } else if (type === "3") {
          questionList.splice(index, 1, {
            answerList: [
              {
                answerName: "",
                answerValue: "",
                answerIndex: `answer${randnum()}`
              }
            ],
            questionType: type,
            questionName: title,
            questionIndex: `question${randnum()}`
          });
        }
      }
    }

    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function changePosition(index, type) {
  return (dispatch, getState) => {
    const {
      plukReducer: { systemSettingsReducers: {
        QuestionGroupReducer: { questionList }
      } }
    } = getState();
    if (type === "up") {
      questionList[index] = questionList.splice(
        index - 1,
        1,
        questionList[index]
      )[0];
    } else if (type === "down") {
      questionList[index] = questionList.splice(
        index + 1,
        1,
        questionList[index]
      )[0];
    }

    dispatch({
      type: SAVE,
      payload: {
        questionList
      }
    });
  };
}

export function reSetGroup() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        questionList: [],
        questionDetailData: {}
      }
    });
  };
}
