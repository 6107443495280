import { message } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pacs/requirementSettingReducer/SAVE";

const initState = {
  requirementData: {},
  updateLoading: false,
  dateList: [],
  tableLoading: false,
  dataSourceList: [],
  cpdTypeList: [],
  temporaryData: {},
  pageLoading: false
};

// reducer
export default function requirementSettingReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// pamb api
export function getRequirementData(payload, cb, form) {
  return dispatch => {
    if (form) {
      form.resetFields();
    }
    post("/liamandmat/requirementList", { pambEffectiveDateId: payload })
      .then(res => {
        const { data } = res;
        dispatch({ type: SAVE, payload: { requirementData: data || {} } });
        if (cb) {
          cb();
        }
      })
      .catch(() => {

      });
  };
}

export function createRequirement(payload, cb, getdate) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateLoading: true
      }
    });

    post("/liamandmat/createRequirement", payload)
      .then(() => {
        getdate()
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              updateLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateLoading: false
          }
        });
      });
  };
}

export function updateRequirement(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        updateLoading: true
      }
    });

    post("/liamandmat/updateRequirement", payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              updateLoading: false
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            updateLoading: false
          }
        });
      });
  };
}

export function getDateList() {
  return dispatch => {
    get("/liamandmat/dateList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            dateList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function resetData() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        requirementData: {}
      }
    });
  };
}

// pacs api

export function getTypeBycateGory(payload) {
  return (dispatch) => {
    get("/tmsRequirement/getTypeListByCategory", { categoryName: payload })
      .then(res => {
        const { data = [] } = res;
        dispatch({
          type: SAVE,
          payload: {
            cpdTypeList: data
          }
        });
      })
      .catch(() => { });
  };
}

export function getDataByYear(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    get("/tmsRequirement/editCpdYearSetting", { yearId: payload })
      .then(res => {
        const { data = [] } = res;
        dispatch({ type: SAVE, payload: { dataSourceList: data, tableLoading: false } });
        // function getData(elt) {
        //   return new Promise((resolve, reject) => {
        //     get("/tmsRequirement/getTypeListByCategory", { categoryName: elt.cpdcategory || 'Core' }).then((result) => {
        //       if (result.success) {
        //         elt.cpdTypeList1 = result.data || []
        //         resolve();
        //       } else {
        //         reject()
        //       }
        //     })
        //   })
        // }

        // const promises = datalist.map((elt) => getData(elt));
        // Promise.all(promises).then(() => {
        //   dispatch({ type: SAVE, payload: { dataSourceList: datalist || [], tableLoading: false } });
        // });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function handleSubmit(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post('/tmsRequirement/saveOrUpdateRequirementSetting', payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              tableLoading: false,
              cpdTypeList: []
            }
          });
          cb()
        }
        message.info("Saved successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function removeData(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post('/tmsRequirement/deleteRequirementSetting', {}, payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              tableLoading: false
            }
          });
          cb()
        }
        message.info("deleted successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function addCpdYear(payload, cb) {
  return (dispatch) => {
    dispatch({
      type: SAVE,
      payload: {
        pageLoading: true
      }
    });
    get("/tmsRequirement/saveYear", payload)
      .then(() => {
        const combineCb = () => {
          dispatch({
            type: SAVE,
            payload: {
              pageLoading: false
            }
          });
          if (cb) { cb() }
        }

        message.info("Create successfully", globalPromptTime, combineCb);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            pageLoading: false
          }
        });
      });
  };
}

export function addARow(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        dataSourceList: payload
      }
    });
  };
}

export function saveFields(payload) {
  return (dispatch, getState) => {
    const {
      pacsReducer: { CPDSettingsReducers: {
        requirementSettingReducer: { temporaryData }
      } }
    } = getState();
    dispatch({
      type: SAVE,
      payload: {
        temporaryData: { ...temporaryData, ...payload }
      }
    });
  };
}
