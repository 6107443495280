import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Spin,
  Tooltip,
  Icon,
  Table
} from "antd";
import { changeVals } from "../../../../../redux/plukReducer/systemSettingsReducers/index.action";
import { searchCourse } from "../../../../../redux/plukReducer/systemSettingsReducers/SelectCourseReducer";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "SelectCourse_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("SelectCourse", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      })
    };
  },
  onValuesChange() {}
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading, columns } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {})(<Input />)}
            </Form.Item>
          </Col>
          <Col {...flexCol} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div>
          <Table
            bordered
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              pageSize: props.pageSize,
              current: props.pageIndex
            }}
            dataSource={props.List}
            size="small"
            rowKey="id"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { systemSettingsReducers: { SelectCourseReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: SelectCourseReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    searchCourse
  }
)
class SelectCourse extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      tableData: {
        courseName: "",
        pageSize: 10,
        currentPageNumber: 1
      },
      columns: [
        {
          title: "Course Name",
          dataIndex: "name",
          align: "center",
          width: 300,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Option",
          dataIndex: "option",
          ellipsis: true,
          align: "center",
          width: 150,
          render: (text, record) => {
            return (
              <div style={insideStyles.tableData}>
                <div
                  onClick={() => {
                    this.props.changeVals("EditCoachingCourse", {
                      courseName: {
                        value: record.name
                      },
                      courseId: record.id
                    });
                    this.props.history.push(
                      "/home/system_settings/daily_coaching/edit_coaching_course",
                      {
                        ...this.props.location.state,
                        courseName: record.name,
                        courseId: record.id,
                        go: false
                      }
                    );
                  }}
                >
                  OK
                </div>
              </div>
            );
          }
        }
      ]
    };
  }

  componentDidMount() {
    const { courseName } = this.props.location.state;
    this.props.changeVals("SelectCourse", {
      courseName: {
        value: courseName
      }
    });
    this.props.searchCourse(courseName);
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;

      if (!err) {
        this.setState(() => ({
          pageIndex: 1,
          tableData: {
            courseName: fields.courseName.value
          }
        }));
        this.props.searchCourse(fields.courseName.value);
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    this.setState(() => ({
      pageSize,
      pageIndex: current
    }));
  };

  goDetail() {
    this.props.history.push(
      "/home/system_settings/daily_coaching/edit_coaching_course",
      {}
    );
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange,
      pageSize: this.state.pageSize,
      pageIndex: this.state.pageIndex
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push(
                "/home/system_settings/daily_coaching/edit_coaching_course",
                {
                  ...this.props.location.state,
                  go: false
                }
              );
            }}
          />
          Select Course
        </div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "rgb(38, 98, 208)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(SelectCourse);
