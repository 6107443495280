import React from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import Router from "../../../../router";

@connect(state => state)
class MyProfileSetting extends React.Component {
  componentDidMount() {}

  render() {
    const {
      routers,
      PublicReducer: { loading }
    } = this.props;
    return (
      <Spin spinning={loading}>
        <Router routers={routers} />
      </Spin>
    );
  }
}

export default MyProfileSetting;
