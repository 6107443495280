import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Input,
  Row,
  Col,
  Icon,
  DatePicker,
  Button,
  Spin,
  Select
} from "antd";
import styles from "./PACSAgentDetail.module.less";
import {
  getuserDetial,
  updateAgent
} from "../../../../../redux/pambReducer/systemSettingsReducers/otherInfoReducer";
import { handleStatus, handleGender } from "../../../../../utils";

const { Option } = Select;
const { RangePicker } = DatePicker;
const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol2 = {
  span: 24
};

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const formLayout2 = {
  labelCol: { span: 11 },
  wrapperCol: { span: 13 }
};

const formLayout3 = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 }
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({
    pambReducer: { systemSettingsReducers: {
      CreateLBUAdminReducer,
      userManagementReducer,
      otherInfoReducer
    } }
  }) => ({ CreateLBUAdminReducer, userManagementReducer, otherInfoReducer }),
  { getuserDetial, updateAgent }
)
class AdminForm extends React.Component {
  state = {
    isEditable: false,
    nneList: [
      {
        id: 'Y',
        value: 'Y'
      },
      {
        id: 'N',
        value: 'N'
      },
    ]
  };

  componentDidMount() {
    const {
      location: { state = {} }
    } = this.props;
    const { bu, userId, roleId } = state;
    this.props.getuserDetial({ userId, roleId }, bu);
  }

  handleEdit = () => {
    const { isEditable } = this.state;
    this.props.form.resetFields();
    this.setState(() => ({ isEditable: !isEditable }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      form,
      location: { state = {} }
    } = this.props;
    const { userId } = state;
    form.validateFields(null, { first: true }, (err, values) => {
      if (!err) {
        const { nne, priorContractDate } = values;
        const [start, end] = priorContractDate || [];
        const priorContractStartDate =
          start && moment(start).format("YYYY-MM-DD");
        const priorContractEndDate = end && moment(end).format("YYYY-MM-DD");
        const submitData = {
          userId,
          nne,
          priorContractStartDate,
          priorContractEndDate
        };
        const callback = () => {
          this.props.history.push("/home/system_settings/user_management");
        };
        this.props.updateAgent(submitData, callback);
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      otherInfoReducer: { userInfo = {}, updateAdminLoading },
      location: { state = {} }
    } = this.props;
    const { roleName } = state;

    const {
      userName,
      nric,
      email,
      unitCode,
      agentCode,
      contactNumber,
      channel,
      bdm,
      nationality,
      gender,
      dob,
      gam,
      pgroup,
      status,
      contractedDate,
      terminationDate,
      bankPartner,
      rank,
      leaderCode,
      nne,
      officeAddress,
      officeBuilding,
      unitNo,
      rnfno,
      cislicenseDate,
      hidate,
      priorContractStartDate,
      priorContractEndDate
    } = userInfo;

    const priorContractDate = [
      priorContractStartDate
        ? moment(priorContractStartDate, "YYYY-MM-DD")
        : "",
      priorContractEndDate ? moment(priorContractEndDate, "YYYY-MM-DD") : ""
    ];
    const { isEditable, nneList } = this.state;
    return (
      <div className={styles.container}>
        <Spin spinning={updateAdminLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            {`${roleName} Info`}
          </div>
          <Form
            layout="horizontal"
            labelAlign="left"
            {...formLayout}
            colon={false}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent Name">
                  {getFieldDecorator("userName", {
                    initialValue: userName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Nationality">
                  {getFieldDecorator("nationality", {
                    initialValue: nationality
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="DOB">
                  {getFieldDecorator("dob", {
                    initialValue: dob ? moment(dob).format("YYYY-MM-DD") : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Gender">
                  {getFieldDecorator("gender", {
                    initialValue: handleGender(gender)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BDM">
                  {getFieldDecorator("BDM", {
                    initialValue: bdm
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="GAM">
                  {getFieldDecorator("GAM", {
                    initialValue: gam
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Group">
                  {getFieldDecorator("group", {
                    initialValue: pgroup
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("uinitCode", {
                    initialValue: unitCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="FC Code">
                  {getFieldDecorator("fcCode", {
                    initialValue: agentCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Status">
                  {getFieldDecorator("status", {
                    initialValue: handleStatus(status)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contracted Date">
                  {getFieldDecorator("contractedDate", {
                    initialValue: contractedDate
                      ? moment(contractedDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Business Partner Code" {...formLayout2}>
                  {getFieldDecorator("businessPartnerCode", {
                    initialValue: bankPartner
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Termination Date">
                  {getFieldDecorator("terminationDate", {
                    initialValue: terminationDate
                      ? moment(terminationDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Rank">
                  {getFieldDecorator("rank", {
                    initialValue: rank
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Leader FC Code">
                  {getFieldDecorator("leaderCode", {
                    initialValue: leaderCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: email
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC/FIN">
                  {getFieldDecorator("nric", {
                    initialValue: nric
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactNumber", {
                    initialValue: contactNumber
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol2}>
                <Form.Item label="Office Address" {...formLayout3}>
                  {getFieldDecorator("officeAddress", {
                    initialValue: officeAddress
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol2}>
                <Form.Item label="Office Building" {...formLayout3}>
                  {getFieldDecorator("officeBuildin", {
                    initialValue: officeBuilding
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Unit No. ">
                  {getFieldDecorator("unitNo", {
                    initialValue: unitNo
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="RNF No. ">
                  {getFieldDecorator("RNFNo", {
                    initialValue: rnfno
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="CIS License Date">
                  {getFieldDecorator("CISLicenseDate", {
                    initialValue: cislicenseDate
                      ? moment(cislicenseDate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NNE">
                  {getFieldDecorator("nne", {
                    initialValue: nne
                  })(
                    <Select disabled={!isEditable} allowClear>
                      {
                        nneList.map(elt => <Option key={elt.id} value={elt.id}>{elt.value}</Option>)
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="HI date">
                  {getFieldDecorator("HIdate", {
                    initialValue: hidate
                      ? moment(hidate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Channel">
                  {getFieldDecorator("Channel", {
                    initialValue: channel
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col span={12}>
                <Form.Item
                  label="Prior Contract Date"
                  labelCol={{ span: 7 }}
                  wrapperCol={{ span: 11 }}
                >
                  {getFieldDecorator("priorContractDate", {
                    initialValue: priorContractDate
                  })(
                    <RangePicker
                      format="YYYY-MM-DD"
                      placeholder={["Start Time", "End Time"]}
                      disabled={!isEditable}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={
                      isEditable ? styles.editbalBtn : styles.submitBtn
                    }
                    disabled={!isEditable}
                  >
                    Save
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleEdit}
                    className={styles.cancelBtn}
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const PACSAgentDetail = Form.create()(AdminForm);

export default PACSAgentDetail;
