import { message } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pacs/CreateLBUAdminReducer/SAVE";

const initState = {
  createLbuAdminLoading: false
};

// reducer
export default function CreateLBUAdminReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleSubmit(payload, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        createLbuAdminLoading: true
      }
    });

    post("/systemSetting/createLbuAdmin", payload)
      .then(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLbuAdminLoading: false
          }
        });
        message.info("Saved successfully", globalPromptTime, callback);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLbuAdminLoading: false
          }
        });
      });
  };
}
