import React, { Component } from "react";
import { Icon } from "antd";
import { withRouter } from "react-router-dom";
import styles from './index.module.less';

class Header extends Component {
  render() {
    const { goBackUrl, title, isTopNav } = this.props;
    return (
      <div className={styles.heading}>
        {
          isTopNav ? null : (
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() => {
                if (goBackUrl) {
                  this.props.history.push(goBackUrl)
                } else {
                  this.props.history.goBack()
                }
              }
              }
            />
          )
        }
          {title}
      </div>
    )
  }
}

export default withRouter(Header);
