import React from "react";
import { Form, Input, Row, Col, Icon, Popover, Table, Button, Spin } from "antd";
// import moment from "moment";
import { connect } from "react-redux";
import {
  getcpdHoursAgentDetail,
  handleDownloadxlsCpd
} from "../../../../../redux/pambReducer/CPDSettingsReducers/TRagentDetailReducer";
import WrapGroup from "./WrapGroup";
import styles from "./CPDHoursMTADetail.module.less";

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const formLayout2 = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 8,
  md: 8,
  sm: 8,
  xs: 8
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({ pambReducer: { CPDSettingsReducers: { TRagentDetailReducer } } }) => ({
    TRagentDetailReducer
  }),
  { getcpdHoursAgentDetail, handleDownloadxlsCpd }
)
class HomeForm extends React.Component {
  state = {
    columns: [
      {
        title: "Course Name",
        dataIndex: "courseName",
        width: 150,
        align: "center"
      },
      {
        title: "Course Type",
        dataIndex: "courseType",
        align: "center"
      },
      {
        title: "Department",
        dataIndex: "department",
        align: "center"
      },
      {
        title: "Training Date",
        dataIndex: "trainingDate",
        align: "center",
        render: (text, record) => {
          const { trainingDates = [] } = record;
          if (trainingDates && trainingDates.length > 1) {
            const content = (
              <div className={styles.contentBox}>
                {trainingDates.map(elt => {
                  return <p key={elt}>{elt}</p>;
                })}
              </div>
            );

            return (
              <div className={styles.datebox}>
                <span className={styles.firstdate}>{trainingDates[0]}</span>
                <Popover
                  placement="right"
                  title="Training Date"
                  content={content}
                  trigger="click"
                  overlayClassName="ant_popC_training_date"
                >
                  <span className={styles.datemore}>···</span>
                </Popover>
              </div>
            );
          }

          return <div>{(trainingDates && trainingDates.length) ? trainingDates[0] : null}</div>;
        }
      },
      {
        title: "Training Session status",
        dataIndex: "trainingSessionStatus",
        align: "center"
      },
      {
        title: "Completed Date",
        dataIndex: "completeDate",
        align: "center",
      },
      {
        title: "Course Completed Status",
        dataIndex: "courseStatus",
        align: "center"
      },
      {
        title: () => (
          <div>
            <div>Technical</div>
            <div>(CPD Hours)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam6monReqStatus",
        render: (text, record) => {
          const { courseType, cpdHours } = record;
          return (
            <span>
              {courseType === "Technical" ? cpdHours : ""}
            </span>
          );
        }
      },
      {
        title: () => (
          <div>
            <div>Non-Technical</div>
            <div>(CPD Hours)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam12monReqStatus",
        render: (text, record) => {
          const { courseType, cpdHours } = record;
          return (
            <span>
              {courseType === "Non-Technical" ? cpdHours : ""}
            </span>
          );
        }
      },
      {
        title: () => (
          <div>
            <div>Unstructured</div>
            <div>(CPD Hours)</div>
          </div>
        ),
        align: "center",
        dataIndex: "liam24monReqStatus",
        render: (text, record) => {
          const { courseType, cpdHours } = record;
          return (
            <span>
              {courseType === "Unstructured" ? cpdHours : ""}
            </span>
          );
        }
      }
    ]
  };

  componentDidMount() {
    const { state = {} } = this.props.location;
    const { userId, type, startDate, endDate } = state;
    this.props.getcpdHoursAgentDetail({ userId, type, startDate, endDate });
  }

  handleDownload = props => {
    const { state } = props;
    const { userId, type, startDate, endDate, totalCpd } = state || {};
    this.props.handleDownloadxlsCpd({
      userId,
      type,
      startDate: startDate || '',
      endDate: endDate || '',
      totalCPDAchieved: totalCpd
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      TRagentDetailReducer: { cpdmtaAgentDetailData, detailLoading },
      location: { state }
    } = this.props;

    const { totalCpd, startDate, endDate } = state || {};
    const { columns } = this.state;
    const {
      name,
      region,
      agencyUnit,
      agentId,
      nric,
      deadline,
      agentCode,
      mtaContractDate,
      tableList = [],
      totalCPDtoBefulfilled,
      balanceCPDtoBefulfilled,
      reappointedIndicator
    } = cpdmtaAgentDetailData;

    return (
      <div className={styles.container}>
        <Spin spinning={detailLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/training_history/cpd_history/search"
                )
              }
            />
          TAKAFUL AGENCY CPD REPORT
          </div>
          <WrapGroup heading="Report Details">
            <Form
              className={styles.ant_form}
              labelAlign="left"
              colon={false}
              {...formLayout}
            >
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Reporting Date">
                    {getFieldDecorator("date", {
                      initialValue: startDate ? `${startDate} - ${endDate}` : ""
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Region">
                    {getFieldDecorator("region", {
                      initialValue: region
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Agency Unit">
                    {getFieldDecorator("agencyUnit", {
                      initialValue: agencyUnit
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </WrapGroup>
          <WrapGroup heading="Agent Details">
            <Form
              className={styles.ant_form}
              labelAlign="left"
              colon={false}
              {...formLayout}
            >
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Name">
                    {getFieldDecorator("name", {
                      initialValue: name
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="NRIC Number">
                    {getFieldDecorator("nric", {
                      initialValue: nric
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Agent ID">
                    {getFieldDecorator("agentId", {
                      initialValue: agentId
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>

              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Agent Code">
                    {getFieldDecorator("agentCode", {
                      initialValue: agentCode
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="MTA Contract Date" {...formLayout2}>
                    {getFieldDecorator("mtaContractDate", {
                      initialValue: mtaContractDate
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Reappointed Indicator" {...formLayout2}>
                    {getFieldDecorator("reappointedIndicator", {
                      initialValue: reappointedIndicator
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>


              </Row>

              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="CPD Deadline">
                    {getFieldDecorator("cpdDeadline", {
                      initialValue: deadline
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </WrapGroup>
          <div className={styles.downloadBox}>
            <Button
              className={styles.downloadBtn}
              icon="download"
              onClick={() => this.handleDownload({ state })}
            >
              Download
            </Button>
          </div>
          <Table
            columns={columns}
            dataSource={tableList}
            size="small"
            pagination={false}
            rowClassName={(record) => {
              const { highLight } = record;
              switch (highLight) {
                case 0:
                  return styles.ant_row_bg;
                  break;
                case 2:
                  return styles.ant_row_bg_2;
                  break;
                default:
                  return '';
                  break;
              }
            }}
            // loading={
            //   queryUserListLoading || resendEmaiLoading || activateLoading
            // }
            className={styles.ant_dev_table}
            rowKey="courseName"
          />
          <div className={styles.requirement_status}>
            <span className={styles.infobox}>
              <span className={styles.circle} />
            Total CPD Hours To Be Fulfilled:
            <span className={styles.num}>{totalCPDtoBefulfilled}</span>
            </span>
            <span className={styles.infobox}>
              <span className={styles.circle} />
            Total CPD Hours Achieved:
            <span className={styles.num}>{totalCpd}</span>
            </span>
            <span className={styles.infobox}>
              <span className={styles.circle} />
            Balance CPD Hours To Be Fulfilled:
            <span className={styles.num}>{balanceCPDtoBefulfilled}</span>
            </span>
          </div>
        </Spin>
      </div>
    );
  }
}
const CPDHoursMTADetail = Form.create()(HomeForm);

export default CPDHoursMTADetail;
