// AddAttendanceReducer
import { notification } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";

const INIT_DATA_EDITATTENDANCE = "PAMB/INIT_DATA_EDITATTENDANCE";

const initState = {
  attendanceId: "",
  code: {
    value: ""
  },
  name: {
    value: ""
  },
  contractDate: {
    value: ""
  },
  startDate: {
    value: ""
  },
  endDate: {
    value: ""
  },
  walkIn: {
    value: false
  }
};

// reducer
export default function EditAttendanceReducer(state = initState, action) {
  switch (action.type) {
    case "EditAttendance":
      return { ...state, ...action.payload };
    case INIT_DATA_EDITATTENDANCE:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA_EDITATTENDANCE
  };
}

export function update_attendance(payload, history, location) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceTms/updateAttendance", payload)
      .then(res => {
        if (res.success) {
          notification.success({
            message: "Updated successfully",
            duration: globalPromptTime10s
          });
          history.push("/home/attendance/session_detail", {
            ...location.state
          });
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
