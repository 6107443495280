import React from "react";
import { Table } from "antd";
// import moment from "moment";
import styles from "./TitleAndTabel.module.less";

class TitleAndTabel extends React.Component {
  render() {
    const { columns, title, dataSource, status, style } = this.props;

    return (
      <div className={styles.container} style={style}>
        <div className={styles.headerbox}>
          <div className={styles.title}>{title}</div>
          <div className={styles.status}>Requirement Status : {status}</div>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          size="small"
          pagination={false}
          rowClassName={(record) => {
            const { highLight } = record;
            switch (highLight) {
              case 0:
                return styles.ant_row_bg;
                break;
              case 2:
                return styles.ant_row_bg_2;
                break;
              default:
                return '';
                break;
            }
          }}
          // loading={
          //   queryUserListLoading || resendEmaiLoading || activateLoading
          // }
          className={styles.ant_dev_table}
          rowKey="courseName"
        />
      </div>
    );
  }
}

export default TitleAndTabel;
