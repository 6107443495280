import React, { Component } from 'react';
import { message, Spin } from "antd";
import { post } from '../../../../../../../utils/request';
import Header from '../../../../../../components/Header';
import ActionButton from './ActionButton/index';
import { userListPath } from '../utils'

export default (WrappedComponent, config) => {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isEditable: false,
        loading: false,
        userInfo: {},
        canModify: false
      }
    }

    componentDidMount() {
      this.fetchUserDetail();
    }

    canModify = (roleCode, bu, isEditable) => {
      if (bu === 'ADMIN') {
        return true;
      }

      if (roleCode === 'PHKLADCandidates') {
        return isEditable !== false;
      }
    };

    fetchUserDetail = async () => {
      const { userId, bu, roleCode } = this.props.location.state;
      const data = {
        userId
      };

      this.setState({
        loading: true
      });

      const res = await post(config.fetchUrl, data);

      const userInfo = res.data;

      if (userInfo) {
        userInfo.roleCode = roleCode;
        this.setState({
          userInfo,
          loading: false,
          canModify: this.canModify(roleCode, bu, userInfo.editable)
        });
      }
    }

    handleEdit = () => {
      const { isEditable } = this.state;
      this.setState(() => ({ isEditable: !isEditable }), () => {
        if (this.state.isEditable === false) {
          this.wrappedInstance.resetFields();
        }
      });
    };

    handleSubmit = () => {
      this.wrappedInstance.validateFields({ first: true }, async (err, values) => {
        if (!err) {
          try {
            const { isEditable } = this.state;
            const { userId } = this.props.location.state;

            this.setState({
              loading: true
            });

            const data = {
              ...values,
              userId
            };

            await post(config.saveUrl, data, true);
            this.setState(() => ({
              isEditable: !isEditable,
              loading: false
            }));
            message.info("Saved successfully", 2);
            this.fetchUserDetail();
          } catch (error) {
            this.setState(() => ({
              isEditable: true,
              loading: false
            }));
          }
        }
      });
    }

    render() {
      const { userInfo, isEditable, loading, canModify } = this.state;
      const headerTitle = `${userInfo.roleName} Info`;

      return (
        <div>
          <Header
            title={headerTitle}
            goBackUrl={userListPath}
          />
          <Spin spinning={loading}>
            <WrappedComponent
              userInfo={userInfo}
              isEditable={isEditable}
              ref={(instance) => { this.wrappedInstance = instance }}
              {...this.props}
            />
          </Spin>
          {canModify && <ActionButton
            isEditable={isEditable}
            handleEdit={this.handleEdit}
            handleSubmit={this.handleSubmit}
          />}
        </div>
      )
    }
  }
}
