import React from "react";
import { connect } from "react-redux";
import {
  getBuList,
  getUserRoleList,
  getAllRolerList,
  getAllRoleList,
  getUserStatusList
} from "../../../redux/phklReducer/systemSettingsReducers/userManagement/action";

@connect(
  state => state,
  {
    getBuList,
    getUserRoleList,
    getAllRolerList,
    getAllRoleList,
    getUserStatusList,
  }
)
class RegionalHome extends React.Component {
  componentDidMount() {
    this.props.getBuList();
    this.props.getUserRoleList();
    this.props.getAllRolerList();
    this.props.getAllRoleList();
    // this.props.getUserStatusList();
  }

  render() {
    return null;
  }
}

export default RegionalHome;
