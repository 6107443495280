import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Checkbox,
  notification,
  Icon,
  Spin
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../utils/global";
import { update_attendance } from "../../../../redux/pacsReducer/attendanceReducers/EditAttendanceReducer";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateEditAttendance_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("EditAttendance", changedFields);
  },
  mapPropsToFields(props) {
    return {
      code: Form.createFormField({
        ...props.code,
        value: props.code.value
      }),
      name: Form.createFormField({
        ...props.name,
        value: props.name.value
      }),
      // contractDate: Form.createFormField({
      //   ...props.contractDate,
      //   value: props.contractDate.value
      // }),
      walkIn: Form.createFormField({
        ...props.walkIn,
        value: props.walkIn.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading } = props;

  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item labelCol={{ span: 5 }} label="Walk-In">
              {getFieldDecorator("walkIn", {
                valuePropName: "checked",
                initialValue: false
              })(<Checkbox disabled>Walk-In</Checkbox>)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Code" labelCol={{ span: 5 }}>
              {getFieldDecorator("code", {})(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Name" labelCol={{ span: 5 }}>
              {getFieldDecorator("name", {})(<Input disabled />)}
            </Form.Item>
          </Col>
          <Col {...flexCol} className={styles.dateWidth}>
            <Form.Item label="Scan In & Out" style={{ marginBottom: 0, width: "100%", }}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Form.Item style={{ display: 'inline-block', width: '46%' }}>
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: moment("00:00:00", "HH:mm:ss"),
                    }}
                    value={props.contractStartDate}
                    onChange={e => {
                      props.onChange("EditAttendance", {
                        contractStartDate: e
                      });
                    }}
                    placeholder="Scan In"
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <span style={{ display: 'inline-block', flex: 1, textAlign: "center" }}> ~ </span>
                <Form.Item style={{ display: 'inline-block', width: '46%' }}>
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: moment("23:59:59", "HH:mm:ss")
                    }}
                    value={props.contractEndDate}
                    onChange={e => {
                      props.onChange("EditAttendance", {
                        contractEndDate: e
                      });
                    }}
                    placeholder="Scan Out"
                    format="YYYY-MM-DD HH:mm:ss"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </div>
            </Form.Item>
            {/* <Form.Item label="Scan In & Out" style={{ marginBottom: 0 }}>
              {getFieldDecorator("contractDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["Scan In", "Scan Out"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                />
              )}
            </Form.Item> */}
          </Col>
        </Row>

        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
});

@connect(
  state => ({
    fields: {
      ...state.pacsReducer.attendanceReducers.EditAttendanceReducer
    },
    loading: state.PublicReducer.loading
  }),
  {
    changeVals,
    update_attendance
  }
)
class EditAttendance extends Component {
  componentDidMount() {
    const {
      attendanceId,
      agentCode,
      agentName,
      scanInDate,
      scanOutDate,
      isWalkIn
    } = this.props.location.state;
    this.props.changeVals("EditAttendance", {
      attendanceId,
      code: {
        value: agentCode
      },
      name: {
        value: agentName
      },
      // contractDate: {
      //   value: [
      //     scanInDate ? moment(scanInDate, "YYYY-MM-DD HH:mm:ss") : "",
      //     scanOutDate ? moment(scanOutDate, "YYYY-MM-DD HH:mm:ss") : ""
      //   ]
      // },
      contractStartDate: scanInDate ? moment(scanInDate, "YYYY-MM-DD HH:mm:ss") : "",
      contractEndDate: scanOutDate ? moment(scanOutDate, "YYYY-MM-DD HH:mm:ss") : "",
      walkIn: {
        value: isWalkIn === "Y"
      }
    });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields, history, location } = this.props;
      const { startEndDate, qrDetail } = this.props.location.state;
      if (!err) {
        if (!fields.contractStartDate && !fields.contractEndDate) {
          notification.error({
            message: "Scan In & Out is empty",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
          return;
        }

        if (fields.contractStartDate && fields.contractEndDate) {
          const scanIn = fields.contractStartDate.valueOf();
          const scanOut = fields.contractEndDate.valueOf();
          if (scanOut <= scanIn) {
            notification.error({
              message: "The scan in in and scan out cannot be the same",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
          if (qrDetail.dailyCoachingFlag === "1") {
            const startLimitDate = moment(qrDetail.startDate).startOf("day").valueOf();
            const endLimitDate = moment(qrDetail.endDate).endOf("day").valueOf();
            if (scanIn >= startLimitDate && scanOut <= endLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const startLimitDate = moment(startEndDate.startDate.value).valueOf();
            const endLimitDate = moment(startEndDate.endDate.value).valueOf();
            if (scanIn < endLimitDate && scanOut > startLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }

        if (fields.contractStartDate) {
          const scanIn = fields.contractStartDate.valueOf();
          if (qrDetail.dailyCoachingFlag === "1") {
            const startLimitDate = moment(qrDetail.startDate).startOf("day").valueOf();
            if (scanIn >= startLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: ""
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const endLimitDate = moment(startEndDate.endDate.value).valueOf();
            if (scanIn < endLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: fields.contractStartDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  ),
                  scanOutDate: ""
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }

        if (fields.contractEndDate) {
          const scanOut = fields.contractEndDate.valueOf();
          if (qrDetail.dailyCoachingFlag === "1") {
            const endLimitDate = moment(qrDetail.endDate).endOf("day").valueOf();
            if (scanOut <= endLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: "",
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          } else {
            const startLimitDate = moment(startEndDate.startDate.value).valueOf();
            if (scanOut > startLimitDate) {
              this.props.update_attendance(
                {
                  attendanceId: fields.attendanceId,
                  isWalkIn: fields.walkIn.value ? "Y" : "N",
                  scanInDate: "",
                  scanOutDate: fields.contractEndDate.format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                },
                history,
                location
              );
            } else {
              notification.error({
                message: "Time out of session valid time",
                duration: globalPromptTime10s,
                className: "ant-style-notification-error"
              });
            }
          }
          return false;
        }
      }
    });
  };

  render() {
    const props = {
      onChange: this.props.changeVals,
      loading: this.props.loading,
      handleSubmit: this.handleSubmit,
      goBack: this.props.history.goBack
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              this.props.history.push("/home/attendance/session_detail", {
                ...this.props.location.state
              });
            }}
          />
          Edit Attendance
        </div>
        <div className={styles.col_dev_left_short}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export default withRouter(EditAttendance);
