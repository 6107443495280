import React from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Empty,
  Row,
  Col,
  Popconfirm,
  Divider,
  Select
} from "antd";
import { connect } from "react-redux";
import styles from "./QuestionGroupSearch.module.less";
import {
  queryData,
  handleSearchPageDelete,
  saveTempData,
  handleResetQueryData
} from "../../../../../redux/pacsReducer/systemSettingsReducers/QuestionGroupReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

const { Option } = Select;

@connect(
  ({ pacsReducer: { systemSettingsReducers: { QuestionGroupReducer } } }) => ({
    QuestionGroupReducer
  }),
  {
    queryData,
    handleSearchPageDelete,
    saveTempData,
    handleResetQueryData
  }
)
class QuestionGroupSearch extends React.Component {
  state = {
    columns: [
      {
        title: "Group Name",
        dataIndex: "groupName",
        align: "center",
        width: 200,
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        dataIndex: "groupDescription",
        ellipsis: true
      },
      {
        title: "Actions",
        align: "center",
        width: 240,
        render: (text, record) => {
          const { pambQuestionGroupId, isInUse } = record;
          const toEdit = {
            pathname:
              "/home/system_settings/question_group/question_group_edit",
            state: {
              pambQuestionGroupId
            }
          };
          const toDetail = {
            pathname:
              "/home/system_settings/question_group/question_group_detail",
            state: {
              pambQuestionGroupId
            }
          };
          return (
            <div className={styles.actions}>
              <span className={styles.spanCss} onClick={() => this.gotoEditpage(toDetail)}>View</span>
              {isInUse === "0" ? (
                <div>
                  <Divider type="vertical" />
                  <span className={styles.spanCss} onClick={() => this.gotoEditpage(toEdit)}>Edit</span>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Please double confirm?"
                    onConfirm={() => this.handleDelete({ pambQuestionGroupId })}
                    okText="Yes"
                    cancelText="No"
                  >
                    <span className={styles.spanCss}>Delete</span>
                  </Popconfirm>
                </div>
              ) : null}
            </div>
          );
        }
      }
    ]
  };

  async componentDidMount() {
    await this.props.saveTempData({ dontFresh: false });
    const { temporaryQueryData } = this.props.QuestionGroupReducer
    if (temporaryQueryData.isShowTable) {
      await this.props.queryData({ ...temporaryQueryData })
    }
  }

  componentWillUnmount() {
    const { temporaryQueryData } = this.props.QuestionGroupReducer
    if (!temporaryQueryData.dontFresh) {
      this.props.handleResetQueryData()
    }
  }

  gotoEditpage = async (data) => {
    await this.props.saveTempData({ dontFresh: true })
    await this.props.history.push(data);
  };

  handleDelete = id => {
    const {
      QuestionGroupReducer: {
        questionGroupData: { data: dataList, totalPage },
        temporaryQueryData
      }
    } = this.props;
    const { currentPageNumber } = temporaryQueryData

    let current = currentPageNumber;
    if (totalPage > 1 && dataList.length === 1) {
      current = currentPageNumber - 1;
    }

    const callback = () => {
      this.props.queryData({
        ...temporaryQueryData,
        currentPageNumber: current
      });
    };

    this.props.handleSearchPageDelete(id, callback);
  };

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        const queryData = {
          ...values,
          currentPageNumber: 1,
          isShowTable: true
        }
        this.props.saveTempData({ isShowTable: true })
        this.props.queryData(queryData);
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const {
      temporaryQueryData
    } = this.props.QuestionGroupReducer;

    this.props.queryData({
      ...temporaryQueryData,
      pageSize,
      currentPageNumber: current
    });
  };

  gotoCreatepage = async () => {
    await this.props.saveTempData({ dontFresh: true })
    await this.props.history.push(
      '/home/system_settings/question_group/question_group_create'
    );
  };

  render() {
    const {
      form: { getFieldDecorator },
      QuestionGroupReducer: {
        questionGroupData: { data: dataList, total },
        queryLoading,
        trainerList,
        temporaryQueryData,
      }
    } = this.props;

    const {
      currentPageNumber, groupName, groupType
    } = temporaryQueryData

    const { columns } = this.state;


    return (
      <div className={styles.container}>
        <div className={styles.topBox}>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Group Name">
                  {getFieldDecorator("groupName", {
                    initialValue: groupName
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Is Trainer">
                  {getFieldDecorator("groupType", {
                    initialValue: groupType || ((trainerList && trainerList.length) ? trainerList[0].value : '')
                  })(
                    <Select>
                      {trainerList.map(elt => (
                        <Option value={elt.value} key={elt.value}>
                          {elt.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <div className={styles.btnBox}>
                  <Button className={styles.ant_btn} onClick={this.handleSearch}>
                    Search
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
        <Button
          className={styles.btn}
          style={{ marginBottom: "20px", marginTop: "4px" }}
          icon="plus"
          onClick={this.gotoCreatepage}
        >
          Add
        </Button>
        <Table
          locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
          columns={columns}
          dataSource={dataList}
          pagination={{
            total,
            pageSize: 10,
            current: currentPageNumber
          }}
          size="small"
          loading={queryLoading}
          onChange={this.handleTableChange}
          className={styles.ant_dev_table}
          rowKey="pambQuestionGroupId"
        />
      </div>
    );
  }
}

const QuestionGroupSearchForm = Form.create()(QuestionGroupSearch);

export default QuestionGroupSearchForm;
