import { notification } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE_COURSEFINDER_DATA = "PLUK/SAVE_COURSEFINDER_DATA";
const UPDATE_QRCODE_TIME = "PLUK/UPDATE_QRCODE_TIME";
const INIT_DATA_COURSEFINDER = "PLUK/INIT_DATA_COURSEFINDER";

const initState = {
  courseName: {
    value: ""
  },
  moduleName: {
    value: ""
  },
  trainerName: {
    value: ""
  },
  venueRegion: {
    value: ""
  },
  venueName: {
    value: ""
  },
  venueAddress: {
    value: ""
  },
  trainingDate: {
    value: []
  },
  sessionName: {
    value: ""
  },
  showQR: false,
  showList: false,
  courseNameList: [],
  trainerNameList: [],
  sessionNameList: [],
  venueRegionList: [],
  venueNameList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 50,
  currentPageNumber: 1,
  ScanTimes: 0,
  attendanceStatistics: {
    absentSum: "",
    attendedSum: "",
    completedSum: "",
    courseCompletedSum: "",
    registeredSum: "",
    walkInSum: ""
  },
  qrDetail: {
    courseName: "",
    moduleName: "",
    sessionName: "",
    startTime: "",
    endTime: "",
    trainer: "",
    venueRegion: "",
    venueName: "",
    venueAddress: "",
    nowDate: "",
    currentTime: 0
  }
};

// reducer
export default function CourseFinderReducer(state = initState, action) {
  switch (action.type) {
    case "CourseFinder":
      return { ...state, ...action.payload };
    case SAVE_COURSEFINDER_DATA:
      return { ...state, ...action.payload };
    case UPDATE_QRCODE_TIME:
      return { ...state, qrDetail: { ...state.qrDetail, ...action.payload } };
    case INIT_DATA_COURSEFINDER:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA_COURSEFINDER
  };
}

export function save_data(payload) {
  return {
    type: SAVE_COURSEFINDER_DATA,
    payload
  };
}

export function update_time(payload) {
  return {
    type: UPDATE_QRCODE_TIME,
    payload
  };
}

// GET /pamb/attendanceTms/getTrainerNameList
export function getTrainerNameList() {
  return dispatch => {
    get("/attendanceTms/getTrainerNameList")
      .then(res => {
        if (res.success) {
          if (res.data.length) {
            dispatch(
              save_data({
                trainerNameList: [...res.data]
              })
            );
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => {
      });
  };
}

// GET /pamb/attendanceTms/getSessionNameList
export function getSessionNameList(courseId) {
  return dispatch => {
    dispatch(
      save_data({
        sessionName: {
          value: ""
        }
      })
    );
    get("/attendanceTms/getSessionNameList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionNameList: courseId ? [...res.data] : []
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /pamb/attendanceTms/getCourseNameList
export function getCourseNameList() {
  return dispatch => {
    get("/attendanceTms/getCourseNameList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              courseNameList: [...res.data]
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /pamb/attendanceTms/getCourseList
export function getCourseList(payload) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceTms/getCourseList", payload)
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              searchList: res.data.data,
              showList: true,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
export function download_sessionday(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/attendanceTms/downloadSessionInfo", data, {}, "blob")
      .then(blob => {
        const fileName = "CourseList.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// GET /pamb/attendanceTms/getAttendanceScanTimes
export function getAttendanceScanTimes() {
  return dispatch => {
    get("/attendanceTms/getAttendanceScanTimes")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              ScanTimes: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}
// GET /pamb/attendanceTms/getVenueNameList
export function getVenueNameList() {
  return dispatch => {
    get("/attendanceTms/getVenueNameList")
      .then(res => {
        if (res.success) {
          const arrData = res.data.filter(item => {
            return item.venueId;
          });
          dispatch(
            save_data({
              venueNameList: arrData
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}
// GET /pamb/attendanceTms/getVenueRegionList
export function getVenueRegionList() {
  return dispatch => {
    get("/attendanceTms/getVenueRegionList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              venueRegionList: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}
// GET /pamb/attendanceTms/getAttendanceStatistics
export function update_attendance_statistics(sessionId) {
  return dispatch => {
    dispatch(loading(true));
    get("/attendanceTms/getAttendanceStatistics", { sessionId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              attendanceStatistics: { ...res.data }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
