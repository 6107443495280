import { notification } from "antd";
import moment from "moment";
import { globalPromptTime10s } from "../../../utils/global";
import { get, post } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_EDITCOACHINGCOURSE = "PLUK/INIT_DATA_EDITCOACHINGCOURSE";

const initState = {
  pambDailyCoachingRuleId: "",
  type: "",
  courseId: "",
  sessionId: "",
  startDate: "",
  courseName: {
    value: ""
  },
  courseCode: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  sessionNameEdit: {
    value: ""
  },
  trainer: {
    value: ""
  },
  trainerName: {
    value: ""
  },
  sessionDate: {
    value: []
  },
  sessionStart: "",
  sessionEnd: "",
  frequency: {
    value: ""
  },
  programDate: {
    value: ""
  },
  attendHours: {
    value: ""
  },
  trainingDays: {
    value: ""
  },
  attendRate: {
    value: ""
  },
  trainerList: [],
  trainerNameList: [],
  trainerHaveList: [],
  sessionList: []
};

// reducer
export default function EditCoachingCourseReducer(state = initState, action) {
  switch (action.type) {
    case "EditCoachingCourse":
      return { ...state, ...action.payload };
    case INIT_DATA_EDITCOACHINGCOURSE:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_EDITCOACHINGCOURSE
  };
}

export function save_data(payload) {
  return {
    type: "EditCoachingCourse",
    payload
  };
}

export function getDetail(courseId, sessionId, obj, pambDailyCoachingRuleId) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/getDailyCoaching", { pambDailyCoachingRuleId })
      .then(res => {
        if (res.success) {
          const data = { ...res.data };
          const trainerList = [];
          const trainerNameList = [];
          data.trainerNameList.forEach(item => {
            trainerList.push(item.userid);
            trainerNameList.push(item.username);
          });

          dispatch(
            save_data({
              pambDailyCoachingRuleId: data.pambDailyCoachingRuleId,
              sessionId: data.sessionId,
              courseId: data.courseId,
              courseName: {
                value: data.courseName
              },
              courseCode: {
                value: data.courseCode
              },
              sessionName: {
                value: data.sessionId
              },
              sessionNameEdit: {
                value: data.sessionName
              },
              trainer: {
                value: data.trainerFlag
              },
              trainerName: {
                value: trainerNameList.join(",")
              },
              sessionStart: data.sessionStartTime
                ? moment(data.sessionStartTime, "HH:mm:ss")
                : "",
              sessionEnd: data.sessionEndTime
                ? moment(data.sessionEndTime, "HH:mm:ss")
                : "",
              sessionDate: {
                value: [
                  data.sessionStartTime
                    ? moment(data.sessionStartTime, "HH:mm:ss")
                    : "",
                  data.sessionEndTime
                    ? moment(data.sessionEndTime, "HH:mm:ss")
                    : ""
                ]
              },
              frequency: {
                value: data.generationFrequency
              },
              programStart: data.programDateStart
                ? moment(data.programDateStart, "YYYY-MM-DD")
                : "",
              programEnd: data.programDateEnd
                ? moment(data.programDateEnd, "YYYY-MM-DD")
                : "",
              programDate: {
                value: [
                  data.programDateStart
                    ? moment(data.programDateStart, "YYYY-MM-DD")
                    : "",
                  data.programDateEnd
                    ? moment(data.programDateEnd, "YYYY-MM-DD")
                    : ""
                ]
              },
              startDate: data.programDateStart
                ? moment(data.programDateStart, "YYYY-MM-DD")
                : "",
              attendHours: {
                value: data.dailyMinAttendHours
              },
              trainingDays: {
                value: data.totalTrainingDays
              },
              attendRate: {
                value: data.requiredAttendRate
              },
              trainerList,
              trainerNameList,
              trainerHaveList: data.trainerNameList,
              ...obj
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function edit(payload, history) {
  return dispatch => {
    dispatch(loading(true));
    post("/dailycoaching/updateDailyCoaching", payload)
      .then(res => {
        if (res.success) {
          history.push(
            "/home/system_settings/daily_coaching/daily_coaching_search"
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function create(payload, history) {
  return dispatch => {
    dispatch(loading(true));
    post("/dailycoaching/createDailyCoaching", payload)
      .then(res => {
        if (res.success) {
          history.push(
            "/home/system_settings/daily_coaching/daily_coaching_search"
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function getSession(courseId) {
  return dispatch => {
    dispatch(loading(true));
    get("/dailycoaching/sessionList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionList: res.data
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
