import React from "react";
import { connect } from "react-redux";
import Router from "../../../../../router";
import {
  getQuestionType,
  getTrainerList
} from "../../../../../redux/plukReducer/systemSettingsReducers/QuestionGroupReducer";

@connect(
  state => state,
  { getQuestionType, getTrainerList }
)
class QuestionGroup extends React.Component {
  componentDidMount() {
    this.props.getQuestionType();
    this.props.getTrainerList();
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default QuestionGroup;
