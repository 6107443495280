import React from "react";
import Router from "../../../../../router";

class CPDHours extends React.Component {
  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default CPDHours;
