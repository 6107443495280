// env.cofig
export const globalUrlPre = "tmsweb";

export const BASE_URL = "https://rtms-uat.prudential.com.sg";
export const BASE_URL_PACS = "https://rtms-uat.prudential.com.sg";
export const BASE_URL_PAMB = "https://rtms-uat.prudential.com.sg";
export const BASE_URL_PHKL = "https://rtms-uat.prudential.com.sg";
export const BASE_URL_PLUK = "{{ publishedpluk }}"; // add by zhy

// export const BASE_URL = "https://rtms-sit.prudential.com.sg";
// export const BASE_URL_PACS = "https://rtms-sit.prudential.com.sg";
// export const BASE_URL_PAMB = "https://rtms-sit.prudential.com.sg";
// export const BASE_URL_PHKL = "https://rtms-sit.prudential.com.sg";
// export const BASE_URL_PLUK = "https://rtms-sit.prudential.com.sg"; // add by zhy

// export const BASE_URL = "https://rtms-uat.prudential.com.sg";
// export const BASE_URL_PACS = "https://rtms-uat.prudential.com.sg";
// export const BASE_URL_PAMB = "https://rtms-uat.prudential.com.sg";
// export const BASE_URL_PHKL = "https://rtms-uat.prudential.com.sg";
// export const BASE_URL_PLUK = "https://rtms-uat.prudential.com.sg";

// export const BASE_URL = "http://10.236.29.187";
// export const BASE_URL_PACS = "http://10.236.29.187";
// export const BASE_URL_PAMB = "http://10.236.29.187";
// export const BASE_URL_PHKL = "http://10.236.29.187";
// export const BASE_URL_PLUK = "http://10.236.29.187";


export const originalUrlPrefix = {
  admin: `${BASE_URL}/admin`,
  pacs: `${BASE_URL_PACS}/pacs`,
  pamb: `${BASE_URL_PAMB}/pamb`,
  phkl: `${BASE_URL_PHKL}/phkl`,
  pluk: `${BASE_URL_PLUK}/pluk`, // add by zhy

};
