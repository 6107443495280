import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Table,
  Empty,
  Icon,
  Tooltip,
  Spin,
  Button
} from "antd";
import {
  detail
} from "../../../../../redux/pacsReducer/systemSettingsReducers/TimeJobRerunReducer";
import styles from "./styles.module.less";

@connect(
  ({
    pacsReducer: { systemSettingsReducers: { TimeJobRerunReducer } },
    PublicReducer: { loading },
  }) => ({
    ...TimeJobRerunReducer,
    globalLoading: loading,
  }),
  {
    detail
  }
)
class TimeJobRerunDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingStart: "",
      currentPageNumber: 1,
      fileList: [],
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "Sequence",
          dataIndex: "jobSequence",
          align: "center",
          width: 100,
          fixed: "left",
          render: (text, record, index) => (
            <Tooltip placement="top" title={index + 1}>
              {index + 1}
            </Tooltip>
          )
        },
        {
          title: "Name",
          dataIndex: "taskName",
          align: "center",
          width: 300,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Start Time",
          dataIndex: "startDateTime",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "End Time",
          dataIndex: "endDateTime",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Job Start Date",
          dataIndex: "startDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Job End Date",
          dataIndex: "endDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
      ]
    };
  }

  componentDidMount() {
    const { sequence, type, bu, taskName } = this.props.location.state;
    const service = bu == "PACS" ? "pacs" : "attendancepacs";
    this.props.detail(sequence, type, service, taskName)
  }

  handleSubmit = () => {

  };

  render() {
    const { history, globalLoading, location } = this.props

    return (
      <Spin spinning={globalLoading}>
        <div className={styles.container}>
          <div className={styles.title}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() => {
                history.push(
                  "/home/system_settings/time_job_rerun/time_job_rerunlist",
                  {
                    search: location.state.search
                  }
                );
              }}
            />
         Agent Detail
          </div>
          <Button
            type="primary"
            onClick={() => {
              const { sequence, type, bu, taskName } = this.props.location.state;
              const service = bu == "PACS" ? "pacs" : "attendancepacs";
              this.props.detail(sequence, type, service, taskName)
            }}
          >Refresh
          </Button>
          <Table
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            bordered
            style={{ marginTop: "20px" }}
            className={styles.ant_dev_table}
            columns={this.state.columns}
            dataSource={this.props.detailList}
            size="small"
            rowKey="jobTaskId"
            scroll={{ x: 1300 }}
          />
        </div>
      </Spin>
    );
  }
}


export default TimeJobRerunDetail;
