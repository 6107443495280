import React from "react";
import { Link } from 'react-router-dom';
import { Tooltip } from "antd";


export const getColumns = () => {
  return [
    {
      title: "Course Name",
      dataIndex: "courseName",
      align: "center",
      width: 300,
      fixed: "left",
      render: (text, record) => (
        <Tooltip placement="top" title={record.courseName}>
          {record.courseName}
        </Tooltip>
      )
    },
    {
      title: "Module Name",
      dataIndex: "moduleName",
      width: 250,
      fixed: "left",
      align: "center",
      render: (text, record) => (
        <Tooltip placement="top" title={record.moduleName}>
          {record.moduleName}
        </Tooltip>
      )
    },
    {
      title: "Session Name",
      dataIndex: "sessionName",
      width: 250,
      align: "center",
    },
    {
      title: "Trainer",
      dataIndex: "instructorName",
      width: 250,
      align: "center"
    },
    {
      title: "Training Date Start",
      dataIndex: "startDate",
      width: 250,
      ellipsis: true,
      align: "center",
      render: text => {
        const startDate =
          text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
        return startDate;
      }
    },
    {
      title: "Training Date End",
      dataIndex: "endDate",
      width: 250,
      ellipsis: true,
      align: "center",
      render: text => {
        const endDate =
          text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
        return endDate;
      }
    },
    {
      title: "Venue",
      dataIndex: "venueName",
      width: 250,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Registered",
      dataIndex: "registeredSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Attended",
      dataIndex: "attendedSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Walk In",
      dataIndex: "walkInSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Completed",
      dataIndex: "completedSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Absent",
      dataIndex: "absentSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Course Completed",
      dataIndex: "courseCompletedSum",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },
    {
      title: "Submit Date Time",
      dataIndex: "submitDate",
      width: 150,
      align: "center",
      render: text => (
        <Tooltip placement="top" title={text}>
          {text}
        </Tooltip>
      )
    },

    {
      title: "Submit By",
      dataIndex: "submitBy",
      width: 150,
      ellipsis: true,
      align: "center"
    },
    {
      title: "Status",
      dataIndex: "submitStatus",
      width: 150,
      ellipsis: true,
      align: "center"
    },
    {
      title: "Option",
      dataIndex: "option",
      ellipsis: true,
      fixed: "right",
      align: "center",
      width: 150,
      render: (text, record) => {
        const pathname = `/home/attendance/session_detail`;
        return (
          <div className="option-area">
            <Link
              to={{
                pathname,
                state: {
                  sessionId: record.sessionId,
                  courseId: record.courseId
                }
              }}
            >
              <div className="option_detail" />
            </Link>
          </div>
        );
      }
    }
  ]
}
