import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Upload, Table, Icon, Spin } from "antd";
import {
  handleUploadAndCheck,
  handleImport,
  handleDoloadTemplate
} from "../../../../../redux/pambReducer/systemSettingsReducers/ImportAgentReducer";
import styles from "./ImportAgent.module.less";
import { file as uploadfileIcon } from "../../../../../images";

@connect(
  ({
    pambReducer: { systemSettingsReducers: { ImportAgentReducer, userManagementReducer } }
  }) => ({
    ImportAgentReducer,
    userManagementReducer
  }),
  { handleUploadAndCheck, handleImport, handleDoloadTemplate }
)
class ImportAgent extends Component {
  state = {
    fileList: [],
    isShowTable: false,
    columnsPamb: [
      {
        title: "S/N",
        dataIndex: "agentId",
        align: "center",
        ellipsis: true,
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "Agent Id",
        dataIndex: "agentId",
        align: "center",
        ellipsis: true
      },
      {
        title: "User",
        dataIndex: "userName",
        align: "center",
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        dataIndex: "roleCode",
        render: (text, record) => {
          const { descriptionList = [] } = record;
          return descriptionList.map(elt => {
            const { color, description } = elt;
            const showcolor = handleColor(color);
            return (
              <div style={{ color: showcolor }} key={description}>
                {description}
              </div>
            );
          });
        }
      }
    ]
  };

  uploadAndCheck = buRole => {
    const { fileList } = this.state;
    const FormDatas = new FormData();
    FormDatas.append("file", fileList[0]);
    const callback = () => this.setState({ isShowTable: true });
    this.props.handleUploadAndCheck(FormDatas, buRole, callback);
  };

  handleImportData = buRole => {
    const {
      uploadBackData: { fileId }
    } = this.props.ImportAgentReducer;
    const callback = () => {
      this.setState({ isShowTable: false, fileList: [] });
    };
    this.props.handleImport({ fileId }, buRole, callback);
  };

  render() {
    const { fileList, columnsPamb, isShowTable } = this.state;
    const {
      ImportAgentReducer: { checkLoading, uploadBackData, importLoading },
      userManagementReducer: { rolerList }
    } = this.props;
    const { agentList = [], containError } = uploadBackData;
    const buRole = rolerList[0] && rolerList[0].country;

    const props = {
      beforeUpload: file => {
        this.setState(() => ({
          fileList: [file],
          isShowTable: false
        }));
        return false;
      },
      fileList,
      showUploadList: false
    };

    return (
      <div className={styles.container}>
        <Spin spinning={importLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            Import Agents
          </div>
          <div className={styles.contentBox}>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>
                {fileList[0] && fileList[0].name}
              </div>
              <Upload {...props}>
                <div className={styles.uploadIconBox}>
                  <img
                    className={styles.uploadIcon}
                    src={uploadfileIcon}
                    alt=""
                  />
                </div>
              </Upload>
              <div className={styles.downloadBox}>
                <Button
                  className={styles.downloadBtn}
                  icon="download"
                  onClick={this.props.handleDoloadTemplate}
                >
                  Download Template
                </Button>
              </div>
            </div>
            <div className={styles.uploadAndCheckBtnBox}>
              <Button
                onClick={() => this.uploadAndCheck(buRole)}
                disabled={!fileList.length}
                loading={checkLoading}
                className={
                  fileList.length ? styles.uploadBtnActive : styles.uploadBtn
                }
              >
                {checkLoading ? "Uploading" : "Upload & Check"}
              </Button>
            </div>
            {isShowTable ? (
              <div className={styles.tabelbox}>
                <Table
                  columns={columnsPamb}
                  dataSource={agentList}
                  size="small"
                  loading={checkLoading}
                  className={styles.ant_dev_table}
                  rowKey="userName"
                  pagination={false}
                  scroll={agentList.length > 6 ? { y: 240 } : {}}
                />
                <div className={styles.importBox}>
                  <Button
                    disabled={containError}
                    className={
                      containError ? styles.importBtn : styles.importBtnActive
                    }
                    onClick={() => this.handleImportData(buRole)}
                  >
                    Import
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </Spin>
      </div>
    );
  }
}

export default ImportAgent;

function handleColor(color) {
  switch (color) {
    case "0":
      return "#D0021B";
    case "1":
      return "#E79000";
    case "2":
      return "#417505 ";
    default:
      return "#666";
  }
}
