import React from "react";
import {
  Form,
  Select,
  Input,
  Button,
  Table,
  Row,
  Col,
  message,
  DatePicker
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCPDsearchData,
  saveFields,
  resetForm
} from "../../../../../redux/pambReducer/CPDSettingsReducers/CPDHistoryReducer";
import { globalPromptTime10s } from "../../../../../utils/global";
import styles from "./CPDHoursSearch.module.less";

const { Option } = Select;
// const { RangePicker } = DatePicker;
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
};

const formLayout2 = {
  labelCol: { span: 12 },
  wrapperCol: { span: 11 }
};

const formLayout3 = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({ pambReducer: { CPDSettingsReducers: { CPDHistoryReducer, commonReducer } } }) => ({
    CPDHistoryReducer,
    commonReducer
  }),
  { getCPDsearchData, saveFields, resetForm }
)
class SearchForm extends React.Component {
  state = {
    type: "",
    status: "",
    nric: "",
    unitCode: "",
    agentId: "",
    agentCode: "",
    currentNum: 1,
    startDate: "",
    endDate: "",
    columns: [
      {
        title: "No",
        dataIndex: "No",
        align: "center",
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "Name",
        dataIndex: "username",
        align: "center",
        render: (text, record) => {
          const {
            type,
            startDate,
            endDate
          } = this.props.CPDHistoryReducer.cpdTemporaryData;
          const { userId, totalCpd } = record;
          const pathname =
            type === "LIAM"
              ? "/home/training_history/cpd_history/liam_agent_detail"
              : "/home/training_history/cpd_history/mta_agent_detail";
          return (
            <Link
              to={{
                pathname,
                state: {
                  userId,
                  type,
                  totalCpd,
                  startDate,
                  endDate
                }
              }}
            >
              {text}
            </Link>
          );
        }
      },
      {
        title: "NRIC Number",
        dataIndex: "nric",
        align: "center"
      },
      {
        title: "Agent ID",
        dataIndex: "agentId",
        align: "center"
      },
      {
        title: "Agent Code",
        dataIndex: "agentCode",
        align: "center"
      },
      {
        title: "Total CPD Hours",
        dataIndex: "totalCpd",
        align: "center"
      }
    ]
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          type,
          status,
          nric,
          unitCode,
          agentId,
          agentCode,
          fromDate,
          toDate
        } = values;

        const atleastont = unitCode || agentId || agentCode || nric;
        if (!atleastont) {
          message.info(
            "Please fill in at least one from NRIC Number, Unit Code, Agent Id and Agent Code",
            globalPromptTime10s
          );
          return null;
        }

        const startDate = fromDate && moment(fromDate).format("YYYY-MM-DD");
        const endDate = toDate && moment(toDate).format("YYYY-MM-DD");
        const submitData = {
          ...values,
          page: 1,
          pageSize: 10,
          startDate,
          endDate
        };
        this.props.getCPDsearchData({ ...submitData });
        this.props.saveFields({ type, status });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const {
      type,
      status,
      nric,
      unitCode,
      agentId,
      agentCode,
      startDate,
      endDate
    } = this.props.CPDHistoryReducer.cpdTemporaryData;

    this.props.saveFields({ currentNum: current });
    this.props.getCPDsearchData({
      type,
      status,
      nric,
      unitCode,
      agentId,
      agentCode,
      pageSize,
      startDate,
      endDate,
      page: current
    });
  };

  handleDateChange = value => {
    if (value) {
      const y = moment(value).format("YYYY");
      const date = moment(value).format("YYYY-MM-DD");
      const startDate = `${y}-01-01`;
      this.props.saveFields({
        startDate,
        toDate: value,
        endDate: date
      });
    } else {
      this.props.saveFields({
        startDate: "",
        endDate: "",
        toDate: undefined
      });
    }
  };

  handleSaveField = (field, val) => {
    this.props.saveFields({ [field]: val });
  };

  handleResetForm = () => {
    this.props.resetForm();
    this.props.form.resetFields();
  };

  render() {
    const {
      form: { getFieldDecorator },
      CPDHistoryReducer: {
        cpdSearchData,
        searchLoading,
        isShowTableCpd,
        cpdTemporaryData
      },
      commonReducer: { reportTypeList, statusList }
    } = this.props;
    const { items = [], total } = cpdSearchData;
    const { columns } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.heading}>CPD History</div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Report Type">
                {getFieldDecorator("type", {
                  initialValue:
                    cpdTemporaryData.type ||
                    (reportTypeList[0] && reportTypeList[0].value)
                })(
                  <Select onChange={val => this.handleSaveField("type", val)}>
                    {reportTypeList.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Status">
                {getFieldDecorator("status", {
                  initialValue:
                    cpdTemporaryData.status ||
                    (statusList[0] && statusList[0].value)
                })(
                  <Select onChange={val => this.handleSaveField("status", val)}>
                    {statusList.map(elt => (
                      <Option value={elt.value} key={elt.value}>
                        {elt.code}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="NRIC Number">
                {getFieldDecorator("nric", {
                  initialValue: cpdTemporaryData.nric
                })(
                  <Input
                    onChange={e => this.handleSaveField("nric", e.target.value)}
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Unit Code">
                {getFieldDecorator("unitCode", {
                  initialValue: cpdTemporaryData.unitCode
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("unitCode", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agent ID">
                {getFieldDecorator("agentId", {
                  initialValue: cpdTemporaryData.agentId
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("agentId", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Agent Code">
                {getFieldDecorator("agentCode", {
                  initialValue: cpdTemporaryData.agentCode
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("agentCode", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={styles.datebox}>
                <Form.Item label="Training Session End Date" {...formLayout2}>
                  {getFieldDecorator("fromDate", {
                    initialValue: cpdTemporaryData.startDate
                  })(<Input disabled placeholder="Start Date" />)}
                </Form.Item>
                <span className={styles.toSpan}>to</span>
                <Form.Item {...formLayout3}>
                  {getFieldDecorator("toDate", {
                    initialValue: cpdTemporaryData.toDate
                  })(
                    <DatePicker
                      placeholder="End Date"
                      onChange={this.handleDateChange}
                    />
                  )}
                </Form.Item>
              </div>
            </Col>
          </Row>
          <div className={styles.searchBtnBox}>
            <Button onClick={this.handleSubmit} className={styles.ant_btn}>
              Search
            </Button>
          </div>
        </Form>
        {isShowTableCpd ? (
          <Table
            columns={columns}
            dataSource={items}
            pagination={{
              total,
              pageSize: 10,
              current: cpdTemporaryData.currentNum
            }}
            size="small"
            loading={searchLoading}
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey="userId"
          />
        ) : null}
      </div>
    );
  }
}

const CPDHoursSearch = Form.create()(SearchForm);

export default CPDHoursSearch;
