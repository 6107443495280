const initState = {
  role: {
    value: ""
  },
  bu: {
    value: ""
  },
  channel: {
    value: ""
  },
  nirc: {
    value: ""
  },
  userName: {
    value: "benjycui"
  },
  email: {
    value: ""
  },
  contactNum: {
    value: ""
  },
  account: {
    value: "nis"
  },
  BDM: {
    value: ""
  },
  GAM: {
    value: ""
  },
  unitCode: {
    value: ""
  },
  agentCode: {
    value: ""
  },
  status: {
    value: ""
  },
  contractedDate: {
    value: ""
  },
  terminationDate: {
    value: ""
  },
  rank: {
    value: ""
  },
  leaderName: {
    value: ""
  },
  NNE: {
    value: ""
  },
  officeAddress: {
    value: ""
  },
  officeBuildin: {
    value: ""
  },
  unitNo: {
    value: ""
  },
  RNFNo: {
    value: ""
  },
  CISLicenseDate: {
    value: ""
  },
  HIdate: {
    value: ""
  },
  priorStartDate: {
    value: ""
  },
  priorEndDate: {
    value: ""
  }
};

// reducer
export default function Agent_PACS_Reducer(state = initState, action) {
  switch (action.type) {
    case "Agent_PACS":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
