import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import QRCode from "qrcode.react";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import {
  init_data,
  save_data,
  getAttendanceScanTimes,
  getCurrentTime
} from "../../../../redux/plukReducer/attendanceReducers/SessionDetailReducer";
import styles from "./styles.module.less";

@connect(
  state => ({
    fields: {
      ...state.plukReducer.attendanceReducers.SessionDetailReducer
    },
    loading: state.PublicReducer.loading
  }),
  {
    changeVals,
    save_data,
    init_data,
    getAttendanceScanTimes,
    getCurrentTime
  }
)
class QRCodePage extends Component {
  constructor(props) {
    super(props);
    this.showQRCode = this.showQRCode.bind(this);
    this.state = {
      timer: null,
      time: 0,
      flash: false,
      timeText: 0,
      scanType: true,
      qrDetail: {},
      ServerTime: 0,
      ScanTimes: 0
    };
  }

  componentDidMount() {
    this.props.getAttendanceScanTimes();
    this.props.getCurrentTime();
    const QRCodeParams = JSON.parse(sessionStorage.getItem("QRCodeParams"));
    const {
      scanType,
      qrDetail,
      ScanTimes,
      venueName
    } = QRCodeParams;
    this.setState(() => ({
      qrDetail,
      scanType,
      ScanTimes,
      venueName
    }));
    this.showQRCode(qrDetail, scanType, ScanTimes, venueName);
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer() {
    const ScanTimes = this.state.ScanTimes;
    this.setState(() => ({
      time: ScanTimes,
      timeText: ScanTimes
    }));
    const timer = setInterval(() => {
      const time = this.state.time - 1;
      if (time < 0) {
        clearInterval(this.state.timer);
        this.freshQR();
      } else {
        this.setState(() => ({
          time,
          timeText: time > 9 ? time : `0${time}`
        }));
      }
    }, 1000);
    this.setState(() => ({
      timer
    }));
  }

  showQRCode(qrDetail, scanType, ScanTimes, venueName) {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
    this.setState(() => ({
      scanType
    }));
    this.setState(() => ({
      qrDetail: {
        ...qrDetail,
        nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
        currentTime: this.props.fields.ServerTime + ScanTimes * 1000,
        expirationTime:
          this.props.fields.ServerTime + ScanTimes * 1000 + ScanTimes * 1000,
        scanType,
        venueName
      }
    }));
    this.setTimer();
  }

  freshQR() {
    this.props.getCurrentTime();
    const { qrDetail, ScanTimes } = this.state;
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
    const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
    const hours = date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`;
    const minutes =
      date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`;
    const seconds =
      date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`;
    this.setState(() => ({
      qrDetail: {
        ...qrDetail,
        nowDate: `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`,
        currentTime: this.props.fields.ServerTime + ScanTimes * 1000,
        expirationTime:
          this.props.fields.ServerTime +
          ScanTimes * 1000 +
          ScanTimes * 1000
      }
    }));
    this.setTimer();
  }

  clearTimer() {
    clearInterval(this.state.timer);
  }

  render() {
    const qrDetail = this.state.qrDetail;

    return (
      <div
        className={styles.container}
        onClick={() => {
          this.clearTimer();
        }}
      >
        <div className={styles.title} style={{ textAlign: "center" }}>
          {qrDetail.scanType ? "Scan In" : "Scan Out"}
        </div>
        <div
          onClick={e => {
            e.stopPropagation();
          }}
          style={{ display: "block" }}
          className={styles.qrContent}
        >
          <div> </div>
          <div className={styles.qrBox}>
            <div className={styles.qrLeft}>
              <div className={styles.qrTimer}>00’{this.state.timeText}”</div>
              <div className={styles.qr}>
                <QRCode
                  renderAs="svg"
                  value={JSON.stringify(qrDetail)}
                  size={450}
                  fgColor="#000000"
                />
              </div>
              <div className={styles.qrText}>{qrDetail.courseName}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(QRCodePage);
