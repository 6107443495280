import { connect } from "react-redux";
import { saveFields } from "../../../redux/phklReducer/commonRenducers/action";

const mapStateToProps = state => {
  return {
    commonRenducers: state.phklReducer.commonRenducers
  };
};

const mapDispatchToProps = {
  saveFields
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true });
