import { message } from "antd";
import { get, post } from "../../../utils/request";

const SAVE = "pamb/ImportCandidatesReducer/SAVE";

const initState = {
  uploadBackData: {},
  checkLoading: false,
  importLoading: false,
  isShowTable: false
};

// reducer
export default function ImportCandidatesReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleUploadAndCheck(paylaod, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        checkLoading: true
      }
    });
    post("/candidate/checktCandidateList", paylaod)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false,
            uploadBackData: data || {}
          }
        });
        callback();
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            checkLoading: false
          }
        });
      });
  };
}

export function handleImport(paylaod, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        importLoading: true
      }
    });
    get("/candidate/importCandidate", paylaod)
      .then(() => {
        const resetData = () => {
          callback();
          dispatch({
            type: SAVE,
            payload: {
              importLoading: false,
              uploadBackData: {}
            }
          });
        };
        message.info("Import successfully", 1, resetData);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            importLoading: false
          }
        });
      });
  };
}
