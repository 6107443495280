import React, {Children, Component} from 'react'
import moment from 'moment';
import {
  Form,
  Row,
  Col,
  Icon,
  Modal,
  Menu,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty,
  Dropdown,
  Checkbox,
  Popconfirm,
  Tag,
  Popover,
} from "antd";
import { connect } from "react-redux";
import { get, isArray, isObject, range, uniq, } from 'lodash';
import styles from './style.module.less'
import {
  SAVE_DATA,
  init_data,
  ONCAHENG_TYPE,
  getRequirements,
  getAgentIds,
  getAgentLeaders,
  getAttendanceReports,
  getRegions,
  getStatus,
  getUnitCodes,
  downLoadExcel,
  fields,
} from "../../../../../../redux/pambReducer/attendanceReportReducers/listReducer";
import { changeVals } from "../../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { ListForm } from './forms'
import { commonColumns, dropdownItem, getSessionColumns, sessionColumns } from './columns'
import { AttendanceReportDownload } from './download'

const monthWidth = 150

@connect(
  state => ({
    listReducer: state.pambReducer.attendanceReportReducers.listReducer,
    fields: state.pambReducer.attendanceReportReducers.listReducer,
    loading: state.PublicReducer.loading,
    roleId: state.userReducer.roleid
  }),
  {
    changeVals,
    getAgentIds,
    getAgentLeaders,
    getAttendanceReports,
    getRegions,
    getStatus,
    getUnitCodes,
    downLoadExcel,
    init_data,
    getRequirements,
  }
)
class AttendanceReportList extends Component {
  state = {
    modalVisible: false,
    requirementFilter: undefined,
    requirementSelected: undefined,
    ...fields,
  };

  componentDidMount() {
    Promise.allSettled([
      this.props.getAgentIds(),
      this.props.getAgentLeaders(),
      this.props.getRegions(),
      this.props.getStatus(),
      this.props.getUnitCodes(),
    ]).then(() => {
      const status = this.props.fields.status.value ? this.props.fields.status : this.props.listReducer.statuses[0]
      this.setState({
        region: this.props.fields.region,
        status,

        reportPeriod: this.props.fields.reportPeriod,
        registrationDate: this.props.fields.registrationDate,

        agentId: this.props.fields.agentId,
        agentLeader: this.props.fields.agentLeader,
        unitCode: this.props.fields.unitCode,
      });
      this.props.getAttendanceReports({
        pageSize: this.props.listReducer.pageSize,
        currentPageNumber: this.props.listReducer.currentPageNumber,

        agentId: this.props.fields.agentId.value,
        agentLeader: this.props.fields.agentLeader.value,
        unitCode: this.props.fields.unitCode.value,
        region: this.props.fields.region.value,
        status: status.value,
        registrationDate: this.getDate(this.props.fields.registrationDate.value),
        reportPeriod: this.getDate(this.props.fields.reportPeriod.value),

        requirementStatuses: this.props.fields.requirementStatuses.value ?? [],

        orderBy: this.props.fields.orderBy.value
      });
    });
  }

  componentWillUnmount() {
    // this.props.init_data()
  }

  getDate = (value) => {
    if (!value) {
      return ''
    }
    return moment(value).format('YYYY-MM')
  }

  getColumns = () => {
    const dateIndex = () => {
      if (this.props.fields.status.value === "1") {
        return "duration";
      } else if (this.props.fields.status.value === "2") {
        return "withdrawDate";
      } else if (this.props.fields.status.value === "3") {
        return "graduationDate";
      } else if (this.props.fields.status.value === "4") {
        return "terminationDate";
      } else {
        return "";
      }
    };
    const columns = [...commonColumns];
    columns.push({
      title: tableData => {
        if (this.props.fields.status.value === "1") {
          return (
            <div style={styles.durationPop}>
              <span style={{marginRight: 6}}>Duration</span>
              <Popover
                autoAdjustOverflow
                placement="top"
                content="The length of time an agent registers into the program; will reflect the duration as of the report period"
                overlayClassName="attendance-report-popover"
                trigger="hover"
              >
                <Icon type="question-circle" />
              </Popover>
            </div>
          );
        } else if (this.props.fields.status.value === "2") {
          return "Withdrawn Date";
        } else if (this.props.fields.status.value === "3") {
          return "Graduation Date";
        } else if (this.props.fields.status.value === "4") {
          return "Termination Date";
        } else {
          return "Date";
        }
      },
      dataIndex: dateIndex(),
      align: "center",
      render: text => {
        const t = isObject(text) ? '' : text
        return (
          <Tooltip placement="top" title={t}>
            {t}
          </Tooltip>
        );
      }
    });
    if (this.props.fields.reportPeriod.value) {
      if (this.props.fields.status.value === "1") {
        columns.push({
          title: "Requirement Fulfillment",
          dataIndex: "fulfilled",
          align: "center",
          filters: [
            { text: "Yes", value: "Y" },
            { text: "No", value: "N" },
            { text: "NA", value: "NA" },
          ],
          filterDropdown: props => {
            const filters = props.filters ?? [];
            const reset = () => {
              if (props.setSelectedKeys) {
                props.clearFilters([]);
              }
            };
            const confirm = () => {
              if (props.confirm) {
                props.confirm();
              }
            };
            const onChange = value => {
              const key = value.target.value.value;
              if (props.setSelectedKeys && value.target.checked) {
                props.setSelectedKeys([key, ...props.selectedKeys]);
                return
              }
              if (!value.target.checked) {
                if (props.selectedKeys.length === 1) {
                  props.clearFilters([]);
                  return
                }
                const index = props.selectedKeys.findIndex(k => k === key);
                props.selectedKeys.splice(index, 1)
                props.setSelectedKeys(props.selectedKeys);
              }
            };
            return (
              <div className={styles.attendanceReportFilters}>
                <div className={styles.checkBox}>
                  {filters.map(f => {
                    return (
                      <Checkbox
                        key={f.value}
                        className={styles.check}
                        checked={
                          props?.selectedKeys?.includes(f.value) ?? false
                        }
                        onChange={onChange}
                        value={f}
                      >
                        {f.text}
                      </Checkbox>
                    );
                  })}
                </div>
                <div className={styles.actionBox}>
                  <Button onClick={reset} type="link">
                    Reset
                  </Button>
                  <Button onClick={confirm} type="primary">
                    OK
                  </Button>
                </div>
              </div>
            );
          },
          render: text => {
            let t = text
            if (!text) {
              t = '--'
            }
            return (
              <Tooltip placement="top" title={t}>
                {t}
              </Tooltip>
            );
          }
        });
      }
      columns.push(
        ...getSessionColumns(
          this.props.roleId,
          this.props.fields.status.value,
        )
      );
    } else {
      columns.push(...this.getOverAllColumns())
    }
    columns.push({
      title: "Action",
      dataIndex: "Action",
      align: "center",
      fixed: "right",
      width: 150,
      render: (text, data, index) => {
        return (
          <div className={styles.action}>
            <Button type="link" onClick={() => this.toDetial(data)}>
              View Details
            </Button>
          </div>
        );
      }
    });
    return columns;
  };

  getMonth = () => {
    const reports = this.props.listReducer.attendanceReports;
    if (!isArray(reports)) {
      return []
    }
    const resMonth = get(reports, '[0].monthFulfillment', undefined)
    if (!resMonth) {
      return []
    }
    const months = Object.keys(resMonth).map(m => Number(m))
    return months.sort((a, b) => { return a > b ? 1 : -1 })
  }

  getOverAllColumns = () => {
    const data = this.props.listReducer.attendanceReports;
    if (!isArray(data) || data.length === 0 || this.props.fields.reportPeriod.value) {
      return []
    }
    const month = this.getMonth()
    if (month.length === 0) {
      return []
    }
    const fulfillment = {
      title: "Requirement Fulfillment For",
      align: "center",
      children: month.map(v => {
        return {
          title: `Month ${v}`,
          dataIndex: "month",
          key: `Month ${v}`,
          align: "center",
          width: monthWidth,
          render: (text, data, index) => {
            const te = get(data, "monthFulfillment", undefined);
            if (!te) {
              return <></>
            }
            return (
              <Tooltip placement="top" title={te[v] ?? '--'}>
                {te[v] ?? '--'}
              </Tooltip>
            );
          }
        }
      })
    };
    return [fulfillment]
  }

  toDetial = data => {
    const type = !this.props.fields.reportPeriod.value ? 'overAll' : 'period'
    if (type === 'period') {
      this.props.history.push('/home/scheme_management/pru_venture_attendance_report/period', {
        registrationId: data.registrationId,
        type,
        reportPeriod: this.getDate(this.props.fields.reportPeriod.value),
        backPath: '/home/scheme_management/pru_venture_attendance_report/list',
      })
    } else if (type === 'overAll') {
      this.props.history.push('/home/scheme_management/pru_venture_attendance_report/overall', {
        registrationId: data.registrationId,
        type,
        backPath: '/home/scheme_management/pru_venture_attendance_report/list',
      })
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const order = get(sorter, "order", "");
    const orders = order === "ascend" ? 1 : -1;
    const requirementStatus = { value: filters.fulfilled }
    this.props.changeVals(ONCAHENG_TYPE, {
      orderBy: { value: orders },
      requirementStatuses: requirementStatus,
    });
    this.props.getAttendanceReports({
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: pagination.current,

      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      status: this.props.fields.status.value,
      registrationDate: this.getDate(this.props.fields.registrationDate.value),
      reportPeriod: this.getDate(this.props.fields.reportPeriod.value),

      requirementStatuses: requirementStatus.value ?? [],
      orderBy: orders
    });
  };

  searchAgentIds = value => {
    this.props.getAgentIds({ search: value });
  };

  searchAgentLeaders = value => {
    this.props.getAgentLeaders({ search: value });
  };

  searchUnitCodes = value => {
    this.props.getUnitCodes({ search: value });
  };

  getData = payload => {
    const param = payload ?? {
      agentId: this.props.fields.agentId.value,
      agentLeader: this.props.fields.agentLeader.value,
      unitCode: this.props.fields.unitCode.value,
      region: this.props.fields.region.value,
      status: this.props.fields.status.value,
      registrationDate: this.getDate(this.props.fields.registrationDate.value),
      reportPeriod: this.getDate(this.props.fields.reportPeriod.value),
      requirementStatuses: this.props.fields.requirementStatuses.value ?? [],
    };
    this.props.getAttendanceReports({
      pageSize: this.props.listReducer.pageSize,
      currentPageNumber: this.props.listReducer.currentPageNumber,
      orderBy: this.props.listReducer.orderBy.value,
      requirementStatuses: this.props.fields.requirementStatuses.value ?? [],
      ...param
    });
  };

  downLoadExcel = (props) => {
    this.props.downLoadExcel("PruVenture Attendance Report.xlsx", {...props});
    this.closeModal()
  };

  changeFields = (data, fields) => {
    this.setState(fields);
  };

  closeModal = () => {
    this.setState({modalVisible: false})
  }

  openModal = () => {
    this.setState({modalVisible: true})
  }

  confirm = data => {
    this.props.changeVals(ONCAHENG_TYPE, {
      agentId: this.state.agentId,
      agentLeader: this.state.agentLeader,
      unitCode: this.state.unitCode,
      region: this.state.region,
      registrationDate: this.state.registrationDate,
      reportPeriod: this.state.reportPeriod,
      status: this.state.status
    });
    this.getData({
      currentPageNumber: 1,
      agentId: this.state.agentId.value,
      agentLeader: this.state.agentLeader.value,
      unitCode: this.state.unitCode.value,
      region: this.state.region.value,
      status: this.state.status.value,
      registrationDate: this.getDate(this.state.registrationDate.value),
      reportPeriod: this.getDate(this.state.reportPeriod.value),
    });
  };

  getScrollX = () => {
    const month = this.getMonth()
    return month.length * monthWidth
  }

  render() {
    const formProps = {
      loading: this.props.loading,
      onChange: this.changeFields,
      statuses: this.props.listReducer.statuses,
      agentIds: this.props.listReducer.agentIds,
      unitCodes: this.props.listReducer.unitCodes,
      regions: this.props.listReducer.regions,
      agentLeaders: this.props.listReducer.agentLeaders,
      roleId: this.props.roleId,
      searchAgentLeaders: this.searchAgentLeaders,
      searchAgentIds: this.searchAgentIds,
      searchAgentUnitCodes: this.searchUnitCodes
    };
    const modalProps = {
      onCancel: this.closeModal,
      onConfirm: this.downLoadExcel,
    }
    return (
      <div className={styles.container}>
        {
          <Spin spinning={this.props.loading}>
            <div className={styles.actionWrap}>
              <ListForm {...this.state} {...formProps}></ListForm>
            </div>
            <div className={styles.actionBox}>
              <div className={styles.apply}>
                <Button
                  type="primary"
                  className={styles.ant_btn}
                  onClick={this.confirm}
                >
                  Search
                </Button>
              </div>
              <div className={styles.add_import}>
                <Button
                  onClick={this.openModal}
                  className={styles.ant_btn_download}
                  icon="download"
                >
                  Download to Excel
                </Button>
              </div>
            </div>
            <div className={styles.table}>
              <Table
                className={styles.ant_dev_table}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description="No Data Found"
                    />
                  )
                }}
                scroll={
                  !this.props.listReducer.reportPeriod.value
                    ? { x: 2500 + this.getScrollX() }
                    : { x: 3200 }
                }
                // className={styles.ant_dev_table}
                columns={this.getColumns()}
                onChange={this.handleTableChange}
                pagination={{
                  total: this.props.listReducer.total,
                  pageSize: this.props.listReducer.pageSize,
                  current: this.props.listReducer.currentPageNumber
                }}
                dataSource={this.props.listReducer.attendanceReports}
                size="small"
                rowKey={(record, index) => {
                  return String(index);
                }}
              />
            </div>
            <Modal
              title="Attendance Report - Agent View Download to Excel"
              visible={this.state.modalVisible}
              onCancel={this.closeModal}
              okButtonProps={{ disabled: this.props.loading }}
              footer={null}
            >
              <div className={styles.modalContainer}>
                <AttendanceReportDownload {...modalProps}/>
              </div>
            </Modal>
          </Spin>
        }
      </div>
    );
  }
}


export default AttendanceReportList;

