import React, { Component } from "react";
import { Select, Row, Col } from "antd";
import { Link } from "react-router-dom";
import styles from "../index.module.less";
import { RED_TRIANGLE } from "../../../../../../../images";
import { handleButtonList } from '../utils';

const { Option } = Select;

export default class ActionBar extends Component {
  render() {
    const { buttonList } = this.props;
    const { importList, createList } = handleButtonList(buttonList);

    return (
      <div className={styles.actionWrap}>
        <Row gutter={[20, 10]} type="flex">
          {importList.length ? (
            <Col>
              <Select
                labelInValue
                defaultValue={{ key: "Import" }}
                className={styles.buttonBox}
                defaultActiveFirstOption={false}
                suffixIcon={
                  <img
                    className={styles.ant_suffixicon}
                    src={RED_TRIANGLE}
                    alt=""
                  />
                }
                dropdownClassName={styles.ant_home_select_drop}
              >
                {importList.map(elt => (
                  <Option key={elt.buttonId}>
                    <Link to={elt.path} className={styles.actionItem}>
                      {elt.name}
                    </Link>
                  </Option>
                ))}
              </Select>
            </Col>
          ) : null}

          {createList.length ? (
            <Col>
              <Select
                labelInValue
                defaultValue={{ key: "Create" }}
                className={styles.buttonBox}
                suffixIcon={
                  <img
                    className={styles.ant_suffixicon}
                    src={RED_TRIANGLE}
                    alt=""
                  />
                }
                defaultActiveFirstOption={false}
                dropdownClassName={styles.ant_home_select_drop}
              >
                {createList.map(elt => (
                  <Option key={elt.buttonId}>
                    <Link to={elt.path} className={styles.actionItem}>
                      {elt.name}
                    </Link>
                  </Option>
                ))}
              </Select>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}
