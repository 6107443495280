export const getColumns = () => {
  return [
    {
      title: "Name",
      align: "center",
      dataIndex: "name"
    },
    {
      title: "User Role",
      align: "center",
      dataIndex: "roleName"
    },
    {
      title: "Login Account",
      align: "center",
      dataIndex: "loginAccount"
    },
    {
      title: "Agent Code",
      align: "center",
      dataIndex: "agentCode"
    },
    {
      title: "Course Name",
      align: "center",
      dataIndex: "courseName"
    },
    {
      title: "Course Code",
      align: "center",
      dataIndex: "courseCode"
    },
    {
      title: "Complete Status",
      align: "center",
      dataIndex: "courseStatus"
    },
    {
      title: "Sequence",
      align: "center",
      dataIndex: "sequence",
      render: text => {
        return text === 0 ? '' : text;
      }
    },
    {
      title: "Completion date/Time on litmos",
      align: "center",
      dataIndex: "completeDate"
    },
    {
      title: "Completion date/Time on DCMS",
      align: "center",
      dataIndex: "iltCompleteDate"
    },
    {
      title: "IA CPD",
      align: "center",
      dataIndex: "laCPD"
    },
    {
      title: "MPF CORE",
      align: "center",
      dataIndex: "mpfCORE"
    },
    {
      title: "MPF NON-CORE",
      align: "center",
      dataIndex: "mpfNoneCORE"
    },
    {
      title: "SFC CPD",
      align: "center",
      dataIndex: "sfcCPD"
    }
  ]
}
