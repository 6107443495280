
import React from "react";
import { Button, Popconfirm } from "antd";

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableData: {
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "#666666"
  },
}

export const getColumns = (deleteRecord, editRecord, courseDetail) => {
  return [
    {
      title: "No",
      width: 80,
      dataIndex: "",
      ellipsis: true,
      align: "center",
      render: (text, item, index) => {
        /* eslint-disable */
        const a = text;
        const b = item;
        /* eslint-disable */
        const c = index + 1;
        return c;
      }
    },
    {
      title: "Login Account",
      dataIndex: "agentId",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Agent Code",
      dataIndex: "agentCode",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Agent Name",
      dataIndex: "agentName",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Unit Code",
      dataIndex: "unitCode",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Scan In Date/Time",
      dataIndex: "scanInDate",
      ellipsis: true,
      align: "center",
      render: text => {
        const scanInDate =
          text && text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
        return scanInDate;
      }
    },
    {
      title: "Scan out Date/Time",
      dataIndex: "scanOutDate",
      ellipsis: true,
      align: "center",
      render: text => {
        const scanOutDate =
          text && text.lastIndexOf(".") > -1 ? text.split(".")[0] : text;
        return scanOutDate;
      }
    },
    {
      title: "Walk in Indicator",
      dataIndex: "isWalkIn",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Complete",
      dataIndex: "completeStatus",
      ellipsis: true,
      align: "center"
    },
    {
      title: "Action",
      dataIndex: "ss",
      ellipsis: true,
      width: 150,
      align: "center",
      render: (text, record) => {
        return (
          <div style={insideStyles.tableData}>
            {text}
            <Button
              type="link"
              onClick={() => {
                editRecord(record, courseDetail);
              }}
            >
              Edit
                </Button>
            <Popconfirm
              autoAdjustOverflow={true}
              placement="left"
              title="Confirm to delete?"
              overlayClassName="deleteBtn"
              onConfirm={() => {
                deleteRecord(record.attendanceId);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">Delete</Button>
            </Popconfirm>
          </div>
        );
      }
    }
  ];
}
