import React from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Table,
  Row,
  Col,
  Select,
  DatePicker,
  Spin
} from "antd";
import { connect } from "react-redux";
import styles from "./TeachingDaysReport.module.less";
import {
  handleQueryDataForTeachingDays,
  getSessionNameList,
  downloadTeachingDaysReport,
  reSetSessionList
} from "../../../../redux/plukReducer/CPDReportReducers/TrainingReportReducer";

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 8,
  md: 8,
  sm: 8,
  xs: 8
};

const flexRow = {
  gutter: [22, 0]
};

const { Option } = Select;
const { RangePicker } = DatePicker;
@connect(
  ({ plukReducer: { CPDReportReducers: { TrainingReportReducer } } }) => ({
    TrainingReportReducer
  }),
  {
    handleQueryDataForTeachingDays,
    getSessionNameList,
    downloadTeachingDaysReport,
    reSetSessionList
  }
)
class TeachingDaysReport extends React.Component {
  state = {
    currentNum: 1,
    isShowTable: false,
    columns: [
      {
        title: "No",
        dataIndex: "no",
        align: "center",
        width: 100,
        fixed: 'left',
        render: (text, record, index) => {
          return <span>{index + 1}</span>;
        }
      },
      {
        title: "Trainer Name",
        dataIndex: "trainerName",
        align: "center",
        width: 200,
        ellipsis: true
      },
      {
        title: "DEPT",
        align: "center",
        dataIndex: "unitCode",
        ellipsis: true
      },
      {
        title: "Type",
        dataIndex: "type",
        align: "center",
        ellipsis: true
      },
      {
        title: "LIAM Contract Date/Join Date",
        dataIndex: "contractedDate",
        align: "center",
        width: 250,
        ellipsis: true,
        render: (text, record) => {
          const { contractedDate } = record;
          const date = contractedDate
            ? moment(contractedDate).format("YYYY-MM-DD")
            : "";
          return <span>{date}</span>;
        }
      },
      {
        title: "SUB-ORDINATE",
        align: "center",
        width: 150,
        dataIndex: "subOrdinate",
        ellipsis: true
      },
      {
        title: "Training Days",
        dataIndex: "trainingDays",
        align: "center",
        width: 150,
        ellipsis: true
      },
      {
        title: "ADHOC",
        dataIndex: "adHoc",
        align: "center",
        width: 100,
        ellipsis: true
      },
      {
        title: "Total(A)+(B)",
        dataIndex: "total",
        align: "center",
        width: 150,
        ellipsis: true
      },
      {
        title: "Quality Objective",
        dataIndex: "qualityObjective",
        align: "center",
        width: 150,
        ellipsis: true
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        width: 150,
        ellipsis: true
      },
      {
        title: "Balance To Achieve(D)-(C)",
        dataIndex: "balanceToAchieve",
        align: "center",
        width: 200,
        ellipsis: true
      },
      {
        title: "Remarks",
        dataIndex: "remark",
        align: "center",
        width: 150,
        ellipsis: true
      }
    ]
  };

  componentWillUnmount() {
    this.props.reSetSessionList();
  }

  handleSearch = e => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          instructorId,
          courseId,
          sessionId,
          faculty,
          courseStatus,
          venueRegion,
          venueName,
          trainingDate,
          unitCode,
          trainerStatus
        } = values;
        const [start, end] = trainingDate || [];
        const startDate = start && moment(start).format("YYYY-MM-DD HH:mm:ss");
        const endDate = end && moment(end).format("YYYY-MM-DD HH:mm:ss");
        this.props.handleQueryDataForTeachingDays({
          ...values,
          currentPageNumber: 1,
          startDate,
          endDate
        });
        this.setState({
          currentNum: 1,
          instructorId,
          courseId,
          sessionId,
          faculty,
          courseStatus,
          venueRegion,
          venueName,
          startDate,
          endDate,
          unitCode,
          trainerStatus,
          isShowTable: true
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current } = pagination;

    const {
      instructorId,
      courseId,
      sessionId,
      faculty,
      courseStatus,
      venueRegion,
      venueName,
      startDate,
      endDate,
      unitCode,
      trainerStatus
    } = this.state;
    this.props.handleQueryDataForTeachingDays({
      instructorId,
      courseId,
      sessionId,
      faculty,
      courseStatus,
      venueRegion,
      venueName,
      startDate,
      endDate,
      unitCode,
      trainerStatus,
      currentPageNumber: current
    });
    this.setState({ currentNum: current });
  };

  handleDownload = () => {
    const {
      instructorId,
      courseId,
      sessionId,
      faculty,
      courseStatus,
      venueRegion,
      venueName,
      startDate,
      endDate,
      unitCode,
      trainerStatus
    } = this.state;
    this.props.downloadTeachingDaysReport({
      instructorId,
      courseId,
      sessionId,
      faculty,
      courseStatus,
      venueRegion,
      venueName,
      startDate,
      endDate,
      unitCode,
      trainerStatus
    });
  };

  handleCourseChange = val => {
    const callback = () =>
      this.setState({
        sessionId: ""
      });
    this.props.getSessionNameList({ courseId: val }, callback);
  };

  render() {
    const {
      form: { getFieldDecorator },
      TrainingReportReducer: {
        teachingDaysData: { data: dataList, total },
        trainerNameList,
        courseList,
        trainerStatusList,
        courseStatusList,
        venueRegionList,
        venueNameList,
        sessionList,
        queryLoading,
        downloadLoading
      }
    } = this.props;

    const { columns, currentNum, isShowTable } = this.state;
    const sessionFirstCode = sessionList.length ? sessionList[0].sessionId : undefined;
    return (
      <div className={styles.container}>
        <Spin spinning={downloadLoading}>
          <div className={styles.heading}>Teaching Days Report</div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Trainer Name">
                  {getFieldDecorator("instructorId")(
                    <Select optionFilterProp="children" showSearch allowClear>
                      {trainerNameList.map(elt => (
                        <Option value={elt.instructorId} key={elt.instructorId}>
                          {elt.instructorName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Course Name">
                  {getFieldDecorator("courseId")(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                      onChange={this.handleCourseChange}
                    >
                      {courseList.map(elt => (
                        <Option value={elt.courseId} key={elt.courseId}>
                          {elt.courseName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Session Name">
                  {getFieldDecorator("sessionId", {
                    initialValue: sessionFirstCode
                  })(
                    <Select
                      optionFilterProp="children"
                      showSearch
                      allowClear
                    >
                      {sessionList.map(elt => (
                        <Option value={elt.sessionId} key={elt.sessionId}>
                          {elt.sessionName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Faculty">
                  {getFieldDecorator("faculty", {})(<Input />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Course Status">
                  {getFieldDecorator("courseStatus")(
                    <Select allowClear>
                      {courseStatusList.map(elt => (
                        <Option value={elt.value} key={elt.value}>
                          {elt.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Trainer Status">
                  {getFieldDecorator("trainerStatus")(
                    <Select allowClear>
                      {trainerStatusList.map(elt => (
                        <Option value={elt.value} key={elt.value}>
                          {elt.code}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {})(<Input />)}
                </Form.Item>
              </Col>
            </Row>

            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Venue Region">
                  {getFieldDecorator("venueRegion")(
                    <Select allowClear>
                      {venueRegionList.map(elt => (
                        <Option
                          value={elt.venueRegionId}
                          key={elt.venueRegionId}
                        >
                          {elt.venueRegionName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Venue Name">
                  {getFieldDecorator("venueName")(
                    <Select allowClear>
                      {venueNameList.map(elt => (
                        <Option value={elt.venueId} key={elt.venueId}>
                          {elt.venueName}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col {...flexCol}>
                <Form.Item label="Training  Date">
                  {getFieldDecorator("trainingDate", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        validator: async (rule, value) => {
                          const [start, end] = value
                          if (start && end) {
                            const range = end.diff(start, 'years', true)
                            if (range > 1) {
                              throw new Error('Maximal range is 1 year');
                            }
                          }
                        },
                      }
                    ],
                  })(
                    <RangePicker
                      showTime={{
                        hideDisabledOptions: true,
                        format: "HH:mm",
                        defaultValue: [
                          moment("00:00", "HH:mm"),
                          moment("23:59", "HH:mm")
                        ]
                      }}
                      format={["YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm"]}
                      placeholder={["Start Time", "End Time"]}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.btnBox}>
            <Button className={styles.ant_btn} onClick={this.handleSearch}>
              Search
            </Button>
          </div>
          {
            isShowTable ?
              <>
                <div className={styles.downloadBox}>
                  <Button
                    className={styles.downloadBtn}
                    icon="download"
                    onClick={this.handleDownload}
                  >
                    Download To Excel
                  </Button>
                </div>
                <Table
                  columns={columns}
                  scroll={{ x: 1900 }}
                  dataSource={dataList || []}
                  pagination={{
                    total,
                    pageSize: 10,
                    current: currentNum
                  }}
                  size="small"
                  loading={queryLoading}
                  onChange={this.handleTableChange}
                  className={styles.ant_dev_table}
                  rowKey="courseId"
                />
              </> : null
          }
        </Spin>
      </div>
    );
  }
}

const TeachingDaysReportForm = Form.create()(TeachingDaysReport);

export default TeachingDaysReportForm;
