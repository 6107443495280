import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Input,
  Tooltip,
  Table,
  notification,
  Icon,
  Modal,
  Popconfirm
} from "antd";
// import moment from "moment";
import { changeVals } from "../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../../utils/global";
import {
  search,
  create,
  update,
  deleteItem,
  init_data,
  getCourseNameListByCode,
  getCourseNameListByName,
  downLoadAgentTrainingCodeExcel,
  downLoadAgentTrainingCodeTxt
} from "../../../../../redux/pambReducer/systemSettingsReducers/CertificationReducer";
import styles from "./styles.module.less";

const EditForm = Form.create({
  name: "PAMBCertification_Edit_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("PAMBCertification", { editFields: { ...props.editFields, ...changedFields } });
  },
  mapPropsToFields(props) {
    const { editFields } = props;
    return {
      codeName: Form.createFormField({
        ...editFields.codeName,
        value: editFields.codeName.value
      }),
      codeCode: Form.createFormField({
        ...editFields.codeCode,
        value: editFields.codeCode.value
      }),
      Exam: Form.createFormField({
        ...editFields.Exam,
        value: editFields.Exam.value
      }),
      Bu: Form.createFormField({
        ...editFields.Bu,
        value: editFields.Bu.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  // const { Option } = Select;
  return (
    <Form
      hideRequiredMark
      layout="horizontal"
      labelCol={{ span: 7 }}
      labelAlign="left"
      wrapperCol={{ span: 17 }}
      colon={false}
    >
      <Row gutter={[24, 0]}>
        <Col>
          <Form.Item label="Course Code">
            {getFieldDecorator("codeCode", {})(
              <Input disabled />
              // <Select
              //   style={{ width: "100%" }}
              //   showSearch
              //   allowClear
              //   placeholder="Course Code"
              //   optionFilterProp="children"
              //   filterOption={(input, option) => {
              //     if (option.props.children.props) {
              //       return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              //     }
              //   }
              //   }
              // >
              //   {props.codeList.length
              //     ? props.codeList.map(item => (
              //       <Option value={item.courseCode} key={item.courseCode}>
              //         <Tooltip placement="top" title={item.courseCode}>
              //           {item.courseCode}
              //         </Tooltip>
              //       </Option>
              //     ))
              //     : null}
              // </Select>
              // <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Course Name">
            {getFieldDecorator("codeName", {})(
              <Input disabled />
              // <Select
              //   style={{ width: "100%" }}
              //   showSearch
              //   allowClear
              //   placeholder="Course Name"
              //   disabled={!props.editFields.codeCode.value}
              //   onDropdownVisibleChange={() => {
              //     props.getCourseNameListByName("", props.editFields.codeCode.value);
              //   }}
              //   onChange={() => {
              //   }}
              //   optionFilterProp="children"
              //   filterOption={(input, option) => {
              //     if (option.props.children.props) {
              //       return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              //     }
              //   }
              //   }
              // >
              //   {props.nameList.length
              //     ? props.nameList.map(item => (
              //       <Option value={item.courseId} key={item.courseId}>
              //         <Tooltip placement="left" title={item.courseName}>
              //           {item.courseName}
              //         </Tooltip>
              //       </Option>
              //     ))
              //     : null}
              // </Select>
              // <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
            )}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Product Code">
            {getFieldDecorator(
              "Exam",
              {}
            )(<Input />)}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="Bu">
            {getFieldDecorator(
              "Bu",
              {}
            )(<Input disabled/>)}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
})

const CreateForm = Form.create({
  name: "PAMBCertification_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("PAMBCertification", changedFields);
  },
  mapPropsToFields(props) {
    return {
      codeName: Form.createFormField({
        ...props.codeName,
        value: props.codeName.value
      }),
      codeCode: Form.createFormField({
        ...props.codeCode,
        value: props.codeCode.value
      }),
      exam: Form.createFormField({
        ...props.exam,
        value: props.exam.value
      }),
      bu: Form.createFormField({
        ...props.bu,
        value: props.bu.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading, columns } = props;
  // const { TextArea } = Input;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Code">
              {getFieldDecorator("codeCode", {})(
                <Input />
                // <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("codeName", {})(
                <Input />
                // <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Product Code">
              {getFieldDecorator(
                "exam",
                {}
              )(<Input />)}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Bu">
              {getFieldDecorator(
                "bu",
                {}
              )(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {/* <div className={styles.btnCol} style={{ display: props.searchList.length ? "block" : "none" }}>
          <Button
            className={styles.btn}
            type="danger"
            icon="download"
            onClick={() => {
              // const courseIdList = Array.from(new Set(props.courseName.value.concat(props.courseCode.value)));
              const courseIdList = [];
              const nowTrainingCodeArr = [...props.trainingCodeArr];
              nowTrainingCodeArr.forEach((item) => {
                let strIdArr = "";
                strIdArr = `${item.courseName}&&${item.trainingCode}`
                courseIdList.push(strIdArr);
              });
              props.downLoadAgentTrainingCodeTxt({
                courseIdList,
                fromCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                toCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                pageSize: props.pageSize,
                currentPageNumber: props.currentPageNumber
              });
            }}
          >
            Download to TXT
          </Button>
          <Button
            className={styles.btn}
            type="danger"
            icon="download"
            onClick={() => {
              // const courseIdList = Array.from(new Set(props.courseName.value.concat(props.courseCode.value)));
              const courseIdList = [];
              const nowTrainingCodeArr = [...props.trainingCodeArr];
              nowTrainingCodeArr.forEach((item) => {
                let strIdArr = "";
                strIdArr = `${item.courseName}&&${item.trainingCode}`
                courseIdList.push(strIdArr);
              });
              props.downLoadAgentTrainingCodeExcel({
                courseIdList,
                fromCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                toCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                pageSize: props.pageSize,
                currentPageNumber: props.currentPageNumber
              });
            }}
          >
            Download to Excel
          </Button>
        </div> */}
        <Button
          className={styles.btn}
          icon="plus"
          style={{ marginBottom: "20px" }}
          onClick={props.showArrBox}
        >
          Add
        </Button>
        <div>
          <Table
            // style={{ display: props.searchList.length ? "block" : "none" }}
            bordered
            dataSource={props.searchList}
            className={styles.ant_dev_table}
            columns={columns}
            // onChange={pagination => {
            //   props.handleTableChange(pagination);
            // }}
            // pagination={{
            //   total: props.total,
            //   pageSize: props.pageSize,
            //   current: props.currentPageNumber
            // }}
            size="small"
            rowKey="index"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pambReducer: { systemSettingsReducers: { CertificationReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: CertificationReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    search,
    create,
    update,
    deleteItem,
    init_data,
    getCourseNameListByCode,
    getCourseNameListByName,
    downLoadAgentTrainingCodeExcel,
    downLoadAgentTrainingCodeTxt
  }
)
class CertificationCodeMap extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showArrBox = this.showArrBox.bind(this);
    this.saveArrBox = this.saveArrBox.bind(this);
    this.closeArrBox = this.closeArrBox.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      showBox: false,
      trainingCodeArr: [],
      saveTrainingCodeArr: [],
      edit: false,
      visible: false,
      columns: [
        {
          title: "Course Code",
          dataIndex: "courseCode",
          align: "center",
          width: 300,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Product Code",
          dataIndex: "exam",
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Bu",
          dataIndex: "bu",
          align: "center",
          width: 150,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Action",
          dataIndex: "canDelete",
          align: "center",
          width: 100,
          render: (text, record) => (
            <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
              {
                text == "0" ? (
                  <Tooltip placement="top">
                    <a
                      onClick={() => {
                        this.showModal(record);
                      }}
                    >
                      Edit
                    </a>
                  </Tooltip>
                ) : (
                  <Tooltip placement="top">
                    <Popconfirm
                      placement="left"
                      title="Code will be re-generated, please rerun the job of the day"
                      onConfirm={() => { this.showModal(record) }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a>Edit</a>
                    </Popconfirm>
                  </Tooltip>
                )
              }
              {
                text == "0" ? (
                  <Tooltip placement="top">
                    <Popconfirm
                      placement="left"
                      title="Are you sure you want to delete it"
                      onConfirm={() => {
                        this.props.deleteItem({ certCourseId: record.certCourseId },
                          () => {
                            const { fields } = this.props;
                            this.props.search({
                              courseCode: fields.codeCode.value,
                              courseName: fields.codeName.value,
                              exam: fields.exam.value,
                              bu: fields.bu.value
                            });
                          })
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <a>Delete</a>
                    </Popconfirm>
                  </Tooltip>
                ) : null
              }
            </div>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.init_data();
    // this.props.search({
    //   courseIdList: [],
    //   fromCompletionDate: "",
    //   toCompletionDate: "",
    //   pageSize: this.state.pageSize,
    //   currentPageNumber: this.state.pageIndex
    // });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      // const { pageSize, pageIndex } = this.state
      if (!err) {
        this.props.search({
          courseCode: fields.codeCode.value,
          courseName: fields.codeName.value,
          exam: fields.exam.value,
          bu: fields.bu.value
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    // const courseIdList = [];
    // const nowTrainingCodeArr = [...this.state.trainingCodeArr];
    // nowTrainingCodeArr.forEach((item) => {
    //   let strIdArr = "";
    //   strIdArr = `${item.courseName}&&${item.trainingCode}`
    //   courseIdList.push(strIdArr);
    // });

    const { fields } = this.props;

    this.props.search({
      courseCode: fields.codeCode.value,
      courseName: fields.codeName.value,
      exam: fields.exam.value,
      pageSize,
      currentPageNumber: current,
      bu: fields.bu.value
    });
    // this.props.search({
    //   courseIdList,
    //   fromCompletionDate: fields.completuonDate.value.length
    //     ? fields.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
    //     : "",
    //   toCompletionDate: fields.completuonDate.value.length
    //     ? fields.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
    //     : "",
    //   pageSize,
    //   currentPageNumber: current
    // });
  };

  showModal = (record) => {
    console.log("record:", record); // eslint-disable-line
    this.props.getCourseNameListByCode("");
    this.props.changeVals("PAMBCertification", {
      editFields: {
        codeName: {
          value: record.courseName
        },
        codeCode: {
          value: record.courseCode
        },
        Exam: {
          value: record.exam
        },
        Bu: {
          value: record.bu
        },
        ...record
      },
    });
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    const { editFields } = this.props.fields
    console.log("editFields:", editFields) // eslint-disable-line
    this.props.update({
      certCourseId: editFields.certCourseId,
      courseId: editFields.courseId,
      exam: editFields.Exam.value,
      bu: editFields.Bu.value,
    }, () => {
      const { fields } = this.props;
      this.props.search({
        courseCode: fields.codeCode.value,
        courseName: fields.codeName.value,
        exam: fields.exam.value,
        bu: fields.bu.value
      });
    });
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  saveArrBox() {
    const nowTrainingCodeArr = [...this.state.trainingCodeArr];
    const postData = []
    const FilterArr = []
    /* eslint-disable */
    for (let i = 0; i < nowTrainingCodeArr.length; i++) {
      if (!nowTrainingCodeArr[i].courseCode || !nowTrainingCodeArr[i].trainingCode || !nowTrainingCodeArr[i].courseName || !nowTrainingCodeArr[i].bu) {
        notification.error({
          message: "Please fill in the mandatory fields",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
        return;
      }

      if (nowTrainingCodeArr[i].courseName == nowTrainingCodeArr[i].trainingCode) {
        notification.error({
          message: "The Product Code is an invalid value",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
        return;
      }

      const nowItem = `${nowTrainingCodeArr[i].courseName}_${nowTrainingCodeArr[i].trainingCode}`;
      if (FilterArr.indexOf(nowItem) > -1) {
        notification.error({
          message: "Duplicate values are not allowed",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
        return;
      }
      else {
        FilterArr.push(`${nowTrainingCodeArr[i].courseName}_${nowTrainingCodeArr[i].trainingCode}`);
      }
      postData.push({
        courseId: nowTrainingCodeArr[i].courseName,
        exam: nowTrainingCodeArr[i].trainingCode,
        bu: nowTrainingCodeArr[i].bu
      })
      console.log("nowTrainingCodeArr:", nowTrainingCodeArr)
      console.log("postData:", postData);
    }

    /* eslint-disable */

    // /* eslint-disable */
    // const FilterArr = [];
    // for (let x = 0; x < nowTrainingCodeArr.length; x++) {
    //   if (FilterArr.indexOf(nowTrainingCodeArr[x].courseName) < 0) {
    //     FilterArr.push(nowTrainingCodeArr[x].courseName);
    //   } else {
    //     if (nowTrainingCodeArr[x].courseName) {
    //       notification.error({
    //         message: "Course Name already exists",
    //         duration: globalPromptTime10s,
    //         className: "ant-style-notification-error"
    //       });
    //       nowTrainingCodeArr[index].courseName = "";
    //       nowTrainingCodeArr[index].showCourseName = "";
    //       break;
    //     }
    //   }
    // }
    // /* eslint-disable */

    this.props.create(postData,
      () => {
        const { fields } = this.props;
        this.props.search({
          courseCode: fields.codeCode.value,
          courseName: fields.codeName.value,
          exam: fields.exam.value,
          bu: fields.bu.value
        });
      }
    );
    this.setState(() => ({
      showBox: false,
    }));
    // let valStr = "";
    // nowTrainingCodeArr.forEach((item) => {
    //   if (!valStr) {
    //     valStr += `${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
    //   } else {
    //     valStr += ` , ${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
    //   }
    // });
    // this.setState(() => ({
    //   showBox: false,
    //   saveTrainingCodeArr: nowTrainingCodeArr
    // }));
    // this.props.changeVals("PAMBCertification", {
    //   codeName: {
    //     value: valStr
    //   }
    // });
  }

  showArrBox() {
    this.props.getCourseNameListByCode("");
    const saveTrainingCodeArr = [...this.state.saveTrainingCodeArr];
    if (!saveTrainingCodeArr.length) {
      this.setState(() => ({
        showBox: true,
        trainingCodeArr: [
          {
            courseName: "",
            courseCode: "",
            trainingCode: "",
            showCourseName: "",
            showCourseCode: "",
            bu: ""
          }
        ]
      }));
    } else {
      this.setState(() => ({
        showBox: true,
        trainingCodeArr: saveTrainingCodeArr
      }));
    }
  }

  closeArrBox() {
    this.setState(() => ({
      showBox: false,
      trainingCodeArr: [...this.state.saveTrainingCodeArr]
    }));
  }

  render() {
    const { Option } = Select;
    const { nameList, codeList } = this.props.fields;
    const { trainingCodeArr, showBox, saveTrainingCodeArr } = this.state;
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      getCourseNameListByName: this.props.getCourseNameListByName,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange,
      downLoadAgentTrainingCodeExcel: this.props.downLoadAgentTrainingCodeExcel,
      downLoadAgentTrainingCodeTxt: this.props.downLoadAgentTrainingCodeTxt,
      showArrBox: this.showArrBox,
      trainingCodeArr: this.state.trainingCodeArr,
    };
    
    return (
      <div className={styles.container}>
        <Modal
          title="Update"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <EditForm {...props} {...this.props.fields} />
        </Modal>
        {
          showBox ?
            <div className={styles.showBox}>
              <div style={{ marginBottom: "20px", width: "100%", display: "flex", justifyContent: "space-between", color: "#ed1b2e", alignItems: "center" }}>
                <Button
                  type="primary"
                  style={{ width: "120px" }}
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={() => {
                    const nowTrainingCodeArr = [...trainingCodeArr];
                    nowTrainingCodeArr.push({
                      courseName: "",
                      courseCode: "",
                      trainingCode: "",
                      showCourseName: "",
                      showCourseCode: "",
                      bu: ""
                    });
                    this.setState(() => ({
                      trainingCodeArr: nowTrainingCodeArr
                    }), () => {
                    });
                  }}
                >
                  Add Course
              </Button>
                <Icon
                  type="close-circle"
                  style={{ marginRight: 15, cursor: "pointer", fontSize: "25px" }}
                  onClick={this.closeArrBox}
                />
              </div>
              <div className={styles.showBoxContent}>
                <div className={styles.formCol} style={{ display: trainingCodeArr.length ? "flex" : "none" }}>
                  <div style={{ width: "200px" }}>Course Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "200px" }}>Course Name <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "150px" }}>Product Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "255px" }}>Bu <span style={{ color: "#ed1b2e" }}>*</span></div>
                </div>
                {
                  trainingCodeArr.map((item, index) => {
                    return (
                      <div className={styles.formCol} key={index}>
                        <Select
                          style={{ width: "200px" }}
                          showSearch
                          allowClear
                          placeholder="Course Code"
                          value={item.courseCode}
                          onDropdownVisibleChange={() => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr[index].showCourseName = "";
                            nowTrainingCodeArr[index].courseName = "";
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr,
                            }), () => {
                              this.props.changeVals("PAMBCertification", {
                                nameList: []
                              });
                            });
                          }}
                          onChange={(val, oo) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            const showCourseCode = oo ? oo.props.children.props.title : "";
                            nowTrainingCodeArr[index].courseCode = val;
                            nowTrainingCodeArr[index].showCourseCode = showCourseCode;
                            this.props.getCourseNameListByName("", showCourseCode,
                              (data) => {
                                nowTrainingCodeArr[index].courseCode = val;
                                nowTrainingCodeArr[index].showCourseCode = showCourseCode;
                                nowTrainingCodeArr[index].courseName = data[0].courseId;
                                nowTrainingCodeArr[index].showCourseName = data[0].courseName;
                                this.setState(() => ({
                                  trainingCodeArr: nowTrainingCodeArr
                                }));
                              });
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option.props.children.props) {
                              return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          }
                          }
                        >
                          {codeList.length
                            ? codeList.map(item => (
                              <Option value={item.courseCode} key={item.courseCode}>
                                <Tooltip placement="top" title={item.courseCode}>
                                  {item.courseCode}
                                </Tooltip>
                              </Option>
                            ))
                            : null}
                        </Select>

                        <Select
                          style={{ width: "200px" }}
                          showSearch
                          allowClear
                          placeholder="Course Name"
                          value={item.showCourseName}
                          disabled
                        // disabled={!trainingCodeArr[index].courseCode}
                        // onDropdownVisibleChange={() => {
                        // }}

                        // onChange={(val, oo) => {
                        //   const nowTrainingCodeArr = [...trainingCodeArr];
                        //   const showCourseName = oo ? oo.props.children.props.title : "";
                        //   nowTrainingCodeArr[index].courseName = val;
                        //   nowTrainingCodeArr[index].showCourseName = showCourseName;

                        //   this.setState(() => ({
                        //     trainingCodeArr: nowTrainingCodeArr
                        //   }));
                        // }}
                        // optionFilterProp="children"
                        // filterOption={(input, option) => {
                        //   if (option.props.children.props) {
                        //     return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        //   }
                        // }
                        // }
                        >
                          {/* {nameList.length
                            ? nameList.map(item => (
                              <Option value={item.courseId} key={item.courseId}>
                                <Tooltip placement="left" title={item.courseName}>
                                  {item.courseName}
                                </Tooltip>
                              </Option>
                            ))
                            : null} */}
                        </Select>
                        <Input
                          style={{ width: "150px" }}
                          placeholder="Product Code"
                          value={item.trainingCode}
                          allowClear
                          onChange={(val) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr[index].trainingCode = val.target.value;
                            // nowTrainingCodeArr[index].courseName = nameList.length ? nameList[0].courseId : "";
                            // nowTrainingCodeArr[index].showCourseName = nameList.length ? nameList[0].courseName : "";
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }), () => {
                              console.log("trainingCodeArr", this.state.trainingCodeArr) // eslint-disable-line
                            })
                          }}
                        />
                        <Select
                          style={{ width: "150px" }}
                          showSearch
                          allowClear
                          placeholder="Bu"
                          value={item.bu}
                          onChange={(val, oo) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr[index].bu = val;
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }));
                          }}
                          optionFilterProp="children"
                        >
                          <Option value="PAMB" key="PAMB">
                            <Tooltip placement="top" title="PAMB">
                              PAMB
                            </Tooltip>
                          </Option>
                          <Option value="PBTB" key="PBTB">
                            <Tooltip placement="top" title="PBTB">
                             PBTB
                            </Tooltip>
                          </Option>
                        </Select>
                        {/* <div style={{ width: "100px", display: index !== 0 ? "none" : "inline-block" }}></div> */}
                        <Button
                          type="primary"
                          className={`${styles.btnColor} ${styles.btnText}`}
                          style={{ width: "100px" }}
                          onClick={() => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr.splice(index, 1);
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }))
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )
                  })
                }
                <div style={{ width: "100%", justifyContent: "center", marginTop: "20px", display: 'flex' }}>
                  <Button
                    type="primary"
                    style={{ width: "100px", marginRight: "20px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={this.saveArrBox}
                  >
                    Save
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{ width: "100px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={() => {
                      this.setState(() => ({
                        showBox: false
                      }))
                    }}
                  >
                    Cancel
                  </Button> */}
                </div>
              </div>
            </div> : null
        }
        <div className={styles.title}>Certification Code</div>
        <CreateForm {...props} {...this.props.fields} />
        {/* <div className={styles.col_dev_left_short}>
        </div> */}
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "rgb(38, 98, 208)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};
const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 24,
  xs: 24
};

export default withRouter(CertificationCodeMap);
