import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INITDATA_COURSERATINGSUMMARY_REPORT = "PACS/INITDATA_COURSERATINGSUMMARY_REPORT";
const SAVE_COURSERATINGSUMMARY_REPORT_DATA = "PACS/SAVE_COURSERATINGSUMMARY_REPORT_DATA";

const initState = {
  courseName: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  trainingDate: {
    value: ""
  },
  sessionId: "",
  qIndex: 0,
  courseList: [],
  sessionList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1,

  searchShortList: [],
  sTotalPage: 0,
  sTotal: 0,
  sPageSize: 10,
  sCurrentPageNumber: 1,
  sQIndex: 0,
};

export default function CourseRatingSummaryReportPACSReducer(state = initState, action) {
  switch (action.type) {
    case "CourseRatingSummaryReportPACS":
      return { ...state, ...action.payload };
    case INITDATA_COURSERATINGSUMMARY_REPORT:
      return { ...initState };
    case SAVE_COURSERATINGSUMMARY_REPORT_DATA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_COURSERATINGSUMMARY_REPORT
  };
}

export function save_data(payload) {
  return {
    type: SAVE_COURSERATINGSUMMARY_REPORT_DATA,
    payload
  };
}
// GET /surveyReport/getCourseNameList
export function getCourseNameList() {
  return dispatch => {
    get("/surveyReport/getCourseNameList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              courseList: res.data.length ? [...res.data] : [],
              // courseName: {
              //   value: res.data.length ? res.data[0].courseId : ""
              // }
            })
          );
          // if (res.data.length) {
          //   dispatch(getSessionNameList(res.data[0].courseId));
          // }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /surveyReport/getSessionNameList
export function getSessionNameList(courseId) {
  return dispatch => {
    get("/surveyReport/getSessionNameList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionName: {
                value: ""
              },
              sessionList: [...res.data],
              // sessionName: {
              //   value:
              //     res.data.length && res.data[0] !== null
              //       ? res.data[0].sessionId
              //       : ""
              // }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /surveyReport/getSurveySummaryReport
export function getSurveySummaryReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/surveyReport/getSurveySummaryReport", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          let qIndex = 0;
          const filterColumns = [];
          const searchList = [...res.data.data];
          searchList.map((item) => {
            item.questionList.forEach((_item) => {
              if(filterColumns.indexOf(_item.questionName)<0){
                qIndex++;
                filterColumns.push(_item.questionName)
              }
              for (let x in _item) {
                if (x !== "questionName" && x !== "showName") {
                  item[`${x}_${_item.questionName}`] = _item[x];
                }
              };
            });
            return item;
          });
          // console.log("searchList:", searchList);
          dispatch(
            save_data({
              searchList,
              qIndex,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /surveyReport/getSurveyDetailReport
export function getSurveyDetailReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/surveyReport/getSurveyDetailReport", data)
      .then(res => {
        if (res.success) {
          let sQIndex = 0;
          const searchShortList = [...res.data.data];
          const searchShortListTest = [
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "adcan65001@yopmail.com",
              "agentName": "adcan65001",
              "userId": "ri8Hw2uVAe01",
              "overAllRating": "16",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "mmnhfddss"
                }
              ]
            },
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "adcan65002@yopmail.com",
              "agentName": "adcan65002",
              "userId": "LkTYd02DMCU1",
              "overAllRating": "17",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "6"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "7"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "8"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "mmnhfddss"
                }
              ]
            },
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "FC42901",
              "agentName": "pdc1",
              "userId": "2hz-cmmuCpk1",
              "overAllRating": "19",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "mmnhfddss"
                }
              ]
            },
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "FC42902",
              "agentName": "pdc2",
              "userId": "GPzc_vggiuw1",
              "overAllRating": "21",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "mmnhfddss"
                }
              ]
            },
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "FC42911",
              "agentName": "pda1",
              "userId": "7rGfGQlg2Us1",
              "overAllRating": "19",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "mmnhfddss"
                }
              ]
            },
            {
              "courseName": "Kris-Survey-Test",
              "sessionName": "Session 1",
              "trainer": null,
              "startDate": "06/06/2020",
              "endDate": "06/06/2020",
              "venue": "Kris Zheng-Venue Name ",
              "unitCode": "A01",
              "loginAccount": "guest0605001@yopmail.com",
              "agentName": "guest0605001",
              "userId": "P8WpkfZO10Q1",
              "overAllRating": "24",
              "questionList": [
                {
                  "questionName": "Kris - Over View Q5",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q3",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Overall Feedback QA1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "2"
                },
                {
                  "questionName": "Kris - Over View Q1",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "5"
                },
                {
                  "questionName": "Kris - Over View Q2",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "4"
                },
                {
                  "questionName": "Kris - Over View Q4",
                  "questionType": "6",
                  "showName": "Q 1 Rating",
                  "answer": "3"
                },
                {
                  "questionName": "Kris - Comments QA1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "fhhnjbnnbb"
                },
                {
                  "questionName": "Kris - multi QA1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "dsfg"
                },
                {
                  "questionName": "Kris - Single QA1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "sdfs"
                },
                {
                  "questionName": "Kris - Single Option Q1",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "CCC"
                },
                {
                  "questionName": "Kris - Single Option Q2",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "AAA"
                },
                {
                  "questionName": "Kris - Multiple Option Q1",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "fafdds,sdfds"
                },
                {
                  "questionName": "Kris - Multiple Option Q2",
                  "questionType": "2",
                  "showName": "Multiple Option 1",
                  "answer": "sdfs,asdfs"
                },
                {
                  "questionName": "Kris - Comments Q1",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "dhjjjmnmk"
                },
                {
                  "questionName": "Kris - Comments Q2",
                  "questionType": "1",
                  "showName": "Comments 1",
                  "answer": "chnnmmm"
                },
                {
                  "questionName": "Kris - Single Option Q3",
                  "questionType": "3",
                  "showName": "Single Option 1",
                  "answer": "fgjgf"
                },
                {
                  "questionName": "Kris - Comments Q3",
                  "questionType": "1",
                  "showName": "Comments 1",
                }
              ]
            }
          ];
          searchShortList.map((item,index) => {
            sQIndex = item.questionList.length;
            const trainer = item.trainer.replace(/\s/g, "");
            item.keyId =`${item.userId}_${index}_${trainer}`;
            item.questionList.forEach((_item) => {
              const name = _item.questionName.replace(/\s/g, "");
              item[`${name}_answer`] = _item.answer;
            });
            return item;
          });
          console.log("searchShortList:", searchShortList);
          dispatch(
            save_data({
              searchShortList,
              sQIndex,
              sTotalPage: res.data.totalPage,
              sTotal: res.data.total,
              sPageSize: res.data.pageSize,
              sCurrentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /surveyReport/downloadSurveySummaryReport
export function downloadSurveySummaryReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/surveyReport/downloadSurveySummaryReport", data, {}, "blob")
      .then(blob => {
        const fileName = "SurveySummaryReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}

// POST /surveyReport/downloadSurveyDetailReport
export function downloadSurveyDetailReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/surveyReport/downloadSurveyDetailReport", data, {}, "blob")
      .then(blob => {
        const fileName = "SurveyDetailReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
