import React, { Component } from 'react';
import { message, Select, Modal } from "antd";
import styles from "./index.module.less";
import { get, post } from '../../../../../utils/request';

const { Option } = Select;

export default class HouseKeeping extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: null,
    };
  }

  componentDidMount() {
    this.getHouseKeeping();
  }

  async getHouseKeeping() {
    try {
      const res = await get('/housekeeping/retention-period');
      if (res && res.success) {
        const { retentionPeriod } = res.data;
        this.setState({
          value: retentionPeriod
        })
      }
    } catch (err) {
      message.error("Operation Failed", 3);
    }
  }

  async udpateHouseKeeping(retentionPeriod) {
    try {
      const res = await post('/housekeeping/retention-period', { retentionPeriod });
      if (res && res.success) {
        this.setState({
          value: retentionPeriod
        })
        message.success("Operation successful", 5);
      }
    } catch (err) {
      message.error("Operation Failed", 3);
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>House Keeping Setting</div>
        <div>
          Terminated agents to be purged from system
          <Select
            className={styles.select}
            value={this.state.value}
            placeholder="Select a option"
            onChange={(val) => {
              Modal.confirm({
                title: 'House keeping settings',
                content: `Terminated agents with termination date > ${val} years will be deleted, are you sure to make this change?`,
                cancelText: 'No',
                okText: 'Yes',
                onOk: () => this.udpateHouseKeeping(val)
              });
            }}
          >
            {[1, 2, 3, 4, 5, 6, 7].map(elt => (
              <Option
                value={elt}
                key={elt}
                name={elt}
                code={elt}
              >
                {elt}
              </Option>
            ))}
          </Select>
          years after termination date. Related training data will be Removed.
        </div>
      </div>
    )
  }
}
