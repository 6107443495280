import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Tooltip,
  Table,
  notification,
  DatePicker
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pclaReducer/systemSettingsReducers/index.action";
import {
  getCourseList,
  getSessionList,
  getSessionDayList,
  getRegionList,
  searchDeductionReport,
  download_deduction
} from "../../../../redux/pclaReducer/CPDReportReducers/DeductionReportReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateDeductionReport_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("DeductionReport", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      sessionDayName: Form.createFormField({
        ...props.sessionDayName,
        value: props.sessionDayName.value
      }),
      region: Form.createFormField({
        ...props.region,
        value: props.region.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { loading, columns } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={e => {
                    props.getSessionList(e);
                  }}
                >
                  {props.courseList.length
                    ? props.courseList.map(item => (
                      <Option value={item.id} key={item.code}>
                        <Tooltip placement="top" title={item.name}>
                          {item.name}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <Select
                  filterOption={false}
                  defaultActiveFirstOption={false}
                >
                  {props.sessionList.length
                    ? props.sessionList.map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.name}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row>
          <Col {...flexCol}>
            <Form.Item label="Region">
              {getFieldDecorator(
                "region",
                {}
              )(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.regionList.length
                    ? props.regionList.map(item => (
                      <Option value={item} key={item}>
                        {item}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item
              labelCol={{ span: 5 }}
              label="Training Session End Date"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("trainingDate", {})(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    format: "HH:mm",
                    defaultValue: [
                      moment("00:00", "HH:mm"),
                      moment("23:59", "HH:mm")
                    ]
                  }}
                  placeholder={["From Date", "To Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm", "YYYY-MM-DD HH:mm"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
          style={{ display: props.showList ? "block" : "none" }}
        >
          {" "}
        </div>
        <Button
          className={styles.btn}
          style={{ display: props.showList ? "block" : "none" }}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_deduction();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
            display: props.showList ? "block" : "none"
          }}
        >
          <div className={styles.deductionTableBottom}>
            <div className={styles.TableBottom_left}>
              <i></i>Deduction Installment:&nbsp;
              <span>{props.installment}</span>
            </div>
            <div className={styles.TableBottom_right}>
              <div>
                <i></i> Gross excl SST:&nbsp;<span>{props.grossTotal}</span>
              </div>
              <div>
                <i></i> SST Amount:&nbsp;<span>{props.sstTotal}</span>
              </div>
            </div>
          </div>
          <Table
            bordered
            scroll={{ x: 1600 }}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pclaReducer: { CPDReportReducers: { DeductionReportReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: DeductionReportReducer,
    loading
  }),
  {
    changeVals,
    getCourseList,
    getSessionList,
    getRegionList,
    searchDeductionReport,
    download_deduction,
    getSessionDayList
  }
)
class DeductionReport extends Component {
  constructor(props) {
    super(props);
    this.download_deduction = this.download_deduction.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      currentPageNumber: 1,
      shortColumns: [
        {
          title: "Registered",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Absent",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Attended",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Completed",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Walk In",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ],
      columns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          width: 100,
          fixed: "left",
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Agent Code",
          dataIndex: "agencyCode",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent Name",
          dataIndex: "agentName",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Gross excl SST",
          dataIndex: "grossExclSST",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Remark",
          dataIndex: "remark",
          ellipsis: true,
          align: "center",
          width: 600,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "SST Indicator",
          dataIndex: "sstIndicator",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "SST Amount",
          dataIndex: "sstAmount",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.getCourseList();
    this.props.getRegionList();
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.trainingDate.value.length) {
          const year = new Date().getFullYear();
          /* eslint-disable */
          let seconds = 0;
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            seconds = 31643326;
          } else {
            seconds = 31556926;
          }
          /* eslint-disable */
          const start = new Date(
            fields.trainingDate.value[0].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const end = new Date(
            fields.trainingDate.value[1].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const between = Math.round(end / 1000) - Math.round(start / 1000);
          if (seconds < between) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        this.setState(() => ({
          currentPageNumber: 1
        }));
        this.props.searchDeductionReport({
          courseId: fields.courseName.value,
          region: fields.region.value,
          sessionId:
            fields.sessionName.value === "none" ? "" : fields.sessionName.value,
          fromdate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          todate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : ""
        });
      }
    });
  };

  download_deduction() {
    const { fields, download_deduction } = this.props;
    download_deduction({
      courseId: fields.courseName.value,
      region: fields.region.value,
      sessionId:
        fields.sessionName.value === "none" ? "" : fields.sessionName.value,
      sessionDayId: fields.sessionDayName.value,
      fromdate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      todate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : ""
    });
  }

  handleTableChange = pagination => {
    const { current } = pagination;
    this.setState(() => ({
      currentPageNumber: current
    }));
  };

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      shortColumns: this.state.shortColumns,
      getSessionList: this.props.getSessionList,
      getSessionDayList: this.props.getSessionDayList,
      download_deduction: this.download_deduction,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Deduction Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

const flexCol = {
  xxl: 10,
  xl: 10,
  lg: 10,
  md: 12,
  sm: 12,
  xs: 12
};
export default DeductionReport;
