import { post } from "../../../utils/request";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pacs/SFAReportReducer/SAVE";

const initState = {
  sfaBackData: {},
  distributionList: [
    { code: '', value: 'All' },
    { code: 'AD', value: 'AD' },
    { code: 'PD', value: 'PD' }
  ],
  courseTypeList: [
    { code: '', value: 'All' },
    { code: '0', value: 'Classroom' },
    { code: '1', value: 'E-Course' }
  ],
  courseStatusList: [
    { code: '', value: 'All' },
    { code: '1', value: 'Completed' },
    { code: '0', value: 'Incompleted' }
  ],
  agentStatusList: [
    { code: '', value: 'All' },
    { code: '2', value: 'Active' },
    { code: '1', value: 'Inactive' }
  ],
  tableLoading: false,
  downloadLoading: false
};

// reducer
export default function SFAReportReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleQueryData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/sfaReport/getSfaReportList", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false,
            sfaBackData: data || {}
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export const downloadData = (payload) => {
  return async dispatch => {
    await handleCheckToken()
    dispatch({
      type: SAVE,
      payload: {
        downloadLoading: true
      }
    });
    post("/sfaReport/downloadSfaReport", payload, {}, "blob")
      .then(res => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
        const fileName = "SFA Report.csv";
        downloadFile(res, fileName);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            downloadLoading: false
          }
        });
      });
  };
}
