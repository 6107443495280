import React from "react";
import moment from "moment";
import { Form, Button, DatePicker, Row, Col } from 'antd'
import { connect } from "react-redux";
import styles from "./ItemWrap.module.less";
import { handleSubmit } from "../../../../../redux/pambReducer/systemSettingsReducers/TimeJobReducer";

const { RangePicker } = DatePicker

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 8,
  md: 8,
  sm: 8,
  xs: 8
};

const flexRow = {
  gutter: [22, 0]
};

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

@connect(
  ({ pambReducer: { systemSettingsReducers: { TimeJobReducer } } }) => ({
    TimeJobReducer
  }),
  { handleSubmit }
)
class ItemWrap extends React.Component {
  handleSend = (url) => {
    const { form } = this.props
    form.validateFields(null, { first: true }, (err, values) => {
      if (!err) {
        const { rangeDate, fromDate } = values
        const [start, end] = rangeDate || [];
        const startDate = start && moment(start).format("YYYY-MM-DD");
        const endDate = end && moment(end).format("YYYY-MM-DD");
        const from = fromDate && moment(fromDate).format("YYYY-MM-DD");
        const submitData = {
          startDate: startDate || from,
          endDate
        }
        this.props.handleSubmit({ ...submitData }, url)
      }
    });
  };

  render() {
    const { form: { getFieldDecorator }, itemprop: { title, url, index, hasRangeDate, hasStartDate } } = this.props
    return (
      <div className={styles.box}>
        <Form
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <div className={styles.title}>{`${index + 1}. ${title}`}</div>
          <Row {...flexRow}>
            {
              hasStartDate ?
                <Col {...flexCol}>
                  <Form.Item label="Start Date">
                    {getFieldDecorator("fromDate", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        }
                      ],
                    })(
                      <DatePicker className={styles.ant_dev_dataPicker} />
                    )}
                  </Form.Item>
                </Col>
                : null
            }

            {
              hasRangeDate ?
                <Col {...flexCol}>
                  <Form.Item label="Range Date">
                    {getFieldDecorator("rangeDate", {
                      rules: [
                        {
                          required: true,
                          message: "Please fill in the mandatory fields"
                        },
                        {
                          validator: async (rule, value) => {
                            const [start, end] = value
                            if (start && end) {
                              const range = end.diff(start, 'days', true)
                              if (range > 7) {
                                throw new Error('Maximal range is 7 days');
                              }
                            }
                          },
                        }
                      ],
                    })(
                      <RangePicker
                        format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                        placeholder={["Start Time", "End Time"]}
                      />
                    )}
                  </Form.Item>
                </Col>
                : null
            }

            <Col {...flexCol}>
              <Form.Item><Button onClick={() => this.handleSend(url)} className={styles.btn}>Send</Button></Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}

const ItemWrapForm = Form.create()(ItemWrap);
export default ItemWrapForm;
