import { notification } from "antd";
import { post, get, put, deleteReq } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";

const GET_TRAINER_ACCOUNTS = "GET_TRAINER_ACCOUNTS";
const SAVE_DATA = 'MY_SESSION_SAVE'
const GET_SESSION_TYPES = 'GET_SESSION_TYPES'
const ALL = 'All'


export const initState = {
  sessionTypes: [],
  loginsAccounts: [],
  allAccounts: [],
  registrations: [],
  updateSessionValidation: {}
};

// reducer
export default function AddSessionReducer(state = initState, action) {
  switch (action.type) {
    case SAVE_DATA:
      return { ...state, ...action.payload };
    case GET_TRAINER_ACCOUNTS:
      return { ...state, ...action.payload };
    case GET_SESSION_TYPES:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: SAVE_DATA
  };
}

export function changeVals(type, val) {
  return dispatch => {
    return dispatch({
      type,
      payload: val
    });
  };
}

const baseApiUrl = '/pruventure/sessions'
const registrationApiUrl = '/pruventure/registrations'
/**
 * {
    "pageSize": 0,
    "currentPageNumber": 0,
    "orderBy": "string",
    "agentId": "string",
    "agentLeader": "string",
    "region": "string",
    "unitCode": "string",
    "registrationDate": "2023-01-12",
    "status": "Active"
  }
 * @param {*} payload
 * @returns
 */
export function getRegistrations(payload) {
  return dispatch => {
    dispatch(loading(true))
    get(`${registrationApiUrl}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: SAVE_DATA,
            payload: {
              registrations: data.items,
            }
          })
        } else {
          notification.error({
            message: 'get regions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 */
export function getSessionTypes(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}/types`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: GET_SESSION_TYPES,
            payload: {
              sessionTypes: [...data],
            }
          })
        } else {
          dispatch({
            type: GET_SESSION_TYPES,
            payload: {
              sessionTypes: [],
            }
          })
          notification.error({
            message: 'get sessionTypes error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 */
export function getTainerAccounts(payload) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}/trainers/search`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: GET_TRAINER_ACCOUNTS,
            payload: {
              loginsAccounts: [...data],
            }
          })
        } else {
          dispatch({
            type: GET_TRAINER_ACCOUNTS,
            payload: {
              loginsAccounts: [],
            }
          })
          notification.error({
            message: 'get loginsAccounts error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 * @param {*} payload {search: string}
 * @param {*} cb
 */
export function getAllAccounts(payload, cb) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}/creators/search`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          const data = res.data
          dispatch({
            type: GET_TRAINER_ACCOUNTS,
            payload: {
              allAccounts: [...data],
            }
          })
          cb()
        } else {
          dispatch({
            type: GET_TRAINER_ACCOUNTS,
            payload: {
              allAccounts: [],
            }
          })
          notification.error({
            message: 'get allAccounts error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}


export function addSession(payload, callBack, handleError) {
  return dispatch => {
    dispatch(loading(true))
    return post(`${baseApiUrl}`, payload)
      .then(res => {
        if (res.success) {
          callBack()
          dispatch(loading(false))
          notification.success({
            message: 'add successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'upload error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch((res) => {
        dispatch(loading(false));
        handleError(res)
      });
  };
}

export function searchSessions(payload, cb) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          dispatch({
            type: SAVE_DATA,
            payload: {
              sessions: res.data.items,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            }
          })
        } else {
          dispatch({
            type: SAVE_DATA,
            payload: {
              sessions: [],
            }
          })
          notification.error({
            message: 'get sessions error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        dispatch(loading(false));
      });
  };
}

export function updateSession(payload, cb, handleError) {
  return dispatch => {
    dispatch(loading(true))
    return put(`${baseApiUrl}/${payload.sessionId}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          notification.success({
            message: 'update successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
          })
        }
      })
      .catch((res) => {
        dispatch(loading(false));
        if (res.code === 0) {
          return
        }
        handleError(res)
      });
  };
}

export function deleteSession(payload, cb) {
  return dispatch => {
    dispatch(loading(true))
    return deleteReq(`${baseApiUrl}/${payload.sessionId}`)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          notification.success({
            message: 'delete successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'delete error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

