import React, { useState } from "react";
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { Form, Row, Col, Button, Select, Checkbox, Spin, DatePicker, notification, Input } from "antd";
import { get, post } from '../../../../../../../utils/request';
import { globalPromptTime10s } from '../../../../../../../utils/global';
import { showError } from '../utils';
import styles from '../../index.module.less';
import Header from '../../../../../../components/Header';

const { Option } = Select;

const { RangePicker } = DatePicker;

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px"
  }
};

function AttendanceTemp(props) {
  const [loading, setLoading] = useState(false);
  const [isWalkIn, setWalkInCheck] = useState(false);
  const [loginAccount, setLoginAccount] = useState({});
  const [loginAccountList, setLoginAccountList] = useState([]);
  const { form: { getFieldDecorator } } = props;
  const { courseDetail, currentSessiondayId } = props.location.state;
  const validate = (values) => {
    if (!values.scanDateRange || values.scanDateRange.length === 0) {
      return false;
    }

    const scanIn = values.scanDateRange[0].valueOf();
    const scanOut = values.scanDateRange[1].valueOf();
    const startLimitDate = moment(courseDetail.startDate).startOf("day").valueOf();
    const endLimitDate = moment(courseDetail.endDate).endOf("day").valueOf();
    const canScan = scanIn >= startLimitDate && scanOut <= endLimitDate;

    if (canScan) {
      return true;
    } else if (scanIn > scanOut) {
      showError('Scan In is greater than Scan Out!');
      return false;
    } else {
      showError('Time out of session valid time');
      return false;
    }
  }

  const handleSubmit = async () => {
    props.form.validateFields({ first: true }, async (err, values) => {
      if (validate(values)) {
        setLoading(true);
        const payload = {
          ...values,
          ...loginAccount,
          ...{
            sessiondayId: currentSessiondayId,
            scanInDate: values.scanDateRange[0].format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            scanOutDate: values.scanDateRange[1].format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            isWalkIn: values.isWalkIn ? "Y" : "N"
          }
        };

        try {
          const res = await post("/attendanceTms/createAttendance", payload);

          if (res.success) {
            setLoading(false);

            notification.success({
              message: "Successfully created",
              duration: globalPromptTime10s
            });

            props.history.push("/home/attendance/session_detail", {
              sessionId: courseDetail.sessionId,
              courseId: courseDetail.courseId
            });
          } else {
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
        }
      }
    });
  }

  const searchLoginAcount = async (name) => {
    if (!name) {
      return;
    }

    const { sessionId } = courseDetail;
    const data = {
      agentName: name,
      sessionId,
      isWalkIn: isWalkIn ? "Y" : "N"
    };
    const res = await get("/attendanceTms/getAgentNameList", data);

    setLoginAccountList(res.data);
  }

  const handleWalkInChange = e => {
    setWalkInCheck(e.target.checked);
  }

  const selectAgent = (item) => {
    setLoginAccount(item);
  }

  return (
    <div>
      <Header title="Add Attendance" />
      <Spin spinning={loading}>
        <Form
          hideRequiredMark
          layout="horizontal"
          labelCol={{ span: 8 }}
          labelAlign="left"
          wrapperCol={{ span: 16 }}
          colon={false}
        >
          <Row>
            <Col {...flexCol}>
              <Form.Item labelCol={{ span: 3 }}>
                {getFieldDecorator("isWalkIn", {
                  initialValue: isWalkIn
                })(<Checkbox checked={isWalkIn} onChange={handleWalkInChange}>Walk-In</Checkbox>)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Session Time">
                {getFieldDecorator("sessionTime", {
                  initialValue: `${courseDetail.startDate} - ${courseDetail.endDate}`
                })(<Input disabled />)}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Login Account" labelCol={{ span: 5 }}>
                {getFieldDecorator("loginAccount", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    },
                    {
                      max: 50,
                      message: "fields cannot be longer than 50 characters"
                    }
                  ]
                })(
                  <Select
                    defaultActiveFirstOption={false}
                    showSearch
                    showArrow={false}
                    onSearch={searchLoginAcount}
                    filterOption={false}
                    optionLabelProp="value"
                  >
                    {loginAccountList.length
                      ? loginAccountList.map(item => (
                        <Option
                          onClick={() => {
                            selectAgent(item);
                          }}
                          key={item.agentId}
                          label={item.agentName}
                          value={item.agentId}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                          >
                            <div>{item.agentName}</div>
                            <div>{item.agentId}</div>
                          </div>
                        </Option>
                      ))
                      : null}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol}>
              <Form.Item label="Scan In & Out" style={{ marginBottom: 0 }}>
                {getFieldDecorator("scanDateRange", {
                  rules: [
                    {
                      required: true,
                      message: "Scan in is empty"
                    }
                  ]
                })(
                  <RangePicker
                    showTime={{
                      hideDisabledOptions: true,
                      defaultValue: [
                        moment("00:00:00", "HH:mm:ss"),
                        moment("23:59:59", "HH:mm:ss")
                      ]
                    }}
                    placeholder={["Scan In", "Scan Out"]}
                    style={{ width: "100%" }}
                    format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={insideStyles.goCenter}>
              <Form.Item>
                <Button
                  type="primary"
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
    </div>

  );
}

const AttendanceTempWithForm = Form.create({ name: "AttendanceTemp" })(AttendanceTemp);

export default withRouter(AttendanceTempWithForm);
