import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Select,
  Input,
  Icon,
} from "antd";
import {
  save_data,
  getDetail,
  setRule,
  init_data
} from "../../../../../redux/pclaReducer/systemSettingsReducers/ReminderDetailReducer";
import styles from "./styles.module.less";

@connect(
  ({
    pclaReducer: { systemSettingsReducers: { ReminderDetailReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: ReminderDetailReducer,
    loading
  }),
  {
    save_data,
    getDetail,
    setRule,
    init_data
  }
)
class ReminderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: [],
      minutes: []
    };
  }

  componentDidMount() {
    const { pambReminderRuleId } = this.props.location.state;
    this.props.init_data();
    this.props.getDetail(pambReminderRuleId);
    const hours = [];
    const minutes = ["00", "15", "30", "45"];
    let hour = "";
    for (let i = 0; i < 24; i += 1) {
      if (i < 10) {
        hour = `0${i}`;
      } else {
        hour = i;
      }
      hours.push(hour);
    }
    this.setState(() => ({
      hours,
      minutes
    }));
  }

  handleSubmit = () => {
    const {
      pambReminderRuleId,
      beforeDays,
      frequency,
      frequencyUnit,
      setHour,
      setMinute
    } = this.props.fields;
    this.props.setRule({
      pambReminderRuleId,
      beforeDays,
      frequency,
      frequencyUnit,
      sendTime: `${setHour}:${setMinute}`
    });
  };

  render() {
    const {
      save_data,
      setRule,
      fields: {
        pambReminderRuleId,
        MandatoryRequirement,
        DueDate,
        SMSTemplate,
        EMailTemplate,
        setHour,
        setMinute,
        beforeDays,
        frequency,
        frequencyUnit
      },
      history,
      location
    } = this.props;
    const { Option } = Select;
    const { hours, minutes } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => {
              history.push(
                "/home/system_settings/reminder_rule/reminder_list",
                {
                  search: location.state.search
                }
              );
            }}
          />
          Reminder Rule Set Up
        </div>
        <div className={styles.detailContent}>
          <div className={styles.col}>
            <div className={styles.left}>Mandatory Requirement</div>
            <div className={styles.right}>{MandatoryRequirement}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.left}>Due Date</div>
            <div className={styles.right}>{DueDate}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.left}>SMS Template</div>
            <div className={styles.right}>{SMSTemplate}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.left}>EMail Template</div>
            <div className={styles.right}>{EMailTemplate}</div>
          </div>
          <div className={styles.col}>
            <div className={styles.left}>Reminder Rule</div>
            <div className={styles.setContent}>
              <div>
                <span>Send out on</span>
                <Select
                  defaultValue={setHour}
                  value={setHour}
                  onChange={e => {
                    save_data({
                      setHour: e
                    });
                  }}
                  style={{
                    width: "60px",
                    marginLeft: "5px",
                    marginRight: "5px"
                  }}
                >
                  {hours.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
                <span>:</span>
                <Select
                  defaultValue={setMinute}
                  value={setMinute}
                  onChange={e => {
                    save_data({
                      setMinute: e
                    });
                  }}
                  style={{
                    width: "60px",
                    marginLeft: "5px",
                    marginRight: "5px"
                  }}
                >
                  {minutes.map(item => (
                    <Option value={item} key={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
                ,
                <Input
                  value={beforeDays}
                  type="number"
                  onChange={e => {
                    const reg = /^([1-9][0-9]*){1,}$/;

                    if (reg.test(e.target.value)) {
                      save_data({
                        beforeDays: e.target.value
                      });
                    }
                  }}
                  style={{
                    width: "70px",
                    height: "25px",
                    marginLeft: "5px",
                    marginRight: "5px",
                    lineHeight: "15px"
                  }}
                />
                <span>
                  days before the Agent/agent leader's requirement due date, and
                  with{" "}
                </span>
              </div>
              <div>
                <span>frequency by every</span>
                <Input
                  type="number"
                  value={frequency}
                  onChange={e => {
                    const reg = /^([1-9][0-9]*){1,}$/;
                    if (reg.test(e.target.value)) {
                      save_data({
                        frequency: e.target.value
                      });
                    }
                  }}
                  style={{ width: "70px", height: "25px", marginLeft: "5px", lineHeight: "15px" }}
                />
                <Select
                  defaultValue={frequencyUnit}
                  value={frequencyUnit}
                  onChange={e => {
                    save_data({
                      frequencyUnit: e
                    });
                  }}
                  style={{
                    width: "100px",
                    marginLeft: "5px",
                    marginRight: "5px"
                  }}
                >
                  <Option value="1">days</Option>
                  <Option value="2">weeks</Option>
                </Select>
                <span> . until the Due Date.</span>
              </div>
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.left}></div>
            <div className={styles.setContent} style={insideStyles.goCenter}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  setRule(
                    {
                      pambReminderRuleId,
                      mandatoryRequirement: MandatoryRequirement,
                      beforeDays,
                      frequency,
                      frequencyUnit,
                      sendTime: `${setHour}:${setMinute}`
                    },
                    history,
                    location
                  );
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

export default ReminderDetail;
