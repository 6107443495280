import React from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Form, Table, Button, Modal, Spin } from "antd";
import {
  getDataByYear,
  handleSubmit,
  addARow,
  getTypeBycateGory,
  removeData,
  addCpdYear,
  saveFields
} from "../../../../redux/plukReducer/CPDSettingsReducers/requirementSettingReducer";
import {
  getYearList
} from "../../../../redux/plukReducer/CPDSettingsReducers/commonReducer";
import styles from './RequirementSettingPacs.module.less'

const { Option } = Select

const formLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
};

const flexCol = {
  span: 8
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ plukReducer: { CPDSettingsReducers: { requirementSettingReducer, commonReducer } } }) => ({ requirementSettingReducer, commonReducer }),
  { getDataByYear, handleSubmit, addARow, getTypeBycateGory, removeData, addCpdYear, saveFields, getYearList }
)

class Formes extends React.Component {
  state = {
    editingKey: '',
    isShowNewyearModel: false,
    isEditAction: false,
    columns: [
      {
        title: "CPD Category",
        dataIndex: "cpdcategory",
        align: "center",
        ellipsis: true,
        editable: true,
      },
      {
        title: "CPD Type",
        dataIndex: "cpdtype",
        align: "center",
        ellipsis: true,
        editable: true,
        render: (text, record) => {
          const { cpdTypeMap = {}, cpdtype } = record
          return <span>{cpdTypeMap[cpdtype]}</span>
        }
      },
      {
        title: "Minimum Hours",
        dataIndex: "minimumHours",
        align: "center",
        ellipsis: true,
        editable: true,
      },
      {
        title: "Maximum Hours",
        dataIndex: "maximumHours",
        align: "center",
        ellipsis: true,
        editable: true,
      }
    ]
  }

  componentDidMount() {
    const { commonReducer: { plukCdpYearList }, requirementSettingReducer: { temporaryData } } = this.props;
    const yearId = temporaryData.tempYearId || (plukCdpYearList.length ? plukCdpYearList[0].yearId : "")
    this.props.getDataByYear(yearId)
    this.props.saveFields('tempYearId', yearId)
  }

  handleYearChange = (val) => {
    this.props.getDataByYear(val)
    this.handleSaveField('tempYearId', val)
  }

  handleCancel = () => {
    this.setState({ isShowNewyearModel: false });
  };

  handleOk = (payload) => {
    const callback = () => {
      const cb = () => {
        this.props.history.push('/home/cpd_settings/requiement_setting/edit')
      }
      this.props.getYearList(cb)
      this.props.saveFields({ tempYearId: '' })
    }
    this.props.addCpdYear({ year: payload }, callback)
    this.setState({ isShowNewyearModel: false });
  };

  handleGotoEdit = () => {
    const { requirementSettingReducer: { temporaryData } } = this.props;
    this.props.history.push('/home/cpd_settings/requiement_setting/edit', { yearId: temporaryData.tempYearId })
  };

  handleSaveField = (field, val) => {
    this.props.saveFields({ [field]: val });
  };

  handleNewYear = () => {
    this.setState({ isShowNewyearModel: true })
  }

  render() {
    const {
      commonReducer: { plukCdpYearList },
      form: { getFieldDecorator },
      requirementSettingReducer: { dataSourceList, tableLoading, pageLoading, temporaryData: { tempYearId } }
    } = this.props
    const selectId = tempYearId || (plukCdpYearList.length ? plukCdpYearList[0].yearId : '')
    const { isShowNewyearModel, columns } = this.state
    const newYear = plukCdpYearList.length ? plukCdpYearList[0].year * 1 + 1 : ""

    const theOne = plukCdpYearList.find(elt => elt.yearId === tempYearId)
    const tempYearName = theOne ? theOne.year : ''

    return (
      <div className={styles.container}>
        <Spin spinning={pageLoading}>
          <div className={styles.header}>
            <Form
              colon={false}
              {...formLayout}
              labelAlign="left"
              className={styles.ant_form}
            >
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="CPD Year">
                    {getFieldDecorator("role", {
                      initialValue: selectId
                    })(
                      <Select
                        onChange={this.handleYearChange}
                      >
                        {
                          plukCdpYearList.map((elt => {
                            return (<Option key={elt.yearId} value={elt.yearId}>{elt.year}</Option>)
                          }))
                        }
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item>
                    <Button className={styles.addBtn} icon="plus" onClick={this.handleNewYear}>Add</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div
            className={styles.tableBox}
          >
            <div className={styles.headerWrapper}>
              <div className={styles.heading}>{`${tempYearName} CPD Requirement Detail`}</div>
              <Button className={styles.editBtn} onClick={this.handleGotoEdit}>Edit</Button>
            </div>
            <Table
              columns={columns}
              dataSource={dataSourceList || []}
              size="small"
              loading={tableLoading}
              className={styles.ant_dev_table}
              rowKey="yearReqId"
              pagination={false}
            />
          </div>
          <Modal
            title="Create New CPD Year"
            visible={isShowNewyearModel}
            onOk={() => this.handleOk(newYear)}
            onCancel={this.handleCancel}
            okType='danger'
          >{`${newYear} new Setting?`}
          </Modal>
        </Spin>
      </div>
    )
  }
}
const RequirementSettingPacs = Form.create()(Formes)
export default RequirementSettingPacs;
