import React from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Icon,
  Select,
  Rate,
  Spin
} from "antd";
import { connect } from "react-redux";
import styles from "./QuestionrGroupDetail.module.less";
import {
  getQuestionDetail,
  reSetGroup
} from "../../../../../redux/pclaReducer/systemSettingsReducers/QuestionGroupReducer";
import { handleSaveOriginData } from "../../../../../redux/pclaReducer/systemSettingsReducers/SurveyReducer";
import { numberToLetter } from "../../../../../utils/global";

const { Option } = Select;
const { TextArea } = Input;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const formLayout2 = {
  labelCol: { span: 5 },
  wrapperCol: { span: 19 }
};

const formLayout3 = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};
const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexCol2 = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pclaReducer: { systemSettingsReducers: { QuestionGroupReducer } } }) => ({
    QuestionGroupReducer
  }),
  {
    getQuestionDetail,
    reSetGroup,
    handleSaveOriginData
  }
)
class QuestionrGroupDetail extends React.Component {
  componentDidMount() {
    const { state } = this.props.location;
    const { noReFresh, edit, fieldsData, questionGroups, pambQuestionGroupId } =
      state || {};
    if (edit) {
      this.props.handleSaveOriginData({
        fieldsData,
        questionGroups,
        noReFresh
      });
    }

    this.props.getQuestionDetail({ pambQuestionGroupId });
  }

  componentWillUnmount() {
    this.props.reSetGroup();
  }

  render() {
    const {
      form: { getFieldDecorator },
      QuestionGroupReducer: {
        questionList,
        typeList,
        questionDetailData,
        submitLoading,
        trainerList
      }
    } = this.props;
    const { groupName, groupDescription, groupType } = questionDetailData;

    return (
      <div className={styles.container}>
        <Spin spinning={submitLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() => {
                const { state = {} } = this.props.location;
                const { from, edit } = state;
                this.props.history.push({
                  pathname:
                    from || "/home/system_settings/question_group/index",
                  state: {
                    ...state,
                    from: edit
                      ? "/home/system_settings/survey_management/survey_detail"
                      : "/home/system_settings/survey_management/survey_create"
                  }
                });
              }}
            />
            Group Info
          </div>
          <Form labelAlign="left" colon={false} {...formLayout}>
            <div className={styles.ant_form}>
              <Row {...flexRow}>
                <Col {...flexCol}>
                  <Form.Item label="Group Name">
                    {getFieldDecorator("groupName", {
                      initialValue: groupName
                    })(<Input disabled />)}
                  </Form.Item>
                </Col>
                <Col {...flexCol}>
                  <Form.Item label="Is Trainer" {...formLayout3}>
                    {getFieldDecorator("groupType", {
                      initialValue: groupType
                    })(
                      <Select disabled>
                        {trainerList.map(elt => (
                          <Option value={elt.value} key={elt.value}>
                            {elt.code}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row {...flexRow}>
                <Col {...flexCol2}>
                  <Form.Item label="Group Description" {...formLayout2}>
                    {getFieldDecorator("groupDescription", {
                      initialValue: groupDescription
                    })(<TextArea disabled />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            {questionList.length ? (
              <div className={styles.groupBox}>
                <div className={styles.contentBox}>
                  {questionList.map(
                    (
                      { questionType, questionName, answerList, questionIndex },
                      ind
                    ) => {
                      return (
                        <div className={styles.groupItem} key={questionIndex}>
                          <div className={styles.indexBox}>{`${ind + 1}.`}</div>
                          <div className={styles.content}>
                            <div className={styles.questionTop}>
                              <Form.Item>
                                {getFieldDecorator(`type${questionIndex}`, {
                                  initialValue: questionType
                                })(
                                  <Select
                                    className={styles.typeBox}
                                    onChange={val =>
                                      this.handleChange({
                                        type: val,
                                        index: ind
                                      })
                                    }
                                    disabled
                                  >
                                    {typeList.map(elt => (
                                      <Option value={elt.value} key={elt.value}>
                                        {elt.code}
                                      </Option>
                                    ))}
                                  </Select>
                                )}
                              </Form.Item>
                            </div>
                            <div className={styles.bottomBox}>
                              <Form.Item>
                                {getFieldDecorator(`r${questionIndex}`, {
                                  rules: [
                                    {
                                      required: true,
                                      message:
                                        "Please fill in the mandatory fields"
                                    }
                                  ],
                                  initialValue: questionName
                                })(
                                  <TextArea
                                    rows={4}
                                    disabled
                                    onChange={e =>
                                      this.handleChange({
                                        type: questionType,
                                        title: e.target.value,
                                        index: ind
                                      })
                                    }
                                  />
                                )}
                              </Form.Item>
                            </div>
                            {questionType === "0" ? <Rate disabled /> : null}
                            {questionType === "1" ? (
                              <TextArea disabled />
                            ) : null}
                            {questionType === "2" ? (
                              <div className={styles.answerBox}>
                                {answerList
                                  ? answerList.map((item, i) => {
                                      return (
                                        <div
                                          className={styles.answerItem}
                                          key={item.answerIndex}
                                        >
                                          <span className={styles.sortBox}>
                                            {`${numberToLetter[i]}.`}
                                          </span>
                                          <Form.Item
                                            className={styles.answerInputBox}
                                          >
                                            {getFieldDecorator(
                                              item.answerIndex,
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Please fill in the mandatory fields"
                                                  }
                                                ],
                                                initialValue: item.answerName
                                              }
                                            )(
                                              <Input
                                                className={styles.answerInput}
                                                onChange={e =>
                                                  this.handleChange({
                                                    type: questionType,
                                                    index: ind,
                                                    answerIndex: i,
                                                    answerName: e.target.value
                                                  })
                                                }
                                                disabled
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            ) : null}
                            {questionType === "3" ? (
                              <div className={styles.answerBox}>
                                {answerList
                                  ? answerList.map((item, i) => {
                                      return (
                                        <div
                                          className={styles.answerItem}
                                          key={item.answerIndex}
                                        >
                                          <span className={styles.sortBox}>
                                            {`${numberToLetter[i]}.`}
                                          </span>
                                          <Form.Item
                                            className={styles.answerInputBox}
                                          >
                                            {getFieldDecorator(
                                              item.answerIndex,
                                              {
                                                rules: [
                                                  {
                                                    required: true,
                                                    message:
                                                      "Please fill in the mandatory fields"
                                                  }
                                                ],
                                                initialValue: item.answerName
                                              }
                                            )(
                                              <Input
                                                className={styles.answerInput}
                                                onChange={e =>
                                                  this.handleChange({
                                                    type: questionType,
                                                    index: ind,
                                                    answerIndex: i,
                                                    answerName: e.target.value
                                                  })
                                                }
                                                disabled
                                              />
                                            )}
                                          </Form.Item>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
          </Form>
        </Spin>
      </div>
    );
  }
}

const QuestionrGroupDetailForm = Form.create()(QuestionrGroupDetail);

export default QuestionrGroupDetailForm;
