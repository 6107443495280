import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Tooltip,
  Table,
  notification,
  DatePicker
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pambReducer/systemSettingsReducers/index.action";
import {
  getCourseList,
  getFacultyList,
  getCommentsFeedbackReportList,
  downloadCommentsFeedbackReport
} from "../../../../redux/pambReducer/CPDReportReducers/SurveyReportsReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateSurveyReports_Form",
  onFieldsChange(props, changedFields) {
    let courseNameArr;
    if (changedFields.courseName) {
      if (!changedFields.courseName.value.length) {
        courseNameArr = 'All'
      } else {
        courseNameArr = changedFields.courseName.value.filter((item) => {
          return item != "All";
        })
      }
      changedFields.courseName.value = courseNameArr;
    }
    let facultyArr;
    if (changedFields.faculty) {
      if (!changedFields.faculty.value.length) {
        facultyArr = 'All'
      } else {
        facultyArr = changedFields.faculty.value.filter((item) => {
          return item != "All";
        })
      }
      changedFields.faculty.value = facultyArr;
    }
    props.onChange("SurveyReports", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      faculty: Form.createFormField({
        ...props.faculty,
        value: props.faculty.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { loading, columns } = props;
  console.log("searchList:", props.searchList) // eslint-disable-line
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelAlign="left"
        colon={false}
      >
        <Row>
          <Col {...flexCol}>
            <Form.Item
              label="Course Name"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
            >
              {getFieldDecorator(
                "courseName",
                {}
              )(
                <Select
                  defaultActiveFirstOption={false}
                  showSearch
                  showArrow={false}
                  mode="multiple"
                  optionFilterProp="children"
                  onChange={(e) => {
                    console.log("courseName:", e) //eslint-disable-line
                  }}
                  filterOption={(input, option) => {
                    // console.log(input.toLowerCase()) // eslint-disable-line
                    // console.log(option.props.children) // eslint-disable-line
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                >
                  {props.courseList.length
                    ? props.courseList.map(item => (
                      <Option value={item.courseId} key={item.courseId}>
                        {item.courseName}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item
              label="Faculty"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              labelAlign="center"
            >
              {getFieldDecorator("faculty", {
                rules: [
                  // {
                  //   required: true,
                  //   message: "Please fill in the mandatory fields"
                  // }
                ]
              })(
                <Select
                  defaultActiveFirstOption={false}
                  showSearch
                  showArrow={false}
                  mode="multiple"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    // console.log(input.toLowerCase()) // eslint-disable-line
                    // console.log(option.props.children) // eslint-disable-line
                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                >
                  {props.facultyList.length
                    ? props.facultyList.map(item => (
                      <Option value={item.facultyId} key={item.facultyId}>
                        {item.facultyName}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...flexCol}>
            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
              label="Training Date Range"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("trainingDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  // showTime={{
                  //   hideDisabledOptions: true,
                  //   format: "HH:mm",
                  //   defaultValue: [
                  //     moment("00:00", "HH:mm"),
                  //     moment("23:59", "HH:mm")
                  //   ]
                  // }}
                  placeholder={["From Date", "To Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
          style={{ display: props.showList ? "block" : "none" }}
        >
          {" "}
        </div>
        <Button
          className={styles.btn}
          style={{ display: props.showList ? "block" : "none" }}
          type="danger"
          icon="download"
          onClick={() => {
            props.downloadCommentsFeedbackReport();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
            display: props.showList ? "block" : "none"
          }}
        >
          {/* <div className={styles.deductionTableBottom}>
            <div className={styles.TableBottom_left}>
              <i></i>Deduction Installment:&nbsp;
              <span>{props.installment}</span>
            </div>
            <div className={styles.TableBottom_right}>
              <div>
                <i></i> Gross excl SST:&nbsp;<span>{props.grossTotal}</span>
              </div>
              <div>
                <i></i> SST Amount:&nbsp;<span>{props.sstTotal}</span>
              </div>
            </div>
          </div> */}
          <Table
            bordered
            scroll={{ x: 3100 }}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
            rowKey="index"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pambReducer: { CPDReportReducers: { SurveyReportsReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: SurveyReportsReducer,
    loading
  }),
  {
    changeVals,
    getCourseList,
    getFacultyList,
    getCommentsFeedbackReportList,
    downloadCommentsFeedbackReport,
  }
)
class SurveyReports extends Component {
  constructor(props) {
    super(props);
    this.downloadCommentsFeedbackReport = this.downloadCommentsFeedbackReport.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "No",
          dataIndex: "",
          width: 80,
          fixed: "left",
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Faculty",
          dataIndex: "faculty",
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Date",
          dataIndex: "sessionDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Venue",
          dataIndex: "venue",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Language",
          dataIndex: "language",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Trainer",
          dataIndex: "trainer",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
        ,

        {
          title: "Caterer",
          dataIndex: "caterer",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Feedback Comment Question",
          dataIndex: "commentQuestion",
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent ID",
          dataIndex: "agentId",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent's Comment",
          dataIndex: "answer",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Action Taken",
          dataIndex: "actionTaken",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Action Taken Date",
          dataIndex: "actionTakenDate",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Action Taken By",
          dataIndex: "actionTakenBy",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.getCourseList();
    this.props.getFacultyList();
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.trainingDate.value.length) {
          // const year = new Date().getFullYear();
          // /* eslint-disable */
          // let seconds = 0;
          // if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
          //   seconds = 31643326;
          // } else {
          //   seconds = 31556926;
          // }
          // /* eslint-disable */
          // const start = fields.trainingDate.value[0].startOf('day').valueOf()
          // const end = fields.trainingDate.value[1].endOf('day').valueOf();
          // const between = Math.round(end / 1000) - Math.round(start / 1000);
          const end = fields.trainingDate.value[1].endOf('day').valueOf();
          const start = fields.trainingDate.value[0];
          const nowYear = moment(start).add(1, "year").valueOf();

          if (end > nowYear) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        console.log("fields:", fields) // eslint-disable-line
        this.props.getCommentsFeedbackReportList({
          courseIdList: Array.isArray(fields.courseName.value) ? fields.courseName.value : [],
          endDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD")
            : "",
          facultyList: Array.isArray(fields.faculty.value) ? fields.faculty.value : [],

          startDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD")
            : "",
          pageSize: this.state.pageSize,
          currentPageNumber: this.state.pageIndex,
        });
      }
    });
  };

  downloadCommentsFeedbackReport() {
    const { fields, downloadCommentsFeedbackReport } = this.props;
    downloadCommentsFeedbackReport({
      courseIdList: Array.isArray(fields.courseName.value) ? fields.courseName.value : [],
      endDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD")
        : "",
      facultyList: Array.isArray(fields.faculty.value) ? fields.faculty.value : [],

      startDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD")
        : "",
    });
  }

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { fields } = this.props;
    this.props.getCommentsFeedbackReportList({
      courseIdList: Array.isArray(fields.courseName.value) ? fields.courseName.value : [],
      endDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD")
        : "",
      facultyList: Array.isArray(fields.faculty.value) ? fields.faculty.value : [],
      startDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize,
      currentPageNumber: current

    });
  };

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      downloadCommentsFeedbackReport: this.downloadCommentsFeedbackReport,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Comments Feedback Report</div>
        <CreateForm {...props} {...this.props.fields} />
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};
export default SurveyReports;
