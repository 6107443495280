import { combineReducers } from "redux";
import requirementSettingReducer from "./requirementSettingReducer";
import trainingRequirementSearchReducer from "./trainingRequirementSearchReducer";
import commonReducer from "./commonReducer";
import TRagentDetailReducer from "./TRagentDetailReducer";
import CPDHistoryReducer from "./CPDHistoryReducer";

export default combineReducers({
  requirementSettingReducer,
  trainingRequirementSearchReducer,
  commonReducer,
  TRagentDetailReducer,
  CPDHistoryReducer
});
