import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import debounce from "lodash/debounce";
import { Form, Row, Col, DatePicker, Table, Empty, Button, Modal, Select, notification, Menu, Icon, Dropdown, Popover } from 'antd'
import { TimePicker, Input as InputV5 } from 'antd-v5'
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { get } from 'lodash'
import styles from './MySessionDetails.module.less'
import { getSessionTypes, addSession, changeVals, getTainerAccounts, getAllAccounts, searchSessions, updateSession, deleteSession, getRegistrations } from "../../../../../redux/pambReducer/mySessionReducers/AddSessionReducer";
import { getAttendance, validateAttendances, handleUpdateImportAttendances, handleValidateUserIds, handleImportAttendances } from "../../../../../redux/pambReducer/mySessionReducers/AddAttendanceReducer";
import { globalPromptTime10s, isPambPruventureAdmin } from "../../../../../utils/global";
import { download_template } from "../../../../../redux/PublicReducer";
import { columns } from './mySessionDetailsColumn'
import AddAttendanceModalForm from "./AddAttendanceModal";

const { Option } = Select
const { TextArea } = InputV5;
dayjs.extend(utc)
// const { TreeNode } = Tree;
const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const formLayout2 = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};


const flexCol1 = {
  span: 8
};

const flexCol = {
  span: 14
};

const flexCol3 = {
  span: 12
}

const flexCol2 = {
  span: 24
};

const flexRow = {
  gutter: [16, 0]
};

const sessionTypeMapToCode = {
  'Parenting Day': '1',
  'Sales Builder Meeting': '2',
  'PV Learning Day': '3'
}

@connect(
  ({ pambReducer: { mySessionReducers: { AddSessionReducer, AddAttendanceReducer } }, PublicReducer, }) => ({ AddSessionReducer, PublicReducer, AddAttendanceReducer }),
  {
    getSessionTypes,
    getTainerAccounts,
    getAllAccounts,
    addSession,
    searchSessions,
    updateSession,
    deleteSession,
    changeVals,
    getAttendance,
    validateAttendances,
    handleUpdateImportAttendances,
    handleValidateUserIds,
    handleImportAttendances,
    download_template,
    getRegistrations
  }
)

class Formes extends React.Component {
  state = {
    visible: false,
    attendanceVisible: false,
    currentPageNumber: 1,
    pageSize: 10,
    total: '',
    userId: '',
    agentCode: '',
    isShowTable: false,
    sessionTypes: [],
    loginsAccounts: [],
    allAccounts: [],
    orderBy: 1,
    orderField: 'START_TIME',
    modalType: '',
    registrations: [],
    modalInitialValues: {
      sessionType: '',
      sessionDate: '',
      sessionDateTime: '',
      trainerId: '',
      description: ''
    },
    fileList: [],
    fileName: '',
    reqDto: {},
    targetKeys: [],
    attendanceSessionId: '',
    addAttendanceValidateInfo: {
      isShowErrorBanner: false,
      importErrors: [],
      activeAgentIds: [],
      importId: '',
      isChecked: false,
      type: '',
      isShowSuccessBanner: false,
    },
    updateSessionValidation: {}
  }

  initialValidationInfo = {
    isShowErrorBanner: false,
    importErrors: [],
    activeAgentIds: [],
    importId: '',
    isChecked: false,
    type: '',
    isShowSuccessBanner: false,
  }

  initialValues = {
    sessionType: '',
    sessionDate: '',
    sessionDateTime: '',
    trainerId: '',
    description: ''
  }



  componentDidMount() {
    Promise.allSettled([
      this.props.getSessionTypes(),
      this.props.getTainerAccounts(),
      this.props.getRegistrations(),
      this.props.getAllAccounts({ loginAccount: '' })
    ]).then(() => {
      this.getData({ ...this.state.reqDto }, () => {
        this.setState({ isShowTable: true, loginAccounts: this.props.AddSessionReducer.loginsAccounts, allAccounts: this.props.AddSessionReducer.allAccounts, sessionTypes: this.props.AddSessionReducer.sessionTypes, registrations: this.props.AddSessionReducer.registrations })
      })
    })
  }

  downLoadTemplate = () => {
    this.props.download_template(
      "/pruventure/templates/Import Session Attendance Template.xlsx",
      'ImportSessionAttendanceTemplate.xlsx',
    );
  }

  onChangeFile = (file) => {
    this.setState({ fileList: [file], fileName: file.name });
    return false;
  };

  onRemoveFile = () => {
    this.setState({ fileList: [], fileName: '' });
  };

  onChangeImportType = (e) => {
    // set validateInfo to initial state
    this.setState({ addAttendanceValidateInfo: this.initialValidationInfo, targetKeys: [], fileList: [], fileName: '' })
  }


  getColumns = (isTrainer, username) => {
    const tableColumns = [...columns]
    const isBeforeSessionStartTimeOneHour = (record) => {
      // return dayjs().isBefore((dayjs.utc(record.sessionStartTime).local()))
      return dayjs().isBefore((dayjs.utc(record.sessionStartTime).local()).subtract(1, 'hour'))
    }
    const isBeforeSessionStartTime = (record) => {
      // return dayjs().isBefore((dayjs.utc(record.sessionStartTime).local()))
      return dayjs().isBefore((dayjs.utc(record.sessionStartTime).local()))
    }
    const isBeforeSessionEndTime30Mins = (record) => {
      return dayjs().isBefore((dayjs.utc(record.sessionEndTime).local()).add(30, 'minutes'))
    }
    const userId = sessionStorage.getItem('userId')

    tableColumns.push(
      {
        title: "Action",
        dataIndex: "action",
        align: "center",
        width: 200,
        render: (text, record) => {
          const menu = (
            <Menu>
              {
                (((!isTrainer && userId === record.createUser) || (isTrainer && record.createUser === record.trainerId)) && isBeforeSessionStartTimeOneHour(record)) && (
                  <Menu.Item
                    onClick={() => {
                      this.handleUpdateSession(record, 'edit')
                    }}
                  >
                    Edit Session
                  </Menu.Item>
                )
              }
              {
                (isTrainer && isBeforeSessionEndTime30Mins(record)) && (
                  <Menu.Item
                    onClick={() => {
                      this.handleScanInOut(record, 'in')
                    }}>
                    Scan In
                  </Menu.Item>
                )
              }
              {
                (isTrainer && isBeforeSessionEndTime30Mins(record)) && (
                  <Menu.Item
                    onClick={() => {
                      this.handleScanInOut(record, 'out')
                    }}
                  >
                    Scan Out
                  </Menu.Item>
                )
              }
              <Menu.Item
                onClick={() => {
                  this.handleAddAttendance(record)
                }}>
                Add Attendance
              </Menu.Item>
              {
                ((!isTrainer && userId === record.createUser) || (isTrainer && record.createUser === record.trainerId)) && (isBeforeSessionStartTime(record)) && (
                  <Menu.Item
                    onClick={() => {
                      this.handleUpdateSession(record, 'delete')
                    }}
                  >
                    Delete Session
                  </Menu.Item>
                )
              }
            </Menu>
          )
          return (
            <div>
              <Button
                type="link"
                onClick={() => {
                  this.handleViewDetail(record)
                }}
              >
                View Details
              </Button>
              {
                (!isTrainer || (isTrainer && record.trainerName === username)) &&
                <Dropdown
                  overlay={menu}
                  trigger={['click']}
                  placement="bottomCenter"
                  overlayClassName={styles.ant_dev_dropdown}
                >
                  <span className={styles.spanCss}>
                    <span className={styles.text}>More</span>
                    <Icon type="caret-down" style={{ color: '#ff4152' }} />
                  </span>
                </Dropdown>
              }
            </div>
          )
        }
      }
    )
    tableColumns.push({
      dataIndex: "isConflicted",
      align: "center",
      render: (text, record) => {
        return (((isTrainer && username === record.createdBy) || !isTrainer) && record.isConflicted) ? (
          <Popover content="Conflicting attendance found" placement="topRight">
            <Icon type="exclamation-circle" style={{ color: '#ff4d4f' }} />
          </Popover>
        )
          : null
      }
    })
    return tableColumns
  }


  handleViewDetail = (record) => {
    const { completed, sessionType, sessionStartTime, sessionEndTime, trainerName, sessionId } = record
    this.props.history.push({
      pathname: '/home/scheme_management/pru_venture_my_session/attendance',
      state: {
        completed,
        sessionType,
        sessionDateTime: `${dayjs.utc(sessionStartTime).local().format('YYYY-MM-DD')} ${dayjs.utc(sessionStartTime).local().format('HH:mm')} - ${dayjs.utc(sessionEndTime).local().format('HH:mm')}`,
        sessionStartTime,
        sessionEndTime,
        trainer: trainerName,
        trainerId: record.trainerId,
        createUser: record.createUser,
        createdBy: record.createdBy,
        sessionId
      }
    })
  }

  getData = (payload, cb = () => { }) => {
    if (Object.keys(payload).length) {
      this.props.searchSessions(payload, cb)
    } else {
      const myUserId = sessionStorage.getItem('userId')
      const myEmai = sessionStorage.getItem('email')
      const initialParams = {
        currentPageNumber: 1,
        pageSize: this.state.pageSize,
      }
      const roleId = sessionStorage.getItem('roleid')
      const isAdmin = isPambPruventureAdmin(roleId)
      if (isAdmin) {
        initialParams.createUser = myEmai
      } else {
        initialParams.trainerId = myUserId
      }
      this.props.searchSessions({ ...initialParams, ...this.state.reqDto }, cb)
    }
  }

  handleSearch = (e) => {
    e.preventDefault();
    const { form } = this.props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const reqDto = {}
        Object.keys(values).forEach((key) => {
          if (values[key] !== undefined && values[key] !== null) {
            if (key === 'sessionDate') {
              reqDto.sessionDate = dayjs(values[key]).format('YYYY-MM-DD')
              return
            }
            reqDto[key] = values[key]
          }
        })
        // callback
        const callback = () => {
          this.setState({ isShowTable: true, reqDto })
        }
        this.getData({ currentPageNumber: 1, pageSize: this.state.pageSize, ...reqDto }, callback)
      }
    });
  }

  handleAddSession = async (e) => {
    this.setState({
      visible: true,
      modalType: 'add',
      modalInitialValues: this.initialValues,
    })
    await this.props.getSessionTypes()
    await this.props.getTainerAccounts({
      roleType: this.props.AddSessionReducer.sessionTypes[0].roleTypeCode
    })
  }

  handleScanInOut = (record, type) => {
    if (type === 'in') {
      if (((dayjs.utc(record.sessionStartTime).local()).diff(dayjs(), 'minutes') > 60)) {
        notification.warning({
          message: `You can't scan ${type} now`,
          description: `Scan ${type} is allowed starting from ${dayjs.utc(record.sessionStartTime).local().subtract(1, 'hour').format('YYYY-MM-DD HH:mm')}`,
          duration: '1000',
          className: styles.ant_dev_session_error,
        })
        return
      }
    } else {
      if (((dayjs.utc(record.sessionStartTime).local()).diff(dayjs(), 'minutes') > 60)) {
        notification.warning({
          message: `You can't scan ${type} now`,
          description: `Scan ${type} is allowed starting from ${dayjs.utc(record.sessionStartTime).local().subtract(1, 'hour').format('YYYY-MM-DD HH:mm')}`,
          duration: globalPromptTime10s,
          className: styles.ant_dev_session_error
        })
        return
      }
    }
    // try to open popup window
    try {
      const { sessionId } = record
      const popup = window.open(`${window.location.origin}/home/scheme_management/pru_venture_my_session/qr_code?sessionId=${sessionId}&type=${type}`, '_blank', 'width=530,height=530')
      popup.focus()
    } catch (e) {
      console.log(e)
    }
  }


  handleUpdateSession = async (record, type) => {
    const { sessionId } = record
    this.setState({
      visible: true,
      modalType: type,
      modalInitialValues: {
        sessionId,
        sessionType: sessionTypeMapToCode[record.sessionType],
        sessionDate: moment.utc(record.sessionStartTime, 'YYYY-MM-DD').local(),
        sessionDateTime: [dayjs.utc(record.sessionStartTime).local(), dayjs.utc(record.sessionEndTime).local()],
        description: record.description,
        userId: record.trainerId
      },
      updateSessionValidation: {}
    })
    await this.props.getTainerAccounts({
      roleType: sessionTypeMapToCode[record.sessionType]
    })
  }


  handleOk = (e, props) => {
    e.preventDefault();
    const { form, type } = props;
    form.validateFields((err, values) => {
      if (!err) {
        switch (type) {
          case 'add':
            this.handleAddSessionOk(e, props)
            break
          case 'delete':
            this.handleDeleteSessionOk(e, props)
            break
          case 'edit':
            this.handleEditSessionOk(e, props)
            break
          default:
            break
        }
      }
    });
  };


  handleAddAttendance = async (record) => {
    const { sessionId } = record
    const callback = () => {
      this.setState({
        attendanceVisible: true,
        attendanceSessionId: sessionId,
      })
    }
    await this.props.getAttendance({ sessionId }, callback)
  }

  handleAddAttendanceCancel = (props) => {
    props.form.resetFields()
    this.setState({
      attendanceVisible: false,
      targetKeys: [],
      fileList: [],
      fileName: '',
      addAttendanceValidateInfo: this.initialValidationInfo
    });
  }

  handleAddAttendanceOk = (e, props) => {
    const { form } = props;
    const addType = form.getFieldValue('attendance')
    const { addAttendanceValidateInfo, reqDto } = this.state
    const callback = () => {
      this.handleAddAttendanceCancel.bind(this, props)()
      // refresh table
      this.getData(reqDto, () => {
        this.setState({ isShowTable: true })
      })
    }
    if (addAttendanceValidateInfo.isChecked && (!addAttendanceValidateInfo.isShowErrorBanner || (!addAttendanceValidateInfo.isContainError || addAttendanceValidateInfo.importErrors?.length === 0))) {
      this.props.handleImportAttendances({ sessionId: this.state.attendanceSessionId, importId: this.state.addAttendanceValidateInfo.importId }, callback)
    } else {
      // validation
      this.checkAddAttendance({ type: addType }, callback)
    }
  }

  checkAddAttendance = async (payload, cb = () => { }) => {
    try {
      const { type } = payload
      const { targetKeys, attendanceSessionId, fileList } = this.state
      switch (type) {
        case 'transfer':
          await this.props.handleValidateUserIds({ sessionId: attendanceSessionId, userIds: targetKeys })
          break
        case 'import':
          if (fileList.length === 0) {
            return
          }
          const formData = new FormData()
          formData.append('file', fileList[0])
          await this.props.validateAttendances({ sessionId: attendanceSessionId, file: formData })
          break
        default:
          break
      }
      const validateInfo = this.props.AddAttendanceReducer.validateInfo
      const newValidateInfo = {
        isShowErrorBanner: false,
        importErrors: validateInfo.importErrors,
        activeAgentIds: validateInfo.activeAgentIds,
        importId: validateInfo.importId,
        isChecked: true,
        type
      }

      if (validateInfo && (validateInfo.isContainError || validateInfo.importErrors.length > 0)) {
        newValidateInfo.isShowErrorBanner = true
        newValidateInfo.isContainError = validateInfo.isContainError
        this.setState({ addAttendanceValidateInfo: newValidateInfo })
      } else {
        this.props.handleImportAttendances({ sessionId: this.state.attendanceSessionId, importId: newValidateInfo.importId }, cb)
      }
    } catch (e) {
      console.log(e)
    }
  }


  // accept/remove all errors
  handleUpdateImport = async (actionType) => {
    const { attendanceSessionId, addAttendanceValidateInfo, targetKeys } = this.state
    const { importId, importErrors, type } = addAttendanceValidateInfo

    const errorInfo = importErrors.shift()
    const isShowErrorBanner = importErrors.length > 0
    const submitValidate = {
      ...this.state.addAttendanceValidateInfo,
      importErrors,
      isShowErrorBanner
    }
    let newTargetKeys = targetKeys
    if (actionType === 'REMOVE_ALL') {
      newTargetKeys = targetKeys.filter((item) => {
        return !errorInfo.agentIds.includes(item)
      })
    }

    const callback = (res) => {
      try {
        const { toUploadAgents } = res.data
        if (toUploadAgents.length > 0) {
          submitValidate.isShowSuccessBanner = true
          submitValidate.successDesc = `${toUploadAgents?.length} attendance will be uploaded`
        }
      } catch (e) {
        console.log(e)
      }
      this.setState({ addAttendanceValidateInfo: submitValidate, targetKeys: newTargetKeys })
    }

    const updateData = {
      importId,
      action: actionType,
      errorAgentIds: errorInfo.agentIds,
      sessionId: attendanceSessionId
    }

    await this.props.handleUpdateImportAttendances(updateData, callback)
  }




  handleDeleteSessionOk = async (e, props) => {
    const { form } = props;
    const { sessionId } = this.state.modalInitialValues
    const callback = () => {
      this.setState({
        visible: false,
      });
      form.resetFields()
      this.getData(this.state.reqDto, () => {
        this.setState({ isShowTable: true })
      })
    }
    this.props.deleteSession({ sessionId }, callback)
  }

  handleEditSessionOk = async (e, props) => {
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { sessionId } = this.state.modalInitialValues
        const { description = '', sessionDate, sessionDateTime, sessionType } = values
        const sessionDay = `${sessionDate.format('YYYY-MM-DD')} `
        const submitData = {
          sessionId,
          sessionType,
          description,
          sessionStartTime: sessionDay + sessionDateTime[0].format('HH:mm:ss'),
          sessionEndTime: sessionDay + sessionDateTime[1].format('HH:mm:ss')
        }
        if (values.loginAccount) {
          submitData.trainerId = values.loginAccount
        }
        const callback = () => {
          this.setState({
            visible: false,
          });
          form.resetFields()
          this.getData(this.state.reqDto, () => {
            this.setState({ isShowTable: true })
          })
        }
        this.props.updateSession(submitData, callback)
      }
    });
  }

  handleAddSessionOk = (e, props) => {
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { description = '', sessionDate, sessionDateTime, sessionType } = values
        const sessionDay = `${sessionDate.format('YYYY-MM-DD')} `
        const submitData = {
          sessionType,
          description,
          sessionStartTime: sessionDay + sessionDateTime[0].format('HH:mm:ss'),
          sessionEndTime: sessionDay + sessionDateTime[1].format('HH:mm:ss')
        }
        if (values.loginAccount) {
          submitData.trainerId = values.loginAccount
        }

        const callback = () => {
          this.setState({
            visible: false,
          });
          form.resetFields()
          this.getData(this.state.reqDto, () => {
            this.setState({ isShowTable: true })
          })
        }
        const handleError = (error) => {
          // set updateSessionValidation
          this.setState({
            updateSessionValidation: {
              status: 'error',
              msg: error.message,
              code: error.code
            }
          });
        }
        this.props.addSession(submitData, callback, handleError)
      }
    });
  }

  handleCancel = (props) => {
    props.form.resetFields()
    this.setState({
      userId: '',
      visible: false,
      updateSessionValidation: {}
    });
  };


  handleTableChange = (pagination, filter, sorter) => {
    const { current, pageSize } = pagination;
    const order = get(sorter, "order", "");
    const orderBy = order === 'ascend' ? 1 : -1
    const filed = get(sorter, "field", "");
    // map field to db field
    const orderFieldMap = {
      sessionStartTime: 'START_TIME',
      sessionEndTime: 'END_TIME',
    }
    const orderField = orderFieldMap[filed] || ''

    const cb = () => {
      this.setState({ isShowTable: true })
      this.setState({ currentPageNumber: current })
      this.setState({ orderBy, orderField })
    }
    this.props.searchSessions(
      {
        pageSize,
        currentPageNumber: current,
        orderBy,
        orderField,
        ...this.state.reqDto
      },
      cb
    );
  };


  searchTrainerAccount = (value, getTypeCode, type = 'account') => {
    const typeCode = typeof getTypeCode === 'function' ? getTypeCode('sessionType') : getTypeCode
    const params = {}

    if (type === 'account') {
      params.loginAccount = value
    } else {
      params.username = value
    }
    this.props.getTainerAccounts({
      roleType: typeCode,
      ...params
    })
  }

  searchAllAccount = (value) => {
    this.props.getAllAccounts({
      loginAccount: value
    })
  }


  // handle Transfer on change
  handleTransferChange = targetKeys => {
    const { addAttendanceValidateInfo } = this.state
    const newValidateInfo = addAttendanceValidateInfo
    newValidateInfo.isChecked = false
    this.setState({ targetKeys, newValidateInfo });
  };




  render() {
    const { form: { getFieldDecorator, getFieldValue, setFieldsValue },
      AddSessionReducer: { sessionTypes, loginsAccounts, allAccounts, sessions, totalPage, total, pageSize, currentPageNumber },
      PublicReducer: { loading },
      AddAttendanceReducer: { attendancesList }
    } = this.props
    const { visible, isShowTable, registrations, modalType, attendanceVisible, addAttendanceValidateInfo, updateSessionValidation, targetKeys, fileName } = this.state


    const username = sessionStorage.getItem('username')
    const userId = sessionStorage.getItem('userId')
    const roleid = sessionStorage.getItem('roleid')
    const email = sessionStorage.getItem('email')
    const isTrainer = !isPambPruventureAdmin(roleid)

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <Button
            disabled={registrations.length === 0}
            onClick={this.handleAddSession}
            className={registrations.length !== 0 ? styles.addSessionBtn : ''}
            size='large'
            icon="plus-circle">Add Session
          </Button>
        </div>
        <Form
          colon={false}
          {...formLayout}
          labelAlign="left"
          className={styles.ant_form}
        >
          <Row {...flexRow}>
            <Col {...flexCol1}>
              <Form.Item label="Session Type">
                {getFieldDecorator("sessionType", {
                  initialValue: ''
                })(
                  <Select
                    onChange={(e) => {
                      this.searchTrainerAccount('', e);
                      setFieldsValue({ trainerId: '' })
                    }}
                  >
                    <Option value="">All</Option>
                    {sessionTypes.map(elt => <Option key={elt.sessionTypeCode} value={elt.sessionTypeCode}>{elt.sessionType}</Option>)}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col {...flexCol1}>
              <Form.Item label="Session Date">
                {getFieldDecorator("sessionDate")(<DatePicker />)}
              </Form.Item>
            </Col>
            <Col {...flexCol1}>
              <Form.Item label="Trainer">
                {getFieldDecorator("trainerId", {
                  initialValue: isTrainer ? userId : ''
                })(
                  <Select
                    showSearch
                    showArrow
                    defaultActiveFirstOption={false}
                    filterOption={false}
                    onSearch={debounce((e) => { this.searchTrainerAccount(e, getFieldValue, 'name') }, 500)}
                    optionFilterProp="child">
                    <Option value="">All</Option>
                    {
                      isTrainer &&
                      <Option value={userId}>Me</Option>
                    }
                    {loginsAccounts?.length &&
                      loginsAccounts.filter(elt => elt.userId !== userId).map((elt, index) => {
                        return (
                          <Option key={elt.userId + index} value={elt.userId}>
                            {elt.username}
                          </Option>
                        );
                      })
                    }
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          {
            !isTrainer &&
            <Row
              {...flexRow}
            >
              <Col {...flexCol1}>
                <Form.Item label="Created By">
                  {getFieldDecorator("createUser", {
                    initialValue: email
                  })(
                    <Select
                      showSearch
                      showArrow
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={debounce((e) => { this.searchAllAccount(e) }, 500)}
                      optionFilterProp="child">
                      <Option value="">All</Option>
                      <Option value={email}>Me</Option>
                      {allAccounts?.length &&
                        allAccounts.filter(elt => elt.loginAccount !== email).map((elt, index) => {
                          return (
                            <Option key={elt.loginAccount + index} value={elt.loginAccount}>
                              {elt.loginAccount}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          }
          <Row {...flexRow}>
            <Col {...flexCol2}>
              <div className={styles.btnBox}>
                <Button className={styles.ant_btn} onClick={this.handleSearch}>
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        {isShowTable ? (
          <Table
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            columns={this.getColumns(isTrainer, username)}
            dataSource={sessions || []}
            pagination={{
              current: currentPageNumber,
              pageSize,
              total,
              totalPage
            }}
            size="small"
            loading={
              loading
            }
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(record) => record.sessionId}
            scroll={{}}
          />
        ) : null}

        <ModalForm
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          visible={visible}
          sessionTypes={sessionTypes}
          loginAccounts={loginsAccounts}
          searchTrainerAccount={this.searchTrainerAccount}
          type={modalType}
          initialValues={this.state.modalInitialValues}
          updateSessionValidation={updateSessionValidation}
        />

        <AddAttendanceModalForm
          visible={attendanceVisible}
          fileName={fileName}
          handleCancel={this.handleAddAttendanceCancel}
          onChange={this.handleTransferChange}
          handleOk={this.handleAddAttendanceOk}
          targetKeys={targetKeys}
          attendancesList={attendancesList}
          handleAttendancesChange={this.handleAttendancesChange}
          validateInfo={addAttendanceValidateInfo}
          handleUpdateImport={this.handleUpdateImport}
          downLoadTemplate={this.downLoadTemplate}
          onChangeFile={this.onChangeFile}
          onRemoveFile={this.onRemoveFile}
          onChangeImportType={this.onChangeImportType}
        />
      </div >
    )
  }
}






const MySessionDetails = Form.create()(Formes);


function Formes2(props) {
  const { form: { getFieldDecorator, setFieldsValue, getFieldValue }, initialValues, searchTrainerAccount, updateSessionValidation, loginAccounts, visible, type = 'add', handleOk, handleCancel, sessionTypes } = props
  // display modal title by type,total 3 types: add,delete,edit
  const modalTitle = {
    add: 'Add Session',
    edit: 'Edit Session',
    delete: 'Are you sure to delete this session?'
  }
  const disabled = type === 'delete'
  const roleId = sessionStorage.getItem('roleid')
  const trainerDisabled = !isPambPruventureAdmin(roleId)

  const initialSessionType = isPambPruventureAdmin(roleId) ?
    (initialValues.sessionType || (sessionTypes?.length ? sessionTypes[0]?.sessionTypeCode : ''))
    : (initialValues.sessionType || (sessionTypes?.length ? sessionTypes.find(elt => elt.isAllowed === true).sessionTypeCode : ''))

  return (
    <Modal
      title={modalTitle[type]}
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText={type === 'delete' ? 'Delete' : 'Confirm'}
      okType='danger'
      width="50%"
      className={styles.ant_dev_modal}
    >
      <Form
        colon={false}
        hideRequiredMark
        {...formLayout2}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <Form.Item label={
              <div className={styles.label}>
                <span>
                  Session Type
                </span>
                <span className={styles.required}>*</span>
              </div>
            }>
              {getFieldDecorator("sessionType", {
                initialValue: initialSessionType,
                rules: [{
                  required: true,
                  message: "All fields should be filled in"
                }]
              })(
                <Select
                  onChange={e => {
                    searchTrainerAccount('', e);
                    setFieldsValue({ loginAccount: '' });
                  }}
                  disabled={disabled || trainerDisabled}>
                  {sessionTypes.map(elt => <Option disabled={!elt.isAllowed} key={elt.sessionTypeCode} value={elt.sessionTypeCode}>{elt.sessionType}</Option>)}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol2}>
            <Form.Item
              label={
                <div className={styles.label}>
                  <span>
                    Session Date Time
                  </span>
                  <span className={styles.required}>*</span>
                </div>
              }
              className={styles.datePickerRow}
              help={updateSessionValidation?.msg}
              validateStatus={updateSessionValidation?.status}
            >
              <Row
                {...flexRow}>
                <Col {...flexCol3}>
                  <Form.Item>
                    {getFieldDecorator("sessionDate", {
                      initialValue: initialValues.sessionDate || '',
                      rules: [{
                        required: true,
                        message: "All fields should be filled in"
                      }]
                    })(
                      <DatePicker className={styles.datePicker} disabled={disabled} />
                    )}
                  </Form.Item>

                </Col>
                <Col {...flexCol3}>
                  <Form.Item
                  >
                    {getFieldDecorator("sessionDateTime", {
                      initialValue: initialValues.sessionDateTime || '',
                      rules: [{
                        required: true,
                        message: "All fields should be filled in"
                      }]
                    })(
                      <TimePicker.RangePicker
                        disabled={disabled}
                        renderExtraFooter={() =>
                          <Button
                            type="link"
                            onClick={() => {
                              setFieldsValue({
                                sessionDateTime: [dayjs(), dayjs()]
                              })
                            }}>Now
                          </Button>
                        }
                        format="HH:mm"
                        placeholder={["Start Time", "End Time"]} />
                    )}
                  </Form.Item>
                </Col>
              </Row>


            </Form.Item>
          </Col>
          {
            !trainerDisabled ? (
              <Col {...flexCol2}>
                <Form.Item label={
                  <div className={styles.label}>
                    <span>
                      Trainer Login Account
                    </span>
                    <span className={styles.required}>*</span>
                  </div>
                }>
                  {getFieldDecorator("loginAccount", {
                    initialValue: initialValues.userId || (loginAccounts.length ? loginAccounts[0]?.userId : ''),
                    rules: [{
                      required: true,
                      message: "All fields should be filled in"
                    }]
                  })(
                    <Select
                      disabled={disabled}
                      showSearch
                      showArrow
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={debounce((e) => { searchTrainerAccount(e, getFieldValue) }, 500)}
                      optionFilterProp="child">
                      {loginAccounts?.length &&
                        loginAccounts.map(elt => {
                          return (
                            <Option key={elt.loginAccount + Math.random()} value={elt.userId}>
                              {elt.loginAccount}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null
          }
          <Col {...flexCol2}>
            <Form.Item label={
              <span className={styles.label}>
                Description
              </span>
            }>
              {getFieldDecorator("description", {
                initialValue: initialValues.description || '',
              })(
                <TextArea disabled={disabled} row={4} maxLength={255} showCount />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}





const ModalForm = Form.create()(Formes2);


export default MySessionDetails;
