import axios from "axios";
import "./axios.config";

const request = async _options => axios(_options);

export const get = (url, params, extra, responseType = "json") => {
  url = parseUrl(url, params, "get");
  return request({
    url,
    method: "GET",
    params: { ...extra },
    responseType
  });
};

export const post = (url, data = {}, params, responseType = "json") => {
  url = parseUrl(url);
  return request({
    data,
    url,
    method: "POST",
    params: { ...params },
    responseType
  });
};

export const deleteReq = (url, data = {}, params, responseType = "json") => {
  url = parseUrl(url);
  return request({
    data,
    url,
    method: "DELETE",
    params: { ...params },
    responseType
  });
};

export const put = (url, data = {}, params, responseType = "json") => {
  url = parseUrl(url);
  return request({
    data,
    url,
    method: "PUT",
    params: { ...params },
    responseType
  });
};

const parseUrl = (url, params, type) => {
  params = params || "";
  const str = Object.keys(params).reduce((result, key) => {
    result += `${key}=${params[key]}&`;
    return result;
  }, "");

  if (type === "get" && isIE()) {
    const time = Date.now();
    const temp = `temp=${time}`;
    return `${url}?${str + temp}`;
  }

  return `${url}${params ? "?" : ""}${str.substr(0, str.length - 1)}`;
};

function isIE() {
  // ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    return true;
  }
  return false;
}
