export const LOGIN = "LOGIN";
export const SET_PSD_SUCCESS = "SET_PSD_SUCCESS";
export const SET_PSD = "SET_PSD";
export const INIT_PSD = "INIT_PSD";
export const FORGET_PSD = "FORGET_PSD";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const BACK = "BACK";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const CHANGE_FORM = "CHANGE_FORM";
export const LOGOUT = "LOGOUT";
export const SAVE_MYPROFILE = "SAVE_MYPROFILE";
export const SAVE = "SAVE";
export const CHECK_CODE_SUCCESS = "CHECK_CODE_SUCCESS";

export const CHANGE_FIELDS = "CHANGE_FIELDS";
export const CHANGE_PSDWORD = "CHANGE_PSDWORD";

export const ADD_ERCOL = "ADD_ERCOL";
export const DELETE_ERCOL = "DELETE_ERCOL";
export const CHANGE_ERCOLVAL = "CHANGE_ERCOLVAL";
export const CHANGE_ERYEAR = "CHANGE_ERYEAR";

export const PUBLIC_LOADING = "PUBLIC_LOADING";
