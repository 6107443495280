import { message } from "antd";
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const SAVE = "pamb/TRagentDetailReducer/SAVE";

const initState = {
  agentDetailData: {},
  mtaAgentDetailData: {},
  cpdliamAgentDetailData: {},
  cpdmtaAgentDetailData: {},
  detailLoading: false
};

// reducer
export default function TRagentDetailReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getAgentDetail(payload) {
  const { userId, type } = payload;
  return dispatch => {
    dispatch({
      type: SAVE, payload: { detailLoading: true }
    })
    get(`/training/function/${userId}`, { type })
      .then(res => {
        const { data } = res;
        if (type === "LIAM") {
          dispatch({ type: SAVE, payload: { agentDetailData: data || {}, detailLoading: false } });
        } else {
          dispatch({ type: SAVE, payload: { mtaAgentDetailData: data || {}, detailLoading: false } });
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE, payload: { detailLoading: false }
        })
      });
  };
}

export function getcpdHoursAgentDetail(payload) {
  const { type } = payload;
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: { detailLoading: true }
    });
    post(`/cpd/function/detail`, payload)
      .then(res => {
        const { data } = res;
        if (type === "LIAM") {
          dispatch({
            type: SAVE,
            payload: { cpdliamAgentDetailData: data || {}, detailLoading: false }
          });
        } else {
          dispatch({
            type: SAVE,
            payload: { cpdmtaAgentDetailData: data || {}, detailLoading: false }
          });
        }
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: { detailLoading: false }
        });
      });
  };
}

export function updateStatus(payload, callback) {
  return () => {
    get("/training/approve", payload)
      .then(() => {
        message.info("Update Successfully", globalPromptTime, callback);
      })
      .catch(() => {

      });
  };
}

export const handleDownloadxls = (payload) => {
  return async () => {
    await handleCheckToken()
    const { userId } = payload;
    get(`/training/report/${userId}`, payload, "", "blob")
      .then(res => {
        const fileName = "agent_detail.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {

      });
  };
}

export const handleDownloadxlsCpd = (payload) => {
  return async () => {
    await handleCheckToken()
    get("/cpd/function/report", payload, "", "blob")
      .then(res => {
        const fileName = "agent_detail.xls";
        downloadFile(res, fileName);
      })
      .catch(() => {

      });
  };
}
