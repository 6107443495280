import React, { Component } from "react";
import { notFound } from "../../images"
import styles from './Forbidden.module.less';
import { onlyClearLocalData } from '../../utils';

export default class NotAuth extends Component {
  componentDidMount() {
    const token = sessionStorage.getItem('user');
    if (token) {
      onlyClearLocalData();
      window.location.reload();
    }
  }


  render() {
    return (
      <div className={styles.container}>
        <img src={notFound} className={styles.img} alt="" />
        <div className={styles.right}>
          <div className={styles.num}>401</div>
          <div className={styles.text}>Sorry, you are not authorized to access this application.</div>
        </div>
      </div>
    );
  }
}
