// import { CHANGE_FIELDS } from "../actionTypes";

export function changeVals(type, val) {
  return dispatch => {
    dispatch({
      type,
      payload: val
    });
  };
}
