export const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

export const formLayout2 = {
  labelCol: { span: 13 },
  wrapperCol: { span: 11 }
};

export const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export const flexRow = {
  gutter: [22, 0]
};
