import { message } from 'antd'
import { get, post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pacs/GrantHoursReducer/SAVE";
const ALL = 'all'

const initState = {
  tableLoading: false,
  grantUserData: {
    total: 0,
    pageSize: 10,
    userList: []
  },
  grantYearList: [],
  fcCodeList: [],
  fcCodeList2: [],
  grantDetailData: {}
};

// reducer
export default function GrantHoursReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getTabelData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        tableLoading: true
      }
    });
    post("/GrantHoursController/getGrantHoursList", {}, payload)
      .then(res => {
        const { data = {} } = res;
        dispatch({
          type: SAVE,
          payload: {
            grantUserData: {
              total: data.total,
              pageSize: data.pageSize,
              userList: data.data || [],
              currentPageNumber: data.currentPageNumber,
            },
            tableLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            tableLoading: false
          }
        });
      });
  };
}

export function getYearList() {
  return dispatch => {
    get("/pacs/cpd/yearDesc")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            grantYearList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getFcCodeList(payload, cb) {
  return dispatch => {
    get("/GrantHoursController/getPacsUserInfoSelect2", payload)
      .then(res => {
        const { data = [] } = res;

        if (ALL.indexOf(payload.ownerName.toLowerCase()) > -1) {
          data.unshift({
            userId: '',
            agentCode: 'All'
          })
        }

        dispatch({
          type: SAVE,
          payload: {
            fcCodeList: data || []
          }
        });
        if (cb) { cb() }
      })
      .catch(() => { });
  };
}

export function getFcCodeList2(payload, cb) {
  return dispatch => {
    get("/GrantHoursController/getPacsUserInfoSelect2", payload)
      .then(res => {
        const { data = {} } = res;
        dispatch({
          type: SAVE,
          payload: {
            fcCodeList2: data || []
          }
        });
        if (cb) { cb() }
      })
      .catch(() => { });
  };
}

export function updateGrant(payload, cb) {
  return () => {
    post('/GrantHoursController/updateGrantHours', payload)
      .then(() => {
        message.info("Saved successfully", globalPromptTime, cb);
      })
      .catch(() => { });
  };
}

export function createGrant(payload, cb) {
  return () => {
    post('/GrantHoursController/addGrantHours', payload)
      .then(() => {
        message.info("Saved successfully", globalPromptTime, cb);
      })
      .catch(() => { });
  };
}
export function deleteGrant(payload, cb) {
  return () => {
    post('/GrantHoursController/deleteGrantHours', {}, { id: payload })
      .then(() => {
        message.info("Deleted successfully", globalPromptTime, cb);
      })
      .catch(() => { });
  };
}

export function getGrantDetail(payload) {
  return dispatch => {
    post('/GrantHoursController/GrantHoursDetail', {}, { id: payload })
      .then((res) => {
        const { data } = res
        const { data: resData } = data || {}
        dispatch({
          type: SAVE,
          payload: {
            grantDetailData: resData || {}
          }
        });
      })
      .catch(() => { });
  };
}

export function resetData() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        grantDetailData: {}
      }
    });
  };
}
