import React from "react";
import { Form, Button, Col, Spin, Icon, message, Select, Input, Row } from "antd";
import { withRouter } from "react-router-dom";
import { post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";
import styles from "./index.module.less";

const { Option } = Select;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@withRouter
class AddUserByRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  save = async (e) => {
    e.preventDefault();
    const { form, url, afterSaveCb } = this.props;
    form.validateFields({ first: true }, async (err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });

        try {
          await post(url, values);

          this.setState({
            loading: false
          }, () => {
            message.info("Saved successfully", globalPromptTime, afterSaveCb);
          });
        } catch (error) {
          this.setState({
            loading: false
          });
        }
      }
    });
  };

  goBack = () => {
    const { lastPage, history } = this.props;
    history.push(lastPage)
  }

  getRoleIDByCode = (roleList, code) => {
    if (roleList.length === 0) {
      return roleList;
    }

    return roleList.filter(item => {
      return item.code === code;
    });
  }

  getRoleInitialValue = () => {
    const { rolerList, roleCode } = this.props;
    const roleList = this.getRoleIDByCode(rolerList, roleCode);
    const role = roleList && roleList[0];

    if (!role) {
      return '';
    }

    return role.roleid;
  }

  handleEmailChange = e => {
    this.props.form.setFieldsValue({
      loginaccount: e.target.value
    });
  };


  render() {
    const { title, rolerList, buList } = this.props;
    const { form: { getFieldDecorator } } = this.props;
    const { loading } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.heading}>
          <Icon
            type="left"
            style={{ marginRight: 15, cursor: "pointer" }}
            onClick={() => { this.goBack() }}
          />
            {title}
        </div>
        <Spin spinning={loading}>
          <Form
            className="ant_form"
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: rolerList[0] && rolerList[0].country
                  })(
                    <Select disabled>
                      {buList.map(elt => (
                        <Option value={elt.id} key={elt.id}>
                          {elt.value}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("roleid", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      }
                    ],
                    initialValue: this.getRoleInitialValue()
                  })(
                    <Select disabled>
                      {rolerList.map(elt => (
                        <Option value={elt.roleid} key={elt.roleid}>
                          {elt.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("loginaccount", {})(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please fill in the mandatory fields"
                      },
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        type: "email",
                        message: "Please input the correct email format"
                      }
                    ]
                  })(<Input onChange={this.handleEmailChange} />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactnumber", {
                    rules: [
                      {
                        max: 50,
                        message: "fields cannot be longer than 50 characters"
                      },
                      {
                        pattern: /^[0-9]{0,}$/,
                        message: "Only numbers are allowed"
                      }
                    ]
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Col className={styles.submitBox}>
            <Form.Item>
              <Button type="primary" onClick={this.save} className={styles.submitBtn}>Save</Button>
            </Form.Item>
          </Col>
        </Spin>
      </div>
    );
  }
}

export default AddUserByRole;
