import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Input,
  Tooltip,
  Table,
  Empty,
  notification,
  Icon,
  Modal,
  Popconfirm,
  Divider
} from "antd";
// import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../utils/global";
import {
  search,
  create,
  update,
  deleteItem,
  init_data,
  getCourseNameListByCode,
  getCourseNameListByName,
  downLoadAgentTrainingCodeExcel,
  downLoadAgentTrainingCodeTxt,
  deleteTrainingCodeMappingCourseCode
} from "../../../../redux/pacsReducer/CPDReportReducers/TrainingCodeMapReducer";
import styles from "./styles.module.less";

const { Option } = Select;

const CreateForm = Form.create({
  name: "PACSTrainingCodeMap_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("PACSTrainingCodeMap", changedFields);
  },
  mapPropsToFields(props) {
    return {
      relationship: Form.createFormField({
        ...props.relationship,
        value: props.relationship.value
      }),
      system: Form.createFormField({
        ...props.system,
        value: props.system.value
      }),
      codeCode: Form.createFormField({
        ...props.codeCode,
        value: props.codeCode.value
      }),
      trainingCode: Form.createFormField({
        ...props.trainingCode,
        value: props.trainingCode.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading, columns } = props;
  // const { TextArea } = Input;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Code">
              {getFieldDecorator("codeCode", {})(
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  allowClear
                  defaultActiveFirstOption={false}
                  placeholder="Course Code"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option.props.children.props) {
                      return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  }
                  }
                  onChange={(e, option) => {
                    if (option.props.children.props) {
                      const val = option.props.children.props.title;
                      props.onChange("PACSTrainingCodeMap", {
                        nowCode: {
                          value: val
                        },
                      });
                    }
                  }}
                >
                  {props.codeList.length
                    ? props.codeList.map(item => (
                      <Option value={item.courseId} key={item.courseId}>
                        <Tooltip placement="top" title={item.courseCode}>
                          {item.courseCode}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="System">
              {getFieldDecorator("system", {})(
                <Select style={{ width: "100%" }}>
                  <Option value="" key="">All</Option>
                  <Option value="LA" key="LA">LA</Option>
                  <Option value="GA" key="GA">GA</Option>
                  <Option value="LA,GA" key="LA,GA">LA,GA</Option>
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Relationship">
              {getFieldDecorator("relationship", {})(
                <Select style={{ width: "100%" }}>
                  <Option value="" key="">All</Option>
                  <Option value="AND" key="AND">AND</Option>
                  <Option value="OR" key="OR">OR</Option>
                </Select>
                // <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
              )}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Training Code">
              {getFieldDecorator(
                "trainingCode",
                {}
              )(<Input />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Button
          className={styles.btn}
          icon="plus"
          style={{ marginBottom: "20px" }}
          onClick={() => {
            props.showArrBox(false)
          }}
        >
          Add
        </Button>
        <div>
          <Table
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            // style={{ display: props.searchList.length ? "block" : "none" }}
            bordered
            dataSource={props.searchList}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            size="small"
            rowKey="index"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { CPDReportReducers: { TrainingCodeMapReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: TrainingCodeMapReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    search,
    create,
    update,
    deleteItem,
    init_data,
    getCourseNameListByCode,
    getCourseNameListByName,
    downLoadAgentTrainingCodeExcel,
    downLoadAgentTrainingCodeTxt,
    deleteTrainingCodeMappingCourseCode
  }
)
class TrainingCodeMap extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showArrBox = this.showArrBox.bind(this);
    this.saveArrBox = this.saveArrBox.bind(this);
    this.closeArrBox = this.closeArrBox.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      showBox: false,
      trainingCodeArr: [],
      saveTrainingCodeArr: [],
      edit: false,
      editId: null,
      visible: false,
      columns: [
        {
          title: "Training Code",
          dataIndex: "trainingCode",
          align: "center",
          width: 150,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "System",
          dataIndex: "system",
          align: "center",
          width: 100,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Relationship",
          dataIndex: "relationShip",
          align: "center",
          width: 150,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Course Code",
          dataIndex: "courseCodes",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Action",
          dataIndex: "",
          align: "center",
          width: 200,
          render: (text, record) => (
            /* eslint-disable */
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
              <Tooltip placement="top">
                <a
                  onClick={() => {
                    this.showArrBox(true, record, text);
                  }}
                >
                  Add Course Code
                </a>
              </Tooltip>
              <Popconfirm overlayStyle={{ width: "200px" }} placement="left" title="Confirm to delete?"
                onConfirm={() => {
                  const { fields, search, deleteTrainingCodeMappingCourseCode } = this.props;
                  // console.log("record:", record)
                  deleteTrainingCodeMappingCourseCode(record.id, () => {
                    notification.success({
                      message: "Delete the success!"
                    })
                    // console.log("fields:", fields) // eslint-disable-line
                    search({
                      courseCode: fields.nowCode.value,
                      relationShip: fields.relationship.value,
                      system: fields.system.value,
                      trainingCode: fields.trainingCode.value,
                      pageSize: this.state.pageSize,
                      currentPageNumber: this.state.pageIndex,
                    });
                    // console.log("callback:")
                  })
                }}
                okText="Yes" cancelText="No">
                <a>Delete</a>
              </Popconfirm>
            </div>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.init_data();
    this.props.getCourseNameListByCode("");
    // this.props.search({
    //   courseIdList: [],
    //   fromCompletionDate: "",
    //   toCompletionDate: "",
    //   pageSize: this.state.pageSize,
    //   currentPageNumber: this.state.pageIndex
    // });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      // const { pageSize, pageIndex } = this.state
      if (!err) {
        // console.log("fields:", fields) // eslint-disable-line
        this.props.search({
          courseCode: fields.nowCode.value,
          relationShip: fields.relationship.value,
          system: fields.system.value,
          trainingCode: fields.trainingCode.value,
          pageSize: this.state.pageSize,
          currentPageNumber: this.state.pageIndex,
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    // const { tableData: fields } = this.state;
    const { fields } = this.props;
    // const courseIdList = Array.from(new Set(fields.courseName.value.concat(fields.courseCode.value)));
    this.props.search({
      courseCode: fields.nowCode.value,
      relationShip: fields.relationship.value,
      system: fields.system.value,
      trainingCode: fields.trainingCode.value,
      pageSize,
      currentPageNumber: current
    });
  };


  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  saveArrBox() {
    const nowTrainingCodeArr = [...this.state.trainingCodeArr];
    let postData = []
    // const FilterArr = []
    /* eslint-disable */
    // courseCode: "",
    // trainingCode: "",
    // system: "",
    // relationship: ""

    for (let i = 0; i < nowTrainingCodeArr.length; i++) {
      if (!nowTrainingCodeArr[i].courseCode.length || !nowTrainingCodeArr[i].trainingCode || !nowTrainingCodeArr[i].system || !nowTrainingCodeArr[i].relationship) {
        notification.error({
          message: "Please fill in the mandatory fields",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
        return;
      }

      const courseCodes = nowTrainingCodeArr[i].showCourseCode.join(",")
      const courseIds = nowTrainingCodeArr[i].courseCode.join(",")
      if (this.state.edit) {
        postData = {
          CourseCodes: courseCodes,
          CourseIds: courseIds,
          Id: this.state.editId
        }
      }
      else {
        postData.push(
          {
            courseCodes,
            courseIds,
            relationShip: nowTrainingCodeArr[i].relationship,
            system: nowTrainingCodeArr[i].system,
            trainingCode: nowTrainingCodeArr[i].trainingCode
          }
        )
      }
    }

    /* eslint-disable */

    // /* eslint-disable */
    // const FilterArr = [];
    // for (let x = 0; x < nowTrainingCodeArr.length; x++) {
    //   if (FilterArr.indexOf(nowTrainingCodeArr[x].courseName) < 0) {
    //     FilterArr.push(nowTrainingCodeArr[x].courseName);
    //   } else {
    //     if (nowTrainingCodeArr[x].courseName) {
    //       notification.error({
    //         message: "Course Name already exists",
    //         duration: globalPromptTime10s,
    //         className: "ant-style-notification-error"
    //       });
    //       nowTrainingCodeArr[index].courseName = "";
    //       nowTrainingCodeArr[index].showCourseName = "";
    //       break;
    //     }
    //   }
    // }
    // console.log("nowTrainingCodeArr:", nowTrainingCodeArr)
    // console.log("postData:", postData);
    // /* eslint-disable */

    if (this.state.edit) {
      this.props.update(postData,
        () => {
          const { fields } = this.props;
          this.props.search({
            courseCode: fields.nowCode.value,
            relationShip: fields.relationship.value,
            system: fields.system.value,
            trainingCode: fields.trainingCode.value,
            pageSize: this.state.pageSize,
            currentPageNumber: this.state.pageIndex,
          });
        }
      );
    } else {
      this.props.create(postData,
        () => {
          const { fields } = this.props;
          this.props.search({
            courseCode: fields.nowCode.value,
            relationShip: fields.relationship.value,
            system: fields.system.value,
            trainingCode: fields.trainingCode.value,
            pageSize: this.state.pageSize,
            currentPageNumber: this.state.pageIndex,
          });
        }
      );
    }
    this.setState(() => ({
      showBox: false,
      edit: false,
      editId: null
    }));
    // let valStr = "";
    // nowTrainingCodeArr.forEach((item) => {
    //   if (!valStr) {
    //     valStr += `${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
    //   } else {
    //     valStr += ` , ${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
    //   }
    // });
    // this.setState(() => ({
    //   showBox: false,
    //   saveTrainingCodeArr: nowTrainingCodeArr
    // }));
    // this.props.changeVals("PACSTrainingCodeMap", {
    //   codeName: {
    //     value: valStr
    //   }
    // });
  }

  showArrBox(edit, record) {
    this.props.getCourseNameListByCode("");
    const saveTrainingCodeArr = [...this.state.saveTrainingCodeArr];
    if (edit) {
      const courseCode = record.courseIds.split(",")
      const showCourseCode = record.courseCodes.split(",")
      this.setState(() => ({
        showBox: true,
        edit: true,
        editId: record.id,
        trainingCodeArr: [
          {
            courseCode,
            showCourseCode,
            trainingCode: record.trainingCode,
            system: record.system,
            relationship: record.relationShip,
          }
        ]
      }));
    }
    else {
      this.setState(() => ({
        edit: false,
        editId: null
      }), () => {
        if (!saveTrainingCodeArr.length) {
          this.setState(() => ({
            showBox: true,
            trainingCodeArr: [
              {
                courseCode: [],
                showCourseCode: [],
                trainingCode: "",
                system: "",
                relationship: ""
              }
            ]
          }));
        }
        else {
          this.setState(() => ({
            showBox: true,
            trainingCodeArr: saveTrainingCodeArr
          }));
        }
      });
    }
  }

  closeArrBox() {
    this.setState(() => ({
      showBox: false,
      trainingCodeArr: [...this.state.saveTrainingCodeArr]
    }));
  }

  render() {
    const { Option } = Select;
    const { nameList, codeList } = this.props.fields;
    const { trainingCodeArr, showBox, saveTrainingCodeArr } = this.state;
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      getCourseNameListByName: this.props.getCourseNameListByName,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange,
      downLoadAgentTrainingCodeExcel: this.props.downLoadAgentTrainingCodeExcel,
      downLoadAgentTrainingCodeTxt: this.props.downLoadAgentTrainingCodeTxt,
      showArrBox: this.showArrBox,
      trainingCodeArr: this.state.trainingCodeArr
    };
    return (
      <div className={styles.container}>
        <Modal
          title="Update"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          {/* <EditForm {...props} {...this.props.fields} /> */}
        </Modal>
        {
          showBox ?
            <div className={styles.showBox}>
              <div style={{ marginBottom: "20px", width: "100%", display: "flex", justifyContent: "space-between", color: "#ed1b2e", alignItems: "center" }}>
                {
                  !this.state.edit ? (
                    <Button
                      type="primary"
                      style={{ width: "150px" }}
                      className={`${styles.btnColor} ${styles.btnText}`}
                      onClick={() => {
                        const nowTrainingCodeArr = [...trainingCodeArr];
                        nowTrainingCodeArr.push({
                          courseCode: [],
                          showCourseCode: [],
                          trainingCode: "",
                          system: "",
                          relationship: ""
                        });
                        this.setState(() => ({
                          trainingCodeArr: nowTrainingCodeArr
                        }), () => {
                        });
                      }}
                    >
                      Add Training Code
                    </Button>
                  ) : <div></div>
                }
                <Icon
                  type="close-circle"
                  style={{ marginRight: 15, cursor: "pointer", fontSize: "25px" }}
                  onClick={this.closeArrBox}
                />
              </div>
              <div className={styles.showBoxContent}>
                {/* <div className={styles.formCol} style={{ paddingRight: '131px', display: trainingCodeArr.length ? "flex" : "none" }}>
                  <div style={{ width: "20%" }}>Training Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "20%" }}>System <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "20%" }}>Relationship <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "20%" }}>Course Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                </div> */}
                {
                  trainingCodeArr.map((item, index) => {
                    return (
                      <div className={styles.formBox}>
                        <div className={styles.formBoxCol}>
                          <div className={styles.formBoxItem}>
                            <div>Training Code</div>
                            <Input style={{ width: "100%" }} disabled={this.state.edit} value={item.trainingCode} onChange={(e) => {
                              const nowTrainingCodeArr = [...trainingCodeArr];
                              // console.log("nowTrainingCodeArr:", nowTrainingCodeArr)
                              nowTrainingCodeArr[index].trainingCode = e.target.value;
                              this.setState(() => ({
                                trainingCodeArr: nowTrainingCodeArr
                              }));
                            }} placeholder="Training Code"></Input>
                          </div>
                          <div className={styles.formBoxItem}>
                            <div>System</div>
                            <Select
                              style={{ width: "100%" }}
                              showSearch
                              allowClear
                              disabled={this.state.edit}
                              placeholder="System"
                              value={item.system}
                              onChange={(val) => {
                                const nowTrainingCodeArr = [...trainingCodeArr];
                                nowTrainingCodeArr[index].system = val;
                                this.setState(() => ({
                                  trainingCodeArr: nowTrainingCodeArr
                                }));
                              }}
                            >
                              <Option value="LA" key="LA">LA</Option>
                              <Option value="GA" key="GA">GA</Option>
                              <Option value="LA,GA" key="LA,GA">LA,GA</Option>
                            </Select>

                          </div>
                          <div className={styles.formBoxItem}>
                            <div>Relationship</div>
                            <Select
                              style={{ width: "100%" }}
                              showSearch
                              allowClear
                              disabled={this.state.edit}
                              placeholder="Relationship"
                              value={item.relationship}
                              onChange={(val) => {
                                const nowTrainingCodeArr = [...trainingCodeArr];
                                nowTrainingCodeArr[index].relationship = val;
                                this.setState(() => ({
                                  trainingCodeArr: nowTrainingCodeArr
                                }));
                              }}
                            >
                              <Option value="AND" key="AND">AND</Option>
                              <Option value="OR" key="OR">OR</Option>
                            </Select>
                          </div>
                        </div>
                        <div className={styles.formBoxCol}>
                          <div className={styles.formBoxItem} style={{ width: !this.state.edit ? "80%" : "100%", marginBottom: '20px' }}>
                            <div>Course Code</div>
                            <Select
                              style={{ width: "100%" }} value={item.courseCode}
                              showSearch
                              allowClear
                              defaultActiveFirstOption={false}
                              placeholder="Course Code"
                              mode="tags"
                              onChange={(val, oo) => {
                                const nowTrainingCodeArr = [...trainingCodeArr];
                                let showCourseCode = [];
                                if (oo.length) {
                                  oo.forEach((y) => {
                                    showCourseCode.push(y.props.children.props.title)
                                  })
                                }
                                nowTrainingCodeArr[index].courseCode = val;
                                nowTrainingCodeArr[index].showCourseCode = showCourseCode;
                                this.setState(() => ({
                                  trainingCodeArr: nowTrainingCodeArr
                                }));
                              }}
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                if (option.props.children.props) {
                                  return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              }
                              }
                            >
                              {codeList.length
                                ? codeList.map(item => (
                                  <Option value={item.courseId} key={item.courseId}>
                                    <Tooltip placement="top" title={item.courseCode}>
                                      {item.courseCode}
                                    </Tooltip>
                                  </Option>
                                ))
                                : null}
                            </Select>
                          </div>
                          {
                            !this.state.edit ? (
                              <Button
                                type="primary"
                                className={`${styles.btnColor} ${styles.btnText}`}
                                style={{ width: "15%", marginTop: "31px", marginBottom: "20px" }}
                                onClick={() => {
                                  const nowTrainingCodeArr = [...trainingCodeArr];
                                  nowTrainingCodeArr.splice(index, 1);
                                  this.setState(() => ({
                                    trainingCodeArr: nowTrainingCodeArr
                                  }))
                                }}
                              >
                                Delete
                              </Button>
                            ) : null
                          }

                        </div>
                        <Divider />
                      </div>
                    )
                  })
                }
                <div style={{ width: "100%", justifyContent: "center", marginTop: "20px", display: 'flex' }}>
                  <Button
                    type="primary"
                    style={{ width: "100px", marginRight: "20px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={this.saveArrBox}
                  >
                    Save
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{ width: "100px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={() => {
                      this.setState(() => ({
                        showBox: false
                      }))
                    }}
                  >
                    Cancel
                  </Button> */}
                </div>
              </div>
            </div> : null
        }
        <div className={styles.title}>Training Code</div>
        <CreateForm {...props} {...this.props.fields} />
        {/* <div className={styles.col_dev_left_short}>
        </div> */}
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "rgb(38, 98, 208)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};
const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 24,
  xs: 24
};

export default withRouter(TrainingCodeMap);
