import { notification } from "antd";
import { globalPromptTime10s } from "../../../utils/global";
import { get, post } from "../../../utils/request";
import { loading } from "../../PublicReducer";

const INIT_DATA_REMINDERDETAIL = "PAMB/INIT_DATA_REMINDERDETAIL";

const initState = {
  pambReminderRuleId: "",
  MandatoryRequirement: "",
  DueDate: "",
  SMSTemplate: "",
  EMailTemplate: "",
  setHour: "",
  setMinute: "",
  beforeDays: "",
  frequency: "",
  frequencyUnit: ""
};

// reducer
export default function ReminderDetailReducer(state = initState, action) {
  switch (action.type) {
    case "ReminderDetail":
      return { ...state, ...action.payload };
    case INIT_DATA_REMINDERDETAIL:
      return { ...initState };
    default:
      return state;
  }
}
export function init_data() {
  return {
    type: INIT_DATA_REMINDERDETAIL
  };
}

export function save_data(payload) {
  return {
    type: "ReminderDetail",
    payload
  };
}

export function setRule(data, history, location) {
  return dispatch => {
    dispatch(loading(true));
    post("/reminder", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          notification.success({
            message: "Successfully set up",
            duration: globalPromptTime10s
          });
          history.push("/home/system_settings/reminder_rule/reminder_list", {
            search: location.state.search
          });
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function getDetail(id) {
  return dispatch => {
    dispatch(loading(true));
    get("/reminder/" + id)
      .then(res => {
        if (res.success) {
          const data = res.data;
          const arr = data.sendTime ? data.sendTime.split(":") : [0, 0];
          dispatch(
            save_data({
              pambReminderRuleId: data.pambReminderRuleId,
              MandatoryRequirement: data.mandatoryRequirement,
              DueDate: data.dueDate,
              SMSTemplate: data.smsTemplate,
              EMailTemplate: data.emailTemplate,
              setHour: arr[0],
              setMinute: arr[1],
              beforeDays: data.beforeDays,
              frequency: data.frequency,
              frequencyUnit: data.frequencyUnit
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
