import React from "react";
import { Spin, Icon } from 'antd'
import { connect } from "react-redux";
import styles from "./index.module.less";
import ItemWrap from "./ItemWrap";

@connect(
  ({ pambReducer: { systemSettingsReducers: { TimeJobReducer } } }) => ({
    TimeJobReducer
  }),
  {}
)
class TimeJob extends React.Component {
  state = {
    dailyToggle: false,
  }

  toggleItem = (id) => {
    const { dailyToggle } = this.state
    this.setState({ dailyToggle: dailyToggle === id ? '' : id })
  }

  render() {
    const { TimeJobReducer: { submitLoading, timeJobList } } = this.props
    const { dailyToggle } = this.state
    return (
      <div className={styles.container}>
        <Spin spinning={submitLoading}>
          <div className={styles.heading}>
            Time Job
          </div>
          {
            timeJobList.map(({ id, name, children }) => {
              const isActive = dailyToggle === id
              return (
                <div className={styles.wrapper} key={id}>
                  <div className={styles.title} onClick={() => this.toggleItem(id)}>
                    <Icon type={isActive ? 'caret-down' : 'caret-right'} />
                    <span style={{ marginLeft: 5 }}>{name}</span>
                  </div>
                  <div className={isActive ? styles.dailyItemActive : styles.dailyItemInactive}>
                    {children.map((elt, index) => <ItemWrap key={elt.url} itemprop={{ ...elt, index }} />)}
                  </div>
                </div>
              )
            })
          }

        </Spin>
      </div>
    )
  }
}

export default TimeJob;
