import React, { useEffect, useState } from 'react';
import {
  Button,
} from 'antd-v5';
import { useRequest } from 'ahooks';
import { getRequirementSettingList, deleteRequirementSetting, saveRequirementSetting } from './api'
import RequirementSettingPanel from './RequirementSettingPanel';
import styles from './RequirementSettingDetails.module.less'


const RequirementSettingDetails = (props) => {
  const [list, setList] = useState([])
  const initSetting = { fromMonth: "", toMonth: "", frequency: "MONTHLY", minSessions: "", disabled: false }


  const { run } = useRequest(getRequirementSettingList, {
    manual: true,
    onBefore: () => {
    },
    onSuccess: (result) => {
      const { data = [] } = result
      const res = [...data]
      setList(res)
    },
    onFinally: () => {
      checkBackToCreate()
    }
  })

  useEffect(() => {
    getSettingList()
  }, [])

  const handleAddSetting = () => {
    list.push(initSetting)
    setList([...list])
  }

  const getSettingList = () => {
    if (props.location.state?.params) {
      const res = [...props.location.state.params]
      props.history.replace({ state: {} })
      setList(res)
    } else {
      run()
    }
  }

  const checkBackToCreate = () => {
    if (list.length === 0) {
      props.history.push('/home/scheme_management/pru_venture_requirement_setting/create')
    }
  }


  const handleDeletePanel = async (id, index) => {
    if (id) {
      await deleteRequirementSetting(id)
      run()
    }
    list.splice(index, 1)
    setList([...list])
    checkBackToCreate()
  }

  const handleConfirmSetting = async (data, cb = () => { }, handleError = () => { }) => {
    try {
      const res = await saveRequirementSetting(data)
      if (res.success) {
        cb()
        run()
        handleError({
          status: 'success',
          help: ''
        })
      }
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <div className={styles.container}>
      {list.length ? list.map((item, index) => {
        const disabled = !!item.requirementId
        return (
          <div
            className={styles.panel}
            key={item.requirementId || index}
          >
            <RequirementSettingPanel
              data={item}
              index={index}
              handleDeletePanel={handleDeletePanel}
              handleConfirmSetting={handleConfirmSetting}
              disabled={disabled}
            />
          </div>
        )
      })
        : null}
      <div>
        <Button
          type="link"
          onClick={handleAddSetting}
        >
          +Add Another Requirement
        </Button>
      </div>
    </div>
  )
}


export default RequirementSettingDetails;
