import { notification } from "antd";
import { LOGIN_SUCCESS, SAVE_MYPROFILE } from "./pambReducer/actionTypes";
import { get } from "../utils/request";
import { globalPromptTime10s } from "../utils/global";
import { globalUrlPre } from "../utils/config";
import { loading } from "./PublicReducer";

const initState = {
  user: "",
  isLogin: false,
  token: "",
  bu: null,
  contactnumber: null,
  createTime: null,
  createUser: null,
  email: "",
  isDelete: null,
  loginaccount: "",
  nirc: null,
  psd: "",
  psdupdatetime: "",
  roldid: null,
  status: "",
  updateTime: null,
  updateUser: null,
  userid: "",
  username: "",
  dateSwitch: false,
};
const LOGOUT = "LOGOUT";
const SAVE = "userReducer/SAVE";
// reducer
export default function userReducer(state = initState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.data.token,
        isLogin: true,
        user: action.payload.user
      };
    case LOGOUT:
      return {
        ...state,
        token: "",
        isLogin: false,
        user: ""
      };
    case SAVE_MYPROFILE:
      return { ...state, ...action.payload.data, ...action.payload.nowVal };
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// saveMyprofile
export function saveMyprofile(data, payload = "") {
  return {
    type: SAVE_MYPROFILE,
    payload: {
      data,
      nowVal: payload
        ? {
          username: payload.username,
          email: payload.email,
          contactnumber: payload.contactnumber
        }
        : {}
    }
  };
}

export function save_data(payload) {
  return {
    type: "myProfile",
    payload
  };
}

export function myProfile(payload = "") {
  return dispatch => {
    dispatch(loading(true));
    const prefix = sessionStorage.getItem('prefix')
    get("/login/myProfile", null, { buRole: prefix })
      .then(res => {
        if (res.success) {
          sessionStorage.setItem("roleid", res.data.roleid);
          sessionStorage.setItem("bu", res.data.bu);
          sessionStorage.setItem("username", res.data.username);
          sessionStorage.setItem("email", res.data.email);
          sessionStorage.setItem("userId", res.data.userid);
          dispatch(save_data({
            userName: res.data.username,
            email: res.data.email,
            contactNum: res.data.contactnumber,
          }));
          dispatch(saveMyprofile(res.data, payload));
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function logout(urlPrefix, history) {
  return dispatch => {
    sessionStorage.clear();
    const loginPath = `/${globalUrlPre}/${urlPrefix}/login`;
    history.push(loginPath);
    return dispatch({
      type: LOGOUT,
      payload: { urlPrefix, history }
    });
  };
}


export function setBuCountry(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        buCountry: payload
      }
    });
  };
}

// check date picker yearly access status api
export function checkDatePickerDisabled() {
  return dispatch => {
    get("/config")
      .then(res => {
        if (res.success) {
          // save dateSwitch to sessionStorage
          sessionStorage.setItem('dateSwitch', res.data.dateSwitch);
          dispatch(
            save_data({
              dateSwitch: res.data.dateSwitch
            })
          )
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
  }
}
