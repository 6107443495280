import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  notification,
  Table,
  Empty
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import {
  searchCPDReport,
  getStatusList,
  download_management
} from "../../../../redux/pacsReducer/CPDReportReducers/ManagementReportReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateManagementReport_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("ManagementReport", changedFields);
  },
  mapPropsToFields(props) {
    return {
      status: Form.createFormField({
        ...props.status,
        value: props.status.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      }),
      contractDate: Form.createFormField({
        ...props.contractDate,
        value: props.contractDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, columns } = props;
  const { RangePicker } = DatePicker;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 8 }}
        labelAlign="left"
        wrapperCol={{ span: 16 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Status">
              {getFieldDecorator("status", {})(
                <Select filterOption={false} defaultActiveFirstOption={false}>
                  <Option value="">All</Option>
                  {props.statusList.length
                    ? props.statusList.map(item => (
                      <Option value={item.value} key={item.code}>
                        {item.code}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item
              labelCol={{ span: 5 }}
              label="Reporting Year"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("trainingDate", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <RangePicker
                  disabled
                  placeholder={["From Date", "To Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item
              labelCol={{ span: 5 }}
              label="LIAM Contract Date"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("contractDate", {})(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      moment("00:00:00", "HH:mm:ss"),
                      moment("23:59:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col xl={4} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
          style={{ display: props.showList ? "block" : "none" }}
        >
          {" "}
        </div>
        <Button
          className={styles.btn}
          style={{ display: props.showList ? "block" : "none" }}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_management();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "15px",
            // display: props.showList ? "block" : "none"
          }}
        >
          <Table
            bordered
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
            rowKey="key"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { CPDReportReducers: { ManagementReportReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: ManagementReportReducer,
    loading
  }),
  {
    changeVals,
    searchCPDReport,
    getStatusList,
    download_management
  }
)
class ManagementReport extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.download_management = this.download_management.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      currentPageNumber: 1,
      columns: [
        {
          title: "CPD Hours",
          dataIndex: "range",
          ellipsis: true,
          width: 180,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Central",
          dataIndex: "Central",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "East Coast",
          dataIndex: "EastCoast",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "North",
          dataIndex: "North",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },

        {
          title: "SAB",
          dataIndex: "SAB",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "SAR",
          dataIndex: "SAR",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "South",
          dataIndex: "South",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Total",
          dataIndex: "total",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Percentage(%)",
          dataIndex: "percentAge",
          ellipsis: true,
          width: 150,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    this.props.changeVals("ManagementReport", {
      trainingDate: {
        value: [moment().startOf("year"), moment().endOf("day")]
      },
    })
    this.props.getStatusList();
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.trainingDate.value.length) {
          const year = new Date().getFullYear();
          /* eslint-disable */
          let seconds = 0;
          if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
            seconds = 31643326;
          } else {
            seconds = 31556926;
          }
          /* eslint-disable */
          const start = new Date(
            fields.trainingDate.value[0].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const end = new Date(
            fields.trainingDate.value[1].format("YYYY-MM-DD HH:MM:SS")
          ).getTime();
          const between = Math.round(end / 1000) - Math.round(start / 1000);
          if (seconds < between) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        this.setState(() => ({
          currentPageNumber: 1
        }));
        this.props.searchCPDReport({
          fromdate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          todate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : "",
          liamFromdate: fields.contractDate.value.length
            ? fields.contractDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          liamTodate: fields.contractDate.value.length
            ? fields.contractDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : "",
          status: fields.status.value
        });
      }
    });
  };

  download_management() {
    const { fields, download_management } = this.props;
    download_management({
      fromdate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      todate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : "",
      liamFromdate: fields.contractDate.value.length
        ? fields.contractDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      liamTodate: fields.contractDate.value.length
        ? fields.contractDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : "",
      status: fields.status.value
    });
  }
  handleTableChange = pagination => {
    const { current } = pagination;
    this.setState(() => ({
      currentPageNumber: current
    }));
  };
  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      download_management: this.download_management,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>CPD Management Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

const flexCol = {
  xxl: 10,
  xl: 10,
  lg: 10,
  md: 12,
  sm: 12,
  xs: 12
};

export default ManagementReport;
