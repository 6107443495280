import React, { Component } from "react";
import { Form, Col, DatePicker } from "antd";
import moment from 'moment';
import '../index.less';

export const flexCol = {
  xxl: 24,
  xl: 24,
  lg: 24,
  md: 24,
  sm: 24,
  xs: 24
};

const { RangePicker } = DatePicker;
export default class DateRangePickers extends Component {
  render() {
    const { getFieldDecorator, label, startDate, endDate, rules, labelCol, wrapperCol, searchData } = this.props;

    const hasSaveFiled = Object.keys(searchData).length > 0;
    const startDateValue = searchData[startDate.name];
    const endDateValue = searchData[endDate.name];
    const startDataSaveValue = startDateValue && moment(startDateValue, "YYYY-MM-DD");
    const endDataSaveValue = endDateValue && moment(endDateValue, "YYYY-MM-DD");
    const defaultStartDate = (startDate.initialValue ? startDate.initialValue : null);
    const defaultEndDate = (endDate.initialValue ? endDate.initialValue : null);

    return (
      <Col {...flexCol}>
        <Form.Item label={label} labelCol={labelCol || { span: 7 }} wrapperCol={wrapperCol || {}} style={{ marginBottom: 10 }}>
          {getFieldDecorator("scanDateRange", {
            initialValue: [
              hasSaveFiled ? startDataSaveValue : defaultStartDate,
              hasSaveFiled ? endDataSaveValue : defaultEndDate
            ],
            rules
          })(
            <RangePicker
              placeholder={[startDate.placeholder || "Start Date", endDate.placeholder || "End Date"]}
              style={{ width: "100%" }}
              format={["YYYY-MM-DD", "YYYY-MM-DD"]}
            />
          )}
        </Form.Item>
      </Col>
    );
  }
}
