export default {
  BEFORE_GET_USER_LIST: 'beforeGetUserList',
  GET_USER_LIST: 'getUserList',
  GET_USER_LIST_ERROR: 'getUserListError',
  GET_ROLE_LIST: 'getRoleList',
  GET_RANK_LIST: 'getRankList',
  GET_USER_STATUS_LIST: 'getUserStatusList',
  GET_USER_ROLE_LIST: 'getUserRoleList',
  GET_SYSTEM_ROLE_LIST: 'getSystemRoleList',
  GET_BU_LIST: 'getBuList',
  BEFORE_SEND_EMAIL: 'beforeSendEmail',
  RE_SEND_EMAIL: 'reSendEmail',
  SEND_EMAIL_ERROR: 'sendEmailError',
  BEFORE_ACTIVATE: 'beforeActivate',
  HANDLE_ACTIVATE: 'handleActivate',
  HANDLE_ACTIVATE_ERROR: 'handleActivateError',
  SAVE_FIELDS: 'saveFields',
  SAVE_TEMPORARY_DATA: 'saveTemporaryData',
  HANDLE_REFRASH: 'handleRefrash'
};
