import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Input, Row, Col, Icon, Spin } from "antd";
import styles from "./CreateLBUAdmin.module.less";
import {
  // handleSubmit,
  getuserDetial
} from "../../../../../redux/pacsReducer/systemSettingsReducers/otherInfoReducer";
import { handleStatus, handleGender } from "../../../../../utils";

// const { Option } = Select;
const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

@connect(
  ({
    pacsReducer: { systemSettingsReducers: {
      CreateLBUAdminReducer,
      userManagementReducer,
      otherInfoReducer
    } }
  }) => ({ CreateLBUAdminReducer, userManagementReducer, otherInfoReducer }),
  { getuserDetial }
)
class PAMBForm extends React.Component {
  componentDidMount() {
    const {
      location: { state = {} }
    } = this.props;
    const { bu, userId } = state;
    this.props.getuserDetial({ userId }, bu);
  }

  render() {
    const {
      form: { getFieldDecorator },
      otherInfoReducer: { userInfo = {}, updateAdminLoading },
      location: { state = {} }
    } = this.props;
    const { roleName } = state;
    const {
      bu,
      username,
      nirc,
      email,
      contactnumber,
      loginaccount,
      unitcode,
      agentcode,
      status,
      contracteddate,
      terminationdate,
      gender,
      pbtbappointed,
      edpIndicator,
      eastspringCode,
      bumiIndicator,
      pruventureIndicator,
      branchlocation,
      region
    } = userInfo;

    return (
      <div className={styles.container}>
        <Spin spinning={updateAdminLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            {`${roleName} Info`}
          </div>
          <Form layout="horizontal" {...formLayout} labelAlign="left" colon={false}>
            <Row gutter={[24, 0]}>
              <Col {...flexCol}>
                <Form.Item label="Role">
                  {getFieldDecorator("role", {
                    initialValue: roleName
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="BU">
                  {getFieldDecorator("bu", {
                    initialValue: bu
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("userName", {
                    initialValue: username
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC">
                  {getFieldDecorator("nirc", {
                    initialValue: nirc
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Email">
                  {getFieldDecorator("email", {
                    initialValue: email
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Contact Number">
                  {getFieldDecorator("contactnumber", {
                    initialValue: contactnumber
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Login Account">
                  {getFieldDecorator("account", {
                    initialValue: loginaccount
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: unitcode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Agent Code">
                  {getFieldDecorator("agentCode", {
                    initialValue: agentcode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Status">
                  {getFieldDecorator("status", {
                    initialValue: handleStatus(status)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Contracted Date">
                  {getFieldDecorator("contractedDate", {
                    initialValue: contracteddate
                      ? moment(contracteddate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Termination Date">
                  {getFieldDecorator("terminationDate", {
                    initialValue: terminationdate
                      ? moment(terminationdate).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Gender">
                  {getFieldDecorator("gender", {
                    initialValue: handleGender(gender)
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="EDP Indicator">
                  {getFieldDecorator("edpIndicator", {
                    initialValue: edpIndicator
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="PBTB Appointed">
                  {getFieldDecorator("PBTBAppointed", {
                    initialValue: pbtbappointed
                      ? moment(pbtbappointed).format("YYYY-MM-DD")
                      : ""
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Eastspring Code">
                  {getFieldDecorator("eastspringCode", {
                    initialValue: eastspringCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Bumi Indicator">
                  {getFieldDecorator("bumiIndicator", {
                    initialValue: bumiIndicator
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Pruventure Indicator">
                  {getFieldDecorator("pruventureIndicator", {
                    initialValue: pruventureIndicator
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Branch Location">
                  {getFieldDecorator("pruventureIndicator", {
                    initialValue: branchlocation
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Region">
                  {getFieldDecorator("region", {
                    initialValue: region
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

const PAMBAgentDetail = Form.create()(PAMBForm);

export default PAMBAgentDetail;
