import React, { useState, useEffect } from "react";
import { Table, Spin } from "antd";
import { withRouter } from "react-router-dom";
import withConnector from '../../connector';
import styles from '../index.module.less';
import { get } from '../../../../../../utils/request'

import { getColumns } from './columns';

function CourseAttendance(props) {
  const { courseDetail } = props;
  const [columns, setColumns] = useState([]);
  const { currentSessiondayId, courseAttendanceList, loadingCourseAttendanceList } = props.CourseDetailReducer;
  const fetchData = async (currentSessiondayId) => {
    await props.getCourseAttendanceList(currentSessiondayId);
  }

  const deleteRecord = async (attendanceId) => {
    await get("/attendanceTms/deleteAttendance", { attendanceId });
    fetchData(currentSessiondayId);
  }

  const editRecord = async (record, courseDetail) => {
    props.history.push("/home/attendance/edit_attendance", {
      record, courseDetail
    });
  }

  useEffect(() => {
    fetchData(currentSessiondayId);
    setColumns(getColumns(deleteRecord, editRecord, courseDetail));
  }, [currentSessiondayId, courseDetail]);

  return (
    <div>
      <div className={styles.tableTitle}>Attendance Information</div>
      <Spin spinning={loadingCourseAttendanceList}>
        <Table
          bordered
          scroll={{ x: 1800 }}
          className={styles.ant_dev_two_table}
          columns={columns}
          dataSource={courseAttendanceList}
          size="small"
          rowKey="attendanceId"
        />
      </Spin>

    </div>
  );
}

export default withConnector(withRouter(CourseAttendance));
