import React from "react";
import { QRCode } from "antd-v5";
import dayjs from "dayjs";
import { get } from "../../../../../utils/request";
import styles from "./MySessionQRCode.module.less"

const qrCodeTypeMap = {
  in: 'SCAN_IN',
  out: 'SCAN_OUT',
}
// refresh duration 15s
const refreshDuration = 15000

class MySessionQRCode extends React.Component {
  state = {
    sessionId: "",
    type: "",
    data: {},
    scanCount: 0,
    qrCodeStatus: 'loading'
  }

  // after mount (async)
  async componentDidMount() {
    // hide antd header and side menu
    const antHeader = window.document.getElementsByClassName('ant-layout-header')[0]
    antHeader.style.display = 'none'
    const leftuMenu = window.document.getElementsByClassName('ant-layout-sider')[0]
    leftuMenu.style.display = 'none'
    // get sessionId and type from url
    const url = window.location.href
    const sessionId = url.split('?')[1].split('&')[0].split('=')[1]
    const type = url.split('?')[1].split('&')[1].split('=')[1]
    // hide scroll bar
    document.body.style.overflow = 'hidden'
    // set page title
    document.title = type === 'in' ? 'Scan In QR Code' : 'Scan Out QR Code'
    this.setState({
      sessionId,
      type,
    })
    await this.handleQrCode(sessionId, type)
    this.handleFreshQrCode(sessionId, type)
  }

  async handleFreshQrCode(sessionId, type) {
    this.timer = setInterval(async () => {
      if (this.state.qrCodeStatus !== 'expired') {
        await this.handleQrCode(sessionId, type)
      } else {
        clearInterval(this.timer)
      }
    },
      refreshDuration)
  }

  async handleQrCode(sessionId, type) {
    try {
      const res = await get(`/pruventure/sessions/${sessionId}/qrCode/${qrCodeTypeMap[type]}`)
      if (!res.success) {
        this.setState({
          qrCodeStatus: 'expired',
          data: {}
        })
        return
      }
      const { expireTime, currentTime } = res?.data
      const now = dayjs(currentTime).valueOf()
      const expiredTimeInMs = dayjs(expireTime).valueOf()
      const duration = expiredTimeInMs - now
      if (duration > 0) {
        // avoid duration out than the range of setTimeout
        if (duration < refreshDuration + 1000) {
          setTimeout(() => {
            this.setState({
              qrCodeStatus: 'expired',
              data: {}
            })
          }, duration)
        }
        const scanCount = this.calTotalScanCount(res?.data)

        this.setState({
          data: res?.data,
          qrCodeStatus: 'active',
          scanCount,
        })
      } else {
        this.setState({
          qrCodeStatus: 'expired',
          data: {},
          scanCount: 0
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  calTotalScanCount = (data) => {
    const { successfulScanInfo } = data
    let scanCount = 0
    if (successfulScanInfo) {
      Object.values(successfulScanInfo).forEach((item) => {
        scanCount += item.attendee
      })
    }
    return scanCount;
  }


  render() {
    return (
      <div className={styles.container}>
        <div className={styles.qrCodeContainer}>
          {
            this.state.qrCodeStatus === 'expired' &&
            <div className={styles.qrCodeHeader}>
              <p>QR code expired.</p>
              <p>Please close the window</p>
            </div>
          }
          {
            this.state.qrCodeStatus === 'active' &&
            <div className={styles.qrCodeHeader}>
              <p>Code refreshes every 15s</p>
            </div>
          }
          <QRCode
            className={styles.qrCode}
            size={300}
            value={
              JSON.stringify({
                sessionId: this.state.sessionId,
                type: this.state.data?.action,
                data: JSON.stringify(this.state.data)
              })
            }
            status={this.state.qrCodeStatus} />
          {
            this.state.qrCodeStatus === 'active' &&
            <div className={styles.qrCodeFooter}>
              <p className={styles.boldFont}>Successful Scan: {this.state.scanCount}</p>
              {/* <div className={styles.scanInfo}>
                {Object.entries(this.state.data?.successfulScanInfo || {}).map(([key, value]) => {
                  return (
                    <div key={key} className={styles.infoItem}>
                      {key} <p className={value?.attendee > 0 ? styles.attendeeColor : ''}>({value.attendee}/{value.total})</p>
                    </div>
                  )
                })
                }
              </div> */}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default MySessionQRCode;
