import { get, post } from "../../../utils/request";

const SAVE = "pcla/commonReducer/SAVE";

const initState = {
  reportTypeList: [],
  statusList: [],
  pclaCdpYearList: [],
  cpdCategoryList: []
};

// reducer
export default function commonReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getReportTypeList() {
  return dispatch => {
    get("/training/function/dropdown/type")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            reportTypeList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function getStatusList() {
  return dispatch => {
    get("/training/function/dropdown/status")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            statusList: data || []
          }
        });
      })
      .catch(() => { });
  };
}

export function getYearList(cb) {
  return dispatch => {
    post("/tmsRequirement/getYearList")
      .then(res => {
        const { data } = res;

        dispatch({
          type: SAVE,
          payload: {
            pclaCdpYearList: data || []
          }
        });
        if (cb) { cb() }
      })
      .catch(() => { });
  };
}

export function getCpdCategory() {
  return dispatch => {
    post("/tmsRequirement/getCategoryList")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            cpdCategoryList: data || []
          }
        });
      })
      .catch(() => { });
  };
}
