import moment from 'moment';
import { notification } from "antd";
import { globalPromptTime10s } from '../../../../../../utils/global'

export const showError = (errMessage) => {
  notification.error({
    message: errMessage,
    duration: globalPromptTime10s,
    className: "ant-style-notification-error"
  });
}

export const validate = (values, courseDetail) => {
  if (!values.scanDateRange || values.scanDateRange.length === 0) {
    return false;
  }

  const scanIn = values.scanDateRange[0].valueOf();
  const scanOut = values.scanDateRange[1].valueOf();
  const startLimitDate = moment(courseDetail.startDate).startOf("day").valueOf();
  const endLimitDate = moment(courseDetail.endDate).endOf("day").valueOf();
  const canScan = scanIn >= startLimitDate && scanOut <= endLimitDate;

  if (canScan) {
    return true;
  } else if (scanIn > scanOut) {
    showError('Scan In is greater than Scan Out!');
    return false;
  } else {
    showError('Time out of session valid time');
    return false;
  }
}
