import React from "react";
import { Form, Table, message } from "antd";
import withConnector from '../connector';
import ActionBar from './ActionBar';
import SearchBar from './SearchBar';
import styles from "./index.module.less";
import { handleSplitUrl } from "../../../../../../utils";
import { getColumnsByRole } from './columns';
import { get, post } from '../../../../../../utils/request'

@withConnector
class HomeForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columns: [],
      pageSize: 10,
      searchData: {
        currentPageNumber: 1,
      },
      userList: []
    };
  }

  componentDidMount() {
    const {
      homeReducer: { buttonList },
    } = this.props;
    if (!buttonList.length) {
      const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
      this.props.getMenuList(tabKey, sideKey);
    }

    this.handleInitData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const userList = nextProps.userManagementReducer.userData.userList;

    this.setState({
      userList: []
    }, () => {
        this.setState({
          userList
        });
    });
  }

  search = e => {
    e.preventDefault();
    const self = this;
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          userManagementReducer: {
            rolerList,
            usefulTemporaryData: {
              fieldsData,
            }
          }
        } = this.props;

        const valuesData = {};
        const { bu, roleCode } = fieldsData;

        self.setState({
          userList: []
        });

        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else {
            valuesData[item] = elt;
          }
        });
        self.setState({
          columns: getColumnsByRole(roleCode, this, values.roleId, rolerList || []),
          searchData: {
            ...valuesData,
            currentPageNumber: 1,
          },
          bu
        }, () => {
            self.props.saveFields('searchData', {
              ...this.state.searchData,
            });
            self.props.getUserList(this.state.searchData, bu);
        });
      }
    });
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  handleTableChange = pagination => {
    const { current } = pagination;
    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;

    const { bu } = fieldsData;

    this.props.saveFields('searchData', {
      ...this.state.searchData,
      ...{ currentPageNumber: current }
    });

    this.setState({
      searchData: {
        ...this.state.searchData,
        ...{ currentPageNumber: current }
      }
    }, () => {
        this.props.getUserList({
          ...this.state.searchData
        }, bu);
    });
  };

  handleInitData = async () => {
    await this.props.getUserRoleList();

    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;

    const { bu, roleId, roleCode, searchData } = fieldsData;
    this.setState({
      columns: getColumnsByRole(roleCode, this),
      searchData: {
        ...searchData,
        ...{ roleId }
      }
    }, () => {
        this.props.getUserList(this.state.searchData, bu);
    });
  };

  resendEmail = async (payload) => {
    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;
    const { bu } = fieldsData;
    await get("/login/generateLink", payload, { buRole: bu });
    message.info("Operation Success", 10);
  }

  resendEmailToTrainer = async (payload) => {
    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;
    const { bu } = fieldsData;
    await get("/login/generateLink", payload, { buRole: bu });
    message.info("Operation Success", 10);
  }

  activate = async (payload) => {
    const url = '/user/Activate';

    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;
    const { bu } = fieldsData;

    if (bu === 'ADMIN') {
      await post(url, payload, { buRole: bu })
    } else {
      await get('/user/activate', payload);
    }

    message.info("Operation Success", 10);
    this.props.getUserList(this.state.searchData, bu);
  }

  deActivate = async (payload) => {
    const {
      userManagementReducer: {
        usefulTemporaryData: {
          fieldsData,
        }
      }
    } = this.props;
    const { bu } = fieldsData;

    if (bu === 'ADMIN') {
      await post('/user/Deactivate', payload, { buRole: bu })
    } else {
      await get('/user/deactivate', payload);
    }

    message.info("Operation Success", 10);
    this.props.getUserList(this.state.searchData, bu);
  }


  render() {
    const {
      form,
      homeReducer: { buttonList },
      userManagementReducer: {
        rolerList,
        userData: { total, pageSize },
        queryUserListLoading
      },
    } = this.props;

    const { columns, userList } = this.state;

    return (
      <div className={styles.container}>

        <ActionBar buttonList={buttonList} />
        <SearchBar
          form={form}
          rolerList={rolerList}
          userManagementReducer={this.props.userManagementReducer}
          handleSaveField={this.handleSaveField}
          search={this.search}
          handleSelectChange={this.handleSelectChange}
          ref={(n) => {
            this.searchRole = n;
          }}
        />

        <div className={styles.table}>
          <Table
            columns={columns}
            dataSource={userList}
            pagination={{
              total,
              pageSize,
              current: this.state.searchData.currentPageNumber
            }}
            size="small"
            loading={
              queryUserListLoading
            }
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(rowInfo) => rowInfo.userid || rowInfo.loginAccount}
          />
        </div>
      </div>
    );
  }
}

const UserManagementHome = Form.create({ name: "homePage" })(HomeForm);

export default UserManagementHome;
