import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Upload, Icon, Spin } from 'antd';
import { uploadAndCheckForPambTemp } from '../../../../../redux/pambReducer/systemSettingsReducers/ImportAgentReducer';
import styles from './ImportDcmsFile.module.less';

import { file as uploadfileIcon } from '../../../../../images';

@connect(
  ({ pambReducer: { systemSettingsReducers: { ImportAgentReducer, userManagementReducer } } }) => ({
    ImportAgentReducer,
    userManagementReducer
  }),
  { uploadAndCheckForPambTemp }
)
class ImportDcmsFile extends Component {
  state = {
    afsDetailsList: [],
    agentMainList: [],
    pbtbDetailsList: [],
    putbDetailsList: [],
    isShowTable: false,
    uploadFiles: {},
  };

  uploadAndCheck = () => {
    const { uploadFiles } = this.state;
    const array = ['mainFile', 'edpFile', 'mtaFile', 'eastpringFile'];
    const FormDatas = new FormData();
    array.forEach((elt) => {
      const file = uploadFiles[elt];
      if (file) {
        FormDatas.append(elt, file);
      } else {
        FormDatas.append(elt, null);
      }
    });

    const callback = () => {
      this.setState({
        afsDetailsList: [],
        agentMainList: [],
        pbtbDetailsList: [],
        putbDetailsList: [],
        uploadFiles: {}
      });
    };
    this.props.uploadAndCheckForPambTemp(FormDatas, callback);
  };

  handleFillVal = (file) => {
    const { name } = file;
    const str = name || '';
    const filenameArray = ['agent_main', 'afs_details', 'pbtb_details', 'putb_details'];
    const val = filenameArray.find((elt) => str.indexOf(elt) > -1);
    if (val) {
      const { uploadFiles } = this.state;
      if (val === filenameArray[0]) {
        uploadFiles.mainFile = file;
      } else if (val === filenameArray[1]) {
        uploadFiles.edpFile = file;
      } else if (val === filenameArray[2]) {
        uploadFiles.mtaFile = file;
      } else if (val === filenameArray[3]) {
        uploadFiles.eastpringFile = file;
      }
      this.setState({ uploadFiles });
    }
  };

  render() {
    const { agentMainList, afsDetailsList, pbtbDetailsList, putbDetailsList } = this.state;
    const { ImportAgentReducer: { checkLoading, importLoading } } = this.props;
    const isDisabled = handleDisable({
      agentMainList,
      afsDetailsList,
      pbtbDetailsList,
      putbDetailsList
    });

    const agentMain = {
      beforeUpload: (file) => {
        this.handleFillVal(file);
        this.setState(() => ({
          agentMainList: [file],
          isShowTable: false
        }));
        return false;
      },
      agentMainList,
      showUploadList: false
    };

    const afsDetails = {
      beforeUpload: (file) => {
        this.handleFillVal(file);
        this.setState(() => ({
          afsDetailsList: [file],
          isShowTable: false
        }));
        return false;
      },
      afsDetailsList,
      showUploadList: false
    };

    const pbtbDetails = {
      beforeUpload: (file) => {
        this.handleFillVal(file);
        this.setState(() => ({
          pbtbDetailsList: [file],
          isShowTable: false
        }));
        return false;
      },
      pbtbDetailsList,
      showUploadList: false
    };

    const putbDetails = {
      beforeUpload: (file) => {
        this.handleFillVal(file);
        this.setState(() => ({
          putbDetailsList: [file],
          isShowTable: false
        }));
        return false;
      },
      putbDetailsList,
      showUploadList: false
    };

    return (
      <div className={styles.container}>
        <Spin spinning={importLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: 'pointer' }}
              onClick={() => this.props.history.push('/home/system_settings/user_management/index')}
            />
            Import DCMS Four Files
          </div>
          <div className={styles.contentBox}>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>{agentMainList[0] && agentMainList[0].name}</div>
              <Upload {...agentMain}>
                <div className={styles.uploadIconBox}>
                  <img className={styles.uploadIcon} src={uploadfileIcon} alt="" />
                </div>
              </Upload>
            </div>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>{afsDetailsList[0] && afsDetailsList[0].name}</div>
              <Upload {...afsDetails}>
                <div className={styles.uploadIconBox}>
                  <img className={styles.uploadIcon} src={uploadfileIcon} alt="" />
                </div>
              </Upload>
            </div>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>{pbtbDetailsList[0] && pbtbDetailsList[0].name}</div>
              <Upload {...pbtbDetails}>
                <div className={styles.uploadIconBox}>
                  <img className={styles.uploadIcon} src={uploadfileIcon} alt="" />
                </div>
              </Upload>
            </div>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>{putbDetailsList[0] && putbDetailsList[0].name}</div>
              <Upload {...putbDetails}>
                <div className={styles.uploadIconBox}>
                  <img className={styles.uploadIcon} src={uploadfileIcon} alt="" />
                </div>
              </Upload>
            </div>
            <div className={styles.uploadAndCheckBtnBox}>
              <Button
                onClick={this.uploadAndCheck}
                disabled={!isDisabled}
                loading={checkLoading}
                className={isDisabled ? styles.uploadBtnActive : styles.uploadBtn}
              >
                {checkLoading ? 'Uploading' : 'Upload & Download'}
              </Button>
            </div>
          </div>
        </Spin>
      </div>
    );
  }
}

export default ImportDcmsFile;

function handleDisable({ agentMainList, afsDetailsList, pbtbDetailsList, putbDetailsList }) {
  const hasLen = agentMainList.length || afsDetailsList.length || pbtbDetailsList.length || putbDetailsList.length;
  return hasLen;
}
