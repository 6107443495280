import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Form,
  Row,
  Col,
  Input,
  Button,
  Checkbox,
  Spin,
  DatePicker,
  Icon
} from "antd";
import {
  createRequirement,
  getDateList,
  getRequirementData
} from "../../../../redux/pclaReducer/CPDSettingsReducers/requirementSettingReducer";
import styles from "./RequirementSettingPCreate.module.less";

const CheckboxGroup = Checkbox.Group;

@connect(
  ({ pclaReducer: { CPDSettingsReducers: { requirementSettingReducer } } }) => ({
    requirementSettingReducer
  }),
  { createRequirement, getDateList, getRequirementData }
)
class RequirementSettingFrom extends Component {
  state = {
    endValue: ""
  };

  componentDidMount() {
    const {
      requirementSettingReducer: { dateList }
    } = this.props;
    const id = dateList[0] && dateList[0].pambEffectiveDateId;
    this.props.getRequirementData(id);
  }

  handleSubmit = e => {
    e.preventDefault();
    const { form } = this.props;

    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          year1m6Total,
          year1m6StructuredMin,
          year1m6StructuredMax,
          year1m6UnstructuredMin,
          year1m6UnstructuredMax,
          year1m12Total,
          year1m12StructuredMin,
          year1m12StructuredMax,
          year1m12UnstructuredMin,
          year1m12UnstructuredMax,
          year2m24Total,
          year2m24StructuredMin,
          year2m24StructuredMax,
          year2m24UstructuredMin,
          year2m24UstructuredMax,
          cpdhoursTotal,
          cpdhoursStructuredMin,
          cpdhoursStructuredMax,
          cpdhoursUnstructuredMin,
          cpdhoursUnstructuredMax,

          year1m6TotalMTA,
          year1m6TecMin,
          year1m6UntecMin,
          year1m6TecMax,
          year1m6UntecMax,
          year1m6UnstrMin,
          year1m6UnstrMax,
          nontecAndUnstrTotalMax,
          nontecAndUnstrTotalMax2,
          nontecAndUnstrTotalMax3,
          year2Total,
          year2TechMin,
          year2TechMax,
          year2NonTechMin,
          year2NonTechMax,
          year2UnstracturedMin,
          year2UnstracturedMax,
          year2SpecialHours,
          year3Total,
          year3TechMin,
          year3TechMax,
          year3NonTechMin,
          year3NonTechMax,
          year3UnstructuredMin,
          year3UnstructuredMax,
          fromDate,
          toDate
        } = values;

        const liamRequirementDto = {
          year1m6Total,
          year1m12Total,
          year2m24Total,
          year1m6StructuredMin,
          year1m6StructuredMax,
          year1m6UnstructuredMin,
          year1m6UnstructuredMax,
          year1m12StructuredMin,
          year1m12StructuredMax,
          year1m12UnstructuredMin,
          year1m12UnstructuredMax,
          year2m24StructuredMin,
          year2m24StructuredMax,
          year2m24UstructuredMin,
          year2m24UstructuredMax,
          cpdhoursTotal,
          cpdhoursStructuredMin,
          cpdhoursStructuredMax,
          cpdhoursUnstructuredMin,
          cpdhoursUnstructuredMax
        };

        const mtaRequirementDto = {
          year1m6Total: year1m6TotalMTA,
          year1m6TecMin,
          year1m6UntecMin,
          year1m6TecMax,
          year1m6UntecMax,
          year1m6UnstrMin,
          year1m6UnstrMax,
          nontecAndUnstrTotalMax,
          nontecAndUnstrTotalMax2,
          nontecAndUnstrTotalMax3,
          year2Total,
          year2TechMin,
          year2TechMax,
          year2NonTechMin,
          year2NonTechMax,
          year2UnstracturedMin,
          year2UnstracturedMax,
          year2SpecialHours,
          year3Total,
          year3TechMin,
          year3TechMax,
          year3NonTechMin,
          year3NonTechMax,
          year3UnstructuredMin,
          year3UnstructuredMax
        };
        const callback = () => {
          this.props.history.push('/home/system_settings/cpd_settings')
        }
        const getdate = () => this.props.getDateList()
        this.props.createRequirement({
          fromDate,
          toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
          liamRequirementDto,
          mtaRequirementDto
        }, callback, getdate);
      }
    });
  };

  handleFormat = () => { };

  // handleFormat = (value, id) => {
  //   const val = value && value.replace(/[^\d]/g, "");
  //   const num = val && parseFloat(val).toString();
  //   this.props.form.setFieldsValue({
  //     [id]: num
  //   });
  // };

  disabledEndDate = startValue => {
    const {
      location: { state }
    } = this.props;
    const { endDate } = state || {};
    if (!endDate) {
      return false;
    }

    const time = new Date(endDate).getTime();
    return time > startValue.valueOf();
  };

  onEndChange = value => {
    this.setState({ endValue: value });
  };

  render() {
    const {
      form: { getFieldDecorator },
      requirementSettingReducer: { updateLoading, requirementData },
      location: { state }
    } = this.props;
    const { endValue } = this.state;
    const { endDate } = state || {};

    const { liamRequirementDto, mtaRequirementDto } = requirementData;

    const {
      year1m6Total,
      year1m6StructuredMin,
      year1m6StructuredMax,
      year1m6UnstructuredMin,
      year1m6UnstructuredMax,
      year1m12Total,
      year1m12StructuredMin,
      year1m12StructuredMax,
      year1m12UnstructuredMin,
      year1m12UnstructuredMax,
      year2m24Total,
      year2m24StructuredMin,
      year2m24StructuredMax,
      year2m24UstructuredMin,
      year2m24UstructuredMax,
      cpdhoursTotal,
      cpdhoursStructuredMin,
      cpdhoursStructuredMax,
      cpdhoursUnstructuredMin,
      cpdhoursUnstructuredMax
    } = liamRequirementDto || {};

    const {
      year1m6Total: year1m6TotalMTA,
      year1m6TecMin,
      year1m6UntecMin,
      year1m6TecMax,
      year1m6UntecMax,
      year1m6UnstrMin,
      year1m6UnstrMax,
      nontecAndUnstrTotalMax,
      nontecAndUnstrTotalMax2,
      nontecAndUnstrTotalMax3,
      year2Total,
      year2TechMin,
      year2TechMax,
      year2NonTechMin,
      year2NonTechMax,
      year2UnstracturedMin,
      year2UnstracturedMax,
      year2SpecialHours,
      year3Total,
      year3TechMin,
      year3TechMax,
      year3NonTechMin,
      year3NonTechMax,
      year3UnstructuredMin,
      year3UnstructuredMax
    } = mtaRequirementDto || {};

    const inputProps = {
      className: styles.ant_input
      // maxLength: 4
    };
    const props = {
      getFieldDecorator,
      inputProps,
      handleFormat: this.handleFormat
    };
    return (
      <div className={styles.container}>
        <Spin spinning={updateLoading}>
          <Form className={styles.ant_form} labelAlign="left" colon={false}>
            <div className={styles.heading}>
              <Icon
                type="left"
                style={{ marginRight: 15, cursor: "pointer" }}
                onClick={() => {
                  this.props.history.push("/home/system_settings/cpd_settings");
                }}
                className={styles.iconBox}
              />
              <Form.Item label="Effective Date">
                {getFieldDecorator("fromDate", {
                  initialValue: endDate
                })(<Input disabled />)}
              </Form.Item>
              <span className={styles.toSpan}>to</span>
              <Form.Item>
                {getFieldDecorator("toDate", {
                  rules: [
                    {
                      required: true,
                      message: "Please fill in the mandatory fields"
                    }
                  ]
                })(
                  <DatePicker
                    disabledDate={this.disabledEndDate}
                    value={endValue}
                    placeholder="End"
                    onChange={this.onEndChange}
                  />
                )}
              </Form.Item>
            </div>
            <WrapGroup heading="LIAM Requirement">
              <LIAMWrap
                {...props}
                title="LIAM 1st 6 mth Agent Training Requirements"
                totalCode="year1m6Total"
                StructuredMinCode="year1m6StructuredMin"
                StructuredMaxCode="year1m6StructuredMax"
                UnstructuredMinCode="year1m6UnstructuredMin"
                UnstructuredMaxCode="year1m6UnstructuredMax"
                totalVal={year1m6Total}
                StructuredMinVal={year1m6StructuredMin}
                StructuredMaxVal={year1m6StructuredMax}
                UnstructuredMinVal={year1m6UnstructuredMin}
                UnstructuredMaxVal={year1m6UnstructuredMax}
              />
              <LIAMWrap
                {...props}
                title="LIAM 2nd 6 mth Agent Training Requirements"
                totalCode="year1m12Total"
                StructuredMinCode="year1m12StructuredMin"
                StructuredMaxCode="year1m12StructuredMax"
                UnstructuredMinCode="year1m12UnstructuredMin"
                UnstructuredMaxCode="year1m12UnstructuredMax"
                totalVal={year1m12Total}
                StructuredMinVal={year1m12StructuredMin}
                StructuredMaxVal={year1m12StructuredMax}
                UnstructuredMinVal={year1m12UnstructuredMin}
                UnstructuredMaxVal={year1m12UnstructuredMax}
              />
              <LIAMWrap
                {...props}
                title="LIAM 2nd Year Agent Training Requirements"
                totalCode="year2m24Total"
                StructuredMinCode="year2m24StructuredMin"
                StructuredMaxCode="year2m24StructuredMax"
                UnstructuredMinCode="year2m24UstructuredMin"
                UnstructuredMaxCode="year2m24UstructuredMax"
                totalVal={year2m24Total}
                StructuredMinVal={year2m24StructuredMin}
                StructuredMaxVal={year2m24StructuredMax}
                UnstructuredMinVal={year2m24UstructuredMin}
                UnstructuredMaxVal={year2m24UstructuredMax}
              />
              <LIAMWrap
                {...props}
                title="End of specific year since Year3"
                totalCode="cpdhoursTotal"
                StructuredMinCode="cpdhoursStructuredMin"
                StructuredMaxCode="cpdhoursStructuredMax"
                UnstructuredMinCode="cpdhoursUnstructuredMin"
                UnstructuredMaxCode="cpdhoursUnstructuredMax"
                totalVal={cpdhoursTotal}
                StructuredMinVal={cpdhoursStructuredMin}
                StructuredMaxVal={cpdhoursStructuredMax}
                UnstructuredMinVal={cpdhoursUnstructuredMin}
                UnstructuredMaxVal={cpdhoursUnstructuredMax}
              />

              <Form.Item label="RFP Requirement M1&M2 (either 1 to be finished by Year4, both to be finished by Year6)">
                {getFieldDecorator("rfpmOptions", {
                  initialValue: ["rfpm1", "rfpm2"]
                })(
                  <CheckboxGroup
                    options={[
                      { label: "M1", value: "rfpm1" },
                      { label: "M2", value: "rfpm2" }
                    ]}
                    disabled
                  />
                )}
              </Form.Item>
            </WrapGroup>

            <WrapGroup heading="MTA Requirement">
              <MTAWrap
                {...props}
                title="MTA 1st Year Agent Training Requirements"
                totalHoursCode="year1m6TotalMTA"
                technicaMinCode="year1m6TecMin"
                technicaMaxCode="year1m6TecMax"
                nonTecMinCode="year1m6UntecMin"
                nonTecMaxCode="year1m6UntecMax"
                UnstructuredMinCode="year1m6UnstrMin"
                UnstructuredMaxCode="year1m6UnstrMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax"
                totalHoursVal={year1m6TotalMTA}
                technicaMinVal={year1m6TecMin}
                technicaMaxVal={year1m6TecMax}
                nonTecMinVal={year1m6UntecMin}
                nonTecMaxVal={year1m6UntecMax}
                UnstructuredMinVal={year1m6UnstrMin}
                UnstructuredMaxVal={year1m6UnstrMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax}
              />
              <MTAWrap
                {...props}
                title="MTA 2nd Year Agent Training Requirements"
                isShowSpecialHours
                totalHoursCode="year2Total"
                technicaMinCode="year2TechMin"
                technicaMaxCode="year2TechMax"
                nonTecMinCode="year2NonTechMin"
                nonTecMaxCode="year2NonTechMax"
                UnstructuredMinCode="year2UnstracturedMin"
                UnstructuredMaxCode="year2UnstracturedMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax2"
                specialHoursCode="year2SpecialHours"
                totalHoursVal={year2Total}
                technicaMinVal={year2TechMin}
                technicaMaxVal={year2TechMax}
                nonTecMinVal={year2NonTechMin}
                nonTecMaxVal={year2NonTechMax}
                UnstructuredMinVal={year2UnstracturedMin}
                UnstructuredMaxVal={year2UnstracturedMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax2}
                specialHoursVal={year2SpecialHours}
              />
              <MTAWrap
                {...props}
                title="Year3 onwards CPD hours"
                totalHoursCode="year3Total"
                technicaMinCode="year3TechMin"
                technicaMaxCode="year3TechMax"
                nonTecMinCode="year3NonTechMin"
                nonTecMaxCode="year3NonTechMax"
                UnstructuredMinCode="year3UnstructuredMin"
                UnstructuredMaxCode="year3UnstructuredMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax3"
                totalHoursVal={year3Total}
                technicaMinVal={year3TechMin}
                technicaMaxVal={year3TechMax}
                nonTecMinVal={year3NonTechMin}
                nonTecMaxVal={year3NonTechMax}
                UnstructuredMinVal={year3UnstructuredMin}
                UnstructuredMaxVal={year3UnstructuredMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax3}
              />
            </WrapGroup>

            <Row>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={styles.editbalBtn}
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

function WrapGroup(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>{props.heading}</span>
      </div>
      {props.children}
    </div>
  );
}

function LIAMWrap(props) {
  const {
    getFieldDecorator,
    inputProps,
    title,
    handleFormat,
    totalCode,
    StructuredMinCode,
    StructuredMaxCode,
    UnstructuredMinCode,
    UnstructuredMaxCode,
    totalVal,
    StructuredMinVal,
    StructuredMaxVal,
    UnstructuredMinVal,
    UnstructuredMaxVal
  } = props;
  return (
    <div className={styles.LIAMWrapBox}>
      <div className={styles.titleBox}>
        <Form.Item>
          <div className={styles.headings}>{title}</div>
        </Form.Item>
        <Form.Item label="Total Hours" className={styles.totals}>
          {getFieldDecorator(totalCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, totalCode)
              }
            ],
            initialValue: totalVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.titles}>Structured</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(StructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, StructuredMinCode)
              }
            ],
            initialValue: StructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(StructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, StructuredMaxCode)
              }
            ],
            initialValue: StructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.titles}>Unstructured</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(UnstructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMinCode)
              }
            ],
            initialValue: UnstructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(UnstructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMaxCode)
              }
            ],
            initialValue: UnstructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
    </div>
  );
}

function MTAWrap(props) {
  const {
    getFieldDecorator,
    inputProps,
    title,
    isShowSpecialHours,
    handleFormat,

    totalHoursCode,
    technicaMinCode,
    technicaMaxCode,
    nonTecMinCode,
    nonTecMaxCode,
    UnstructuredMinCode,
    UnstructuredMaxCode,
    nontecAndUnstrTotalMaxCode,
    specialHoursCode,

    totalHoursVal,
    technicaMinVal,
    technicaMaxVal,
    nonTecMinVal,
    nonTecMaxVal,
    UnstructuredMinVal,
    UnstructuredMaxVal,
    nontecAndUnstrTotalMaxVal,
    specialHoursVal
  } = props;
  return (
    <div className={styles.LIAMWrapBox}>
      <div className={styles.titleBox}>
        <Form.Item>
          <div className={styles.headings}>{title}</div>
        </Form.Item>
        <Form.Item label="Total Hours" className={styles.totals}>
          {getFieldDecorator(totalHoursCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, totalHoursCode)
              }
            ],
            initialValue: totalHoursVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        {isShowSpecialHours ? (
          <Form.Item
            label="Special Hours"
            className={styles.totals}
            style={{ marginLeft: 25 }}
          >
            {getFieldDecorator(specialHoursCode, {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  transform: v => handleFormat(v, "specialHoursCode")
                }
              ],
              initialValue: specialHoursVal
            })(<Input {...inputProps} className={styles.inputs} />)}
          </Form.Item>
        ) : null}
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Technical</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(technicaMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, technicaMinCode)
              }
            ],
            initialValue: technicaMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(technicaMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, technicaMaxCode)
              }
            ],
            initialValue: technicaMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Non-Technical</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(nonTecMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nonTecMinCode)
              }
            ],
            initialValue: nonTecMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(nonTecMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nonTecMaxCode)
              }
            ],
            initialValue: nonTecMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Unstructered</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(UnstructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMinCode)
              }
            ],
            initialValue: UnstructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(UnstructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMaxCode)
              }
            ],
            initialValue: UnstructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Non-Technical + Unstructured</div>
        </Form.Item>
        <Form.Item label="Max" className={styles.minform}>
          {getFieldDecorator(nontecAndUnstrTotalMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nontecAndUnstrTotalMaxCode)
              }
            ],
            initialValue: nontecAndUnstrTotalMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
    </div>
  );
}

const RequirementSettingPambCreate = Form.create()(RequirementSettingFrom);

export default RequirementSettingPambCreate;
