import React from "react";
import { connect } from "react-redux";
import {
  getReportTypeList,
  getStatusList
} from "../../../../redux/pambReducer/CPDSettingsReducers/commonReducer";

import Router from "../../../../router";

@connect(
  state => state,
  { getReportTypeList, getStatusList }
)
class TrainingHistory extends React.Component {
  componentDidMount() {
    this.props.getReportTypeList();
    this.props.getStatusList();
  }

  render() {
    const { routers } = this.props;
    return <Router routers={routers} />;
  }
}

export default TrainingHistory;
