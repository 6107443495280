import React, { Component } from "react";
import { connect } from "react-redux";
import { Select, Progress } from "antd";
import { getAgentOneselfCPDHours, getYeaRequirementList } from "../../../../redux/pacsReducer/myProfileReducers/CPDDashboardReducer";
import styles from "./styles.module.less";
import {
  finish, notfinish
} from "../../../../images";
import { switchDate } from "../../../../utils/global";

@connect(
  state => ({
    pacsReducer: {
      ...state.pacsReducer,
    }
  }),
  {
    getAgentOneselfCPDHours,
    getYeaRequirementList
  }
)
class MyCPDDashboard extends Component {
  state = {
    isEditable: true,
    years: [],
    nowYear: ""
  };

  componentDidMount() {
    this.props.getYeaRequirementList();
    const nowYear = new Date().getFullYear();
    // if switchDate is true and nowYear is 2022 then set nowYear to 2023
    const requestYear = (switchDate() && nowYear < 2023) ? 2023 : nowYear;
    console.log('date', requestYear, switchDate());
    const years = [];
    years.push({
      year: requestYear,
      yearId: `${requestYear}_now`
    })

    this.setState(() => ({
      requestYear,
      years
    }), () => {
      this.props.getAgentOneselfCPDHours(requestYear);
    });
    /* eslint-disable */
  }


  render() {
    const { Option } = Select;
    const {
      coreAttained,
      coreRequired,
      coreTotal,
      supplementtaryAttained,
      supplementtaryRequired,
      supplementtaryTotal,
      aandHAttained,
      aandHRequired,
      aandH
    } = this.props?.pacsReducer?.myProfileReducers?.CPDDashboardReducer;
    return (
      <div className={styles.container} style={{ marginBottom: 30 }}>
        <div className={styles.title}>My CPD Achievement</div>
        <div className={styles.dashboardContent}>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div className={styles.dashboardLeft}>
                <div>CORE(TOTAL)</div>
                <div><span>{coreTotal}</span> to fulfill</div>
                <div className={styles.progressBar}>
                  <Progress percent={coreTotal ? coreTotal / coreRequired === 1 ? 0 : ((coreRequired - coreTotal) / coreRequired) * 100 : 100} strokeColor="#19A9EA" showInfo={false} />
                </div>
                <div><span style={{ color: "#19A9EA" }}>{coreAttained} Attained</span> / {coreRequired} Required</div>
              </div>
              <div className={styles.dashboardRight}>
                <img style={{ marginTop: "25px", marginRight: "5px" }} src={coreTotal ? notfinish : finish} alt="" width="54px" height="72px" />
              </div>
            </div>
          </div>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div className={styles.dashboardLeft}>
                <div>SUPPLEMENTARY(TOTAL)</div>
                <div><span>{supplementtaryTotal}</span> to fulfill</div>
                <div className={styles.progressBar}>
                  <Progress percent={supplementtaryTotal ? supplementtaryTotal / supplementtaryRequired === 1 ? 0 : ((supplementtaryRequired - supplementtaryTotal) / supplementtaryRequired) * 100 : 100} strokeColor="#19A9EA" showInfo={false} />
                </div>
                <div><span style={{ color: "#19A9EA" }}>{supplementtaryAttained} Attained </span> / {supplementtaryRequired} Required</div>
              </div>
              <div className={styles.dashboardRight}>
                <img style={{ marginTop: "25px", marginRight: "5px" }} src={supplementtaryTotal ? notfinish : finish} alt="" width="54px" height="72px" />
              </div>
            </div>
          </div>
          <div className={styles.dashboardBox}>
            <div className={styles.dashboardText}>
              <div className={styles.dashboardLeft}>
                <div>SUPPLEMENTARY(A&H)</div>
                <div><span>{aandH}</span> to fulfill</div>
                <div className={styles.progressBar}>
                  <Progress percent={aandH ? aandH / aandHRequired === 1 ? 0 : ((aandHRequired - aandH) / aandHRequired) * 100 : 100} strokeColor="#19A9EA" showInfo={false} />
                </div>
                <div><span style={{ color: "#19A9EA" }}>{aandHAttained} Attained </span> / {aandHRequired} Required</div>
              </div>
              <div className={styles.dashboardRight}>
                <img style={{ marginTop: "25px", marginRight: "5px" }} src={aandH ? notfinish : finish} alt="" width="54px" height="72px" />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default MyCPDDashboard;
