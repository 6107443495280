import React from 'react'
import {
  Tooltip,
} from "antd";

export const columns = [
  {
    title: 'Agent ID',
    dataIndex: "agentId",
    align: "center",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Description',
    dataIndex: "description",
    align: "left",
    render: text => (
      <Tooltip placement="top" title={text}>
        {text}
      </Tooltip>
    )
  },
  {
    title: 'Remarks',
    dataIndex: "remarks",
    align: "left",
    render: (text, data, index) => {
      const remarks = data.remarks
      return (
        <>
        {
          remarks.map((r, index) => {
            return (
              <Tooltip key={index} placement="top" title={r.description}>
                <span style={{color: handleColor(r.color)}}>
                  {r.description}
                </span>
              </Tooltip>
            )
          })
        }
        </>
      )
    }
  },
]

function handleColor(color) {
  switch (color) {
    case "0":
      return "#D0021B";
    case "1":
      return "#E79000";
    case "2":
      return "#417505 ";
    default:
      return "#666";
  }
}
