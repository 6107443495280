import { combineReducers } from "redux";
import userReducer from "./userReducer";
import homeReducer from "./homeReducer";
import pacsReducer from "./pacsReducer";
import pambReducer from "./pambReducer";
import regionalReducer from "./regionalReducer";
import phklReducer from "./phklReducer";
import loginReducer from "./loginReducer";
import PublicReducer from "./PublicReducer";
import plukReducer from './plukReducer';
import pclaReducer from './pclaReducer';

export default combineReducers({
  userReducer,
  homeReducer,
  loginReducer,
  PublicReducer,
  pacsReducer,
  pambReducer,
  regionalReducer,
  phklReducer,
  plukReducer, // add by zhy
  pclaReducer // add by zhy
});
