import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Spin,
  Input,
  Tooltip,
  Table,
  Empty
} from "antd";
import { changeVals } from "../../../../../redux/pacsReducer/systemSettingsReducers/index.action";
import styles from "./styles.module.less";
import { editIcon } from "../../../../../images";
import {
  search,
  init_data
} from "../../../../../redux/pacsReducer/systemSettingsReducers/ReminderListTReducer";

const CreateForm = Form.create({
  name: "CreateReminderListForm",
  onFieldsChange(props, changedFields) {
    props.onChange("ReminderList", changedFields);
  },
  mapPropsToFields(props) {
    return {
      search: Form.createFormField({
        ...props.search,
        value: props.search.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { loading, columns, list } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col span={16}>
            <Form.Item label="Reminder Name">
              {getFieldDecorator("search", {})(<Input />)}
            </Form.Item>
          </Col>
          <Col span={6} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          style={{
            marginTop: "10px"
          }}
        >
          <Table
            bordered
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            className={styles.ant_dev_table}
            columns={columns}
            dataSource={list}
            size="small"
            rowKey="reminderRuleId"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pacsReducer: { systemSettingsReducers: { ReminderListTReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: ReminderListTReducer,
    loading
  }),
  {
    changeVals,
    search,
    init_data
  }
)
class ReminderList extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.goDetail = this.goDetail.bind(this);
    this.state = {
      trainingStart: "",
      currentPageNumber: 1,
      fileList: [],
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "Reminder Requirement",
          dataIndex: "reminderRequirement",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Reminder Target",
          dataIndex: "reminderTarget",
          align: "left",
          width: 200,
          render: text => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Due Date",
          dataIndex: "dueDate",
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Reminder Rule",
          dataIndex: "Rule",
          align: "left",
          width: 200,
          render: () => {
            const texts = `Send out on [Time],[x] days before the End of calendar Year, and with frequency by every [y] [days]/[weeks]. until End of Calendar Year.`;
            return (
              <Tooltip placement="topLeft" title={texts}>
                {texts}
              </Tooltip>
            );
          }
        },

        {
          title: "Email Template(CC to leader)",
          dataIndex: "emailTemplate",
          align: "left",
          width: 200,
          render: text => (
            <Tooltip placement="topLeft" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Edit",
          dataIndex: "Edit",
          align: "center",
          width: 100,
          render: (text, record) => (
            <div className={styles.editBox}>
              <div
                className={styles.editIcon}
                style={setBg(editIcon)}
                onClick={() => {
                  this.goDetail(record.reminderRuleId);
                }}
              />
            </div>
          )
        }
      ]
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { search: searches } = this.props.location.state;
      if (searches) {
        this.props.search(searches);
      } else {
        this.props.search();
        // this.props.init_data();
      }
    } else {
      this.props.init_data();
    }
  }

  handleTableChange = pagination => {
    const { current } = pagination;
    this.setState(() => ({
      currentPageNumber: current
    }));
  };

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;

      if (!err) {
        this.props.search(fields.search.value);
      }
    });
  };

  goDetail(reminderRuleId) {
    this.props.history.push(
      "/home/system_settings/reminder_rule/reminder_detail",
      {
        reminderRuleId,
        search: this.props.fields.search.value
      }
    );
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange
    };
    return (
      <div className={styles.container}>
        <div className={styles.title}>Reminder Rule</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});

const insideStyles = {
  goCenter: {
    display: "flex",
    // justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

export default ReminderList;
