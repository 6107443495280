import React from 'react';
import { Form, Button, Row, Col } from "antd";
import styles from './index.module.less';
import { flexRow } from '../utils';

const ActionButton = (props) => {
  const { isEditable, handleEdit, handleSubmit } = props;

  return (
    <Row {...flexRow}>
      <Col className={styles.submitBox}>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleSubmit}
            className={
              isEditable ? styles.editbalBtn : styles.submitBtn
            }
            disabled={!isEditable}
          >
            Save
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleEdit}
            className={styles.cancelBtn}
          >
            {isEditable ? "Cancel" : "Edit"}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
}

export default ActionButton;
