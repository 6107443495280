import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";
import { globalPromptTime10s } from "../../../utils/global";

const INITDATA_REGISTRATION_SUMMARY = "PAMB/INITDATA_REGISTRATION_SUMMARY";
const SAVEDATA_REGISTRATION_SUMMARY = "PAMB/SAVEDATA_REGISTRATION_SUMMARY";

const initState = {
  courseName: {
    value: ""
  },
  courseCode: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  reportDate: {
    value: ""
  },
  courseNameList: [],
  courseCodeList: [],
  sessionNameList: [],
  searchList: [],
  totalPage: 0,
  total: 0,
  pageSize: 10,
  currentPageNumber: 1
};

export default function RegistrationSummaryReducer(state = initState, action) {
  switch (action.type) {
    case "RegistrationSummary":
      return { ...state, ...action.payload };
    case INITDATA_REGISTRATION_SUMMARY:
      return { ...initState };
    case SAVEDATA_REGISTRATION_SUMMARY:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_REGISTRATION_SUMMARY
  };
}

export function save_data(payload) {
  return {
    type: SAVEDATA_REGISTRATION_SUMMARY,
    payload
  };
}

// GET /pacs/attendanceReport/getCourseCodeList
export function getCourseCodeList() {
  return dispatch => {
    get("/attendanceReport/getCourseCodeList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                courseCodeList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /pacs/attendanceReport/getCourseNameList
export function getCourseNameList() {
  return dispatch => {
    get("/attendanceReport/getCourseNameList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                courseNameList: [...res.data]
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /pacs/attendanceReport/getSessionNameList
export function getSessionNameList(courseId) {
  return dispatch => {
    get("/attendanceReport/getSessionNameList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data(
              {
                sessionNameList: [...res.data],
                sessionName: {
                  value: ""
                }
              }
            )
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /pacs/attendanceReport/getRegistrationSummaryReport
export function getRegistrationSummaryReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/attendanceReport/getRegistrationSummaryReport", data)
      .then(res => {
        if (res.success) {
          const finalData = res.data.data.length ? [...res.data.data] : [];
          finalData.map((item, index) => {
            item.index = `${index}&&${item.fcCode}`;
            return item;
          });
          dispatch(
            save_data({
              searchList: res.data.data,
              totalPage: res.data.totalPage,
              total: res.data.total,
              pageSize: res.data.pageSize,
              currentPageNumber: res.data.currentPageNumber
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

// POST /pacs/attendanceReport/downloadRegistrationSummaryReport
export function downloadRegistrationSummaryReport(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/attendanceReport/downloadRegistrationSummaryReport", data, {}, "blob")
      .then(blob => {
        const fileName = "RegistrationSummaryReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
