// import Login from "../views/user/Login";
import Home from "../views/home";

import Forbidden from "../views/exception/Forbidden";
import NotFound from "../views/exception/NotFound";
import ServerError from "../views/exception/ServerError";
import NoAuth from "../views/exception/NoAuth";
// attendance
import QRCodePage from "../views/home/pamb/attendance/QRCode";
import { globalUrlPre } from "../utils/config";

/**
 * hasSecondRoute: If the first level of menu has a second level of sidebar routing, this attribute should be added. The main function is to dynamically load the first sidebar menu
 * redirctPath: The back-end returned path, but it is not the final route, which needs to be redirected
 * customDevPath: The back-end does not returned path, but the front-end local needs to configure the redirected route
 * initialRoute: The back-end does not returned path，it is Locally initialized route
 */
const RouteConfig = [
  {
    title: "home",
    path: "/home",
    component: Home,
    initialRoute: true,
    children: [
      {
        id: "404",
        path: "/home/404",
        component: NotFound,
        initialRoute: true
      },
      {
        id: "403",
        path: "/home/403",
        component: Forbidden,
        initialRoute: true
      },
    ]
  },
  {
    id: "qrcode_attendance",
    title: "QRCode Attendance",
    path: `/${globalUrlPre}/qrcode_attendance`,
    customDevPath: true,
    exact: true,
    component: QRCodePage,
    initialRoute: true
  },
  {
    id: "403",
    path: "/403",
    component: Forbidden,
    initialRoute: true
  },
  {
    id: "401",
    path: "/401",
    component: NoAuth,
    initialRoute: true
  },
  {
    id: "404",
    path: "/404",
    component: NotFound,
    initialRoute: true
  },
  {
    id: "500",
    path: "/500",
    component: ServerError,
    initialRoute: true
  },
];

export default RouteConfig;
