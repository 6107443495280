import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Tooltip,
  Table,
  // notification,
  DatePicker,
  // Icon
} from "antd";
// import moment from "moment";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import {
  innit_data,
  getCourseNameList,
  getSessionNameList,
  getSurveySummaryReport,
  getSurveyDetailReport,
  downloadSurveySummaryReport,
  downloadSurveyDetailReport
} from "../../../../redux/plukReducer/CPDReportReducers/CourseRatingSummaryReportPLUKReducer";
// import { globalPromptTime10s } from "../../../../utils/global";
import {
  attendanceDetails
} from "../../../../images";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateDeductionReport_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("CourseRatingSummaryReportPLUK", changedFields);
  },
  mapPropsToFields(props) {
    return {
      courseName: Form.createFormField({
        ...props.courseName,
        value: props.courseName.value
      }),
      sessionName: Form.createFormField({
        ...props.sessionName,
        value: props.sessionName.value
      }),
      trainingDate: Form.createFormField({
        ...props.trainingDate,
        value: props.trainingDate.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const { loading } = props;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Name">
              {getFieldDecorator("courseName", {
                rules: [
                  {
                    required: true,
                    message: "Please fill in the mandatory fields"
                  }
                ]
              })(
                <Select
                  showSearch
                  showArrow={false}
                  defaultActiveFirstOption={false}
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  }
                  onChange={e => {
                    props.getSessionNameList(e);
                  }}
                >
                  {props.courseList.length
                    ? props.courseList.map(item => (
                      <Option value={item.courseId} key={item.courseId}>
                        <Tooltip placement="top" title={item.courseName}>
                          {item.courseName}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Session Name">
              {getFieldDecorator("sessionName", {
                // rules: [
                //   {
                //     required: true,
                //     message: "Please fill in the mandatory fields"
                //   }
                // ]
              })(
                <Select
                  filterOption={false}
                  defaultActiveFirstOption={false}
                >
                  <Option value="">All</Option>
                  {props.sessionList.length
                    ? props.sessionList.map(item => (
                      <Option value={item.sessionId} key={item.sessionId}>
                        <Tooltip placement="top" title={item.sessionName}>
                          {item.sessionName}
                        </Tooltip>
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item
              labelCol={{ span: 5 }}
              label="Training Date"
              style={{ marginBottom: 0 }}
            >
              {getFieldDecorator("trainingDate", {})(
                <RangePicker
                  // showTime={{
                  //   hideDisabledOptions: true,
                  //   format: "HH:mm",
                  //   defaultValue: [
                  //     moment("00:00", "HH:mm"),
                  //     moment("23:59", "HH:mm")
                  //   ]
                  // }}
                  placeholder={["From Date", "To Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
        </Row> */}
        <div
          className={styles.line}
        // style={{ display: props.searchList.length ? "block" : "none" }}
        >
          {" "}
        </div>
        <Button
          className={styles.btn}
          style={{ display: props.searchList.length ? "block" : "none" }}
          type="danger"
          icon="download"
          onClick={() => {
            props.downloadSurveySummaryReport();
          }}
        >
          Download to Excel
        </Button>
        <div
          style={{
            marginTop: "10px",
            display: props.searchList.length ? "block" : "none"
          }}
        >
          <Table
            bordered
            scroll={{ x: 3200 + ((props.qIndex + 1) * 250) }}
            className={styles.ant_dev_table}
            columns={props.dynamicColumns(props)}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            dataSource={props.searchList}
            size="small"
            rowKey="sessionId"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { CPDReportReducers: { CourseRatingSummaryReportPLUKReducer } },
    PublicReducer: { loading }
  }) => ({
    fields: CourseRatingSummaryReportPLUKReducer,
    loading
  }),
  {
    changeVals,
    innit_data,
    getCourseNameList,
    getSessionNameList,
    getSurveySummaryReport,
    getSurveyDetailReport,
    downloadSurveySummaryReport,
    downloadSurveyDetailReport
  }
)
class CourseRatingSummaryReportPLUK extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.sHandleTableChange = this.sHandleTableChange.bind(this);
    this.getShowTable = this.getShowTable.bind(this);
    this.dynamicColumns = this.dynamicColumns.bind(this);
    this.sDynamicColumns = this.sDynamicColumns.bind(this);
    this.downloadSurveySummaryReport = this.downloadSurveySummaryReport.bind(this);
    this.downloadSurveyDetailReport = this.downloadSurveyDetailReport.bind(this);
    this.state = {
      sessionId: "",
      showTable: false,
      pageSize: 10,
      pageIndex: 1,
      sPageSize: 10,
      sPageIndex: 1,
      showColumns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          width: 100,
          fixed: "left",
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: "left",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Venue",
          dataIndex: "venue",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Start Date",
          dataIndex: "startDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session End Date",
          dataIndex: "endDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Trainer",
          dataIndex: "trainer",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC code",
          dataIndex: "loginAccount",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agent Name",
          dataIndex: "agentName",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Over All Rating",
          dataIndex: "overAllRating",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ],
      columns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          width: 100,
          fixed: "left",
          align: "center",
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Option",
          dataIndex: "option",
          ellipsis: true,
          align: "center",
          fixed: "left",
          width: 200,
          render: (text, record) => {
            return (
              <div style={insideStyles.tableData}>
                <Tooltip placement="top" title="Detail">
                  <div
                    className={styles.details}
                    style={setBg(attendanceDetails)}
                    onClick={() => {
                      this.goDetail(record.sessionId);
                    }}
                  />
                </Tooltip>
              </div>
            );
          }
        },
        {
          title: "Course Name",
          dataIndex: "courseName",
          ellipsis: true,
          align: "center",
          fixed: "left",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Name",
          dataIndex: "sessionName",
          ellipsis: true,
          align: "center",
          fixed: "left",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Venue",
          dataIndex: "venue",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session Start Date",
          dataIndex: "startDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Session End Date",
          dataIndex: "endDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Trainer",
          dataIndex: "trainer",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Total Attendance",
          dataIndex: "totalAttendance",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
          // render: (text, record) => (
          //   <Tooltip placement="top" title={text} onClick={() => {
          //     if (record.totalAttendance != "0" && record.totalAttendance != null) {
          //       this.getShowTable(record.sessionId);
          //     }
          //   }}>
          //     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, cursor: "pointer", position: "absolute", width: "100%", height: "100%" }}>
          //       {text}
          //     </div>
          //   </Tooltip>
          // )
        },
        {
          title: "Total Rating Received",
          dataIndex: "totalRatingReceived",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Overall Average Course Rating",
          dataIndex: "overallAverageCourseRating",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      ]
    };
  }

  componentDidMount() {
    if (!this.props.location.state || !this.props.location.state.detail) {
      // this.props.location.state.detail = false;
      // console.log(this.props.location) // eslint-disable-line
      this.props.innit_data();
    }
    this.props.getCourseNameList();
    // this.props.getSurveySummaryReport({
    //   "courseId": "9MMtzpLrQfw1",
    //   "currentPageNumber": 1,
    //   "endDate": "",
    //   "pageSize": 10,
    //   "sessionId": "",
    //   "startDate": ""
    // });
    // this.getShowTable();
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        this.setState(() => ({
          currentPageNumber: 1
        }));
        this.props.getSurveySummaryReport({
          courseId: fields.courseName.value,
          sessionId: fields.sessionName.value,
          startDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[0].format("YYYY-MM-DD")
            : "",
          endDate: fields.trainingDate.value.length
            ? fields.trainingDate.value[1].format("YYYY-MM-DD")
            : "",
          pageSize: 10,
          currentPageNumber: 1,
        });
      }
    });
  };

  goDetail(sessionId) {
    this.props.history.push("/home/cpd_reports/cpd_reports_courseratingsummaryReportDetail_pacs", {
      sessionId
    });
  }

  downloadSurveySummaryReport() {
    const { fields, downloadSurveySummaryReport } = this.props;
    downloadSurveySummaryReport({
      courseId: fields.courseName.value,
      sessionId: fields.sessionName.value,
      startDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD")
        : "",
      endDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD")
        : "",
    });
  }

  downloadSurveyDetailReport() {
    const { downloadSurveyDetailReport } = this.props;
    const { sessionId } = this.state;
    downloadSurveyDetailReport({
      sessionId,
    });
  }

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { fields } = this.props;
    this.props.getSurveySummaryReport({
      courseId: fields.courseName.value,
      sessionId: fields.sessionName.value,
      startDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[0].format("YYYY-MM-DD")
        : "",
      endDate: fields.trainingDate.value.length
        ? fields.trainingDate.value[1].format("YYYY-MM-DD")
        : "",
      pageSize,
      currentPageNumber: current
    });
  };

  sHandleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { sessionId } = this.state;
    this.props.getSurveyDetailReport({
      sessionId,
      pageSize,
      currentPageNumber: current
    });
  };

  dynamicColumns(props) {
    const { searchList, columns } = props;
    const finalColumns = [...columns];
    // console.log("finalColumns:", finalColumns) // eslint-disable-line
    /* eslint-disable */
    if (searchList.length) {
      searchList.forEach((item) => {
        item.questionList.forEach((_item) => {
          const questionName = _item.questionName.substr(0, 1).toUpperCase() + _item.questionName.substr(1);
          const showName = _item.showName.replace(/\s/g, "");
          const new_columns = {
            title: `${questionName} ( ${showName} )`,
            children: []
          };
          for (let x in _item) {
            if (x !== "questionName" && x !== "showName") {
              const str = x.replace(/([A-Z])/g, " $1")
              const title = str.substr(0, 1).toUpperCase() + str.substr(1);
              new_columns.children.push(
                {
                  title,
                  dataIndex: `${x}_${_item.questionName}`,
                  ellipsis: true,
                  align: "center",
                  width: 250,
                  render: text => (
                    <Tooltip placement="top" title={text}>
                      {text}
                    </Tooltip>
                  )
                }
              )
            }
          };
          finalColumns.push(new_columns);
        });
      });
      console.log("finalColumns:", finalColumns);
      finalColumns.push(
        {
          title: "Total Comments Received",
          dataIndex: "totalCommentsReceived",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Total Single Option Received",
          dataIndex: "totalSingleOptionReceived",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Total Multi Option Received",
          dataIndex: "totalMultiOptionReceived",
          ellipsis: true,
          align: "center",
          width: 250,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        }
      );
    }
    /* eslint-disable */
    return finalColumns;
  }

  sDynamicColumns() {
    const { searchShortList } = this.props.fields;
    const { showColumns } = this.state;
    const finalColumns = [...showColumns];
    /* eslint-disable */
    if (searchShortList.length) {
      searchShortList.forEach((item) => {
        item.questionList.forEach((_item) => {
          finalColumns.push(
            {
              title: _item.showName,
              dataIndex: `${_item.questionName}_answer`,
              ellipsis: true,
              align: "center",
              width: 250,
              render: text => (
                <Tooltip placement="top" title={text}>
                  {text}
                </Tooltip>
              )
            }
          );
        });
      });
      console.log("sFinalColumns:", finalColumns);
    }
    /* eslint-disable */
    return finalColumns;
  }

  getShowTable(sessionId = "") {
    this.setState(() => ({
      showTable: !this.state.showTable,
      sessionId
    }), () => {
      if (this.state.showTable) {
        this.props.getSurveyDetailReport({
          sessionId,
          pageSize: 10,
          currentPageNumber: 1
        });
      } else {
        this.props.changeVals("CourseRatingSummaryReportPLUK", {
          searchShortList: [],
          sTotalPage: 0,
          sTotal: 0,
          sPageSize: 10,
          sCurrentPageNumber: 1,
          sQIndex: 0,
        })
      }
    });
  }

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      getSessionNameList: this.props.getSessionNameList,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      dynamicColumns: this.dynamicColumns,
      downloadSurveySummaryReport: this.downloadSurveySummaryReport
    };
    const { showTable, sPageSize } = this.state;
    const { fields } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>Course Rating Summary Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
        {/* <div className={styles.showTable} style={{ display: showTable && fields.searchShortList.length ? "block" : "none" }}>
          <div style={{ marginBottom: "20px", width: "100%", display: "flex", justifyContent: "space-between", color: "#ed1b2e", alignItems: "center" }}>
            <Button
              className={styles.btn}
              type="danger"
              icon="download"
              onClick={() => {
                this.downloadSurveyDetailReport();
              }}
            >
              Download to Excel
          </Button>
            <Icon
              type="close-circle"
              style={{ marginRight: 15, cursor: "pointer", fontSize: "25px" }}
              onClick={this.getShowTable}
            />
          </div>
          <div
            style={{ marginTop: "20px" }}
          >
            <Table
              bordered
              scroll={{ x: 3000 + ((fields.sQIndex + 1) * 250) }}
              className={styles.ant_dev_table}
              columns={this.sDynamicColumns()}
              onChange={pagination => {
                this.sHandleTableChange(pagination);
              }}
              pagination={{
                total: fields.sTotal,
                pageSize: sPageSize,
                current: fields.sCurrentPageNumber
              }}
              dataSource={fields.searchShortList}
              size="small"
              rowKey="userId"
            />
          </div>
        </div> */}
      </div>
    );
  }
}

const setBg = (url, size = "contain") => ({
  background: `url(${url}) center center no-repeat`,
  backgroundSize: size
});

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  }
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};
export default CourseRatingSummaryReportPLUK;
