
import { notification } from "antd";
import { post, deleteReq, put, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { downloadFile } from "../../../utils";
import { loading } from "../../PublicReducer";

export const SAVE_DATA = 'BULKADD_REGISTRATIONS_SAVE'
export const INIT_DATA = 'BULKADD_REGISTRATIONS_SAVE_INIT'

const initState = {
  registrations: [],
  fileId: "",
  containError: false,
};

// reducer
export default function RegistrationListReducer(state = initState, action) {
  switch (action.type) {
    case SAVE_DATA:
      return { ...state, ...action.payload };
    case INIT_DATA:
      return { ...initState };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: INIT_DATA,
  };
}

const baseApiUrl = '/pruventure/registrations'
export function validateFile(payload) {
  return dispatch => {
    dispatch(loading(true))
    post(`${baseApiUrl}/bulkImport/validation`, payload)
      .then(res => {
        dispatch(loading(false))
        if (res.success) {
          const data = res.data;
          dispatch({
            type: SAVE_DATA,
            payload: {
              ...data,
            }
          });
        } else {
          notification.error({
            message: "validate file error",
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function uploadFile(id, callBack) {
  return dispatch => {
    dispatch(loading(true))
    post(`${baseApiUrl}/bulkImport/${id}`)
      .then(res => {
        if (res.success) {
          callBack()
          dispatch(loading(false))
          notification.success({
            message: 'Uploaded successfully',
            duration: globalPromptTime10s,
          })
        } else {
          notification.error({
            message: 'upload error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}
