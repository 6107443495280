import { connect } from "react-redux";
import {
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  getUserRoleList
} from "../../../../redux/phklReducer/systemSettingsReducers/userManagement/action";
import { getMenuList } from "../../../../redux/homeReducer";
import { tabChangesTrigger, sessionDayChangesTrigger, getCourseAttendanceList } from "../../../../redux/phklReducer/attendanceReducers/CourseDetail/action";

const mapStateToProps = state => {
  return {
    homeReducer: state.homeReducer,
    userManagementReducer: state.phklReducer.systemSettingsReducers.userManagementReducer,
    CourseDetailReducer: state.phklReducer.attendanceReducers.CourseDetailReducer
  };
};

const mapDispatchToProps = {
  getMenuList,
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  getUserRoleList,
  tabChangesTrigger,
  sessionDayChangesTrigger,
  getCourseAttendanceList
};

export default connect(mapStateToProps, mapDispatchToProps);
