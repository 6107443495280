import React from "react";
import styles from '../index.module.less';
import Tabs from './Tabs';
import Selection from './Selection';
import withConnector from '../../connector';

function ActionBar(props) {
  return (
    <div className={styles.actionBar}>
      <Tabs {...props} />
      <Selection {...props} />
    </div>
  );
}

export default withConnector(ActionBar);
