import { message } from "antd";
import { post } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";

const SAVE = "pacs/CreateRegionAdminReducer/SAVE";

const initState = {
  createLoading: false
};

// reducer
export default function CreateRegionAdminReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function handleSubmit(payload, callback) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        createLoading: true
      }
    });
    post("/systemSetting/createRegionAdmin", payload)
      .then(() => {
        message.info("Saved successfully", globalPromptTime, callback);
        dispatch({
          type: SAVE,
          payload: {
            createLoading: false
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            createLoading: false
          }
        });
      });
  };
}
