import { combineReducers } from "redux";
import ManagementReportReducer from "./ManagementReportReducer";
import AttendedSummaryReducer from "./AttendedSummaryReducer";
import DeductionReportReducer from "./DeductionReportReducer";
import TrainingReportReducer from "./TrainingReportReducer";
import AttendanceSummaryReducer from "./AttendanceSummaryReducer";
import RegistrationSummaryReducer from "./RegistrationSummaryReducer";
import RegistrationAttendanceReducer from "./RegistrationAttendanceReducer";
import ManagementReportPACSReducer from "./ManagementReportPACSReducer";
import TrainingHistoryReportReducer from "./TrainingHistoryReportReducer";
import SFAReportReducer from "./SFAReportReducer";
import CourseRatingSummaryReportPACSReducer from "./CourseRatingSummaryReportPACSReducer";
import TrainingCodeMapReducer from "./TrainingCodeMapReducer";

export default combineReducers({
  ManagementReportReducer,
  AttendedSummaryReducer,
  DeductionReportReducer,
  TrainingReportReducer,
  AttendanceSummaryReducer,
  RegistrationSummaryReducer,
  RegistrationAttendanceReducer,
  ManagementReportPACSReducer,
  TrainingHistoryReportReducer,
  SFAReportReducer,
  CourseRatingSummaryReportPACSReducer,
  TrainingCodeMapReducer
});
