export const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

export const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

export const flexRow = {
  gutter: [22, 0]
};

export const formLayout2 = {
  labelCol: { span: 12 },
  wrapperCol: { span: 11 }
};

export const formLayout3 = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 }
};
