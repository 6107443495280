import { combineReducers } from "redux";
import requirementSettingReducer from "./requirementSettingReducer";
import commonReducer from "./commonReducer";
import CPDHistoryReducer from "./CPDHistoryReducer";
import ShiftInOutReducer from './ShiftInOutReducer'
import GrantHoursReducer from './GrantHoursReducer'

export default combineReducers({
  requirementSettingReducer,
  commonReducer,
  CPDHistoryReducer,
  ShiftInOutReducer,
  GrantHoursReducer
});
