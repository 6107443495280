import React, { Component } from 'react'
import {
  Form,
  Row,
  Col,
  Button,
  Radio,
} from "antd";
import { debounce } from 'lodash'
// import Router from "../../../../../../router";
import styles from './AttendanceDetail.module.less'
import {
  ONCAHENG_TYPE,
} from "../../../../../redux/pambReducer/mySessionReducers/AttendanceDetailReducer";

const flexCol = {
  lg: 24,
  // offset: 6
};

const formLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18
  },
};

const flexRow = {
  gutter: [8, 0]
};

const propStatusMapToValue = {
  Completed: '1',
  InCompleted: '2',
  'Not Attended': '3',
}

/**
 * @param {object} props
 * @param {object} props.form
 *
 */

export const CreateForm = Form.create({
  name: 'editAttendanceDetail_Form',
  onFieldsChange(props, changedFields) {
    props.onChange(ONCAHENG_TYPE, changedFields);
  },
  mapPropsToFields(props) {
    return {
      updateStatus: Form.createFormField({
        key: props.updateStatus || props.originStatus,
        value: props.updateStatus.value || propStatusMapToValue[props.originStatus]
      }),
      voidRecord: Form.createFormField({
        ...props.voidRecord,
        value: props.voidRecord.value
      }),
      clockRecord: Form.createFormField({
        ...props.clockRecord,
        value: props.clockRecord.value
      }),
    }
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <Form
      layout="horizontal"
      labelAlign="left"
      colon={false}
      {...formLayout}
    >
      <Row {...flexRow}>
        <Col {...flexCol}>
          <Form.Item
            label="Update To:">
            {getFieldDecorator("updateStatus", {
              initialValue: propStatusMapToValue[props.originStatus]
            })(
              <Radio.Group
              >
                {!!props.statuses.length &&
                  props.statuses.map(status => {
                    if (status.value) {
                      return (
                        <Radio style={radioStyle} key={status.key} value={status.value}>
                          {status.key}
                        </Radio>
                      );
                    }
                  })}
              </Radio.Group>
            )}
          </Form.Item>
        </Col>
      </Row>
      {
        // if status is not Attended or completed, hide this field
        ((props.form.getFieldValue('updateStatus') !== '1' && props.form.getFieldValue('updateStatus') !== '3') && props.originStatus === 'Completed') ?
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Void Record">
                {getFieldDecorator("voidRecord", {
                  initialValue: "SCAN_IN"
                })(
                  <Radio.Group>
                    <Radio style={radioStyle} value="SCAN_IN">
                      Scan In
                    </Radio>
                    <Radio style={radioStyle} value="SCAN_OUT">
                      Scan Out
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          : null
      }
      {
        ((props.form.getFieldValue('updateStatus') !== '1' && props.form.getFieldValue('updateStatus') !== '3') && props.originStatus === 'Not Attended') ?
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Clock In Record:">
                {getFieldDecorator("clockRecord", {
                  initialValue: 'SCAN_IN'
                })(
                  <Radio.Group>
                    <Radio style={radioStyle} value="SCAN_IN">Scan In</Radio>
                    <Radio style={radioStyle} value="SCAN_OUT">Scan Out</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
            </Col>
          </Row>
          : null
      }
      <div className={styles.modalActionBox}>
        <Button
          onClick={props.onCancel}>
          Cancel
        </Button>
        <Button
          type="primary"
          className={styles.confirmBtn}
          onClick={(e) => {
            e.preventDefault()
            props.form.validateFields((error, values) => {
              if (error) {
                return
              }
              props.onConfirm()
            })
          }}>
          Confirm
        </Button>
      </div>
    </Form>
  );
})
