import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Col,
  Button,
  DatePicker,
  Select,
  Spin,
  Tooltip,
  Table,
  Input,
  Checkbox,
  notification
} from "antd";
// import moment from "moment";
import { changeVals } from "../../../../redux/plukReducer/systemSettingsReducers/index.action";
import {
  innit_data,
  getAgentStatusSelect,
  getAgentLeaderSelect,
  getFcCodeSelect,
  getFullFillSelect,
  getUnitCodeSelect,
  getReportList,
  downloadRegistrationSummaryReport
} from "../../../../redux/plukReducer/CPDReportReducers/ManagementReportPLUKReducer";
import { globalPromptTime10s } from "../../../../utils/global";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "CreateManagementReportPLUK_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("ManagementReportPLUK", changedFields);
  },
  mapPropsToFields(props) {
    const { roleId, agentCode, unitCode } = props.userReducer;
    // console.log("userReducer:", roleId, agentCode, unitCode) // eslint-disable-line
    const isAgent = roleId === '8';
    return {
      agentLeader: Form.createFormField({
        ...props.agentLeader,
        value: props.agentLeader.value
      }),
      unitCode: Form.createFormField({
        ...props.unitCode,
        value: isAgent ? unitCode : props.unitCode.value
      }),
      trainingDateRange: Form.createFormField({
        ...props.trainingDateRange,
        value: props.trainingDateRange.value
      }),
      agentStatus: Form.createFormField({
        ...props.agentStatus,
        value: props.agentStatus.value
      }),
      contractDateRange: Form.createFormField({
        ...props.contractDateRange,
        value: props.contractDateRange.value
      }),
      fcCode: Form.createFormField({
        ...props.fcCode,
        value: isAgent ? agentCode : props.fcCode.value
      }),
      terminationDateRange: Form.createFormField({
        ...props.terminationDateRange,
        value: props.terminationDateRange.value
      }),
      fullFill: Form.createFormField({
        ...props.fullFill,
        value: props.fullFill.value
      }),
      includeShiftedHours: Form.createFormField({
        ...props.includeShiftedHours,
        value: props.includeShiftedHours.value
      })
    };
  }
})(props => {
  const { getFieldDecorator } = props.form;
  const { Option } = Select;
  const { loading, userReducer } = props;
  const { RangePicker } = DatePicker;
  const { roleId } = userReducer;
  const isAgent = roleId === '8';
  const isAgentLeader = roleId === '12';
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 9 }}
        labelAlign="left"
        wrapperCol={{ span: 15 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          {
            (isAgent || isAgentLeader) ? null : (
              <Col {...flexCol}>
                <Form.Item label="Agent Leader">
                  {getFieldDecorator("agentLeader", {})(
                    <Select
                      showSearch
                      showArrow
                      defaultActiveFirstOption={false}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        // console.log(option) // eslint-disable-line
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      <Option value="">All</Option>
                      {props.agentLeaderList.length
                        ? props.agentLeaderList.map(item => (
                          <Option value={item.leaderCode} key={item.leaderCode}>
                            {item.leaderName}
                          </Option>
                        ))
                        : null}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            )
          }
          <Col {...flexCol}>
            <Form.Item label="Unit Code">
              {getFieldDecorator("unitCode", {})(
                isAgent ?
                  (<Input disabled />) :
                  (
                    <Select
                      disabled={isAgent}
                      showSearch
                      showArrow
                      allowClear
                      defaultActiveFirstOption={false}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        // console.log(option) // eslint-disable-line
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      <Option value="">All</Option>
                      {props.unitCodeList.length
                        ? props.unitCodeList.map(item => (
                          <Option value={item.unitCode} key={item.unitCode}>
                            {item.unitCode}
                          </Option>
                        ))
                        : null}
                    </Select>
                  )
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Training Date Range">
              {getFieldDecorator("trainingDateRange", {
                // rules: [
                //   {
                //     required: true,
                //     message: "Please fill in the mandatory fields"
                //   }
                // ]
              })(
                <RangePicker
                  // showTime={{
                  //   hideDisabledOptions: true,
                  //   defaultValue: [
                  //     moment("00:00:00", "HH:mm:ss"),
                  //     moment("23:59:59", "HH:mm:ss")
                  //   ]
                  // }}
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Agent Status">
              {getFieldDecorator("agentStatus", {})(
                <Select
                  disabled={isAgent}
                  // showSearch
                  showArrow
                  // allowClear
                  defaultActiveFirstOption={false}
                // optionFilterProp="children"
                // filterOption={(input, option) => {
                //   console.log(option) // eslint-disable-line
                //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                // }
                >
                  <Option value="">All</Option>
                  {props.agentStatusList.length
                    ? props.agentStatusList.map(item => (
                      <Option value={item.value} key={item.value}>
                        {item.code}
                      </Option>
                    ))
                    : null}
                </Select>
              )
              }
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Contract Date Range">
              {getFieldDecorator("contractDateRange", {})(
                <RangePicker
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="FC Code">
              {getFieldDecorator("fcCode", {})(
                isAgent ?
                  (<Input disabled />) :
                  (
                    <Select
                      showSearch
                      showArrow
                      allowClear
                      defaultActiveFirstOption={false}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        // console.log(option) // eslint-disable-line
                        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                    >
                      <Option value="">All</Option>
                      {props.fcCodeList.length
                        ? props.fcCodeList.map(item => (
                          <Option value={item.agentCode} key={item.agentCode}>
                            {item.agentCode}
                          </Option>
                        ))
                        : null}
                    </Select>
                  )
              )}
            </Form.Item>
          </Col>

          <Col {...flexCol}>
            <Form.Item label="Termination Date Range">
              {getFieldDecorator("terminationDateRange", {})(
                <RangePicker
                  placeholder={["Start Date", "End Date"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD", "YYYY-MM-DD"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Fulfillment status">
              {getFieldDecorator("fullFill", {
                // rules: [
                //   {
                //     required: true,
                //     message: "Please fill in the mandatory fields"
                //   }
                // ]
              })(
                <Select
                  // showSearch
                  showArrow
                  // allowClear
                  defaultActiveFirstOption={false}
                // optionFilterProp="children"
                // filterOption={(input, option) => {
                //   console.log(option) // eslint-disable-line
                //   return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }
                // }
                >
                  <Option value="">All</Option>
                  {props.fullFillList.length
                    ? props.fullFillList.map(item => (
                      <Option value={item.value} key={item.value}>
                        {item.code}
                      </Option>
                    ))
                    : null}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item>
              {getFieldDecorator("includeShiftedHours", {
                // rules: [
                //   {
                //     required: true,
                //     message: "Please fill in the mandatory fields"
                //   }
                // ]
              })(<Checkbox defaultChecked>Include Shifted Hours</Checkbox>)}
            </Form.Item>
          </Col>

          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div
          className={styles.line}
        >
          {" "}
        </div>
        {/* <Button
          style={{ display: props.searchList.length ? "inline-block" : "none" }}
          className={styles.btn}
          type="danger"
          icon="download"
          onClick={() => {
            props.download_summary();
          }}
        >
          Download to Excel
        </Button> */}
        <div
          style={{
            marginTop: "10px",
            display: props.searchList.length ? "block" : "none"
          }}
        >
          <Table
            bordered
            scroll={{ x: 4000 }}
            className={styles.ant_dev_table}
            // columns={props.columns}
            columns={props.dynamicColumns(props)}
            // onChange={pagination => {
            //   props.handleTableChange(pagination);
            // }}
            // pagination={{
            //   total: props.total,
            //   pageSize: props.pageSize,
            //   current: props.currentPageNumber
            // }}
            dataSource={props.searchList}
            size="small"
            rowKey="unitCode"
          />

        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    plukReducer: { CPDReportReducers: { ManagementReportPLUKReducer } },
    PublicReducer: { loading },
    userReducer
  }) => ({
    fields: ManagementReportPLUKReducer,
    loading,
    userReducer
  }),
  {
    changeVals,
    innit_data,
    getAgentStatusSelect,
    getAgentLeaderSelect,
    getFcCodeSelect,
    getFullFillSelect,
    getUnitCodeSelect,
    getReportList,
    downloadRegistrationSummaryReport
  }
)
class ManagementReportPLUK extends Component {
  constructor(props) {
    super(props);
    this.download_summary = this.download_summary.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.dynamicColumns = this.dynamicColumns.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      columns: [
        {
          title: "No",
          dataIndex: "",
          ellipsis: true,
          align: "center",
          width: 100,
          fixed: 'left',
          render: (text, item, index) => {
            /* eslint-disable */
            const a = text;
            const b = item;
            /* eslint-disable */
            const c = index + 1;
            return c;
          }
        },
        {
          title: "Unit Code",
          dataIndex: "unitCode",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: 'left',
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "FC Code",
          dataIndex: "agentCode",
          ellipsis: true,
          align: "center",
          width: 200,
          fixed: 'left',
          render: (text, record) => (
            <Tooltip placement="top" title={text}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: record.nne === "Y" ? "orange" : "", position: "absolute", width: "100%", height: "100%" }}>
                {text}
              </div>
            </Tooltip>
          )
        },
        {
          title: "Name",
          dataIndex: "userName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Date of Appt.",
          dataIndex: "contractedDate",
          ellipsis: true,
          align: "center",
          width: 200,
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Agency Leader",
          dataIndex: "leaderName",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Bdm",
          dataIndex: "bdm",
          ellipsis: true,
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        // {
        //   title: 'CPD Hours Attained',
        //   children: [
        //     {
        //       title: "Core",
        //       dataIndex: "agentName",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "S(T)",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "A&H",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "CIS",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     }
        //   ]
        // },
        // {
        //   title: 'CPD Hours Requirement',
        //   children: [
        //     {
        //       title: "Core",
        //       dataIndex: "agentName",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "S(T)",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "A&H",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "CIS",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     }
        //   ]
        // },
        // {
        //   title: 'CPD Hours Shortfall',
        //   children: [
        //     {
        //       title: "Core",
        //       dataIndex: "agentName",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "S(T)",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "A&H",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     },
        //     {
        //       title: "CIS",
        //       dataIndex: "unitCode",
        //       ellipsis: true,
        //       align: "center",
        //       render: text => (
        //         <Tooltip placement="top" title={text}>
        //           {text}
        //         </Tooltip>
        //       )
        //     }
        //   ]
        // }
      ]
    };
  }

  componentDidMount() {
    this.props.innit_data();
    this.props.getAgentLeaderSelect();
    this.props.getAgentStatusSelect();
    this.props.getFcCodeSelect();
    this.props.getFullFillSelect();
    this.props.getUnitCodeSelect();
    // this.props.getReportList({
    //   // bdm: 1
    //   "leadercode": "",
    //   "unitCode": "",
    //   "status": "",
    //   "agentCode": "",
    //   "fulfillmentStatus": "",
    //   "contractedDateStart": "",
    //   "contractedDateEnd": "",
    //   "terminationDateStart": "",
    //   "terminationDateEnd": "",
    //   "trainingDateStart": "",
    //   "trainingDateEnd": ""
    // });
  }

  dynamicColumns(props) {
    const { searchList, columns } = props;
    const finalColumns = [...columns];
    // console.log("finalColumns:", finalColumns) // eslint-disable-line
    /* eslint-disable */
    if (searchList.length) {
      const attainedColumns = {
        title: "CPD Hours Attained",
        children: []
      }
      const requirementColumns = {
        title: "CPD Hours Requirement",
        children: []
      }
      const shortFallColumns = {
        title: "CPD Hours Shortfall",
        children: []
      }
      for (let q in searchList[0].attainedMap) {
        attainedColumns.children.push(
          {
            title: q,
            dataIndex: `attained_${q}`,
            ellipsis: true,
            align: "center",
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        )
      };
      for (let w in searchList[0].requirementMap) {
        requirementColumns.children.push(
          {
            title: w,
            dataIndex: `requirement_${w}`,
            ellipsis: true,
            align: "center",
            render: text => (
              <Tooltip placement="top" title={text}>
                {text}
              </Tooltip>
            )
          }
        )
      };
      for (let e in searchList[0].shortFallMap) {
        shortFallColumns.children.push(
          {
            title: e,
            dataIndex: `shortFall_${e}`,
            ellipsis: true,
            align: "center",
            render: (text, record) => {
              if (record[`shortFall_${e}`] != "" && record[`shortFall_${e}`] != "0" && record[`shortFall_${e}`] != "0.0") {
                return (
                  <Tooltip placement="top" title={text}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', top: 0, left: 0, background: "pink", position: "absolute", width: "100%", height: "100%" }}>
                      {text}
                    </div>
                  </Tooltip >
                )
              }
              return (<Tooltip placement="top" title={text}>{text}</Tooltip>)
            }
          }
        )
      };
      finalColumns.push(attainedColumns, requirementColumns, shortFallColumns,
        {
          title: "Fulfill",
          dataIndex: "fulfillmentStatus",
          ellipsis: true,
          align: "center",
          // width: 100,
          // fixed: 'right',
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        });
      // console.log("attainedColumns:", attainedColumns);
      // console.log("requirementColumns:", requirementColumns);
      // console.log("shortFallColumns:", shortFallColumns);
    }
    /* eslint-disable */
    return finalColumns;
  }


  handleTableChange = pagination => {
    // const { current, pageSize } = pagination;
    // const { fields } = this.props;
    // this.props.getRegistrationSummaryReport({
    //   courseCode: fields.courseCode.value,
    //   courseId: fields.courseName.value,
    //   endDate: fields.reportDate.value.length
    //     ? fields.reportDate.value[1].format("YYYY-MM-DD")
    //     : "",
    //   sessionId: fields.sessionName.value,
    //   startDate: fields.reportDate.value.length
    //     ? fields.reportDate.value[0].format("YYYY-MM-DD")
    //     : "",
    //   pageSize,
    //   currentPageNumber: current
    // });
  };

  download_summary() {
    const { fields } = this.props;
    this.props.downloadRegistrationSummaryReport({
      courseCode: fields.courseCode.value,
      courseId: fields.courseName.value,
      endDate: fields.reportDate.value.length
        ? fields.reportDate.value[1].format("YYYY-MM-DD")
        : "",
      sessionId: fields.sessionName.value,
      startDate: fields.reportDate.value.length
        ? fields.reportDate.value[0].format("YYYY-MM-DD")
        : "",
      pageSize: this.props.pageSize,
      currentPageNumber: this.props.currentPageNumber
    });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      if (!err) {
        if (fields.trainingDateRange.value.length) {
          // const end = fields.trainingDateRange.value[1].valueOf();
          // const start = fields.trainingDateRange.value[0];
          // const nowYear = moment(start).add(1, "year").valueOf();
          const start = new Date(fields.trainingDateRange.value[0]).getFullYear();
          const end = new Date(fields.trainingDateRange.value[1]).getFullYear();
          // console.log("fields:", fields) // eslint-disable-line
          if (end !== start) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        this.props.getReportList({
          leaderCode: fields.agentLeader.value,
          unitCode: fields.unitCode.value,
          status: fields.agentStatus.value,
          agentCode: fields.fcCode.value,
          fulfillmentStatus: fields.fullFill.value,
          contractedDateStart: fields.contractDateRange.value.length ? fields.contractDateRange.value[0].format("YYYY-MM-DD") : "",
          contractedDateEnd: fields.contractDateRange.value.length ? fields.contractDateRange.value[1].format("YYYY-MM-DD") : "",
          terminationDateStart: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[0].format("YYYY-MM-DD") : "",
          terminationDateEnd: fields.terminationDateRange.value.length ? fields.terminationDateRange.value[1].format("YYYY-MM-DD") : "",
          trainingDateStart: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[0].format("YYYY-MM-DD") : "",
          trainingDateEnd: fields.trainingDateRange.value.length ? fields.trainingDateRange.value[1].format("YYYY-MM-DD") : "",
          includeShiftedHours: fields.includeShiftedHours.value ? 1 : 0
        });
      }
    });
  };

  render() {
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      userReducer: this.props.userReducer,
      columns: this.state.columns,
      download_summary: this.download_summary,
      currentPageNumber: this.state.currentPageNumber,
      handleTableChange: this.handleTableChange,
      dynamicColumns: this.dynamicColumns
    };

    return (
      <div className={styles.container}>
        <div className={styles.title}>CPD Management Report</div>
        <div className={styles.col_dev_left_long}>
          <CreateForm {...props} {...this.props.fields} />
        </div>
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    width: "100%",
    overflow: "auto",
    textAlign: "center",
    color: "#666666",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "0 5px"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
};

const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24
};

export default ManagementReportPLUK;
