import React, { Component } from "react";
import { Link } from "react-router-dom";
import { notFound } from "../../images"
import styles from './ServerError.module.less'

export default class ServerError extends Component {
  render() {
    return (
      <div className={styles.container}>
        <img src={notFound} className={styles.img} alt="" />
        <div className={styles.right}>
          <div className={styles.num}>500</div>
          <div className={styles.text}>Sorry, the server is wrong.</div>
          <div className={styles.btn}><Link to='/'>Back Home</Link></div>
        </div>
      </div>
    );
  }
}
