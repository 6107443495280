import { notification } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";
import { downloadFile } from "../../../utils";
import { handleCheckToken } from "../../../utils/refreshToken";

const INITDATA_DEDUCTION_REPORT = "PACS/INITDATA_DEDUCTION_REPORT";
const SAVE_DEDUCTION_REPORT_DATA = "PACS/SAVE_DEDUCTION_REPORT_DATA";

const initState = {
  courseName: {
    value: ""
  },
  sessionName: {
    value: ""
  },
  sessionDayName: {
    value: ""
  },
  region: {
    value: ""
  },
  trainingDate: {
    value: ""
  },
  grossTotal: "",
  sstTotal: "",
  installment: "",
  courseList: [],
  sessionList: [],
  sessionDayList: [],
  regionList: [],
  searchList: [],
  searchShortList: [],
  showList: false
};

export default function DeductionReportReducer(state = initState, action) {
  switch (action.type) {
    case "DeductionReport":
      return { ...state, ...action.payload };
    case INITDATA_DEDUCTION_REPORT:
      return { ...initState };
    case SAVE_DEDUCTION_REPORT_DATA:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

export function innit_data() {
  return {
    type: INITDATA_DEDUCTION_REPORT
  };
}

export function save_data(payload) {
  return {
    type: SAVE_DEDUCTION_REPORT_DATA,
    payload
  };
}
// GET /pamb/deduction/courseList
export function getCourseList() {
  return dispatch => {
    get("/deduction/courseList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              courseList: res.data.length ? [...res.data] : [],
              courseName: {
                value: res.data.length ? res.data[0].id : ""
              }
            })
          );
          if (res.data.length) {
            dispatch(getSessionList(res.data[0].id));
          }
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /pamb/deduction/sessionList
export function getSessionList(courseId) {
  return dispatch => {
    get("/deduction/sessionList", { courseId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionList:
                res.data.length && res.data[0] !== null
                  ? [...res.data]
                  : [
                    {
                      id: "none",
                      name: "N/A"
                    }
                  ],
              sessionDayList: [],
              sessionName: {
                value:
                  res.data.length && res.data[0] !== null
                    ? res.data[0].id
                    : "none"
              },
              sessionDayName: {
                value: res.data.length ? "" : "none"
              }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /pamb/deduction/sessionDayList
export function getSessionDayList(sessionId) {
  return dispatch => {
    get("/deduction/sessionDayList", { sessionId })
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              sessionDayList: res.data.length
                ? [...res.data]
                : [
                  {
                    id: "none",
                    name: "N/A"
                  }
                ],
              sessionDayName: {
                value: res.data.length ? "" : "none"
              }
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// GET /pamb/deduction/regionList
export function getRegionList() {
  return dispatch => {
    get("/deduction/regionList")
      .then(res => {
        if (res.success) {
          dispatch(
            save_data({
              regionList: res.data.length ? [...res.data] : []
            })
          );
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
      })
      .catch(() => { });
  };
}

// POST /pamb/deduction/deductionReport
export function searchDeductionReport(data) {
  return dispatch => {
    dispatch(loading(true));
    post("/deduction/deductionReport", data)
      .then(res => {
        if (res.success) {
          /* eslint-disable */
          const { list, grossTotal, sstTotal, installment } = res.data;
          dispatch(
            save_data({
              grossTotal,
              sstTotal,
              installment,
              searchList: [...list],
              showList: true
            })
          );
          /* eslint-disable */
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          });
        }
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
// POST /pamb/deduction/downloadDeductionReport
export function download_deduction(data) {
  return async dispatch => {
    await handleCheckToken()
    dispatch(loading(true));
    post("/deduction/downloadDeductionReport", data, {}, "blob")
      .then(blob => {
        const fileName = "DeductionReport.xls"; // Registration Attendance
        downloadFile(blob, fileName);
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);

      });
  };
}
