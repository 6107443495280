/* eslint-disable */
import React, { useState, useEffect, useReducer } from "react";
import { Form, Row, Col, Tooltip, Input, Spin } from "antd";
import { withRouter } from "react-router-dom";
import withConnector from './../../connector';
import CustomList from '../../../../../../components/CustomList';
import { getColumns } from './columns';
import { get, post } from '../../../../../../../utils/request';



function CPDhourEditingHisotry(props) {
  const { courseUserId } = props.location.state;
  console.log('courseUserId', courseUserId)
  const initialFilters = {
    headerTitle: 'CPD Hour Editing History',
    keyword: 'CPDHourHistoryEditing',
    fetchUrl: `/cpdEditing/hours/${courseUserId}/history`,
    method: 'get',
    searchFileds: [],
    columns: [],
    needTimestamp: false,
    loading: false,
    rowKey: 'courseUserId'
  }
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    const fetchAPI = async () => {
      await fetchData();
    }

    fetchAPI();

    return;
    // eslint-disable-next-line
  }, [])

  const fetchData = async () => {
    setLoading(true);
    setFilters(
      {
        ...filters,
        ...{
          columns: getColumns(),
          // searchFileds: getSearchFileds(searchFiledsParams),
        }
      }
    )

    setLoading(false);
  }


  return (
    <div className="editing-hour">
      <Spin spinning={loading}>
        <CustomList
          {...filters}
          canScroll={true}
          maxHeight={300}
          rowKey="editing-hour-histroy"
          noSearch={true}
          isTopNav={false}
        />
      </Spin>
    </div>

  );
}

export default withConnector(withRouter(CPDhourEditingHisotry));
