import { globalUrlPre } from './config';

export const globalPromptTime1s = 1;
export const globalPromptTime = 3;
export const globalPromptTime10s = 10;
export const numberToLetter = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];

export const buCuontryList = {
  REGIONAL: 'REGIONAL',
  PACS: 'PACS',
  PAMB: 'PAMB',
  PHKL: 'PHKL',
  PLUK: 'PLUK', // add by zhy
  PCLA: 'PCLA', // add by zhy
}

// update by zhy
export const buServiceList = [
  'admin', 'pacs', 'pamb', "phkl", 'pluk', 'pcla'
];

export const loginUrlAdmin = `/${globalUrlPre}/admin/login`;
export const loginUrlPamb = `/${globalUrlPre}/pamb/login`;
export const loginUrlPacs = `/${globalUrlPre}/pacs/login`;
export const loginUrlPhkl = `/${globalUrlPre}/phkl/login`;
export const loginUrlPluk = `/${globalUrlPre}/pluk/login`; // add by zhy
export const loginUrlPcla = `/${globalUrlPre}/pcla/login`; // add by zhy

export const pwdFormat = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.!@#$%^&*-_+=])(?=\S+$).{8,15}$/;

export const pwdTips = 'Password must contain: 1 upper case, 1 lower case, 1 number, 1 special character, 8-15 characters';

export const twoDecimalReg = /(^\d+(\.[0-9]{0,2})?$)/;

export const getIsPSELogin = () => {
  return sessionStorage.getItem('loginChannel') === 'PSESSO'
};

export const getIsAADLogin = () => {
  return sessionStorage.getItem('loginChannel') === 'AAD'
};

export const getIsImpersonate = () => {
  return sessionStorage.getItem(impersonateItemName);
};

export const getPrefix = () => {
  return sessionStorage.getItem(urlPrefixItemName);
};

export const impersonatorUser = (prefix, user, refreshToken, buCountry, roleId) => {
  // Remember previouse user
  sessionStorage.setItem(preUrlPrefixItemName, sessionStorage.getItem(urlPrefixItemName));
  sessionStorage.setItem(preUserItemName, sessionStorage.getItem(userItemName));
  sessionStorage.setItem(preRefreshTokenItemName, sessionStorage.getItem(refreshTokenItemName));
  sessionStorage.setItem(preBuCountryItemName, sessionStorage.getItem(buCountryItemName));
  sessionStorage.setItem(preRoleIdItemName, sessionStorage.getItem(roleIdItemName));
  sessionStorage.setItem(impersonateItemName, true);

  persistUserToken(prefix, user, refreshToken, buCountry, roleId);
};

export const backToImpersonator = () => {
  sessionStorage.setItem(urlPrefixItemName, sessionStorage.getItem(preUrlPrefixItemName));
  sessionStorage.setItem(userItemName, sessionStorage.getItem(preUserItemName));
  sessionStorage.setItem(refreshTokenItemName, sessionStorage.getItem(preRefreshTokenItemName));
  sessionStorage.setItem(buCountryItemName, sessionStorage.getItem(preBuCountryItemName));
  sessionStorage.setItem(roleIdItemName, sessionStorage.getItem(preRoleIdItemName));

  sessionStorage.removeItem(preUrlPrefixItemName);
  sessionStorage.removeItem(preUserItemName);
  sessionStorage.removeItem(preRefreshTokenItemName);
  sessionStorage.removeItem(preBuCountryItemName);
  sessionStorage.removeItem(preRoleIdItemName);
  sessionStorage.removeItem(impersonateItemName);
};

export const persistUserToken = (prefix, user, refreshToken, buCountry, roleId) => {
  sessionStorage.setItem(urlPrefixItemName, prefix);
  sessionStorage.setItem(userItemName, user);
  sessionStorage.setItem(refreshTokenItemName, refreshToken);
  sessionStorage.setItem(buCountryItemName, buCountry);
  sessionStorage.setItem(roleIdItemName, roleId);
}

export const urlPrefixItemName = "prefix";
export const userItemName = "user";
export const refreshTokenItemName = "refreshToken";
export const buCountryItemName = "buCountry";
export const roleIdItemName = "roleid";
export const impersonateItemName = "impersonate";

// For remember Impersonate user's attributes
export const preUrlPrefixItemName = "pre_prefix";
export const preUserItemName = "pre_user";
export const preRefreshTokenItemName = "pre_refreshToken";
export const preBuCountryItemName = "pre_buCountry";
export const preRoleIdItemName = "pre_roleid";


export const isPacsAdmin = (roleId) => {
  const pacsAdminRoleIds = ['6', '7'];
  return pacsAdminRoleIds.includes(roleId);
};

// Agent(8), AgentLeader(12), AgentLeaderTrainer(18) all are Agent
export const isPacsAgent = (roleId) => {
  const pacsAgentRoleIds = ['8', '12', '18'];
  return pacsAgentRoleIds.includes(roleId);
}

// AgentLeader(12), AgentLeaderTrainer(18) all are AgentLeader
export const isPacsAgentLeader = (roleId) => {
  const role = ['12', '18'];
  return role.includes(roleId);
}

export const isPacsPureAgent = (roleId) => {
  const role = ['8'];
  return role.includes(roleId);
}

export const isPacsPureAgentLeader = (roleId) => {
  const role = ['12'];
  return role.includes(roleId);
}

export const isPambPruventureTrainer = (roleId) => {
  const role = ['10', '151', '15', '16'];
  return role.includes(roleId);
}

export const isPambPruventureAdmin = (roleId) => {
  const role = ['1', '2', '21', '22'];
  return role.includes(roleId);
}

export const isPambPruventureALTrainer = (roleId) => {
  const role = ['14', '15', '141', '151'];
  return role.includes(roleId);
}

export const switchDate = () => {
  return sessionStorage.getItem('dateSwitch') === 'true';
}
