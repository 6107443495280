import React from "react";
import Pdf from '../../documents/Staff_Notice.pdf'
import styles from './TCpage.module.less'

class TCpage extends React.Component {
  render() {
    return (
      <div className={styles.box}>
        <span> We, Prudential Corporation Asia, take privacy and protection of your personal data seriously. Please carefully read</span>
        <a className={styles.title} href={Pdf} target="_blank" rel="noopener noreferrer">
          Prudential's Privacy Notice
        </a>
        <span>before you continue in logging on. By continue logging on, you agree to our Privacy Notice</span>
      </div>
    )
  }
}

export default TCpage;
