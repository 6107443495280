import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Upload, Table, Empty, Icon, Spin } from "antd";
import {
  handleUploadAndCheck,
  handleImport,
  handleDoloadTemplate
} from "../../../../../redux/pacsReducer/systemSettingsReducers/ImportCandidatesReducer";
import styles from "./ImportCandidates.module.less";
import { file as uploadfileIcon } from "../../../../../images";

@connect(
  ({ pacsReducer: { systemSettingsReducers: { ImportCandidatesReducer } } }) => ({
    ImportCandidatesReducer
  }),
  { handleUploadAndCheck, handleImport, handleDoloadTemplate }
)
class ImportCandidates extends Component {
  state = {
    fileList: [],
    isShowTable: false,
    columns: [
      {
        title: "User",
        dataIndex: "userName",
        align: "center",
        ellipsis: true
      },
      {
        title: "Description",
        align: "center",
        dataIndex: "roleCode",
        render: (text, record) => {
          const { descriptionList = [] } = record;
          return descriptionList.map(elt => {
            const { color, description } = elt;
            const showcolor = handleColor(color);
            return (
              <div style={{ color: showcolor }} key={description}>
                {description}
              </div>
            );
          });
        }
      }
    ]
  };

  uploadAndCheck = () => {
    const { fileList } = this.state;
    const FormDatas = new FormData();
    FormDatas.append("file", fileList[0]);
    const callback = () => this.setState({ isShowTable: true });
    this.props.handleUploadAndCheck(FormDatas, callback);
  };

  handleImportData = () => {
    const {
      uploadBackData: { fileId }
    } = this.props.ImportCandidatesReducer;
    const callback = () => {
      this.setState({ isShowTable: false, fileList: [] });
    };
    this.props.handleImport({ fileId }, callback);
  };

  render() {
    const { fileList, columns, isShowTable } = this.state;
    const {
      ImportCandidatesReducer: { checkLoading, uploadBackData, importLoading }
    } = this.props;
    const { candidateList = [], containError } = uploadBackData;
    const props = {
      beforeUpload: file => {
        this.setState(() => ({
          fileList: [file],
          isShowTable: false
        }));
        return false;
      },
      fileList,
      showUploadList: false
    };

    return (
      <div className={styles.container}>
        <Spin spinning={importLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/system_settings/user_management/index"
                )
              }
            />
            Import Candidates
          </div>
          <div className={styles.contentBox}>
            <div className={styles.uploadBox}>
              <div className={styles.uploadtitle}>Select File</div>
              <div className={styles.showpath}>
                {fileList[0] && fileList[0].name}
              </div>
              <Upload {...props}>
                <div className={styles.uploadIconBox}>
                  <img
                    className={styles.uploadIcon}
                    src={uploadfileIcon}
                    alt=""
                  />
                </div>
              </Upload>
              <div className={styles.downloadBox}>
                <Button
                  className={styles.downloadBtn}
                  icon="download"
                  onClick={this.props.handleDoloadTemplate}
                >
                  Download Template
                </Button>
              </div>
            </div>
            <div className={styles.uploadAndCheckBtnBox}>
              <Button
                onClick={this.uploadAndCheck}
                disabled={!fileList.length}
                loading={checkLoading}
                className={
                  fileList.length ? styles.uploadBtnActive : styles.uploadBtn
                }
              >
                {checkLoading ? "Uploading" : "Upload & Check"}
              </Button>
            </div>
            {isShowTable ? (
              <div className={styles.tabelbox}>
                <Table
                  locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
                  columns={columns}
                  dataSource={candidateList || []}
                  size="small"
                  loading={checkLoading}
                  className={styles.ant_dev_table}
                  rowKey="agentId"
                  pagination={false}
                  scroll={candidateList.length > 6 ? { y: 240 } : {}}
                />
                <div className={styles.importBox}>
                  <Button
                    disabled={containError}
                    className={
                      containError ? styles.importBtn : styles.importBtnActive
                    }
                    onClick={this.handleImportData}
                  >
                    Import
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        </Spin>
      </div>
    );
  }
}

export default ImportCandidates;

function handleColor(color) {
  switch (color) {
    case "0":
      return "#D0021B";
    case "1":
      return "#E79000";
    case "2":
      return "#417505 ";
    default:
      return "#666";
  }
}
