import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Form, Row, Col, Input, Button, Checkbox, Spin, Select } from "antd";
import { Link } from "react-router-dom";
import {
  getRequirementData,
  updateRequirement,
  resetData
} from "../../../../redux/pambReducer/CPDSettingsReducers/requirementSettingReducer";
import styles from "./RequirementSettingPamb.module.less";

const CheckboxGroup = Checkbox.Group;
const { Option } = Select;

@connect(
  ({ pambReducer: { CPDSettingsReducers: { requirementSettingReducer } } }) => ({
    requirementSettingReducer
  }),
  { getRequirementData, updateRequirement, resetData }
)
class RequirementSettingFrom extends Component {
  state = {
    isEditable: false,
    dateId: ""
  };

  componentDidMount() {
    const {
      requirementSettingReducer: { dateList }
    } = this.props;
    const id = dateList[0] && dateList[0].pambEffectiveDateId;
    const endDate = dateList[0] && dateList[0].toDate;
    let dateTime = new Date(endDate);
    dateTime = dateTime.setDate(dateTime.getDate() + 1);
    dateTime = moment(dateTime).format("YYYY-MM-DD");
    this.props.getRequirementData(id);
    this.setState({ endDate: dateTime, dateId: id });
  }

  handleSubmit = e => {
    e.preventDefault();
    const {
      form,
      requirementSettingReducer: { requirementData }
    } = this.props;

    const { liamRequirementDto = {}, mtaRequirementDto = {} } = requirementData;

    const { pambLiamReqId } = liamRequirementDto || {};
    const { pambMtaReqId } = mtaRequirementDto || {};
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { dateId } = this.state;
        const {
          year1m6Total,
          year1m6StructuredMin,
          year1m6StructuredMax,
          year1m6UnstructuredMin,
          year1m6UnstructuredMax,
          year1m12Total,
          year1m12StructuredMin,
          year1m12StructuredMax,
          year1m12UnstructuredMin,
          year1m12UnstructuredMax,
          year2m24Total,
          year2m24StructuredMin,
          year2m24StructuredMax,
          year2m24UstructuredMin,
          year2m24UstructuredMax,
          cpdhoursTotal,
          cpdhoursStructuredMin,
          cpdhoursStructuredMax,
          cpdhoursUnstructuredMin,
          cpdhoursUnstructuredMax,
          onwardsY2StructuredMin,
          onwardsY2StructuredMax,
          onwardsY2UnstructuredMin,
          onwardsY2UnstructuredMax,
          onwardsY2Total,

          year1m6TotalMTA,
          year1m6TecMin,
          year1m6UntecMin,
          year1m6TecMax,
          year1m6UntecMax,
          year1m6UnstrMin,
          year1m6UnstrMax,
          nontecAndUnstrTotalMax,
          nontecAndUnstrTotalMax2,
          nontecAndUnstrTotalMax3,
          year2Total,
          year2TechMin,
          year2TechMax,
          year2NonTechMin,
          year2NonTechMax,
          year2UnstracturedMin,
          year2UnstracturedMax,
          year2SpecialHours,
          year3Total,
          year3TechMin,
          year3TechMax,
          year3NonTechMin,
          year3NonTechMax,
          year3UnstructuredMin,
          year3UnstructuredMax,
          year2After2024SpecialTotal,
          year2After2024StructuredMax,
          year2After2024StructuredMin,
          year2After2024Total,
          year2After2024UnstructuredMax,
          year2After2024UnstructuredMin,
        } = values;

        const liamRequirement = {
          pambLiamReqId,
          effectiveDateId: dateId,
          year1m6Total,
          year1m12Total,
          year2m24Total,
          year1m6StructuredMin,
          year1m6StructuredMax,
          year1m6UnstructuredMin,
          year1m6UnstructuredMax,
          year1m12StructuredMin,
          year1m12StructuredMax,
          year1m12UnstructuredMin,
          year1m12UnstructuredMax,
          year2m24StructuredMin,
          year2m24StructuredMax,
          year2m24UstructuredMin,
          year2m24UstructuredMax,
          cpdhoursTotal,
          cpdhoursStructuredMin,
          cpdhoursStructuredMax,
          cpdhoursUnstructuredMin,
          cpdhoursUnstructuredMax,

          onwardsY2StructuredMin,
          onwardsY2StructuredMax,
          onwardsY2UnstructuredMin,
          onwardsY2UnstructuredMax,
          onwardsY2Total,
          year2After2024SpecialTotal,
          year2After2024StructuredMax,
          year2After2024StructuredMin,
          year2After2024Total,
          year2After2024UnstructuredMax,
          year2After2024UnstructuredMin,
        };

        const mtaRequirement = {
          pambMtaReqId,
          effectiveDateId: dateId,
          year1m6Total: year1m6TotalMTA,
          year1m6TecMin,
          year1m6UntecMin,
          year1m6TecMax,
          year1m6UntecMax,
          year1m6UnstrMin,
          year1m6UnstrMax,
          nontecAndUnstrTotalMax,
          nontecAndUnstrTotalMax2,
          nontecAndUnstrTotalMax3,
          year2Total,
          year2TechMin,
          year2TechMax,
          year2NonTechMin,
          year2NonTechMax,
          year2UnstracturedMin,
          year2UnstracturedMax,
          year2SpecialHours,
          year3Total,
          year3TechMin,
          year3TechMax,
          year3NonTechMin,
          year3NonTechMax,
          year3UnstructuredMin,
          year3UnstructuredMax
        };
        const callback = () => {
          this.setState({ isEditable: false });
        };
        this.props.updateRequirement(
          {
            liamRequirementDto: liamRequirement,
            mtaRequirementDto: mtaRequirement
          },
          callback
        );
      }
    });
  };

  handleFormat = () => { };

  // handleFormat = (value, id) => {
  //   const val = value && value.replace(/[^\d]/g, "");
  //   const num = val && parseFloat(val).toString();
  //   this.props.form.setFieldsValue({
  //     [id]: num
  //   });
  // };

  handleEdit = () => {
    const { isEditable, dateId } = this.state;
    if (isEditable) {
      const callback = () => this.props.form.resetFields();
      this.props.getRequirementData(dateId, callback);
    }
    this.setState(() => ({ isEditable: !isEditable }));
  };

  handleChange = id => {
    this.props.getRequirementData(id, null, this.props.form);
    this.setState({ dateId: id });
  };

  render() {
    const {
      form: { getFieldDecorator },
      requirementSettingReducer: { requirementData, updateLoading, dateList }
    } = this.props;

    const { isEditable, endDate } = this.state;

    const { liamRequirementDto, mtaRequirementDto } = requirementData;

    const {
      year1m6Total,
      year1m6StructuredMin,
      year1m6StructuredMax,
      year1m6UnstructuredMin,
      year1m6UnstructuredMax,
      year1m12Total,
      year1m12StructuredMin,
      year1m12StructuredMax,
      year1m12UnstructuredMin,
      year1m12UnstructuredMax,
      year2m24Total,
      year2m24StructuredMin,
      year2m24StructuredMax,
      year2m24UstructuredMin,
      year2m24UstructuredMax,
      cpdhoursTotal,
      cpdhoursStructuredMin,
      cpdhoursStructuredMax,
      cpdhoursUnstructuredMin,
      cpdhoursUnstructuredMax,

      onwardsY2StructuredMin = 'NA',
      onwardsY2StructuredMax = 'NA',
      onwardsY2UnstructuredMin = 'NA',
      onwardsY2UnstructuredMax = 'NA',
      onwardsY2Total,

      year2After2024SpecialTotal,
      year2After2024StructuredMax,
      year2After2024StructuredMin,
      year2After2024Total,
      year2After2024UnstructuredMax,
      year2After2024UnstructuredMin,
    } = liamRequirementDto || {};
    const {
      year1m6Total: year1m6TotalMTA,
      year1m6TecMin,
      year1m6UntecMin,
      year1m6TecMax,
      year1m6UntecMax,
      year1m6UnstrMin,
      year1m6UnstrMax,
      nontecAndUnstrTotalMax,
      nontecAndUnstrTotalMax2,
      nontecAndUnstrTotalMax3,
      year2Total,
      year2TechMin,
      year2TechMax,
      year2NonTechMin,
      year2NonTechMax,
      year2UnstracturedMin,
      year2UnstracturedMax,
      year2SpecialHours,
      year3Total,
      year3TechMin,
      year3TechMax,
      year3NonTechMin,
      year3NonTechMax,
      year3UnstructuredMin,
      year3UnstructuredMax
    } = mtaRequirementDto || {};

    const inputProps = {
      className: styles.ant_input,
      // maxLength: 4,
      disabled: !isEditable
    };
    const props = {
      getFieldDecorator,
      inputProps,
      handleFormat: this.handleFormat
    };
    const defaultVal = dateList[0] && dateList[0].pambEffectiveDateId;
    return (
      <div className={styles.container}>
        <Spin spinning={updateLoading}>
          <div className={styles.heading}>
            <Select
              style={{ width: 225 }}
              onChange={this.handleChange}
              defaultValue={defaultVal}
            >
              {dateList.map(elt => {
                const { fromDate, toDate, pambEffectiveDateId } = elt;
                const name = `${fromDate} - ${toDate}`;
                return (
                  <Option
                    key={pambEffectiveDateId}
                    value={pambEffectiveDateId}
                    endDate={toDate}
                  >
                    {name}
                  </Option>
                );
              })}
            </Select>
            <div className={styles.gobtn}>
              <Link
                to={{
                  pathname:
                    "/home/system_settings/cpd_settings/requirement_setting_create",
                  state: {
                    endDate
                  }
                }}
              >
                <Button icon="plus" className={styles.addBtn}>
                  Add New Requirement
                </Button>
              </Link>
            </div>
          </div>
          <Form className={styles.ant_form} labelAlign="left" colon={false}>
            <WrapGroup heading="LIAM Requirement">
              <LIAMWrap
                {...props}
                title="LIAM 1st 6 mth Agent Training Requirements"
                totalCode="year1m6Total"
                StructuredMinCode="year1m6StructuredMin"
                StructuredMaxCode="year1m6StructuredMax"
                UnstructuredMinCode="year1m6UnstructuredMin"
                UnstructuredMaxCode="year1m6UnstructuredMax"
                totalVal={year1m6Total}
                StructuredMinVal={year1m6StructuredMin}
                StructuredMaxVal={year1m6StructuredMax}
                UnstructuredMinVal={year1m6UnstructuredMin}
                UnstructuredMaxVal={year1m6UnstructuredMax}
              />
              <LIAMWrap
                {...props}
                title={
                  <>
                    <p>LIAM 2nd 6 mth Agent Training Requirement</p>
                    <p> (Applicable to agents appointed before 2024)</p>
                  </>
                }
                totalCode="year1m12Total"
                StructuredMinCode="year1m12StructuredMin"
                StructuredMaxCode="year1m12StructuredMax"
                UnstructuredMinCode="year1m12UnstructuredMin"
                UnstructuredMaxCode="year1m12UnstructuredMax"
                totalVal={year1m12Total}
                StructuredMinVal={year1m12StructuredMin}
                StructuredMaxVal={year1m12StructuredMax}
                UnstructuredMinVal={year1m12UnstructuredMin}
                UnstructuredMaxVal={year1m12UnstructuredMax}
              />
              <LIAMWrap
                {...props}
                title={
                  <>
                    <p>LIAM 2nd Year Agent Training Requirement</p>
                    <p>(Applicable to agents appointed before 2024)</p>
                  </>
                }
                totalCode="year2m24Total"
                StructuredMinCode="year2m24StructuredMin"
                StructuredMaxCode="year2m24StructuredMax"
                UnstructuredMinCode="year2m24UstructuredMin"
                UnstructuredMaxCode="year2m24UstructuredMax"
                totalVal={year2m24Total}
                StructuredMinVal={year2m24StructuredMin}
                StructuredMaxVal={year2m24StructuredMax}
                UnstructuredMinVal={year2m24UstructuredMin}
                UnstructuredMaxVal={year2m24UstructuredMax}
              />
              <LIAMWrap
                {...props}
                title={<p><label className={styles.label}>Normal Agent:</label>LIAM Y2 CPD Hours<p>(Applicable to agents appointed after 2024)</p></p>}
                isShowSpecialHours
                specialHoursCode="year2After2024SpecialTotal"
                totalCode="year2After2024Total"
                StructuredMinCode="year2After2024StructuredMin"
                StructuredMaxCode="year2After2024StructuredMax"
                UnstructuredMinCode="year2After2024UnstructuredMin"
                UnstructuredMaxCode="year2After2024UnstructuredMax"
                specialHoursVal={year2After2024SpecialTotal}
                totalVal={year2After2024Total}
                StructuredMinVal={year2After2024StructuredMin}
                StructuredMaxVal={year2After2024StructuredMax}
                UnstructuredMinVal={year2After2024UnstructuredMin}
                UnstructuredMaxVal={year2After2024UnstructuredMax}
              />

              <LIAMWrap
                {...props}
                title={<p><label className={styles.label}>Normal Agent:</label> LIAM Y3 Onwards CPD Hours</p>}
                totalCode="cpdhoursTotal"
                StructuredMinCode="cpdhoursStructuredMin"
                StructuredMaxCode="cpdhoursStructuredMax"
                UnstructuredMinCode="cpdhoursUnstructuredMin"
                UnstructuredMaxCode="cpdhoursUnstructuredMax"
                totalVal={cpdhoursTotal}
                StructuredMinVal={cpdhoursStructuredMin}
                StructuredMaxVal={cpdhoursStructuredMax}
                UnstructuredMinVal={cpdhoursUnstructuredMin}
                UnstructuredMaxVal={cpdhoursUnstructuredMax}
              />

              <LIAMWrap
                {...props}
                title={<p><label className={styles.label}>Reappointed Agent:</label>LIAM Y2 Onwards CPD Hours</p>}
                totalCode="onwardsY2Total"
                StructuredMinCode="onwardsY2StructuredMin"
                StructuredMaxCode="onwardsY2StructuredMax"
                UnstructuredMinCode="onwardsY2UnstructuredMin"
                UnstructuredMaxCode="onwardsY2UnstructuredMax"
                totalVal={onwardsY2Total}
                StructuredMinVal={onwardsY2StructuredMin}
                StructuredMaxVal={onwardsY2StructuredMax}
                UnstructuredMinVal={onwardsY2UnstructuredMin}
                UnstructuredMaxVal={onwardsY2UnstructuredMax}
              />

              {/* <Form.Item label="RFP Requirement M1&M2 (either 1 to be finished by Year4, both to be finished by Year6)">
                {getFieldDecorator("rfpmOptions", {
                  initialValue: ["rfpm1", "rfpm2"]
                })(
                  <CheckboxGroup
                    options={[
                      { label: "M1", value: "rfpm1" },
                      { label: "M2", value: "rfpm2" }
                    ]}
                    disabled
                  />
                )}
              </Form.Item> */}
            </WrapGroup>

            <WrapGroup heading="MTA Requirement">
              <MTAWrap
                {...props}
                title="MTA 1st Year Agent Training Requirements"
                totalHoursCode="year1m6TotalMTA"
                technicaMinCode="year1m6TecMin"
                technicaMaxCode="year1m6TecMax"
                nonTecMinCode="year1m6UntecMin"
                nonTecMaxCode="year1m6UntecMax"
                UnstructuredMinCode="year1m6UnstrMin"
                UnstructuredMaxCode="year1m6UnstrMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax"
                totalHoursVal={year1m6TotalMTA}
                technicaMinVal={year1m6TecMin}
                technicaMaxVal={year1m6TecMax}
                nonTecMinVal={year1m6UntecMin}
                nonTecMaxVal={year1m6UntecMax}
                UnstructuredMinVal={year1m6UnstrMin}
                UnstructuredMaxVal={year1m6UnstrMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax}
              />
              <MTAWrap
                {...props}
                title="MTA 2nd Year Agent Training Requirements"
                isShowSpecialHours
                totalHoursCode="year2Total"
                technicaMinCode="year2TechMin"
                technicaMaxCode="year2TechMax"
                nonTecMinCode="year2NonTechMin"
                nonTecMaxCode="year2NonTechMax"
                UnstructuredMinCode="year2UnstracturedMin"
                UnstructuredMaxCode="year2UnstracturedMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax2"
                specialHoursCode="year2SpecialHours"
                totalHoursVal={year2Total}
                technicaMinVal={year2TechMin}
                technicaMaxVal={year2TechMax}
                nonTecMinVal={year2NonTechMin}
                nonTecMaxVal={year2NonTechMax}
                UnstructuredMinVal={year2UnstracturedMin}
                UnstructuredMaxVal={year2UnstracturedMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax2}
                specialHoursVal={year2SpecialHours}
              />
              <MTAWrap
                {...props}
                title="Year3 onwards CPD hours"
                totalHoursCode="year3Total"
                technicaMinCode="year3TechMin"
                technicaMaxCode="year3TechMax"
                nonTecMinCode="year3NonTechMin"
                nonTecMaxCode="year3NonTechMax"
                UnstructuredMinCode="year3UnstructuredMin"
                UnstructuredMaxCode="year3UnstructuredMax"
                nontecAndUnstrTotalMaxCode="nontecAndUnstrTotalMax3"
                totalHoursVal={year3Total}
                technicaMinVal={year3TechMin}
                technicaMaxVal={year3TechMax}
                nonTecMinVal={year3NonTechMin}
                nonTecMaxVal={year3NonTechMax}
                UnstructuredMinVal={year3UnstructuredMin}
                UnstructuredMaxVal={year3UnstructuredMax}
                nontecAndUnstrTotalMaxVal={nontecAndUnstrTotalMax3}
              />
            </WrapGroup>

            <Row>
              <Col className={styles.submitBox}>
                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleSubmit}
                    className={
                      isEditable ? styles.editbalBtn : styles.submitBtn
                    }
                    disabled={!isEditable}
                  >
                    Save
                  </Button>
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    onClick={this.handleEdit}
                    className={styles.cancelBtn}
                  >
                    {isEditable ? "Cancel" : "Edit"}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

function WrapGroup(props) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <span>{props.heading}</span>
      </div>
      {props.children}
    </div>
  );
}

function LIAMWrap(props) {
  const {
    getFieldDecorator,
    inputProps,
    title,
    isShowSpecialHours,
    handleFormat,
    totalCode,
    StructuredMinCode,
    StructuredMaxCode,
    UnstructuredMinCode,
    UnstructuredMaxCode,
    specialHoursCode,
    totalVal,
    StructuredMinVal,
    StructuredMaxVal,
    UnstructuredMinVal,
    UnstructuredMaxVal,
    specialHoursVal
  } = props;
  return (
    <div className={styles.LIAMWrapBox}>
      <div className={styles.titleBox}>
        <Form.Item>
          <div className={styles.headings}>{title}</div>
        </Form.Item>
        <Form.Item label="Total Hours" className={styles.totals}>
          {getFieldDecorator(totalCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, totalCode)
              }
            ],
            initialValue: totalVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        {isShowSpecialHours ? (
          <Form.Item
            label="Special Hours"
            className={styles.totals}
            style={{ marginLeft: 25 }}
          >
            {getFieldDecorator(specialHoursCode, {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  transform: v => handleFormat(v, "specialHoursCode")
                }
              ],
              initialValue: specialHoursVal
            })(<Input {...inputProps} className={styles.inputs} />)}
          </Form.Item>
        ) : null}
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.titles}>Structured</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(StructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, StructuredMinCode)
              }
            ],
            initialValue: StructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(StructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, StructuredMaxCode)
              }
            ],
            initialValue: StructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.titles}>Unstructured</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(UnstructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMinCode)
              }
            ],
            initialValue: UnstructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(UnstructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMaxCode)
              }
            ],
            initialValue: UnstructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
    </div>
  );
}

function MTAWrap(props) {
  const {
    getFieldDecorator,
    inputProps,
    title,
    isShowSpecialHours,
    handleFormat,

    totalHoursCode,
    technicaMinCode,
    technicaMaxCode,
    nonTecMinCode,
    nonTecMaxCode,
    UnstructuredMinCode,
    UnstructuredMaxCode,
    nontecAndUnstrTotalMaxCode,
    specialHoursCode,

    totalHoursVal,
    technicaMinVal,
    technicaMaxVal,
    nonTecMinVal,
    nonTecMaxVal,
    UnstructuredMinVal,
    UnstructuredMaxVal,
    nontecAndUnstrTotalMaxVal,
    specialHoursVal
  } = props;
  return (
    <div className={styles.LIAMWrapBox}>
      <div className={styles.titleBox}>
        <Form.Item>
          <div className={styles.headings}>{title}</div>
        </Form.Item>
        <Form.Item label="Total Hours" className={styles.totals}>
          {getFieldDecorator(totalHoursCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, totalHoursCode)
              }
            ],
            initialValue: totalHoursVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        {isShowSpecialHours ? (
          <Form.Item
            label="Special Hours"
            className={styles.totals}
            style={{ marginLeft: 25 }}
          >
            {getFieldDecorator(specialHoursCode, {
              rules: [
                {
                  required: true,
                  message: "Please fill in the mandatory fields"
                },
                {
                  transform: v => handleFormat(v, "specialHoursCode")
                }
              ],
              initialValue: specialHoursVal
            })(<Input {...inputProps} className={styles.inputs} />)}
          </Form.Item>
        ) : null}
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Technical</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(technicaMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, technicaMinCode)
              }
            ],
            initialValue: technicaMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(technicaMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, technicaMaxCode)
              }
            ],
            initialValue: technicaMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>

      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Non-Technical</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(nonTecMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nonTecMinCode)
              }
            ],
            initialValue: nonTecMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(nonTecMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nonTecMaxCode)
              }
            ],
            initialValue: nonTecMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Unstructered</div>
        </Form.Item>
        <Form.Item label="Min" className={styles.minform}>
          {getFieldDecorator(UnstructuredMinCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMinCode)
              }
            ],
            initialValue: UnstructuredMinVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
        <Form.Item label="Max">
          {getFieldDecorator(UnstructuredMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, UnstructuredMaxCode)
              }
            ],
            initialValue: UnstructuredMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
      <div className={styles.structuredBox}>
        <Form.Item>
          <div className={styles.mtatitles}>Non-Technical + Unstructured</div>
        </Form.Item>
        <Form.Item label="Max" className={styles.minform}>
          {getFieldDecorator(nontecAndUnstrTotalMaxCode, {
            rules: [
              {
                required: true,
                message: "Please fill in the mandatory fields"
              },
              {
                transform: v => handleFormat(v, nontecAndUnstrTotalMaxCode)
              }
            ],
            initialValue: nontecAndUnstrTotalMaxVal
          })(<Input {...inputProps} className={styles.inputs} />)}
        </Form.Item>
      </div>
    </div>
  );
}

const RequirementSettingPamb = Form.create()(RequirementSettingFrom);

export default RequirementSettingPamb;
