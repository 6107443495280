
import { combineReducers } from "redux";
import listReducer from './listReducer'
import individualReducer from './individualReducer'
import importReducer from './importAddReducer'

export default combineReducers({
  listReducer,
  individualReducer,
  importReducer,
});
