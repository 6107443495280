import { globalUrlPre, originalUrlPrefix } from "./config";
import { getIsPSELogin, getIsImpersonate, getPrefix, backToImpersonator } from './global';

export function handleSplitUrl(props) {
  const { location } = props;
  const pathSnippets = location.pathname.split("/").filter(i => i);
  return pathSnippets;
}

export function urlIntoArray(url) {
  const array = []
  if (url) {
    const urlList = url.split("/").filter(i => i);
    const arr = [].concat(urlList)

    arr.reduce((pre, item) => {
      const elt = `/${item}`
      array.push(pre + elt)
      return pre + elt
    }, '')
  }

  return array;
}

export function downloadFile(blob, fileName) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
}

export function handleStatus(type) {
  switch (type) {
    case "0":
      return "NoLogin";
    case "1":
      return "Inactive";
    case "2":
      return "Active";
    default:
      return "";
  }
}

export function handleGender(type) {
  switch (type) {
    case "M":
      return "Male";
    case "F":
      return "Female";
    default:
      return "";
  }
}

export function getUrlParam(string) {
  const obj = {};
  if (string.indexOf("?") !== -1) {
    const str = string.substr(string.indexOf("?") + 1);
    const strs = str.split("&");
    for (let i = 0; i < strs.length; i += 1) {
      const tempArr = strs[i].split("=");
      obj[tempArr[0]] = decodeURI(tempArr[1]);
    }
  }
  return obj;
}

export function randnum(index) {
  const Dates = new Date()
  const number = typeof index === 'number' ? index : 1
  const seed = Dates.getTime() + number;
  const num = (seed * 9301 + 49297) % 233280;
  return num / (233280.0);
}

export const randArray = (function () {
  let seed = (new Date()).getTime()
  function r() {
    seed = (seed * 9301 + 49297) % 233280
    return seed / (233280.0)
  }
  return function (number) {
    return r() + number
  }
}())

export function safeUrl(url, whiteChars) {
  const str = `${whiteChars || '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz.-_~+#,%&=*;:@[]/'}`;
  let ret = '';

  for (let i = 0; i < url.length; i += 1) {
    ret += str[str.indexOf(url[i])] || '';
  }
  let old = ''
  do {
    old = ret;
    ret = ret.replace(/javascript:/gi, '');
  } while (ret !== old);

  return ret;
}

export const onlyClearLocalData = () => {
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('buCountry')
  sessionStorage.removeItem('refreshToken')
  sessionStorage.removeItem('onlyPopFirstErr');
}

export const clearStorage = () => {
  // If it's impersonate, then token is not the impersonator's token
  // So before we redirect, we set session storage item to items of impersonator
  const isImpersonate = getIsImpersonate();
  if (isImpersonate) {
    backToImpersonator();
  }
  onlyClearLocalData();
  const prefix = getPrefix();
  const isPSELogin = getIsPSELogin();
  const PSERedirectUrl = decodeURIComponent(sessionStorage.getItem('PSERedirectUrl'));

  const loginUrl = isPSELogin ? PSERedirectUrl : `/${globalUrlPre}/${prefix}/login`;
  const url = safeUrl(loginUrl);
  document.location = url;
}


export const forbidden = () => {
  document.location = safeUrl("/403")
}

export const serverError = () => {
  document.location = safeUrl("/500")
}

export const redirectBaseUrl = (config) => {
  const { params } = config || {};
  const buRole = params.buRole || "";
  if (buRole) {
    const role = buRole.toLowerCase();
    config.baseURL = originalUrlPrefix[role];
    delete config.params.buRole
  } else {
    const prefix = getPrefix()
    config.baseURL = originalUrlPrefix[prefix];
  }
}

export const readBlobToJson = (data, notification, globalPromptTime10s) => {
  const fileReader = new FileReader();
  fileReader.onload = function () {
    try {
      const jsonData = JSON.parse(this.result);
      if (jsonData.code) {
        notification.error({
          message: jsonData.message,
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
      }
    } catch (err) { }
  };
  fileReader.readAsText(data);
}

export const replaceSpaces = (str) => {
  if (typeof str === 'string') {
    const reg = /\s/g
    str = str.replace(reg, ' ')
  }

  return str
}
