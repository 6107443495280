
import React, { Component } from "react";
import { Button, Table } from "antd";
import styles from './index.module.less';

class ErrorList extends Component {
  render() {
    const { containError, total, incorrect, correct, handleImportData } = this.props;
    return (
      <div className={styles.tabelbox}>
        <div className={styles.summarize}>Total <label className={styles.total}>{total}</label> records, <label className={styles.correct}>{correct}</label> correct, <label className={styles.incorrect}>{incorrect}</label> incorrect</div>
        {incorrect > 0 ? <Table
          rowKey='line'
          size="small"
          className={styles.ant_dev_table}
          pagination={false}
          {...this.props}
        /> : null }
        <div className={styles.importBox}>
          <Button
            disabled={containError}
            className={
              containError ? styles.importBtn : styles.importBtnActive
            }
            onClick={handleImportData}
          >
            Import
          </Button>
        </div>
      </div>
    );
  }
}

export default ErrorList;
