import { message } from "antd";
import { post, get } from "../../../utils/request";
import { globalPromptTime } from "../../../utils/global";
import { randnum, randArray } from "../../../utils";

const SAVE = "pamb/SurveyReducer/SAVE";

const initState = {
  surveyData: {},
  queryLoading: false,
  submitLoading: false,
  mandatoryList: [],
  questionGroups: [],
  fieldsData: {},
  noReFresh: false
};

// reducer
export default function SurveyReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function createSurvey(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    post("/survey/surveyTemplate/add", payload)
      .then(() => {
        const cbcombine = () => {
          dispatch({
            type: SAVE,
            payload: {
              submitLoading: false
            }
          });
          cb();
        };
        message.info("Saved successfully", globalPromptTime, cbcombine);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}

export function updateSurvey(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    post("/survey/surveyTemplate/update", payload)
      .then(() => {
        const cbcombine = () => {
          dispatch({
            type: SAVE,
            payload: {
              submitLoading: false
            }
          });
          cb();
        };
        message.info("Saved successfully", globalPromptTime, cbcombine);
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}

export function getSurveyDetail(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        submitLoading: true
      }
    });
    get("/survey/surveyTemplate/detail", payload)
      .then(res => {
        const { data } = res;
        const {
          surveyName,
          surveyCode,
          mandatoryFlag,
          openTime,
          closeTime,
          surveyDescription,
          groupList = []
        } = data || {};
        if (groupList && groupList.length) {
          groupList.map((elt, index) => {
            elt.groupIndex = randArray(index)
          })
        }
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false,
            surveyData: data || {},
            fieldsData: {
              surveyName,
              surveyCode,
              mandatoryFlag,
              openTime,
              closeTime,
              surveyDescription
            },
            questionGroups: groupList || []
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            submitLoading: false
          }
        });
      });
  };
}
export function queryData(payload, cb) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        queryLoading: true
      }
    });
    post("/survey/surveyTemplate/list", payload)
      .then(res => {
        if (cb) {
          cb();
        }

        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            surveyData: data || {}
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false
          }
        });
      });
  };
}

export function handleSearchPageDelete(payload, cb) {
  return () => {
    get("/survey/surveyTemplate/delete", payload)
      .then(() => {
        message.info("Operation Success", globalPromptTime, cb);
      })
      .catch(() => {

      });
  };
}

export function getMandatory() {
  return dispatch => {
    get("/survey/getIsMondatory")
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            queryLoading: false,
            mandatoryList: data || []
          }
        });
      })
      .catch(() => {

      });
  };
}

export function addQuestionBySurveyId(payload, cb) {
  return (dispatch, getState) => {
    get("/survey/group/detail", payload)
      .then(res => {
        const { data } = res;
        const {
          pambReducer: { systemSettingsReducers: {
            SurveyReducer: { questionGroups }
          } }
        } = getState();

        if (data) {
          data.mandatoryFlag = null
          data.groupIndex = randnum()
          questionGroups.push(data);
        }
        if (cb) {
          cb();
        }

        dispatch({
          type: SAVE,
          payload: {
            questionGroups
          }
        });
      })
      .catch(() => {

      });
  };
}

export function changePosition(index, type) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        SurveyReducer: { questionGroups }
      } }
    } = getState();
    if (type === "up") {
      questionGroups[index] = questionGroups.splice(
        index - 1,
        1,
        questionGroups[index]
      )[0];
    } else if (type === "down") {
      questionGroups[index] = questionGroups.splice(
        index + 1,
        1,
        questionGroups[index]
      )[0];
    }

    dispatch({
      type: SAVE,
      payload: {
        questionGroups
      }
    });
  };
}

export function changeContent(val, index) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        SurveyReducer: { questionGroups }
      } }
    } = getState();
    const item = questionGroups[index];
    item.mandatoryFlag = val;

    dispatch({
      type: SAVE,
      payload: {
        questionGroups
      }
    });
  };
}

export function saveFields(field, val) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        SurveyReducer: { fieldsData }
      } }
    } = getState();
    fieldsData[field] = val;
    dispatch({
      type: SAVE,
      payload: {
        fieldsData
      }
    });
  };
}

export function deleteItem(index) {
  return (dispatch, getState) => {
    const {
      pambReducer: { systemSettingsReducers: {
        SurveyReducer: { questionGroups }
      } }
    } = getState();
    questionGroups.splice(index, 1);
    dispatch({
      type: SAVE,
      payload: {
        questionGroups
      }
    });
  };
}

export function reSetGroupForSurvey() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        questionGroups: [],
        fieldsData: {}
      }
    });
  };
}

export function handleFresh(handle) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        noReFresh: !handle
      }
    });
  };
}

export function handleSaveOriginData(data) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: data
    });
  };
}
