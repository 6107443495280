import React from "react";
import {
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Table,
  Row,
  Col,
  Divider,
  Modal,
  Checkbox,
  Radio
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import styles from "./UserManagementHome.module.less";
import { getMenuList } from "../../../../../redux/homeReducer";
import {
  getUserList,
  resendEmail,
  handleActivate,
  saveFields,
  handleRefrash,
  saveTemporaryData,
  getTrainerInfo,
  updateRoleInfo
} from "../../../../../redux/pambReducer/systemSettingsReducers/userManagementReducer";
import { handleSplitUrl } from "../../../../../utils";
import { RED_TRIANGLE } from "../../../../../images";
import { showActionByRole } from "../../../../../utils/tableUtils"

const { Option } = Select;
const { RangePicker } = DatePicker;
const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};


const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ homeReducer, pambReducer: { systemSettingsReducers: { userManagementReducer } } }) => ({
    homeReducer,
    userManagementReducer
  }),
  {
    getMenuList,
    getUserList,
    resendEmail,
    handleActivate,
    saveFields,
    handleRefrash,
    saveTemporaryData,
    getTrainerInfo,
    updateRoleInfo
  }
)
class HomeForm extends React.Component {
  state = {
    columns: [],
    visible: false,
    roleAccessCode: '',
    roleTypeCode: '',
    userId: '',
    roleId: '',
  };

  componentDidMount() {
    const {
      homeReducer: { buttonList },
    } = this.props;

    const { rolerList } = this.props.userManagementReducer;
    this.getTainerInfoList()
    let columns = this.state.columns
    if (rolerList && rolerList[0]) {
      columns = this.getColumn(rolerList[0].roleid, rolerList)
      this.setState({
        columns
      })
    }

    if (!buttonList.length) {
      const [_, tabKey, sideKey] = handleSplitUrl(this.props); // eslint-disable-line
      this.props.getMenuList(tabKey, sideKey);
    }

    const { noRefrash } = this.props.userManagementReducer;
    // const { columns } = this.state;
    if (noRefrash) {
      const {
        usefulTemporaryData: { buCode, buRole, currentNum }
      } = this.props.userManagementReducer;

      const dataes = handleDataes(
        this.props.userManagementReducer.usefulTemporaryData
      );

      this.props.getUserList(
        {
          ...dataes,
          currentPageNumber: currentNum
        },
        buRole
      );

      this.props.handleRefrash(false);
      handleColumns({ columns, buCode, buRole });
    } else {
      this.handleInitData();
    }
  }

  getTainerInfoList = async () => {
    await this.props.getTrainerInfo('TRAINER_ROLE_TYPE')
    await this.props.getTrainerInfo('TRAINER_ROLE_ACCESS')
  }

  getColumn = (roleId, rolerList) => {
    const showAction = showActionByRole(roleId, rolerList);
    return [
      {
        title: "Name",
        dataIndex: "userName",
        ellipsis: true,
        align: "center",
        render: (text, record) => {
          const { userid, userId, userName, username, roleCode } = record;
          const {
            buRole,
            roleId,
            roleName,
            buCode
          } = this.props.userManagementReducer.usefulTemporaryData;
          const url = buRole && buRole.toLowerCase();
          const nowBuCode = buCode.replace(/\s+/g, "");
          const pathname = handleGotoDetail({ url, buCode: nowBuCode });
          return (
            <span onClick={() => this.props.handleRefrash(true)}>
              <Link
                to={{
                  pathname,
                  state: {
                    userId: userid || userId,
                    bu: buRole,
                    roleId,
                    roleName: roleCode === 'PACS AGENT LEADER' ? 'PACS Agent Leader' : roleName
                  }
                }}
              >
                {username || userName}
              </Link>
            </span>
          );
        }
      },
      {
        title: "Role",
        align: "center",
        dataIndex: "roleCode",
        render: (text, record) => {
          const {
            roleName
          } = this.props.userManagementReducer.usefulTemporaryData;
          const { roleCode } = record
          if (roleCode === 'PACS AGENT LEADER') {
            return <span>PACS Agent Leader</span>;
          } else if (roleCode === 'PACS AGENT') {
            return <span>PACS Agent</span>;
          } else {
            return <span>{roleName}</span>;
          }
        }
      },
      {
        title: "Last Login",
        dataIndex: "lastLogin",
        align: "center",
        render: text => (
          <span>{text ? moment(text).format("YYYY-MM-DD") : ""}</span>
        )
      },
      {
        title: "Status",
        align: "center",
        dataIndex: "status",
        render: text => {
          const statusArray = ["NoLogin", "Inactive", "Active"];
          return <span>{statusArray[text]}</span>;
        }
      },
      {
        title: "Actions",
        align: "center",
        width: "240px",
        render: (text, record) => {
          const {
            status,
            loginaccount,
            loginAccount,
            // username,
            // bu: buRole,
            userid,
            userId
          } = record;
          const {
            buRole,
            roleId
          } = this.props.userManagementReducer.usefulTemporaryData;
          let dom = null;
          const pruventureActionDom = (
            <Button
              onClick={() => this.openSchemeManagement(record)}
              type="link">
              manage trainer
            </Button>
          )
          let statusDom = <span className={styles.spanCssRight}></span>;
          const isStatus0 = status === "0";
          if (status === "0") {
            dom = showAction ? (
              <span
                className={styles.spanCss}
                onClick={() =>
                  this.props.resendEmail(
                    {
                      loginAccount: loginaccount || loginAccount,
                      subject: "init_password"
                    },
                    buRole
                  )
                }
              >
                Resend Email
              </span>
            ) : dom

            statusDom = (
              <span
                className={styles.spanCssRight}
                onClick={() =>
                  this.handleclickActivate(
                    { userId: userid || userId },
                    buRole,
                    "Deactivate"
                  )
                }
              >
                Deactivate
              </span>
            );
          } else if (status === "1") {
            statusDom = (
              <span
                className={styles.spanCssRight}
                onClick={() =>
                  this.handleclickActivate(
                    { userId: userid || userId },
                    buRole,
                    "Activate"
                  )
                }
              >
                Activate
              </span>
            );
          } else if (status === "2") {
            statusDom = (
              <span
                className={styles.spanCssRight}
                onClick={() =>
                  this.handleclickActivate(
                    { userId: userid || userId },
                    buRole,
                    "Deactivate"
                  )
                }
              >
                Deactivate
              </span>
            );
          }
          return (
            <span>
              {dom}
              {showAction && <Divider type="vertical" style={{ opacity: isStatus0 ? 1 : 0 }} />}
              {
                showAction ? <span>{statusDom}</span> : null
              }
              {roleId === '10' ? pruventureActionDom : null}
            </span>
          );
        }
      }
    ]
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const {
          buRole,
          tempBuRole,
          tempRoleName,
          tempBuCode
        } = this.props.userManagementReducer.usefulTemporaryData;
        const {
          rolerList
        } = this.props.userManagementReducer
        // const { columns } = this.state;
        const columns = this.getColumn(values.roleId, rolerList)
        this.setState({
          columns
        })
        const valuesData = {};
        Object.keys(values).map(item => {
          const elt = values[item];
          if (elt && typeof elt === "string") {
            valuesData[item] = elt.trim();
          } else {
            valuesData[item] = elt;
          }
        });
        const {
          roleId,
          userName,
          unitCode,
          agentCode,
          leaderName,
          contractDate,
          agentId,
          roleAccess,
          roleType
        } = valuesData;
        const [from, to] = contractDate || [];
        const fromdate = from && moment(from).format("YYYY-MM-DD");
        const todate = to && moment(to).format("YYYY-MM-DD");
        const submitData = {
          ...valuesData,
          currentPageNumber: 1,
          fromdate,
          todate
        };

        if (tempBuRole === buRole) {
          this.props.getUserList({ ...submitData }, buRole);
          this.props.saveTemporaryData({
            currentNum: 1,
            roleId,
            agentId,
            userName,
            unitCode,
            agentCode,
            leaderName,
            fromdate,
            todate,
            roleName: tempRoleName,
            buCode: tempBuCode,
            roleAccess,
            roleType
          });
        } else {
          this.props.getUserList({ ...submitData }, tempBuRole);
          this.props.saveTemporaryData({
            currentNum: 1,
            roleId,
            userName,
            agentId,
            unitCode,
            agentCode,
            leaderName,
            fromdate,
            todate,
            buRole: tempBuRole,
            roleName: tempRoleName,
            buCode: tempBuCode,
            roleAccess,
            roleType
          });
        }

        handleColumns({ columns, buCode: tempBuCode, buRole: tempBuRole });
      }
    });
  };

  handleSaveField = (field, val) => {
    this.props.saveFields(field, val);
  };

  handleUpdateUserInfo = (payload, userId) => {
    const {
      buRole,
      currentNum
    } = this.props.userManagementReducer.usefulTemporaryData;

    const dataes = handleDataes(
      this.props.userManagementReducer.usefulTemporaryData
    )
    const callback = () => {
      this.props.getUserList(
        { ...dataes, currentPageNumber: currentNum }, buRole
      );
    }
    this.props.updateRoleInfo(payload, userId, callback)
  }

  openSchemeManagement = (payload) => {
    const { userid, roleAccessCode, roleTypeCode } = payload
    this.setState({
      visible: true,
      userId: userid,
      roleAccessCode,
      roleTypeCode
    })
  }

  handleOk = (e, props) => {
    e.preventDefault();
    const { form } = props;
    form.validateFields({ first: true }, (err, values) => {
      if (!err) {
        const { pruVentureAccess, roleType } = values
        const { userId } = this.state
        this.setState({
          visible: false,
          roleAccessCode: '',
          roleTypeCode: '',
          userId: ''
        });
        const roleInfoDto = {
          pruVentureAccess,
          roleType
        }

        this.handleUpdateUserInfo(roleInfoDto, userId)
        form.resetFields()
      }
    });
  };

  handleCancel = (props) => {
    props.form.resetFields()
    this.setState({
      visible: false,
      userId: '',
      roleAccessCode: '',
      roleTypeCode: ''
    })
  }

  handleclickActivate = (payload, buRole, type) => {
    const {
      buRole: selectBu,
      currentNum
    } = this.props.userManagementReducer.usefulTemporaryData;
    const dataes = handleDataes(
      this.props.userManagementReducer.usefulTemporaryData
    );
    const callback = () =>
      this.props.getUserList(
        { ...dataes, currentPageNumber: currentNum },
        selectBu
      );
    this.props.handleActivate(payload, buRole, type, callback);
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    const { buRole } = this.props.userManagementReducer.usefulTemporaryData;

    this.props.saveTemporaryData({
      currentNum: current
    });
    const dataes = handleDataes(
      this.props.userManagementReducer.usefulTemporaryData
    );


    this.props.getUserList(
      {
        ...dataes,
        pageSize,
        currentPageNumber: current
      },
      buRole
    );
  };

  handleSelectChange = (val, option) => {
    const {
      props: { bu, name, code, type }
    } = option;
    if (bu === "ADMIN") {
      const reg = /^PACS/i
      const isPacs = reg.test(code)
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showQueryCondition: false,
        showLeaderName: false,
        showUnitCode: false,
        showFcCode: false,
        showUserStatus: isPacs
      });
    } else if (bu === "PAMB") {
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showQueryCondition: true,
        showUnitCode: true,
        showLeaderName: false,
        showFcCode: false,
        showUserStatus: false,
        showPruVenture: code === 'PAMBTrainer'
      });
    } else if (bu === "PACS") {
      this.props.saveTemporaryData({
        tempBuRole: bu,
        tempRoleName: name,
        tempBuCode: code,
        showQueryCondition: false,
        showFcCode: code === 'PACSAgent' || code === 'PACSAgentLeader',
        showUnitCode: true,
        showLeaderName: true,
        showUserStatus: true
      });
    }
    this.handleSaveField("roleId", val);
  };

  handleResetForm = () => {
    this.handleInitData();
    this.props.form.resetFields();
  };

  handleInitData = () => {
    const {
      userManagementReducer: { rolerList }
    } = this.props;
    const { columns } = this.state;
    const roleId = rolerList[0] && rolerList[0].roleid;
    const buRole = rolerList[0] && rolerList[0].bu;
    const roleName = rolerList[0] && rolerList[0].name;
    const buCode = rolerList[0] && rolerList[0].code;
    let showQueryCondition = false;
    let showLeaderName = false;
    let showUnitCode = false;
    let showFcCode = false;
    let showUserStatus = false;
    let showPruVenture = false;

    if (buRole === "PAMB") {
      showUnitCode = true
      showQueryCondition = true;
      showUserStatus = false;
      showPruVenture = buCode === 'PAMBTrainer'
    } else if (buRole === "PACS") {
      if (buCode === 'PACSAgent' || buCode === 'PACSAgentLeader') {
        showFcCode = true
      }
      showUnitCode = true
      showLeaderName = true;
      showQueryCondition = false;
      showUserStatus = true;
    } else {
      const reg = /^PACS/i
      const isPacs = reg.test(buCode)
      if (isPacs) {
        showUserStatus = true;
      } else {
        showUserStatus = false;
      }
    }
    this.props.getUserList({ roleId, currentPageNumber: 1 }, buRole);
    this.props.saveTemporaryData({
      roleId,
      buRole,
      tempBuRole: buRole,
      roleName,
      tempRoleName: roleName,
      buCode,
      tempBuCode: buCode,
      showUserStatus,
      showQueryCondition,
      showPruVenture,
      showLeaderName,
      showUnitCode,
      showFcCode,
      fieldsData: {}
    });
    handleColumns({ columns, buCode, buRole });
  };

  render() {
    const {
      form: { getFieldDecorator },
      homeReducer: { buttonList },
      userManagementReducer: {
        rolerList,
        userData: { userList, total, pageSize },
        trainerRoleAccessList,
        trainerRoleTypeList,
        queryUserListLoading,
        resendEmaiLoading,
        activateLoading,
        userStatusList = [],
        usefulTemporaryData: {
          currentNum,
          showQueryCondition,
          showPruVenture,
          showLeaderName,
          showUnitCode,
          fieldsData,
          showFcCode,
          showUserStatus,
        }
      }
    } = this.props;
    const { columns, visible, roleAccessCode, roleTypeCode, userid } = this.state;
    const { importList, createList } = handleButtonList(buttonList);
    return (
      <div className={styles.container}>
        <div className={styles.actionWrap}>
          <Row gutter={[20, 10]} type="flex">
            {importList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Import" }}
                  className={styles.buttonBox}
                  defaultActiveFirstOption={false}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {importList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}

            {createList.length ? (
              <Col>
                <Select
                  labelInValue
                  defaultValue={{ key: "Create" }}
                  className={styles.buttonBox}
                  suffixIcon={
                    <img
                      className={styles.ant_suffixicon}
                      src={RED_TRIANGLE}
                      alt=""
                    />
                  }
                  defaultActiveFirstOption={false}
                  dropdownClassName={styles.ant_home_select_drop}
                >
                  {createList.map(elt => (
                    <Option key={elt.buttonId}>
                      <Link to={elt.path} className={styles.actionItem}>
                        {elt.name}
                      </Link>
                    </Option>
                  ))}
                </Select>
              </Col>
            ) : null}
          </Row>
        </div>
        <Form
          className={styles.ant_form}
          labelAlign="left"
          colon={false}
          {...formLayout}
        >
          <Row {...flexRow}>
            <Col {...flexCol}>
              <Form.Item label="Role">
                {getFieldDecorator("roleId", {
                  initialValue:
                    fieldsData.roleId || (rolerList[0] && rolerList[0].roleid)
                })(
                  <Select
                    placeholder="Select a option"
                    onChange={this.handleSelectChange}
                  >
                    {rolerList.map(elt => (
                      <Option
                        value={elt.roleid}
                        key={elt.roleid}
                        bu={elt.bu}
                        name={elt.name}
                        code={elt.code}
                      >
                        {elt.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            {showQueryCondition ? (
              <Col {...flexCol}>
                <Form.Item label="Agent ID">
                  {getFieldDecorator("agentId", {
                    initialValue: fieldsData.AgentId
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("agentId", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            ) : null}
            {
              showQueryCondition ? (
                <Col {...flexCol}>
                  <Form.Item label="Agent Code">
                    {getFieldDecorator("agentCode", {
                      initialValue: fieldsData.agentCode
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("agentCode", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }

            <Col {...flexCol}>
              <Form.Item label="Name">
                {getFieldDecorator("userName", {
                  initialValue: fieldsData.userName
                })(
                  <Input
                    onChange={e =>
                      this.handleSaveField("userName", e.target.value)
                    }
                  />
                )}
              </Form.Item>
            </Col>
            {
              showFcCode ? (
                <Col {...flexCol}>
                  <Form.Item label="FC Code">
                    {getFieldDecorator("agentCode", {
                      initialValue: fieldsData.agentCode
                    })(
                      <Input
                        onChange={e =>
                          this.handleSaveField("agentCode", e.target.value)
                        }
                      />
                    )}
                  </Form.Item>
                </Col>
              ) : null
            }
            {showUnitCode ? (
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: fieldsData.unitCode
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("unitCode", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showPruVenture ? (
              <Col {...flexCol}>
                <Form.Item
                  label="Scheme Management"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  {getFieldDecorator("roleAccess", {
                    initialValue: fieldsData.roleAccess || ''
                  })(
                    <Select
                      placeholder="Select a option"
                    >
                      <Option value=''>All</Option>
                      {(trainerRoleAccessList && trainerRoleAccessList?.length) ? trainerRoleAccessList.map((item) => (
                        <Option
                          value={Object.values(item)[0]}
                          key={Object.values(item)[0]}
                          name={Object.keys(item)[0]}
                        >
                          {Object.keys(item)[0] === 'General' ? 'None' : Object.keys(item)[0]}

                        </Option>
                      )) : null}
                    </Select>

                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showPruVenture ? (
              <Col {...flexCol}>
                <Form.Item label="Type">
                  {getFieldDecorator("roleType", {
                    initialValue: fieldsData.roleType || ''
                  })(
                    <Select
                      placeholder="Select a option"
                    >
                      <Option value="">All</Option>
                      {(trainerRoleTypeList && trainerRoleTypeList.length) ? trainerRoleTypeList.map((item) => (
                        <Option
                          value={Object.values(item)[0]}
                          key={Object.values(item)[0]}
                          name={Object.keys(item)[0]}
                        >
                          {Object.keys(item)[0]}

                        </Option>
                      )) : null}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}

            {showLeaderName ? (
              <Col {...flexCol}>
                <Form.Item label="Leader Name">
                  {getFieldDecorator("leaderName", {
                    initialValue: fieldsData.leaderName
                  })(
                    <Input
                      onChange={e =>
                        this.handleSaveField("leaderName", e.target.value)
                      }
                    />
                  )}
                </Form.Item>
              </Col>

            ) : null}

            {showUserStatus ? (
              <Col {...flexCol}>
                <Form.Item label="User Status">
                  {getFieldDecorator("status", {
                    initialValue: fieldsData.status
                  })(
                    <Select
                      onChange={e =>
                        this.handleSaveField("status", e)
                      }
                      allowClear
                    >
                      {
                        userStatusList.map((elt) => {
                          return (
                            <Option key={elt.value} value={elt.value}>{elt.code}</Option>
                          )
                        })
                      }
                    </Select>
                  )}
                </Form.Item>
              </Col>
            ) : null}
          </Row>
        </Form>
        <div className={styles.serachBox}>
          <Button onClick={this.handleSubmit} className={styles.ant_btn}>
            Search
          </Button>
          {/* <Button
            onClick={this.handleResetForm}
            className={styles.ant_btn_reset}
          >
            Reset
          </Button> */}
        </div>
        {/* <div className={styles.dividingLine} /> */}
        <div className={styles.table}>
          <Table
            columns={columns}
            dataSource={userList}
            pagination={{
              total,
              pageSize,
              current: currentNum
            }}
            size="small"
            loading={
              queryUserListLoading || resendEmaiLoading || activateLoading
            }
            onChange={this.handleTableChange}
            className={styles.ant_dev_table}
            rowKey={(rowInfo) => rowInfo.userid || rowInfo.userId}
          />
          <ModalForm
            visible={visible}
            handleOk={this.handleOk}
            handleCancel={this.handleCancel}
            roleAccessCode={roleAccessCode}
            roleTypeCode={roleTypeCode}
            userId={userid}
          />
        </div>
      </div >
    );
  }
}

function handleButtonList(list) {
  const importList = [];
  const createList = [];
  list.map(item => {
    if (item.group === "import") {
      importList.push(item);
    } else if (item.group === "create") {
      createList.push(item);
    }
  });
  return { importList, createList };
}

const UserManagementHome = Form.create({ name: "homePage" })(HomeForm);

export default UserManagementHome;

function handleDataes(state) {
  const {
    roleId,
    userName,
    unitCode,
    agentCode,
    leaderName,
    fromdate,
    todate,
    agentId,
    roleAccess,
    roleType
  } = state;

  const dataes = {
    roleId,
    userName: userName || undefined,
    unitCode: unitCode || undefined,
    agentCode: agentCode || undefined,
    agentId: agentId || undefined,
    leaderName: leaderName || undefined,
    fromdate: fromdate || undefined,
    todate: todate || undefined,
    roleAccess: roleAccess || undefined,
    roleType: roleType || undefined
  };

  return dataes;
}

function handleGotoDetail({ url, buCode }) {
  // PACSCandidateAdmin
  switch (buCode) {
    case "PACSCandidate":
      return "/home/system_settings/user_management/candidate_detail";
    case "PACSGUEST":
      return "/home/system_settings/user_management/guest_detail";
    case "PAMBAdmin":
      return "/home/system_settings/user_management/lbu_admin_detail";
    case "PACSAdmin":
      return "/home/system_settings/user_management/lbu_admin_detail";
    case "PAMBCourseAdmin":
      return "/home/system_settings/user_management/course_admin_detail";
    case "PACSCourseAdmin":
      return "/home/system_settings/user_management/course_admin_detail";
    case "RegionalAdmin":
      return "/home/system_settings/user_management/region_admin_detail";
    case "PACSADCandidateAdmin":
      return "/home/system_settings/user_management/ad_candidate_admin_detail";
    case "PACSPDCandidateAdmin":
      return "/home/system_settings/user_management/pd_candidate_admin_detail";
    case "PBTBCourseAdmin":
      return "/home/system_settings/user_management/course_admin_detail";
    case "OverallCourseAdmin":
      return "/home/system_settings/user_management/course_admin_detail";
    default:
      return `/home/system_settings/user_management/${url}_detail`;
  }
}

function handleColumns({ columns, buCode, buRole }) {
  if (buCode) {
    const nowBuCode = buCode.replace(/\s+/g, "");
    const showDateRoleList = ['PAMBCourseAdmin', 'PAMBAgent', 'PAMBTrainer', 'PBTBCourseAdmin', 'OverallCourseAdmin', 'PBTBAgent']
    const hasRole = showDateRoleList.includes(nowBuCode)
    const reg = /^PACS/i
    const isPacs = reg.test(buCode)
    const hasField = columns.some(elt => elt.dataIndex === "createTime");
    const theOne = columns.findIndex(elt => elt.dataIndex === "lastLogin");
    if (hasRole || isPacs) {
      if (!hasField) {
        columns.splice(
          theOne + 1,
          0,
          {
            title: "Creation Date",
            align: "center",
            dataIndex: "createTime",
            render: (text, record) => {
              const { createTime, createtime } = record;
              const time = createTime || createtime;
              return (
                <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
              );
            }
          },
          {
            title: "Last Update Date",
            align: "center",
            dataIndex: "updateTime",
            render: (text, record) => {
              const { updateTime, updatetime } = record;
              const time = updateTime || updatetime;
              return (
                <span>{time ? moment(time).format("YYYY-MM-DD") : ""}</span>
              );
            }
          }
        );
      }
    } else {
      if (hasField) {
        columns.splice(theOne + 1, 2);
      }
    }
  }

  if (buRole) {
    const nowBuCode = buCode.replace(/\s+/g, "");
    const hasAgentId = columns.some(elt => elt.dataIndex === "agentId");
    const isPambAgent = nowBuCode === "PAMBAgent" || nowBuCode === "PAMBTrainer" || nowBuCode === "PBTBAgent";
    if (buRole === "ADMIN") {
      if (hasAgentId) {
        columns.splice(1, 2);
      }
    } else {
      if (isPambAgent) {
        if (!hasAgentId) {
          columns.splice(
            1,
            0,
            {
              title: "Agent ID",
              dataIndex: "agentId",
              align: "center",
              ellipsis: true,
              render: (text, record) => {
                const { agentid, agentId } = record;
                return <span>{agentid || agentId}</span>;
              }
            },
            {
              title: "Agent Code",
              dataIndex: "agentCode",
              align: "center",
              ellipsis: true,
              render: (text, record) => {
                const { agentcode, agentCode } = record;
                return <span>{agentcode || agentCode}</span>;
              }
            }
          );
        }
        if (nowBuCode === "PAMBTrainer") {
          columns.splice(4, 0,
            {
              title: "Scheme Management",
              align: "center",
              dataIndex: "schemeManagement",
              render: (text, record) => {
                const { roleAccessCode, roleAccess } = record
                return <span>{roleAccessCode === '1' ? 'None' : roleAccess}</span>
              }
            },
            {
              title: 'Type',
              align: 'center',
              dataIndex: 'roleType',
              render: (text, record) => {
                const { roleType } = record
                return <span>{roleType}</span>
              }
            })
        }
      }
    }
  }
}


function Formes2(props) {
  const flexCol2 = {
    span: 24
  };
  const formLayout2 = {
    labelCol: { span: 3 },
    wrapperCol: { span: 19 }
  };
  const { form: { getFieldDecorator }, visible, handleOk, handleCancel, roleAccessCode, roleTypeCode } = props
  return (
    <Modal
      title="Manage Trainer"
      visible={visible}
      onOk={(e) => handleOk(e, props)}
      onCancel={() => handleCancel(props)}
      okText='Confirm'
      okType='danger'
      className={styles.manageTrainerPopup}
    >
      <Form
        colon={false}
        {...formLayout2}
        labelAlign="left"
        className={styles.ant_form}
      >
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <div className={styles.manageTrainerPopupTitle}>Scheme Management Access</div>
          </Col>
          <Col {...flexCol2}>
            <span className={styles.ALTrainerTips}>{roleTypeCode === '1' ? 'By checking this, you allow the agent leader to access PruForce Trainer Assistance feature' : ''}</span>
          </Col>
          <Col {...flexCol2}>
            <Form.Item>
              {getFieldDecorator("pruVentureAccess", {
                initialValue: roleAccessCode === '2',
                valuePropName: 'checked'
              })(
                <Checkbox
                >
                  PruVenture
                </Checkbox>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row {...flexRow}>
          <Col {...flexCol2}>
            <div className={styles.manageTrainerPopupTitle}>Type</div>
          </Col>
          <Col {...flexCol2}>
            <Form.Item>
              {getFieldDecorator("roleType", {
                initialValue: roleTypeCode
              })(
                <Radio.Group
                  value={roleTypeCode}
                >
                  <Radio value="1" disabled>Agent Leader</Radio>
                  <Radio value="2" disabled={roleTypeCode === '1'}>Agency Growth</Radio>
                  <Radio value="3" disabled={roleTypeCode === '1'}>Litmos</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const ModalForm = Form.create()(Formes2);
