import React, {Component} from 'react'
import {
  Button,
  Icon,
  Spin,
  Upload,
  Empty,
  Table,
} from "antd";
import {get} from 'lodash'
import { connect } from "react-redux";
import styles from './style.module.less'
import {
  getAttendanceDetailsOverAll,
  getAttendanceDetailsPeriod,
  init_data,
  SAVE_DATA,
} from "../../../../../../redux/pambReducer/attendanceReportReducers/detial";
import { changeVals } from "../../../../../../redux/pambReducer/systemSettingsReducers/index.action";
import { download_template } from '../../../../../../redux/PublicReducer';
import {columnsOverall, columnsPeriod} from './column'

@connect(
  state => ({
    reducer: state.pambReducer.attendanceReportReducers.detailReducer,
    loading: state.PublicReducer.loading
  }),
  {
    changeVals,
    getAttendanceDetailsOverAll,
    getAttendanceDetailsPeriod,
    init_data,
    download_template
  }
)
class AttendanceReportDetail extends Component {
  state = {
    id: "",
    type: "",
    reportPeriod: "",
    backPath: "",
    orderField: 'START_TIME',
    orderBy: -1,
  };

  componentDidMount() {
    const {
      registrationId,
      reportPeriod,
      backPath,
      type
    } = this.props.location.state;
    this.setState({ id: registrationId, type, reportPeriod, backPath });
    if (type === "period") {
      this.props.getAttendanceDetailsPeriod(registrationId, {
        reportPeriod,

        pageSize: 10,
        currentPageNumber: this.props.reducer.currentPageNumber,
        orderField: this.state.orderField,
        orderBy: this.state.orderBy,
      });
    }
    if (type === "overAll") {
      this.props.getAttendanceDetailsOverAll(registrationId, {
        pageSize: 10,
        currentPageNumber: this.props.reducer.currentOverAllPageNumber
      });
    }
  }

  componentWillUnmount() {
    // this.props.init_data()
  }

  goBack = () => {
    if (this.state.type === "period") {
      this.props.history.push(this.state.backPath, {
        backPath: '/home/scheme_management/pru_venture_attendance_report/list',
        registrationId: this.state.id,
        type: "overAll"
      });
    } else if (this.state.type === "overAll") {
      this.props.history.push(this.state.backPath);
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    const order = get(sorter, "order", "");
    const ord = order === "ascend" ? 1 : -1;
    const field = get(sorter, "field", "")
    let fiel = 'START_TIME'
    if (field === "sessionEndTime") {
      fiel = 'END_TIME'
    }
    if (field === "sessionStartTime") {
      fiel = 'START_TIME'
    }
    if (this.state.type === "period") {
      this.props.getAttendanceDetailsPeriod(this.state.id, {
        reportPeriod: this.state.reportPeriod,

        pageSize: 10,
        currentPageNumber: pagination.current,
        orderField: fiel,
        orderBy: ord,
      });
    }
    if (this.state.type === "overAll") {
      this.props.getAttendanceDetailsOverAll(this.state.id, {
        pageSize: 10,
        currentPageNumber: pagination.current,
      });
    }
  }

  toPeriod = data => {
    const period = data.reportPeriod;
    if (this.state.type === "overAll") {
      this.props.history.push(
        "/home/scheme_management/pru_venture_attendance_report/period",
        {
          registrationId: this.state.id,
          type: "period",
          reportPeriod: period,
          backPath:
            "/home/scheme_management/pru_venture_attendance_report/overall"
        }
      );
    }
  };

  getColumns = () => {
    if (this.state.type === 'period') {
      return columnsPeriod
    }
    return [
      ...columnsOverall,
      {
        title: "Action",
        dataIndex: "Action",
        align: "center",
        fixed: "right",
        width: 150,
        render: (text, data, index) => {
          return (
            <div className={styles.action}>
              <Button type="link" onClick={() => this.toPeriod(data)}>
                View Details
              </Button>
            </div>
          );
        }
      }
    ];
  };

  render() {
    return (
      <div className={styles.container}>
        <Spin spinning={this.props.loading}>
          <div className={styles.header}>
            <Button
              className={styles.goback}
              onClick={this.goBack}
              type="link"
              icon="left"
            >
              View Agent Attendance Details
            </Button>
          </div>
          <div className={styles.tableBox}>
            <Table
              className={styles.ant_dev_table}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data Found"
                  />
                )
              }}
              pagination={{
                total: this.props.reducer.total,
                pageSize: this.props.reducer.pageSize,
                current: this.state.type === "overAll" ? this.props.reducer.currentOverAllPageNumber : this.props.reducer.currentPageNumber
              }}
              size="small"
              columns={this.getColumns()}
              onChange={this.handleTableChange}
              dataSource={this.props.reducer.attendanceReports}
              scroll={
                this.props.reducer.attendanceReports.length > 6
                  ? { y: 240 }
                  : {}
              }
              rowKey={(record, index) => {
                return String(index);
              }}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default AttendanceReportDetail;

