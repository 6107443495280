import { notification } from "antd";
import { post, get, put } from "../../../utils/request";
import { globalPromptTime10s } from "../../../utils/global";
import { loading } from "../../PublicReducer";

const GET_ATTENDANCES = "GET_ATTENDANCES";
const SAVE_DATA = 'ATTENDANCE_SAVE';
const VALIDATE_INFO = 'VALIDATE_INFO';
const ALL = 'All'


export const initState = {
  attendancesList: [],
  validateInfo: {}
};

// reducer
export default function AddAttendanceReducer(state = initState, action) {
  switch (action.type) {
    case SAVE_DATA:
      return { ...state, ...action.payload };
    case GET_ATTENDANCES:
      return { ...state, ...action.payload };
    case VALIDATE_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function init_data() {
  return {
    type: SAVE_DATA
  };
}

export function changeVals(type, val) {
  return dispatch => {
    return dispatch({
      type,
      payload: val
    });
  };
}

const baseApiUrl = '/pruventure/attendances'

/**
 * @param {*} payload {search: string}
 */
export function getAttendance(payload, cb = () => { }) {
  return dispatch => {
    dispatch(loading(true))
    return get(`${baseApiUrl}/${payload.sessionId}/options`)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          const data = res.data.regionInfo
          dispatch({
            type: GET_ATTENDANCES,
            payload: {
              attendancesList: [...data],
            }
          })
        } else {
          dispatch({
            type: GET_ATTENDANCES,
            payload: {
              attendancesList: [],
            }
          })
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

/**
 *
 * @param {*} payload
 * @param {*} cb
 * @returns
 */
export function validateAttendances(payload, cb = () => { }) {
  return dispatch => {
    dispatch(loading(true))
    return post(`${baseApiUrl}/${payload.sessionId}/bulkImport/validation`, payload.file)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          const data = res.data
          dispatch({
            type: VALIDATE_INFO,
            payload: {
              validateInfo: { ...data }
            }
          })
        } else {
          dispatch({
            type: VALIDATE_INFO,
            payload: {
              validateInfo: {},
            }
          })
          notification.error({
            message: 'validation error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function handleUpdateImportAttendances(payload, cb = () => { }) {
  return dispatch => {
    dispatch(loading(true))
    return put(`${baseApiUrl}/${payload.sessionId}/bulkImport/${payload.importId}/${payload.action}`, payload.errorAgentIds)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb(res)
        } else {
          notification.error({
            message: 'error',
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function handleValidateUserIds(payload, cb = () => { }) {
  return dispatch => {
    dispatch(loading(true))
    return post(`${baseApiUrl}/${payload.sessionId}`, payload.userIds)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          const data = res.data
          dispatch({
            type: VALIDATE_INFO,
            payload: {
              validateInfo: { ...data }

            }
          })
        } else {
          dispatch({
            type: VALIDATE_INFO,
            payload: {
              validateInfo: {},
            }
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

export function handleImportAttendances(payload, cb = () => { }) {
  return dispatch => {
    dispatch(loading(true))
    post(`${baseApiUrl}/${payload.sessionId}/bulkImport/${payload.importId}`, payload)
      .then(res => {
        dispatch(loading(false));
        if (res.success) {
          cb()
          notification.success({
            message: 'Attendance is added successfully',
            duration: globalPromptTime10s,
            className: "ant-style-notification-success"
          })
        } else {
          notification.error({
            message: res.message,
            duration: globalPromptTime10s,
            className: "ant-style-notification-error"
          })
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch(loading(false));
        }, 500);
      });
  };
}

