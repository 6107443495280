import React from "react";
import { Form, Input, Row, Col, Icon, Popover, Popconfirm, Button, Spin } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import TitleAndTabel from "./TitleAndTabel";
import {
  getAgentDetail,
  updateStatus,
  handleDownloadxls
} from "../../../../../redux/pambReducer/CPDSettingsReducers/TRagentDetailReducer";
import { getTRsearchData } from "../../../../../redux/pambReducer/CPDSettingsReducers/trainingRequirementSearchReducer";
import styles from "./TRagentMTADetail.module.less";

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};
@connect(
  ({
    pambReducer: { CPDSettingsReducers: {
      TRagentDetailReducer,
      trainingRequirementSearchReducer
    } }
  }) => ({
    TRagentDetailReducer,
    trainingRequirementSearchReducer
  }),
  { getAgentDetail, updateStatus, getTRsearchData, handleDownloadxls }
)
class HomeForm extends React.Component {
  state = {
    columns: [
      {
        title: "Course Name",
        dataIndex: "courseName",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Course Type",
        dataIndex: "courseType",
        ellipsis: true,
        align: "center"
      },
      {
        title: "Department",
        dataIndex: "department",
        align: "center"
      },
      {
        title: "Training Date",
        dataIndex: "trainingDate",
        align: "center",
        render: (text, record) => {
          const { trainingDates = [] } = record;
          if (trainingDates && trainingDates.length > 1) {
            const content = (
              <div className={styles.contentBox}>
                {trainingDates.map(elt => {
                  return <p key={elt}>{elt}</p>;
                })}
              </div>
            );

            return (
              <div className={styles.datebox}>
                <span className={styles.firstdate}>{trainingDates[0]}</span>
                <Popover
                  placement="right"
                  title="Training Date"
                  content={content}
                  trigger="click"
                  overlayClassName="ant_popC_training_date"
                >
                  <span className={styles.datemore}>···</span>
                </Popover>
              </div>
            );
          }

          return <div>{(trainingDates && trainingDates.length) ? trainingDates[0] : null}</div>;
        }
      },
      {
        title: "Training Session Status",
        dataIndex: "trainingSessionStatus",
        align: "center"
      },
      {
        title: "Training Hours",
        dataIndex: "cpdHours",
        align: "center",
      },
      {
        title: "Completed Date",
        dataIndex: "completeDate",
        align: "center",
      },
      {
        title: "Course Completed Status",
        dataIndex: "courseStatus",
        align: "center"
      },
      {
        title: "Operation",
        align: "center",
        render: (text, record) => {
          const { courseStatus, courseId, highLight } = record;
          const {
            location: { state = {} }
          } = this.props;
          const { userId, type } = state;
          if (courseStatus === "YES(overdue)" && highLight !== 0) {
            return (
              <Popconfirm
                title="Please double confirm?"
                onConfirm={() => this.handleUpdate({ userId, courseId, type })}
                okText="Yes"
                cancelText="No"
              >
                <span className={styles.spanCss}>Update</span>
              </Popconfirm>
            );
          }

          return <span />;
        }
      }
    ]
  };

  componentDidMount() {
    const { state = {} } = this.props.location;
    const { userId, type, searchPramas } = state;
    this.props.getAgentDetail({ userId, type });
    this.props.getTRsearchData(searchPramas, true);
  }

  handleUpdate = payload => {
    const { state = {} } = this.props.location;
    const { userId, type, searchPramas } = state;
    const callback = () => {
      this.props.getAgentDetail({ userId, type });
      this.props.getTRsearchData(searchPramas, true);
    };
    this.props.updateStatus(payload, callback);
  };

  handleDownload = props => {
    const { mtaFirstReq, mtaSecondReq, state, rfpExamStatus } = props;
    const { userId, type } = state || {};
    this.props.handleDownloadxls({
      userId,
      type,
      requirementStatus1: mtaFirstReq,
      requirementStatus2: mtaSecondReq,
      requirementStatus3: rfpExamStatus
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      TRagentDetailReducer: { mtaAgentDetailData, detailLoading },
      trainingRequirementSearchReducer: { TRsearchData },
      location: { state }
    } = this.props;
    const {
      name,
      agentId,
      agentCode,
      nric,
      mtaContractDate,
      first6MonMtaDeadline,
      first6MonthMtaList = [],
      totalM2CPDtoBefulfilled,
      totalM2CPDAchieved,
      balanceM2CPDtoBefulfilled,
      reappointedIndicator,
      rfpExamList,
      rfpExamStatus,
      rfpExamDeadline,

    } = mtaAgentDetailData;
    const { columns } = this.state;
    let month = 30;
    if (mtaContractDate) {
      const mon = moment(mtaContractDate).get('month') + 1
      if (mon > 7) {
        month = 10
      }
    }
    const { mtaFirstReq, mtaSecondReq } = handleGetReqStatus(
      TRsearchData,
      state
    );
    return (
      <div className={styles.container}>
        <Spin spinning={detailLoading}>
          <div className={styles.heading}>
            <Icon
              type="left"
              style={{ marginRight: 15, cursor: "pointer" }}
              onClick={() =>
                this.props.history.push(
                  "/home/training_history/new_agent_training_history/search"
                )
              }
            />
            TAKAFUL NEW AGENT REPORT
          </div>
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    initialValue: name
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="NRIC Number">
                  {getFieldDecorator("NRICNumber", {
                    initialValue: nric
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent ID">
                  {getFieldDecorator("agentId", {
                    initialValue: agentId
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="MTA Contract Date">
                  {getFieldDecorator("mtaContractDate", {
                    initialValue: mtaContractDate
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Agent Code">
                  {getFieldDecorator("agentCode", {
                    initialValue: agentCode
                  })(<Input disabled />)}
                </Form.Item>
              </Col>

              <Col {...flexCol}>
                <Form.Item label="Reappointed Indicator">
                  {getFieldDecorator("reappointedIndicator", {
                    initialValue: reappointedIndicator
                  })(<Input disabled />)}
                </Form.Item>
              </Col>
            </Row>

          </Form>

          <div className={styles.downloadBox}>
            <Button
              className={styles.downloadBtn}
              icon="download"
              onClick={() =>
                this.handleDownload({ mtaFirstReq, mtaSecondReq, rfpExamStatus, state })
              }
            >
              Download
            </Button>
          </div>

          <TitleAndTabel
            columns={columns}
            title={`MTA 1st Year Agent Training Requirements (20 Hours) - Deadline : ${first6MonMtaDeadline ||
              ""}`}
            dataSource={first6MonthMtaList}
            status={mtaFirstReq}
            style={{ marginBottom: 20 }}
          />
          {/* has been moved to CPD report
          <TitleAndTabel
            columns={columns}
            title={`MTA 2nd Year Agent Training Requirements (${month} Hours) - Deadline: ${secondYearMtaDeadline ||
              ""}`}
            dataSource={secondYearMtaList}
            status={mtaSecondReq}
            style={{ marginBottom: 0 }}
          /> */}
          {/* <div className={styles.requirement_status}>
            <span className={styles.infobox}>
              <span className={styles.circle} />
              Total M2 CPD Hours To Be Fulfilled:
              <span className={styles.num}>{totalM2CPDtoBefulfilled}</span>
            </span>
            <span className={styles.infobox}>
              <span className={styles.circle} />
              Total M2 CPD Hours Achieved:
              <span className={styles.num}>{totalM2CPDAchieved}</span>
            </span>
            <span className={styles.infobox}>
              <span className={styles.circle} />
              Balance M2 CPD Hours To Be Fulfilled:
              <span className={styles.num}>{balanceM2CPDtoBefulfilled}</span>
            </span>
          </div> */}
          <TitleAndTabel
            columns={columns}
            title={`MTA – Shariah RFP / IFP Exam - PITA - Deadline: ${rfpExamDeadline || ""}`}
            dataSource={rfpExamList}
            status={rfpExamStatus || ""}
          />
        </Spin>
      </div>
    );
  }
}
const TRagentMTADetail = Form.create()(HomeForm);

export default TRagentMTADetail;

function handleGetReqStatus(data = {}, state = {}) {
  const { items } = data;
  if (items) {
    const { userId } = state;
    const reqstatusData = items.find(elt => {
      return elt.userId === userId;
    });
    return reqstatusData || {};
  }
  return {};
}
