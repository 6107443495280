import React from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Input,
  Empty,
  Popover
} from "antd";

import {
  Table,
} from "antd-v5";
import { connect } from "react-redux";
import moment from "moment";
import styles from "./TrainingHistoryReportDetail.module.less";
import {
  getDetailByUserId,
  resetDetailData
} from "../../../../../redux/pacsReducer/CPDReportReducers/TrainingHistoryReportReducer";
import Header from '../../../../components/Header'
import { switchDate } from "../../../../../utils/global";

const formLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const flexCol = {
  xxl: 8,
  xl: 8,
  lg: 12,
  md: 12,
  sm: 12,
  xs: 12
};

const flexRow = {
  gutter: [22, 0]
};

@connect(
  ({ pacsReducer: { CPDReportReducers: { TrainingHistoryReportReducer } } }) => ({
    TrainingHistoryReportReducer
  }),
  {
    getDetailByUserId,
    resetDetailData
  }
)
class ReportDetail extends React.Component {
  state = {
    fetching: false,
    queryData: { currentPageNumber: 1 },
    isShowTable: false,
    columns: [
      {
        title: ' ',
        children: [
          {
            title: "Course Code",
            dataIndex: "courseCode",
            align: "center",
          },
          {
            title: "Course Name",
            align: "center",
            dataIndex: "courseName",
          },
          {
            title: "Start Date",
            dataIndex: "startDate",
            align: "center",
          },
          {
            title: "End Date",
            dataIndex: "endDate",
            align: "center",
          },
          {
            title: "Session Attended",
            align: "center",
            dataIndex: "sessionAttened",
          },
          {
            title: "Session Required",
            align: "center",
            dataIndex: "sessionRequired",
          },
          {
            title: "Status",
            dataIndex: "status",
            align: 'center',
            render: (text, row, index) => {
              const {
                TrainingHistoryReportReducer: {
                  detailData: { agentHistoryReportCourseInfoList = [] }
                }
              } = this.props;

              return <span>{text}</span>;
            }
          }
        ]
      },
      {
        title: 'Core',
        dataIndex: "core1",
        align: "center",
        children: [
          {
            title: "Ethics",
            dataIndex: "ethicS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, ethicV } = record
              return this.renderPopover(record, 'Ethics', shiftInOutFlag, ethicV, text);
            }
          },
          {
            title: "R&R",
            dataIndex: "rANDrS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, rANDrV } = record
              // return <span>{shiftInOutFlag === 1 ? rANDrV : text}</span>
              return this.renderPopover(record, 'R&R', shiftInOutFlag, rANDrV, text);
            }
          },
          {
            title: "ERR",
            dataIndex: "errS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, errV } = record
              return this.renderPopover(record, 'ERR', shiftInOutFlag, errV, text);
            }
          }
        ]
      },
      {
        title: 'Supplementary',
        dataIndex: "Supplementary",
        align: "center",
        children: [
          {
            title: "CIS",
            dataIndex: "cisS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, cisV } = record
              return this.renderPopover(record, 'CIS', shiftInOutFlag, cisV, text);
            }
          },
          {
            title: "A&H",
            dataIndex: "aANDhS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, aANDhV } = record
              return this.renderPopover(record, 'A&H', shiftInOutFlag, aANDhV, text);
            }
          },
          {
            title: "Others",
            dataIndex: "othersS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, othersV } = record
              return this.renderPopover(record, 'Others', shiftInOutFlag, othersV, text);
            }
          },
          {
            title: "S(TOT)",
            dataIndex: "sTOTS",
            align: "center",
            render: (text, record) => {
              const { shiftInOutFlag, sTOTV } = record
              return <span>{shiftInOutFlag === 1 ? sTOTV : text}</span>
            }
          },
        ]
      },
    ]
  };

  componentDidMount() {
    const { state = {} } = this.props.location
    this.props.getDetailByUserId({ ...state });
    this.saveDate(state)
  }

  componentWillUnmount() {
    this.props.resetDetailData()
  }

  saveDate = (state) => {
    let trainingDate = ''
    const { trainingDateFrom, trainingDateTo } = state
    if (trainingDateFrom && trainingDateFrom) {
      trainingDate = `${trainingDateFrom} - ${trainingDateTo}`
    } else {
      const year = new Date().getFullYear()
      trainingDate = `${year}-01-01 - ${year}-12-31`
    }

    this.setState({
      trainingDate
    })
  }

  handlebgColor = (record) => {
    const { shiftInOutFlag } = record
    switch (shiftInOutFlag) {
      case 1:
        return styles.ant_row_bg_pink
      case 2:
        return styles.ant_row_bg_yellow
      default:
        return ''
    }
  }

  renderPopover = (record, key, shiftInOutFlag, vText = '', text = '') => {
    const { cpdEditResults } = record;

    const preValue = cpdEditResults?.courseCpd && cpdEditResults?.courseCpd[key];
    const currentValue = cpdEditResults?.effectiveCpd && cpdEditResults?.effectiveCpd[key];
    let updated = false;

    if (preValue !== currentValue) {
      updated = true;
    }

    if (updated) {
      const updateTime = cpdEditResults?.updateTime ? moment(cpdEditResults?.updateTime).format("YYYY-MM-DD") : '';
      const content = (
        <div style={{ width: 250 }}>
          <p>CPD hours assigned to course: {preValue}</p>
          <p>CPD hours updated to: <span style={{ fontWeight: 'bold' }} >{currentValue}</span></p>
          <p>Last update time: {updateTime}</p>
          <p>Updated by: {cpdEditResults?.updateUser}</p>
          <p>Reason of update: {cpdEditResults?.updateReason}</p>
        </div>
      );

      return (
        <Popover content={content} style={{ width: 250 }} placement="left" autoAdjustOverflow>
          <span className={styles.blodSpan}>{shiftInOutFlag === 1 ? vText : text}*</span>
        </Popover>
      )
    }

    return <span>{shiftInOutFlag === 1 ? vText : text}</span>
  }

  renderSummary = () => {
    const {
      TrainingHistoryReportReducer: {
        detailData
      }
    } = this.props;

    const { name, unit, appointment, al, summaryArray = [], agentHistoryReportCourseInfoList = [] } = detailData;

    if (agentHistoryReportCourseInfoList.length === 0) {
      return null;
    }

    return (
      <Table.Summary fixed='top' >

        <Table.Summary.Row className={styles.summary_row}>
          <Table.Summary.Cell colSpan={5} className={[styles.summary_cell, styles.right]}>{unit} - {name}</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2} className={[styles.summary_cell, styles.right]}>Shortfall</Table.Summary.Cell>
          {
            summaryArray?.length > 0 && summaryArray[0].map(item => {
              return (
                <Table.Summary.Cell colSpan={1} className={[styles.summary_cell, styles.center]}>{item}</Table.Summary.Cell>
              )
            })
          }
        </Table.Summary.Row>
        <Table.Summary.Row className={styles.summary_row}>
          <Table.Summary.Cell colSpan={5} className={[styles.summary_cell, styles.right]}>Appointment: {appointment} </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2} className={[styles.summary_cell, styles.right]}>Requirement</Table.Summary.Cell>
          {
            summaryArray?.length > 0 && summaryArray[1].map(item => {
              return (
                <Table.Summary.Cell colSpan={1} className={[styles.summary_cell, styles.center]}>{item}</Table.Summary.Cell>
              )
            })
          }
        </Table.Summary.Row>

        <Table.Summary.Row className={styles.summary_row}>
          <Table.Summary.Cell colSpan={5} className={[styles.summary_cell, styles.right]}>AL: {al}</Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2} className={[styles.summary_cell, styles.right]}>Total</Table.Summary.Cell>
          {
            summaryArray?.length > 0 && summaryArray[2].map(item => {
              return (
                <Table.Summary.Cell colSpan={1} className={[styles.summary_cell, styles.center]}>{item}</Table.Summary.Cell>
              )
            })
          }
        </Table.Summary.Row>
      </Table.Summary>
    );
  }

  render() {
    const {
      form: { getFieldDecorator },
      TrainingHistoryReportReducer: {
        detailData,
        pageLoading,
      }
    } = this.props;

    const { columns, trainingDate } = this.state;

    const dataList = detailData.agentHistoryReportCourseInfoList || [];


    return (
      <div className={styles.container}>
        <Spin spinning={pageLoading}>
          <Header
            title="Training History Report Detail"
          />
          <Form
            className={styles.ant_form}
            labelAlign="left"
            colon={false}
            {...formLayout}
          >
            <Row {...flexRow}>
              <Col {...flexCol}>
                <Form.Item label="Name">
                  {getFieldDecorator("name", {
                    initialValue: detailData.name
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Unit Code">
                  {getFieldDecorator("unitCode", {
                    initialValue: detailData.unit
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="CPD Status">
                  {getFieldDecorator("cpdstatus", {
                    initialValue: detailData.cpdstatus
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
              <Col {...flexCol}>
                <Form.Item label="Training Date">
                  {getFieldDecorator("trainingDate", {
                    initialValue: trainingDate
                  })(
                    <Input disabled />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.markbox}>
            <div className={styles.colorBox}>
              <span className={`${styles.ant_row_bg_yellow} ${styles.ant_mark}`} />
              Shift In
            </div>
            <div className={styles.colorBox}>
              <span className={`${styles.ant_row_bg_pink} ${styles.ant_mark}`} />
              Shift Out
            </div>
          </div>
          <Table
            sticky
            columns={columns}
            dataSource={dataList}
            pagination={false}
            size="small"
            className={styles.ant_dev_table}
            rowKey={(elt, ind) => ind}
            rowClassName={this.handlebgColor}
            bordered
            locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data Found" /> }}
            scroll={dataList.length >= 10 ? { y: 400 } : {}}
            summary={this.renderSummary}
          />

        </Spin>
      </div>
    );
  }
}

const TrainingHistoryReportDetail = Form.create()(ReportDetail);

export default TrainingHistoryReportDetail;
