import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Spin,
  Input,
  Tooltip,
  Table,
  DatePicker,
  notification,
  Icon
} from "antd";
import moment from "moment";
import { changeVals } from "../../../../../redux/pclaReducer/systemSettingsReducers/index.action";
import { globalPromptTime10s } from "../../../../../utils/global";
import {
  search,
  init_data,
  getCourseNameListByCode,
  getCourseNameListByName,
  downLoadAgentTrainingCodeExcel,
  downLoadAgentTrainingCodeTxt
} from "../../../../../redux/pclaReducer/systemSettingsReducers/PCLAAgentTrainingCodeReducer";
import styles from "./styles.module.less";

const CreateForm = Form.create({
  name: "PCLAAgentTrainingCode_Form",
  onFieldsChange(props, changedFields) {
    props.onChange("PCLAAgentTrainingCode", changedFields);
  },
  mapPropsToFields(props) {
    return {
      codeName: Form.createFormField({
        ...props.codeName,
        value: props.codeName.value
      }),
      completuonDate: Form.createFormField({
        ...props.completuonDate,
        value: props.completuonDate.value
      })
    };
  },
  onValuesChange() { }
})(props => {
  const { getFieldDecorator } = props.form;
  const { RangePicker } = DatePicker;
  const { loading, columns } = props;
  const { TextArea } = Input;
  return (
    <Spin spinning={loading}>
      <Form
        hideRequiredMark
        layout="horizontal"
        labelCol={{ span: 7 }}
        labelAlign="left"
        wrapperCol={{ span: 17 }}
        colon={false}
      >
        <Row gutter={[24, 0]}>
          <Col {...flexCol}>
            <Form.Item label="Course Code & Name">
              {getFieldDecorator("codeName", {})(
                <TextArea readOnly autoSize={{ minRows: 1, maxRows: 6 }} onClick={props.showArrBox} />
              )}
            </Form.Item>
          </Col>
          <Col {...flexCol}>
            <Form.Item label="Completion Time">
              {getFieldDecorator(
                "completuonDate",
                {}
              )(
                <RangePicker
                  showTime={{
                    hideDisabledOptions: true,
                    format: "HH:mm:ss",
                    defaultValue: [
                      moment("00:00", "HH:mm:ss"),
                      moment("23:59", "HH:mm:ss")
                    ]
                  }}
                  placeholder={["From Time", "To Time"]}
                  style={{ width: "100%" }}
                  format={["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm:ss"]}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24} style={insideStyles.goCenter}>
            <Form.Item style={{ marginRight: "20px" }}>
              <Button
                type="primary"
                className={`${styles.btnColor} ${styles.btnText}`}
                onClick={() => {
                  props.handleSubmit(props.form);
                }}
              >
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <div className={styles.btnCol} style={{ display: props.searchList.length ? "block" : "none" }}>
          <Button
            className={styles.btn}
            type="danger"
            icon="download"
            onClick={() => {
              // const courseIdList = Array.from(new Set(props.courseName.value.concat(props.courseCode.value)));
              const courseIdList = [];
              const nowTrainingCodeArr = [...props.trainingCodeArr];
              nowTrainingCodeArr.forEach((item) => {
                let strIdArr = "";
                strIdArr = `${item.courseName}&&${item.trainingCode}`
                courseIdList.push(strIdArr);
              });
              props.downLoadAgentTrainingCodeTxt({
                courseIdList,
                fromCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                toCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                pageSize: props.pageSize,
                currentPageNumber: props.currentPageNumber
              });
            }}
          >
            Download to TXT
          </Button>
          <Button
            className={styles.btn}
            type="danger"
            icon="download"
            onClick={() => {
              // const courseIdList = Array.from(new Set(props.courseName.value.concat(props.courseCode.value)));
              const courseIdList = [];
              const nowTrainingCodeArr = [...props.trainingCodeArr];
              nowTrainingCodeArr.forEach((item) => {
                let strIdArr = "";
                strIdArr = `${item.courseName}&&${item.trainingCode}`
                courseIdList.push(strIdArr);
              });
              props.downLoadAgentTrainingCodeExcel({
                courseIdList,
                fromCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                toCompletionDate: props.completuonDate.value.length
                  ? props.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
                  : "",
                pageSize: props.pageSize,
                currentPageNumber: props.currentPageNumber
              });
            }}
          >
            Download to Excel
          </Button>
        </div>
        <div>
          <Table
            style={{ display: props.searchList.length ? "block" : "none" }}
            bordered
            dataSource={props.searchList}
            className={styles.ant_dev_table}
            columns={columns}
            onChange={pagination => {
              props.handleTableChange(pagination);
            }}
            pagination={{
              total: props.total,
              pageSize: props.pageSize,
              current: props.currentPageNumber
            }}
            size="small"
            rowKey="index"
          />
        </div>
      </Form>
    </Spin>
  );
});

@connect(
  ({
    pclaReducer: { systemSettingsReducers: { PCLAAgentTrainingCodeReducer } },
    PublicReducer: { loading },
    homeReducer: { buttonList },
    homeReducer
  }) => ({
    fields: PCLAAgentTrainingCodeReducer,
    loading,
    buttonList,
    homeReducer
  }),
  {
    changeVals,
    search,
    init_data,
    getCourseNameListByCode,
    getCourseNameListByName,
    downLoadAgentTrainingCodeExcel,
    downLoadAgentTrainingCodeTxt
  }
)
class PCLAAgentTrainingCode extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.showArrBox = this.showArrBox.bind(this);
    this.saveArrBox = this.saveArrBox.bind(this);
    this.closeArrBox = this.closeArrBox.bind(this);
    this.state = {
      pageSize: 10,
      pageIndex: 1,
      showBox: false,
      trainingCodeArr: [],
      saveTrainingCodeArr: [],
      columns: [
        {
          title: "FC Code",
          dataIndex: "fcCode",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Training Code",
          dataIndex: "trainingCode",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Completion Time",
          dataIndex: "completionDate",
          align: "center",
          render: text => (
            <Tooltip placement="top" title={text}>
              {text}
            </Tooltip>
          )
        },
        {
          title: "Status",
          dataIndex: "status",
          align: "center"
        },
        {
          title: "Action",
          dataIndex: "action",
          align: "center"
        }
      ]
    };
  }

  componentDidMount() {
    this.props.init_data();
    // this.props.search({
    //   courseIdList: [],
    //   fromCompletionDate: "",
    //   toCompletionDate: "",
    //   pageSize: this.state.pageSize,
    //   currentPageNumber: this.state.pageIndex
    // });
  }

  handleSubmit = form => {
    form.validateFields(null, { first: true }, err => {
      const { fields } = this.props;
      // const { pageSize, pageIndex } = this.state
      if (!err) {
        if (fields.completuonDate.value.length) {
          const end = fields.completuonDate.value[1].valueOf();
          const start = fields.completuonDate.value[0];
          const nowYear = moment(start).add(1, "year").valueOf();
          if (end > nowYear) {
            notification.error({
              message: "No longer than one year",
              duration: globalPromptTime10s,
              className: "ant-style-notification-error"
            });
            return;
          }
        }
        // const courseIdList = Array.from(new Set(fields.courseName.value.concat(fields.courseCode.value)));
        const courseIdList = [];
        if (this.state.trainingCodeArr.length) {
          const nowTrainingCodeArr = [...this.state.trainingCodeArr];
          nowTrainingCodeArr.forEach((item) => {
            const strIdArr = `${item.courseName}&&${item.trainingCode}`;
            courseIdList.push(strIdArr);
          });
        }
        this.props.search({
          courseIdList,
          fromCompletionDate: fields.completuonDate.value.length
            ? fields.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
            : "",
          toCompletionDate: fields.completuonDate.value.length
            ? fields.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
            : "",
          pageSize: this.state.pageSize,
          currentPageNumber: this.state.pageIndex
        });
      }
    });
  };

  handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    // const { tableData: fields } = this.state;
    const { fields } = this.props;
    // const courseIdList = Array.from(new Set(fields.courseName.value.concat(fields.courseCode.value)));
    const courseIdList = [];
    const nowTrainingCodeArr = [...this.state.trainingCodeArr];
    nowTrainingCodeArr.forEach((item) => {
      let strIdArr = "";
      strIdArr = `${item.courseName}&&${item.trainingCode}`
      courseIdList.push(strIdArr);
    });
    this.props.search({
      courseIdList,
      fromCompletionDate: fields.completuonDate.value.length
        ? fields.completuonDate.value[0].format("YYYY-MM-DD HH:mm:ss")
        : "",
      toCompletionDate: fields.completuonDate.value.length
        ? fields.completuonDate.value[1].format("YYYY-MM-DD HH:mm:ss")
        : "",
      pageSize,
      currentPageNumber: current
    });
  };

  saveArrBox() {
    const nowTrainingCodeArr = [...this.state.trainingCodeArr];
    /* eslint-disable */
    for (let i = 0; i < nowTrainingCodeArr.length; i++) {
      if (!nowTrainingCodeArr[i].courseCode || !nowTrainingCodeArr[i].trainingCode || !nowTrainingCodeArr[i].courseName) {
        notification.error({
          message: "Please fill in the mandatory fields",
          duration: globalPromptTime10s,
          className: "ant-style-notification-error"
        });
        return;
      }
    }
    /* eslint-disable */
    let valStr = "";
    nowTrainingCodeArr.forEach((item) => {
      if (!valStr) {
        valStr += `${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
      } else {
        valStr += ` , ${item.showCourseCode} && ${item.showCourseName} && ${item.trainingCode}`;
      }
    });
    this.setState(() => ({
      showBox: false,
      saveTrainingCodeArr: nowTrainingCodeArr
    }));
    this.props.changeVals("PCLAAgentTrainingCode", {
      codeName: {
        value: valStr
      }
    });
  }

  showArrBox() {
    this.props.getCourseNameListByCode("");
    const saveTrainingCodeArr = [...this.state.saveTrainingCodeArr];
    if (!saveTrainingCodeArr.length) {
      this.setState(() => ({
        showBox: true,
        trainingCodeArr: [
          {
            courseName: "",
            courseCode: "",
            trainingCode: "",
            showCourseName: "",
            showCourseCode: ""
          }
        ]
      }));
    }
    else {
      this.setState(() => ({
        showBox: true,
        trainingCodeArr: saveTrainingCodeArr
      }));
    }
  }

  closeArrBox() {
    this.setState(() => ({
      showBox: false,
      trainingCodeArr: [...this.state.saveTrainingCodeArr]
    }));
  }

  render() {
    const { Option } = Select;
    const { nameList, codeList } = this.props.fields;
    const { trainingCodeArr, showBox, saveTrainingCodeArr } = this.state;
    const props = {
      onChange: this.props.changeVals,
      handleSubmit: this.handleSubmit,
      loading: this.props.loading,
      columns: this.state.columns,
      handleTableChange: this.handleTableChange,
      downLoadAgentTrainingCodeExcel: this.props.downLoadAgentTrainingCodeExcel,
      downLoadAgentTrainingCodeTxt: this.props.downLoadAgentTrainingCodeTxt,
      showArrBox: this.showArrBox,
      trainingCodeArr: this.state.trainingCodeArr
    };
    return (
      <div className={styles.container}>
        {
          showBox ?
            <div className={styles.showBox}>
              <div style={{ marginBottom: "20px", width: "100%", display: "flex", justifyContent: "space-between", color: "#ed1b2e", alignItems: "center" }}>
                <Button
                  type="primary"
                  style={{ width: "120px" }}
                  className={`${styles.btnColor} ${styles.btnText}`}
                  onClick={() => {
                    const nowTrainingCodeArr = [...trainingCodeArr];
                    nowTrainingCodeArr.push({
                      courseName: "",
                      courseCode: "",
                      trainingCode: "",
                      showCourseName: "",
                      showCourseCode: ""
                    });
                    this.setState(() => ({
                      trainingCodeArr: nowTrainingCodeArr
                    }), () => {
                    });
                  }}
                >
                  Add Course
              </Button>
                <Icon
                  type="close-circle"
                  style={{ marginRight: 15, cursor: "pointer", fontSize: "25px" }}
                  onClick={this.closeArrBox}
                />
              </div>
              <div className={styles.showBoxContent}>
                <div className={styles.formCol} style={{ display: trainingCodeArr.length ? "flex" : "none" }}>
                  <div style={{ width: "210px" }}>Course Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "210px" }}>Course Name <span style={{ color: "#ed1b2e" }}>*</span></div>
                  <div style={{ width: "260px" }}>Training Code <span style={{ color: "#ed1b2e" }}>*</span></div>
                </div>
                {
                  trainingCodeArr.map((item, index) => {
                    return (
                      <div className={styles.formCol}>
                        <Select
                          style={{ width: "200px" }}
                          showSearch
                          allowClear
                          placeholder="Course Code"
                          value={item.courseCode}
                          onDropdownVisibleChange={() => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr[index].showCourseName = "";
                            nowTrainingCodeArr[index].courseName = "";
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr,
                            }), () => {
                              this.props.changeVals("PCLAAgentTrainingCode", {
                                nameList: []
                              });
                            });
                          }}
                          onChange={(val, oo) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            const showCourseCode = oo ? oo.props.children.props.title : "";
                            nowTrainingCodeArr[index].courseCode = val;
                            nowTrainingCodeArr[index].showCourseCode = showCourseCode;
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }));
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option.props.children.props) {
                              return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          }
                          }
                        >
                          {codeList.length
                            ? codeList.map(item => (
                              <Option value={item.courseCode} key={item.courseCode}>
                                <Tooltip placement="top" title={item.courseCode}>
                                  {item.courseCode}
                                </Tooltip>
                              </Option>
                            ))
                            : null}
                        </Select>
                        <Select
                          style={{ width: "200px" }}
                          showSearch
                          allowClear
                          placeholder="Course Name"
                          value={item.courseName}
                          disabled={!trainingCodeArr[index].courseCode}
                          onDropdownVisibleChange={() => {
                            this.props.getCourseNameListByName("", trainingCodeArr[index].showCourseCode);
                          }}
                          onChange={(val, oo) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            const showCourseName = oo ? oo.props.children.props.title : "";
                            nowTrainingCodeArr[index].courseName = val;
                            nowTrainingCodeArr[index].showCourseName = showCourseName;
                            /* eslint-disable */
                            const FilterArr = [];
                            for (let x = 0; x < nowTrainingCodeArr.length; x++) {
                              if (FilterArr.indexOf(nowTrainingCodeArr[x].courseName) < 0) {
                                FilterArr.push(nowTrainingCodeArr[x].courseName);
                              } else {
                                if (nowTrainingCodeArr[x].courseName) {
                                  notification.error({
                                    message: "Course Name already exists",
                                    duration: globalPromptTime10s,
                                    className: "ant-style-notification-error"
                                  });
                                  nowTrainingCodeArr[index].courseName = "";
                                  nowTrainingCodeArr[index].showCourseName = "";
                                  break;
                                }
                              }
                            }
                            /* eslint-disable */
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }));
                          }}
                          optionFilterProp="children"
                          filterOption={(input, option) => {
                            if (option.props.children.props) {
                              return option.props.children.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          }
                          }
                        >
                          {nameList.length
                            ? nameList.map(item => (
                              <Option value={item.courseId} key={item.courseId}>
                                <Tooltip placement="left" title={item.courseName}>
                                  {item.courseName}
                                </Tooltip>
                              </Option>
                            ))
                            : null}
                        </Select>
                        <Input
                          style={{ width: "150px" }}
                          placeholder="Training Code"
                          value={item.trainingCode}
                          allowClear
                          onChange={(val) => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr[index].trainingCode = val.target.value;
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }))
                          }}
                        />
                        {/* <div style={{ width: "100px", display: index !== 0 ? "none" : "inline-block" }}></div> */}
                        <Button
                          type="primary"
                          className={`${styles.btnColor} ${styles.btnText}`}
                          style={{ width: "100px" }}
                          onClick={() => {
                            const nowTrainingCodeArr = [...trainingCodeArr];
                            nowTrainingCodeArr.splice(index, 1);
                            this.setState(() => ({
                              trainingCodeArr: nowTrainingCodeArr
                            }))
                          }}
                        >
                          Delete
                        </Button>
                      </div>
                    )
                  })
                }
                <div style={{ width: "100%", justifyContent: "center", marginTop: "20px", display: 'flex' }}>
                  <Button
                    type="primary"
                    style={{ width: "100px", marginRight: "20px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={this.saveArrBox}
                  >
                    Save
                  </Button>
                  {/* <Button
                    type="primary"
                    style={{ width: "100px" }}
                    className={`${styles.btnColor} ${styles.btnText}`}
                    onClick={() => {
                      this.setState(() => ({
                        showBox: false
                      }))
                    }}
                  >
                    Cancel
                  </Button> */}
                </div>
              </div>
            </div> : null
        }
        <div className={styles.title}>PCLA Agent Training Code</div>
        <CreateForm {...props} {...this.props.fields} />
        {/* <div className={styles.col_dev_left_short}>
        </div> */}
      </div>
    );
  }
}

const insideStyles = {
  goCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  tableRow: {
    width: "100%",
    minHeight: "60px",
    borderBottom: "1px solid #BCC1CA",
    alignItems: "center",
    display: "flex"
  },
  tableData: {
    overflow: "auto",
    textAlign: "center",
    color: "rgb(38, 98, 208)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer"
  },
  tableTitle: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "24px",
    textAlign: "center",
    color: "white"
  },
  title: {
    background: "#FF4152",
    width: "100%",
    lineHeight: "30px",
    border: "0.5px solid #e8e8e8",
    textAlign: "center",
    color: "white",
    fontWeight: "bolder"
  }
};
const flexCol = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 12,
  sm: 24,
  xs: 24
};

export default withRouter(PCLAAgentTrainingCode);
