import { post } from "../../../utils/request";

const SAVE = "pacs/CPDHistoryReducer/SAVE";

const initState = {
  cpdSearchData: {},
  isShowTableCpd: false,
  searchLoading: false,
  cpdTemporaryData: {
    currentNum: 1
  }
};

// reducer
export default function CPDHistoryReducer(state = initState, action) {
  switch (action.type) {
    case SAVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export function getCPDsearchData(payload) {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        searchLoading: true
      }
    });
    post("/cpd/function", payload)
      .then(res => {
        const { data } = res;
        dispatch({
          type: SAVE,
          payload: {
            cpdSearchData: data || {},
            searchLoading: false,
            isShowTableCpd: true
          }
        });
      })
      .catch(() => {
        dispatch({
          type: SAVE,
          payload: {
            searchLoading: false
          }
        });
      });
  };
}

export function saveFields(payload) {
  return (dispatch, getState) => {
    const {
      pacsReducer: { CPDSettingsReducers: {
        CPDHistoryReducer: { cpdTemporaryData }
      } }
    } = getState();
    dispatch({
      type: SAVE,
      payload: {
        cpdTemporaryData: { ...cpdTemporaryData, ...payload }
      }
    });
  };
}

export function resetForm() {
  return dispatch => {
    dispatch({
      type: SAVE,
      payload: {
        cpdTemporaryData: {
          currentNum: 1
        },
        isShowTableCpd: false
      }
    });
  };
}
