import moment from "moment";

const initState = {
  role: {
    value: ""
  },
  bu: {
    value: ""
  },
  channel: {
    value: ""
  },
  nirc: {
    value: ""
  },
  userName: {
    value: ""
  },
  email: {
    value: ""
  },
  contactNum: {
    value: ""
  },
  account: {
    value: ""
  },
  unitCode: {
    value: ""
  },
  agentCode: {
    value: ""
  },
  status: {
    value: ""
  },
  contractedDate: {
    value: moment("2015/01/01", "YYYY/MM/DD")
  },
  terminationDate: {
    value: moment("2015/01/01", "YYYY/MM/DD")
  },
  rank: {
    value: ""
  },
  gender: {
    value: ""
  },
  region: {
    value: ""
  },
  branch: {
    value: ""
  },
  EDPAgentID: {
    value: ""
  },
  PBTBAppointed: {
    value: ""
  },
  EDPAgentCode: {
    value: ""
  },
  PBTBLicens: {
    value: ""
  },
  userType: {
    value: ""
  }
};

// reducer
export default function Agent_PAMB_Reducer(state = initState, action) {
  switch (action.type) {
    case "Agent_PAMB":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
